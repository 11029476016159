import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import { FC, ReactNode, useEffect, useState } from 'react'

import useDeleteTool from 'hooks/tools/useDeleteTool'

import { useStore } from 'stores'

import { planningToolsType, schedulerOptionEnum, schedulerSelectOptionEnum } from 'types/Template'
import { RepeatingTypeEnum } from 'types/Template/Tools'

import DayTool from './DayTool'

import UpdateOrRemoveToolModal from 'components/UpdateOrRemoveToolModal'

import styles from './styles.module.scss'

let savedDeleteParams: IDeleteParams | null = null

interface IDeleteParams {
  onAfterDelete: (() => void) | undefined
  toolIndex: number
  item: planningToolsType
}

interface IProps {
  isNewsMode?: boolean
  Header?: ReactNode
  dayIndex: number
  dayId?: number
  dayContent: planningToolsType[]
  preventDrag?: boolean
  inSearch?: boolean
}

const DayItem: FC<IProps> = ({ isNewsMode, Header, dayId, dayIndex, dayContent, preventDrag, inSearch }) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const [toolDeleteModalVisible, setToolDeleteModalVisible] = useState(false)
  const [currentDraggingTool, setCurrentDraggingTool] = useState<planningToolsType>()

  const { deleteToolConfirm, deleteTool } = useDeleteTool({ isNewsMode })

  const onCloseDeleteModal = () => {
    setToolDeleteModalVisible(false)
    savedDeleteParams = null
  }

  const onDeleteTool = ({
    onAfterDelete,
    item,
    toolIndex,
    repeatingOption,
  }: IDeleteParams & { repeatingOption?: RepeatingTypeEnum }) => {
    if (item.id !== -1 && item.schedulerOption !== schedulerOptionEnum.doNotRepeat && repeatingOption === undefined) {
      savedDeleteParams = {
        onAfterDelete,
        item,
        toolIndex,
      }
      return setToolDeleteModalVisible(true)
    }

    deleteToolConfirm({
      dayIndex: templateEntity.currentDayIndex,
      isUnbound: false,
      tool: item,
      toolIndex,
      onAfterDelete,
      repeatingOption,
    })

    savedDeleteParams = null
  }

  const onPlanningOptionSelect = async (value: schedulerSelectOptionEnum) => {
    let params = savedDeleteParams

    if (!params) return

    const { item, ...propsToPass } = {
      ...params,
      dayIndex: templateEntity.currentDayIndex,
      isUnbound: false,
      tool: params.item,
    }

    switch (value) {
      case schedulerSelectOptionEnum.ALL:
        await deleteTool({
          ...propsToPass,
          repeatingOption: RepeatingTypeEnum.ALL,
        })
        break
      case schedulerSelectOptionEnum.ONLY_THIS:
        await deleteTool({ ...propsToPass, repeatingOption: RepeatingTypeEnum.ONLY_THIS })
        break
      case schedulerSelectOptionEnum.THIS_AND_SUBSEQUENT:
        await deleteTool({ ...propsToPass, repeatingOption: RepeatingTypeEnum.THIS_AND_SUBSEQUENT })
        break
      default:
        break
    }

    onCloseDeleteModal()
  }

  useEffect(() => {
    return () => {
      savedDeleteParams = null
    }
  }, [])

  return (
    <li className={styles.dayItem} data-day-item="day-item">
      <UpdateOrRemoveToolModal
        visible={toolDeleteModalVisible}
        onCancel={onCloseDeleteModal}
        onOk={onPlanningOptionSelect}
        title={t('templateCreation.modalRemoveToolText')}
      />
      {!!Header && Header}
      <div className={styles.dropContainer} data-drop-container="dropContainer">
        <div>
          {dayContent.map((item, toolIndex) => (
            <DayTool
              key={`${toolIndex}-${item.id}-${item.uniqueId}`}
              item={item}
              toolIndex={toolIndex}
              dayIndex={dayIndex}
              dayId={dayId}
              onDelete={onAfterDelete => onDeleteTool({ onAfterDelete, item, toolIndex })}
              currentDraggingTool={currentDraggingTool}
              setCurrentDraggingTool={setCurrentDraggingTool}
              isNewsMode={isNewsMode}
              preventDrag={preventDrag}
              inSearch={inSearch}
            />
          ))}
        </div>
      </div>
    </li>
  )
}

export default observer(DayItem)
