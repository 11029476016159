import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import useIsRtl from 'hooks/useIsRtl'

import styles from './styles.module.scss'

import { ReactComponent as GoalIcon } from 'sources/images/GoalIcon.svg'

interface IProps {
  goal: string
  handleGoToProgramClick: () => void
}

const SelectedProgramInfo: FC<IProps> = ({ goal, handleGoToProgramClick }) => {
  const { t } = useTranslation()
  const isRtl = useIsRtl()

  return (
    <div className={styles.currentProgram}>
      <div className={styles.icon}>
        <GoalIcon />
      </div>
      <div className={styles.goalInfo}>
        <div>{t('programList.goal')}</div>
        <div>{goal}</div>
      </div>
      <div className={styles.actionButtons}>
        <div
          onClick={handleGoToProgramClick}
          data-dashboard-go-to-program="dashboard-go-to-program"
          id="dashboard-go-to-program"
          key={+isRtl}
        >
          {t('mentorDashboard.goToProgram')} {isRtl ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
        </div>
      </div>
    </div>
  )
}

export default observer(SelectedProgramInfo)
