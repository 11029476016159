import { t } from 'i18next'

import message from './message'

const handleOTPErrorMessage = ({ Message, StatusCode }: { Message: string; StatusCode: number }) => {
  if (Message?.includes('User does not have this brand')) {
    message.error(t('login.loginToWrongBrand'))
    return { message: t('login.loginToWrongBrand') }
  } else if (StatusCode === 404) {
    const isInvalidCode = Message.includes('Code is invalid')
    if (isInvalidCode) {
      message.error(t('login.otpInvalid'))
      return { message: t('login.otpInvalid') }
    }
    message.error(t('programCreation.smtWrong'))
    return { message: t('programCreation.smtWrong') }
  } else if (StatusCode === 400) {
    message.error(t('otp.banned'))
    return { message: t('otp.banned'), isBanned: true }
  }
}

export default handleOTPErrorMessage
