import { Radio, RadioChangeEvent, Space } from 'antd'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { schedulerSelectOptionEnum } from 'types/Template'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomModal from 'components/CustomComponents/CustomModal'

interface IProps {
  isUpdating?: boolean
  visible: boolean
  onCancel: () => void
  onOk: (value: schedulerSelectOptionEnum) => Promise<void>
  onChange?: (e: RadioChangeEvent) => void
  value?: schedulerSelectOptionEnum
  title?: string
}

const UpdateOrRemoveToolModal: FC<IProps> = ({ isUpdating, visible, onCancel, onOk, onChange, value, title }) => {
  const [localValue, setLocalValue] = useState<schedulerSelectOptionEnum>(schedulerSelectOptionEnum.ONLY_THIS)
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  return (
    <CustomModal
      title={
        !isUpdating
          ? title
          : isUpdating
          ? t('templateCreation.modalUpdateToolText')
          : t('templateCreation.modalRemoveToolText')
      }
      open={visible}
      onCancel={onCancel}
      okText={t<string>('dashboard.ok')}
      cancelText={t<string>('dashboard.cancel')}
      data-is-scheduler-modal-visible="isSchedulerModalVisible"
      footer={null}
    >
      <Radio.Group
        onChange={onChange ? onChange : e => setLocalValue(e.target.value)}
        value={value ?? localValue}
        data-planned-tool-delete-option="plannedToolDeleteOption"
      >
        <Space direction="vertical">
          <Radio value={1} data-only-this-tool="onlyThisTool">
            {t('templateCreation.onlyThisTool')}
          </Radio>
          <Radio value={2} data-this-and-the-following="thisAndTheFollowing">
            {t('templateCreation.thisAndTheFollowing')}
          </Radio>
          <Radio value={3} data-all-tools="allTools">
            {t('templateCreation.allTools')}
          </Radio>
        </Space>
      </Radio.Group>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, marginTop: 10 }}>
        <CustomButton onClick={onCancel}>{t('dashboard.cancel')}</CustomButton>
        <CustomButton
          loading={loading}
          type="primary"
          onClick={async () => {
            try {
              setLoading(true)
              await onOk(localValue)
            } finally {
              setLoading(false)
            }
          }}
        >
          {t('dashboard.ok')}
        </CustomButton>
      </div>
    </CustomModal>
  )
}

export default observer(UpdateOrRemoveToolModal)
