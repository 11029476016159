import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import stores from 'stores'

const useRedirectAfterAuth = () => {
  const { userStore } = stores

  const navigate = useNavigate()

  useEffect(() => {
    if (userStore.isTrainer) {
      const redirectUrl = localStorage.getItem('redirectAfterAuthUrl')

      if (redirectUrl) {
        navigate(redirectUrl)
        localStorage.removeItem('redirectAfterAuthUrl')
      }
    }
  }, [userStore.isTrainer])
}

export default useRedirectAfterAuth
