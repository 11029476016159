import { ITraineeWithInvite } from 'types/User'

const isAlreadyInvitedTrainee = (trainee: ITraineeWithInvite) => {
  return (
    trainee.inviteDto?.inviteStatus !== 'Failed' &&
    trainee.inviteDto?.inviteStatus !== 'Rejected' &&
    trainee.inviteDto?.inviteStatus !== 'Error'
  )
}

export default isAlreadyInvitedTrainee
