import { Button, ButtonProps } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { useStore } from 'stores'

import { DEFAULT_PRIMARY_COLOR } from 'utils/const'

import styles from './styles.module.scss'

type ButtonType = 'primary' | 'default' | 'link'
type SizeType = 'small' | 'medium' | 'large'

export interface ICustomBtnProps extends ButtonProps {
  type?: ButtonType
  buttonSize?: SizeType
}

const CustomButton: React.FC<ICustomBtnProps> = ({
  type = 'default',
  buttonSize = 'large',
  disabled,
  className,
  ...rest
}) => {
  const { userStore } = useStore()

  return (
    <Button
      {...rest}
      style={type === 'primary' ? { backgroundColor: userStore.brandData?.primaryColor || DEFAULT_PRIMARY_COLOR } : {}}
      disabled={disabled}
      className={classNames(
        styles[type],
        styles[buttonSize],
        {
          [styles.disabled]: disabled,
        },
        className
      )}
    />
  )
}

export default observer(CustomButton)
