import { Card, Typography } from 'antd'
import classNames from 'classnames'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import AimpactHeader from 'components/AimpactHeader'
import OTPForm from 'components/OTPForm'

import styles from './styles.module.scss'

const { Title, Text } = Typography

interface IProps {
  setIsContinued: (isCont: boolean) => void
  phoneNumber: string
  setIsBlocked: Dispatch<SetStateAction<boolean>>
  dark?: boolean
}

const EnterOPT = ({ setIsContinued, phoneNumber, setIsBlocked, dark }: IProps) => {
  const { t } = useTranslation()

  return (
    <Card className={styles.card} data-card-1="card-1">
      <AimpactHeader />

      <div className={styles.form} data-form-1="form-1">
        <Title level={3} className={classNames(styles.title, { [styles.titleDark]: dark })} data-title-2="title-2">
          {t('otp.otp')}
        </Title>
        <div className={styles.textContainer} data-text-container="data-text-container">
          <Text className={classNames(styles.text, { [styles.textDark]: dark })} data-text-1="text-1">
            {t('otp.text')}
          </Text>
          <Text
            className={styles.changePhone}
            onClick={() => setIsContinued(false)}
            data-change-phone="change-phone"
            id="change-phone"
          >
            {t('otp.changePhone')}
          </Text>
        </div>

        <OTPForm phoneNumber={phoneNumber} setIsBlocked={setIsBlocked} dark={dark} />
      </div>
    </Card>
  )
}

export default EnterOPT
