import { LoadingOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import { IReportUserLocal } from 'types/Report'

import sortingByLocale from 'utils/sortingByLocale'

import MenuTopLine from '../MenuTopLine'
import Trainee from './User'

import ContextMenu from 'components/ContextMenu'
import SearchBar from 'components/SearchBar'

import styles from './styles.module.scss'

interface IProps {
  allUsers: IReportUserLocal[]
  selectedUserIds: number[]
  setSelectedUserIds: Dispatch<SetStateAction<number[]>>
  traineesLoading: boolean
}

const SelectUsersMenu: FC<IProps> = ({ allUsers, selectedUserIds, setSelectedUserIds, traineesLoading }) => {
  const [searchVal, setSearchVal] = useState('')

  const { t } = useTranslation()

  const { userStore } = useStore()

  const searchedTrainees = useMemo(() => {
    const filteredUsers = allUsers.filter(user => {
      if ((!user?.firstName || !user?.lastName) && !searchVal) {
        return true
      }

      return (
        user?.firstName?.toLowerCase().includes(searchVal.toLowerCase()) ||
        user?.lastName?.toLowerCase().includes(searchVal.toLowerCase()) ||
        user?.phoneNumber.includes(searchVal.length && searchVal[0] === '0' ? searchVal.slice(1) : searchVal)
      )
    })

    return sortingByLocale(filteredUsers, userStore.language, 'firstName') as IReportUserLocal[]
  }, [searchVal, allUsers, userStore.language])

  const selectAllChange = useCallback(
    (e: any) => {
      if (e.target.checked) {
        setSelectedUserIds(allUsers.map(user => user.id))
      } else {
        setSelectedUserIds([])
      }
    },
    [allUsers]
  )

  const selectOneChange = useCallback(
    (user: IReportUserLocal) => {
      if (selectedUserIds.includes(user.id)) {
        setSelectedUserIds(prev => prev.filter(id => id !== user.id))
      } else {
        setSelectedUserIds(prev => [...prev, user.id])
      }
    },
    [selectedUserIds]
  )

  return (
    <ContextMenu>
      <div>
        <SearchBar value={searchVal} onChangeCustom={setSearchVal} placeholder={t<string>('dailyReport.searchUsers')} />
        <MenuTopLine
          title={`${t<string>('dailyReport.selectedUsers')} (${selectedUserIds.length})`}
          checkboxChecked={selectedUserIds.length > 0 && selectedUserIds.length >= allUsers.length}
          onCheckboxChange={selectAllChange}
        />
        {traineesLoading ? (
          <div className={styles.loader}>
            <LoadingOutlined />
          </div>
        ) : searchedTrainees.length ? (
          searchedTrainees.map(user => (
            <Trainee
              key={user.id}
              selectOneChange={selectOneChange}
              selected={selectedUserIds.includes(user.id)}
              user={user}
            />
          ))
        ) : (
          <div className={styles.notFound}>{t<string>('dailyReport.usersNotFound')}</div>
        )}
      </div>
    </ContextMenu>
  )
}

export default observer(SelectUsersMenu)
