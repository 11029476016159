import { useCallback, useEffect } from 'react'

import programsApi from 'api/programs.api'

import { useStore } from 'stores'

let programIdsProgressStarted: number[] = []

const useProgramCreationProgressBG = () => {
  const { programStore } = useStore()

  const checkProgress = useCallback(
    async (programId: number) => {
      return new Promise((res, rej) => {
        setTimeout(
          async () => {
            try {
              if (!programIdsProgressStarted.includes(programId)) {
                programIdsProgressStarted.push(programId)
              }

              const percent = await programsApi.checkProgramFullness(programId)

              programStore.setProgramCreationProgress(programId, percent)

              if (percent < 100) {
                res(await checkProgress(programId))
              }

              res(percent > 100 ? 100 : percent)
            } catch (e) {
              programStore.setProgramCreationProgress(programId, 100)
              rej(e)
            }
          },
          programIdsProgressStarted.includes(programId) ? 1000 : 0
        )
      })
    },
    [programStore.setProgramCreationProgress]
  )

  useEffect(() => {
    ;(async () => {
      if (!programStore.programsIdsToCheckProgress.length) return

      for (let i = 0; i < programStore.programsIdsToCheckProgress.length; i++) {
        try {
          const id = programStore.programsIdsToCheckProgress[i]

          await checkProgress(id)
        } catch (e) {}
      }
    })()
  }, [programStore.programsIdsToCheckProgress])
}

export default useProgramCreationProgressBG
