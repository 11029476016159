import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import styles from './styles.module.scss'

interface IProps {
  className?: string
  children: ReactNode
}

const ContextMenu: FC<IProps> = ({ children, className }) => {
  return <div className={classNames(styles.menu, className)}>{children}</div>
}

export default ContextMenu
