import { ILightMentor } from './Program'

export interface ICommonUser {
  accessProfile?: AccessProfileEnum
  companyAccessProfile?: AccessProfileEnum
  avatarId: number
  id: number
  firstName: string
  lastName: string
  fullName: string
  email: string | null
  phoneNumber: string
  lastActiveTime: null | string
  trainers?: ITrainerInAppRelation[]
  ban: null | IBan
  haveTrainerPermission?: boolean
  children?: {
    key: number
    lastActiveTime: string
    trainingProgram: string
    trainers: ITrainerInAppRelation
    trainingProgramId: number
    trainerId: number
  }[]
  trainerAppRelationships: {
    trainers: ITrainerInAppRelation[]
    trainingProgram: {
      goal: string
      groupChatAvatarId: number
      id: number
      isPublic: boolean
      name: string
      templateId: number
      trainingProgramAvatarId: number
      uniqueId: string | null
    }
  }[]
  trainingPrograms: {
    goal: string
    groupChatAvatarId: number
    id: number
    isPublic: boolean
    name: string
    templateId: number
    trainingProgramAvatarId: number
    uniqueId: string | null
  }[]
  fixedCode: null | string
  isDeleted: boolean
}

interface ITrainerInAppRelation {
  avatarId: number
  firstName: string
  id: number
  lastName: string
  phoneNumber: string
}

export interface IProgramAutocomplete {
  label: string
  value: string | number
}

export interface IDashboardCompany {
  id: number
  companyName: string
  trainers: IDashboardTrainer[]
  ban: null | IBan
  moderatorEmail: string
  companyModeratorId: number
  avatarId: number | null
  customBrandAllowed: boolean
  activeTraineesCount: number
  hideChat: boolean
  hideHome: boolean
  hideNotification: boolean
  hasBrand: boolean
}

interface IBan {
  description: string
  until: null | string
  userId: number
}

export type DashboardUsersIntersection = ICommonUser | IDashboardCompany

export interface IChangeLog {
  id: number
  version: string
  createdAt: string
  changeNotes: string
}

export interface ICompanyInvite {
  companyName: string
  moderatorEmail: string
  moderatorPhoneNumber: string
  trainers: string[]
  avatarId?: number
  customBrandAllowed: boolean
}

export interface ICompanyInviteTransformed {
  companyName: string
  moderatorEmail: string
  moderatorPhoneNumber: string
  trainers: {
    phoneNumber: string
  }[]
  avatarId?: number
}

export interface IDashboardTrainer {
  id: number
  fullName: string
  email: string
  phoneNumber: string
  lastActiveTime: null | string
  ban: null | IBan
  isDeleted: boolean
}

export interface IDashboardProgramsWithModerators {
  id: number
  name: string
  moderatorsCount: number
  usersCount: number
  moderators: IDashboardModerator[]
}

export interface IDashboardModerator {
  id: number
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  createdAt: string
}

export interface ISelectedTraineesProgram {
  name: string
  id: number
}

export interface ICompanyEditFields {
  companyName: string
  moderatorEmail: string
  avatarId: number | null
  customBrandAllowed: boolean
}

export enum ModeratorMainReceivingEnum {
  Answer = 1,
  ChatTranscript = 2,
  ChatTranscriptAndAnswer = Answer | ChatTranscript, //3
}

export interface IAdminProgram {
  id: number
  name: string
  mentors: ILightMentor[]
}

export enum AccessProfileEnum {
  Default = 0,
  MessagesOnly = 1,
  MessagesAndPrograms = 2,
}
