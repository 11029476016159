import { ClockCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { TraineeGroopingEnum } from '../../index'

import styles from './styles.module.scss'

interface IProps {
  activeTab: TraineeGroopingEnum
  handleDeleteTrainees: () => void
  resendInvitations: () => void
}

const ActionPanel: FC<IProps> = ({ activeTab, handleDeleteTrainees, resendInvitations }) => {
  const { t } = useTranslation()

  return (
    <span className={styles.actionBtns}>
      {(activeTab === TraineeGroopingEnum.Approved || activeTab === TraineeGroopingEnum.Rejected) && (
        <Button className={styles.action} onClick={handleDeleteTrainees}>
          <DeleteOutlined /> {t<string>('programList.delete')}
        </Button>
      )}
      {/* {activeTab === TraineeGroopingEnum.NeedParentalApproval && (
        <Button className={styles.action}>
          <ClockCircleOutlined /> {t<string>('programCreation.remindApproval')}
        </Button>
      )} */}
      {(activeTab === TraineeGroopingEnum.NeedParentalApproval ||
        activeTab === TraineeGroopingEnum.InvitationPending) && (
        <Button className={styles.action} onClick={handleDeleteTrainees}>
          <DeleteOutlined /> {t<string>('programCreation.cancelInvitation')}
        </Button>
      )}
      {activeTab === TraineeGroopingEnum.InvitationErr && (
        <Button className={styles.action} onClick={resendInvitations}>
          <ClockCircleOutlined /> {t<string>('programCreation.resendInvitation')}
        </Button>
      )}
    </span>
  )
}

export default ActionPanel
