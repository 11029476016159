import { authApi } from 'config'

export const uploadExcelFile = async (file: Blob) => {
  const formData = new FormData()
  formData.append('file', file)

  try {
    await authApi.post('ExternalDbs/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (error) {
    throw error
  }
}
