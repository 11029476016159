import { Checkbox, Form, FormInstance } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IProgramDetailsForm } from 'types/Program'

import CustomFormItem from 'components/CustomComponents/CustomFormItem'

import styles from './styles.module.scss'

interface IProps {
  form: FormInstance<IProgramDetailsForm>
  isQuickProgram?: boolean
}

const ProgramSettingsCheckboxes: FC<IProps> = ({ form, isQuickProgram }) => {
  const { t } = useTranslation()

  return (
    <Form form={form} className={styles.checkboxes}>
      {!isQuickProgram && (
        <CustomFormItem name="isVisible" initialValue={true} valuePropName="checked" className={styles.formItem}>
          <Checkbox className={styles.checkbox}>{t('programCreation.programVisible')}</Checkbox>
        </CustomFormItem>
      )}
      {isQuickProgram && (
        <CustomFormItem name="sendSms" initialValue={false} valuePropName="checked" className={styles.formItem}>
          <Checkbox className={styles.checkbox}>{t('programCreation.sendSms')}</Checkbox>
        </CustomFormItem>
      )}
      <CustomFormItem name="enableChat" initialValue={true} valuePropName="checked" className={styles.formItem}>
        <Checkbox className={styles.checkbox}>{t('programCreation.enableChat')}</Checkbox>
      </CustomFormItem>
      <CustomFormItem name="showParticipants" initialValue={true} valuePropName="checked" className={styles.formItem}>
        <Checkbox className={styles.checkbox}>{t('programCreation.showParticipants')}</Checkbox>
      </CustomFormItem>
      {!isQuickProgram && (
        <CustomFormItem
          name="autoAcceptInvite"
          initialValue={false}
          valuePropName="checked"
          className={styles.formItem}
        >
          <Checkbox className={styles.checkbox}>{t('programCreation.autoAcceptInvite')}</Checkbox>
        </CustomFormItem>
      )}
    </Form>
  )
}

export default ProgramSettingsCheckboxes
