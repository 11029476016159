import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import React, { ReactNode, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useIsRtl from 'hooks/useIsRtl'

import { useStore } from 'stores'

import dateTime from 'utils/dateTime'
import message from 'utils/message'

import Calendar from './Calendar'
import DaysNavigation from './DaysNavigation'
import ModeSelector from './ModeSelector'
import TemplateActions from './TemplateActions'
import TopBtns from './TopBtns'

import CreateTemplateBtn from 'components/CreateTemplateBtn'

import styles from './styles.module.scss'

interface IProps {
  showBackRoute?: boolean
  goBack: () => void
  isNewsMode?: boolean
  PostHeader: ReactNode
  isSearchMode?: boolean
}

const HeadInfo: React.FC<IProps> = ({ showBackRoute, goBack, isNewsMode, PostHeader, isSearchMode }) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const { t } = useTranslation()

  const isRtl = useIsRtl()
  const navigate = useNavigate()

  const [loadingDayIdx, setLoadingDayIdx] = useState<number | null>(null)

  const onChangeDay = async (idx: number) => {
    if (templateStore.isToolEditing) {
      message.error(t('templateCreation.previousEdit'))
      return
    }

    if (!templateEntity.templateInfo?.id || templateEntity.loadingDay) return

    try {
      setLoadingDayIdx(idx)

      if (templateEntity.isWallPosts && templateEntity.programId) {
        if (isNewsMode) {
          await templateStore.getWallNews({
            programId: templateEntity.programId.toString(),
            dayIndex: idx,
            useCache: true,
          })
        } else {
          await templateStore.getWallPostById({
            dayIndex: idx,
            programId: templateEntity.programId.toString(),
            useCache: true,
          })
        }
      } else {
        await templateStore.getTemplateEntitiesDay({
          dayIndex: idx,
          id: templateEntity.templateInfo?.id,
          useCache: true,
        })
      }
    } catch (e) {
      navigate(-1)
    } finally {
      setLoadingDayIdx(null)
    }
  }

  const createTemplateBtnImperativeRef = useRef<{ openModal: () => void }>(null)

  const isTemplateHeader = !!templateEntity.templateInfo?.name && !!templateEntity.templateInfo?.createdAt

  if (!templateEntity.templateInfo) return <></>

  return (
    <>
      <div>
        {!showBackRoute ? (
          <div
            onClick={goBack}
            className={styles.backBtn}
            data-template-back-btn="template-back-btn"
            id="template-back-btn"
          >
            <span>
              {isRtl ? <ArrowRightOutlined /> : <ArrowLeftOutlined />} {t<string>('templateCreation.back')}
            </span>
          </div>
        ) : (
          <div className={styles.newTemplateBtnsContainer}>
            <CreateTemplateBtn ref={createTemplateBtnImperativeRef} isLoading={false} type="primary" />
          </div>
        )}
        <div className={styles.titleContainer}>
          {isTemplateHeader ? (
            <div>
              <div className={styles.titleInner}>
                <h2 className={styles.templateName}>{templateEntity.templateInfo.name}</h2>
                {!!templateEntity.templateInfo.id && <TemplateActions />}
              </div>
              <div className={styles.id}>ID: {templateEntity.templateInfo.id}</div>

              <span className={styles.created}>
                {t('templateCreation.created')} {dateTime.getHebrewDate(templateEntity.templateInfo.createdAt)}
              </span>
            </div>
          ) : !!templateStore.wallProgram?.name ? (
            <div className={styles.programName}>{templateStore.wallProgram?.name}</div>
          ) : (
            <div />
          )}
          <TopBtns />
        </div>
        {!isSearchMode && !!templateStore.templateModeVisible && (
          <div className={styles.options}>
            <ModeSelector isNewsMode={!!isNewsMode} />
          </div>
        )}
      </div>
      {!isSearchMode && (
        <DaysNavigation isNewsMode={isNewsMode} loadingDayIdx={loadingDayIdx} onChangeDay={onChangeDay} />
      )}
      {templateStore.templateEntity.isWallPosts && (
        <div className={styles.bot}>
          {!isSearchMode ? (
            <Calendar
              templateCreatedAt={templateStore.wallProgram?.startDate || templateEntity.templateInfo.createdAt}
              onChangeDay={onChangeDay}
              isNewsMode={isNewsMode}
            />
          ) : (
            <div />
          )}
          {PostHeader}
        </div>
      )}
    </>
  )
}

export default observer(HeadInfo)
