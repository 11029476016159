import { useQueryClient } from '@tanstack/react-query'
import { Card, Form } from 'antd'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import useRestoreProgramData from 'hooks/programCreation/useRestoreProgramData'
import useProgramCreateMutation from 'hooks/tanstack/mutations/useProgramCreateMutation'

import { IProgramDetailsForm, ProgramCreationStepsEnum, ProgramForm, ProgramImage } from 'types/Program'
import { QueryKeysEnum } from 'types/QueryKeysEnum'

import FileTranform from 'utils/FileTranform'
import LS, { LSKeys } from 'utils/LS'

import BottomPanel from './BottomPanel'
import HeadContent from './HeadContent'
import Preview from './Preview'
import TemplateBlock from './TemplatesBlock'
import TraineesTab from './TraineesTab'

import Loader from 'components/Loader'
import ProgramDetailsForm from 'components/ProgramDetailsForm'

import styles from './styles.module.scss'

const ProgramCreation = () => {
  const { id: programId } = useParams<{ id: string }>()

  const changeDebounce = useRef<number | null>(null)

  const queryClient = useQueryClient()

  const [currentStep, setCurrentStep] = useState<ProgramCreationStepsEnum>(ProgramCreationStepsEnum.DETAILS)
  const [programData, setProgramData] = useState<ProgramForm>()

  const [form] = Form.useForm<ProgramForm>()
  const [formDetails] = Form.useForm<IProgramDetailsForm>()

  const {
    createProgramHandler,
    logoImage,
    isLoading,
    selectedTemplateId,
    setLogoImage,
    setTrainees,
    trainees,
    setSelectedTemplateId,
    setStartDate,
    startDate,
    disableSMS,
    selectedTemplate,
    setSelectedTemplate,
    setDisableSMS,
    selectedTrainees,
    setSelectedTrainees,
    setTrainingProgramAvatarId,
    settingsProfileId,
    setSettingsProfileId,
    setSortingOption,
    sortingOption,
    companyId,
    setCompanyId,
  } = useProgramCreateMutation({
    onSuccess: () => {
      queryClient.removeQueries([QueryKeysEnum.PROGRAMS])
    },
  })

  useRestoreProgramData({
    form,
    setSelectedTemplateId,
    setSelectedTemplate,
    setTrainees,
    setCurrentStep,
    setSelectedTrainees,
    setLogoImage,
    confirmClassname: styles.confirmLoad,
    onProgramRestore: (programFromLS?: ProgramForm) => {
      if (!programFromLS) return
      setProgramData(prevState => ({ ...prevState, ...programFromLS }))
    },
  })

  useEffect(() => {
    if (trainees.length) LS.set(LSKeys.PROGRAM_TRAINEES, trainees)
  }, [trainees])

  useEffect(() => {
    if (selectedTrainees.length) LS.set(LSKeys.PROGRAM_TRAINEES_SELECTED, selectedTrainees)
  }, [selectedTrainees])

  const logoImageHandler = async (image: ProgramImage) => {
    setLogoImage(image)
    setTrainingProgramAvatarId(null)

    if (image.file) {
      LS.set(LSKeys.PROGRAM_LOGO, await FileTranform.toBase64(image.file))
    }
  }

  const saveFormState = () => {
    const data = form.getFieldsValue()

    LS.set(LSKeys.PROGRAM, data)
    LS.set(LSKeys.PROGRAM_TEMPLATE_ID, selectedTemplateId)
  }

  const handleChange = async () => {
    if (programId) return

    if (changeDebounce.current) clearTimeout(changeDebounce.current)

    changeDebounce.current = setTimeout(saveFormState, 500) as unknown as number
  }

  const setDetails = async (moveToStep: ProgramCreationStepsEnum | null) => {
    saveFormState()

    const res = await form.validateFields()

    if (!res) return false

    setProgramData(prevState => ({ ...prevState, ...res }))

    if (moveToStep !== null) {
      setCurrentStep(moveToStep)
    }

    return true
  }

  const onStepChange = async (newStep: ProgramCreationStepsEnum) => {
    const isValid = await setDetails(null)

    if (!isValid) return

    setCurrentStep(newStep)
  }

  const handleNext = async () => {
    switch (currentStep) {
      case ProgramCreationStepsEnum.DETAILS:
        setDetails(ProgramCreationStepsEnum.TEMPLATE)
        break
      case ProgramCreationStepsEnum.TEMPLATE:
        setCurrentStep(prevStep => prevStep + 1)
        break
      case ProgramCreationStepsEnum.INVITES:
        setCurrentStep(prevStep => prevStep + 1)
        break
      case ProgramCreationStepsEnum.PREVIEW:
        if (!programData) return

        const programCheckboxes = await formDetails.validateFields()

        return createProgramHandler({
          ...programData,
          ...programCheckboxes,
          name: programData.name?.trim(),
          goal: programData.goal?.trim(),
          description: programData.description?.trim(),
          companyId,
        })
    }
  }

  const handlePrev = () => {
    setCurrentStep(prevStep => prevStep - 1)
  }

  return (
    <div className={styles.program} data-program="program">
      {isLoading ? (
        <div className={styles.loaderContainer} data-loader-container="loaderContainer">
          <Loader />
        </div>
      ) : (
        <>
          <HeadContent currentStep={currentStep} onStepChange={onStepChange} selectedTemplateId={selectedTemplateId} />
          <Card
            className={cn(styles.container, 'container', {
              [styles.whiteBg]: currentStep < 2,
              [styles.dropStyles]: currentStep >= 2,
              [styles.templateSelected]: currentStep === ProgramCreationStepsEnum.TEMPLATE && selectedTemplateId,
            })}
            data-container-card="container-card"
          >
            {currentStep === ProgramCreationStepsEnum.DETAILS ? (
              <ProgramDetailsForm
                form={form}
                logoImage={logoImage}
                setLogoImage={logoImageHandler}
                handleSubmit={createProgramHandler}
                handleChange={handleChange}
                sortingOption={sortingOption}
                setSortingOption={setSortingOption}
                isCreation
              />
            ) : currentStep === ProgramCreationStepsEnum.TEMPLATE ? (
              <TemplateBlock
                selectedTemplateId={selectedTemplateId}
                setSelectedTemplateId={setSelectedTemplateId}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
              />
            ) : currentStep === ProgramCreationStepsEnum.INVITES ? (
              <TraineesTab
                setTrainees={setTrainees}
                selectedTrainees={selectedTrainees}
                setSelectedTrainees={setSelectedTrainees}
                disableSMS={disableSMS}
                setDisableSMS={setDisableSMS}
                settingsProfileId={settingsProfileId}
                setSettingsProfileId={setSettingsProfileId}
              />
            ) : currentStep === ProgramCreationStepsEnum.PREVIEW ? (
              <Preview
                startDate={startDate}
                setStartDate={setStartDate}
                companyId={companyId}
                onCompanyIdChanged={setCompanyId}
                formDetails={formDetails}
              />
            ) : (
              <></>
            )}
          </Card>
        </>
      )}

      <BottomPanel
        onNext={handleNext}
        onPrev={handlePrev}
        isNextDisabled={!selectedTemplateId && currentStep === ProgramCreationStepsEnum.TEMPLATE}
        currentStep={currentStep}
        isLoading={isLoading}
      />
    </div>
  )
}

export default observer(ProgramCreation)
