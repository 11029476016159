import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import styles from './styles.module.scss'

interface IProps {
  backRoute: '/programs' | '/company' | '/message' | '/trainees' | '/company/message'
  text?: string
}

const BackLink: FC<IProps> = ({ backRoute, text }) => {
  const isRtl = document.body.dir === 'rtl'

  const { t } = useTranslation()

  return (
    <Link className={styles.link} to={backRoute} data-link-3="link-3">
      {isRtl ? (
        <ArrowRightOutlined data-right-outlined="right-outlined" />
      ) : (
        <ArrowLeftOutlined data-left-outlined="left-outlined" />
      )}
      <span data-cancel="cancel">{text || t<string>('templateCreation.back')}</span>
    </Link>
  )
}

export default BackLink
