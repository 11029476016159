import classNames from 'classnames'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'

import { IQuestionnaireCommonState, QuestionTypeEnum } from 'types/Template'

import mergeTextAndVariable from 'utils/mergeTextAndVariable'

import QuestionEditActions from '../../QuestionEditActions'

import CustomInput from 'components/CustomComponents/CustomInput'
import ToolWatchMode from 'components/ToolWatchMode'

import styles from '../styles.module.scss'

interface IProps {
  question: string
  questionIndex: number
  handleChange: (questionState: IQuestionnaireCommonState, questionIndex: number) => void
  isEditing: boolean
  isEmpty?: boolean
  canValidate: boolean
  handleTypeChange: (option: QuestionTypeEnum | null) => void
  handleDelete: (questionIndex: number) => void
  questionCount: number
}

const Text: React.FC<IProps> = ({
  question,
  handleChange,
  questionIndex,
  isEditing,
  isEmpty,
  canValidate,
  handleTypeChange,
  handleDelete,
  questionCount,
}) => {
  const isError = canValidate && !question

  const scrollParam = useMemo(() => {
    const obj: any = {}

    if (isError) {
      obj['data-scroll'] = '1'
    }

    return obj
  }, [isError])

  return (
    <div {...scrollParam}>
      <ToolWatchMode isEditing={isEditing} value={question} className={styles.styleClear}>
        <div className={styles.ToolWatchModeInner}>
          <CustomInput
            className={classNames(styles.input, {
              [styles.error]: isError,
            })}
            dir="ltr"
            value={question}
            onChange={e =>
              handleChange(
                //@ts-ignore
                { type: isEmpty ? null : QuestionTypeEnum.TEXT, question: e.target.value },
                questionIndex
              )
            }
            onSelectVariable={variable => {
              handleChange(
                //@ts-ignore
                { type: isEmpty ? null : QuestionTypeEnum.TEXT, question: mergeTextAndVariable(question, variable) },
                questionIndex
              )
            }}
            disabled={!isEditing}
            placeholder={t<string>('templateCreation.ratingInputPlaceholder')}
            data-text-question-input="text-question-input"
            id="text-question-input"
          />
          <QuestionEditActions
            canValidate={canValidate}
            handleDelete={handleDelete}
            handleTypeChange={handleTypeChange}
            isEditing={isEditing}
            questionCount={questionCount}
            questionIndex={questionIndex}
            questionType={isEmpty ? null : QuestionTypeEnum.TEXT}
          />
        </div>
      </ToolWatchMode>
    </div>
  )
}

export default observer(Text)
