import { PlusOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import { IGoal, IGoalCreate } from 'types/Goal'

import Goal from './Goal'
import GoalForm from './GoalForm'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomModal from 'components/CustomComponents/CustomModal'

import message from 'utils/message'
import styles from './styles.module.scss'

interface IProps {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

const DailyGoalModal: FC<IProps> = ({ setVisible, visible }) => {
  const { t } = useTranslation()

  const [formVisible, setFormVisible] = useState(false)
  const [goalsLocal, setGoalsLocal] = useState<IGoal[]>([])
  const [numberOfEditItems, setNumberOfEditItems] = useState(0)
  const [loading, setLoading] = useState(false)

  const { goalsStore, templateStore } = useStore()

  const onSave = async () => {
    try {
      const toUpdate: IGoal[] = []
      const toRemove: number[] = []
      const toCreate: IGoalCreate[] = []

      goalsLocal.forEach(goal => {
        if (goal.id === -1) {
          toCreate.push(_.omit(goal, 'id'))
        } else {
          toUpdate.push(goal)
        }
      })

      if (goalsStore.templateGoals && goalsStore.templateGoals.length) {
        goalsStore.templateGoals.forEach(backupItem => {
          const exist = goalsLocal.findIndex(goal => goal.id === backupItem.id) !== -1

          if (!exist) {
            toRemove.push(backupItem.id)
          }
        })
      }

      setLoading(true)

      if (templateStore.templateEntity.programId) {
        await goalsStore.saveProgramGoals({
          id: +templateStore.templateEntity.programId,
          templateGoalIdsToRemove: toRemove,
          templateGoalsToCreate: toCreate,
          templateGoalsToUpdate: toUpdate,
        })
      } else if (templateStore.templateEntity.templateInfo) {
        await goalsStore.saveTempalteGoals({
          id: templateStore.templateEntity.templateInfo.id,
          templateGoalIdsToRemove: toRemove,
          templateGoalsToCreate: toCreate,
          templateGoalsToUpdate: toUpdate,
        })
      } else {
        throw new Error('No template info found when trying to save goals')
      }

      message.success(t('.goalsSaved'))

      setVisible(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setGoalsLocal(goalsStore.templateGoals)
  }, [goalsStore.templateGoals])

  useEffect(() => {
    if (!visible) {
      setFormVisible(false)
      setNumberOfEditItems(0)
    }
  }, [visible])

  const saveBtnVisible = !_.isEqual(goalsStore.templateGoals, goalsLocal) || numberOfEditItems !== 0

  return (
    <CustomModal
      style={{ minWidth: 600 }}
      title={<div className={styles.modalTitle}>{`${t<string>('templateCreation.goals')} (${goalsLocal.length})`}</div>}
      open={visible}
      destroyOnClose
      onCancel={() => {
        setVisible(false)

        setGoalsLocal(goalsStore.templateGoals)
      }}
      footer={false}
    >
      {!goalsLocal.length && !formVisible ? (
        <div className={styles.noGoals}>
          <div className={styles.message}>{t<string>('templateCreation.noGoalsYet')}</div>
          <CustomButton type="primary" icon={<PlusOutlined />} onClick={() => setFormVisible(true)}>
            {t<string>('templateCreation.addGoal')}
          </CustomButton>
        </div>
      ) : (
        <>
          {!!goalsLocal.length && (
            <div>
              {goalsLocal.map((goal, idx) => (
                <Goal
                  key={idx}
                  idx={idx}
                  goal={goal}
                  setGoals={setGoalsLocal}
                  setNumberOfEditItems={setNumberOfEditItems}
                  formVisible={formVisible}
                />
              ))}
              <div className={styles.addGoalLinkContainer}>
                {!formVisible && (
                  <CustomButton type="link" onClick={() => setFormVisible(true)} className={styles.addGoalLink}>
                    {t<string>('templateCreation.addGoal')}
                  </CustomButton>
                )}
              </div>
            </div>
          )}
          <div>
            {formVisible ? (
              <GoalForm setGoals={setGoalsLocal} setFormVisible={setFormVisible} />
            ) : saveBtnVisible ? (
              <div className={styles.btnContainer}>
                <CustomButton onClick={onSave} type="primary" loading={loading}>
                  {t<string>('programCreation.save')}
                </CustomButton>
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </CustomModal>
  )
}

export default observer(DailyGoalModal)
