import { MoreOutlined } from '@ant-design/icons'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import useTraineeStatusMap from 'hooks/trainees/useTraineeStatusMap'

import { ITraineeWithInvite, ITrainees } from 'types/User'
import { TraineeGroopingEnum } from '../../index'

import CustomDropdown from 'components/CustomComponents/CustomDropdown'
import CustomMenu from 'components/CustomComponents/CustomMenu'

interface IProps {
  trainee: ITraineeWithInvite
  currentTab: TraineeGroopingEnum
  resendInvitations: (traineeIds: number[]) => Promise<void>
  handleDeleteOneTrainee: (trainee: ITrainees) => Promise<void>
}

const menuHashMap = {
  isApproved: ['2'],
  isError: ['1'],
  isParentalApproval: ['1', '3'],
  isPending: ['3'],
  isRejected: ['1', '2'],
}

const TraineeActions: FC<IProps> = ({ currentTab, trainee, resendInvitations, handleDeleteOneTrainee }) => {
  const statusMap = useTraineeStatusMap({ currentTab, trainee })

  const { t } = useTranslation()

  const menu = [
    {
      key: '1',
      label: statusMap.isParentalApproval ? t('programCreation.remindApproval') : t('programCreation.resendInvitation'),
      onClick: () => resendInvitations([trainee.id]),
    },
    {
      key: '2',
      label: t('programList.delete'),
      onClick: () => handleDeleteOneTrainee(trainee as unknown as ITrainees),
    },
    {
      key: '3',
      label: t('programCreation.cancelInvitation'),
      onClick: () => handleDeleteOneTrainee(trainee as unknown as ITrainees),
    },
  ]

  const key = Object.entries(statusMap).find(entry => !!entry[1]) as [keyof typeof statusMap, boolean]

  const itemsToFilter = menuHashMap?.[key?.[0]] || []

  const items = menu.filter(itemsInMenu => itemsToFilter.includes(itemsInMenu.key))

  return (
    <CustomDropdown overlay={<CustomMenu items={items} />}>
      <MoreOutlined />
    </CustomDropdown>
  )
}

export default memo(TraineeActions)
