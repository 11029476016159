import { LoadingOutlined } from '@ant-design/icons'
import { Image, Space } from 'antd'
import { FC, memo, useMemo } from 'react'

import { IEditProgramDTO } from 'api/DTOs/programs.dto'

import useProgramImgInterceptor from 'hooks/useProgramImgInterceptor'

import AvatarWithActions from './AvatarWithActions'

import styles from './styles.module.scss'

import DefaultLogo from 'sources/images/logo.png'

interface IProps {
  trainingProgramAvatarId: number
  programDetails?: Omit<IEditProgramDTO, 'trainingProgramAvatarId, groupChatAvatarId'> & { id: number }
  size?: number
  withActions?: boolean
}

const ProgramAvatar: FC<IProps> = ({ trainingProgramAvatarId, size, programDetails, withActions }) => {
  const { image, isLoading } = useProgramImgInterceptor({ id: trainingProgramAvatarId })

  const calcStyles = useMemo(() => {
    if (size) {
      return {
        width: size,
        height: size,
      }
    }

    return {
      width: 124,
      height: 124,
    }
  }, [size])

  return (
    <Space>
      {isLoading ? (
        <div className={styles.loader} style={calcStyles}>
          <LoadingOutlined style={{ color: '#333', fontSize: size ? size / 3 : 40 }} />
        </div>
      ) : !!programDetails && withActions ? (
        <AvatarWithActions
          currentAvatar={image || DefaultLogo}
          calcStyles={calcStyles}
          programDetails={programDetails}
        />
      ) : (
        <Image src={image || DefaultLogo} className={styles.avatarImage} style={calcStyles} preview={false} />
      )}
    </Space>
  )
}

export default memo(ProgramAvatar)
