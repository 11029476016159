import { Checkbox } from 'antd'
import classNames from 'classnames'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

interface IProps {
  title: string
  checkboxChecked: boolean
  onCheckboxChange: (e: any) => void
}

const MenuTopLine: FC<IProps> = ({ title, checkboxChecked, onCheckboxChange }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.line}>
      <h2 className={styles.title}>{title}</h2>
      <Checkbox
        checked={checkboxChecked}
        className={classNames('checkboxUI', styles.checkbox)}
        onChange={onCheckboxChange}
      >
        {t<string>('programCreation.selectAll')}
      </Checkbox>
    </div>
  )
}

export default memo(MenuTopLine)
