import { Rule } from 'antd/lib/form'
import { t } from 'i18next'

import { emailRe } from 'utils/const'

export const emailValidator = ({ required }: { required: boolean }) => {
  return (rule: Rule, value: string, callback: (error?: string) => void) => {
    const trimmedValue = value?.trim()

    if (!trimmedValue) {
      if (!required) {
        callback()
      } else {
        callback(t<string>('programCreation.required'))
      }
    } else if (!emailRe.test(trimmedValue)) {
      callback(t<string>('dashboard.emailIsIncorrect'))
    } else {
      callback()
    }
  }
}
