import React, { FC, Suspense, memo, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useStore } from 'stores'

import { ITemplateOption } from 'types/Template'

import TemplateTab from './TemplateTab'

import Tabbar from 'components/Tabbar'

const ActionsTab = React.lazy(() => /* webpackPrefetch: true */ import('./ActionsTab'))
const TraineesTab = React.lazy(() => /* webpackPrefetch: true */ import('./TraineesTab'))
const MentorsTab = React.lazy(() => /* webpackPrefetch: true */ import('./MentorsTab'))

enum TabsEnum {
  Templates = 'Templates',
  Trainees = 'Trainees',
  Settings = 'Settings',
  Mentors = 'Mentors',
}

interface IProps {
  programId: string
  template: ITemplateOption | null
  currentDayCompleted: number
}

const ProgramTabbar: FC<IProps> = ({ programId, template, currentDayCompleted }) => {
  const { userStore } = useStore()

  const [searchParams, setSearchParams] = useSearchParams({
    activeTab: template?.value
      ? TabsEnum.Templates
      : userStore.isForAnonSubdomain
      ? TabsEnum.Mentors
      : TabsEnum.Trainees,
  })

  const tabFromQuery = searchParams.get('activeTab')
  const activeTab =
    tabFromQuery && TabsEnum[tabFromQuery as TabsEnum]
      ? tabFromQuery
      : template?.value
      ? TabsEnum.Templates
      : userStore.isForAnonSubdomain
      ? TabsEnum.Mentors
      : TabsEnum.Trainees

  const setActiveTab = (tab: TabsEnum) => {
    setSearchParams(
      prev => {
        prev.set('activeTab', tab.toString())
        return prev
      },
      { replace: true }
    )
  }

  const tabs = useMemo(() => {
    const res = [
      {
        name: 'companyPage.mentors',
        key: TabsEnum.Mentors,
      },
      {
        name: 'integration.notifications',
        key: TabsEnum.Settings,
      },
    ]

    if (!userStore.isForAnonSubdomain) {
      res.unshift({
        name: 'programList.trainees',
        key: TabsEnum.Trainees,
      })
    }

    if (template?.value) {
      res.unshift({
        name: 'templateCreation.content',
        key: TabsEnum.Templates,
      })
    }

    return res
  }, [template?.value, userStore.isForAnonSubdomain])

  return (
    <div>
      <Tabbar activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />

      <Suspense fallback={<></>}>
        {activeTab === TabsEnum.Templates && template && !!template.value ? (
          <TemplateTab selectedTemplate={template} programId={programId} currentDayCompleted={currentDayCompleted} />
        ) : activeTab === TabsEnum.Trainees ? (
          <TraineesTab programId={programId} />
        ) : activeTab === TabsEnum.Settings ? (
          <ActionsTab programId={programId} />
        ) : activeTab === TabsEnum.Mentors ? (
          <MentorsTab programId={+programId} />
        ) : (
          <></>
        )}
      </Suspense>
    </div>
  )
}

export default memo(ProgramTabbar)
