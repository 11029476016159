import { authApi } from 'config'

import { VariablesType } from 'types/User'

const controller = 'variable'

const variableApi = {
  async getAll() {
    const res = await authApi.get<VariablesType>(controller)

    return res.data
  },
}

export default variableApi
