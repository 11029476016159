import { useState } from 'react'

import templatesApi from 'api/templates.api'
import wallPostApi from 'api/wallPost.api'

import useUnboundStore from 'hooks/useUnboundStore'

import { useStore } from 'stores'

import { IDeleteIds } from 'types/Template'
import { RepeatingTypeEnum } from 'types/Template/Tools'
import transformDeleteIdsToDeleteWithRepeating from 'utils/tools/transformDeleteIdsToDeleteWithRepeating'

interface IProps {
  isUnbound?: boolean
}

const useDeleteEntity = ({ isUnbound }: IProps) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const { unboundStore } = useUnboundStore()

  const [entitiyIdsToDelete, setEntityIdsToDelete] = useState<IDeleteIds[]>([])
  const [uniqIdsToExclude, setUnicIdsToExclude] = useState<string[]>([])

  const onDeleteEntities = async (repeatingOption?: RepeatingTypeEnum) => {
    if (!entitiyIdsToDelete.length) return

    if (isUnbound) {
      return unboundStore.addUnboundEntitiesToDelete(entitiyIdsToDelete)
    }

    if (templateEntity.isWallPosts) {
      if (repeatingOption !== void 0) {
        const toDelete = transformDeleteIdsToDeleteWithRepeating(entitiyIdsToDelete)
        await Promise.all(toDelete.map(entity => wallPostApi.deleteToolWithRepeating(entity, repeatingOption)))
      } else {
        await wallPostApi.deleteEntities(entitiyIdsToDelete)
      }
    } else {
      if (repeatingOption !== void 0) {
        const toDelete = transformDeleteIdsToDeleteWithRepeating(entitiyIdsToDelete)
        await Promise.all(toDelete.map(entity => templatesApi.deleteToolWithRepeating(entity, repeatingOption)))
      } else {
        await templatesApi.deleteEntities(entitiyIdsToDelete)
      }
    }
  }

  const addEntityToDeleting = (entity: IDeleteIds) => {
    setEntityIdsToDelete(prev => {
      const candidate = prev.findIndex(item => item.entity === entity.entity)

      if (candidate === -1) {
        return [...prev, entity]
      } else {
        const prevCopy = [...prev]
        prevCopy[candidate].id = [...prevCopy[candidate].id, ...entity.id]
        return prevCopy
      }
    })
  }

  const resetEntityIdsToDelete = () => {
    setEntityIdsToDelete([])
  }

  const addUniqueIdToExclude = (uniqueId: string) => {
    setUnicIdsToExclude(prev => [...prev, uniqueId])
  }

  const resetUniqueIdToExclude = () => {
    setUnicIdsToExclude([])
  }

  return {
    uniqIdsToExclude,
    addEntityToDeleting,
    resetEntityIdsToDelete,
    onDeleteEntities,
    addUniqueIdToExclude,
    resetUniqueIdToExclude,
  }
}

export default useDeleteEntity
