import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import 'i18nextConf'

import useBrandSync from 'hooks/useBrandSync'
import useSyncLanguage from 'hooks/useSyncLanguage'

import stores from 'stores'

import queryClient from 'utils/queryClient'

import Router from './Router'

import 'react-virtualized/styles.css'

// hide errors and wornings in console
// console.log = console.warn = console.error = () => {}

const App = () => {
  useSyncLanguage()
  useBrandSync({})

  if (window.location.pathname.includes('/report/') && !stores.userStore.isTrainer) {
    localStorage.setItem('redirectAfterAuthUrl', window.location.pathname + window.location.search || '')
  }

  return (
    <QueryClientProvider client={queryClient}>
      <DndProvider backend={HTML5Backend}>
        <Router />
      </DndProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default App
