import { DownOutlined } from '@ant-design/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { schedulerOptionEnum } from 'types/Template'
import { DayOfWeek } from 'types/Template/Tools'

import CustomSelect from 'components/CustomComponents/CustomSelect'

import styles from './styles.module.scss'

interface IProps {
  isEditing: boolean
  handleSelectSchedulerOption: (value: number) => void
  schedulerValue: number
  daysValue: DayOfWeek[]
  onDaysValueChange: (days: DayOfWeek[]) => void
}

const SchedulerSelect: FC<IProps> = ({
  isEditing,
  handleSelectSchedulerOption,
  schedulerValue,
  daysValue,
  onDaysValueChange,
}) => {
  const { t } = useTranslation()

  const daysSelectorVisible = schedulerValue === schedulerOptionEnum.specificDay

  const options = [
    {
      label: t<string>('templateCreation.doNotRepeat'),
      value: schedulerOptionEnum.doNotRepeat,
    },
    {
      label: t<string>('templateCreation.daily'),
      value: schedulerOptionEnum.daily,
    },
    {
      label: t<string>('templateCreation.weekly'),
      value: schedulerOptionEnum.weekly,
    },
    {
      label: t<string>('templateCreation.monthly'),
      value: schedulerOptionEnum.monthly,
    },
    {
      label: t<string>('templateCreation.everyWeekday'),
      value: schedulerOptionEnum.everyWeekday,
    },
    {
      label: t<string>('templateCreation.atSpecificDays'),
      value: schedulerOptionEnum.specificDay,
    },
  ]

  const daysOptions = [
    {
      label: t('selectDays.sunday'),
      value: DayOfWeek.Sunday,
    },
    {
      label: t('selectDays.monday'),
      value: DayOfWeek.Monday,
    },
    {
      label: t('selectDays.tuesday'),
      value: DayOfWeek.Tuesday,
    },
    {
      label: t('selectDays.wednesday'),
      value: DayOfWeek.Wednesday,
    },
    {
      label: t('selectDays.thursday'),
      value: DayOfWeek.Thursday,
    },
    {
      label: t('selectDays.friday'),
      value: DayOfWeek.Friday,
    },
    {
      label: t('selectDays.saturday'),
      value: DayOfWeek.Saturday,
    },
  ]

  return (
    <>
      <div className={styles.schedulerContainer}>
        <div className={styles.label}>{t<string>('templateCreation.duplicateContent')}</div>
        <CustomSelect
          style={{ width: 300 }}
          defaultValue={1}
          disabled={!isEditing}
          onSelect={handleSelectSchedulerOption}
          value={schedulerValue}
          getPopupContainer={trigger => trigger}
          suffixIcon={<DownOutlined style={{ color: '#130F26', fontSize: 12 }} />}
          options={options}
          data-scheduler-select="scheduler-select"
        />
      </div>
      {daysSelectorVisible && (
        <div className={styles.daysSelector}>
          <div className={styles.label}>{t('templateCreation.selectDaysToRepeat')}</div>
          <CustomSelect
            style={{ width: 300 }}
            disabled={!isEditing}
            showSearch={false}
            onChange={onDaysValueChange}
            placeholder={t('templateCreation.selectDaysToRepeat')}
            value={daysValue}
            mode="multiple"
            getPopupContainer={trigger => trigger}
            suffixIcon={<DownOutlined style={{ color: '#130F26', fontSize: 12 }} />}
            options={daysOptions}
            className={styles.selectMultiple}
          />
        </div>
      )}
    </>
  )
}

export default SchedulerSelect
