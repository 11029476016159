import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useIsRtl from 'hooks/useIsRtl'

import { useStore } from 'stores'

import { LanguagesType } from 'types/common'
import { LanguagesEnum } from 'types/InitialSettings'

import styles from './styles.module.scss'

import { ReactComponent as LanguagePic } from 'sources/images/sidebar/LanguageOld.svg'

interface IProps {
  position?: 'relative' | 'absolute'
  className?: string
}

export enum LanguageLabelsEnum {
  en = 'English (US)',
  heFemale = 'עברית - נקבה',
  heMale = 'עברית - זכר',
  heSababa = 'עברית יום יומית',
  heUndefined = 'עברית - לא מוגדר',
  ru = 'Русский',
}

export const langLabels = {
  [LanguagesEnum.en]: LanguageLabelsEnum.en,
  [LanguagesEnum.heFemale]: LanguageLabelsEnum.heFemale,
  [LanguagesEnum.heMale]: LanguageLabelsEnum.heMale,
  [LanguagesEnum.heSababa]: LanguageLabelsEnum.heSababa,
  [LanguagesEnum.heUndefined]: LanguageLabelsEnum.heUndefined,
  [LanguagesEnum.ru]: LanguageLabelsEnum.ru,
}

const LanguageSelectButton: React.FC<IProps> = ({ position = 'absolute', className }) => {
  const { i18n } = useTranslation()

  const { userStore } = useStore()

  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [languageOptions] = useState<LanguagesType[]>(['en', 'ru', 'heMale', 'heFemale', 'heUndefined', 'heSababa'])

  useEffect(() => {
    const handler = () => {
      setDropdownVisible(false)
    }

    window.addEventListener('click', handler)

    return () => window.removeEventListener('click', handler)
  }, [])

  const changeLanguage = (lang: LanguagesType) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('i18nextLng', lang)
    userStore.setLanguage(lang, !userStore.isAdmin)

    if (lang === 'heMale' || lang === 'heFemale' || lang === 'heUndefined' || lang === 'heSababa') {
      document.body.dir = 'rtl'
    } else {
      document.body.dir = 'ltr'
    }
  }

  const handleChange = (lang: LanguagesType) => {
    changeLanguage(lang)
  }

  const calcLangLabel = (label: LanguagesType) => {
    return langLabels[LanguagesEnum[label]]
  }

  const isRtl = useIsRtl()

  return (
    <div
      className={position === 'absolute' ? styles.absolute : styles.relative}
      data-relative="relative"
      data-language-select="language-select"
    >
      <div
        className={cn(styles.btn, { [styles.btnAbsolute]: position === 'absolute' })}
        onClick={e => {
          e.stopPropagation()

          setDropdownVisible(prev => !prev)
        }}
        data-language-btn="language-btn"
        id="language-btn"
      >
        <LanguagePic width={15} height={15} data-pic="pic" className={styles.langPic} />
        <div className={styles.btnText} data-btn-text="btnText">
          {calcLangLabel(localStorage.getItem('i18nextLng') as LanguagesType)}
        </div>
      </div>
      <div
        className={cn(styles.dropdown, {
          [styles.dropdownRtr]: document.body.dir === 'rtl',
          [styles.dropdownVisible]: dropdownVisible,
          [styles.dropdownWithAbsolute]: position === 'absolute',
        })}
        data-dropdown="dropdown"
      >
        {languageOptions.map(lang => (
          <div
            key={lang}
            dir="ltr"
            onClick={e => {
              e.stopPropagation()
              handleChange(lang)
            }}
            className={cn(styles.option, {
              [styles.activeOption]: userStore.language === lang,
              [styles.optionRtl]: isRtl,
            })}
            data-language-option="language-option"
          >
            {calcLangLabel(lang)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default LanguageSelectButton
