import { api, authApi } from 'config'

import { StatusInvitesEnum } from 'stores/Program'

import { LastActiveFilterEnum } from 'types/Program'
import {
  IShortTrainees,
  ITraineeByPhoneNumber,
  ITraineeForDashboard,
  ITraineeWithProgranInfo,
  ITraineesCount,
  IUser,
  IUserBase,
} from 'types/User'
import { PaginationResponse, UserLanguagesEnum, UserLanguagesReversedEnum } from 'types/common'
import { IExportTraineesParams, IGetAllUserTraineesParams, IUpdateUserDto } from './DTOs/user.dto'

const endpoint = 'User/'

const userApi = {
  async getProgramTraineeWithInviteInfo({
    programId,
    pageLength,
    pageNumber,
    str = '',
    inviteStatus,
    lastActiveFilter,
  }: {
    programId: string
    pageLength: number
    pageNumber: number
    str: string
    inviteStatus?: StatusInvitesEnum
    lastActiveFilter: LastActiveFilterEnum
  }) {
    const response = await authApi.get('User/traineesForProgram', {
      params: {
        programId,
        pageLength,
        pageNumber,
        str,
        inviteStatus,
        lastActiveFilter,
      },
    })

    return response.data
  },
  async getTrainersForProgram(params: { programId: number; str: string }) {
    const response = await authApi.get<PaginationResponse<IUserBase>>('User/trainersForProgram', {
      params: {
        ...params,
        pageNumber: 1,
        pageLenght: 9999,
      },
    })

    return response.data
  },
  async getShortAllTrainees(ProgramId: number | null) {
    const response = await authApi.get<IShortTrainees[]>(`User/traineesShort`, {
      params: {
        ProgramId,
      },
    })

    return response.data
  },
  async getAllUserTrainees(params: IGetAllUserTraineesParams) {
    const { programIds, ...rest } = params

    let queryString = ''

    programIds.forEach((id, idx) => {
      if (idx === 0) {
        queryString += 'programIds=' + id
      } else {
        queryString += '&programIds=' + id
      }
    })

    const response = await authApi.get<PaginationResponse<ITraineeWithProgranInfo>>(`User/trainees?${queryString}`, {
      params: rest,
    })

    return response.data
  },
  async removeFromProgram({ userID, trainingProgramId }: { userID: number; trainingProgramId: number }) {
    await authApi.patch('User/removeFromProgram', {
      userID,
      trainingProgramId,
    })

    return true
  },
  async getLeastPerformersTrainees(programId: string | number = '', str?: string) {
    const response = await authApi.get<ITraineeForDashboard[]>('User/traineesForDashboard', {
      params: {
        programId,
        str,
      },
    })
    return response.data
  },
  async sendSms(phoneNumber: string) {
    const response = await api.post(endpoint + 'sendSMS', { phoneNumber })

    return response
  },
  async smsAuthenticate(phoneNumber?: string, otpCode?: string) {
    const response = await api.post(
      endpoint + 'smsAuthenticate',
      {
        phoneNumber,
        otpCode,
      },
      {
        withCredentials: true,
      }
    )

    return response.data
  },
  async getUserPermission(phoneNumber: string) {
    try {
      const response = await api.get(endpoint + 'userPermission', {
        params: {
          phoneNumber,
        },
      })
      return response.data
    } catch (e) {}
  },
  async getCurrentUser() {
    const response = await authApi.get<IUser>(endpoint + 'currentUser')

    return response.data
  },
  async getUserLanguage() {
    const response = await authApi.get<UserLanguagesEnum>(endpoint + 'userLanguage')

    return response.data
  },
  async changeLanguage(languageCode: UserLanguagesReversedEnum) {
    await authApi.patch(endpoint + 'changeLanguage', null, {
      params: {
        languageCode,
      },
    })
  },
  async updateUser(dto: IUpdateUserDto) {
    const response = await authApi.patch(endpoint + 'update', dto)
    return response.data
  },
  async revokeToken() {
    await authApi.post(
      endpoint + 'revoke-token',
      {},
      {
        withCredentials: true,
      }
    )
  },
  async refreshToken() {
    try {
      const response = await authApi.post(
        endpoint + 'refresh-token',
        { notificationToken: '' },
        {
          withCredentials: true,
        }
      )

      return response.data
    } catch (e) {
      console.log('refresh token failed')
    }
  },
  async getTrainee(phoneNumber: string) {
    const response = await authApi.get<ITraineeByPhoneNumber>('User/trainee', {
      params: {
        phoneNumber,
      },
    })

    return response.data
  },
  async getTraineesCount() {
    const response = await authApi.get<ITraineesCount>('User/traineesCount')
    return response.data
  },
  async exportTraineesForProgram(programId: number) {
    const response = await authApi.get('User/exportTraineesForProgram', {
      params: { programId },
      responseType: 'blob',
    })
    const filename =
      response.headers['content-disposition'].split('filename="')?.[1].split('";')?.[0] ||
      `Trainees_${programId}_${new Date().toISOString()}.xlsx`

    const url = window.URL.createObjectURL(new Blob([response.data as any]))
    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', filename)
    link.click()

    return true
  },
  async exportTrainees(params: IExportTraineesParams) {
    const { programIds, ...rest } = params

    let queryString = ''

    programIds.forEach((id, idx) => {
      if (idx === 0) {
        queryString += 'programIds=' + id
      } else {
        queryString += '&programIds=' + id
      }
    })

    const response = await authApi.get(`User/exportTrainees?${queryString}`, {
      params: rest,
      responseType: 'blob',
    })
    const filename = `Trainees_${new Date().toISOString()}.xlsx`

    const url = window.URL.createObjectURL(new Blob([response.data as any]))
    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', filename)
    link.click()

    return true
  },
}

export default userApi
