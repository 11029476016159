import { mapTagToOption } from 'components/TagsUserSelector'
import useAllUserProgramsQuery from 'hooks/tanstack/queries/useAllUserProgramsQuery'
import useMentorTagsQuery from 'hooks/tanstack/queries/useMentorTagsQuery'
import { useState } from 'react'
import { PostMessageTemplate } from 'types/MessageTemplate'
import { useStore } from 'stores'

export enum deliveryTypeEnum {
  Post = 0,
  Notification = 1,
  PostAndNotification = 2,
}

const useCreateMessage = () => {
  const { userStore } = useStore()
  const { isLoading: isProgramsLoading, programs } = useAllUserProgramsQuery(userStore.user?.id)
  const [infoAboutTemplate, setInfoAboutTemplate] = useState<PostMessageTemplate>({
    deliveryType: deliveryTypeEnum.Post,
    name: '',
    title: '',
    subTitle: '',
    message: '',
    priority: 0,
    image: '',
    isForAll: false,
    tagIds: [],
    programIds: [],
  })
  const [error, setError] = useState(false)
  const handleChange = (value: deliveryTypeEnum) => {
    if (value === deliveryTypeEnum.Post) {
      return setInfoAboutTemplate(prev => ({ ...prev, tagIds: [], deliveryType: value }))
    }
    if (value === deliveryTypeEnum.Notification) {
      return setInfoAboutTemplate(prev => ({ ...prev, programIds: [], deliveryType: value }))
    }
    setInfoAboutTemplate(prev => ({ ...prev, deliveryType: value }))
  }
  const validateImageUrl = (url: string) => {
    const imagePattern = /\.(jpeg|jpg|gif|png|webp|svg)$/i
    return imagePattern.test(url)
  }
  const handleSubmit = (event: any) => {
    event.preventDefault()
    setInfoAboutTemplate(prev => ({ ...prev, image: event.target.value }))
    if (event.target.value.length === 0) {
      setError(false)
      return
    }
    if (!validateImageUrl(event.target.value)) {
      setError(true)
    } else {
      setError(false)
    }
  }

  const Disabled = () => {
    const { name, message } = infoAboutTemplate

    if (name.length > 0 && message.length > 0) {
      return false
    }

    return true
  }
  const { fromMoreThanOneCompany, tags, isLoading } = useMentorTagsQuery()

  const formatTags = tags.map(tag => mapTagToOption(tag, fromMoreThanOneCompany))
  const programsList = programs.map(prog => ({
    label: prog.name,
    value: prog.id,
  }))
  const onIncludedChange = (ids: number[]) => {
    setInfoAboutTemplate(prev => ({ ...prev, tagIds: ids }))
  }

  return {
    infoAboutTemplate,
    error,
    formatTags,
    isLoading,
    programsList,
    isProgramsLoading,
    onIncludedChange,
    setError,
    setInfoAboutTemplate,
    handleChange,
    handleSubmit,
    Disabled,
  }
}
export default useCreateMessage
