import { useCallback, useState } from 'react'
import { UploadFile } from 'antd'
import _ from 'lodash'

import { useStore } from 'stores'

import { IFileUploadingProgress, IUploadedFile } from 'types/Template'

import generateFormData from 'utils/generateFormData'

interface IProps {
  toolIndex: number
}

const useFileUpload = ({ toolIndex }: IProps) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const [uploadingProgress, setUploadingProgress] = useState<IFileUploadingProgress[]>([])

  const forceUploadFile = async (file: UploadFile) => {
    templateStore.setUploadInProgressFilesId([...templateStore.uploadInProgressFilesId, file.uid])

    if (!file) return

    const fileData = await generateFormData([file], false)

    const uploadedIds = await templateStore.uploadFiles(
      fileData.formData,
      fileData.mimeType !== 'image',
      setUploadingProgress,
      file.uid,
      file.name || file.fileName
    )

    templateStore.setUploadInProgressFilesId(templateStore.uploadInProgressFilesId.filter(item => file.uid !== item))

    if (!uploadedIds || !uploadedIds.length) {
      templateStore.setUploadErrorFilesId([file.uid])
      return
    }

    const constructedUploadedFilesInfo: IUploadedFile[] = uploadedIds.map((id, idx) => ({
      id,
      uuid: file.uid || '',
      toolIndex,
      dayIndex: templateEntity.currentDayIndex,
    }))

    templateStore.setUploadedFilesId([...templateStore.uploadedFilesId, ...constructedUploadedFilesInfo])

    return constructedUploadedFilesInfo
  }

  return {
    forceUploadFile,
    uploadingProgress,
  }
}

export default useFileUpload
