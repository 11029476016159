import { Form, FormItemProps } from 'antd'
import { FormItemLabelProps } from 'antd/es/form/FormItemLabel'
import classNames from 'classnames'
import { FC } from 'react'

import styles from './styles.module.scss'

const CustomFormItem: FC<FormItemLabelProps & FormItemProps> = ({ children, className, ...rest }) => {
  return (
    <Form.Item className={classNames(styles.item, className)} {...rest}>
      {children}
    </Form.Item>
  )
}

export default CustomFormItem
