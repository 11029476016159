import React from 'react'
import styles from './styles.module.scss'
import { ReactComponent as Send } from 'sources/images/send.svg'
import { ReactComponent as SendAndRead } from 'sources/images/sendAndRead.svg'
import { DeliveryStatusEnum } from '..'
interface ButtonsItemProps {
  name: string
  id: number
  userId: number
  deliveryStatus: number
  handleButtonClick: (id: number, userId: number, deliveryStatus: DeliveryStatusEnum) => void
}

const ButtonsItem: React.FC<ButtonsItemProps> = ({
  name,
  id,
  userId,
  deliveryStatus,

  handleButtonClick,
}) => {
  const getButtonClass = (status: DeliveryStatusEnum) => {
    switch (status) {
      case DeliveryStatusEnum.Not_Send:
        return styles.buttonNotSend
      case DeliveryStatusEnum.Sent:
        return styles.buttonSent
      case DeliveryStatusEnum.Read:
        return styles.buttonRead
      default:
        return styles.buttonDefault
    }
  }

  return (
    <button
      className={getButtonClass(deliveryStatus)}
      onClick={() => {
        handleButtonClick(userId, id, deliveryStatus)
      }}
    >
      {name} {DeliveryStatusEnum.Read === deliveryStatus && <SendAndRead />}
      {DeliveryStatusEnum.Sent === deliveryStatus && <Send />}
    </button>
  )
}

export default ButtonsItem
