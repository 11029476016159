import { Typography } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStore } from 'stores'
import { useTranslation } from 'react-i18next'

import LoginLayout from 'layouts/LoginLayout'

import styles from './styles.module.scss'

const { Title, Text } = Typography

const DeleteAccount: React.FC = () => {
  const { t } = useTranslation()
  const { userStore } = useStore()

  const brandName = userStore?.brandName || 'Aimpact'

  return (
    <LoginLayout>
      <div className={styles.container}>
        <Title level={3}>{t('deleteAccount.permanentlyDeleteAccount', { brandName })}</Title>
        <Text>{t('deleteAccount.youCanPermDeleteYourAccount', { brandName })}</Text>

        <Title level={4} type="secondary">
          {t('deleteAccount.howToPermDeleteYourAccount')}
        </Title>
        <Text>{t('deleteAccount.toDeleteFollowInstructions')}</Text>
        <ol>
          <li>{t('deleteAccount.step1', { brandName })}</li>
          <li>{t('deleteAccount.step2')}</li>
          <li>{t('deleteAccount.step3')}</li>
          <li>{t('deleteAccount.step4')}</li>
        </ol>

        <Title level={4} type="secondary">
          {t('deleteAccount.importantNotes')}
        </Title>

        <ul>
          <li>{t('deleteAccount.permanentDeletion')}</li>
          <li>{t('deleteAccount.reusingUsername')}</li>
        </ul>

        <Title level={4} type="secondary">
          {t('deleteAccount.weCannotDeleteYourAccount')}
        </Title>
        <Text>{t('deleteAccount.forSecurityReasons')}</Text>
      </div>
    </LoginLayout>
  )
}

export default observer(DeleteAccount)
