import { api, authApi } from 'config'
import { IPaginationParams } from './DTOs/common.dto'
import { ICreateEventPlaceDto, IGetEventPlacesParams, IUpdateEventPlaceDto } from './DTOs/eventPlace.dto'

import { AdminEditUserDto } from './DTOs/admin.dto'

import { ILoginActivity, ILoginActivityCount, IUserActivity } from 'types/Activity'
import { ICompanyProgramAdmin } from 'types/Admin'
import { ICompanyBrand } from 'types/Brand'
import {
  IAdminProgram,
  ICommonUser,
  IDashboardCompany,
  IDashboardProgramsWithModerators,
  ModeratorMainReceivingEnum,
} from 'types/Dashboard'
import { IUserInfo } from 'types/User'
import { IPagination, InviteSMSEnum, PaginationResponse } from 'types/common'
import {
  ICreateTagDto,
  IEditBrandDisplayOptionsDto,
  IEditCompanyDto,
  IEditPermissionsDto,
  IGetActivitiesAdminDto,
  IGetLoginActivityCountParams,
  IGetLoginActivityParams,
  IGetTagsParams,
  IIniteCompanyCSVDto,
  IInviteCompanyDto,
  ISendInviteToMentorDto,
  ISendInviteToTraineeDto,
  InviteModeratorDto,
} from './DTOs/admin.dto'

import { IEventPlace } from 'types/EventPlace'
import { IScrapeWebsite } from 'types/Scraping'
import { applicationBrand } from 'utils/brand/brandDetector'
import isStage from 'utils/isStage'
import parseCommonUsersData from 'utils/parseCommonUserData'

const endpoint = 'Admin/'

const adminApi = {
  async getTraineesAutocomplete({
    str,
    pageNumber,
    pageLength,
    traineesProgramIdsToRequest,
  }: {
    str: string
    pageNumber: number
    pageLength: number
    traineesProgramIdsToRequest: number[]
  }): Promise<{ userData: ICommonUser[]; paginationInfo: IPagination }> {
    let queryString = ''

    traineesProgramIdsToRequest?.forEach((id, idx) => {
      if (idx === 0) {
        queryString += '?programIds=' + id
      } else {
        queryString += '&programIds=' + id
      }
    })

    const { data } = await authApi.get(endpoint + 'traineesByName' + queryString, {
      params: {
        str,
        pageLength,
        pageNumber,
      },
    })

    const { items, ...paginationInfo } = data

    return { userData: parseCommonUsersData(items, true), paginationInfo }
  },
  async getTrainersAutocomplete({
    str,
    pageNumber,
    pageLength,
    traineesProgramIdsToRequest,
  }: {
    str: string
    pageNumber: number
    pageLength: number
    traineesProgramIdsToRequest: number[]
  }): Promise<{ userData: ICommonUser[]; paginationInfo: IPagination }> {
    let queryString = ''

    traineesProgramIdsToRequest?.forEach((id, idx) => {
      if (idx === 0) {
        queryString += '?programIds=' + id
      } else {
        queryString += '&programIds=' + id
      }
    })

    const { data } = await authApi.get(endpoint + 'trainersByName' + queryString, {
      params: {
        str,
        pageLength,
        pageNumber,
      },
    })

    const { items, ...paginationInfo } = data

    return { userData: parseCommonUsersData(items, false), paginationInfo }
  },
  async deleteUser(userId: number, isDeleted: boolean) {
    const response = await authApi.patch(endpoint + 'changeDeleteFlag/', null, {
      params: {
        userId,
        isDeleted: !isDeleted,
      },
    })

    return response
  },
  async deleteUserHard(userId: number) {
    const response = await authApi.patch(endpoint + 'deleteUser/' + userId, null, {
      params: {
        userId,
      },
    })

    return response
  },
  async unBlockUser(id: number): Promise<void | boolean> {
    await authApi.patch(endpoint + 'unBlockUser/' + id)

    return true
  },
  async blockUser({
    description,
    until,
    userId,
  }: {
    userId: string | number
    until: string | null
    description: string
  }): Promise<boolean> {
    await authApi.post(endpoint + 'blockUser', {
      userId,
      until: until ?? null,
      description,
    })

    return true
  },
  async giveTraineePermission(id: number): Promise<void> {
    await authApi.patch(endpoint + 'givePermissionToBecomeTrainer/' + id)
  },
  async setFixedOTP(userId: number, otp: string) {
    const res = await authApi.patch(endpoint + 'setFixedOTP', { userId, otp })

    return res.status
  },
  async removeFixedOTP(userId: number) {
    await authApi.patch(endpoint + 'removeFixedOTP', null, {
      params: {
        userId,
      },
    })
  },
  async getAllPrograms(str: string = '') {
    const response = await authApi.get<IAdminProgram[]>(endpoint + 'programsByName', { params: { str } })

    return response.data
  },
  async getImageById(id: number) {
    const response = await authApi.get(endpoint + 'fileByAdmin/' + id, {
      headers: {
        Accept: 'application/json, text/plain',
        Connection: 'keep-alive',
        'Access-Control-Expose-Headers': 'Content-Disposition',
        'Accept-Encoding': 'identity',
        'Transfer-Encoding': 'chunked',
      },
      responseType: 'blob',
    })

    return URL.createObjectURL(response.data)
  },
  async inviteTrainee(dto: ISendInviteToTraineeDto, inviteSms: InviteSMSEnum) {
    const response = await authApi.post<(string | null)[]>(endpoint + 'sendInviteToTrainee', dto, {
      params: { inviteSms },
    })

    return response.data
  },
  async inviteMentor(dto: ISendInviteToMentorDto) {
    const response = await authApi.post(endpoint + 'sendInviteToTrainer', dto)

    return response.data
  },
  async editUser(dto: AdminEditUserDto) {
    const result = await authApi.patch(endpoint + 'editUser', dto)

    return result.data
  },
  async inviteModerator(dto: InviteModeratorDto) {
    const response = await authApi.post(endpoint + 'inviteModerator', dto)

    return response.data
  },
  async changeLogs() {
    const response = await authApi.get(endpoint + 'changeLogs', {
      params: {
        pageLength: 999,
        pageNumber: 1,
      },
    })

    return response.data
  },
  async getCompaniesByName({ str, pageLength, pageNumber }: { str: string; pageLength: number; pageNumber: number }) {
    const response = await authApi.get<PaginationResponse<IDashboardCompany>>(endpoint + 'companiesByName', {
      params: {
        str,
        pageLength,
        pageNumber,
      },
    })

    return response.data
  },
  async inviteMentorsToCompany(mentors: { phoneNumber: string; email: string }[], companyId: number) {
    await authApi.post(endpoint + 'inviteMentorsToCompany', mentors, {
      params: { companyId },
    })
  },
  async checkCompanyName(str: string) {
    return await authApi.get(endpoint + 'checkCompanyName', {
      params: {
        str,
      },
    })
  },
  async editCompany(dto: IEditCompanyDto) {
    await authApi.patch(endpoint + 'editCompany/', dto)
  },
  async removeAllModeratosInProgram(id: number) {
    await authApi.patch(endpoint + 'removeAllModeratosInProgram/' + id)
  },
  async removeModeratorInProgram(moderatorId: number, programId: number) {
    await authApi.patch(
      endpoint + 'removeModeratorInProgram',
      {},
      {
        params: {
          moderatorId,
          programId,
        },
      }
    )
  },
  async deleteCompany(id: number) {
    await authApi.patch(endpoint + 'deleteCompany/' + id)
  },
  async inviteCompany(dto: IInviteCompanyDto) {
    const response = await authApi.post<{ id: number }>(endpoint + 'inviteCompany/', dto)

    return response.data
  },
  async inviteCompanyWithCSV(dto: IIniteCompanyCSVDto, formData: FormData) {
    await authApi.post(endpoint + 'inviteCompanyWithCSV/', formData, {
      params: {
        ...dto,
        trainees: undefined,
        applicationBrand,
      },
    })
  },
  async getProgramsWithModerator({
    str,
    pageLength,
    pageNumber,
  }: {
    str?: string
    pageLength?: number
    pageNumber?: number
  }) {
    const response = await authApi.get<PaginationResponse<IDashboardProgramsWithModerators>>(
      endpoint + 'getProgramsWithModerator/',
      {
        params: {
          str,
          pageLength,
          pageNumber,
        },
      }
    )

    return response.data
  },
  async uploadImagesByAdmin(formData: FormData) {
    const res = await authApi.post(endpoint + 'UploadImagesByAdmin', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return res
  },
  async getModeratorSettings(moderatorId: number) {
    const res = await authApi.get(endpoint + 'moderatorSetting/' + moderatorId)

    return res.data
  },
  async setModeratorSettings(moderatorId: number, notificationType: ModeratorMainReceivingEnum) {
    const res = await authApi.put(`${endpoint}moderatorSetting/${moderatorId}`, {
      notificationType,
    })

    return res.data
  },
  async getFile(id: number, onDownloadProgress: (progressEvent: any) => void) {
    const response = await authApi.get(endpoint + 'fileByAdmin/' + id, {
      headers: {
        Accept: 'application/json, text/plain',
        Connection: 'keep-alive',
        'Access-Control-Expose-Headers': 'Content-Disposition',
        'Accept-Encoding': 'identity',
        'Transfer-Encoding': 'chunked',
      },
      onDownloadProgress,
      responseType: 'blob',
    })

    return response
  },
  async authenticate(login: string, password: string) {
    const response = await api.post(endpoint + 'authenticate', {
      login,
      password,
    })

    authApi.defaults.headers.common.Authorization = 'Bearer ' + response.data

    return response.data
  },
  async editPermissions(dto: IEditPermissionsDto) {
    const response = await authApi.patch(endpoint + 'editPermissions', dto)
    return response.data
  },
  async getUserInfo(phoneNumber: string) {
    const response = await authApi.get<IUserInfo>(endpoint + 'userInfo', { params: { phoneNumber } })
    return response.data
  },
  async getActivity(params: IGetActivitiesAdminDto) {
    const response = await authApi.get<PaginationResponse<IUserActivity>>(endpoint + 'activities', { params })
    return response.data
  },
  async getCompanyBrands() {
    const response = await authApi.get<ICompanyBrand[]>(endpoint + 'brands')
    return response.data
  },
  async getProgramsByCompanyId(companyId: number) {
    const response = await authApi.get<ICompanyProgramAdmin[]>(endpoint + 'programsByCompanyId/' + companyId)
    return response.data
  },
  async exportActivities(params: IGetActivitiesAdminDto) {
    const response = await authApi.get(endpoint + 'exportActivities', { params, responseType: 'blob' })
    return response.data
  },
  async getLoginActivityCount(params: IGetLoginActivityCountParams) {
    const response = await authApi.get<ILoginActivityCount>(endpoint + 'loginActivitiesCount', { params })
    return response.data
  },
  async getLoginActivity(params: IGetLoginActivityParams) {
    const response = await authApi.get<PaginationResponse<ILoginActivity>>(endpoint + 'loginActivities', { params })
    return response.data
  },
  async editBrandDisplayOptions(dto: IEditBrandDisplayOptionsDto) {
    await authApi.patch(endpoint + 'editBrandDisplayOptions', dto)
  },
  async getEventPlaces(params: IGetEventPlacesParams) {
    const response = await authApi.get<PaginationResponse<IEventPlace>>(endpoint + 'eventPlaces', {
      params,
    })

    return response.data
  },
  async deleteEventPlace(id: number) {
    const response = await authApi.delete(endpoint + 'eventPlace', {
      data: {
        id,
      },
    })

    return response.data
  },
  async createEventPlace(dto: ICreateEventPlaceDto) {
    const response = await authApi.post(endpoint + 'eventPlace', dto)
    return response.data
  },
  async updateEventPlace(dto: IUpdateEventPlaceDto) {
    const response = await authApi.patch(endpoint + 'eventPlace', dto)
    return response.data
  },
  async getGeolocation(address: string) {
    const response = await authApi.get<{
      latitude: number
      longitude: number
    }>(endpoint + 'geocoding', { params: { address } })
    return response.data
  },
  async getTags(params: IGetTagsParams) {
    const response = await authApi.get(endpoint + 'tag', { params })
    return response.data
  },
  async createTag(dto: ICreateTagDto) {
    const response = await authApi.post(endpoint + 'tag', dto)
    return response.data
  },
  async scraperAuth(credentials: { login: string; password: string }) {
    const response = await authApi.post<string>(
      isStage
        ? 'https://sync-staging.owlflow.co/api/Authenticate/admin'
        : 'https://sync.owlflow.co/api/Authenticate/admin',
      credentials
    )
    return response.data
  },
  async getScraperWebsites(params: IPaginationParams & { token: string }) {
    const { token, ...rest } = params
    const response = await authApi.get<PaginationResponse<IScrapeWebsite>>(
      isStage ? 'https://sync-staging.owlflow.co/api/Website/short' : 'https://sync.owlflow.co/api/Website/short',
      {
        params: rest,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  },
}

export default adminApi
