import { Dispatch, SetStateAction } from 'react'

import { schedulerSelectOptionEnum } from 'types/Template'
import { RepeatingTypeEnum } from 'types/Template/Tools'

interface IProps {
  onSave: (planningValue?: RepeatingTypeEnum) => Promise<void>
  setIsSchedulerModalVisible: Dispatch<SetStateAction<boolean>>
}

const useSaveToolWithPlanning = ({ onSave, setIsSchedulerModalVisible }: IProps) => {
  const onSaveWithPlanning = async (value: schedulerSelectOptionEnum) => {
    switch (value) {
      case schedulerSelectOptionEnum.ONLY_THIS:
        await onSave(RepeatingTypeEnum.ONLY_THIS)
        break

      case schedulerSelectOptionEnum.THIS_AND_SUBSEQUENT:
        await onSave(RepeatingTypeEnum.THIS_AND_SUBSEQUENT)
        break

      case schedulerSelectOptionEnum.ALL:
        await onSave(RepeatingTypeEnum.ALL)
        break
    }

    setIsSchedulerModalVisible(false)
  }

  return { onSaveWithPlanning }
}

export default useSaveToolWithPlanning
