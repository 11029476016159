import { useEffect, useState } from 'react'

import dateTime, { defaultReportDate } from 'utils/dateTime'

import { IProgramList } from 'types/Program'

import { ICalendarDateRange } from './useReportFilters'

interface IProps {
  currentProgram: IProgramList | undefined
}

const useReportDateRange = ({ currentProgram }: IProps) => {
  const [dateRange, setDateRange] = useState<ICalendarDateRange>(defaultReportDate)

  useEffect(() => {
    if (!currentProgram) {
      setDateRange(defaultReportDate)
      return
    }

    const { endDate, startDate } = currentProgram

    const initialDay = new URLSearchParams(window.location.search).get('day')

    if (initialDay) {
      setDateRange({
        startDate: initialDay ? new Date(initialDay) : new Date(),
        endDate: initialDay ? new Date(initialDay) : new Date(),
        key: 'selection',
      })
      return
    }

    const date = dateTime.calcDefaultReportRange(endDate, startDate)

    setDateRange(date)
  }, [currentProgram])

  return { dateRange, setDateRange }
}

export default useReportDateRange
