import { observer } from 'mobx-react-lite'
import React, { Suspense, useEffect, useState } from 'react'

import { useStore } from 'stores'

import EnterOPT from './EnterOPT'
import EnterPhone from './EnterPhone'

import LoginLayout from 'layouts/LoginLayout'

const Blocked = React.lazy(() => import('./Banned'))

const SignIn = () => {
  const { userStore } = useStore()

  const [isContinued, setIsContinued] = useState(false)

  const [isBlocked, setIsBlocked] = useState(false)

  const [phoneNumber, setPhoneNumber] = useState('')

  const handleContinue = (phoneNumber: string) => {
    setIsContinued(true)
    setPhoneNumber(phoneNumber)
  }

  useEffect(() => {
    const container = document.getElementById('imageContainer')

    if (!container) return

    if (userStore.backgroundImg) {
      container.style.backgroundImage = `url(${userStore.backgroundImg})`
    } else {
      container.style.backgroundImage = ''
    }
  }, [userStore.backgroundImg])

  return (
    <LoginLayout>
      <Suspense fallback={<></>}>
        {isBlocked ? (
          <Blocked
            onSubmit={() => {
              setIsContinued(false)
              setIsBlocked(false)
            }}
          />
        ) : !isContinued ? (
          <EnterPhone handleContinue={handleContinue} />
        ) : (
          <EnterOPT setIsContinued={setIsContinued} phoneNumber={phoneNumber} setIsBlocked={setIsBlocked} />
        )}
      </Suspense>
    </LoginLayout>
  )
}
export default observer(SignIn)
