import Loader from 'components/Loader'

import styles from './styles.module.scss'

const PageLoader = () => {
  return (
    <div className={styles.container}>
      <Loader />
    </div>
  )
}

export default PageLoader
