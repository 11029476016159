import React, { ReactNode } from 'react'
interface IProps {
  title: string
  required?: boolean
  children: ReactNode
}
const Constructor = ({ title, required, children }: IProps) => {
  return (
    <div>
      <p>
        {title}
        {required && '*'}
      </p>
      {children}
    </div>
  )
}

export default Constructor
