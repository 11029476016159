import { useQueryClient } from '@tanstack/react-query'
import { chunk } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import templatesApi from 'api/templates.api'
import wallPostApi from 'api/wallPost.api'

import useUnboundStore from 'hooks/useUnboundStore'

import { useStore } from 'stores'

import { ICreateWallPostEntityDto, IUpdateWallPostEntityDto } from 'api/DTOs/wallPost.dto'
import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { IDayData, WallMode, planningToolsType } from 'types/Template'
import { ITemplateDayAPI_V2, IWallPostDayAPI } from 'types/Template/TemplateToServer'
import { ISummaryDayItem, RepeatingTypeEnum } from 'types/Template/Tools'
import message, { someError } from 'utils/message'
import { templateParserToServer } from 'utils/parseTemplate'

interface IProps {
  entityTagsToRemove: number[]
  isUnbound?: boolean
  dayId: number
}

const isWallPostDayIncludesItem = (day: ICreateWallPostEntityDto) => {
  return (
    day.programWallPostSummary ||
    day.programWallPostStoredFiles.length ||
    day.questionnaires.length ||
    day.programWallPostLinks.length ||
    day.scenarios?.length
  )
}

const isTemplateDayIncludesItem = (day: ITemplateDayAPI_V2) => {
  return (
    day.summaries.length ||
    day.files.length ||
    day.questionnaires.length ||
    day.templateEntityLinks.length ||
    day.scenarios.length
  )
}

const useSaveTool = ({ entityTagsToRemove, isUnbound, dayId }: IProps) => {
  const { templateStore, wallPostStore } = useStore()
  const { templateEntity } = templateStore

  const queryClient = useQueryClient()

  const { unboundStore } = useUnboundStore()

  const [savingLoading, setSavingLoading] = useState(false)

  const [searchParams] = useSearchParams()

  const isNewsMode = searchParams.get('mode') === WallMode.NEWS

  const { t } = useTranslation()

  const deleteTags = async () => {
    const tagIdsToRemoveChunks = chunk(entityTagsToRemove, 10)

    for (let i = 0; i < tagIdsToRemoveChunks.length; i++) {
      const chunk = tagIdsToRemoveChunks[i]
      await Promise.all(
        chunk.map(id =>
          templateEntity.isWallPosts ? wallPostApi.deletePostEntityTag(id) : templatesApi.deleteTemplateEntityTag(id)
        )
      )
    }
  }

  const onSaveAsWallPost = async (dayData: IWallPostDayAPI, repeatingOption?: RepeatingTypeEnum) => {
    if (!templateEntity.programId || !templateEntity.templateInfo) return

    const toCreate = {
      id: dayData.id,
      day: dayData.day,
      questionnaires: [],
      programWallPostStoredFiles: [],
      programWallPostLinks: [],
      programWallPostSummary: null,
      scenarios: [],
      trainingProgramId: templateEntity.programId,
    } as ICreateWallPostEntityDto

    const toUpdate = {
      day: dayData.day,
      id: dayData.id,
      questionnaires: [],
      programWallPostStoredFiles: [],
      programWallPostLinks: [],
      programWallPostSummary: null,
      scenarios: [],
      trainingProgramId: templateEntity.programId,
    } as IUpdateWallPostEntityDto

    dayData.questionnaires.forEach(questionnaire => {
      if (questionnaire.id === -1) {
        toCreate.questionnaires.push(questionnaire)
      } else {
        toUpdate.questionnaires.push(questionnaire)
      }
    })

    dayData.programWallPostStoredFiles.forEach(file => {
      if (file.id === -1) {
        toCreate.programWallPostStoredFiles.push(file)
      } else {
        toUpdate.programWallPostStoredFiles.push(file)
      }
    })

    dayData.programWallPostLinks.forEach(link => {
      if (link.id === -1) {
        toCreate.programWallPostLinks.push(link)
      } else {
        toUpdate.programWallPostLinks.push(link)
      }
    })

    dayData.programWallPostSummaries.forEach(summary => {
      if (summary.id === -1) {
        toCreate.programWallPostSummary = summary
      } else {
        toUpdate.programWallPostSummary = summary
      }
    })

    dayData.scenarios.forEach(scenario => {
      if (scenario.id === -1) {
        toCreate.scenarios = [scenario]
      } else {
        toUpdate.scenarios = [scenario]
      }
    })

    if (isNewsMode) {
      if (toCreate.programWallPostSummary) {
        toCreate.id = toCreate.programWallPostSummary?.programWallPostId || toCreate.id
      } else if (toUpdate.programWallPostSummary) {
        toUpdate.id = toUpdate.programWallPostSummary?.programWallPostId || toUpdate.id
      }
    }

    if (isWallPostDayIncludesItem(toCreate)) {
      await wallPostApi.createEntities(toCreate)
    }

    if (isWallPostDayIncludesItem(toUpdate)) {
      await wallPostApi.updateEntities(toUpdate, repeatingOption)
    }

    wallPostStore.followUpStore.setAvailableContentForFollowUpBound({
      id: +templateEntity.programId,
      isWallPost: true,
      skipCache: true,
    })
  }

  const onSaveAsTemplate = async (dayData: ITemplateDayAPI_V2, repeatingOption?: RepeatingTypeEnum) => {
    if (!templateEntity.templateInfo) return

    const toCreate = {
      id: dayData.id,
      day: dayData.day,
      questionnaires: [],
      files: [],
      templateEntityLinks: [],
      summaries: [],
      scenarios: [],
    } as ITemplateDayAPI_V2

    const toUpdate = {
      day: dayData.day,
      id: dayData.id,
      questionnaires: [],
      files: [],
      templateEntityLinks: [],
      summaries: [],
      scenarios: [],
    } as ITemplateDayAPI_V2

    dayData.questionnaires.forEach(questionnaire => {
      if (questionnaire.id === -1) {
        toCreate.questionnaires.push(questionnaire)
      } else {
        toUpdate.questionnaires.push(questionnaire)
      }
    })

    dayData.files.forEach(file => {
      if (file.id === -1) {
        toCreate.files.push(file)
      } else {
        toUpdate.files.push(file)
      }
    })

    dayData.templateEntityLinks.forEach(link => {
      if (link.id === -1) {
        toCreate.templateEntityLinks.push(link)
      } else {
        toUpdate.templateEntityLinks.push(link)
      }
    })

    dayData.summaries.forEach(summary => {
      if (summary.id === -1) {
        toCreate.summaries.push(summary)
      } else {
        toUpdate.summaries.push(summary)
      }
    })

    dayData.scenarios.forEach(scenario => {
      if (scenario.id === -1) {
        toCreate.scenarios.push(scenario)
      } else {
        toUpdate.scenarios.push(scenario)
      }
    })

    if (isTemplateDayIncludesItem(toCreate)) {
      await templatesApi.createEntities({ entity: toCreate, templateId: templateEntity.templateInfo.id })
    }

    if (isTemplateDayIncludesItem(toUpdate)) {
      await templatesApi.updateEntities(
        { entity: toUpdate, templateId: templateEntity.templateInfo.id },
        repeatingOption
      )
    }

    templateStore.followUpStore.setAvailableContentForFollowUpBound({
      id: +templateEntity.templateInfo.id,
      isWallPost: false,
      skipCache: true,
    })
  }

  const onSaveAsUnboundContent = async (tool: planningToolsType) => {
    const day = templateEntity.currentDay

    if (!day) return

    if (unboundStore.unboundEntitiesToDelete?.some(item => item.id.length)) {
      if (templateEntity.isWallPosts) {
        await wallPostApi.deleteEntities(unboundStore.unboundEntitiesToDelete)
      } else {
        await templatesApi.deleteEntities(unboundStore.unboundEntitiesToDelete)
      }
    }

    if (templateEntity.programId) {
      await wallPostStore.postUnboundContentAPI(
        [{ ...day, id: dayId, dayItems: [tool] } as IDayData],
        templateEntity.programId
      )
    } else if (templateEntity.templateInfo?.id) {
      await templateStore.postUnboundContentAPI(
        [{ ...day, id: dayId, dayItems: [tool] } as IDayData],
        templateEntity.templateInfo.id
      )
    }

    message.success(t('templateCreation.unboundContentSaved'))
  }

  const onAfterSavePostInNewsMode = async (eventDayToModify: string | null, programId: number) => {
    const events = await templateStore.getProgramNewsDays(programId)

    let dayIndexToReload = templateEntity.currentDayIndex

    if (events && eventDayToModify) {
      const idx = events.findIndex(event => event === eventDayToModify.split('T')[0] + 'T00:00:00')
      if (idx !== -1) dayIndexToReload = idx
    }

    await templateStore.getWallNews({
      programId: programId.toString(),
      dayIndex: dayIndexToReload,
      skipLoading: true,
    })
  }

  const onSave = async ({
    tool,
    onDeleteEntities,
    repeatingOption,
    preventRefetch,
  }: {
    tool: planningToolsType
    onDeleteEntities?: (repeatingOption?: RepeatingTypeEnum) => Promise<void>
    repeatingOption?: RepeatingTypeEnum
    preventRefetch?: boolean
  }) => {
    try {
      setSavingLoading(true)

      if (onDeleteEntities) {
        await onDeleteEntities(repeatingOption)
      }

      if (isUnbound) {
        await onSaveAsUnboundContent(tool)
        return
      }

      const eventDayToModify = (tool as ISummaryDayItem)?.eventDay

      const day = templateEntity.currentDay

      const isWallPost = !!templateEntity.programId

      const parsedTool = templateParserToServer
        .setTemplateData(
          //@ts-ignore
          [{ ...day, id: isWallPost ? tool.programWallPostId || day?.id : day?.id, dayItems: [tool] } as IDayData],
          templateEntity.programId || void 0,
          false,
          isNewsMode
        )
        .run()

      const parsedDay = parsedTool[0]

      if (!parsedDay) return

      await deleteTags()

      if (templateEntity.isWallPosts && templateEntity.programId) {
        await onSaveAsWallPost(parsedDay as unknown as IWallPostDayAPI, repeatingOption)

        if (preventRefetch) return

        if (isNewsMode) {
          await onAfterSavePostInNewsMode(eventDayToModify, templateEntity.programId)
        } else {
          if (templateStore.templateEntity.programId) {
            templateStore.setProgramDaysAPI(templateStore.templateEntity.programId.toString())
          }

          templateStore.getProgramNewsDays(templateEntity.programId)
          await templateStore.getWallPostById({
            programId: templateEntity.programId.toString(),
            dayIndex: templateEntity.currentDayIndex,
            skipLoading: true,
          })
        }
        queryClient.invalidateQueries([QueryKeysEnum.WALL_SEARCHED_CONTENT])
      } else if (templateEntity.templateInfo?.id) {
        await onSaveAsTemplate(parsedDay as unknown as ITemplateDayAPI_V2, repeatingOption)

        if (templateEntity.templateInfo) {
          await templateStore.getTemplateEntitiesDay({
            id: templateEntity.templateInfo.id,
            dayIndex: templateEntity.currentDayIndex,
            skipLoading: true,
          })
        }
      } else {
        throw new Error('Fail saving tool -> not template details is found')
      }

      templateEntity.updateDayKey()

      message.success(t('templateCreation.toolSaved'))
    } catch (e) {
      console.error(e)
      someError()
    } finally {
      setSavingLoading(false)
    }
  }

  return { onSave, savingLoading }
}

export default useSaveTool
