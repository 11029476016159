import { useStore } from 'stores'

import useImageQuery from './tanstack/queries/useImageQuery'

interface IProps {
  id: number
}

const useProgramImgInterceptor = ({ id }: IProps) => {
  const { userStore, programStore } = useStore()

  const { image, isLoading } = useImageQuery({
    id: (programStore.defaultProgramAvatarId === id || id === 1) && userStore.brandIconId ? userStore.brandIconId : id,
  })

  return {
    image,
    isLoading,
  }
}

export default useProgramImgInterceptor
