import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Form, Tooltip } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload'
import { observer } from 'mobx-react-lite'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import verificationApi from 'api/verification.api'

import useImageQuery from 'hooks/tanstack/queries/useImageQuery'

import { useStore } from 'stores'

import { imageMimeTypes } from 'utils/const'
import customConfirm from 'utils/customConfirm'
import message from 'utils/message'
import { emailValidator } from 'utils/validators/email.validator'

import AvatarComponent from './Avatar'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomFormItem from 'components/CustomComponents/CustomFormItem'
import CustomInput from 'components/CustomComponents/CustomInput'

import styles from './styles.module.scss'

export interface IPersonalInformationForm {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

interface IProps {
  form: FormInstance<IPersonalInformationForm>
  title?: string
  setLoading: (status: boolean) => void
  children?: ReactNode
}

const PersonalInformationForm: FC<IProps> = ({ children, form, title, setLoading }) => {
  const { userStore, templateStore } = useStore()

  const { image: avatar, isLoading: avatarLoading } = useImageQuery({ id: userStore.user?.avatarId })

  const [userAvatar, setUserAvatar] = useState('')
  const [chosenAvatarFile, setChosenAvatarFile] = useState<UploadFile | null>(null)

  const [isEmailChanged, setIsEmailChanged] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (userStore.user) {
      const { email, firstName, lastName, phoneNumber } = userStore.user

      form.setFieldsValue({
        email,
        firstName,
        lastName,
        phoneNumber,
      })
    }
  }, [userStore.user])

  useEffect(() => {
    if (avatar) {
      setUserAvatar(avatar)
    }
  }, [avatar])

  const onChangeAvatar = ({ file }: UploadChangeParam<UploadFile<any>>) => {
    if (file.type && !imageMimeTypes.includes(file.type)) {
      message.error(t('profile.userFileUploadError'))
      return
    }

    setChosenAvatarFile(file)
    setUserAvatar(URL.createObjectURL(file as unknown as File))
  }

  const onDeleteAvatar = () => {
    setUserAvatar('')
    setChosenAvatarFile(null)
  }

  const resendEmailVerification = async () => {
    try {
      if (!userStore.user?.email) return

      setResendLoading(true)

      await verificationApi.resend(userStore.user.email)

      message.success(t('verification.resentEmail'))
    } catch (e) {
    } finally {
      setResendLoading(false)
    }
  }

  const onFinish = async (values: IPersonalInformationForm) => {
    try {
      setLoading(true)

      let avatarId: number | null = null

      if (chosenAvatarFile) {
        const formData = new FormData()

        formData.append('pictures', chosenAvatarFile as unknown as File)

        const fileUploadResponse = await templateStore.uploadFiles(formData)

        if (fileUploadResponse) {
          avatarId = fileUploadResponse[0]
        }
      }

      const { email, firstName, lastName, phoneNumber } = values

      await userStore.updateUserInfo(
        {
          email: email?.trim(),
          firstName: firstName?.trim(),
          phoneNumber: phoneNumber?.trim(),
          lastName: lastName?.trim(),
          avatarId: (avatarId ? avatarId : !userAvatar ? 1 : userStore.user?.avatarId) || 1,
        },
        chosenAvatarFile ? URL.createObjectURL(chosenAvatarFile as unknown as File) : userAvatar
      )
      userStore.setUserDataAPI()
      userStore.setIsNewUserFlag(false)
      setIsEmailChanged(false)
      message.success(t('profile.basicInfoUpdateSuccess'))
    } catch (e) {
      if (e?.response?.data?.Message?.includes('email already exist')) {
        message.error(t('profile.EmailExist'))
      } else {
        message.error(t('programCreation.smtWrong'))
      }
    } finally {
      setLoading(false)
    }
  }

  const onFinishWithEmailConfirm = (values: IPersonalInformationForm) => {
    customConfirm({
      title: t(!userStore.user?.email ? 'verification.emailConfirm' : 'verification.emailConfirmEdit', {
        email: values.email,
      }),
      icon: <ExclamationCircleOutlined />,
      onOk: () => onFinish(values),
    })
  }

  return (
    <div className={styles.block}>
      {!!title && <h2 className={styles.title}>{title}</h2>}

      <div className={styles.content}>
        <AvatarComponent
          loading={avatarLoading}
          onChange={onChangeAvatar}
          onDelete={onDeleteAvatar}
          userAvatar={userAvatar}
        />

        <Form
          onFieldsChange={(fields, allFields) => {
            const emailField = fields.find(field => field.name.toString().includes('email'))

            if (emailField) {
              setIsEmailChanged(emailField.value !== userStore.user?.email)
            } else {
              setIsEmailChanged(false)
            }
          }}
          form={form}
          onFinish={values => (isEmailChanged ? onFinishWithEmailConfirm(values) : onFinish(values))}
          className={styles.form}
        >
          <div className={styles.personalInformation}>
            <div className={styles.topInputs}>
              <CustomFormItem
                name="firstName"
                rules={[{ required: true, message: t('programCreation.required'), whitespace: true }]}
                className={styles.inputDouble}
              >
                <CustomInput label={t('dashboard.firstName')} labelType="shadow" isErrorNeeded={false} />
              </CustomFormItem>

              <CustomFormItem
                name="lastName"
                rules={[{ required: true, message: t('programCreation.required'), whitespace: true }]}
                className={styles.inputDouble}
              >
                <CustomInput label={t('dashboard.lastName')} labelType="shadow" isErrorNeeded={false} />
              </CustomFormItem>
            </div>

            <CustomFormItem name="email" rules={[{ validator: emailValidator({ required: false }) }]}>
              <CustomInput
                label={t('dashboard.email')}
                labelType="shadow"
                isErrorNeeded={false}
                prefix={
                  isEmailChanged || !userStore.user?.email ? (
                    <></>
                  ) : userStore.user?.isVerified ? (
                    <Tooltip title={t('verification.verifiedLabel')}>
                      <CheckCircleOutlined style={{ color: 'green' }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('verification.waitingForVerifying')}>
                      <ExclamationCircleOutlined style={{ color: 'orange' }} />
                    </Tooltip>
                  )
                }
              />
            </CustomFormItem>
            {!isEmailChanged && !!userStore.user?.email && (
              <CustomFormItem>
                <CustomButton type="link" onClick={resendEmailVerification} loading={resendLoading}>
                  {t('verification.resendEmail')}
                </CustomButton>
              </CustomFormItem>
            )}

            {!userStore.isForAnonSubdomain && (
              <CustomFormItem name="phoneNumber">
                <CustomInput label={t('dashboard.phoneNumber')} labelType="shadow" disabled={true} />
              </CustomFormItem>
            )}
          </div>
        </Form>
      </div>
      {children}
    </div>
  )
}

export default observer(PersonalInformationForm)
