import { Table } from 'antd'
import { TableProps } from 'antd/es/table'
import classNames from 'classnames'

import './table.override.scss'

interface IProps extends TableProps<any> {
  className?: string
  variant?: 'default' | 'grey-rows' | 'expandable' | 'invites' | 'clear' | 'transparent'
}

const CustomTable = (props: IProps) => {
  const { className, variant = 'default', ...rest } = props

  return (
    <Table
      {...rest}
      className={classNames(
        variant === 'clear'
          ? {}
          : {
              ['default_users_table']: variant === 'default',
              ['default_users_table grey-rows']: variant === 'grey-rows',
              ['default_users_table resetExpandableRow table-sort-reverse']: variant === 'expandable',
              ['inviteTraineesTable']: variant === 'invites',
              ['transparent-table']: variant === 'transparent',
            },
        props.className
      )}
    />
  )
}

export default CustomTable
