import { Steps } from 'antd'
import Title from 'antd/lib/typography/Title'
import cn from 'classnames'
import { t } from 'i18next'
import React from 'react'

import useIsRtl from 'hooks/useIsRtl'

import { ProgramCreationStepsEnum } from 'types/Program'

import BackLink from 'components/BackLink'

import styles from './styles.module.scss'

interface IProps {
  currentStep: ProgramCreationStepsEnum
  onStepChange: (newStep: number) => Promise<void>
  selectedTemplateId: number | null
}

const HeadContent: React.FC<IProps> = ({ currentStep, onStepChange, selectedTemplateId }) => {
  const isRtl = useIsRtl()

  return (
    <div style={{ marginTop: 10, marginBottom: 32 }}>
      <BackLink backRoute="/programs" data-program-creation-back="program-creation-back" />
      <Title level={2} style={{ fontWeight: 700, marginTop: 10 }}>
        {t<string>('programList.newProgram')}
      </Title>
      <div>
        <Steps current={currentStep} onChange={onStepChange} labelPlacement="vertical">
          <Steps.Step
            title={t<string>('programCreation.programDetails')}
            className={cn(styles.step, {
              [styles.nextStep]: currentStep > ProgramCreationStepsEnum.DETAILS,
              [styles.currentStep]: currentStep === ProgramCreationStepsEnum.DETAILS,
              [styles.stepRtl]: isRtl,
            })}
          />
          <Steps.Step
            title={t<string>('programCreation.setupTemplate')}
            className={cn(styles.step, {
              [styles.nextStep]: currentStep > ProgramCreationStepsEnum.TEMPLATE,
              [styles.currentStep]: currentStep === ProgramCreationStepsEnum.TEMPLATE,
              [styles.stepRtl]: isRtl,
            })}
          />
          <Steps.Step
            title={t<string>('programCreation.inviteTrainees')}
            className={cn(styles.step, {
              [styles.nextStep]: currentStep > ProgramCreationStepsEnum.INVITES,
              [styles.currentStep]: currentStep === ProgramCreationStepsEnum.INVITES,
              [styles.stepRtl]: isRtl,
            })}
            disabled={!selectedTemplateId}
          />
          <Steps.Step
            title={t<string>('programCreation.previewConfirm')}
            className={cn(styles.step, {
              [styles.nextStep]: currentStep > ProgramCreationStepsEnum.PREVIEW,
              [styles.currentStep]: currentStep === ProgramCreationStepsEnum.PREVIEW,
              [styles.stepRtl]: isRtl,
            })}
            disabled={!selectedTemplateId}
          />
        </Steps>
      </div>
    </div>
  )
}

export default HeadContent
