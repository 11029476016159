import { Button, Card, Form, Typography } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import userApi from 'api/user.api'

import { useStore } from 'stores'

import { IPhoneEnterForm } from 'types/forms/SignIn'

import LS, { LSKeys } from 'utils/LS'
import message, { someError } from 'utils/message'

import AimpactHeader from 'components/AimpactHeader'
import PhoneNumberField from 'components/PhoneNumberField'

import 'react-phone-number-input/style.css'

import styles from './styles.module.scss'

const { Title } = Typography

interface IProps {
  handleContinue: (phoneNumber: string) => void
  dark?: boolean
}

const EnterPhone = ({ handleContinue, dark }: IProps) => {
  const { userStore } = useStore()

  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    message.destroy()
  }, [userStore.language])

  const onFinish = async (formFields: IPhoneEnterForm) => {
    try {
      setLoading(true)

      const phone = '+' + formFields.phoneNumber.replaceAll('+', '').trim()

      const haveMentorPermission = await userApi.getUserPermission(phone)

      if (!haveMentorPermission) {
        form.setFields([
          {
            name: 'phoneNumber',
            errors: [t('login.noMentorPermission')],
          },
        ])

        message.error(t('login.noMentorPermission'))

        return
      }

      const { statusCode, message: resMessage } = await userStore.sendSMS(phone)

      if (!statusCode) {
        someError()
      } else if (resMessage?.includes('User does not have this brand')) {
        message.error(t('login.loginToWrongBrand'))
      } else if (statusCode < 300) {
        handleContinue(formFields.phoneNumber)
      } else if (statusCode === 400) {
        message.error(t('shared.messageBirdError'))
      } else if (statusCode === 404) {
        message.error(t('login.phoneIncorrect'))
        form.setFields([
          {
            name: 'phoneNumber',
            errors: [t('login.phoneIncorrect')],
          },
        ])
      } else if (statusCode === 403) {
        form.setFields([
          {
            name: 'phoneNumber',
            errors: [t('general.maintenanceErrorText')],
          },
        ])
      } else if (statusCode >= 400) {
        message.error(t('programCreation.smtWrong'))
      } else if (statusCode === 429) {
        message.error(t('login.rateLimit'))
      } else {
        someError()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card className={styles.card} data-card="card">
      <AimpactHeader dark={dark} />

      <Title className={classNames(styles.title, { [styles.titleDark]: !!dark })} level={2} data-title-2="title-2">
        {t('login.welcome', {
          name: userStore.brandName ?? 'Aimpact',
        })}
      </Title>

      <Form
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout="vertical"
        className={styles.form}
        data-form="form"
      >
        <PhoneNumberField defaultValue={LS.get(LSKeys.PHONE)} form={form} customStyles={styles.phoneInput} noLabel />

        <Form.Item data-form-item="form-item">
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={loading}
            className={styles.btn}
            data-continue="continue"
            id="continue"
          >
            {t('login.continue')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default observer(EnterPhone)
