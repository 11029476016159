import { ExclamationCircleOutlined } from '@ant-design/icons'
import Title from 'antd/lib/typography/Title'
import cn from 'classnames'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FC, useEffect } from 'react'

import useDaysMoving from 'hooks/tools/useDaysMoving'

import { useStore } from 'stores'

import customConfirm from 'utils/customConfirm'
import dateTime from 'utils/dateTime'
import message from 'utils/message'

import { planningToolsType } from 'types/Template'

import CustomButton from 'components/CustomComponents/CustomButton'
import PageError from 'components/PageError'

import DayItem from '../DayItem'

import styles from './styles.module.scss'

let savedDeleteParams: IDeleteParams | null = null

interface IDeleteParams {
  onAfterDelete: (() => void) | undefined
  toolIndex: number
  item: planningToolsType
}

interface IProps {
  onDayDatePress: () => void
  isNewsMode?: boolean
}

const DayItemConfigHOC: FC<IProps> = ({ onDayDatePress, isNewsMode }) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const dayDate = templateEntity.currentDay?.dayDate

  const canChangeDate = dayDate && moment(dayDate).diff(moment(), 'h') >= 0

  const canSkipDay =
    !!templateEntity.templateInfo &&
    templateEntity.currentDayIndex !== 0 &&
    templateEntity.currentDayIndex < templateEntity.templateInfo.duration - 1

  const { incrementDay } = useDaysMoving({ isNewsMode })

  const handleSkipDayClick = async () => {
    if (!canSkipDay) return

    if (templateStore.isToolEditing) {
      message.error(t('templateCreation.previousEdit'))
      return
    }

    incrementDay()
  }

  const onDeleteDay = async () => {
    try {
      if (!templateEntity.templateInfo) return

      if (templateStore.isToolEditing) {
        message.error(t('templateCreation.previousEdit'))
        return
      }

      if (templateEntity.templateInfo.duration === 1) {
        message.error(t('templateCreation.oneDayError'))
        return
      }

      if (!templateEntity.currentDay?.id) throw new Error('Current day is not found')

      const newDayIdx =
        templateEntity.currentDayIndex >= templateEntity.templateInfo.duration - 1
          ? templateEntity.templateInfo.duration - 2
          : templateEntity.currentDayIndex

      await templateEntity.deleteDay(templateEntity.currentDay.id)

      if (templateEntity.isWallPosts && templateEntity.programId) {
        await templateStore.getWallPostById({
          dayIndex: newDayIdx,
          programId: templateEntity.programId.toString(),
        })
        templateStore.setProgramDaysAPI(templateEntity.programId.toString())
      } else {
        if (templateEntity.currentDay?.id) {
          await templateStore.getTemplateEntitiesDay({
            dayIndex: newDayIdx,
            id: templateEntity.templateInfo.id,
          })
        }
      }
    } catch (e) {
      message.error(t('programCreation.smtWrong'))
    }
  }

  const onDeleteDayWarning = () => {
    customConfirm({
      title: t<string>('templateCreation.deleteDay'),
      icon: <ExclamationCircleOutlined />,
      onOk: onDeleteDay,
    })
  }

  useEffect(() => {
    return () => {
      savedDeleteParams = null
    }
  }, [])

  if (!templateEntity.currentDay?.id && templateEntity.currentDay?.id !== 0) {
    return <PageError />
  }

  return (
    <div className={styles.container}>
      <DayItem
        key={templateEntity.dayKey}
        isNewsMode={isNewsMode}
        dayId={templateEntity.currentDay?.id}
        dayIndex={templateEntity.currentDayIndex}
        dayContent={templateEntity.currentDay?.dayItems || []}
        Header={
          <div className={styles.dayInfo} data-day-info="day-info">
            <div className={styles.titleWithButton}>
              <div>
                <Title level={5} data-day-number="dayNumber" className={styles.dayNumber}>
                  {t<string>('templateCreation.day')} {templateEntity.currentDayIndex + 1}
                </Title>
                {templateEntity.isWallPosts && (
                  <span
                    className={cn(styles.changeDate, {
                      [styles.changeDataDisable]: !canChangeDate,
                    })}
                    onClick={() => {
                      if (canChangeDate) {
                        onDayDatePress()
                      }
                    }}
                    data-change-date="changeDate"
                  >
                    {dayDate ? dateTime.getHebrewDate(dayDate) : ''}
                  </span>
                )}
              </div>
              {!isNewsMode && (
                <div className={styles.btns}>
                  {canSkipDay && (
                    <CustomButton type="link" onClick={handleSkipDayClick}>
                      {t<string>('templateCreation.skipDay')}
                    </CustomButton>
                  )}
                  {templateEntity.templateInfo?.duration !== 1 && (
                    <CustomButton type="link" onClick={onDeleteDayWarning}>
                      {t<string>('templateCreation.deleteThisDay')}
                    </CustomButton>
                  )}
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  )
}

export default observer(DayItemConfigHOC)
