import { SearchOutlined } from '@ant-design/icons'
import { Input, InputProps } from 'antd'
import cn from 'classnames'
import { FC } from 'react'

import styles from './styles.module.scss'

interface IProps extends InputProps {
  onChangeCustom: (str: string) => void
}

const SearchBar: FC<IProps> = ({ onChangeCustom, className, ...rest }) => {
  return (
    <Input
      {...rest}
      suffix={<SearchOutlined />}
      className={cn(styles.customInput, className)}
      onChange={e => onChangeCustom(e.target.value)}
    />
  )
}

export default SearchBar
