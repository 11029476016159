import { t } from 'i18next'
import { action, makeAutoObservable, observable } from 'mobx'
import message from 'utils/message'

import brandsApi from 'api/brands.api'
import companyApi from 'api/company.api'

import stores from 'stores'

import { IBrandInfo } from 'types/User'

class Store {
  @observable companySlug = ''
  @observable companyBrand: IBrandInfo | null = null

  constructor() {
    makeAutoObservable(this)
  }

  @action
  async createCompanyBrand(formFields: any) {
    try {
      const data = await brandsApi.createCompanyBrand(formFields)

      if (data.slug) {
        await stores.userStore.getUserBrandInfo(data.slug)
      }

      return true
    } catch (e) {
      const { StatusCode, Message } = e.response.data

      if (StatusCode >= 300) {
        const isAlreadyExist = Message.includes('Brand already exist')
        if (isAlreadyExist) {
          message.error(t<string>('companyPage.brandAlreadyExist'))
          return
        }

        const isSubdomainExist = Message.includes('Subdomain already exist')
        if (isSubdomainExist) {
          message.error(t<string>('companyPage.subdomainExist'))
          return
        }
      }

      message.error(t<string>('programCreation.smtWrong'))
    }
  }

  @action
  async getCompanyById(companyId: number) {
    try {
      const data = await companyApi.getCompanyById(companyId)

      if (data?.customBrandAllowed) {
        stores.userStore.brandingAllowed = true
      }

      const companyBrandSlug = data?.companyBrand?.slug || ''

      this.companySlug = companyBrandSlug

      if (!stores.userStore.brandData && data?.companyBrand?.slug) {
        stores.userStore.getUserBrandInfo(data.companyBrand.slug)
      }

      if (companyBrandSlug) {
        const brandData = await brandsApi.getUserBrandInfo(companyBrandSlug)

        if (!brandData) return

        this.companyBrand = brandData
      }
    } catch (e) {
      message.error(t<string>('programCreation.smtWrong'))
    }
  }
}

export default new Store()
