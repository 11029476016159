import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IDailyReport } from 'types/Program'

import dateTime from 'utils/dateTime'

import styles from './styles.module.scss'

interface IProps {
  item: IDailyReport
}

const ReportDayHeader: FC<IProps> = ({ item }) => {
  const { t } = useTranslation()

  const questionsNum = useMemo(() => {
    return item.dailyReportModelForUsers?.[0]?.questionnaireCount || 0
  }, [item.dailyReportModelForUsers])

  const trainees = useMemo(() => {
    return item.dailyReportModelForUsers.length || 0
  }, [item.dailyReportModelForUsers])

  return (
    <div className={styles.container}>
      <div className={styles.line}>
        <div className={styles.day}>
          {t<string>('templateCreation.day')} {item.day}
        </div>
        <div className={styles.details}>
          <div className={styles.detailsItem}>{dateTime.getHebrewDate(item.dateTime)}</div>
          <div className={styles.diviner} />
          <div className={styles.detailsItem}>
            {questionsNum} {t<string>('programList.questions')}
          </div>
          <div className={styles.diviner} />
          <div className={styles.detailsItem}>
            {trainees} {t<string>('dashboard.trainees')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(ReportDayHeader)
