import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import eventPlaceApi from 'api/eventPlace.api'
import { QueryKeysEnum } from 'types/QueryKeysEnum'
import message from 'utils/message'

const useEventPlacesShortQuery = () => {
  const { t } = useTranslation()

  const { data: places, isLoading } = useQuery({
    queryKey: [QueryKeysEnum.EVENT_PLACES_SHORT],
    queryFn: async () => {
      const response = await eventPlaceApi.getEventPlacesShort({
        pageNumber: 1,
        pageLength: 9999,
      })
      return response.items
    },
    onError: () => {
      message.error(t<string>('programCreation.smtWrong'))
    },
    initialData: [],
  })

  return {
    places,
    isLoading,
  }
}

export default useEventPlacesShortQuery
