import { EditOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Image, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IEditProgramDTO } from 'api/DTOs/programs.dto'
import fileApi from 'api/file.api'
import programsApi from 'api/programs.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import { imageMimeTypes, maxAvatarSize } from 'utils/const'
import message from 'utils/message'

import styles from '../styles.module.scss'

interface IProps {
  calcStyles: {
    width: number
    height: number
  }
  currentAvatar: string
  programDetails:
    | (Omit<IEditProgramDTO, 'trainingProgramAvatarId, groupChatAvatarId'> & {
        id: number
      })
    | undefined
}

const AvatarWithActions: FC<IProps> = ({ calcStyles, currentAvatar, programDetails }) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const [inPreview, setInPreview] = useState(false)
  const [uploadedUrl, setUploadedUrl] = useState('')

  const [loading, setLoading] = useState(false)

  const onEdit = async ({ fileList }: UploadChangeParam<UploadFile>) => {
    try {
      setLoading(true)
      if (fileList.length !== 1 || !programDetails) return

      const file = fileList[0].originFileObj as File

      if (!imageMimeTypes.includes(file.type)) return message.error(t<string>('programCreation.imgErrFileType'))
      if (file.size > maxAvatarSize) return message.error(t<string>('programCreation.imgErrFileSize'))

      setUploadedUrl(URL.createObjectURL(file))

      const { id, ...rest } = programDetails

      const formData = new FormData()

      formData.append('pictures', file as unknown as Blob)
      formData.append('imageSense', 'logo')

      const imageId = await fileApi.uploadImage(formData)

      await programsApi.updateProgram(id, { ...rest, trainingProgramAvatarId: imageId, groupChatAvatarId: imageId })
    } catch (e) {
      setUploadedUrl('')
    } finally {
      setLoading(false)
      queryClient.invalidateQueries({
        queryKey: [QueryKeysEnum.PROGRAM, programDetails?.uniqueId || programDetails?.id.toString()],
      })
      queryClient.invalidateQueries({ queryKey: [QueryKeysEnum.PROGRAMS] })
    }
  }

  if (loading) {
    return (
      <div className={styles.loader} style={calcStyles}>
        <LoadingOutlined style={{ color: '#333', fontSize: 40 }} />
      </div>
    )
  }

  return (
    <div className={styles.editable}>
      <Image
        src={uploadedUrl ? uploadedUrl : currentAvatar}
        className={styles.avatarImage}
        style={calcStyles}
        preview={{ visible: inPreview, onVisibleChange: setInPreview, mask: <></> }}
      />
      <div className={styles.hover}>
        <Upload maxCount={1} onChange={onEdit}>
          <div className={styles.item}>
            <EditOutlined /> {t<string>('programList.edit')}
          </div>
        </Upload>
        <div onClick={() => setInPreview(true)} className={styles.item}>
          <EyeOutlined /> {t<string>('programDetails.preview')}
        </div>
      </div>
    </div>
  )
}

export default AvatarWithActions
