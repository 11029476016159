import classNames from 'classnames'
import { FC } from 'react'

import CustomButton, { ICustomBtnProps } from 'components/CustomComponents/CustomButton'

import styles from './styles.module.scss'

interface IProps extends ICustomBtnProps {
  onClick: () => void
  danger?: boolean
  small?: boolean
  disabled?: boolean
}

const IconButton: FC<IProps> = ({ onClick, danger, small, children, disabled, ...rest }) => {
  return (
    <CustomButton
      className={classNames(styles.btn, {
        [styles.btnDanger]: danger,
        [styles.small]: small,
      })}
      onClick={onClick}
      disabled={disabled}
      buttonSize="large"
      {...rest}
    >
      {children}
    </CustomButton>
  )
}

export default IconButton
