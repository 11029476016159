import { Modal, ModalProps } from 'antd'
import classNames from 'classnames'
import React from 'react'

import styles from './styles.module.scss'

interface IProps extends ModalProps {}

const CustomModal: React.FC<IProps> = ({ className, ...rest }) => {
  return <Modal className={classNames(styles.customModal, className)} {...rest} />
}

export default CustomModal
