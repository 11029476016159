import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useDeleteTemplateMutation from 'hooks/tanstack/mutations/useDeleteTemplateMutation'

import { useStore } from 'stores'

import message from 'utils/message'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import EditTemplateNameModal from './EditTemplateNameModal'

import CopyTemplateModal from 'components/CopyTemplateModal'
import CustomDropdown from 'components/CustomComponents/CustomDropdown'
import CustomMenu from 'components/CustomComponents/CustomMenu'
import MenuItem from 'components/MenuItem'

const TemplateActions = () => {
  const { t } = useTranslation()

  const { userStore, templateStore } = useStore()
  const { templateEntity } = templateStore

  const [copyModalVisible, setCopyModalVisible] = useState(false)
  const [editNameModalVisible, setEditNameModalVisible] = useState(false)

  const queryClient = useQueryClient()

  const navigate = useNavigate()

  const onSuccess = () => {
    queryClient.removeQueries([QueryKeysEnum.TEMPLATES])
    navigate('/template')
  }

  const deleteTemplate = useDeleteTemplateMutation({ onSuccess })

  const menu = useMemo(() => {
    return (
      <CustomMenu
        items={[
          {
            key: '0',
            label: (
              <MenuItem
                label={t<string>('templateCreation.editTemplateName')}
                Icon={() => <EditOutlined width={15} />}
              />
            ),
            onClick: e => {
              e.domEvent.stopPropagation()

              setEditNameModalVisible(true)
            },
          },
          {
            key: '1',
            label: (
              <MenuItem label={t<string>('templateList.copyTemplateId')} Icon={() => <CopyOutlined width={15} />} />
            ),
            onClick: async e => {
              e.domEvent.stopPropagation()

              if (templateEntity.templateInfo && templateEntity.templateInfo.id) {
                await navigator.clipboard.writeText(templateEntity.templateInfo.id.toString())
                message.success(t('templateList.copiedId'))
              }
            },
          },
          {
            key: '2',
            label: <MenuItem label={t<string>('templateList.copyTemplate')} Icon={() => <CopyOutlined width={15} />} />,
            onClick: e => {
              e.domEvent.stopPropagation()
              setCopyModalVisible(true)
            },
          },
          {
            key: '3',
            label: (
              <MenuItem label={t<string>('templateList.deleteTemplate')} Icon={() => <DeleteOutlined width={15} />} />
            ),
            onClick: e => {
              e.domEvent.stopPropagation()
              if (templateEntity.templateInfo && templateEntity.templateInfo.id) {
                deleteTemplate(templateEntity.templateInfo.id)
              }
            },
          },
        ]}
      />
    )
  }, [userStore.language])

  if (!templateEntity.templateInfo?.id) return <></>

  return (
    <>
      <CustomDropdown overlay={menu} size={28} placement="bottom" />
      <CopyTemplateModal
        isModalVisible={copyModalVisible}
        setIsModalVisible={setCopyModalVisible}
        currentTemplateDropdownOpened={templateEntity.templateInfo.id}
        onSuccess={onSuccess}
      />
      <EditTemplateNameModal
        initialName={templateEntity.templateInfo.name}
        open={editNameModalVisible}
        onCancel={() => setEditNameModalVisible(false)}
      />
    </>
  )
}

export default TemplateActions
