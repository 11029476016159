import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import stores from 'stores'

import LS, { LSKeys } from 'utils/LS'

import translationEN from 'locales/EN/messages.json'
import translationHEFemale from 'locales/HE-female/messages.json'
import translationHE from 'locales/HE-male/messages.json'
import translationHESababa from 'locales/HE-sababa/messages.json'
import translationHEUndefined from 'locales/HE-undefined/messages.json'
import translationRU from 'locales/RU/messages.json'

const availableLanguages = ['en', 'ru', 'heMale', 'heFemale', 'heUndefined', 'heSababa']

const resources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
  heMale: {
    translation: translationHE,
  },
  heFemale: {
    translation: translationHEFemale,
  },
  heUndefined: {
    translation: translationHEUndefined,
  },
  heSababa: {
    translation: translationHESababa,
  },
}

i18n
  .use(Backend)
  .use({
    init: Function.prototype,
    type: 'languageDetector',
    async: true,
    detect: async callback => {
      const token = LS.get(LSKeys.TOKEN)
      if (!token) return
      const lang = await stores.userStore.getUserLanguage()
      if (!lang) return
      localStorage.setItem('i18nextLng', lang)
      if (lang === 'ru' || lang === 'en') document.body.dir = 'ltr'
      else document.body.dir = 'rtl'
      return callback(lang)
    },
    cacheUserLanguage: () => {},
  })
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    detection: {
      checkWhitelist: true,
    },
    react: {
      useSuspense: false,
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
