import { api } from 'config'

const approvalApi = {
  async approve(hash: string) {
    await api.patch(
      'Approval/approve',
      {},
      {
        params: {
          hash: decodeURI(hash),
        },
      }
    )
  },
}

export default approvalApi
