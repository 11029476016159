import Title from 'antd/lib/typography/Title'
import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ITemplateOption } from 'types/Template'

import dateTime from 'utils/dateTime'

import styles from './styles.module.scss'

interface IProps {
  template: ITemplateOption
  isSelected: boolean
  onPress: (template: ITemplateOption) => void
}

const TemplateItem: FC<IProps> = ({ template, isSelected, onPress }) => {
  const { t } = useTranslation()

  return (
    <div
      onClick={() => onPress(template)}
      className={classNames(styles.templateItem, {
        [styles.templateItemSelected]: isSelected,
      })}
      key={template.createdAt}
      data-program-creation-template="program-creation-template"
      id={'program-creation-template-' + template.value}
    >
      <Title style={{ marginBottom: 5 }} level={5} data-title-10="title-10">
        {template.label}
      </Title>
      <div className={styles.date} data-date="date">
        {`${t<string>('programCreation.createdAt')} ${dateTime.getHebrewDate(template.createdAt)}`}
      </div>
      <div className={styles.date} data-date="date">
        {template.duration} {t<string>('templateCreation.days')}
      </div>
    </div>
  )
}

export default TemplateItem
