import { useQuery } from '@tanstack/react-query'

import fileApi from 'api/file.api'

import { useStore } from 'stores'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

interface IProps {
  id: number
}

const useUserAvatarQuery = ({ id }: IProps) => {
  const { userStore } = useStore()

  const { data: avatar, isLoading } = useQuery({
    keepPreviousData: true,
    queryKey: [QueryKeysEnum.AVATAR, id],
    queryFn: async () => {
      if (!id || id === 3 || id === 1) {
        return null
      }

      const avatar = await fileApi.getImageById(id)

      if (avatar) userStore.setUserAvatar(avatar)

      return avatar || null
    },
  })

  return { avatar, isLoading }
}

export default useUserAvatarQuery
