import { Progress } from 'antd'
import classNames from 'classnames'
import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import { LastActiveTraineesTimeRange } from 'types/Program'
import { ITraineesCount } from 'types/User'
import { DEFAULT_PRIMARY_COLOR } from 'utils/const'

import styles from './styles.module.scss'

interface IProps {
  count: ITraineesCount
  setFilter: Dispatch<SetStateAction<LastActiveTraineesTimeRange | null>>
  filter: LastActiveTraineesTimeRange | null
}

const TraineesCount: FC<IProps> = ({ count, filter, setFilter }) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const calcPercent24h = Math.floor((count.activeForLastDay * 100) / count.totalActiveNumber)
  const calcPercent1w = Math.floor((count.activeForLastWeek * 100) / count.totalActiveNumber)
  const calcPercent1m = Math.floor((count.activeForLastMonth * 100) / count.totalActiveNumber)

  const progressProps = {
    width: 80,
    trailColor: '#ccc',
    strokeWidth: 10,
    strokeColor: userStore.brandData?.primaryColor || DEFAULT_PRIMARY_COLOR,
    type: 'circle' as 'circle',
  }

  return (
    <div className={styles.container}>
      <div
        onClick={() => setFilter && setFilter(LastActiveTraineesTimeRange.All)}
        className={classNames(styles.total, { [styles.active]: filter === LastActiveTraineesTimeRange.All })}
      >
        {t('traineesStat.total')} <div className={styles.totalNumber}>{count.totalActiveNumber}</div>
      </div>
      <div
        onClick={() => setFilter && setFilter(LastActiveTraineesTimeRange.Inactive)}
        className={classNames(styles.total, { [styles.active]: filter === LastActiveTraineesTimeRange.Inactive })}
      >
        {t('traineesStat.inactive')} <div className={styles.totalNumber}>{count.totalInActiveNumber}</div>
      </div>
      <div className={styles.charts}>
        <div
          onClick={() => setFilter && setFilter(LastActiveTraineesTimeRange.Day)}
          className={classNames(styles.item, {
            [styles.active]: filter === LastActiveTraineesTimeRange.Day,
          })}
        >
          <div className={styles.label}>
            <span className={styles.count}>{count.activeForLastDay}</span> {t('traineesStat.active24h')}
          </div>
          <Progress {...progressProps} percent={calcPercent24h} />
        </div>
        <div
          onClick={() => setFilter && setFilter(LastActiveTraineesTimeRange.Week)}
          className={classNames(styles.item, {
            [styles.active]: filter === LastActiveTraineesTimeRange.Week,
          })}
        >
          <div className={styles.label}>
            <span className={styles.count}>{count.activeForLastWeek}</span> {t('traineesStat.activeWeek')}
          </div>
          <Progress {...progressProps} percent={calcPercent1w} />
        </div>
        <div
          onClick={() => setFilter && setFilter(LastActiveTraineesTimeRange.Month)}
          className={classNames(styles.item, {
            [styles.active]: filter === LastActiveTraineesTimeRange.Month,
          })}
        >
          <div className={styles.label}>
            <span className={styles.count}>{count.activeForLastMonth}</span> {t('traineesStat.activeMonth')}
          </div>
          <Progress {...progressProps} percent={calcPercent1m} />
        </div>
      </div>
    </div>
  )
}

export default TraineesCount
