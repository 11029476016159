import { PlusOutlined } from '@ant-design/icons'
import { Form, Switch } from 'antd'
import { Rule } from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'
import { DefaultOptionType } from 'antd/lib/select'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useUnboundStore from 'hooks/useUnboundStore'

import { useStore } from 'stores'

import { planingToolTypesEnum } from 'types/Template'

import message from 'utils/message'

import CustomAutocomplete from 'components/CustomComponents/CustomAutocomplete'
import CustomButton from 'components/CustomComponents/CustomButton'
import CustomFormItem from 'components/CustomComponents/CustomFormItem'
import CustomInput from 'components/CustomComponents/CustomInput'
import CustomModal from 'components/CustomComponents/CustomModal'
import CustomSelect from 'components/CustomComponents/CustomSelect'
import CustomTextarea from 'components/CustomComponents/CustomTextarea'

import styles from './styles.module.scss'

interface IFormValues {
  description: string
  name: string
  templateEntityUnboundId: string
  buttonText: string
}

interface IProps {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  showUnboundContentModal: () => void
}

const autocompleteOptions = [
  {
    value: 'Close',
  },
  {
    value: 'Send',
  },
  {
    value: 'Submit',
  },
]

const PlanBtnModal: FC<IProps> = ({ setVisible, visible, showUnboundContentModal }) => {
  const { t } = useTranslation()

  const [touched, setTouched] = useState(false)
  const [isCreation, setIsCreation] = useState(true)
  const [active, setActive] = useState(true)
  const [btnTextTouched, setBtnTextTouched] = useState(false)
  const [contentId, setContentId] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const [form] = useForm()

  const { templateStore, planBtnStore } = useStore()
  const { templateEntity } = templateStore

  const { unboundStore } = useUnboundStore()

  useEffect(() => {
    const setInitialValues = async () => {
      if (templateEntity.programId) {
        const res = await planBtnStore.setPlanBtnFromWallPostsAPI(templateEntity.programId)

        setIsCreation(!res)

        if (!res) return

        const { description, name, programWallPostUnboundId, active, buttonText } = res

        setValuesInForm({ description, name, templateEntityUnboundId: programWallPostUnboundId.toString(), buttonText })
        setActive(!!active)
        return
      }

      if (templateEntity.templateInfo?.id) {
        const res = await planBtnStore.setPlanBtnFromTemplateAPI(templateEntity.templateInfo.id)

        setIsCreation(!res)

        if (!res) return

        const { description, name, templateEntityUnboundId, buttonText } = res

        setValuesInForm({ description, name, templateEntityUnboundId: templateEntityUnboundId.toString(), buttonText })
      }
    }

    setInitialValues()
  }, [templateEntity.programId, templateEntity.templateInfo?.id])

  const availableUnboundContent = useMemo(() => {
    return unboundStore.unboundContent.map(day => ({
      type: day.dayItems?.[0]?.type,
      id: day.id?.toString() || day.dayItems?.[0]?.uniqueId[0],
    }))
  }, [unboundStore.unboundContent])

  const selectedContentType = useMemo(() => {
    if (!contentId) return null

    const content = availableUnboundContent.find(content => content.id === contentId.toString())

    return content?.type || null
  }, [contentId, availableUnboundContent])

  const optionsToAutocomplete = useMemo(() => {
    return availableUnboundContent.map(content => ({
      label: `Content ${content.id} • ${content.type}`,
      value: content.id,
    }))
  }, [availableUnboundContent])

  useEffect(() => {
    if (!visible) return

    if (availableUnboundContent.length === 1) {
      form.setFieldValue('templateEntityUnboundId', availableUnboundContent[0].id)
    }
  }, [availableUnboundContent, visible])

  useEffect(() => {
    if (btnTextTouched || !selectedContentType || !isCreation) return

    if (selectedContentType === planingToolTypesEnum.QUESTIONNAIRE) {
      form.setFieldValue('buttonText', 'Send')
    } else {
      form.setFieldValue('buttonText', 'Close')
    }
  }, [selectedContentType, btnTextTouched, isCreation])

  const checkIfBoundContentIsExistInThisTemplate = useCallback(
    (_: Rule, id: string) => {
      if (!id) return Promise.resolve()

      const ok = availableUnboundContent.find(content => content.id === id)

      if (ok) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    },
    [availableUnboundContent]
  )

  const setValuesInForm = ({ name, description, buttonText, templateEntityUnboundId }: IFormValues) => {
    form.setFieldValue('name', name)
    form.setFieldValue('description', description)
    form.setFieldValue('buttonText', buttonText)
    form.setFieldValue('templateEntityUnboundId', templateEntityUnboundId)
    form.validateFields()
  }

  const rollBack = () => {
    if (templateEntity.isWallPosts) {
      if (planBtnStore.wallPostsPlanBtn) {
        const { description, name, active, programWallPostUnboundId, buttonText } = planBtnStore.wallPostsPlanBtn

        setValuesInForm({ name, description, buttonText, templateEntityUnboundId: programWallPostUnboundId.toString() })
        setActive(!!active)
      } else {
        form.resetFields()
      }
    } else {
      if (planBtnStore.templatePlanBtn) {
        const { description, name, buttonText, templateEntityUnboundId } = planBtnStore.templatePlanBtn

        setValuesInForm({ name, description, buttonText, templateEntityUnboundId: templateEntityUnboundId.toString() })
      } else {
        form.resetFields()
      }
    }

    setTouched(false)
  }

  const onPlanActionsPress = () => {
    setVisible(false)
    showUnboundContentModal()
  }

  const onFinish = async (values: IFormValues) => {
    try {
      setLoading(true)

      const { description, name, buttonText, templateEntityUnboundId } = values

      const isContentIdChanged =
        +templateEntityUnboundId !== planBtnStore.templatePlanBtnSource?.templateEntityUnboundId

      if (templateEntity.isWallPosts && templateEntity.programId) {
        const planBtn = {
          name,
          description,
          id: planBtnStore.wallPostsPlanBtn?.id,
          active,
          buttonText,
          programWallPostUnboundId: +templateEntityUnboundId,
        }

        if (isCreation) {
          await planBtnStore.createWallPostBtn(planBtn, templateEntity.programId)
        } else {
          await planBtnStore.editWallPostBtn(planBtn, templateEntity.programId, isContentIdChanged)
        }
      } else if (templateEntity.templateInfo?.id) {
        const planBtn = {
          name,
          description,
          id: planBtnStore.templatePlanBtn?.id,
          buttonText,
          templateEntityUnboundId: +templateEntityUnboundId,
        }

        if (isCreation) {
          await planBtnStore.createTemplateBtn(planBtn, templateEntity.templateInfo.id)
        } else {
          await planBtnStore.editTemplateBtn(planBtn, templateEntity.templateInfo.id, isContentIdChanged)
        }
      } else {
        throw new Error('Cannot create/update plan btn. TemplateId/ProgramId is not provided')
      }

      setVisible(false)
      setTouched(false)
      setIsCreation(false)

      message.success(t('templateCreation.planButtonSaved'))
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Form error:', errorInfo)
  }

  const btnTextValidator = (rule: Rule, value: string, callback: (error?: string) => void) => {
    const trimmedValue = value?.trim()
    if (trimmedValue?.length > 11) {
      callback(t<string>('companyPage.max11Characters'))
    } else {
      callback()
    }
  }

  return (
    <CustomModal
      style={{ minWidth: 500 }}
      title={
        <div className={styles.titleContainer}>
          <div className={styles.title}>{t<string>('planButton.btn')}</div>
          {templateEntity.isWallPosts && (
            <>
              {active ? (
                <div className={classNames(styles.titleLabel, styles.enable)}>{t<string>('shared.enabled')}</div>
              ) : (
                <div className={classNames(styles.titleLabel, styles.disable)}>{t<string>('shared.disabled')}</div>
              )}
            </>
          )}
        </div>
      }
      open={visible}
      destroyOnClose={false}
      onCancel={() => {
        rollBack()
        setVisible(false)
      }}
      footer={null}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        disabled={loading}
        onValuesChange={() => {
          setTouched(true)
        }}
      >
        <CustomFormItem
          label={t<string>('planButton.buttonName')}
          name="name"
          rules={[
            {
              required: true,
              message: t<string>('programCreation.required'),
            },
          ]}
          requiredMark="optional"
        >
          <CustomInput isErrorNeeded={false} big />
        </CustomFormItem>
        <CustomFormItem
          label={t<string>('planButton.contentId')}
          name="templateEntityUnboundId"
          rules={[
            {
              required: true,
              message: t<string>('programCreation.required'),
            },
            {
              validator: checkIfBoundContentIsExistInThisTemplate,
              message: t<string>('planButton.invalidContentId'),
            },
          ]}
          requiredMark="optional"
        >
          <CustomSelect
            onChange={value => setContentId(value)}
            options={optionsToAutocomplete}
            showSearch
            filterOption={(inputValue, option) =>
              (option?.label?.toString() ?? '').toLowerCase().includes(inputValue.toLowerCase())
            }
            className={styles.autocompleteInput}
            dropdownRender={menu => (
              <div>
                <div onClick={onPlanActionsPress} className={styles.createBtn}>
                  <PlusOutlined /> {t<string>('planButton.createNew')}
                </div>
                {menu}
              </div>
            )}
          />
        </CustomFormItem>
        <CustomFormItem
          label={t<string>('planButton.description')}
          name="description"
          rules={[
            {
              required: true,
              message: t<string>('programCreation.required'),
            },
          ]}
          requiredMark="optional"
        >
          <CustomTextarea isErrorNeeded={false} className={styles.description} />
        </CustomFormItem>

        <CustomFormItem
          label={t<string>('planButton.btnText')}
          name="buttonText"
          rules={[{ validator: btnTextValidator }]}
        >
          <CustomAutocomplete
            filterOption={(inputValue, option) =>
              (option!.value as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            options={autocompleteOptions as DefaultOptionType[]}
            onChange={() => setBtnTextTouched(true)}
            big
          />
        </CustomFormItem>

        {templateEntity.isWallPosts && (
          <div className={styles.switchContainer}>
            <div className={styles.label}>{t<string>('planButton.enableBtn')}</div>
            <Switch
              className="switchUI"
              checked={active}
              onChange={status => {
                setActive(status)
                setTouched(true)
              }}
            />
          </div>
        )}

        {touched && (
          <Form.Item style={{ marginBottom: 0 }}>
            <CustomButton type="primary" htmlType="submit" loading={loading} className={styles.btn}>
              {isCreation ? t('templateCreation.create') : t('programCreation.save')}
            </CustomButton>
          </Form.Item>
        )}
      </Form>
    </CustomModal>
  )
}

export default observer(PlanBtnModal)
