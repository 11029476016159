import React from 'react'
import { useTranslation } from 'react-i18next'

import CustomButton from 'components/CustomComponents/CustomButton'

import styles from './styles.module.scss'

interface IProps {
  handleSaveChanges: () => void
  loading: boolean
}

const BottomPanel: React.FC<IProps> = ({ handleSaveChanges, loading }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.bottomFunctionalButtons}>
      <div>
        <CustomButton loading={loading} type="primary" onClick={handleSaveChanges}>
          {t<string>('templateCreation.saveChanges')}
        </CustomButton>
      </div>
    </div>
  )
}

export default BottomPanel
