import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import { basicStringFieldValidator } from 'utils/validators/basicStringFieldValidator'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomFormItem from 'components/CustomComponents/CustomFormItem'
import CustomInput from 'components/CustomComponents/CustomInput'
import CustomModal from 'components/CustomComponents/CustomModal'

import styles from './styles.module.scss'

interface IProps {
  initialName: string
  open: boolean
  onCancel: () => void
}

const EditTemplateNameModal: FC<IProps> = ({ initialName, onCancel, open }) => {
  const { t } = useTranslation()

  const { templateStore } = useStore()

  const [loading, setLoading] = useState(false)

  const [form] = useForm()

  useEffect(() => {
    form.setFieldsValue({ name: initialName })
  }, [initialName, open])

  const onFinish = async ({ name }: { name: string }) => {
    try {
      setLoading(true)
      await templateStore.templateEntity.renameTemplate(name)
      onCancel()
    } finally {
      setLoading(false)
    }
  }

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      footer={null}
      title={t<string>('templateCreation.editTemplateName')}
      destroyOnClose
    >
      <Form form={form} onFinish={onFinish}>
        <CustomFormItem
          name="name"
          rules={[{ validator: basicStringFieldValidator({ required: true }) }]}
          className={styles.inputContainer}
        >
          <CustomInput isErrorNeeded={false} />
        </CustomFormItem>
        <CustomFormItem>
          <CustomButton loading={loading} type="primary" htmlType="submit">
            {t<string>('programCreation.save')}
          </CustomButton>
        </CustomFormItem>
      </Form>
    </CustomModal>
  )
}

export default EditTemplateNameModal
