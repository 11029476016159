import { Checkbox, Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import programsApi from 'api/programs.api'
import templatesApi from 'api/templates.api'

import { useStore } from 'stores'

import message, { someError } from 'utils/message'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomFormItem from 'components/CustomComponents/CustomFormItem'
import CustomModal from 'components/CustomComponents/CustomModal'
import { defaultProgramFilter } from 'utils/const'

interface IForm {
  hasSearch: boolean
  hasFilter: boolean
  hasCalendar: boolean
}

const Settings = () => {
  const { t } = useTranslation()

  const { id } = useParams<{ id: string }>()

  const { templateStore } = useStore()

  const isWallPosts = templateStore.templateEntity.isWallPosts

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [touch, setTouch] = useState(false)

  const [form] = useForm<IForm>()

  const onFinish = async (values: IForm) => {
    try {
      if (!id) throw new Error('template id not found')

      setLoading(true)

      const payload = {
        filter: values,
        id: +id,
      }

      if (isWallPosts) {
        await programsApi.updateFilter(payload)
        message.success(t('programCreation.mobileShowSettingsChangedProgram'))
      } else {
        await templatesApi.updateFilter(payload)
        message.success(t('programCreation.mobileShowSettingsChangedTemplate'))
      }

      setOpen(false)
      setTouch(false)
    } catch (e) {
      someError()
    } finally {
      setLoading(false)
    }
  }

  const installInitialValues = useCallback(async () => {
    if (!id) return

    if (isWallPosts) {
      const getFilterFromProgram = async () => {
        const res = await programsApi.getProgramById(id)

        const filter = res?.filter

        form.setFieldsValue({
          hasSearch: filter ? filter?.hasSearch : defaultProgramFilter.hasSearch,
          hasFilter: filter ? filter?.hasFilter : defaultProgramFilter.hasFilter,
          hasCalendar: filter ? filter?.hasCalendar : defaultProgramFilter.hasCalendar,
        })
      }

      getFilterFromProgram()
    } else {
      const getFilterFromTemplate = async () => {
        const template = await templatesApi.getLightTemplate(+id)

        const filter = template?.filter

        form.setFieldsValue({
          hasSearch: filter ? filter?.hasSearch : defaultProgramFilter.hasSearch,
          hasFilter: filter ? filter?.hasFilter : defaultProgramFilter.hasFilter,
          hasCalendar: filter ? filter?.hasCalendar : defaultProgramFilter.hasCalendar,
        })
      }

      getFilterFromTemplate()
    }
  }, [isWallPosts, id])

  useEffect(() => {
    if (!open) {
      installInitialValues()
      setTouch(false)
    }
  }, [installInitialValues, open])

  return (
    <div>
      <CustomButton onClick={() => setOpen(true)}>{t('programCreation.mobileSettings')}</CustomButton>
      <CustomModal
        title={<h2 className="modal-title">{t('programCreation.mobileSettings')}</h2>}
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <Form onChange={() => setTouch(true)} form={form} onFinish={onFinish}>
          <div style={{ marginBottom: 20 }}>
            <CustomFormItem name="hasCalendar" valuePropName="checked">
              <Checkbox>{t('programCreation.mobileShowCalendar')}</Checkbox>
            </CustomFormItem>
            <CustomFormItem name="hasFilter" valuePropName="checked">
              <Checkbox>{t('programCreation.mobileShowFilters')}</Checkbox>
            </CustomFormItem>
            <CustomFormItem name="hasSearch" valuePropName="checked">
              <Checkbox>{t('programCreation.mobileShowSearch')}</Checkbox>
            </CustomFormItem>
          </div>
          {touch && (
            <CustomFormItem>
              <CustomButton loading={loading} htmlType="submit" type="primary">
                {t('programCreation.save')}
              </CustomButton>
            </CustomFormItem>
          )}
        </Form>
      </CustomModal>
    </div>
  )
}

export default Settings
