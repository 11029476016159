import { t } from 'i18next'
import { action, makeAutoObservable } from 'mobx'

import adminApi from 'api/admin.api'
import { IInitialSettingInDto } from 'api/DTOs/admin.dto'

import { InviteSMSEnum, IPagination } from 'types/common'
import { ISettingProfile } from 'types/InitialSettings'
import {
  ICompanyInvite,
  ICompanyInviteTransformed,
  IDashboardCompany,
  IDashboardProgramsWithModerators,
  IProgramAutocomplete,
  ISelectedTraineesProgram,
} from '../types/Dashboard'

import { applicationBrand } from 'utils/brand/brandDetector'
import { initialProfileValues } from 'utils/const'
import message from 'utils/message'

import { IInviteTraineeForm } from 'components/ModalInviteCommonUser/TraineeTab'

class Store {
  constructor() {
    makeAutoObservable(this)
  }

  @action
  async deleteUser(userId: number, isDeleted?: boolean): Promise<void> {
    try {
      let response

      if (isDeleted !== undefined) {
        response = await adminApi.deleteUser(userId, isDeleted)
      } else {
        response = await adminApi.deleteUserHard(userId)
      }

      if (response.status === 200) {
        if (response?.data?.isDeleted !== undefined && !response?.data?.isDeleted) {
          message.success(t<string>('dashboard.restoreSuccess'))
        } else {
          message.success(t<string>('dashboard.userDeletedSuccess'))
        }
      }
    } catch (e) {
      const errMessage = e?.response?.data?.Message

      if (errMessage?.includes("You can't restore user profile that already exist with the same phone number")) {
        message.error(t<string>('dashboard.cantRestoreUserWithExistNumber'))
        return
      }

      message.error(t<string>('programCreation.smtWrong'))
    }
  }

  @action
  async blockUser(userId: string | number, until: string | undefined, description: string): Promise<void> {
    try {
      await adminApi.blockUser({
        userId,
        until: until ?? null,
        description,
      })

      message.success(t<string>('dashboard.userBlockedSuccess'))
    } catch (e) {
      const { StatusCode, Message } = e.response.data

      if (StatusCode === 400) {
        message.error(t<string>('dashboard.userError'))
        return
      }

      message.error(t<string>('programCreation.smtWrong'))
    }
  }

  @action
  async unBlockUser(id: number): Promise<void | boolean> {
    try {
      await adminApi.unBlockUser(id)

      message.success(t<string>('dashboard.userUnblockSuccess'))

      return true
    } catch (e) {
      console.log(e)

      const { StatusCode, Message } = e.response.data

      if (StatusCode === 400) {
        message.error('User is already blocked or an error has occurred')
        return
      }

      message.error(t<string>('programCreation.smtWrong'))
    }
  }

  @action
  async inviteTrainee({
    userData,
    selectedPrograms,
    inviteSms,
    initialSettings,
  }: {
    userData: IInviteTraineeForm
    selectedPrograms: ISelectedTraineesProgram[]
    inviteSms: InviteSMSEnum
    initialSettings?: Omit<ISettingProfile, 'id' | 'name'> | null
  }): Promise<void | boolean> {
    try {
      const settings = initialSettings || initialProfileValues

      const trainingProgramIds = selectedPrograms.map(program => program.id)
      const invited = await adminApi.inviteTrainee(
        {
          initialSettingModel: {
            name: 'Admin/sendInviteToTrainee',
            ...settings,
            applicationBrand,
          },
          traineeInviteModel: {
            ...userData,
            trainingProgramIds,
          },
          applicationBrand,
        },

        inviteSms
      )

      const alreadyInvitedProgramNames = invited.flatMap((hash, idx) => {
        const program = selectedPrograms[idx]

        if (hash === null && program) {
          return program.name
        }
        return []
      })

      if (alreadyInvitedProgramNames.length) {
        message.warn(t<string>('dashboard.alreadyInvitedInPrograms', { programNames: alreadyInvitedProgramNames }))

        if (trainingProgramIds.length === alreadyInvitedProgramNames.length) return
      }

      message.success(t<string>('dashboard.traineeInvitedSuccess'))

      return true
    } catch (e) {
      const { StatusCode, Message } = e.response.data

      if (StatusCode === 400) {
        message.error(t<string>('dashboard.traineeError'))
        return
      }
    }
  }

  @action
  async inviteTrainer(
    formFields: IInviteTraineeForm,
    initialSettings?: Omit<ISettingProfile, 'id' | 'name'> | null
  ): Promise<void | boolean> {
    try {
      const settings = initialSettings || initialProfileValues

      await adminApi.inviteMentor({
        trainerInviteModel: formFields,
        initialSettingModel: settings as IInitialSettingInDto,
      })

      message.success(t<string>('dashboard.trainerInvitedSuccess'))

      return true
    } catch (e) {
      const { StatusCode, Message } = e.response.data

      if (StatusCode === 400) {
        message.error(t<string>('dashboard.trainerError'))
        return
      }
    }
  }

  @action
  async inviteModerator(formFields: {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    trainingProgramsIds: number[]
  }) {
    try {
      const response = await adminApi.inviteModerator(formFields)

      message.success(t<string>('dashboard.moderatorInvitedSuccess'))

      return response.data
    } catch (e) {
      const { StatusCode, Message } = e.response.data

      if (StatusCode === 400) {
        const isModeratorProgram = Message.includes('in his program')

        if (isModeratorProgram) {
          message.error(t<string>('dashboard.moderatorInHisProgramError'))
          return
        }

        message.error(t<string>('dashboard.moderatorError'))
      }
    }
  }

  @action
  async getProgramsByName(str: string): Promise<void | IProgramAutocomplete[]> {
    try {
      const data = await adminApi.getAllPrograms(str)

      if (!data) return []

      return data
        .filter(item => item)
        .map(item => {
          return {
            label: `${item.name}`,
            value: item.id + '|' + item.name,
          }
        })
    } catch (e) {
      message.error(t<string>('programCreation.smtWrong'))
    }
  }

  @action
  async inviteCompany(formData: ICompanyInviteTransformed) {
    try {
      const invitedCompany = await adminApi.inviteCompany(formData)

      message.success(t<string>('dashboard.companyInviteSuccess'))

      return invitedCompany
    } catch (e) {
      console.log(e)
      const { StatusCode, Message } = e.response.data

      if (StatusCode === 400) {
        const isSamePhone = Message.includes('company moderator as trainer')
        if (isSamePhone) {
          message.error(t<string>('dashboard.moderatorAsTrainerCompany'))
          return
        }

        const moderatorExist = Message.includes('User is already company moderator')
        if (moderatorExist) {
          message.error(t<string>('dashboard.moderatorAlready'))
          return
        }

        message.error(t<string>('dashboard.companyError'))
        return
      }
      message.error(t<string>('programCreation.smtWrong'))
    }
  }

  @action
  async sendCsvTrainers(formFields: ICompanyInvite, formData: FormData): Promise<void | boolean> {
    try {
      await adminApi.inviteCompanyWithCSV(formFields, formData)

      message.success(t<string>('dashboard.companyInviteSuccess'))

      return true
    } catch (e) {
      const { StatusCode, Message } = e.response.data

      if (StatusCode === 400) {
        const isCsvError = Message.includes('read CSV')

        if (isCsvError) throw new Error(t<string>('dashboard.csvReadError'))

        const moderatorExist = Message.includes('User is already company moderator')
        if (moderatorExist) throw new Error(t<string>('dashboard.moderatorAlready'))

        throw new Error(t<string>('dashboard.companyError'))
      }
      throw new Error(t<string>('programCreation.smtWrong'))
    }
  }

  @action
  async deleteCompany(id: number): Promise<void> {
    try {
      await adminApi.deleteCompany(id)
    } catch (e) {
      message.error(t<string>('programCreation.smtWrong'))
    }
  }

  @action
  async deleteModerator(id: number, isAll?: boolean, programId?: number): Promise<void> {
    try {
      if (isAll) {
        await adminApi.removeAllModeratosInProgram(id)
        message.success('Moderators deleted successfully')
        return
      }
      if (programId) {
        await adminApi.removeModeratorInProgram(id, programId)
        message.success(t<string>('dashboard.moderatorDeleteSuccess'))
        return
      }
    } catch (e) {
      message.error(t<string>('programCreation.smtWrong'))
    }
  }

  @action
  async isCompanyNameExists(str: string): Promise<boolean | void> {
    const response = await adminApi.checkCompanyName(str)
    return !response.data
  }

  @action
  async getDashboardCompaniesAutocomplete(
    str: string,
    pageNumber: number,
    paginationSize: number
  ): Promise<{ items: IDashboardCompany[]; pagination: IPagination } | void> {
    try {
      const companies = await adminApi.getCompaniesByName({ pageLength: paginationSize, pageNumber, str })

      const { items, ...pagination } = companies

      return { items, pagination }
    } catch (e) {
      message.error(t<string>('programCreation.smtWrong'))
    }
  }

  @action
  async inviteMentorsToCompany(
    formFields: { phoneNumber: string; email: string }[],
    companyId: number
  ): Promise<IDashboardCompany[] | void> {
    try {
      await adminApi.inviteMentorsToCompany(
        formFields.map(mentor => ({ ...mentor, applicationBrand })),
        companyId
      )

      message.success(t<string>('dashboard.trainerInvitedSuccess'))
    } catch (e) {
      const { StatusCode, Message } = e.response.data

      const isTrainersExists = Message.split("'") as string[]

      if (isTrainersExists.length) {
        if (isTrainersExists[1].length > 16)
          throw new Error(t<string>('dashboard.trainersExists', { phoneNumbers: isTrainersExists[1] }))
        else throw new Error(t<string>('dashboard.trainerExists', { phoneNumber: isTrainersExists[1] }))
      }

      throw new Error(t<string>('programCreation.smtWrong'))
    }
  }

  @action
  async getProgramsWithModerators(
    str?: string,
    pageNumber?: number,
    pageLength?: number
  ): Promise<{ items: IDashboardProgramsWithModerators[]; pagination: IPagination } | void> {
    try {
      const data = await adminApi.getProgramsWithModerator({ pageLength, pageNumber, str })

      const { items, ...pagination } = data

      return { items, pagination }
    } catch (e) {
      message.error(t<string>('programCreation.smtWrong'))
    }
  }

  @action
  async uploadImagesByAdmin(formData: FormData) {
    try {
      const res = await adminApi.uploadImagesByAdmin(formData)
      return res
    } catch (e) {
      console.log(e)
    }
  }
}

export default new Store()
