import { PlusCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ForwardRefRenderFunction, forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomButton from 'components/CustomComponents/CustomButton'
import TemplateCreateModal from 'components/TemplateCreateModal'

import styles from './styles.module.scss'

import { ReactComponent as PlusSign } from 'sources/images/PlusSign.svg'

interface IProps {
  isLoading?: boolean
  type?: 'default' | 'link' | 'primary'
}

const CreateTemplateBtn: ForwardRefRenderFunction<{ openModal: () => void }, IProps> = (
  { isLoading = false, type = 'default' },
  ref
) => {
  const [isTemplateCreateModalVisible, setIsTemplateCreateModalVisible] = useState(false)

  const { t } = useTranslation()

  useImperativeHandle(ref, () => ({
    openModal: () => {
      setIsTemplateCreateModalVisible(true)
    },
  }))

  const onClick = () => setIsTemplateCreateModalVisible(true)

  return (
    <>
      {type === 'link' ? (
        <CustomButton icon={<PlusCircleOutlined />} onClick={onClick} type="link">
          {t('templateList.createFirst')}
        </CustomButton>
      ) : type === 'primary' ? (
        <CustomButton
          icon={<PlusSign style={{ color: '#fff' }} />}
          onClick={onClick}
          type="primary"
          className={styles.primary}
        >
          {t('templateList.newTemplate')}
        </CustomButton>
      ) : (
        <Button
          icon={<PlusSign style={{ marginInlineEnd: 7, color: '#232631' }} />}
          loading={isLoading}
          onClick={onClick}
          data-template-list-new-btn="template-list-new-btn"
          id="template-list-new-btn"
          className={styles.btn}
        >
          {t('templateList.newTemplate')}
        </Button>
      )}
      <TemplateCreateModal
        open={isTemplateCreateModalVisible}
        onCancel={() => setIsTemplateCreateModalVisible(false)}
      />
    </>
  )
}

export default forwardRef(CreateTemplateBtn)
