import { HubConnection } from '@microsoft/signalr'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

import wallPostHub from 'api/wallPostHub.api'

import LS, { LSKeys } from 'utils/LS'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

let retries = 9

const useReportCommentsHub = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    let connection: HubConnection | null = null

    const connectToCommentsHub = async () => {
      try {
        retries--

        const token = await LS.get(LSKeys.TOKEN)

        connection = await wallPostHub.connect(token)

        if (!connection) throw new Error('connectToCommentsHub [wallPostHub] error')

        connection.on('OnCommentChange', (comment, sec) => {
          queryClient.invalidateQueries([QueryKeysEnum.DAILY_REPORTS])
        })

        connection.start()

        retries = 9
      } catch (e) {
        if (retries > 0) {
          connectToCommentsHub()
        }
      }
    }

    connectToCommentsHub()

    return () => {
      retries = 9

      if (connection) {
        connection.off('OnCommentChange')
        wallPostHub.disconnect(connection)
      }
    }
  }, [])
}

export default useReportCommentsHub
