import { t } from 'i18next'
import { Dispatch, SetStateAction } from 'react'

import { authApi } from 'config'

import { ITraineesServerModel } from 'types/User'
import { InviteSMSEnum } from 'types/common'

import message from 'utils/message'

const invitationsApi = {
  async inviteTraineesToProgram(
    traineesPhones: ITraineesServerModel[],
    trainingProgramId: number | string,
    inviteSms: InviteSMSEnum,
    initialSettingId?: number | null
  ) {
    try {
      const response = await authApi.post('Invitation/sendInvitationToUsers', {
        models: traineesPhones,
        trainingProgramId,
        inviteSms,
        initialSettingId,
      })

      const isNoApprovalsRequired = response.data?.[0]?.errorHandler?.message?.includes('no approvals are required')
      const phoneNumber = response.data?.[0]?.errorHandler?.message?.split('"')[1]

      if (isNoApprovalsRequired) {
        //@ts-ignore
        message.error(t<string>('programCreation.noApprovalsRequired', { phoneNumber }, { cause: 'info' }))

        return null
      }

      return response.data
    } catch (e) {
      const { StatusCode, Message } = e.response.data

      if (StatusCode === 400) {
        const errorType = Message.includes('You')

        if (errorType) {
          message.error(t<string>('programCreation.selfInviteError'))
          return null
        } else {
          message.error(
            t<string>('programCreation.phoneValidationError', {
              phoneNumbers: errorType[1],
            })
          )
          return null
        }
      }

      message.error(t<string>('programCreation.smtWrong'))
      return null
    }
  },
  async validateTraineesCsv(file: any, setProgress: Dispatch<SetStateAction<number>>, signal: AbortSignal) {
    try {
      const formData = new FormData()

      if (!file) return

      formData.append('CVS', file)

      const response = await authApi.post('/Invitation/validateCSV', formData, {
        onUploadProgress: progressEvent => {
          const percent = Math.round((progressEvent.loaded / progressEvent.total) * 100)

          setProgress(percent)
        },
        signal: signal,
      })

      const filteredDuplicates = (response.data = response.data.filter(
        (trainee: any, index: number, self: any) =>
          index === self.findIndex((checkedTrainee: any) => checkedTrainee.phoneNumber === trainee.phoneNumber)
      ))

      return filteredDuplicates
    } catch (e) {
      console.log(e)
    }
  },
  async deleteInvitationsRange(trainingProgramId: number, traineesIdToDelete: number[]) {
    await authApi.patch('Invitation/deleteRange', traineesIdToDelete, {
      params: { trainingProgramId },
    })

    return true
  },
  async deleteInvitation({ userID, trainingProgramId }: { userID: number; trainingProgramId: number }) {
    await authApi.patch(
      'Invitation/delete',
      {},
      {
        params: {
          userID,
          trainingProgramId,
        },
      }
    )
  },
  async resendInvitesRange(trainingProgramId: number, traineesIdToResendInvites: number[]) {
    await authApi.patch('Invitation/resendInvite', traineesIdToResendInvites, {
      params: { trainingProgramId },
    })

    return true
  },
}

export default invitationsApi
