import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import reportsApi from 'api/reports.api'

import { ICalendarDateRange } from 'hooks/reports/useReportFilters'

import { IDailyReport } from 'types/Program'
import { QueryKeysEnum } from 'types/QueryKeysEnum'

import message from 'utils/message'

interface IProps {
  programId: number | null
  dateRange: ICalendarDateRange
}

const useDailyReportsQuery = ({ programId, dateRange }: IProps) => {
  const { t } = useTranslation()

  const {
    data: dailyReports,
    isLoading,
    isError,
    isFetching,
    isFetched,
  } = useQuery({
    keepPreviousData: true,
    queryKey: [QueryKeysEnum.DAILY_REPORTS, programId, dateRange.startDate, dateRange.endDate],
    queryFn: async () => {
      const { endDate, startDate } = dateRange

      let dailyReportData: IDailyReport[] = []

      const dateTimeFrom = startDate.toISOString().split('T')[0] + 'T00:00:00Z'
      const dateTimeTill = endDate.toISOString().split('T')[0] + 'T00:00:00Z'

      if (programId) {
        dailyReportData = await reportsApi.getDailyReportsV2({
          DateTimeFrom: dateTimeFrom,
          DateTimeTill: dateTimeTill,
          ProgramId: programId,
        })
      } else {
        dailyReportData = await reportsApi.getDailyReportsV2({
          DateTimeFrom: dateTimeFrom,
          DateTimeTill: dateTimeTill,
        })
      }

      const modifiedReports: IDailyReport[] = dailyReportData
        .sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime())
        .map((report, reportIdx) => ({
          ...report,
          dailyReportModelForUsers: report.dailyReportModelForUsers.map((userReport, userReportIdx) => ({
            ...userReport,
            id: `${reportIdx}-${userReportIdx}`,
          })),
        }))

      return modifiedReports
    },
    onError: () => {
      message.error(t<string>('programCreation.smtWrong'))
    },
  })

  return {
    dailyReports: dailyReports || [],
    isLoading: isLoading,
    isError: isError,
    reportLoadingFirstTime: isFetching && !isFetched,
  }
}

export default useDailyReportsQuery
