export const checkValidVimeoLink = async (videoURL: string) => {
  const response = await fetch(`https://vimeo.com/api/oembed.json?url=${videoURL}`)

  if (!response.ok) {
    return false
  }

  return true
}

export const checkValidYoutubeLink = async (videoURL: string) => {
  const response = await fetch(`https://www.youtube.com/oembed?format=json&url=${videoURL}`)

  if (!response.ok) {
    return false
  }

  return true
}
