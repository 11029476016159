import { CloseCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, UploadFile } from 'antd'
import classNames from 'classnames'
import { t } from 'i18next'
import { Dispatch, FC, SetStateAction, useCallback } from 'react'

import { useStore } from 'stores'

import generateFormData from 'utils/generateFormData'

import styles from './styles.module.scss'

type ErrorUploadFilePropsType = {
  file: UploadFile<any>
  setFileList: Dispatch<SetStateAction<UploadFile<any>[]>>
  isEditing: boolean
  isImageCard?: boolean
}

const ErrorUploadFile: FC<ErrorUploadFilePropsType> = ({ file, isEditing, setFileList, isImageCard }) => {
  const { templateStore } = useStore()

  const retry = useCallback(
    async (file: UploadFile<any>) => {
      try {
        templateStore.setUploadInProgressFilesId([...templateStore.uploadInProgressFilesId, file.uid])

        const formData = await generateFormData([file], file.type === 'image')

        const resultIds = await templateStore.uploadFiles(formData.formData, formData.mimeType !== 'image')

        if (!resultIds || !resultIds.length) throw new Error()

        templateStore.setUploadErrorFilesId(templateStore.uploadErrorFilesId.filter(err => err !== file.uid))
      } catch (e) {
        console.log(e)
      } finally {
        templateStore.setUploadInProgressFilesId(templateStore.uploadInProgressFilesId.filter(err => err !== file.uid))
      }
    },
    [templateStore.uploadErrorFilesId, templateStore.uploadInProgressFilesId]
  )

  const close = useCallback(
    (file: UploadFile<any>) => {
      templateStore.setUploadErrorFilesId(templateStore.uploadErrorFilesId.filter(err => err !== file.uid))

      setFileList(prev => prev.filter(item => item.uid !== file.uid))
    },
    [templateStore.uploadErrorFilesId]
  )

  return (
    <div
      className={classNames(styles.error, {
        [styles.card]: isImageCard,
      })}
    >
      <span>{t<string>('templateCreation.fileUploadError')}</span>
      <span className={styles.btnsContainer}>
        <Button className={styles.btn} disabled={!isEditing} onClick={() => retry(file)}>
          <SyncOutlined />
        </Button>
        <Button className={styles.btn} disabled={!isEditing} onClick={() => close(file)} danger>
          <CloseCircleOutlined />
        </Button>
      </span>
    </div>
  )
}

export default ErrorUploadFile
