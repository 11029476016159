import { ICalendarDateRange } from 'hooks/reports/useReportFilters'
import moment from 'moment'

export const defaultReportDate = {
  startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
  endDate: new Date(),
  key: 'selection' as const,
}

const dateTime = {
  addZeroToDateItem: (dateItem: string) => {
    if (+dateItem < 10) {
      return `0${dateItem}`
    }

    return dateItem
  },
  isNever: (stringDate: string) => {
    const splited = stringDate.split('-')

    return splited[0] === '0001'
  },
  getHebrewDate: (stringDate: string) => {
    const { addZeroToDateItem } = dateTime

    const [day, month, year] = new Date(stringDate).toLocaleDateString('he-IL').split('.')

    return `${addZeroToDateItem(day)}/${addZeroToDateItem(month)}/${year}`
  },
  dateWithTime: (stringDate: string) => {
    return moment(stringDate).format('DD/MM/YYYY HH:mm')
  },
  calcDefaultReportRange: (endDate: string, startDate: string) => {
    if (moment().isAfter(moment(endDate), 'd')) {
      const weekBeforeEnd = new Date(new Date(endDate).getTime() - 7 * 24 * 60 * 60 * 1000)

      const startDateCalc = moment(weekBeforeEnd).isBefore(moment(startDate)) ? startDate : weekBeforeEnd

      return {
        startDate: new Date(moment(startDateCalc + 'Z').toLocaleString()),
        endDate: new Date(moment(endDate + 'Z').toLocaleString()),
        key: 'selection',
      } as ICalendarDateRange
    } else {
      return defaultReportDate as ICalendarDateRange
    }
  },
}

export const days = {
  en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  ru: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  he: ['יום ראשון', 'יום שני', 'יום שלישי', 'יום רביעי', 'יום חמישי', 'יום שישי', 'שבת'],
}

export default dateTime
