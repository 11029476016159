import { Button, Typography } from 'antd'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import ReactCodeInput from 'react-code-input'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import LS, { LSKeys } from 'utils/LS'
import message, { someError } from 'utils/message'

import { format } from 'utils/timeFormat'

import styles from './styles.module.scss'

const { Title, Text } = Typography

interface IProps {
  setIsBlocked?: (status: boolean) => void
  phoneNumber: string
  dark?: boolean
  submitText?: string
  onSuccess?: () => void
  isConfirm?: boolean
}

const OTPForm = ({ phoneNumber, setIsBlocked, dark, submitText, onSuccess, isConfirm }: IProps) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const [errorMsg, setErrorMsg] = useState('')
  const [otpCode, setOtpCode] = useState('')
  const [counter, setCounter] = useState(300)
  const [loading, setLoading] = useState<boolean>(false)
  const [resetCodeInput, setResetCodeInput] = useState(false)

  useEffect(() => {
    const buttonSubmit = document.getElementById('submit')

    const enterPressHandler = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (buttonSubmit) {
          buttonSubmit?.click()
        }
      }
    }

    document.addEventListener('keyup', enterPressHandler)

    return () => document.removeEventListener('keyup', enterPressHandler)
  }, [])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (counter > 0) {
      timer = setTimeout(() => setCounter(c => c - 1), 1000)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [counter])

  const onFinish = async () => {
    if (!otpCode?.length) {
      setErrorMsg(t('otp.enderCode'))
      return
    }

    setLoading(true)

    const error = isConfirm ? await userStore.otpConfirm(otpCode) : await userStore.sendOTP(phoneNumber, otpCode)

    if (error) {
      setErrorMsg(error.message)
      setResetCodeInput(true)
      setOtpCode('')
      // to reset code input value, because package dont have any ability to do this
      setTimeout(() => setResetCodeInput(false), 0)

      if (setIsBlocked) {
        setIsBlocked(!!error.isBanned)
      }

      setLoading(false)
      return
    }

    if (onSuccess) {
      onSuccess()
    } else {
      userStore.clearCache()
      LS.set(LSKeys.PHONE, phoneNumber)
    }
  }

  const handleReceiveCode = async () => {
    setLoading(true)

    const { statusCode, message: resMessage } = await userStore.sendSMS(phoneNumber)

    if (!statusCode) {
      someError()
    } else if (resMessage?.includes('User does not have this brand')) {
      message.error(t('login.loginToWrongBrand'))
    } else if (statusCode < 300) {
      setCounter(300)
      message.success(t('otp.codeMessage'))
    } else if (statusCode === 400) {
      message.error(t('shared.messageBirdError'))
    } else if (statusCode === 403) {
      message.error(t('login.loginToWrongBrand'))
    } else if (statusCode === 404) {
      message.error(t('login.phoneIncorrect'))
    } else if (statusCode === 403) {
      message.error(t('general.maintenanceErrorText'))
    } else if (statusCode === 429) {
      message.error(t('login.rateLimit'))
    } else {
      someError()
    }

    setLoading(false)
  }

  return (
    <div>
      <div
        style={{
          justifyContent: document.body.dir === 'rtl' ? 'flex-end' : 'flex-start',
        }}
        className={styles.inputsContainer}
        data-input-container="input-container"
      >
        {!resetCodeInput && (
          <ReactCodeInput
            name=""
            type="number"
            fields={6}
            inputMode="verbatim"
            onChange={setOtpCode}
            value={otpCode}
            autoFocus
          />
        )}
      </div>
      <div className={styles.errorMsg} data-error-msg="error-msg">
        <span>{errorMsg}</span>
      </div>
      <div className={styles.info} data-info="info">
        {counter === 0 ? (
          t('otp.timeOver')
        ) : (
          <Title
            className={classNames(styles.time, {
              [styles.dark]: dark,
            })}
            level={5}
            data-title-3="title-3"
          >
            {format(counter)}
          </Title>
        )}
        <Text
          className={classNames(styles.receiveCode, { [styles.dark]: dark })}
          onClick={handleReceiveCode}
          data-text-3="text-3"
        >
          {counter !== 0 ? t('otp.receiveCode') : ''}&nbsp;
          <Button type="link" data-resend-code="resend-code" id="resend-code">
            {' '}
            {t('otp.resendCode')}
          </Button>
        </Text>
      </div>

      <Button
        className={styles.submitBtn}
        type="primary"
        onClick={onFinish}
        block
        size="large"
        loading={loading}
        data-submit="submit"
        id="submit"
      >
        {submitText || t('otp.submit')}
      </Button>
    </div>
  )
}

export default OTPForm
