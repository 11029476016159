import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import useCopyTemplateMutation from 'hooks/tanstack/mutations/useCopyTemplateMutation'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomInput from 'components/CustomComponents/CustomInput'
import CustomModal from 'components/CustomComponents/CustomModal'

import styles from './styles.module.scss'

interface IProps {
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  currentTemplateDropdownOpened: number
  onSuccess?: () => void
}

const CopyTemplateModal: FC<IProps> = ({
  isModalVisible,
  setIsModalVisible,
  currentTemplateDropdownOpened,
  onSuccess,
}) => {
  const { t } = useTranslation()

  const [form] = useForm()

  const { copyTemplate, isLoading } = useCopyTemplateMutation({ setIsModalVisible, form, onSuccess })

  return (
    <CustomModal
      title={t<string>('dashboard.copyTemplate')}
      open={isModalVisible}
      footer={null}
      onCancel={() => setIsModalVisible(false)}
      bodyStyle={{ paddingBottom: 1 }}
      destroyOnClose
      data-copy-template="copy-template"
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={values => copyTemplate({ name: values.name as string, id: currentTemplateDropdownOpened })}
        form={form}
        data-form-copy="form-copy"
        requiredMark={false}
        layout="vertical"
      >
        <Form.Item
          label={t<string>('dashboard.newTemplateName')}
          name="name"
          rules={[
            {
              validator: (_, value, cb) => {
                const trimmedValue = value?.trim()

                if (!trimmedValue) {
                  return cb(t<string>('templateCreation.inputName'))
                }

                return cb()
              },
            },
          ]}
          normalize={(value, prevValue) => (prevValue ? value : value.trim())}
          data-form-item-name="form-item-name"
        >
          <CustomInput
            data-form-item-name-input="form-item-name-input"
            containerClassName={styles.copyInput}
            isErrorNeeded={false}
          />
        </Form.Item>

        <Form.Item className={styles.buttonRight} data-button-right="button-right">
          <CustomButton type="primary" loading={isLoading} htmlType="submit" data-btn-submit="btn-submit">
            {t<string>('programList.copy')}
          </CustomButton>
        </Form.Item>
      </Form>
    </CustomModal>
  )
}

export default CopyTemplateModal
