import { ArrowUpOutlined, LoadingOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import questionCommentApi from 'api/questionComment.api'

import { useStore } from 'stores'

import CustomInput from 'components/CustomComponents/CustomInput'
import UserAvatar from 'components/UserAvatar'

import styles from './styles.module.scss'

interface IProps {
  questionId: number
  traineeId: number
  addOptimisticComment: (comment: string) => void
}

const Controls: FC<IProps> = ({ questionId, traineeId, addOptimisticComment }) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const sendComment = async () => {
    const trimmedMessage = message.trim()

    if (!trimmedMessage || loading) return

    setLoading(true)

    addOptimisticComment(trimmedMessage)

    await questionCommentApi.createComment({
      comment: trimmedMessage,
      questionId,
      traineeId,
    })

    setLoading(false)
    setMessage('')
  }

  return (
    <div className={styles.controls}>
      <div className={styles.controlsInner}>
        <CustomInput
          value={message}
          onChange={e => setMessage(e.target.value)}
          className={styles.input}
          isErrorNeeded={false}
          placeholder={t<string>('dailyReport.addComment')}
          onPressEnter={sendComment}
          big
        />
        <div className={styles.avatar}>
          <UserAvatar avatarId={userStore.user?.avatarId || 1} size={32} />
        </div>
        <div className={styles.btns}>
          <div className={styles.sendBtn} onClick={sendComment}>
            {loading ? <LoadingOutlined color="#fff" /> : <ArrowUpOutlined className={styles.sendBtnPic} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Controls)
