import { Input, InputProps } from 'antd'
import cn from 'classnames'
import React from 'react'

import useIsRtl from 'hooks/useIsRtl'

import Variables from './Variables'

import styles from './styles.module.scss'

interface IProps extends InputProps {
  label?: string
  labelType?: 'default' | 'primary' | 'shadow'
  labelClassName?: string
  errorText?: string[]
  containerClassName?: string
  isErrorNeeded?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onSelectVariable?: (variable: string) => void
  inputWrapperStyles?: string
  big?: boolean
}

const CustomInput: React.FC<IProps> = ({
  label,
  labelType = 'default',
  labelClassName,
  className,
  errorText,
  containerClassName,
  isErrorNeeded = true,
  onChange,
  onSelectVariable,
  inputWrapperStyles,
  big,
  ...rest
}) => {
  const isRtl = useIsRtl()

  return (
    <div className={containerClassName}>
      {label && (
        <label htmlFor="name" className={cn(styles['label' + labelType], labelClassName)}>
          {label}
        </label>
      )}
      <div className={cn(styles.inputWrapper, inputWrapperStyles)}>
        <Input
          dir="auto"
          className={cn(
            styles.customInput,
            {
              [styles.big]: big,
              [styles.withVariable]: !!onSelectVariable,
              [styles.rtl]: isRtl,
            },
            className
          )}
          status={errorText?.length ? 'error' : undefined}
          onChange={onChange}
          {...rest}
        />
        {!!onSelectVariable && <Variables onSelectVariable={onSelectVariable} height={big ? 48 : void 0} />}
      </div>
      {isErrorNeeded && (
        <div
          className={cn(styles.errorText, {
            [styles.show]: errorText?.length,
          })}
        >
          {errorText?.map((text, i) => (
            <div key={`${text}-${i}`}>{text}</div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomInput
