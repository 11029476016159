import React from 'react'
import { Button, Space } from 'antd'
import { ArrowUpOutlined, PaperClipOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import CustomInput from 'components/CustomComponents/CustomInput'

import styles from './styles.module.scss'

const ChatInput: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <CustomInput
        disabled
        suffix={
          <Space>
            <Button disabled shape="circle" icon={<PaperClipOutlined />} size="small" type="text" />
            <Button
              disabled
              shape="circle"
              icon={<ArrowUpOutlined />}
              size="small"
              type="primary"
              className={styles.sendBtn}
            />
          </Space>
        }
        placeholder={t('chat.inputPlaceholder')}
        className={styles.input}
        isErrorNeeded={false}
      />
    </div>
  )
}

export default ChatInput
