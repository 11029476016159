import { Slider } from 'antd'
import React, { ReactNode } from 'react'

import cn from 'classnames'

import styles from './styles.module.scss'

interface IProps {
  isEditing: boolean
  value: string | number
  className?: string
  isRating?: boolean
  followUp?: string
  children: ReactNode
}

const ToolWatchMode: React.FC<IProps> = ({ children, isEditing, value, className, isRating, followUp }) => {
  return (
    <>
      {isEditing ? (
        children
      ) : (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: followUp ? `<span>${value}</span> <span>(${followUp})</span>` : String(value),
            }}
            className={cn(styles.watchModeText, className)}
          />
          {isRating && <Slider defaultValue={1} max={5} />}
        </>
      )}
    </>
  )
}

export default ToolWatchMode
