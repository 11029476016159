import { schedulerOptionEnum } from 'types/Template'

import { RepeatingTypeEnum, planningToolsType } from 'types/Template/Tools'

const checkIfNeedShedulerConfirm = ({
  repeatingOption,
  schedulerValue,
  thisTool,
}: {
  thisTool: planningToolsType
  schedulerValue: schedulerOptionEnum
  repeatingOption?: RepeatingTypeEnum
}) => {
  return (
    thisTool.id !== -1 &&
    (thisTool.schedulerOption !== schedulerOptionEnum.doNotRepeat ||
      (schedulerValue !== thisTool.schedulerOption && thisTool.schedulerOption !== schedulerOptionEnum.doNotRepeat)) &&
    repeatingOption === undefined
  )
}

export default checkIfNeedShedulerConfirm
