import { DownOutlined } from '@ant-design/icons'
import { Progress } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'stores'

import { IDailyReport, IDailyReportAnswer, IDailyReportUser } from 'types/Program'

import ExpandedRowRender from './ExpandedRowRender'

import CustomTable from 'components/CustomComponents/CustomTable'
import UserAvatar from 'components/UserAvatar'

import styles from './styles.module.scss'

interface IProps {
  item: IDailyReport
  handleExpandQuestions: (item: IDailyReportUser) => void
  handleCollapseQuestions: (item: IDailyReportUser) => void
  expandedRowKeys: string[]
  isShowOnlyFirsDayTrainee: boolean
  tableKeyField: string
}

const ReportTable: FC<IProps> = ({
  item,
  handleExpandQuestions,
  handleCollapseQuestions,
  expandedRowKeys,
  isShowOnlyFirsDayTrainee,
  tableKeyField,
}) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const columns = useMemo(
    () => [
      {
        title: isShowOnlyFirsDayTrainee ? '' : t<string>('programCreation.nameTable'),
        dataIndex: 'userName',
        key: 'userName',
        render: (value: string, row: IDailyReportUser) => {
          if (isShowOnlyFirsDayTrainee) return <></>

          return (
            <div className={styles.nameCol}>
              <UserAvatar avatarId={row?.avatarId || 1} />
              <div className={styles.name}>{value}</div>
            </div>
          )
        },
        width: '20%',
      },
      {
        title: t<string>('dailyReport.postViewed'),
        dataIndex: 'postViewCount',
        key: 'postViewCount',
        render: (value: string) => (
          <span className={styles.text}>
            {value}/{item.postCount}
          </span>
        ),
        width: '20%',
      },
      {
        title: t<string>('mentorDashboard.questionsAnswered'),
        dataIndex: 'questionAnsweredCount',
        key: 'questionAnsweredCount',
        render: (value: string, row: IDailyReportUser) => {
          const calc = (row.questionnaireAnsweredCount / row.questionnaireCount) * 100 || 0

          const percent = calc > 100 ? 100 : Math.round(calc)

          return (
            <span className={styles.progressContainer}>
              <Progress
                percent={percent}
                strokeColor={
                  percent > 80 ? '#52c41a' : percent >= 50 ? '#FCCA59' : percent > 30 ? '#F67F3C' : '#D75370'
                }
                trailColor="#DFE4EA"
                showInfo={false}
                className={styles.progress}
              />
              <span className={styles.text}>{percent}%</span>
            </span>
          )
        },
        width: '30%',
      },
      {
        title: t<string>('dailyReport.comments'),
        key: 'userQuestionsAndAnswers',
        dataIndex: 'userQuestionsAndAnswers',
        render: (value: IDailyReportAnswer[], row: IDailyReportUser) => {
          const totalComments = value.reduce((acc, cur) => {
            acc += cur?.coments?.length || 0
            return acc
          }, 0)

          return <div className={styles.text}>{totalComments}</div>
        },
        width: '20%',
      },
      {
        title: '',
        key: 'id',
        dataIndex: 'id',
        render: (value: string, row: IDailyReportUser) => {
          const isExpanded = expandedRowKeys.includes(row.id)

          return (
            <div
              className={styles.details}
              onClick={() => (isExpanded ? handleCollapseQuestions(row) : handleExpandQuestions(row))}
            >
              {isExpanded ? t<string>('dailyReport.hideDetails') : t<string>('dailyReport.showDetails')}{' '}
              <DownOutlined
                className={classNames(styles.arrow, {
                  [styles.active]: isExpanded,
                })}
              />
            </div>
          )
        },
        width: '20%',
      },
    ],
    [expandedRowKeys, userStore.language, isShowOnlyFirsDayTrainee, item.postCount]
  )

  return (
    <CustomTable
      variant="clear"
      rowKey={tableKeyField}
      columns={columns}
      dataSource={item.dailyReportModelForUsers}
      pagination={false}
      expandedRowKeys={expandedRowKeys}
      onExpand={(expanded, item) => (expanded ? handleExpandQuestions(item) : handleCollapseQuestions(item))}
      expandable={{
        expandedRowRender: expandedItem => <ExpandedRowRender user={expandedItem} report={item} />,
        rowExpandable: item => true,
        showExpandColumn: false,
      }}
      className={styles.table}
    />
  )
}

export default observer(ReportTable)
