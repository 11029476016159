import { Progress } from 'antd'
import { FC } from 'react'

interface IProps {
  percent: number
}

const ProgressBar: FC<IProps> = ({ percent }) => {
  return (
    <Progress
      width={50}
      percent={Math.round(percent)}
      type={'circle'}
      strokeColor="#004D98"
      trailColor="#CFE7FE"
      className="csvLoader"
    />
  )
}

export default ProgressBar
