import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { observer } from 'mobx-react-lite'
import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import { periodSelectEnum } from 'hooks/tanstack/queries/useActivityQuery'

import ProgramsLine from './ProgramsLine'

import CustomSelect from 'components/CustomComponents/CustomSelect'

import styles from './styles.module.scss'

interface IProps {
  currentSelectedProgramId: string
  userPrograms: {
    value: string | number
    label: string
  }[]
  handleProgramChange: (programId: number) => void
  searchValue: string
  setSearchValue: (search: string) => void
  periodSelect: periodSelectEnum
  setPeriodSelect: Dispatch<SetStateAction<periodSelectEnum>>
}

const DashboardHeader: FC<IProps> = ({
  currentSelectedProgramId,
  handleProgramChange,
  userPrograms,
  searchValue,
  setSearchValue,
  periodSelect,
  setPeriodSelect,
}) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const searchOptions = [
    { value: periodSelectEnum.WEEK, label: t('mentorDashboard.thisWeek') },
    { value: periodSelectEnum.MONTH, label: t('mentorDashboard.thisMonth') },
    { value: periodSelectEnum.HALF_YEAR, label: t('mentorDashboard.last6Month') },
  ]

  return (
    <>
      {!!userStore.fullUserName && (
        <div className={styles.greetMessage}>{`${t('dashboard.hello')}, ${userStore.fullUserName}`}</div>
      )}
      <div className={styles.dashboardHeader}>
        <ProgramsLine
          currentSelectedProgramId={currentSelectedProgramId}
          handleProgramChange={handleProgramChange}
          userPrograms={userPrograms}
        />
        <div className={styles.leastPerformersHeader}>
          <Input
            placeholder={t('mentorDashboard.search')}
            className={styles.customInput}
            suffix={<SearchOutlined />}
            onChange={e => setSearchValue(e.target.value)}
            value={searchValue}
            style={{ height: 38 }}
            data-dashboard-search="dashboard-search"
            id="dashboard-search"
          />
          <CustomSelect
            value={periodSelect}
            onChange={value => setPeriodSelect(value)}
            options={searchOptions}
            className={styles.select}
            key={userStore.language}
          />
        </div>
      </div>
    </>
  )
}

export default observer(DashboardHeader)
