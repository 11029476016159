import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import templatesApi from 'api/templates.api'

import useSearchWithPagination from 'hooks/useSearchWithPagination'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import { TEMPLATES_ON_PAGE } from 'utils/const'
import message from 'utils/message'

const useTemplateListQuery = () => {
  const { t } = useTranslation()

  const { debouncedSearch, pageNumber, search, setPageNumber, setSearch } = useSearchWithPagination()

  const {
    data: templatesWithPagination,
    isLoading,
    isFetching,
    isFetched,
  } = useQuery({
    keepPreviousData: true,
    queryKey: [QueryKeysEnum.TEMPLATES, +pageNumber || 1, debouncedSearch],
    queryFn: async () => {
      const response = await templatesApi.getLightTemplates(debouncedSearch, +pageNumber || 1, TEMPLATES_ON_PAGE)

      if (!response) throw new Error()

      const { items, ...paginationData } = response
      const { pageNumber: responsePageNumber, totalPages: responseTotalPages } = paginationData
      const lastPage = Math.max(1, responseTotalPages)

      if (responsePageNumber > lastPage) {
        setPageNumber(lastPage)
      }

      return { items, paginationData }
    },
    onError: () => {
      message.error(t<string>('programCreation.smtWrong'))
    },
  })

  return {
    templates: templatesWithPagination?.items,
    pagination: templatesWithPagination?.paginationData,
    isLoading,
    search,
    setSearch,
    setPageNumber,
    debouncedSearch,
    isFetching: isFetching && !isFetched,
  }
}

export default useTemplateListQuery
