import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Dispatch, FC, ReactNode, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import { planningToolsType, schedulerOptionEnum, schedulerSelectOptionEnum } from 'types/Template'
import { DayOfWeek } from 'types/Template/Tools'

import ToolTopBar from './ToolTopBar'

import ContentId from 'components/ContentId'
import CustomButton from 'components/CustomComponents/CustomButton'
import SchedulerSelect from 'components/SchedulerSelect'
import TagsSelector from 'components/TagsSelector'
import UpdateOrRemoveToolModal from 'components/UpdateOrRemoveToolModal'

import styles from './styles.module.scss'

interface IProps {
  id?: string
  isEditing: boolean
  handleSaveClick: () => void
  rollBackChanges: () => void
  handleToolEdit: () => void
  isFirstDrop: boolean
  toolNumber: number
  revealedAt: boolean | moment.Moment | undefined
  isUnbound?: boolean
  schedulerValue: number
  handleSelectSchedulerOption: (value: number) => void
  entityId?: number
  handleTagsChange: (ids: number[]) => void
  selectedTagIds: number[]
  onDelete: () => void
  savingLoading: boolean
  srcTool: planningToolsType
  isUpdating: boolean
  isSchedulerModalVisible: boolean
  setIsSchedulerModalVisible: Dispatch<SetStateAction<boolean>>
  handleSchedulerApplyModalOk: (value: schedulerSelectOptionEnum) => Promise<void>
  isSaveDisabled?: boolean
  dayId: number
  daysValue: DayOfWeek[]
  onDaysValueChange: (days: DayOfWeek[]) => void
  children: ReactNode
  isNewsMode?: boolean
  inSearch?: boolean
}

const ToolWrapper: FC<IProps> = ({
  children,
  id,
  handleSaveClick,
  isEditing,
  rollBackChanges,
  handleToolEdit,
  revealedAt,
  isFirstDrop,
  toolNumber,
  isUnbound,
  handleSelectSchedulerOption,
  schedulerValue,
  entityId,
  handleTagsChange,
  selectedTagIds,
  onDelete,
  savingLoading,
  srcTool,
  isSchedulerModalVisible,
  isUpdating,
  setIsSchedulerModalVisible,
  handleSchedulerApplyModalOk,
  isSaveDisabled,
  dayId,
  daysValue,
  onDaysValueChange,
  isNewsMode,
  inSearch,
  ...rest
}) => {
  const { t } = useTranslation()

  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const idToCopy = isUnbound ? id : templateEntity.currentDay?.id ? templateEntity.currentDay.id.toString() : id

  return (
    <>
      <div
        className={classNames(styles.wrapper, {
          [styles.wrapperDraggableEffect]: !isUnbound && !isNewsMode,
          [styles.wrapperUnbound]: !!isUnbound,
        })}
        id={id}
        data-id={id}
        {...rest}
      >
        <ToolTopBar
          isEditing={isEditing}
          revealedAt={revealedAt}
          handleToolEdit={handleToolEdit}
          isFirstDrop={isFirstDrop}
          idToCopy={idToCopy}
          toolNumber={toolNumber}
          isUnbound={isUnbound}
          onDelete={onDelete}
          dayId={dayId}
          isNewsMode={isNewsMode}
          inSearch={inSearch}
          {...rest}
        />
        {entityId && entityId !== -1 ? (
          <div className={styles.copyIdContainer}>
            <ContentId entityId={entityId} />
          </div>
        ) : (
          ''
        )}
        {children}
        {isEditing && !isUnbound && (
          <div style={{ margin: '10px 0' }} data-sub-content-2="subContent-2">
            <SchedulerSelect
              isEditing={isEditing}
              schedulerValue={schedulerValue}
              handleSelectSchedulerOption={handleSelectSchedulerOption}
              daysValue={daysValue}
              onDaysValueChange={onDaysValueChange}
            />
            <div className={styles.tags}>
              <TagsSelector selectedTagIds={selectedTagIds} handleTagsChange={handleTagsChange} />
            </div>
          </div>
        )}
        {isEditing && (
          <div className={styles.footerButtons} data-footer-buttons="footerButtons">
            <CustomButton onClick={rollBackChanges} data-cancel="cancel">
              {t('dashboard.cancel')}
            </CustomButton>
            <CustomButton
              type="primary"
              onClick={handleSaveClick}
              data-save="save"
              loading={savingLoading}
              disabled={isSaveDisabled}
            >
              {t('programCreation.save')}
            </CustomButton>
          </div>
        )}
      </div>
      {srcTool.schedulerOption !== schedulerOptionEnum.doNotRepeat && (
        <UpdateOrRemoveToolModal
          isUpdating={isUpdating}
          visible={isSchedulerModalVisible}
          onOk={handleSchedulerApplyModalOk}
          onCancel={() => setIsSchedulerModalVisible(false)}
        />
      )}
    </>
  )
}

export default observer(ToolWrapper)
