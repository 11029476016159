import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'

import {
  FollowUpActionsEnum,
  IDeleteIds,
  IQuestionnaireCheckBox,
  IQuestionnaireMultipleChoice,
  QuestionTypeEnum,
  deleteEntityTypeEnum,
} from 'types/Template'

import mergeTextAndVariable from 'utils/mergeTextAndVariable'

import QuestionEditActions from '../../QuestionEditActions'
import OptionItem from './OptionItem'

import CustomInput from 'components/CustomComponents/CustomInput'
import ToolWatchMode from 'components/ToolWatchMode'

import styles from '../styles.module.scss'

interface IProps {
  isCheckBox?: boolean
  question: string
  options: string[]
  questionIndex: number
  handleChange: (questionState: IQuestionnaireMultipleChoice | IQuestionnaireCheckBox, questionIndex: number) => void
  isEditing: boolean
  answerIds: string[]
  addEntityToDeleting: (entity: IDeleteIds) => void
  canValidate: boolean
  followUpAction: FollowUpActionsEnum[]
  followUpActionField: string[]
  handleTypeChange: (option: QuestionTypeEnum | null) => void
  handleDelete: (questionIndex: number) => void
  questionCount: number
  isUnbound?: boolean
  dayId?: number
  toolId?: number
}

const ChoiceType: React.FC<IProps> = ({
  question,
  options,
  handleChange,
  questionIndex,
  isCheckBox,
  isEditing,
  answerIds,
  addEntityToDeleting,
  canValidate,
  followUpAction,
  followUpActionField,
  handleTypeChange,
  handleDelete,
  questionCount,
  isUnbound,
  dayId,
  toolId,
}) => {
  const type = isCheckBox ? QuestionTypeEnum.CHECK_BOX : QuestionTypeEnum.MULTIPLE_CHOICE

  useEffect(() => {
    handleChange({ type, question, options, answerIds, followUpAction, followUpActionField }, questionIndex)
  }, [])

  const commonQuesrionState = {
    answerIds: answerIds || [],
    followUpAction: followUpAction || [],
    followUpActionField: followUpActionField || [],
  }

  const handleOptionChange = (
    value: string,
    index: number,
    newAction?: FollowUpActionsEnum,
    newActionField?: string
  ) => {
    options[index] = value

    if (!isCheckBox) {
      followUpAction[index] =
        newAction !== undefined ? newAction : followUpAction[index] || FollowUpActionsEnum.doNothing
      followUpActionField[index] = newActionField !== undefined ? newActionField : followUpActionField[index] || ''
    }

    handleChange(
      {
        type,
        question,
        options,
        ...commonQuesrionState,
      },
      questionIndex
    )
  }

  const handleAddOption = () => {
    if (!options || !options.length) {
      return handleChange(
        {
          type,
          question,
          options: [''],
          answerIds: answerIds || [],
          followUpAction: followUpAction?.length
            ? [...followUpAction, FollowUpActionsEnum.doNothing]
            : [FollowUpActionsEnum.doNothing],
          followUpActionField: followUpActionField?.length ? [...followUpActionField, ''] : [''],
        },
        questionIndex
      )
    }

    options.push('')

    if (!isCheckBox) {
      followUpAction.push(FollowUpActionsEnum.doNothing)
      followUpActionField.push('')
    }

    handleChange(
      {
        type,
        question,
        options,
        ...commonQuesrionState,
      },
      questionIndex
    )
  }

  const handleDeleteOption = (optionIndex: number) => {
    const filteredOptions = options.filter((_, index) => index !== optionIndex)
    const filteredFollowUpAction = followUpAction.filter((_, index) => index !== optionIndex)
    const filteredFollowUpActionFields = followUpActionField.filter((_, index) => index !== optionIndex)
    const filteredAnswerIds = answerIds.filter((id, index) => {
      if (index !== optionIndex) return true

      addEntityToDeleting({ entity: deleteEntityTypeEnum.answerOption, id: [+id] })

      return false
    })

    handleChange(
      {
        type,
        question,
        options: filteredOptions,
        answerIds: filteredAnswerIds,
        followUpAction: filteredFollowUpAction,
        followUpActionField: filteredFollowUpActionFields,
      },
      questionIndex
    )
  }

  const isError = canValidate && !question

  const scrollParam = useMemo(() => {
    const obj: any = {}

    if (isError) {
      obj['data-scroll'] = '1'
    }

    return obj
  }, [isError])

  return (
    <div {...scrollParam}>
      <ToolWatchMode isEditing={isEditing} value={question}>
        <div className={styles.ToolWatchModeInner}>
          <CustomInput
            className={classNames(styles.input, {
              [styles.error]: isError,
            })}
            style={{ marginBottom: 0 }}
            value={question}
            dir="ltr"
            onChange={e =>
              handleChange(
                {
                  type,
                  question: e.target.value,
                  options,
                  ...commonQuesrionState,
                },
                questionIndex
              )
            }
            onSelectVariable={variable =>
              handleChange(
                {
                  type,
                  question: mergeTextAndVariable(question, variable),
                  options,
                  ...commonQuesrionState,
                },
                questionIndex
              )
            }
            disabled={!isEditing}
            data-choice-question-input="choice-question-input"
            id="choice-question-input"
            inputWrapperStyles={styles.inputWrapper}
          />
          <QuestionEditActions
            canValidate={canValidate}
            handleDelete={handleDelete}
            handleTypeChange={handleTypeChange}
            isEditing={isEditing}
            questionCount={questionCount}
            questionIndex={questionIndex}
            questionType={isCheckBox ? QuestionTypeEnum.CHECK_BOX : QuestionTypeEnum.MULTIPLE_CHOICE}
          />
        </div>
      </ToolWatchMode>

      <div>
        {options?.map((optionValue, optionIndex) => (
          <OptionItem
            key={optionIndex}
            handleDelete={handleDeleteOption}
            value={optionValue}
            followUpAction={followUpAction}
            followUpActionField={followUpActionField}
            handleChange={handleOptionChange}
            index={optionIndex}
            optionsLength={options.length}
            isCheckBox={isCheckBox}
            isEditing={isEditing}
            canValidate={canValidate}
            isUnbound={isUnbound}
            dayId={dayId}
            toolId={toolId}
          />
        ))}

        {isEditing && (
          <OptionItem
            handleAddOption={handleAddOption}
            isCheckBox={isCheckBox}
            isEditing={isEditing}
            canValidate={canValidate && !options?.length}
          />
        )}
      </div>
    </div>
  )
}

export default observer(ChoiceType)
