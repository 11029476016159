import { CameraOutlined, CloseOutlined } from '@ant-design/icons'
import { Avatar, Image, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload'
import { FC } from 'react'

import { useStore } from 'stores'

import LoaderSmall from 'components/LoaderSmall'

import styles from './styles.module.scss'

interface IProps {
  userAvatar: string
  onChange: ({ file }: UploadChangeParam<UploadFile<any>>) => void
  onDelete: () => void
  loading: boolean
}

const AVATAR_SIZE = 240

const AvatarComponent: FC<IProps> = ({ userAvatar, onDelete, onChange, loading }) => {
  const { userStore } = useStore()

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.avatarContainer}>
        <Upload
          onChange={onChange}
          beforeUpload={() => false}
          className={styles.upload}
          maxCount={1}
          data-upload-picture="upload-picture"
          style={{ position: 'relative' }}
        >
          <div className={styles.uploadContent}>
            <CameraOutlined />
          </div>
        </Upload>
        <Avatar
          icon={
            loading ? (
              <div className={styles.loadingContainer}>
                <LoaderSmall />
              </div>
            ) : userAvatar ? (
              <Image
                src={userAvatar}
                style={{ width: AVATAR_SIZE, height: AVATAR_SIZE, objectFit: 'cover' }}
                preview={false}
              />
            ) : (
              <div className={styles.userInitials}>
                {userStore.user?.firstName?.[0]} {userStore.user?.lastName?.[0]}
              </div>
            )
          }
          style={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
        />
      </div>
      {userAvatar && (
        <div className={styles.removeIcon} onClick={onDelete}>
          <CloseOutlined />
        </div>
      )}
    </div>
  )
}

export default AvatarComponent
