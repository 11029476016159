import { Rule } from 'antd/lib/form'

import { t } from 'i18next'

export const basicStringFieldValidator = ({ required }: { required: boolean }) => {
  return (rule: Rule, value: string, callback: (error?: string) => void) => {
    const trimmedValue = value?.trim()

    if (!trimmedValue) {
      if (required) {
        callback(t<string>('programCreation.required'))
      } else {
        callback()
      }
    } else if (trimmedValue.length < 2 || trimmedValue.length > 25) {
      callback(
        t<string>('programCreation.minMaxLen', {
          min: 2,
          max: 25,
        })
      )
    } else {
      callback()
    }
  }
}
