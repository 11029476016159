import { message } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import useSaveTool from 'hooks/tools/useSaveTool'
import useSaveToolWithPlanning from 'hooks/tools/useSaveToolWithPlanning'
import useToolSchedule from 'hooks/tools/useToolSchedule'
import useToolScroll from 'hooks/tools/useToolScroll'
import useToolState from 'hooks/tools/useToolState'
import useToolTags from 'hooks/tools/useToolTags'

import { useStore } from 'stores'

import { IDefaultToolProps, ILinkDayItem, ITagWithToolRelation } from 'types/Template'
import { RepeatingTypeEnum, planingToolTypesEnum, schedulerOptionEnum } from 'types/Template/Tools'

import { urlRe } from 'utils/const'
import checkIfNeedShedulerConfirm from 'utils/tools/checkIfNeedShedulerConfirm'

import ToolRevealedPanel from 'modules/TemplateModule/DayItem/DayTool/ToolSwitch/ToolRevealedPanel'
import ToolWrapper from 'modules/TemplateModule/DayItem/DayTool/ToolSwitch/ToolWrapper'

import LinkItem from './LinkItem'

import CustomButton from 'components/CustomComponents/CustomButton'

import styles from './styles.module.scss'

const linksToServer = (links: ILocalLink[]): string[] => links.map(link => link.link)
const linksToClient = (links: string[]): ILocalLink[] => links.map(link => ({ link, id: v4(), touched: false }))

export interface ILocalLink {
  link: string
  id: string
  touched: boolean
}

let stateCopy: {
  isAllDaySelected: null | boolean
  revealedTime: undefined | moment.Moment
  linksValues: string[]
  schedulerValue: number
  entityTags: number[]
  daysSheduleValue: number[]
} = {
  linksValues: [],
  isAllDaySelected: null,
  revealedTime: undefined,
  schedulerValue: 1,
  entityTags: [],
  daysSheduleValue: [],
}

const LinkTool = ({ tool, toolIndex, isDraggingItem, isUnbound, dayId, dayIndex, onDelete }: IDefaultToolProps) => {
  const { t } = useTranslation()
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const [toolData, setToolData] = useState<ILinkDayItem | null>(null)
  const [isMounted, setIsMounted] = useState(false)
  const [links, setLinks] = useState<ILocalLink[]>([])

  const {
    isAllDaySelected,
    isEditing,
    isFirstDrop,
    isSchedulerModalVisible,
    isUpdating,
    revealedTime,
    setIsAllDaySelected,
    setIsEditing,
    setIsFirstDrop,
    setIsSchedulerModalVisible,
    setIsUpdating,
    setRevealedTime,
  } = useToolState()

  const thisTool = tool as ILinkDayItem

  const { handleTagsChange, selectedTagIds, setSelectedTagIds, selectedTags, entityTagsToRemove } = useToolTags({
    tags: thisTool.entityTags as ITagWithToolRelation[],
  })
  const { schedulerValue, setSchedulerValue, daysSheduleValue, setDaysScheduleValue } = useToolSchedule({
    initialSchedule: thisTool.schedulerOption,
    initialDaysSchedule: thisTool.daysSheduleValue,
  })

  const { onSave, savingLoading } = useSaveTool({ entityTagsToRemove, isUnbound, dayId })

  useEffect(() => {
    setIsMounted(true)
    const linksData = thisTool

    if (!linksData) return

    setToolData(linksData)

    if (!linksData?.linksValues[0]) {
      setIsFirstDrop(true)
      setIsEditing(true)
      handleAddTool()
      templateStore.setIsToolEditing(true, isUnbound)
    } else {
      setIsEditing(false)

      const linksDist: string[] = []

      linksData.linksValues.forEach(linksArr => {
        linksDist.push(...linksArr)
      })

      setLinks(linksToClient(linksDist))

      if (linksData?.isAllDaySelected) {
        setIsAllDaySelected(linksData.isAllDaySelected)
      } else {
        setRevealedTime(linksData.revealedTime ? moment(linksData.revealedTime).local() : undefined)
        setIsAllDaySelected(false)
      }
    }
  }, [thisTool])

  useToolScroll({
    isMounted,
    isFirstDrop,
  })

  useEffect(() => {
    if (isMounted) {
      if (isDraggingItem) return

      const linksData = thisTool

      if (!linksData) return

      setToolData(linksData)

      if (linksData?.isAllDaySelected) {
        setIsAllDaySelected(linksData.isAllDaySelected)
      } else {
        setRevealedTime(linksData.revealedTime ? moment(linksData.revealedTime).local() : undefined)
        setIsAllDaySelected(false)
      }
    }
  }, [thisTool])

  const validationBeforeActions = () => {
    setLinks(prev => prev.map(link => ({ ...link, touched: true })))

    if (!links.every(link => urlRe.test(link.link))) {
      return true
    }

    if (!revealedTime && !isAllDaySelected) {
      return true
    }

    if (schedulerValue === schedulerOptionEnum.specificDay && !daysSheduleValue.length) {
      message.error(t('templateCreation.selectAtLeastOneDay'))
      return true
    }

    return false
  }

  const handleSaveClick = async (repeatingOption?: RepeatingTypeEnum) => {
    if (validationBeforeActions()) return

    setIsUpdating(true)

    if (checkIfNeedShedulerConfirm({ repeatingOption, schedulerValue, thisTool })) {
      return setIsSchedulerModalVisible(true)
    }

    copyState()

    const tool: ILinkDayItem = {
      id: toolData?.id ?? -1,
      type: planingToolTypesEnum.LINK,
      linksValues: [linksToServer(links)],
      revealedTime: revealedTime?.toISOString(true).slice(0, -6) as string,
      isAllDaySelected,
      uniqueId: toolData?.uniqueId || v4(),
      fromDay: toolData?.fromDay,
      schedulerOption: schedulerValue,
      entityIds: toolData?.entityIds || [],
      entityTags: selectedTags,
      position: isFirstDrop ? (isUnbound ? 0 : templateEntity.lastPosition) : toolData?.position || toolIndex,
      repeatId: toolData?.repeatId || v4(),
      daysSheduleValue,
    }

    try {
      await onSave({ tool, repeatingOption })

      saveToolFlagsSet()
    } catch (e) {
      message.error(t<string>('programCreation.smtWrong'))
    }
  }

  const { onSaveWithPlanning } = useSaveToolWithPlanning({ onSave: handleSaveClick, setIsSchedulerModalVisible })

  const copyState = useCallback(() => {
    stateCopy = {
      linksValues: linksToServer(links),
      isAllDaySelected,
      revealedTime,
      schedulerValue,
      entityTags: selectedTagIds,
      daysSheduleValue,
    }
  }, [links, isAllDaySelected, revealedTime, schedulerValue, selectedTagIds])

  const handleToolEdit = () => {
    if (templateStore.checkIfToolEditing(isUnbound)) {
      message.error(t<string>('templateCreation.previousEdit'))
      return
    }

    if (!isEditing) {
      templateStore.setIsToolEditing(true, isUnbound)

      copyState()
    } else {
      rollBackChanges()
    }
    setIsEditing(prevState => !prevState)
  }

  const rollBackChanges = () => {
    if (isFirstDrop) {
      onDelete(() => setIsEditing(false))
      return
    }

    setDaysScheduleValue(stateCopy.daysSheduleValue)

    setLinks(linksToClient(stateCopy.linksValues))

    if (stateCopy.isAllDaySelected !== null) {
      setIsAllDaySelected(stateCopy.isAllDaySelected)
    }

    setSelectedTagIds(stateCopy.entityTags)

    setRevealedTime(stateCopy.revealedTime)

    if (stateCopy.schedulerValue) {
      setSchedulerValue(stateCopy.schedulerValue)
      handleSelectSchedulerOption(stateCopy.schedulerValue)
    }

    templateStore.setIsToolEditing(false, isUnbound)

    setIsEditing(false)
  }

  const handleSelectSchedulerOption = (value: number) => {
    if (schedulerValue === value || validationBeforeActions()) return

    setSchedulerValue(value)
  }

  const handleAddTool = () => {
    setLinks(prev => [...prev, { id: v4(), link: '', touched: false }])
  }

  const saveToolFlagsSet = () => {
    templateStore.setIsToolEditing(false, isUnbound)

    setIsEditing(false)
    setIsFirstDrop(false)
    setIsUpdating(false)
  }

  return (
    <ToolWrapper
      data-container-link="container-link"
      isEditing={isEditing}
      daysValue={daysSheduleValue}
      onDaysValueChange={setDaysScheduleValue}
      handleSaveClick={() => handleSaveClick()}
      rollBackChanges={rollBackChanges}
      id={isUnbound ? dayId?.toString() || '' : toolData?.uniqueId}
      handleToolEdit={handleToolEdit}
      isFirstDrop={isFirstDrop}
      revealedAt={isAllDaySelected || revealedTime}
      toolNumber={isUnbound ? dayIndex + 1 : toolIndex + 1}
      entityId={toolData?.id}
      isUnbound={isUnbound}
      handleSelectSchedulerOption={handleSelectSchedulerOption}
      schedulerValue={schedulerValue}
      handleTagsChange={handleTagsChange}
      selectedTagIds={selectedTagIds}
      onDelete={() => {
        onDelete(() => setIsEditing(false))
      }}
      savingLoading={savingLoading}
      handleSchedulerApplyModalOk={onSaveWithPlanning}
      isSchedulerModalVisible={isSchedulerModalVisible}
      isUpdating={isUpdating}
      setIsSchedulerModalVisible={setIsSchedulerModalVisible}
      srcTool={thisTool}
      dayId={dayId}
    >
      <div className={classNames(styles.linksContainer, { [styles.linksContainerPresent]: !isEditing })}>
        {links.map((link, idx) => (
          <LinkItem key={idx} idx={idx} isEditing={isEditing} link={link} setLinks={setLinks} />
        ))}
      </div>

      {isEditing && (
        <>
          <div data-handle-add-tool="handleAddTool">
            <CustomButton
              className={styles.addMoreBtn}
              onClick={handleAddTool}
              disabled={!isEditing}
              data-add-more="addMore"
              type="link"
            >
              {t<string>('templateCreation.addLink')}
            </CustomButton>
          </div>
          {!isUnbound && (
            <div data-sub-content-2="subContent-2">
              <ToolRevealedPanel
                isAllDaySelected={isAllDaySelected}
                isEditing={isEditing}
                onChangeCheckbox={e => setIsAllDaySelected(e.target.checked)}
                onChangeTimePicker={value => setRevealedTime(value ?? undefined)}
                revealedTime={revealedTime}
              />
            </div>
          )}
        </>
      )}
    </ToolWrapper>
  )
}

export default observer(LinkTool)
