import i18n from 'i18nextConf'
import { useMemo } from 'react'

import stores, { useStore } from 'stores'

import { LanguagesType } from 'types/common'

const useSyncLanguage = () => {
  const { userStore } = useStore()

  useMemo(() => {
    let lang = localStorage.getItem('i18nextLng')

    if (!lang) {
      lang = 'heMale'
    }

    i18n.changeLanguage(lang)
    localStorage.setItem('i18nextLng', lang)
    stores.userStore.setLanguage(lang as LanguagesType, !!userStore.user && !userStore.isAdmin)

    if (lang === 'heMale' || lang === 'heFemale' || lang === 'heUndefined' || lang === 'heSababa') {
      document.body.dir = 'rtl'
    } else {
      document.body.dir = 'ltr'
    }
  }, [userStore.user, userStore.isAdmin])
}

export default useSyncLanguage
