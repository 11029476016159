import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { ActiveRoute } from '../index'

import { useStore } from 'stores'

import styles from './styles.module.scss'

interface IRouteProps {
  to: string
  text: string
  permission: boolean
  textContent?: React.ReactNode
  activeRouteTo: ActiveRoute
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
}

const RouteSidebar = ({ to, Icon, text, permission, textContent, activeRouteTo }: IRouteProps) => {
  const { pathname } = useLocation()

  const { userStore } = useStore()

  const isRouteActive = pathname.startsWith(to)

  if (!permission) return <></>

  return (
    <NavLink
      to={to}
      className={classNames(styles.navigationBtn, {
        [styles.activeRoute]: isRouteActive,
      })}
      type="primary"
      data-nav-link={'nav-link-' + activeRouteTo}
      id={'nav-link-' + activeRouteTo}
    >
      <div data-nav-link={'nav-link-' + activeRouteTo}>
        <Icon color={isRouteActive ? userStore.brandData?.primaryColor || '#00AEEF' : '#8B8F98'} />
      </div>

      {textContent ?? <div className={styles.routeText}>{text}</div>}
    </NavLink>
  )
}

export default observer(RouteSidebar)
