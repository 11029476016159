import { Rule } from 'antd/lib/form'
import { t } from 'i18next'

export const maxWordsValidator = ({ number, required }: { number: number; required: boolean }) => {
  return (rule: Rule, value: string, callback: (error?: string) => void) => {
    const trimmedValue = value?.trim()

    if (!trimmedValue) {
      if (required) {
        callback(t<string>('programCreation.required'))
      } else {
        callback()
      }
    } else if (trimmedValue.split(' ').length > number) {
      callback(t<string>('shared.maxWords', { number }))
    } else {
      callback()
    }
  }
}
