import { Typography } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

import { useStore } from 'stores'

import styles from './styles.module.scss'

import logoUrl from 'sources/images/logo.svg'

const { Title } = Typography

interface IProps {
  dark?: boolean
}

const AimpactHeader: FC<IProps> = ({ dark }) => {
  const { userStore } = useStore()

  return (
    <div className={styles.logoContainer} data-logo-container="logo-container">
      <img className={styles.logo} src={userStore?.brandLogo || logoUrl} alt="Aimpact" data-logo="logo" />
      <Title className={classNames(styles.logoTitle, { [styles.logoTitleDark]: dark })} level={4} data-title="title">
        {userStore?.brandName || 'Aimpact'}
      </Title>
    </div>
  )
}

export default observer(AimpactHeader)
