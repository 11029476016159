import classNames from 'classnames'
import { FC, memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import { IComment, IDailyReportAnswer } from 'types/Program'

import decryptVariablesInString from 'utils/decryptVariablesInString'

import CommentsModal from './CommentsModal'

import CustomTable from 'components/CustomComponents/CustomTable'

import styles from './styles.module.scss'

interface IProps {
  dataSource: IDailyReportAnswer[]
  userId: number
  programName: string
  userName: string
  date: string
  phoneNumber: string
}

const QuestionsTable: FC<IProps> = ({ dataSource, userId, date, programName, userName, phoneNumber }) => {
  const { t } = useTranslation()

  const [firstName, lastName] = userName.split(' ')

  const { userStore } = useStore()

  const [visibleModalId, setVisibleModalId] = useState('')

  const columns = useMemo(() => {
    const decryptQuestion = (text: string) =>
      decryptVariablesInString({
        text,
        date,
        language: userStore.language,
        mentorFirstName: userStore.user?.firstName || '',
        mentorLastName: userStore.user?.lastName || '',
        programName,
        userFirstName: firstName,
        userLastName: lastName,
        phoneNumber,
      })

    return [
      {
        dataIndex: 'question',
        key: 'question',
        render: (text: string, row: IDailyReportAnswer) => (
          <div className={styles.question}>{decryptQuestion(text || '')}</div>
        ),
        width: '35%',
      },
      {
        dataIndex: 'answer',
        key: 'answer',
        render: (value: string) => <div className={classNames(styles.question, styles.answer)}>{value || '-'}</div>,
        width: '45%',
      },
      {
        dataIndex: 'coments',
        key: 'coments',
        render: (value: IComment[], row: IDailyReportAnswer, idx: number) => {
          const rowCopied = { ...row }

          rowCopied.question = decryptQuestion(row.question || '')

          return (
            <>
              <div className={styles.comments} onClick={() => setVisibleModalId(`${row.questionId}-${idx}`)}>
                {value?.length || 0} {t<string>('dailyReport.comments')}
              </div>
              <CommentsModal
                commentsData={rowCopied}
                visible={`${row.questionId}-${idx}` === visibleModalId}
                onClose={() => setVisibleModalId('')}
                userId={userId}
              />
            </>
          )
        },
        width: '20%',
      },
    ]
  }, [visibleModalId, userId, date, programName, userName, userStore.language])

  if (!dataSource.length) return <></>

  return (
    <div className={styles.container}>
      <CustomTable
        variant="transparent"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        showHeader={false}
        rowKey="questionId"
      />
    </div>
  )
}

export default memo(QuestionsTable)
