import { Dropdown } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'

import styles from './styles.module.scss'

import { ReactComponent as BurgerPic } from 'sources/images/burger.svg'

interface IProps {
  items: ItemType[]
}

const ActionsDropdown = ({ items }: IProps) => {
  return (
    <div className={styles.actionButtons}>
      <Dropdown menu={{ items }} placement="bottomRight" overlayClassName={styles.menu}>
        <BurgerPic width={20} height={20} style={{ color: '#80838C', cursor: 'pointer' }} />
      </Dropdown>
    </div>
  )
}

export default ActionsDropdown
