import { ColProps, FormInstance } from 'antd'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInputWithCountrySelect, { Country, isValidPhoneNumber } from 'react-phone-number-input'

import { E164Number } from 'libphonenumber-js/types'

import LS, { LSKeys } from 'utils/LS'
import { getCountryCode } from 'utils/helpers'

import CustomFormItem from 'components/CustomComponents/CustomFormItem'

import styles from './styles.module.scss'

const userPhone = LS.get(LSKeys.PHONE)

interface IProps {
  form: FormInstance<any>
  customStyles?: string
  noLabel?: boolean
  customName?: string
  customLabel?: string
  placeholder?: string
  additionalStyles?: string
  noStyle?: boolean
  isFromList?: boolean
  isWithAnotherFields?: boolean
  indexInList?: number
  labelCol?: ColProps
  defaultValue?: string
  isTrainer?: boolean
  excludeNumbers?: string[]
}

const PhoneNumberField = ({
  form,
  additionalStyles,
  customName,
  customLabel,
  customStyles,
  noLabel,
  placeholder,
  noStyle,
  isFromList,
  indexInList,
  labelCol,
  isWithAnotherFields,
  defaultValue,
  isTrainer,
  excludeNumbers,
  ...rest
}: IProps) => {
  const { t } = useTranslation()
  const [phoneValue, setPhoneValue] = useState<E164Number>('')
  const [countryCode, setCountryCode] = useState<Country>()

  useEffect(() => {
    getUserCountryLocation()

    if (defaultValue) {
      setPhoneValue(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    if (defaultValue) {
      form.setFields([
        {
          name: !isWithAnotherFields ? customName || 'phoneNumber' : [indexInList || 0, 'phoneNumber'],
          value: phoneValue,
        },
      ])
    }
  }, [phoneValue, defaultValue, customName, indexInList, isWithAnotherFields])

  const getUserCountryLocation = async () => {
    const cachedCountryCode = LS.get(LSKeys.COUNTRY)
    if (cachedCountryCode) {
      setCountryCode(cachedCountryCode)
    } else {
      const countryCode = getCountryCode()

      if (countryCode) {
        setCountryCode(countryCode)
        LS.set(LSKeys.COUNTRY, countryCode)
      }
    }
  }

  const handlePhoneInput = (value: E164Number | undefined) => {
    if (value) {
      if (isFromList && customName && indexInList !== undefined) {
        if (isWithAnotherFields) {
          const fieldValues = form.getFieldValue(customName) || []

          fieldValues[indexInList].phoneNumber = value

          form.setFields([{ name: customName, value: fieldValues }])
          return
        }

        const valueArr = form.getFieldValue(customName) || []

        valueArr[indexInList] = value
        form.setFields([{ name: customName, value: valueArr }])
        return
      }

      form.setFields([{ name: customName || 'phoneNumber', value }])
      setPhoneValue(value)
    }
  }

  return (
    <CustomFormItem
      name={!isWithAnotherFields ? customName || 'phoneNumber' : [indexInList || 0, 'phoneNumber']}
      label={noLabel ? null : customLabel ? customLabel : t('programCreation.phoneNumber')}
      noStyle={noStyle || undefined}
      labelCol={labelCol}
      {...rest}
      data-form-item="form-item"
      className={styles.inputContainer}
      rules={[
        () => ({
          validator(_, value) {
            if (!value) {
              return Promise.reject(new Error(t('programCreation.required')))
            }
            if (value.indexOf('+') === -1) {
              return Promise.reject(new Error(t('login.plusSign')))
            } else if (value === '+1225809951') {
              return Promise.resolve()
            } else if (!isValidPhoneNumber(value)) {
              return Promise.reject(new Error(t('programCreation.validationPhone')))
            } else if (value === userPhone && isTrainer) {
              return Promise.reject(new Error(t('programCreation.selfInviteError')))
            } else if (excludeNumbers && excludeNumbers.includes(value)) {
              return Promise.reject(new Error(t('programCreation.alreadyInvited')))
            }

            return Promise.resolve()
          },
        }),
      ]}
    >
      <PhoneInputWithCountrySelect
        placeholder={placeholder || t('login.placeholder')}
        value={phoneValue}
        onChange={handlePhoneInput}
        className={customStyles || cn(styles.phoneInput, additionalStyles)}
        defaultCountry={countryCode}
        international
        data-phone-country="phone-country"
      />
    </CustomFormItem>
  )
}

export default PhoneNumberField
