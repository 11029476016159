import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import scheduleNotificationApi from 'api/scheduleNotification.api'

import usePagination from 'hooks/usePagination'

import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { NotificationStatus } from 'types/ScheduleNotification'

import message from 'utils/message'

interface IProps {
  scheduleId?: number
  statusFilter: NotificationStatus | null
  search: string
}

const PAGE_LENGTH = 10

const useScheduleHistoryQuery = ({ scheduleId, statusFilter, search }: IProps) => {
  const { t } = useTranslation()

  const { pagination, propsToPaginationComponent, setPagination } = usePagination({
    pageLength: PAGE_LENGTH,
    hideSizer: true,
  })

  const {
    data: notificationHistory,
    isLoading,
    isError,
    isFetching,
    isFetched,
  } = useQuery({
    keepPreviousData: true,
    enabled: !!scheduleId,
    queryKey: [
      QueryKeysEnum.SCHEDULED_NOTIFICATION_HISTORY,
      scheduleId,
      statusFilter,
      search,
      pagination.pageNumber || 1,
    ],
    queryFn: async () => {
      if (!scheduleId) return []

      const response = await scheduleNotificationApi.getScheduleNotificationHistory({
        scheduleId,
        pageNumber: pagination.pageNumber || 1,
        pageLength: PAGE_LENGTH,
        status: statusFilter || void 0,
        search,
      })

      setPagination(prev => {
        const paginationToSet = {
          ...prev,
          itemCount: response.itemCount,
          totalItems: response.totalItems,
          totalPages: response.totalPages,
        }

        if (pagination.pageNumber > response.totalPages) {
          paginationToSet.pageNumber = response.totalPages
        }

        return paginationToSet
      })

      return response.items
    },
    onError: e => {
      console.log('e', e)
      message.error(t<string>('programCreation.smtWrong'))
    },
  })

  return {
    notificationHistory,
    isLoading,
    isError,
    propsToPaginationComponent,
    setPagination,
    isFetching: isFetching && !isFetched,
  }
}

export default useScheduleHistoryQuery
