import { IImageDayItem } from 'types/Template'

const generateFormData = async (fileList: IImageDayItem['fileList'], isImage?: boolean) => {
  let mimeType = ''
  const formData = new FormData()
  const formDataKey = isImage ? 'pictures' : 'files'

  for (let i = 0; i < fileList.length; i++) {
    const item = fileList[i]

    if (item?.type) mimeType = item.type
    if (item?.uid === '-1' && item?.url) {
      const file = await fetch(item.url)
        .then(r => r.blob())
        .then(blobFile => new File([blobFile], 'file 1'))

      formData.append(formDataKey, file)
    } else if (item?.originFileObj) {
      formData.append(formDataKey, item.originFileObj)
    } else {
      formData.append(formDataKey, item as any)
    }
  }
  return { formData, mimeType }
}

export default generateFormData
