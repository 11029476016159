import { t } from 'i18next'
import { action, makeAutoObservable } from 'mobx'

import wallPostApi from 'api/wallPost.api'

import { IDayData, ITemplateInfo } from 'types/Template'
import { IUnboundContentWallPosts } from 'types/UnboundContent'

import message from 'utils/message'
import { templateParserFromServer, UnboundTemplateToServerParser } from 'utils/parseTemplate'
import removeIdFromItem from 'utils/removeIdFromItem'

import FollowUpStore from './DI-Stores/FollowUpContent'
import UnboundStore from './DI-Stores/UnboundContent'

class Store {
  constructor() {
    makeAutoObservable(this)
  }

  public unboundContentStore = UnboundStore
  public followUpStore = FollowUpStore

  sourceTemplateData: IDayData[] = []

  @action
  async postUnboundContentAPI(dayData: IDayData[], programId: number) {
    const unboundSorted = new UnboundTemplateToServerParser(dayData, programId).run()

    const mapped = unboundSorted.map(item => item?.toCreateOrUpdateFromWallPosts)

    const toUpdate: IUnboundContentWallPosts[] = []
    const toCreate: IUnboundContentWallPosts[] = []

    mapped.forEach(item => {
      if (!item) return

      if (!item?.id || item.id === -1) {
        toCreate.push(item)
      } else {
        toUpdate.push(item)
      }
    })

    if (toUpdate.length) {
      await wallPostApi.updateUnboundRange(toUpdate as IUnboundContentWallPosts[])
    }

    if (toCreate.length) {
      const toCreateWithoutIds = toCreate.map(item => ({
        ...item,
        programWallPostLinks: item.programWallPostLinks.map(removeIdFromItem),
        programWallPostStoredFiles: item.programWallPostStoredFiles.map(removeIdFromItem),
        programWallPostSummaries: item.programWallPostSummaries.map(removeIdFromItem),
        questionnaires: item.questionnaires.map(removeIdFromItem),
        scenarios: item.scenarios.map(removeIdFromItem),
      }))

      await wallPostApi.createUnbound(programId, toCreateWithoutIds as IUnboundContentWallPosts[])
    }

    await this.setUnboundContentAPI(programId)
  }

  @action
  async deleteUnboundDay(id: number, programId: number, isFirstDrop: boolean) {
    this.unboundContentStore.deleteUnboundDay(id)

    if (isFirstDrop) return

    await wallPostApi.deleteUnboundContent([id])
    this.unboundContentStore.resetUnboundEntitiesToDelete()
    await this.setUnboundContentAPI(programId)
  }

  @action
  async setUnboundContentAPI(programId: number) {
    try {
      this.unboundContentStore.unboundContentLoading = true

      const unboundContent = await wallPostApi.getUnboundWallPosts(programId)

      const days = templateParserFromServer.setWallPostsData(unboundContent).run()

      this.unboundContentStore.setUnboundContent(days)
      this.followUpStore.setAvailableContentForFollowUp(days)
      this.followUpStore.setFollowUpUnboundContent(days)
    } catch (e) {
    } finally {
      this.unboundContentStore.unboundContentLoading = false
    }
  }

  async getWallPostById(programId: string, pageNumber: number): Promise<void | ITemplateInfo> {
    try {
      const wallPost = await wallPostApi.getWallPost({ pageNumber, programId: +programId })

      const obj = { ...wallPost, items: wallPost.items }

      const result = templateParserFromServer.setWallPostsData(obj).run()

      const sortedArr = result.map(item => ({
        ...item,
        dayItems: item.dayItems.sort((a, b) => (a.position as number) - (b.position as number)),
      }))

      this.sourceTemplateData = sortedArr

      return {
        templateData: sortedArr,
        name: wallPost.name,
        duration: wallPost.duration,
        createdAt: wallPost.createdAt,
        id: wallPost?.id || 0,
        templateProgramGoals: wallPost?.templateProgramGoals || [],
      }
    } catch (e: any) {
      console.error(e)
      message.error(t<string>('programCreation.smtWrong'))
    }
  }
}

export default new Store()
