import { useQueryClient } from '@tanstack/react-query'
import { Form } from 'antd'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useCopyProgramMutation from 'hooks/tanstack/mutations/useCopyProgramMutation'

import { useStore } from 'stores'

import { IProgramList } from 'types/Program'
import { QueryKeysEnum } from 'types/QueryKeysEnum'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomInput from 'components/CustomComponents/CustomInput'
import CustomModal from 'components/CustomComponents/CustomModal'

import message from 'utils/message'

import styles from './styles.module.scss'

interface IProps {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
  selectedProgram: IProgramList | undefined
  fromList?: boolean
}

const minMaxValidation = {
  min: 2,
  max: 25,
}

const CopyProgramModal: FC<IProps> = ({ isVisible, setIsVisible, selectedProgram, fromList }) => {
  const [form] = Form.useForm()

  const { userStore } = useStore()

  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const { copyProgram, isLoading } = useCopyProgramMutation({
    selectedProgram,
    onSuccess: () => {
      setIsVisible(false)
      form.resetFields()

      if (fromList) {
        queryClient.removeQueries([QueryKeysEnum.PROGRAMS])
      }
    },
  })

  useEffect(() => {
    form.resetFields()
    message.destroy()
  }, [userStore.language])

  return (
    <CustomModal
      title={t<string>('programList.programName')}
      open={isVisible}
      footer={null}
      onCancel={() => setIsVisible(false)}
      bodyStyle={{ paddingBottom: 1 }}
      data-modal-1="modal-1"
      forceRender={true}
    >
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={copyProgram}
        form={form}
        data-form-4="form-4"
        requiredMark={false}
      >
        <Form.Item
          label={t<string>('programList.programNameField')}
          name="programName"
          rules={[
            { required: true, message: t<string>('programCreation.required'), whitespace: true },
            {
              ...minMaxValidation,
              message: t<string>('programCreation.minMaxLen', minMaxValidation),
            },
          ]}
          data-program-name="form-programName"
        >
          <CustomInput data-program-name-input="programName-input" isErrorNeeded={false} className={styles.copyInput} />
        </Form.Item>
        <Form.Item className={styles.buttonRight} data-button-right="button-right">
          <CustomButton type="primary" loading={isLoading} htmlType="submit" data-btn-submit="btn-submit">
            {t<string>('programList.copy')}
          </CustomButton>
        </Form.Item>
      </Form>
    </CustomModal>
  )
}

export default CopyProgramModal
