import { useEffect, useState } from 'react'

import { useStore } from 'stores'

const useDefineDirection = () => {
  const [direction, setDirection] = useState<'rtl' | 'ltr'>('ltr')

  const { userStore } = useStore()

  useEffect(() => {
    if (!userStore.language) return

    if (userStore.language === 'en' || userStore.language === 'ru') {
      setDirection('ltr')
    } else {
      setDirection('rtl')
    }
  }, [userStore.language])

  return { direction }
}

export default useDefineDirection
