export enum BrandType {
  Default,
  Events,
}

export interface ICompanyBrand {
  companyId: number
  companyName: string
  slug: string
}
