import React from 'react'

import useImageQuery from 'hooks/tanstack/queries/useImageQuery'
import { FileMessageDto } from 'api/swagger.api'
import Loader from 'components/Loader'

import styles from './styles.module.scss'

type Props = {
  file: FileMessageDto
}

const ChatMessageImage: React.FC<Props> = ({ file }) => {
  const { image, isLoading } = useImageQuery({ id: file.fileId })

  return (
    <div className={styles.container}>
      {isLoading && <Loader />}
      {image && <img src={image} alt="chat image message" className={styles.image} />}
    </div>
  )
}

export default ChatMessageImage
