import { RiseOutlined } from '@ant-design/icons'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { IChartData } from 'types/MentorDashboard'

import AttendanceInfoItem from './AttandanceInfoItem'
import Chart from './Chart'

import CustomButton from 'components/CustomComponents/CustomButton'

import styles from './styles.module.scss'

import { ReactComponent as ProgramsIcon } from 'sources/images/sidebar/Programs.svg'
import { ReactComponent as TraineesIcon } from 'sources/images/sidebar/Trainees.svg'

interface IProps {
  chartData: IChartData[]
  infoData: {
    programs: number | null | string
    trainees: number
    days: number | null
  }
  currentSelectedProgramId: string
}

const Attendance: FC<IProps> = ({ chartData, infoData, currentSelectedProgramId }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <div className={styles.attendanceBlock}>
      <div className={styles.attendanceInfo}>
        {!currentSelectedProgramId && (
          <AttendanceInfoItem
            Icon={<ProgramsIcon stroke="#8B8F98" fill="#ededed" color="#8B8F98" />}
            title={infoData.programs ? t<string>('programList.programsText') : t<string>('templateCreation.daysSmall')}
            value={(infoData.programs ?? infoData.days) || 0}
          />
        )}
        <AttendanceInfoItem
          Icon={<TraineesIcon />}
          title={t<string>('programList.trainees')}
          value={infoData.trainees}
        />
      </div>
      <div className={styles.chartTitle}>
        <div>{t<string>('mentorDashboard.attendance')}</div>
        <CustomButton
          icon={<RiseOutlined />}
          size="small"
          onClick={() => navigate(currentSelectedProgramId ? '/report/' + currentSelectedProgramId : '/report/')}
          data-dashboard-report-btn="dashboard-report-btn"
          id="dashboard-report-btn"
        >
          {t<string>('programList.dailyReport')}
        </CustomButton>
      </div>
      <div className={styles.attendanceContainer}>
        <div className={styles.attendanceChart} style={{ minWidth: chartData.length * 50 }}>
          {!chartData.length ? (
            <div>{t<string>('mentorDashboard.noActivity')}</div>
          ) : (
            <Chart data={chartData} key={currentSelectedProgramId} />
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(Attendance)
