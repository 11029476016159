import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import programsApi from 'api/programs.api'

import useSearchWithPagination from 'hooks/useSearchWithPagination'

import { useStore } from 'stores'

import { PROGRAMS_ON_PAGE } from 'utils/const'
import message from 'utils/message'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

const useProgramListQuery = () => {
  const { userStore } = useStore()

  const { t } = useTranslation()

  const { pageNumber, search, setPageNumber, setSearch, debouncedSearch } = useSearchWithPagination()

  const {
    data: programsWithPagination,
    isLoading,
    isFetching,
    isFetched,
  } = useQuery({
    keepPreviousData: true,
    queryKey: [QueryKeysEnum.PROGRAMS, debouncedSearch, +pageNumber || 1],
    queryFn: async () => {
      if (!userStore.user) return

      const response = await programsApi.getUserProgramsWithPagination({
        query: debouncedSearch,
        pageLength: PROGRAMS_ON_PAGE,
        pageNumber: +pageNumber || 1,
      })

      if (!response) throw new Error()

      const { totalPages: responseTotalPages } = response
      const lastPage = Math.max(responseTotalPages, 1)

      if (+pageNumber > lastPage) {
        setPageNumber(lastPage)
      }

      const { items, ...paginationInfo } = response

      return {
        data: items,
        paginationInfo,
      }
    },
    onError(e) {
      message.error(t<string>('programList.listLoadError'))
    },
  })

  return {
    search,
    setSearch,
    programs: programsWithPagination?.data,
    pagination: programsWithPagination?.paginationInfo
      ? { ...programsWithPagination.paginationInfo, pageNumber: +pageNumber }
      : undefined,
    isLoading,
    debouncedSearch,
    fetching: isFetching && !isFetched,
    setPageNumber,
  }
}

export default useProgramListQuery
