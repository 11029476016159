import { FC, useMemo } from 'react'

import { IDailyReport, IDailyReportAnswer, IDailyReportUser } from 'types/Program'
import { ButtonType } from 'types/Report'

import GoalsTable from './GoalsTable'
import CommonTypeTable from './PlanBtn/CommonTypeTable'
import PlanBtnQuestionTable from './PlanBtn/PlanBtnQuestionTable'
import QuestionsTable from './QuestionsTable'

import CustomTable from 'components/CustomComponents/CustomTable'
import styles from './styles.module.scss'

interface IProps {
  user: IDailyReportUser
  report: IDailyReport
}

const ExpandedRowRender: FC<IProps> = ({ report, user }) => {
  const questions = useMemo(() => {
    const questions: IDailyReportAnswer[] = []
    const unboundQuestions: IDailyReportAnswer[] = []

    user.userQuestionsAndAnswers.forEach(question => {
      if (question.isBound) {
        unboundQuestions.push(question)
      } else {
        questions.push(question)
      }
    })

    return { questions, unboundQuestions }
  }, [user.userQuestionsAndAnswers])

  const noData = !questions.questions.length && !questions.unboundQuestions && !user.userGoalChecks

  return (
    <div className={styles.container}>
      {!noData ? (
        <>
          <QuestionsTable
            dataSource={questions.questions}
            userId={user.userId}
            programName={report.trainingProgramName}
            userName={user.userName}
            date={report.dateTime}
            phoneNumber={user.userPhoneNumber}
          />
          <GoalsTable goals={user.userGoalChecks} />
          {user.buttonType === ButtonType.Questionnaires ? (
            <PlanBtnQuestionTable
              questions={questions.unboundQuestions}
              date={report.dateTime}
              userName={user.userName}
              programName={report.trainingProgramName}
              clicked={user.buttonsClicksCount}
              type={user.buttonType}
              phoneNumber={user.userPhoneNumber}
            />
          ) : (
            <CommonTypeTable clicked={user.buttonsClicksCount} type={user.buttonType} />
          )}
          {/* <FilesTable files={itemInDTO?.programWallPostStoredFiles} clicked={user.buttonsClicksCount} />
          <SummariesTable summaries={itemInDTO?.programWallPostSummaries} clicked={user.buttonsClicksCount} />
          <LinksTable links={itemInDTO?.programWallPostLinks} clicked={user.buttonsClicksCount} /> */}
        </>
      ) : (
        <CustomTable variant="clear" dataSource={[]} />
      )}
    </div>
  )
}

export default ExpandedRowRender
