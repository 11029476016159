import { Rule } from 'antd/lib/form'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import programsApi from 'api/programs.api'

import { useStore } from 'stores'

const onlyNumbersRe = /^\d+$/
const numbersAndLettersRe = /^[a-zA-Z0-9\s\u0410-\u044F\u0401\u0451\u05D0-\u05EA]+$/

const useProgramValidate = ({ programId }: { programId?: number }) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const goalValidator = useCallback(
    (rule: Rule, value: string, callback: (error?: string) => void) => {
      const trimmedValue = value?.trim()
      if (!trimmedValue) {
        callback(t<string>('programCreation.required'))
      } else if (trimmedValue.length < 2) {
        callback(t<string>('programCreation.minLen'))
      } else {
        callback()
      }
    },
    [userStore.language]
  )

  const publicIdValidator = useCallback(
    async (rule: Rule, value: string) => {
      const trimmedValue = value?.trim()

      if (!trimmedValue) {
        return Promise.reject(t<string>('programCreation.required'))
      }

      if (onlyNumbersRe.test(trimmedValue)) {
        return Promise.reject(t<string>('programCreation.uniqueIdAtLeastOneLetter'))
      }

      if (!numbersAndLettersRe.test(trimmedValue) || trimmedValue.includes('-')) {
        return Promise.reject(t<string>('programCreation.uniqueIdOnlyLettersAndNumbers'))
      }

      try {
        const res = await programsApi.getProgramByUniqueId(trimmedValue)

        if (programId) {
          if (res.id === programId) {
            return Promise.resolve()
          }
        }
      } catch (e) {
        if (e.response.data.StatusCode !== 400) {
          return Promise.resolve()
        }

        return Promise.reject(t<string>('programCreation.uniqueIdUsed'))
      }

      return Promise.reject(t<string>('programCreation.uniqueIdUsed'))
    },
    [userStore.language, programId]
  )

  return {
    goalValidator,
    publicIdValidator,
  }
}

export default useProgramValidate
