import { DeliveryStatusEnum } from 'containers/Private/Mentor/SendMessage'
import usePostMessageClickMutation from 'hooks/tanstack/mutations/usePostMessageClick'
import { useState } from 'react'

const useSendMessage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userInfo, setUserInfo] = useState({ userId: 0, templateID: 0 })
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState({ pageNumber: 1, pageLength: 10 })
  const { mutateAsync, isLoading: LoadingButton } = usePostMessageClickMutation()
  const handleOk = () => {
    setIsModalOpen(false)
    handleClick(userInfo.templateID, userInfo.userId)
    setUserInfo({ userId: 0, templateID: 0 })
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const handleButtonClick = (userId: number, templateID: number, deliveryStatus: DeliveryStatusEnum) => {
    setUserInfo({ userId, templateID })
    if (deliveryStatus === DeliveryStatusEnum.Read && !isModalOpen) {
      setIsModalOpen(true)
    } else {
      handleClick(templateID, userId)
    }
  }
  const handleSearchChange = (e: string) => {
    setSearch(e)
    setPagination(prev => ({ ...prev, pageNumber: 1 }))
  }
  const handleClick = async (templateId: number, userId: number) => {
    try {
      await mutateAsync({ templateId, userId })
    } catch (e) {
      console.log(e)
    } finally {
    }
  }
  return {
    isModalOpen,
    search,
    pagination,
    LoadingButton,
    setPagination,
    handleOk,
    handleCancel,
    handleButtonClick,
    handleSearchChange,
  }
}
export default useSendMessage
