import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

interface IProps {
  toolNumber: number
  isEditing: boolean
  revealedAt?: boolean | moment.Moment
  closeToolTime?: moment.Moment
  isUnbound?: boolean
  idToCopy?: string
  isNewsMode?: boolean
  inSearch?: boolean
}

const ToolTitle: React.FC<IProps> = ({
  toolNumber,
  revealedAt,
  isEditing,
  closeToolTime,
  isUnbound,
  idToCopy,
  isNewsMode,
  inSearch,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <div data-file-image-outlined="FileImageOutlined" className={styles.toolTitle}>
        {`${t('templateCreation.content')}${inSearch ? '' : ' ' + toolNumber + ':'}`}
        <div>{!!isUnbound && idToCopy && <div>{`${t('templateCreation.planBtnUsage')}: ${idToCopy}`}</div>}</div>
      </div>
      {!isEditing && !isUnbound && !isNewsMode && (
        <div className={styles.visibleAt}>
          {revealedAt && typeof revealedAt === 'boolean'
            ? t('templateCreation.visibleAllDay')
            : closeToolTime
            ? t('programCreation.questionAvailablePart1', {
                selectFrom: moment(revealedAt as moment.Moment).format('hh:mm A'),
                selectTo: moment(closeToolTime as moment.Moment).format('hh:mm A'),
              })
            : `${t('templateCreation.visibleAt')} ${moment(revealedAt as moment.Moment).format('hh:mm A')}`}
          {}
        </div>
      )}
    </div>
  )
}

export default ToolTitle
