import { FollowUpPostType, planingToolTypesEnum } from 'types/Template'

const localToolTypeToServerToolType = (type: planingToolTypesEnum) => {
  switch (type) {
    case planingToolTypesEnum.AUDIO:
      return FollowUpPostType.File
    case planingToolTypesEnum.FILE:
      return FollowUpPostType.File
    case planingToolTypesEnum.IMAGE:
      return FollowUpPostType.File
    case planingToolTypesEnum.VIDEO:
      return FollowUpPostType.File
    case planingToolTypesEnum.LINK:
      return FollowUpPostType.Link
    case planingToolTypesEnum.QUESTIONNAIRE:
      return FollowUpPostType.Questionnaire
    case planingToolTypesEnum.SUMMARY:
      return FollowUpPostType.Summary
    case planingToolTypesEnum.SCENARIO:
      return FollowUpPostType.Scenario
    default:
      return 0
  }
}

export default localToolTypeToServerToolType
