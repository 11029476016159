import { authApi } from 'config'

const controller = 'QuestionComment'

const questionCommentApi = {
  createComment: async ({
    comment,
    questionId,
    traineeId,
  }: {
    comment: string
    questionId: number
    traineeId: number
  }) => {
    try {
      const response = await authApi.post(`${controller}/createComment`, {
        comment,
        questionId,
        traineeId,
      })

      return response.data
    } catch (e) {
      console.log(e, '-> createComment api')
    }
  },
  removeComment: async (commentId: number) => {
    try {
      const response = await authApi.delete(`${controller}/deleteComment/${commentId}`)

      return response.status < 300
    } catch (e) {
      console.log(e)
    }
  },
}

export default questionCommentApi
