import { useCallback, useEffect, useMemo, useState } from 'react'
import { useStore } from 'stores'

import { IDailyReport, IDailyReportAnswer, IDailyReportUser, ILightMentor } from 'types/Program'
import { IReportUserLocal } from 'types/Report'

import sortingByLocale from 'utils/sortingByLocale'

interface IProps {
  allUsers: IReportUserLocal[]
  dailyReports: IDailyReport[]
  initialUserFilter?: number
  loading: boolean
  mentors: ILightMentor[]
}

export interface ICalendarDateRange {
  startDate: Date
  endDate: Date
  key: 'selection'
}

const useReportFilters = ({ allUsers, dailyReports, initialUserFilter, loading }: IProps) => {
  const [mainSearchValue, setMainSearchValue] = useState('')
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([])
  const [selectedQuestionNames, setSelectedQuestionNames] = useState<string[]>([])

  const { userStore } = useStore()

  const dailyReportsWithFilters = useMemo(() => {
    if (loading) return []

    const res = dailyReports
      .map(report => {
        const filteredDailyReportsByTrainees = report.dailyReportModelForUsers.filter(userReport => {
          const isUserMatch =
            userReport.userName.toLowerCase().includes(mainSearchValue.toLowerCase()) &&
            selectedUserIds.includes(userReport.userId)

          if (isUserMatch) return true

          if (!isUserMatch && !mainSearchValue) return false

          const isQuestionOrAnswerOrCommentMatch = userReport.userQuestionsAndAnswers.find(
            question =>
              question.question.toLowerCase().includes(mainSearchValue.toLowerCase()) ||
              (question.answer && question.answer.toLowerCase().includes(mainSearchValue.toLowerCase())) ||
              question.coments.find(comment => comment.comment.toLowerCase().includes(mainSearchValue.toLowerCase()))
          )

          if (isQuestionOrAnswerOrCommentMatch) return true

          return false
        })

        return {
          ...report,
          dailyReportModelForUsers: sortingByLocale(
            filteredDailyReportsByTrainees,
            userStore.language,
            'userName'
          ) as IDailyReportUser[],
        }
      })
      .map(report => ({
        ...report,
        dailyReportModelForUsers: report.dailyReportModelForUsers.map(userReport => ({
          ...userReport,
          userQuestionsAndAnswers: userReport.userQuestionsAndAnswers.filter(question => {
            try {
              const json = JSON.parse(question.jsonForWeb)

              if ((!json?.schedulerOption || json.schedulerOption === 1) && !question.isBound) return true

              return selectedQuestionNames.includes(question.question)
            } catch (e) {
              return true
            }
          }),
        })),
      }))

    return res
  }, [dailyReports, mainSearchValue, selectedUserIds, selectedQuestionNames, userStore.language, loading])

  const repeatedQuestions = useMemo(() => {
    const result: IDailyReportAnswer[] = []

    dailyReports.forEach(report => {
      report.dailyReportModelForUsers.forEach(userReport => {
        userReport.userQuestionsAndAnswers.forEach(question => {
          try {
            const json = JSON.parse(question.jsonForWeb)

            if ((!json?.schedulerOption || json.schedulerOption === 1) && !question.isBound) return

            const exist = result.findIndex(item => item.question === question.question) !== -1

            if (exist) return

            result.push(question)
          } catch (e) {}
        })
      })
    })

    return result
  }, [dailyReports])

  const isShowOnlyFirsDayTrainee = useMemo(() => {
    if (loading) return false

    let traineeId: number | null = null
    let isOnlyOne = true

    dailyReportsWithFilters.forEach(report => {
      if (report.dailyReportModelForUsers.length !== 1) {
        isOnlyOne = false
        return
      }

      report.dailyReportModelForUsers.forEach(userReport => {
        if (!traineeId) {
          traineeId = userReport.userId
        } else {
          if (userReport.userId !== traineeId) {
            isOnlyOne = false
          }
        }
      })
    })

    return isOnlyOne
  }, [dailyReportsWithFilters, loading])

  const questionIdsToXlsxReport = useMemo(() => {
    if (loading) return []

    const result: number[] = []

    dailyReports.forEach(report => {
      report.dailyReportModelForUsers.forEach(userReport => {
        userReport.userQuestionsAndAnswers.forEach(question => {
          try {
            const json = JSON.parse(question.jsonForWeb)

            if ((!json?.schedulerOption || json.schedulerOption === 1) && !question.isBound) {
              result.push(question.questionId)
            } else {
              if (selectedQuestionNames.includes(question.question)) {
                result.push(question.questionId)
              }
            }
          } catch (e) {}
        })
      })
    })

    return result
  }, [dailyReports, selectedQuestionNames, loading])

  useEffect(() => {
    if (loading) return

    setSelectedQuestionNames(repeatedQuestions.map(question => question.question))
  }, [repeatedQuestions, loading])

  const resetQuestions = useCallback(() => {
    setSelectedQuestionNames(repeatedQuestions.map(question => question.question))
  }, [repeatedQuestions])

  useEffect(() => {
    if (initialUserFilter) {
      setSelectedUserIds([initialUserFilter])
      initialUserFilter = void 0
    } else {
      setSelectedUserIds(allUsers.map(trainee => trainee.id))
    }
  }, [allUsers])

  return {
    mainSearchValue,
    setMainSearchValue,
    selectedUserIds,
    setSelectedUserIds,
    dailyReportsWithFilters,
    selectedQuestionNames,
    setSelectedQuestionNames,
    repeatedQuestions,
    questionIdsToXlsxReport,
    resetQuestions,
    isShowOnlyFirsDayTrainee,
  }
}

export default useReportFilters
