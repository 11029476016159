import { Select, SelectProps } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import styles from './styles.module.scss'

export interface ICustomSelectProps extends SelectProps {
  isError?: boolean
  errorMessage?: string
  label?: string
  width?: string | number
  className?: string
  autoSelect?: boolean
  labelClassName?: string
}

const CustomSelect: React.FC<ICustomSelectProps> = ({
  isError,
  errorMessage,
  label,
  width,
  className,
  autoSelect,
  labelClassName,
  ...rest
}) => {
  useEffect(() => {
    if (!autoSelect) return

    if (rest.options?.length === 1 && rest.onChange) {
      const option = rest.options[0]

      if (!option) return

      rest.onChange(option.value, option)
    }
  }, [rest.options, rest.onChange, autoSelect, rest.mode])

  return (
    <div className={styles.container} style={width ? { width } : {}}>
      {!!label && <div className={classNames(styles.label, labelClassName)}>{label}</div>}
      <Select
        className={classNames(styles.customSelect, className, {
          [styles.error]: isError,
        })}
        getPopupContainer={trigger => trigger}
        {...rest}
      />
      {isError && <div className={classNames('ant-form-item-explain-error')}>{errorMessage}</div>}
    </div>
  )
}

export default observer(CustomSelect)
