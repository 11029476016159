import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Dispatch, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ICreateQuickProgramDTO } from 'api/DTOs/programs.dto'
import programsApi from 'api/programs.api'

import { useStore } from 'stores'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import LS, { LSKeys } from 'utils/LS'
import message from 'utils/message'

interface IProps {
  setSelectedTemplateError: Dispatch<SetStateAction<boolean>>
  selectedTemplateId: number | null
  onSuccess: () => void
}

const useCreateQuickProgramMutation = ({ selectedTemplateId, setSelectedTemplateError, onSuccess }: IProps) => {
  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const { programStore } = useStore()

  const navigate = useNavigate()

  const checkIfTemplateSelected = useCallback(() => {
    setSelectedTemplateError(!selectedTemplateId)
    return !!selectedTemplateId
  }, [selectedTemplateId])

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (param: ICreateQuickProgramDTO & { signal: AbortSignal }) => {
      if (!checkIfTemplateSelected()) {
        return
      }

      const { signal, ...dto } = param

      if (dto.phoneNumber === LS.get(LSKeys.PHONE)) {
        return message.error(t<string>('programCreation.selfInviteError'))
      }

      const program = await programsApi.createQuickProgram(dto, signal)

      if (!program) {
        return message.error(t<string>('programCreation.smtWrong'))
      }

      if (program === 'canceled') {
        return
      }

      programStore.programsIdsToCheckProgress = [...programStore.programsIdsToCheckProgress, program.id]

      message.success(t<string>('programCreation.successProgCreate'))
      onSuccess()
      navigate('/programs/' + program.id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeysEnum.PROGRAMS] })
    },
  })

  return { createQuickProgram: mutateAsync, isLoading, checkIfTemplateSelected }
}

export default useCreateQuickProgramMutation
