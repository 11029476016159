import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useStore } from 'stores'

import useTemplateOptionsQuery from 'hooks/tanstack/queries/useTemplateOptionsQuery'

import { ITemplateOption } from 'types/Template'

import LS, { LSKeys } from 'utils/LS'

import TemplateItem from './TemplateItem'

import CustomDropdown from 'components/CustomComponents/CustomDropdown'
import CustomInput from 'components/CustomComponents/CustomInput'
import CustomMenu from 'components/CustomComponents/CustomMenu'
import Pagination from 'components/Pagination'
import SelectedTemplate from 'components/SelectedTemplate'

import styles from './styles.module.scss'

type TemplateBlockPropsType = {
  selectedTemplateId: number | null
  selectedTemplate: ITemplateOption | undefined
  setSelectedTemplateId: Dispatch<SetStateAction<number | null>>
  setSelectedTemplate: Dispatch<SetStateAction<ITemplateOption | undefined>>
}

const TemplateBlock: FC<TemplateBlockPropsType> = ({
  selectedTemplateId,
  setSelectedTemplateId,
  selectedTemplate,
  setSelectedTemplate,
}) => {
  const { t } = useTranslation()

  const { userStore, templateStore } = useStore()

  const navigate = useNavigate()

  const { isLoading, options, pagination, searchValue, setSearchValue, setPageNumber } = useTemplateOptionsQuery()

  const menu = useMemo(() => {
    const items = [
      {
        key: '1',
        label: t<string>('programList.edit'),
        onClick: () => {
          templateStore.setTemplateRedirectionInfo({
            backTo: 'program-creation/step2',
            isCreatingNewTemplate: false,
          })
          navigate('/template/' + selectedTemplateId)
        },
      },
      {
        key: '2',
        label: t<string>('programCreation.change'),
        onClick: () => {
          setSelectedTemplateId(null)
          setSelectedTemplate(undefined)
          LS.remove(LSKeys.PROGRAM_TEMPLATE_ID)
          LS.remove(LSKeys.PROGRAM_TEMPLATE)
        },
      },
    ]

    return <CustomMenu items={items} />
  }, [userStore.language, selectedTemplateId, t])

  useEffect(() => {
    if (options.length === 1) {
      const template = options[0]

      setSelectedTemplateId(template.value)
      setSelectedTemplate(template)
    }
  }, [options])

  const onTemplatePress = (temp: ITemplateOption) => {
    setSelectedTemplateId(temp.value)
    setSelectedTemplate(temp)
    LS.set(LSKeys.PROGRAM_TEMPLATE_ID, temp.value)
    LS.set(LSKeys.PROGRAM_TEMPLATE, temp)
  }

  return (
    <>
      {selectedTemplateId ? (
        <SelectedTemplate selectedTemplate={selectedTemplate} withoutBorder>
          <CustomDropdown overlay={menu} />
        </SelectedTemplate>
      ) : (
        <>
          <div className={styles.blockWrapper}>
            <CustomInput
              value={searchValue}
              placeholder={t<string>('templateList.searchTemplate')}
              onChange={e => setSearchValue(e.target.value)}
              containerClassName={styles.search}
              className={styles.searchInput}
              suffix={<SearchOutlined />}
              data-program-creation-search-template="program-creation-search-template"
              id="program-creation-search-template"
            />
          </div>
          <Spin spinning={isLoading} wrapperClassName={styles.spinWrapper}>
            <div className={styles.listWrapper}>
              <div
                onClick={() => {
                  templateStore.setTemplateRedirectionInfo({
                    backTo: 'program-creation/step2',
                    isCreatingNewTemplate: true,
                  })
                  navigate('/template/create')
                }}
                data-link-create="link-create"
              >
                <div className={styles.addTemplate} data-add-template="add-template">
                  <div>
                    <PlusOutlined data-plus="plus" />
                  </div>
                  <div data-create-new="create-new">{t<string>('dashboard.addNewOne')}</div>
                </div>
              </div>

              <div className={styles.templateList} data-template-list="template-list">
                {options?.map(template => (
                  <TemplateItem
                    key={template.value}
                    template={template}
                    isSelected={selectedTemplateId === template.value}
                    onPress={onTemplatePress}
                  />
                ))}
              </div>
            </div>

            {!!pagination && (
              <Pagination
                currentPage={pagination.pageNumber}
                totalItems={pagination.totalItems}
                size={pagination.pageLength}
                onPageChange={setPageNumber}
                loading={!options.length}
              />
            )}
          </Spin>
        </>
      )}
    </>
  )
}

export default TemplateBlock
