import { HubConnection } from '@microsoft/signalr'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'

import userApi from 'api/user.api'
import { authApi } from 'config'

import stores from 'stores'

import { IUser } from 'types/User'
import LS, { LSKeys } from 'utils/LS'
import message from 'utils/message'
import getSubdomain from './getSubdomain'

const fallback = () => {
  LS.remove(LSKeys.USER)
  LS.remove(LSKeys.TOKEN)
  LS.remove(LSKeys.ADMIN_TOKEN)
  stores.userStore.clearCache()
  stores.userStore.setUser(null)
  stores.userStore.setIsAdmin(false)
  stores.userStore.setIsCompanyModerator(false)
  stores.userStore.setIsTrainer(false)
  stores.userStore.clearBrandingData()

  const subDomain = getSubdomain()
  if (subDomain && !subDomain.includes('localhost')) {
    stores.userStore.getUserBrandInfo(subDomain, true)
  }
}

export let isTokenRefreshing = false
export let refreshQueue: any[] = []

export const addToQueueInPromiseWrapper = (
  resolveAction: (token: string) => Promise<AxiosResponse<any, any> | HubConnection>,
  actionBeforeResolving?: (token: string) => void
) =>
  new Promise((resolve, reject) => {
    refreshQueue.push({
      resolve: (token: string) => {
        if (actionBeforeResolving) {
          actionBeforeResolving(token)
        }

        resolve(resolveAction(token))
      },
      reject: (err: any) => {
        reject(err)
      },
    })
  })

export const initTokenRefreshingAndExecuteAllQueue = async (actionTokenRefreshed: (token: string) => void) => {
  isTokenRefreshing = true
  let tokenRefreshRetries = 5
  let tokenRefreshed = false

  try {
    let tokenResponse: IUser | null = null

    while (tokenRefreshRetries > 0 && !tokenRefreshed) {
      const response = await userApi.refreshToken()
      if (response && response.token) {
        tokenRefreshed = true
        tokenResponse = response
      }
      tokenRefreshRetries--

      if (tokenRefreshRetries > 0) {
        await new Promise(res => setTimeout(res, 300))
      }
    }

    if (!tokenResponse?.token) {
      refreshQueue.forEach(v => v.reject('Token wasnt refresh -> initTokenRefreshingAndExecuteAllQueue'))
      refreshQueue = []
      fallback()
    } else {
      LS.set(LSKeys.USER, tokenResponse)

      if (stores.userStore.isAdmin) {
        LS.set(LSKeys.ADMIN_TOKEN, tokenResponse?.token)
        LS.remove(LSKeys.TOKEN)
      } else {
        LS.set(LSKeys.TOKEN, tokenResponse?.token)
        LS.remove(LSKeys.ADMIN_TOKEN)
      }

      stores.userStore.setUser(tokenResponse)
      stores.userStore.setIsTrainer(tokenResponse?.haveTrainerPermission)
      stores.userStore.setIsCompanyModerator(!!tokenResponse?.companyId)

      authApi.defaults.headers.common['Authorization'] = `Bearer ${tokenResponse.token}`
      authApi.defaults.headers.common['Access-Control-Allow-Credentials'] = true

      actionTokenRefreshed(tokenResponse.token)

      await Promise.all(refreshQueue.map(v => v.resolve(tokenResponse!.token)))
      refreshQueue = []
    }
  } catch (e) {
    isTokenRefreshing = false
    fallback()
    return Promise.reject(e)
  }

  isTokenRefreshing = false
}

export async function axiosRefreshTokenCheck(error: any) {
  const originalRequest = error.config

  if (!error.response) {
    return Promise.reject(error)
  }

  if (error.response?.headers?.userbanned) {
    fallback()
    message.error(t('otp.banned'))
    return
  }

  if (error.response?.headers?.systemonmaintanance || error.response.status === 502) {
    message.error(t('general.maintenanceErrorText'))
    fallback()
    return
  }

  if (error.response.status === 415) {
    if (originalRequest._retry415) {
      return Promise.reject(error)
    }

    originalRequest._retry415 = true

    return authApi(originalRequest)
  }

  if (error.response.status !== 401 || originalRequest.url?.includes('refresh-token')) {
    return Promise.reject(error)
  }

  if (stores.userStore.isAdmin) {
    fallback()
  }

  const resultPromise = addToQueueInPromiseWrapper(
    () => authApi.request(originalRequest),
    token => (originalRequest.headers.Authorization = `Bearer ${token}`)
  )

  if (!isTokenRefreshing) {
    try {
      await initTokenRefreshingAndExecuteAllQueue(token => {
        originalRequest.headers.Authorization = `Bearer ${token}`
      })
    } catch (e) {
      fallback()
      return Promise.reject(error)
    }
  }

  return resultPromise
}
