import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

import useIsRtl from 'hooks/useIsRtl'

import TextVariables from 'components/TextVariables'

import styles from './styles.module.scss'

interface IProps {
  onSelectVariable: (variable: string) => void
  height?: number
}

const Variables: FC<IProps> = ({ onSelectVariable, height }) => {
  const isRtl = useIsRtl()

  return (
    <div
      className={classnames(styles.vars, {
        [styles.varsRtl]: isRtl,
      })}
      style={{ height: height || void 0 }}
    >
      <TextVariables onSelectVariable={onSelectVariable} />
    </div>
  )
}

export default observer(Variables)
