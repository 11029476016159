import { Editor } from '@tinymce/tinymce-react'
import classNames from 'classnames'
import i18n from 'i18nextConf'
import { FC, MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'

import useIsRtl from 'hooks/useIsRtl'

import { useStore } from 'stores'

import TextVariables from 'components/TextVariables'

import styles from './styles.module.scss'

interface IProps {
  additionalToolHandleChange: (value: string, index: number) => void
  isEditing: boolean
  html: string
  handleInsert: (variable: string) => void
  editorRef: MutableRefObject<Editor | null>
  isError: boolean
  titleVariable?: boolean
}

const TextEditor: FC<IProps> = ({
  additionalToolHandleChange,
  isEditing,
  html,
  handleInsert,
  editorRef,
  isError,
  titleVariable,
}) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const isRtl = useIsRtl()

  return (
    <div className={classNames(styles.editor, { [styles.error]: isError })}>
      <Editor
        disabled={!isEditing}
        value={html}
        onEditorChange={value => additionalToolHandleChange(value, 0)}
        apiKey={process.env.REACT_APP_TINY_API_KEY}
        key={i18n.language}
        ref={editorRef}
        init={{
          placeholder: t<string>('templateCreation.editorPlaceholder'),
          width: '100%',
          height: 400,
          min_height: 100,
          skin: 'material-classic',
          toolbar:
            'undo redo | fontsizeselect | alignjustify alignleft aligncenter alignright | bold italic underline forecolor | bullist numlist | indent outdent lineheight | rtl ltr',
          // | register',
          plugins:
            'code autoresize searchreplace autolink directionality visualblocks visualchars image link codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons print autosave',
          content_style: `
        body { font-family:Helvetica,Arial,sans-serif; font-size:14px }
        h1 { font-weight: 500 }
        h2 { font-weight: 500 }
        h3 { font-weight: 500 }
        h4 { font-weight: 500 }
        h5 { font-weight: 500 }
        h6 { font-weight: 500 }
      `,
          // language: isRtl ? 'he_IL' : userStore.language === 'ru' ? 'ru' : 'en',
          language: isRtl ? 'he_IL' : userStore.language === 'ru' ? 'ru' : 'en',
          //@ts-ignore
          directionality: isRtl ? 'rtl' : 'ltr',
          // extended_valid_elements: 'register[contenteditable=true]',
          // forced_root_block: 'register',
          // setup: editor => {
          //   editor.ui.registry.addButton('register', {
          //     text: 'Register',
          //     onAction: () => {
          //       editor.insertContent<string>('<register contenteditable="true">\n</register>')

          //       const node = editor.dom.select<string>('register')[0]

          //       if (node) {
          //         editor.selection.setCursorLocation(node, 0)
          //       }
          //     },
          //   })
          // },
        }}
        data-editor-2="editor-2"
      />
      <div
        className={classNames(styles.vars, {
          [styles.varsRtl]: isRtl,
        })}
      >
        <TextVariables onSelectVariable={handleInsert} withTitle={titleVariable} />
      </div>
    </div>
  )
}

export default TextEditor
