import { useQuery } from '@tanstack/react-query'

import userApi from 'api/user.api'

import { useStore } from 'stores'
import { StatusInvitesEnum } from 'stores/Program'

import { LastActiveFilterEnum } from 'types/Program'
import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { ITraineeWithInvite } from 'types/User'

const useTraineesInProgramQuery = (trainingProgramId: number | null) => {
  const { userStore } = useStore()

  const {
    data: trainees,
    isLoading,
    isError,
  } = useQuery({
    initialData: [],
    keepPreviousData: true,
    enabled: !!trainingProgramId,
    queryKey: [QueryKeysEnum.TRAINEES_IN_PROGRAM, trainingProgramId],
    queryFn: async () => {
      if (!trainingProgramId) throw new Error()

      const response = await userApi.getProgramTraineeWithInviteInfo({
        programId: trainingProgramId.toString(),
        pageNumber: 1,
        pageLength: 9999,
        inviteStatus: userStore.hasPublicProgramAccess ? void 0 : StatusInvitesEnum.Succeed,
        str: '',
        lastActiveFilter: LastActiveFilterEnum.Registered,
      })

      return (response?.item1?.items || []) as ITraineeWithInvite[]
    },
  })

  return {
    trainees,
    isLoading,
    isError,
  }
}

export default useTraineesInProgramQuery
