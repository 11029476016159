import { DeleteOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import questionCommentApi from 'api/questionComment.api'

import useIsRtl from 'hooks/useIsRtl'

import { useStore } from 'stores'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import ActionsDropdown from 'components/ActionsDropdown'
import LoaderSmall from 'components/LoaderSmall'
import UserAvatar from 'components/UserAvatar'

import styles from './styles.module.scss'

interface IProps {
  comment: string
  date: string
  avatarId: number
  username: string
  userId: number
  commentId: number
  deleteOptimisticComment: (id: number) => void
  temporary?: boolean
}

const Comment: FC<IProps> = ({
  avatarId,
  comment,
  date,
  username,
  commentId,
  userId,
  deleteOptimisticComment,
  temporary,
}) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const queryClient = useQueryClient()

  const removeComment = async () => {
    deleteOptimisticComment(commentId)
    await questionCommentApi.removeComment(commentId)
    queryClient.invalidateQueries([QueryKeysEnum.DAILY_REPORTS])
  }

  const dateFormatted = useMemo(() => {
    const dateInMoment = moment(date)

    if (dateInMoment.isSame(moment(), 'day')) {
      return dateInMoment.format('HH:mm')
    }

    return dateInMoment.format('DD/MM/YYYY HH:mm')
  }, [date])

  const isRtl = useIsRtl()

  return (
    <div className={styles.container}>
      <div>
        <UserAvatar avatarId={avatarId} size={32} />
      </div>
      <div className={styles.details}>
        <div className={styles.username}>{username}</div>
        <div className={styles.body}>
          <div className={styles.text}>{comment}</div>
          <div className={styles.date}>{dateFormatted}</div>
          {userStore.user?.id === userId && (
            <div
              className={classNames(styles.options, {
                [styles.optionsRtl]: isRtl,
              })}
            >
              {temporary ? (
                <LoaderSmall />
              ) : (
                <ActionsDropdown
                  items={[
                    {
                      key: '1',
                      icon: <DeleteOutlined />,
                      label: t<string>('dailyReport.deleteComment'),
                      onClick: removeComment,
                    },
                  ]}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(Comment)
