import { useEffect, useState } from 'react'

import { useStore } from 'stores'

const useAvatar = ({ avatarId }: { avatarId: number }) => {
  const [avatarUrl, setAvatarUrl] = useState('')
  const [avatarLoading, setAvatarLoading] = useState(false)

  const { programStore } = useStore()

  useEffect(() => {
    ;(async () => {
      try {
        setAvatarLoading(true)

        const res = await programStore.getProgramImage(avatarId, true)

        if (!res) return

        setAvatarUrl(res)
      } catch (e) {
      } finally {
        setAvatarLoading(false)
      }
    })()
  }, [avatarId])

  return { avatarUrl, avatarLoading }
}

export default useAvatar
