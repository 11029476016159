import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useDeleteTemplateMutation from 'hooks/tanstack/mutations/useDeleteTemplateMutation'

import { useStore } from 'stores'

import { ITemplate } from 'types/Template'
import dateTime from 'utils/dateTime'
import message from 'utils/message'

import CustomDropdown from 'components/CustomComponents/CustomDropdown'
import CustomMenu from 'components/CustomComponents/CustomMenu'
import MenuItem from 'components/MenuItem'

import styles from './styles.module.scss'

interface IProps {
  template: ITemplate
  currentTemplateDropdownOpened: number
  setCurrentTemplateDropdownOpened: Dispatch<SetStateAction<number>>
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
}

const TemplateItem: FC<IProps> = ({
  template,
  currentTemplateDropdownOpened,
  setCurrentTemplateDropdownOpened,
  setIsModalVisible,
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { userStore } = useStore()

  const handleTemplateDelete = useDeleteTemplateMutation({})

  const menu = useMemo(
    () => (
      <CustomMenu
        items={[
          {
            key: '1',
            label: <MenuItem label={t<string>('templateList.editTemplate')} Icon={() => <EditOutlined width={15} />} />,
            onClick: e => {
              e.domEvent.stopPropagation()
              navigate('/template/' + currentTemplateDropdownOpened)
            },
          },
          {
            key: '2',
            label: (
              <MenuItem label={t<string>('templateList.copyTemplateId')} Icon={() => <CopyOutlined width={15} />} />
            ),
            onClick: async e => {
              e.domEvent.stopPropagation()
              await navigator.clipboard.writeText(template.id.toString())
              message.success(t<string>('templateList.copiedId'))
            },
          },
          {
            key: '3',
            label: <MenuItem label={t<string>('templateList.copyTemplate')} Icon={() => <CopyOutlined width={15} />} />,
            onClick: e => {
              e.domEvent.stopPropagation()
              setIsModalVisible(true)
            },
          },
          {
            key: '4',
            label: (
              <MenuItem label={t<string>('templateList.deleteTemplate')} Icon={() => <DeleteOutlined width={15} />} />
            ),
            onClick: e => {
              e.domEvent.stopPropagation()
              handleTemplateDelete(currentTemplateDropdownOpened)
            },
          },
        ]}
        className={styles.templateMore}
      />
    ),
    [userStore.language, currentTemplateDropdownOpened]
  )

  return (
    <div
      className={styles.templateItem}
      onClick={() => navigate('/template/' + template.id)}
      style={{ cursor: 'pointer' }}
      data-template-list-template="template-list-template"
      id={'template-list-template-' + template.id}
      key={template.id}
    >
      <div className={styles.templateNameInfo}>
        <div data-template-list-template-name="template-list-template-name">{template.name}</div>
        <div>
          {t<string>('templateCreation.created')}: {dateTime.getHebrewDate(template.createdAt)}
        </div>
      </div>
      <div className={styles.numberOfDays}>
        <div>{t<string>('templateList.numberOfDays')}</div>
        <div>{template.duration}</div>
      </div>
      <div
        className={styles.more}
        data-template-list-options="template-list-options"
        id={'template-list-template-options-' + template.id}
      >
        <CustomDropdown
          overlay={menu}
          onOpenChange={() => setCurrentTemplateDropdownOpened(template.id)}
          size={28}
          placement="bottom"
        />
      </div>
    </div>
  )
}

export default TemplateItem
