import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import programsApi from 'api/programs.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import message from 'utils/message'

const useProgramsWhereMentorQuery = () => {
  const { t } = useTranslation()

  const {
    data: programs,
    isLoading,
    isError,
  } = useQuery({
    keepPreviousData: true,
    queryKey: [QueryKeysEnum.ALL_PROGRAMS_WHERE_MENTOR],
    queryFn: async () => {
      const programs = await programsApi.getProgramsWhereMentor()
      return programs
    },
    onError: () => {
      message.error(t('programCreation.smtWrong'))
    },
  })

  return {
    programs,
    isLoading,
    isError,
  }
}

export default useProgramsWhereMentorQuery
