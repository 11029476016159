import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { TextMessageDto } from 'api/swagger.api'
import useChatMessagesQuery from 'hooks/tanstack/queries/useChatMessagesQuery'
import useInView from 'hooks/useInView'
import dayjs from 'dayjs'

type RouterParams = {
  chatRelationshipId: string
}

export const useChatMessages = () => {
  const { chatRelationshipId } = useParams<RouterParams>()

  const { data, isLoading, hasNextPage, dataUpdatedAt, fetchNextPage } = useChatMessagesQuery({
    chatRelationshipId: Number(chatRelationshipId),
    returnReadMessage: true,
    pageLength: 20,
  })
  const [nextPageTriggerRef, isInView] = useInView<HTMLDivElement>()

  const [messages, dateGroups] = useMemo(() => {
    const messages = data?.pages.flatMap(page => page.items as TextMessageDto[]) || []

    const dateGroups = new Map<string, number>()
    messages.forEach(message => {
      const date = dayjs(message?.createdAt).startOf('day').toISOString()
      dateGroups.set(date, message.id || 0)
    })

    const swappedMap = new Map<number, string>()
    dateGroups.forEach((value, key) => {
      swappedMap.set(value, key)
    })

    return [messages, swappedMap]
  }, [dataUpdatedAt])

  useEffect(() => {
    if (isInView && hasNextPage && messages.length > 0) {
      fetchNextPage()
    }
  }, [messages?.length, isInView, hasNextPage, fetchNextPage])

  return {
    nextPageTriggerRef,
    isLoading,
    messages,
    dateGroups,
  }
}
