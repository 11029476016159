import { useForm } from 'antd/lib/form/Form'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import BottomPanel from './BottomPanel'
import CompanyProfile from './CompanyProfile'

import PageContainer from 'components/PageContainer'
import PersonalInformationForm, { IPersonalInformationForm } from 'components/PersonalInformationForm'

import styles from './styles.module.scss'

const Profile = () => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const [form] = useForm<IPersonalInformationForm>()

  const [loading, setLoading] = useState(false)

  return (
    <PageContainer topOffset>
      <div className={styles.headInfo}>
        <div className={styles.title}>{t('profile.settings')}</div>
      </div>
      <div className={styles.basicInfo}>
        {(userStore.isCompanyModerator || !!userStore.user?.companiesAsMentor?.length) && <CompanyProfile />}

        <PersonalInformationForm form={form} title={t('profile.personalInformation')} setLoading={setLoading} />

        <BottomPanel handleSaveChanges={() => form.submit()} loading={loading} />
      </div>
    </PageContainer>
  )
}

export default observer(Profile)
