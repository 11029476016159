import { useParams } from 'react-router-dom'

import useProgramQuery from 'hooks/tanstack/queries/useProgramQuery'

import BackLink from 'components/BackLink'
import PageContainer from 'components/PageContainer'
import PageError from 'components/PageError'
import PageLoader from 'components/PageLoader'
import RemindersList from 'components/RemindersList'

import styles from './styles.module.scss'

const MessageInProgram = () => {
  const { id } = useParams<{ id: string }>()
  const { program, isLoading, isError } = useProgramQuery(id, true)

  return (
    <PageContainer topOffset>
      <BackLink backRoute="/message" />
      {isLoading ? (
        <PageLoader />
      ) : !id || !program || isError ? (
        <PageError />
      ) : (
        <div className={styles.container}>
          <h2 className={styles.title}>{program.name}</h2>
          <RemindersList programId={+id} />
        </div>
      )}
    </PageContainer>
  )
}

export default MessageInProgram
