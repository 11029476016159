import { authApi } from 'config'
import { t } from 'i18next'

import {
  IScheduleNotificationsForUser,
  IScheduledNotification,
  IScheduledNotificationHistory,
} from 'types/ScheduleNotification'
import { PaginationResponse } from 'types/common'
import {
  IGetScheduleNotificationHistoryParams,
  IGetScheduleNotificationsByUserId,
  IGetScheduleNotificationsParams,
  IScheduledNotificationCreateDto,
} from './DTOs/scheduleNotification.dto'

import message from 'utils/message'

const controller = 'v2/ScheduleNotification'

const scheduleNotificationApi = {
  async getScheduledNotifications(params: IGetScheduleNotificationsParams) {
    const response = await authApi.get<PaginationResponse<IScheduledNotification>>(controller + '/v2', {
      params,
    })

    return response.data
  },
  async createScheduledNotification(data: IScheduledNotificationCreateDto): Promise<void | boolean> {
    try {
      await authApi.post(controller, data)

      message.success(t('programSettings.reminderCreateSuccess'))

      return true
    } catch (e) {
      const { status } = e.response

      if (status === 400) {
        message.error(t('dashboard.incorrectReminderTime'))
        return
      }
      message.error(t('programCreation.smtWrong'))
    }
  },
  async editScheduledNotification(data: IScheduledNotificationCreateDto, id: number): Promise<void | boolean> {
    await authApi.put(controller + '/' + id, data)
  },
  async deleteScheduledNotification(id: number): Promise<void> {
    await authApi.delete(controller + '/' + id)
  },
  async getScheduleNotificationHistory(params: IGetScheduleNotificationHistoryParams) {
    const { scheduleId, ...rest } = params

    const response = await authApi.get<PaginationResponse<IScheduledNotificationHistory>>(
      `${controller}/history?scheduleIds=${scheduleId}`,
      {
        params: rest,
      }
    )
    return response.data
  },
  async exportHistory(id: number) {
    const response = await authApi.get(controller + '/exportHistory', {
      params: { id },
      responseType: 'blob',
    })
    const filename =
      response.headers['content-disposition'].split('filename="')?.[1].split('";')?.[0] ||
      `Notification_Delivery_Status_${new Date().toISOString()}.xlsx`

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', filename)
    link.click()

    return true
  },
  async getListByUserId(params: IGetScheduleNotificationsByUserId) {
    const response = await authApi.get<PaginationResponse<IScheduleNotificationsForUser>>(controller + '/sentForUser', {
      params,
    })
    return response.data
  },
}

export default scheduleNotificationApi
