import { DatePicker, Form, FormInstance } from 'antd'
import moment from 'moment'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import LS, { LSKeys } from 'utils/LS'

import { IProgramDetailsForm } from 'types/Program'

import CustomFormItem from 'components/CustomComponents/CustomFormItem'
import LinkToBrandSelector from 'components/LinkToBrandSelector'
import ProgramSettingsCheckboxes from 'components/ProgramSettingsCheckboxes'

import styles from './styles.module.scss'

interface IProps {
  startDate: moment.Moment
  setStartDate: Dispatch<SetStateAction<moment.Moment>>
  companyId: number
  onCompanyIdChanged: (val: number) => void
  formDetails: FormInstance<IProgramDetailsForm>
}

const Preview: React.FC<IProps> = ({ startDate, setStartDate, companyId, onCompanyIdChanged, formDetails }) => {
  const { t } = useTranslation()
  const { userStore } = useStore()

  const showLinkToCompanySelector = !!userStore.userBrands.length && userStore.userBrands.length !== 1

  return (
    <Form form={formDetails}>
      <div className={styles.container}>
        <div>
          <CustomFormItem label={t('programCreation.startDate')}>
            <DatePicker
              onChange={date => date && setStartDate(date)}
              value={startDate}
              className={styles.datePicker}
              data-program-creation-date-picker="program-creation-date-picker"
              id="program-creation-date-picker"
              disabledDate={date => moment().diff(date, 'days') > 0}
            />
          </CustomFormItem>
          {showLinkToCompanySelector && (
            <CustomFormItem label={t('programCreation.linkToBrand')}>
              <LinkToBrandSelector
                value={companyId}
                onChange={value => {
                  onCompanyIdChanged(value)
                  LS.set(LSKeys.LAST_BRAND_COMPANY_ID_SELECTED, value)
                }}
              />
            </CustomFormItem>
          )}
          <ProgramSettingsCheckboxes form={formDetails} />
        </div>
      </div>
    </Form>
  )
}

export default Preview
