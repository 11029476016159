export enum FollowUpActionsEnum {
  doNothing,
  content,
  URL,
  Webhook,
}

export enum FollowUpPostType {
  None,
  Questionnaire,
  File,
  Link,
  Summary,
  Scenario,
}

export interface IAvailableFollowUp {
  postType: FollowUpPostType
  postId: number
  contentId: number
}
