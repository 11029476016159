// this done to prevent multiple calling this function
// see more detailed at https://aimpact-ai.atlassian.net/browse/AIMPACT-221
let prevVideoIds: string[] = []

const preventSecondCall = (currentVideoIds: string[]) => {
  if (prevVideoIds.length && prevVideoIds.every(prevId => currentVideoIds.some(currentId => prevId === currentId))) {
    return true
  }

  prevVideoIds = currentVideoIds
}

export default preventSecondCall
