import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { Form, Popover, PopoverProps } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import classNames from 'classnames'
import { Dispatch, FC, SetStateAction, memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useIsRtl from 'hooks/useIsRtl'

import { IGoal } from 'types/Goal'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomFormItem from 'components/CustomComponents/CustomFormItem'
import CustomInput from 'components/CustomComponents/CustomInput'

import styles from './styles.module.scss'

import { ReactComponent as EmojiPlaceholderPic } from 'sources/images/EmojiPlaceholder.svg'

const minMaxValidation = {
  min: 2,
  max: 25,
}

type FormValues = {
  goal: string
  iconString: string
  completionAt: number
  unit: string
}

interface IProps {
  setGoals: Dispatch<SetStateAction<IGoal[]>>
  setFormVisible: Dispatch<SetStateAction<boolean>>
  editingIdx?: number
  initialValues?: FormValues
}

const parseNumber = (value: string | undefined): number | undefined => {
  if (value === undefined) {
    return undefined
  }

  const parsedValue = parseFloat(value)

  return isNaN(parsedValue) ? undefined : parsedValue
}

const GoalForm: FC<IProps> = ({ setGoals, setFormVisible, editingIdx, initialValues }) => {
  const [form] = Form.useForm()

  const { t } = useTranslation()

  const [selectedEmoji, setSelectedEmoji] = useState<string>('')

  const popoverRef = useRef<null | PopoverProps>(null)

  const onEmojiSelect = useCallback(
    (emoji: any) => {
      setEmoji(emoji.native)
      //@ts-ignore
      if (popoverRef.current?.setPopupVisible) {
        //@ts-ignore
        popoverRef.current.setPopupVisible(false)
      }
    },
    [popoverRef.current]
  )

  useEffect(() => {
    if (initialValues) {
      form.setFieldValue('goal', initialValues.goal)
      form.setFieldValue('completionAt', initialValues.completionAt)
      form.setFieldValue('unit', initialValues.unit)

      if (initialValues.iconString) {
        form.setFieldValue('iconString', initialValues.iconString)
        setSelectedEmoji(initialValues.iconString)
      }
    }
  }, [initialValues])

  const onFinish = useCallback(
    async (values: FormValues) => {
      const itemToSet = {
        text: values.goal,
        completionAt: values.completionAt,
        iconString: values.iconString,
        unit: values.unit,
      }

      if (editingIdx !== undefined && !!initialValues) {
        setGoals(prev =>
          prev.map((item, idx) => {
            if (idx === editingIdx) {
              return { ...item, ...itemToSet }
            }

            return item
          })
        )
      } else {
        setGoals(prev => [...prev, { ...itemToSet, id: -1 }])
      }

      setFormVisible(false)
    },
    [editingIdx, initialValues]
  )

  const setEmoji = useCallback((emoji: string) => {
    setSelectedEmoji(emoji)
    form.setFieldValue('iconString', emoji)
    form.validateFields(['iconString'])
  }, [])

  const isRtl = useIsRtl()

  return (
    <Form className={styles.form} form={form} name="my-form" onFinish={onFinish}>
      <h2 className={styles.title}>
        {initialValues ? t<string>('programList.edit') : t<string>('templateCreation.addGoal')}
      </h2>
      <div className={styles.formInputs}>
        <CustomFormItem
          name="iconString"
          rules={[{ required: true, message: '', validateTrigger: selectedEmoji }]}
          required={false}
          className={styles.formItemEmoji}
        >
          <Popover
            ref={popoverRef}
            content={
              <div>
                <Picker data={data} theme="light" onEmojiSelect={onEmojiSelect} />
              </div>
            }
            trigger="click"
            placement="bottomLeft"
            data-trainee-options="trainee-options"
          >
            <CustomInput
              value={selectedEmoji || ''}
              onKeyDown={e => {
                if (e.keyCode === 8) {
                  setEmoji('')
                }
              }}
              isErrorNeeded={false}
            />
            {!selectedEmoji && (
              <div className={styles.emojiPlaceholder}>
                <EmojiPlaceholderPic />
              </div>
            )}
          </Popover>
        </CustomFormItem>
        <CustomFormItem
          name="goal"
          rules={[
            { required: true, message: '' },
            {
              message: t<string>('programCreation.minMaxLen', minMaxValidation),
              ...minMaxValidation,
            },
          ]}
          required={false}
          className={styles.formItem}
        >
          <CustomInput
            isErrorNeeded={false}
            placeholder={`${t<string>('templateCreation.goal')} ${editingIdx ? editingIdx + 1 : ''}`}
          />
        </CustomFormItem>
      </div>

      <div className={styles.formInputsUnion}>
        <div className={styles.formNumLabel}>{t<string>('templateCreation.goalNumericLabel')}</div>
        <div className={styles.formNumConatainer}>
          <div className={styles.formNum}>
            <CustomFormItem
              name="completionAt"
              rules={[{ required: true, type: 'number', message: '' }]}
              getValueFromEvent={event => parseNumber(event.target.value)}
              required={false}
              className={classNames(styles.completionAt)}
            >
              <CustomInput min={1} type="number" isErrorNeeded={false} itemType="number" placeholder="1" />
            </CustomFormItem>
            <CustomFormItem
              name="unit"
              rules={[{ required: true, message: '' }]}
              required={false}
              className={classNames(styles.unit)}
            >
              <CustomInput isErrorNeeded={false} placeholder={t<string>('templateCreation.unit')} />
            </CustomFormItem>
          </div>
          <div
            className={classNames(styles.border, {
              [styles.isRtl]: isRtl,
              [styles.isLtr]: !isRtl,
            })}
          >
            <div />
          </div>
        </div>
      </div>

      <div className={styles.btns}>
        <Form.Item className={styles.btnContainer}>
          <CustomButton type="primary" htmlType="submit">
            {initialValues ? t<string>('templateCreation.saveChanges') : t<string>('dashboard.invite')}
          </CustomButton>
        </Form.Item>
        <FormItem className={styles.btnContainer}>
          <CustomButton onClick={() => setFormVisible(false)}>{t<string>('dashboard.cancel')}</CustomButton>
        </FormItem>
      </div>
    </Form>
  )
}

export default memo(GoalForm)
