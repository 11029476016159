import { ArrowDownOutlined, CalendarOutlined } from '@ant-design/icons'
import { Calendar as CalendarComponent, Col, Row, Select } from 'antd'
import { HeaderRender } from 'antd/lib/calendar/generateCalendar'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import type { Moment } from 'moment'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomSelect from 'components/CustomComponents/CustomSelect'

import './calendar.scss'
import styles from './styles.module.scss'

interface IProps {
  templateCreatedAt: string
  onChangeDay: (idx: number) => void
  isNewsMode?: boolean
}

const Calendar: FC<IProps> = ({ templateCreatedAt, onChangeDay, isNewsMode }) => {
  const { t } = useTranslation()

  const [value, setValue] = useState(() => moment(templateCreatedAt))
  const [selectedValue, setSelectedValue] = useState(() => moment(templateCreatedAt))
  const [expanded, setExpanded] = useState(false)

  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const onSelect = (newValue: Moment) => {
    let idx = -1

    if (isNewsMode) {
      idx = templateEntity.eventsDates.findIndex(evDate => moment(evDate).isSame(newValue, 'day'))
    } else {
      idx = templateStore.programDays.findIndex(day => moment(day.date).isSame(newValue, 'day'))
    }

    if (idx === -1) return

    setValue(newValue)
    setSelectedValue(newValue)
    onChangeDay(idx)
  }

  const onPanelChange = (newValue: Moment) => {
    setValue(newValue)
  }

  useEffect(() => {
    const firstDate = templateEntity.eventsDates[templateEntity.currentDayIndex || 0]

    if (!firstDate) return

    const val = moment(firstDate)
    setValue(val)
    setSelectedValue(val)
  }, [templateEntity.eventsDates, isNewsMode, templateEntity.currentDayIndex])

  useEffect(() => {
    if (!templateEntity.templateInfo?.createdAt || isNewsMode) return

    const val = moment(templateEntity.templateInfo.createdAt)
    setValue(val)
    setSelectedValue(val)
  }, [templateEntity.templateInfo?.createdAt, isNewsMode])

  const headerRender: HeaderRender<Moment> = ({ value, type, onChange, onTypeChange }) => {
    const months = [
      t('months.January'),
      t('months.February'),
      t('months.March'),
      t('months.April'),
      t('months.May'),
      t('months.June'),
      t('months.July'),
      t('months.August'),
      t('months.September'),
      t('months.October'),
      t('months.November'),
      t('months.December'),
    ]

    const year = value.year()
    const month = value.month()
    const options = []
    for (let i = year - 10; i < year + 10; i += 1) {
      options.push(
        <Select.Option key={i} value={i}>
          {i}
        </Select.Option>
      )
    }

    return (
      <div style={{ padding: 8 }}>
        <Row gutter={8}>
          <Col>
            <CustomSelect
              dropdownMatchSelectWidth={false}
              value={year}
              onChange={newYear => {
                const now = value.clone().year(newYear)
                onChange(now)
              }}
            >
              {options}
            </CustomSelect>
          </Col>
          <Col>
            <CustomSelect
              dropdownMatchSelectWidth={false}
              value={month}
              onChange={newMonth => {
                const now = value.clone().month(newMonth)
                onChange(now)
              }}
            >
              {months.map((month, idx) => (
                <Select.Option key={idx} value={idx}>
                  {month}
                </Select.Option>
              ))}
            </CustomSelect>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div>
      <CustomButton onClick={() => setExpanded(prev => !prev)}>
        <div className={styles.btnBody}>
          <CalendarOutlined />
          <div
            className={classNames(styles.arrow, {
              [styles.opened]: expanded,
            })}
          >
            <ArrowDownOutlined />
          </div>
        </div>
      </CustomButton>
      {expanded && (
        <CalendarComponent
          key={templateStore.calendarTimestamp}
          dateFullCellRender={date => {
            const hasContent = isNewsMode
              ? !!templateEntity.eventsDates.find(eventDate =>
                  moment(eventDate.toString()).isSame(moment(date.toISOString()), 'day')
                )
              : !!templateStore.programDays.find(day => moment(day.date).isSame(date, 'day'))

            return (
              <div
                className={classNames('calendar_day', {
                  calendar_day_selected: selectedValue.isSame(date, 'day'),
                })}
              >
                {date.isSame(moment(), 'day') && <div className="calendar_today" />}
                {date.date()}
                <div
                  className={classNames('calendar_dot', {
                    ['calendar_dot_hasContent']: hasContent,
                  })}
                />
              </div>
            )
          }}
          headerRender={headerRender}
          className="calendar"
          disabledDate={date => {
            if (isNewsMode) {
              const candidate = templateEntity.eventsDates.findIndex(evDate => moment(evDate).isSame(date, 'day'))
              if (candidate === -1) return true
              return false
            } else {
              const candidate = templateStore.programDays.findIndex(day => moment(day.date).isSame(date, 'day'))
              if (candidate === -1) return true
              return false
            }
          }}
          value={value}
          onSelect={onSelect}
          onPanelChange={onPanelChange}
        />
      )}
    </div>
  )
}

export default observer(Calendar)
