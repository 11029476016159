import React from 'react'
import { Space } from 'antd'

import { FileMessageDto } from 'api/swagger.api'
import ChatMessageFileListItem from './ChatMessageFileListItem'

type Props = {
  files: FileMessageDto[]
}

const ChatMessageFileList: React.FC<Props> = ({ files }) => {
  return (
    <Space direction="vertical" size="small">
      {files.map(file => (
        <ChatMessageFileListItem key={file.fileId} file={file} />
      ))}
    </Space>
  )
}

export default ChatMessageFileList
