import { authApi } from 'config'

import { IBrandInfo } from 'types/User'
import { ICreateBrandDto, IUpdateBrandDto } from './DTOs/brands.dto'

const endpoint = 'Brands/'

const brandsApi = {
  async createCompanyBrand(formFields: ICreateBrandDto) {
    const response = await authApi.post(endpoint + 'create', formFields)
    return response.data
  },
  async updateCompanyBrand(formFields: IUpdateBrandDto) {
    const response = await authApi.patch(endpoint + 'update', formFields)
    return response.data
  },
  async getUserBrandInfo(brandName: string, isBySubdomain?: boolean) {
    try {
      if (!brandName) return

      const path = isBySubdomain ? endpoint + 'getBysubdomain/' : endpoint

      const responce = await authApi.get<IBrandInfo>(path + brandName)

      return responce.data
    } catch (e) {}
  },
  async getAllBrands() {
    const response = await authApi.get<(string | null)[]>(endpoint)
    return response.data
  },
}

export default brandsApi
