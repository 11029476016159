import { useQuery } from '@tanstack/react-query'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import messageTemplate from 'api/messageTemplates.api'
import { message } from 'antd'
import { ApiError } from 'types/common'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

const useMessagePaginationQuery = (pageNumber: number, pageLength: number, search: string) => {
  const { t } = useTranslation()
  const [done, setDone] = useState(false)
  const { data, isLoading, isError, isFetched } = useQuery({
    keepPreviousData: true,
    queryKey: [QueryKeysEnum.PROGRAM, pageNumber, search],
    queryFn: async () => {
      setDone(true)
      try {
        const users = await messageTemplate.getUsers(pageNumber, pageLength, search)
        return users
      } catch (e) {
        console.log(e)
      }
    },
    onError: (error: ApiError) => {
      setDone(true)
      const errMessage = error?.response?.data?.Message
      console.log(errMessage)
      message.error(t<string>(`messageTable.${errMessage}`))
    },
    onSettled: () => {
      setDone(false)
    },
  })

  return { data, isLoading, isError, isFetched, done }
}

export default useMessagePaginationQuery
