import { LeftOutlined, LoadingOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons'
import { Steps } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import templatesApi from 'api/templates.api'
import wallPostApi from 'api/wallPost.api'

import useIsRtl from 'hooks/useIsRtl'

import { useStore } from 'stores'

import dateTime from 'utils/dateTime'
import message from 'utils/message'

import styles from './styles.module.scss'

const { Step } = Steps

const STEP_WIDTH = 150

let timer: NodeJS.Timeout | null = null

const getContainerWidth = (daysCount: number) => daysCount * STEP_WIDTH

interface IProps {
  isNewsMode?: boolean
  loadingDayIdx: number | null
  onChangeDay: (idx: number) => void
}

const DaysNavigation: FC<IProps> = ({ isNewsMode, loadingDayIdx, onChangeDay }) => {
  const [stepsHorizontalHTML, setStepsHorizontalHTML] = useState<HTMLElement | null>(null)

  const { templateStore, userStore } = useStore()
  const { templateEntity } = templateStore

  const { t } = useTranslation()

  const isRtl = useIsRtl()

  useEffect(() => {
    setStepsHorizontalHTML(document.getElementById('steps_horizontal_scroll'))
  }, [])

  useEffect(() => {
    if (!stepsHorizontalHTML) return

    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      const rtlScrollPos = -(STEP_WIDTH * (templateEntity.currentDayIndex - 4))
      const ltrScrollPos = STEP_WIDTH * (templateEntity.currentDayIndex - 4)

      stepsHorizontalHTML.scrollTo({
        left: isRtl ? rtlScrollPos : ltrScrollPos,
        behavior: 'smooth',
      })
    }, 400)

    return () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
    }
  }, [templateEntity.currentDayIndex, stepsHorizontalHTML, isRtl])

  const handleScroll = (isLeft?: boolean) => {
    if (!stepsHorizontalHTML) return

    stepsHorizontalHTML.scrollBy({
      left: isLeft ? -STEP_WIDTH : +STEP_WIDTH,
      behavior: 'smooth',
    })
  }

  const handleAddDay = async () => {
    if (templateStore.isToolEditing) {
      message.error(t('templateCreation.previousEdit'))
      return
    }

    if (!templateEntity.templateInfo) return

    const templateId = templateEntity.templateInfo.id

    const savedDuration = templateEntity.templateInfo.duration

    if (templateEntity.programId) {
      await wallPostApi.addDay(templateEntity.templateInfo.id)
      await Promise.all([
        templateStore.getWallPostById({
          programId: templateEntity.programId.toString(),
          dayIndex: savedDuration,
        }),
        templateStore.setProgramDaysAPI(templateEntity.programId.toString()),
      ])
    } else {
      await templatesApi.addDay(templateEntity.templateInfo.id)
      await templateStore.getTemplateInfoById(templateEntity.templateInfo.id, true)
      await templateStore.getTemplateEntitiesDay({
        dayIndex: savedDuration,
        id: templateId,
        skipLoading: true,
      })
    }
  }

  if (!templateEntity.templateInfo || (isNewsMode && templateEntity.templateInfo?.duration === 0)) return <></>

  return (
    <div
      className={classNames(styles.stepsContainer, {
        [styles.stepsContainerRtl]: isRtl,
      })}
    >
      {templateEntity.templateInfo.duration >= 11 && (
        <div className={styles.scrollButton} onClick={() => handleScroll(true)}>
          <LeftOutlined />
        </div>
      )}
      <div className={styles.stepsScroll}>
        <div className={styles.steps}>
          <div
            id="steps_horizontal_scroll"
            className={styles.scroller}
            style={{
              width: Math.max(getContainerWidth(templateEntity.templateInfo.duration), 1095),
            }}
          >
            <Steps
              current={templateEntity.currentDayIndex}
              style={{
                paddingBottom: 10,
              }}
            >
              {Array.from({ length: templateEntity.templateInfo.duration }, (_, index) => {
                const isSkiped = !templateEntity.templateInfo?.duration && templateEntity.currentDayIndex > index

                const date = templateEntity?.eventsDates?.[index]?.split('T')?.[0]

                const currentStep = templateEntity.currentDayIndex === index
                const prevSteps = templateEntity.currentDayIndex > index

                return (
                  <Step
                    subTitle={
                      isNewsMode && date ? (
                        <span
                          className={classNames(styles.stepSubtitle, {
                            [styles.active]: templateEntity.currentDayIndex >= index,
                          })}
                        >
                          {dateTime.getHebrewDate(date) || ''}
                        </span>
                      ) : (
                        ''
                      )
                    }
                    onClick={() => onChangeDay(index)}
                    className={classNames(styles.step, {
                      [styles.prevStep]: prevSteps,
                      [styles.skip]: isSkiped,
                      [styles.rtlSkip]: isSkiped && isRtl,
                      [styles.scrollStep]:
                        !!templateEntity.templateInfo?.duration && templateEntity.templateInfo.duration >= 11,
                      [styles.currentStep]: currentStep,
                    })}
                    icon={null}
                    icons={{
                      finish: !!templateEntity.templateInfo?.duration ? index + 1 : <RightOutlined />,
                      error: !!templateEntity.templateInfo?.duration ? index + 1 : <RightOutlined />,
                    }}
                    data-template-step="template-step"
                    status={prevSteps || currentStep ? 'process' : void 0}
                    key={index}
                    stepIcon={
                      loadingDayIdx === index
                        ? () => <LoadingOutlined color={userStore.brandData?.primaryColor || '#0079cb'} />
                        : void 0
                    }
                  />
                )
              })}
              {!isNewsMode && (
                <Step
                  className={classNames(styles.step, styles.stepAddDay)}
                  icon={<PlusOutlined />}
                  icons={{
                    finish: <PlusOutlined />,
                    error: <PlusOutlined />,
                  }}
                  onClick={() => {
                    if (templateStore.isToolEditing) {
                      message.error(t('templateCreation.previousEdit'))
                      return
                    }

                    handleAddDay()
                  }}
                  data-template-add-step="template-add-step"
                />
              )}
            </Steps>
          </div>
        </div>
      </div>
      {templateEntity.templateInfo.duration >= 11 && (
        <div className={styles.scrollButton} onClick={() => handleScroll()}>
          <RightOutlined />
        </div>
      )}
    </div>
  )
}

export default observer(DaysNavigation)
