import { ExclamationCircleOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { ProgramCreationStepsEnum, ProgramForm, ProgramImage } from 'types/Program'
import { ITemplateOption } from 'types/Template'
import { ITraineesServerModel } from 'types/User'

import customConfirm from 'utils/customConfirm'
import { clearProgramStorage, getSavedProgramData } from 'utils/programCreationStorage'

import { ITraineesTable } from 'components/InviteTraineesTable'
import FileTranform from 'utils/FileTranform'

interface IProps {
  form: FormInstance<ProgramForm>
  setSelectedTemplateId: Dispatch<SetStateAction<number | null>>
  setSelectedTemplate: Dispatch<SetStateAction<ITemplateOption | undefined>>
  setTrainees: Dispatch<SetStateAction<ITraineesServerModel[]>>
  setCurrentStep: Dispatch<SetStateAction<ProgramCreationStepsEnum>>
  setSelectedTrainees: Dispatch<SetStateAction<ITraineesTable[]>>
  setLogoImage: Dispatch<SetStateAction<ProgramImage>>
  confirmClassname: string
  onProgramRestore: (programFromLS?: ProgramForm) => void
}

const useRestoreProgramData = ({
  form,
  setSelectedTemplate,
  setSelectedTemplateId,
  setTrainees,
  setCurrentStep,
  setSelectedTrainees,
  setLogoImage,
  confirmClassname,
  onProgramRestore,
}: IProps) => {
  const { t } = useTranslation()

  const { search } = useLocation()

  const navigate = useNavigate()

  const [comesFromTemplate, setComesFromTemplate] = useState(false)

  const restoreSavedProgramData = useCallback(
    async ({
      program,
      templateId,
      template,
      traineesSelected,
      logoImage,
      trainees,
    }: {
      program: ProgramForm
      templateId: number
      template: ITemplateOption
      traineesSelected: ITraineesTable[]
      logoImage: ProgramImage | any
      trainees: ITraineesServerModel[]
    }) => {
      if (program) {
        form.setFieldsValue(program)

        if (onProgramRestore) {
          onProgramRestore(program)
        }
      }

      if (templateId) setSelectedTemplateId(templateId)

      if (template) setSelectedTemplate(template)

      if (trainees) setTrainees(trainees)

      if (traineesSelected) setSelectedTrainees(traineesSelected)

      if (logoImage) {
        const file = await FileTranform.fromBase64(logoImage)

        if (!file) return

        if (file) setLogoImage({ file, url: URL.createObjectURL(file) })
      }
    },
    [onProgramRestore]
  )

  const restoreProgram = useCallback(() => {
    const { program, logoImage, groupImage, trainees, templateId, traineesSelected, template } = getSavedProgramData()

    if (program || logoImage || groupImage || trainees || templateId) {
      customConfirm({
        className: confirmClassname,
        title: t<string>('programCreation.modalTitle'),
        icon: <ExclamationCircleOutlined />,
        content: t<string>('programCreation.modalText'),
        cancelText: t<string>('programCreation.modalCancel'),
        okText: t<string>('programCreation.modalOK'),
        onCancel: clearProgramStorage,
        onOk: async () => {
          restoreSavedProgramData({
            program,
            templateId,
            template,
            traineesSelected,
            logoImage,
            trainees,
          })
        },
      })
    }
  }, [confirmClassname, restoreSavedProgramData])

  const backToCreation2step = useMemo(() => {
    return search.includes('?step=2')
  }, [search])

  useEffect(() => {
    const returnToTemplateStep = () => {
      if (comesFromTemplate || backToCreation2step) {
        const { program, logoImage, templateId, template } = getSavedProgramData()

        if (!program?.name || !program?.goal) {
          navigate('/programs/create')
          return
        }

        restoreSavedProgramData({
          program,
          templateId,
          template,
          logoImage,
          traineesSelected: [],
          trainees: [],
        })

        setComesFromTemplate(true)

        navigate('/programs/create')

        setCurrentStep(ProgramCreationStepsEnum.TEMPLATE)
      } else {
        restoreProgram()
      }
    }

    returnToTemplateStep()
  }, [backToCreation2step, comesFromTemplate])
}

export default useRestoreProgramData
