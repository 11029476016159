import { authApi } from 'config'
import { t } from 'i18next'

import { PaginationResponse } from 'types/common'
import { IGoal, IGoalCreate } from 'types/Goal'
import { IProgramList, ISuperLightProgram, ITraineeProgram } from 'types/Program'

import message from 'utils/message'

import { ICreateProgramDTO, ICreateQuickProgramDTO, IEditProgramDTO, IUpdateFilterDto } from './DTOs/programs.dto'

const endpoint = '/TrainingProgram/'

interface IProgramCreationProgress {
  programTemplateEntityCount: number
  programWallPostCount: number
}

const programsApi = {
  async getProgramsWhereMentor(programId?: number | string) {
    const response = await authApi.get<IProgramList[]>(endpoint + 'programsWhereMentor/', {
      params: {
        programId,
      },
    })

    return response.data
  },
  async getUserProgramsWithPagination({
    query,
    pageLength,
    pageNumber,
  }: {
    query: string
    pageLength: number
    pageNumber: number
  }) {
    try {
      const response = await authApi.get<PaginationResponse<IProgramList>>(
        endpoint + 'programsByNameWhereMentorWithPagination',
        {
          params: {
            name: query,
            pageLength,
            pageNumber,
          },
        }
      )

      return response.data
    } catch (e) {
      console.log(e)
    }
  },

  async copyProgram(programId: string | number, newTrainingProgramName: string): Promise<IProgramList | undefined> {
    try {
      const response = await authApi.post(endpoint + 'copy', { programId, newTrainingProgramName })

      return response.data
    } catch (e) {
      console.log(e)
    }
  },

  async deleteProgram(id: string | number): Promise<boolean> {
    try {
      await authApi.patch(endpoint + 'softDelete/' + id)

      return true
    } catch (e) {
      return false
    }
  },

  async createQuickProgram(quickProgramDTO: ICreateQuickProgramDTO, signal: AbortSignal) {
    try {
      const response = await authApi.post(`${endpoint}openAndAttach`, quickProgramDTO, {
        signal,
      })

      // @ts-ignore
      if (response?.message === 'canceled') {
        return 'canceled'
      }
      return response.data
    } catch (e) {
      console.log(e)
      return null
    }
  },
  async createProgram(programData: ICreateProgramDTO) {
    try {
      const response = await authApi.post<IProgramList>('/TrainingProgram/createWithTemplate', programData)

      return response.data
    } catch (e) {
      const { StatusCode } = e.response.data

      if (StatusCode === 400) {
        message.error(t<string>('programList.programExist', { name: programData.name }))
        return null
      }

      message.error(t<string>('programCreation.smtWrong'))
      return null
    }
  },
  async checkProgramFullness(programId: number) {
    const response = await authApi.get<IProgramCreationProgress>('/TrainingProgram/programFullness/' + programId)

    return Math.ceil((response.data.programWallPostCount * 100) / response.data.programTemplateEntityCount)
  },
  async getProgramByUniqueId(uniqueId: string) {
    const response = await authApi.get<IProgramList>(endpoint + 'getProgramByUniqueId', {
      params: { uniqueId },
    })

    return response.data
  },

  async updateProgram(programId: number, programData: IEditProgramDTO): Promise<void | boolean> {
    await authApi.patch(endpoint + 'update/' + programId, programData)

    return true
  },

  async getInviteLink(programId: string | number) {
    try {
      const response = await authApi.get<string>('/TrainingProgram/inviteLink', {
        params: { programId },
      })

      return response.data
    } catch (e) {
      message.error(t<string>('programCreation.smtWrong'))
    }
  },

  async getProgramById(programId: string) {
    const response = await authApi.get<IProgramList>(endpoint + 'program/' + programId)

    return response.data
  },
  async getProgramGoals(programId: number) {
    const response = await authApi.get<IGoal[]>(endpoint + 'getProgramGoals/' + programId)

    return response.data
  },
  async createProgramGoals(programId: number, data: IGoalCreate[]) {
    const response = await authApi.post(endpoint + 'createProgramGoals/' + programId, data)

    return response.data
  },
  async editProgramGoal(goalId: number, data: IGoal) {
    const response = await authApi.patch(endpoint + 'editProgramGoal/' + goalId, data)

    return response.data
  },
  async deleteProgramGoal(goalId: number) {
    const response = await authApi.delete(endpoint + 'deleteProgramGoal/' + goalId)

    return response.data
  },
  async getAllTraineePrograms(traineeId: number) {
    const response = await authApi.get<ITraineeProgram[]>(endpoint + 'programs/' + traineeId)

    return response.data
  },
  async getDefaultProgramAvatar() {
    const response = await authApi.get<number>(endpoint + 'defaultProgramAvatar/')

    return response.data
  },
  async leaveProgram(programId: number) {
    const response = await authApi.patch(`${endpoint}leaveProgram/${programId}`)

    return response.status === 200
  },
  async updateFilter(dto: IUpdateFilterDto) {
    const response = await authApi.patch(endpoint + 'updateFilter', dto)

    return response.data
  },
  async getLightPrograms() {
    const response = await authApi.get<ISuperLightProgram[]>(endpoint + 'lightProgramsWhereMentor')
    return response.data
  },
}

export default programsApi
