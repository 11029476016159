import { VariablesEnum } from 'types/User'
import { LanguagesType } from 'types/common'

import { days } from './dateTime'

interface IProps {
  text: string
  programName: string
  userFirstName: string
  userLastName: string
  date: string
  mentorFirstName: string
  mentorLastName: string
  language: LanguagesType
  phoneNumber: string
}

const decryptVariablesInString = ({
  date,
  programName,
  text,
  mentorFirstName,
  mentorLastName,
  userFirstName,
  userLastName,
  language,
  phoneNumber,
}: IProps) => {
  const dayNumber = new Date(date).getDay()
  const lang = language === 'en' ? 'en' : language === 'ru' ? 'ru' : 'he'

  const replaced = text
    .replace(VariablesEnum.firstName, userFirstName)
    .replace(VariablesEnum.lastName, userLastName)
    .replace(VariablesEnum.mentorName, `${mentorFirstName} ${mentorLastName}`)
    .replace(VariablesEnum.programName, programName)
    .replace(VariablesEnum.todayName, days[lang][dayNumber])
    .replace(VariablesEnum.phoneNumber, phoneNumber)

  return replaced
}

export default decryptVariablesInString
