import { ResponsiveLine } from '@nivo/line'
import React, { useMemo } from 'react'

import useIsRtl from 'hooks/useIsRtl'

import { IChartData } from 'types/MentorDashboard'

import Tooltip from './Tooltip'

interface IProps {
  data: IChartData[]
}

const Chart: React.FC<IProps> = ({ data }) => {
  const isRtl = useIsRtl()

  const dataWithRtl = useMemo(() => {
    if (isRtl) {
      return data
    }

    return [...data].reverse()
  }, [data, isRtl])

  return (
    <ResponsiveLine
      data={[
        {
          id: 'Attendance',
          data: dataWithRtl,
        },
      ]}
      margin={{ top: 10, right: 30, bottom: 30, left: 30 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 0,
        max: 100,
        stacked: true,
        reverse: false,
      }}
      axisLeft={
        !isRtl
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: 0,
            }
          : null
      }
      axisRight={
        isRtl
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: 0,
            }
          : null
      }
      yFormat=" >-.2f"
      colors={['#038C8C']}
      pointSize={8}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      tooltip={props => <Tooltip {...props} />}
      pointLabelYOffset={-12}
      enableArea={true}
      area-blend-mode="color-light"
      useMesh={true}
      areaOpacity={0.2}
      enableGridX={false}
    />
  )
}

export default Chart
