import { Select, SelectProps } from 'antd'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useAssignTagToTraineeMutation from 'hooks/tanstack/mutations/useAssignTagToTraineeMutation'
import useRemoveTagToTraineeMutation from 'hooks/tanstack/mutations/useRemoveTagToTraineeMutation'

import { filterOnlyAvailableTags } from 'utils/filterOnlyAvailableTags'
import message from 'utils/message'

import { IMentorTag, ITraineeTag } from 'types/Tags'

import CustomButton from 'components/CustomComponents/CustomButton'

import styles from './styles.module.scss'

export const mapTagToOption = (tag: IMentorTag, fromMoreThanOneCompany: boolean) => ({
  label: fromMoreThanOneCompany ? `${tag.name} (${tag.companyName})` : tag.name,
  value: tag.id,
})

interface IProps {
  traineeId: number
  tags: ITraineeTag[]
  availableTags: IMentorTag[]
  fromMoreThanOneCompany: boolean
  isLoading: boolean
  onSaved?: () => void
}

const TagsUserSelector: FC<IProps> = ({
  traineeId,
  tags: initialTags,
  availableTags,
  fromMoreThanOneCompany,
  isLoading,
  onSaved,
}) => {
  const { t } = useTranslation()

  const [included, setIncluded] = useState<number[]>([])
  const [excluded, setExcluded] = useState<number[]>([])

  const [touched, setTouched] = useState(false)

  useEffect(() => {
    if (initialTags.length) {
      const excludedTagIds: number[] = []
      const includedTagIds: number[] = []

      initialTags.filter(filterOnlyAvailableTags(availableTags)).forEach(tag => {
        if (tag.exclude) {
          excludedTagIds.push(tag.tagId)
        } else {
          includedTagIds.push(tag.tagId)
        }
      })

      setIncluded(includedTagIds)
      setExcluded(excludedTagIds)
    }
  }, [initialTags, availableTags])

  const { assingTagToTrainee, isLoading: assingationLoading } = useAssignTagToTraineeMutation()
  const { removeTagToTrainee, isLoading: removingLoading } = useRemoveTagToTraineeMutation()

  const includedOptions: SelectProps['options'] = useMemo(
    () =>
      availableTags.filter(tag => !excluded.includes(tag.id)).map(tag => mapTagToOption(tag, fromMoreThanOneCompany)),
    [availableTags, excluded, fromMoreThanOneCompany]
  )

  const excludedOptions: SelectProps['options'] = useMemo(
    () =>
      availableTags.filter(tag => !included.includes(tag.id)).map(tag => mapTagToOption(tag, fromMoreThanOneCompany)),
    [availableTags, included, fromMoreThanOneCompany]
  )

  const onIncludedChange = (ids: number[]) => {
    setIncluded(ids)
    setTouched(true)
  }

  const onExcludedChange = (ids: number[]) => {
    setExcluded(ids)
    setTouched(true)
  }

  const onSave = async () => {
    setTouched(false)

    const tagsToRemove = initialTags.flatMap(tag => {
      if (tag.exclude && excluded.find(id => id === tag.tagId)) return []
      if (!tag.exclude && included.find(id => id === tag.tagId)) return []

      return [{ tagId: tag.tagId, traineeId: tag.userId }]
    })

    const onlyNewTagsToInclude = included.filter(id => !initialTags.find(tag => tag.tagId === id && !tag.exclude))
    const onlyNewTagsToExclude = excluded.filter(id => !initialTags.find(tag => tag.tagId === id && tag.exclude))

    await removeTagToTrainee(tagsToRemove)

    await assingTagToTrainee({
      tags: onlyNewTagsToInclude.map(tagId => ({ tagId, exclude: false })),
      traineeId,
    })

    await assingTagToTrainee({
      tags: onlyNewTagsToExclude.map(tagId => ({ tagId, exclude: true })),
      traineeId,
    })

    if (onSaved) onSaved()

    message.success(t('companyPage.tagsSaved'))
  }

  if (!availableTags.length) return <></>

  return (
    <div>
      <div className={styles.mainTitle}>{t('companyPage.tags')}:</div>
      <div className={styles.item}>
        <div className={styles.title}>{t('companyPage.includedTags')}</div>
        <Select
          mode="multiple"
          size="large"
          allowClear
          value={included}
          onChange={onIncludedChange}
          placeholder={t('companyPage.selectTags')}
          optionFilterProp="label"
          options={includedOptions}
          className="tags-selector"
          style={{ width: '100%' }}
          loading={isLoading}
          getPopupContainer={trigger => trigger}
        />
      </div>

      <div>
        <div className={styles.title}>{t('companyPage.excludedTags')}</div>
        <Select
          mode="multiple"
          size="large"
          allowClear
          value={excluded}
          onChange={onExcludedChange}
          optionFilterProp="label"
          placeholder={t('companyPage.selectTags')}
          options={excludedOptions}
          className="tags-selector"
          style={{ width: '100%' }}
          loading={isLoading}
          getPopupContainer={trigger => trigger}
        />
      </div>

      {touched && (
        <div className={styles.btnContainer}>
          <CustomButton type="primary" loading={removingLoading || assingationLoading} onClick={onSave}>
            {t('programCreation.save')}
          </CustomButton>
        </div>
      )}
    </div>
  )
}

export default TagsUserSelector
