import { UploadFile } from 'antd'
import _ from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { useStore } from 'stores'

import { IAudioDayItem, IFileDayItem, IFileLoadProgress, IImageDayItem, IVideoDayItem } from 'types/Template'

type useToolFileLoadingPropsType = {
  setFiles: Dispatch<SetStateAction<UploadFile<any>[]>>
  item: IImageDayItem | IVideoDayItem | IFileDayItem | IAudioDayItem
  toolIndex: number
}

const useToolFileLoading = ({ setFiles, item, toolIndex }: useToolFileLoadingPropsType) => {
  const [toolLoading, setToolLoading] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState<IFileLoadProgress[]>([])

  const { programStore, templateStore } = useStore()
  const { templateEntity } = templateStore

  useEffect(() => {
    const setInitialFile = async () => {
      if (!item) return

      const fileIds = item.fileIds

      if (!fileIds || !fileIds?.length || fileIds.length === item.fileList.length) return

      try {
        setToolLoading(true)

        const res: UploadFile<any>[] = []

        const fileIdChunks = _.chunk(fileIds, 3)

        for (const chunk of fileIdChunks) {
          const files = await Promise.all(
            chunk.map(async id => await programStore.getProgramImage(id, false, setLoadingProgress))
          )

          files.forEach((file, idx) => {
            res.push(file)
          })
        }

        const files = [...res, ...item.fileList.filter(fileListItem => fileListItem?.uid?.includes('rc-upload'))]

        if (templateEntity.currentDayIndex !== undefined && toolIndex !== undefined) {
          templateStore.setFilesInToolsEntityInfo({
            dayIndex: templateEntity.currentDayIndex,
            toolIndex,
            entityInfo: item.entityIds.map((entityId, idx) => {
              return { entityId, fileId: files?.[idx]?.url || '' }
            }),
          })
        }

        item.fileList = files
        setFiles(files)
      } catch (e) {
        console.log(e)
      } finally {
        setToolLoading(false)
      }
    }

    setInitialFile()
  }, [item.entityIds, item.fileList, templateEntity.currentDayIndex])

  return { toolLoading, loadingProgress }
}

export default useToolFileLoading
