import { useStore } from 'stores'

const useIsRtl = () => {
  const { userStore } = useStore()

  if (!userStore.language) return false

  return userStore.language.toLowerCase() !== 'en' && userStore.language.toLowerCase() !== 'ru'
}

export default useIsRtl
