import React, { DetailedHTMLProps, HTMLAttributes } from 'react'
import styles from './styles.module.scss'
import { DeleteOutlined } from '@ant-design/icons'
import cn from 'classnames'

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const CustomDeleteIcon: React.FC<IProps> = ({ className, ...rest }) => {
  return (
    <div className={cn(styles.deleteIcon, className)} {...rest}>
      <DeleteOutlined />
    </div>
  )
}

export default CustomDeleteIcon
