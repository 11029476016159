import { Spin, Tag } from 'antd'
import { t } from 'i18next'
import React, { ReactNode } from 'react'

import { ITemplateOption } from 'types/Template'

import classNames from 'classnames'
import styles from './styles.module.scss'

interface IProps {
  selectedTemplate: ITemplateOption | undefined
  withoutBorder?: boolean
  children: ReactNode
}

const SelectedTemplate: React.FC<IProps> = ({ selectedTemplate, children, withoutBorder }) => {
  return (
    <Spin spinning={!selectedTemplate}>
      <div className={classNames(styles.selectedTemplate, { [styles.selectedTemplateWithoutBorder]: withoutBorder })}>
        <div className={styles.selectedTemplateHead}>
          <div>
            <div>{t<string>('programList.template')}</div>
            <div className={styles.selectedTemplateTitle}>
              <div>{selectedTemplate?.label}</div>
              <Tag color="cyan" style={{ borderRadius: 10 }}>
                {selectedTemplate?.duration} {t<string>('templateCreation.days')}
              </Tag>
            </div>
          </div>
          {children}
        </div>
      </div>
    </Spin>
  )
}

export default SelectedTemplate
