import { UploadOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload'
import Dragger from 'antd/lib/upload/Dragger'
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import invitationsApi from 'api/invitations.api'

import { IImportedTraineesCSV } from 'types/User'

import message from 'utils/message'

import ProgressBar from 'components/ProgressBar'

import styles from './styles.module.scss'

interface IProps {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  onAfterImportTrainees: (trainees: IImportedTraineesCSV[]) => void
}

const ImportFromCvsModal: FC<IProps> = ({ visible, setVisible, onAfterImportTrainees }) => {
  const { t } = useTranslation()
  const [uploadingInProgress, setUploadingInProgress] = useState(false)
  const [fileName, setFileName] = useState('')
  const [uploadProgress, setUploadProgress] = useState(0)

  const axiosControllerRef = useRef<AbortController | null>(null)

  const beforeUpload = (file: RcFile) => {
    const isCsv = file.type === 'application/vnd.ms-excel' || file.type === 'text/csv'

    if (!isCsv) {
      // message.error(t<string>('dashboard.csvFileOnly'))

      return Promise.reject<string>('not csv format')
    }

    return false
  }

  const onChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    const { file } = info

    if (!file) return

    setFileName(file.name)
    setUploadingInProgress(true)

    const controller = new AbortController()

    axiosControllerRef.current = controller

    const trainees = await invitationsApi.validateTraineesCsv(file, setUploadProgress, controller.signal)

    if (trainees && !trainees?.Message) {
      onAfterImportTrainees(trainees)
      message.success(t<string>('programCreation.traineesImportedSuccess'))
    } else {
      setUploadingInProgress(false)
      message.error(t<string>('dashboard.csvReadError'))
    }
  }

  const onCancel = () => {
    axiosControllerRef.current?.abort()
    setUploadingInProgress(false)
    setUploadProgress(0)
  }

  useEffect(() => {
    if (!visible) {
      setUploadProgress(0)
      setUploadingInProgress(false)
    }
  }, [visible])

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      destroyOnClose={true}
      title={t<string>('programCreation.importTrainee')}
      className="importTraineesCVSModal"
    >
      <div>
        <Dragger
          name="file"
          beforeUpload={beforeUpload}
          onChange={onChange}
          showUploadList={false}
          multiple={false}
          disabled={uploadingInProgress}
          data-import-trainees-drag="import-trainees-drag"
          id="import-trainees-drag"
        >
          {uploadingInProgress ? (
            <div>
              <ProgressBar percent={uploadProgress} />
              <div className={styles.loadingInfo}>
                <div>{t<string>('programCreation.uploadingFile')}</div>
                <div className={styles.fileName}>{fileName}</div>
              </div>
              <Button
                className={styles.cancelBtn}
                onClick={onCancel}
                data-import-trainees-cancel="import-trainees-cancel"
                id="import-trainees-cancel"
              >
                {t<string>('dashboard.cancel')}
              </Button>
            </div>
          ) : (
            <>
              <div className={styles.uploadIcon}>
                <UploadOutlined />
              </div>
              <div className={styles.textContainer}>
                <div>{t<string>('programCreation.clickOrDrag')}</div>
                <div>{t<string>('templateCreation.or')}</div>
                <div className={styles.link}>{t<string>('programCreation.browse')}</div>
                <div className={styles.info}>
                  <div>{t<string>('programCreation.limitedUsersUpload')}</div>
                  <div>{t<string>('programCreation.supportedTypeFile')}</div>
                </div>
              </div>
              <a
                href="https://docs.google.com/spreadsheets/d/160La5Rrx2hsnOdoY-Tz9AU8U0l_2mFgxR7Bo4nQf98E/edit#gid=1641171426"
                className={styles.link}
                onClick={e => e.stopPropagation()}
                target="_blank"
                rel="noreferrer"
              >
                {t<string>('dashboard.exampleFile')}
              </a>
            </>
          )}
        </Dragger>
      </div>
    </Modal>
  )
}

export default ImportFromCvsModal
