import classNames from 'classnames'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'

import { useStore } from 'stores'

import { FollowUpActionsEnum } from 'types/Template'

import { urlRe } from 'utils/const'
import { QuestionnaireFollowUpActionsParser } from 'utils/parseTemplate'
import zapHookValidate from 'utils/zapHookValidate'

import CustomInput from 'components/CustomComponents/CustomInput'
import CustomSelect from 'components/CustomComponents/CustomSelect'

import styles from './styles.module.scss'

const questionnaireFollowUpParser = new QuestionnaireFollowUpActionsParser()

interface IProps {
  setFollowUpAction: (action: FollowUpActionsEnum, actionField: string) => void
  currentAction: FollowUpActionsEnum | null
  currentActionField: string
  canValidate: boolean
  isUnbound?: boolean
  dayId?: number
  toolId?: number
}

const FollowUp: FC<IProps> = ({
  setFollowUpAction,
  currentAction,
  currentActionField,
  canValidate,
  isUnbound,
  dayId,
  toolId,
}) => {
  const { wallPostStore, templateStore } = useStore()
  const { templateEntity } = templateStore

  const followUpStore = templateEntity.isWallPosts ? wallPostStore.followUpStore : templateStore.followUpStore

  const followUpActionsAsOptions = [
    {
      label: t<string>('templateCreation.doNothing'),
      value: FollowUpActionsEnum.doNothing,
    },
    {
      label: t<string>('templateCreation.followUpContent'),
      value: FollowUpActionsEnum.content,
    },
    {
      label: t<string>('templateCreation.callUrl'),
      value: FollowUpActionsEnum.URL,
    },
    {
      label: t<string>('templateCreation.callWebhook'),
      value: FollowUpActionsEnum.Webhook,
    },
  ]

  const isContentExist = useMemo(() => {
    if (currentAction !== FollowUpActionsEnum.content) return true

    if (!currentActionField) return false

    const inWallPosts = questionnaireFollowUpParser.findAvailableContentToFollowUpById(
      +currentActionField,
      true,
      !isUnbound
    )
    const inTemplate = questionnaireFollowUpParser.findAvailableContentToFollowUpById(
      +currentActionField,
      false,
      !isUnbound
    )

    return !!inWallPosts?.postType || !!inTemplate?.postType
  }, [
    currentActionField,
    currentAction,
    wallPostStore.followUpStore.availableContentForFollowUp,
    templateStore.followUpStore.availableContentForFollowUp,
  ])

  const isValid =
    currentAction === FollowUpActionsEnum.URL
      ? urlRe?.test(currentActionField)
      : currentAction === FollowUpActionsEnum.content
      ? currentActionField && !isNaN(+currentActionField)
      : currentAction === FollowUpActionsEnum.Webhook
      ? typeof currentActionField === 'string'
        ? zapHookValidate(currentActionField)
        : false
      : true

  const isValidError =
    currentAction === FollowUpActionsEnum.URL
      ? t<string>('templateCreation.invalidUrl')
      : currentAction === FollowUpActionsEnum.content
      ? t<string>('templateCreation.contentWithThisNumberNotExist')
      : currentAction === FollowUpActionsEnum.Webhook
      ? t<string>('templateCreation.invalidWebhook')
      : true

  const followUpToItselfError =
    currentAction === FollowUpActionsEnum.content && (+currentActionField === dayId || +currentActionField === toolId)

  const isFollowUpError = canValidate && (!isValid || !isContentExist || followUpToItselfError)

  return (
    <div className={styles.actions}>
      <CustomSelect
        options={followUpActionsAsOptions}
        defaultActiveFirstOption={true}
        value={currentAction}
        onChange={action => setFollowUpAction(action, currentActionField)}
        className={styles.select}
      />
      {currentAction !== null && currentAction !== FollowUpActionsEnum.doNothing && (
        <div className={styles.followUpInputContainer}>
          {currentAction === FollowUpActionsEnum.content ? (
            <CustomSelect
              filterOption={(inputValue, option) =>
                (option?.label?.toString() ?? '').toLowerCase().includes(inputValue.toLowerCase())
              }
              showSearch
              onChange={value => setFollowUpAction(FollowUpActionsEnum.content, value)}
              options={isUnbound ? followUpStore.followUpOptions : followUpStore.getFollowUpOptionsForBound}
              placeholder={t<string>('templateCreation.contentNumber')}
              value={!currentActionField ? void 0 : currentActionField}
              className={classNames(styles.followUpSelect, {
                [styles.error]: isFollowUpError,
              })}
            />
          ) : (
            <CustomInput
              value={currentActionField}
              onChange={e => setFollowUpAction(currentAction, e.target.value)}
              isErrorNeeded={false}
              placeholder={
                currentAction === FollowUpActionsEnum.Webhook
                  ? t('integration.webHookUrl')
                  : currentAction === FollowUpActionsEnum.URL
                  ? 'URL'
                  : ''
              }
              className={classNames(styles.input, {
                [styles.error]: isFollowUpError,
              })}
            />
          )}
          {isFollowUpError && (
            <div className={styles.errorAbs}>
              {!currentActionField
                ? t<string>('programCreation.required')
                : !isValid
                ? isValidError
                : followUpToItselfError
                ? t<string>('templateCreation.selfFollowUpErr')
                : t<string>('templateCreation.contentWithThisNumberNotExist')}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default observer(FollowUp)
