import React, { FC, memo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import LoadPage from "components/LoadPage";

import DashboardMentor from "./DashboardMentor";
import Message from "./Message";
import MessageInProgram from "./MessageInProgram";
import Profile from "./Profile";
import ProgramCreation from "./ProgramCreation";
import ProgramDetail from "./ProgramDetail";
import ProgramList from "./ProgramList";
import Report from "./Report";
import TemplateCreation from "./TemplateCreation";
import TemplateEditing from "./TemplateEditing";
import TemplateList from "./TemplateList";
import Trainees from "./Trainees";
import WallPost from "./WallPost";
import UploadFile from "./UploadFilePage";
import SendMessage from "./SendMessage";
import CreateMessage from "./CompanyAdmin/CompanyMessage/message";
import Chat from "./Chat";

const CompanyDashboard = React.lazy(
	() => import("./CompanyAdmin/CompanyDashboard"),
);
const CompanyUsers = React.lazy(() => import("./CompanyAdmin/CompanyUsers"));
const CompanySettings = React.lazy(
	() => import("./CompanyAdmin/CompanySettings"),
);
const CompanyIntegrations = React.lazy(
	() => import("./CompanyAdmin/CompanyIntegrations"),
);
const CompanyPrograms = React.lazy(
	() => import("./CompanyAdmin/CompanyPrograms"),
);
const CompanyTags = React.lazy(() => import("./CompanyAdmin/CompanyTags"));
const CompanyMessage = React.lazy(
	() => import("./CompanyAdmin/CompanyMessage"),
);
const CompanyTagsAutomatic = React.lazy(
	() => import("./CompanyAdmin/CompanyAutotags"),
);
const SettingsProfiles = React.lazy(
	() => import("./CompanyAdmin/SettingsProfiles"),
);
const Trainee = React.lazy(() => import("./Trainee"));
const Scenario = React.lazy(() => import("./Scenario"));

interface IProps {
	isCompanyModerator: boolean;
	isMessagesOnlyAccess: boolean;
	isMessagesAndProgramsAccess: boolean;
}

const Mentor: FC<IProps> = ({
	isCompanyModerator,
	isMessagesOnlyAccess,
	isMessagesAndProgramsAccess,
}) => {
	return (
		<Routes>
			{isMessagesOnlyAccess ? (
				<>
					<Route path="/message" element={<Message />} />
					<Route
						path="/message/in-program/:id"
						element={<MessageInProgram />}
					/>
					<Route path="/profile" element={<Profile />} />
					<Route path="/sendMessage" element={<SendMessage />} />
					<Route path="*" element={<Navigate to="/message" replace />} />
				</>
			) : isMessagesAndProgramsAccess ? (
				<>
					<Route path="/message" element={<Message />} />
					<Route
						path="/message/in-program/:id"
						element={<MessageInProgram />}
					/>
					<Route path="/profile" element={<Profile />} />
					<Route path="/sendMessage" element={<SendMessage />} />
					<Route path="/programs" element={<ProgramList />} />
					<Route path="/programs/create" element={<ProgramCreation />} />
					<Route path="/programs/:id" element={<ProgramDetail />} />
					<Route path="*" element={<Navigate to="/message" replace />} />
				</>
			) : (
				<>
					<Route path="/dashboard" element={<DashboardMentor />} />
					<Route path="/programs" element={<ProgramList />} />
					<Route path="/programs/create" element={<ProgramCreation />} />
					<Route path="/programs/:id" element={<ProgramDetail />} />
					<Route path="/template" element={<TemplateList />} />
					<Route path="/template/create" element={<TemplateCreation />} />
					<Route path="/template/:id" element={<TemplateEditing />} />
					<Route path="/report/:id" element={<Report />} />
					<Route path="/report" element={<Report />} />
					<Route path="/wall-post/:id" element={<WallPost />} />
					<Route path="/message" element={<Message />} />
					<Route path="/profile" element={<Profile />} />
					<Route path="/trainees" element={<Trainees />} />
					<Route path="/uploadFile" element={<UploadFile />} />
					<Route path="/trainees/:phoneNumber" element={<Trainee />} />
					<Route
						path="/scenario/wall-post/:wallPostId/:trainingProgramId/:scenarioId"
						element={<Scenario type="wall-post" />}
					/>
					<Route
						path="/scenario/template/:templateId/:templateEntityId/:scenarioId"
						element={<Scenario type="template" />}
					/>
					<Route path="/chat/:chatRelationshipId" element={<Chat />} />
					<Route path="/sendMessage" element={<SendMessage />} />
					<Route path="/company/message/create" element={<CreateMessage />} />

					{isCompanyModerator && (
						<>
							<Route
								path="/company"
								element={
									<React.Suspense fallback={<LoadPage />}>
										<CompanyDashboard />
									</React.Suspense>
								}
							/>
							<Route
								path="/company/users"
								element={
									<React.Suspense fallback={<LoadPage />}>
										<CompanyUsers />
									</React.Suspense>
								}
							/>
							<Route
								path="/company/settings"
								element={
									<React.Suspense fallback={<LoadPage />}>
										<CompanySettings />
									</React.Suspense>
								}
							/>
							<Route
								path="/company/integrations"
								element={
									<React.Suspense fallback={<LoadPage />}>
										<CompanyIntegrations />
									</React.Suspense>
								}
							/>
							<Route
								path="/company/programs"
								element={
									<React.Suspense fallback={<LoadPage />}>
										<CompanyPrograms />
									</React.Suspense>
								}
							/>
							<Route
								path="/company/tags"
								element={
									<React.Suspense fallback={<LoadPage />}>
										<CompanyTags />
									</React.Suspense>
								}
							/>
							<Route
								path="/company/profile-settings"
								element={
									<React.Suspense fallback={<LoadPage />}>
										<SettingsProfiles />
									</React.Suspense>
								}
							/>
							<Route
								path="/company/tags-automatic"
								element={
									<React.Suspense fallback={<LoadPage />}>
										<CompanyTagsAutomatic />
									</React.Suspense>
								}
							/>
							<Route
								path="/company/message"
								element={
									<React.Suspense fallback={<LoadPage />}>
										<CompanyMessage />
									</React.Suspense>
								}
							/>
						</>
					)}
					<Route path="*" element={<Navigate to="/dashboard" replace />} />
				</>
			)}
		</Routes>
	);
};

export default memo(Mentor);
