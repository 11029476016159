import { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

import useDebounce from './useDebounce'

const useSearchWithPagination = () => {
  const isMounted = useRef(false)

  const [searchParams, setSearchParams] = useSearchParams({ pageNumber: '1', search: '' })
  const search = searchParams.get('search') || ''
  const pageNumber = searchParams.get('pageNumber') || '1'

  const debouncedSearch = useDebounce(search, 500)

  const setPageNumber = (pageNumber: number) => {
    setSearchParams(
      prev => {
        const params = new URLSearchParams(window.location.href)
        prev.set('pageNumber', pageNumber.toString())
        prev.set('search', params.get('search') || '')
        return prev
      },
      { replace: true }
    )
  }

  const setSearch = (search: string) => {
    setSearchParams(prev => {
      const params = new URLSearchParams(window.location.href)
      prev.set('search', search)
      prev.set('pageNumber', params.get('pageNumber') || '1')
      return prev
    })
  }

  useEffect(() => {
    if (isMounted.current) {
      setPageNumber(1)
    } else {
      isMounted.current = true
    }
  }, [debouncedSearch])

  return {
    search,
    debouncedSearch,
    setSearch,
    pageNumber,
    setPageNumber,
  }
}

export default useSearchWithPagination
