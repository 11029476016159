export enum FileType {
  Video = 'video',
  Audio = 'audio',
  Pdf = 'pdf',
  Excel = 'excel',
  Word = 'word',
  Csv = 'csv',
}

export const isVideo = (input: string): boolean => {
  const videoMimes = ['video/mp4', 'video/ogg', 'video/webm', 'video/avi', 'video/mpeg', 'video/quicktime']
  const videoExtensions = ['.mp4', '.ogg', '.webm', '.avi', '.mpeg', '.mov']

  return videoMimes.includes(input) || videoExtensions.some(ext => input.endsWith(ext))
}

export const isAudio = (input: string): boolean => {
  const audioMimes = ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/mp4', 'audio/webm', 'audio/flac']
  const audioExtensions = ['.mp3', '.wav', '.ogg', '.mp4', '.webm', '.flac']

  return audioMimes.includes(input) || audioExtensions.some(ext => input.endsWith(ext))
}

export const isPdf = (input: string): boolean => {
  return input === 'application/pdf' || input.endsWith('.pdf')
}

export const isXlsx = (input: string): boolean => {
  const xlsxMimes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
  const xlsxExtensions = ['.xlsx', '.xls']

  return xlsxMimes.includes(input) || xlsxExtensions.some(ext => input.endsWith(ext))
}

export const isDoc = (input: string): boolean => {
  const docMimes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
  const docExtensions = ['.doc', '.docx']

  return docMimes.includes(input) || docExtensions.some(ext => input.endsWith(ext))
}

export const isCsv = (input: string): boolean => {
  return input === 'text/csv' || input.endsWith('.csv')
}

export const getFileType = (input = ''): FileType | undefined => {
  if (isVideo(input)) return FileType.Video
  if (isAudio(input)) return FileType.Audio
  if (isPdf(input)) return FileType.Pdf
  if (isXlsx(input)) return FileType.Excel
  if (isDoc(input)) return FileType.Word
  if (isCsv(input)) return FileType.Csv

  return undefined
}
