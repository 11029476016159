import { useEffect, useMemo } from 'react'

import usePagination from './usePagination'

const useClientSidePaginarion = <ItemType>({
  items,
  pageLength = 10,
  hideSizer,
}: {
  items: ItemType[]
  pageLength?: number
  hideSizer?: boolean
}) => {
  const { pagination, setPagination, propsToPaginationComponent } = usePagination({ pageLength, hideSizer })

  useEffect(() => {
    const totalPages = Math.ceil(items.length / pageLength)

    setPagination(prev => ({
      ...prev,
      pageLength,
      pageNumber: 1,
      itemCount: items.length,
      totalItems: items.length,
      totalPages,
    }))
  }, [items, pageLength])

  const itemsPortion = useMemo(() => {
    if (!items.length) return []

    const start = pagination.pageNumber <= 0 ? 0 : (pagination.pageNumber - 1) * pagination.pageLength
    const end = pagination.pageNumber * pagination.pageLength

    return items.slice(start, end)
  }, [items, pagination])

  return {
    pagination,
    setPagination,
    itemsPortion,
    propsToPaginationComponent,
  }
}

export default useClientSidePaginarion
