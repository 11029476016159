import { DownloadOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import moment from 'moment'
import { Dispatch, FC, memo, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import reportsApi from 'api/reports.api'

import { ICalendarDateRange } from 'hooks/reports/useReportFilters'

import dateTime, { defaultReportDate } from 'utils/dateTime'
import message from 'utils/message'

import { IDailyReportAnswer, IProgramList } from 'types/Program'
import { IReportUserLocal } from 'types/Report'

import FilterBtn from './FilterBtn'
import SelectDateMenu from './SelectDateMenu'
import SelectQuestionMenu from './SelectQuestionMenu'
import SelectUsersMenu from './SelectUsersMenu'

import CustomButton from 'components/CustomComponents/CustomButton'

import styles from './styles.module.scss'

interface IProps {
  dateTimeFrom: string | undefined
  dateTimeTill: string | undefined
  programId: number | null
  isAllRowsExpanded: boolean
  handleExpandAll: () => void
  handleCollapseAll: () => void
  allUsers: IReportUserLocal[]
  selectedUserIds: number[]
  setSelectedUserIds: Dispatch<SetStateAction<number[]>>
  traineesLoading: boolean
  dateRange: ICalendarDateRange
  setDateRange: Dispatch<SetStateAction<ICalendarDateRange>>
  currentProgram: IProgramList | undefined
  repeatedQuestions: IDailyReportAnswer[]
  selectedQuestionNames: string[]
  setSelectedQuestionNames: Dispatch<SetStateAction<string[]>>
  questionIdsToXlsxReport: number[]
  loading: boolean
}

const SecondaryPanel: FC<IProps> = ({
  dateTimeFrom,
  programId,
  dateTimeTill,
  isAllRowsExpanded,
  handleCollapseAll,
  handleExpandAll,
  allUsers,
  selectedUserIds,
  setSelectedUserIds,
  traineesLoading,
  dateRange,
  setDateRange,
  currentProgram,
  repeatedQuestions,
  selectedQuestionNames,
  setSelectedQuestionNames,
  questionIdsToXlsxReport,
  loading,
}) => {
  const { t } = useTranslation()

  const [selectTraineesMenulVisible, setSelectTraineesMenuVisible] = useState(false)
  const [selectDateMenuVisible, setSelectDateMenuVisible] = useState(false)
  const [selectQuestionMenuVisible, setSelectQuestionMenuVisible] = useState(false)

  const handleDownload = useCallback(async () => {
    try {
      if (!dateTimeFrom || !dateTimeTill) return

      if (!programId) return

      const blob = await reportsApi.getXlsxReport({
        trainingProgramId: programId,
        dateTimeFrom,
        dateTimeTill,
        traineesId: selectedUserIds,
        questionsId: questionIdsToXlsxReport,
      })

      if (!blob || !blob?.size) return

      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', `daily_report.xlsx`)
      link.click()
    } catch (e: any) {
      console.error('e.message', e.message)

      message.error(t<string>('programCreation.smtWrong'))
    }
  }, [dateTimeFrom, dateTimeTill, programId, selectedUserIds, questionIdsToXlsxReport])

  const resetFilters = useCallback(() => {
    if (!currentProgram) {
      setDateRange(defaultReportDate)
    } else {
      const date = dateTime.calcDefaultReportRange(currentProgram.endDate, currentProgram.startDate)

      setDateRange(date)
    }

    setSelectedUserIds(allUsers.map(trainee => trainee.id))
    setSelectedQuestionNames(repeatedQuestions.map(question => question.question))
  }, [currentProgram, allUsers, repeatedQuestions])

  const isDefaultDate = useMemo(() => {
    if (!currentProgram) {
      return (
        moment(dateRange.startDate).isSame(defaultReportDate.startDate) &&
        moment(dateRange.endDate).isSame(defaultReportDate.endDate)
      )
    }

    const date = dateTime.calcDefaultReportRange(currentProgram.endDate, currentProgram.startDate)

    return moment(dateRange.startDate).isSame(date.startDate) && moment(dateRange.endDate).isSame(date.endDate)
  }, [currentProgram, dateRange])

  const isToday = useMemo(
    () => moment(dateRange.endDate).isSame(new Date(), 'day') && moment(dateRange.startDate).isSame(new Date(), 'day'),
    [dateRange]
  )

  const isOneDay = useMemo(() => moment(dateRange.endDate).isSame(moment(dateRange.startDate)), [dateRange])

  const dateToShow = isToday
    ? t<string>('dailyReport.today')
    : isOneDay
    ? moment(dateRange.startDate).format('MMM Do YYYY')
    : `${moment(dateRange.startDate).format('MMM Do YYYY')} - ${moment(dateRange.endDate).format('MMM Do YYYY')}`

  const isLastProgramDay = useMemo(
    () => isOneDay && moment(dateRange.endDate).isSame(moment(currentProgram?.endDate), 'day'),
    [isOneDay, dateRange.endDate, currentProgram?.endDate]
  )

  const isAllUsers = selectedUserIds.length >= allUsers.length

  const isAllQuestionsSelect = selectedQuestionNames.length >= repeatedQuestions.length

  const filtersActive = !isAllUsers || (!isToday && !isLastProgramDay && !isDefaultDate) || !isAllQuestionsSelect

  return (
    <div className={styles.container}>
      <div className={classNames(styles.block, styles.sm)}>
        <FilterBtn
          text={
            isAllUsers
              ? t<string>('dailyReport.allUsers')
              : t<string>('dailyReport.selectedUsers') + ` (${selectedUserIds.length})`
          }
          visible={selectTraineesMenulVisible}
          onVisibleChange={setSelectTraineesMenuVisible}
          Overlay={
            <SelectUsersMenu
              allUsers={allUsers}
              selectedUserIds={selectedUserIds}
              setSelectedUserIds={setSelectedUserIds}
              traineesLoading={traineesLoading}
            />
          }
        />
        <FilterBtn
          text={dateToShow}
          visible={selectDateMenuVisible}
          onVisibleChange={setSelectDateMenuVisible}
          Overlay={
            <SelectDateMenu
              dateRange={dateRange}
              setDateRange={setDateRange}
              selectDateMenuVisible={selectDateMenuVisible}
              setSelectDateMenuVisible={setSelectDateMenuVisible}
              currentProgram={currentProgram}
            />
          }
        />
        <FilterBtn
          text={
            isAllQuestionsSelect
              ? t<string>('dailyReport.allQuestions')
              : t<string>('dailyReport.selectedQuestions') + ` (${selectedQuestionNames.length})`
          }
          visible={selectQuestionMenuVisible}
          onVisibleChange={setSelectQuestionMenuVisible}
          Overlay={
            <SelectQuestionMenu
              repeatedQuestions={repeatedQuestions}
              selectedQuestionNames={selectedQuestionNames}
              setSelectedQuestionNames={setSelectedQuestionNames}
            />
          }
        />
        {filtersActive && (
          <CustomButton onClick={resetFilters} className={styles.resetBtn} type="link">
            {t<string>('dailyReport.resetFilters')}
          </CustomButton>
        )}
      </div>
      <div className={styles.block}>
        {!loading && (
          <CustomButton
            type="default"
            onClick={() => {
              if (isAllRowsExpanded) {
                handleCollapseAll()
              } else {
                handleExpandAll()
              }
            }}
          >
            {isAllRowsExpanded ? t<string>('dailyReport.collapseAll') : t<string>('dailyReport.expandAll')}
          </CustomButton>
        )}
        {!!programId && !loading && (
          <CustomButton
            type="default"
            className={styles.uploadBtn}
            onClick={handleDownload}
            data-handle-download="handle-download"
          >
            <DownloadOutlined style={{ fontSize: 18 }} />
          </CustomButton>
        )}
      </div>
    </div>
  )
}

export default memo(SecondaryPanel)
