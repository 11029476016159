import { useEffect } from 'react'

import { useStore } from 'stores'

const useDefaultAvatarIdFetch = () => {
  const { programStore, userStore } = useStore()

  useEffect(() => {
    if (userStore.isAdmin) return

    programStore.setDefaultProgramAvatarId()
  }, [userStore.isAdmin])
}

export default useDefaultAvatarIdFetch
