import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, UserDeleteOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import Title from 'antd/lib/typography/Title'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import programsApi from 'api/programs.api'

import useDeleteProgramMutation from 'hooks/tanstack/mutations/useDeleteProgramMutation'
import useProgramQuery from 'hooks/tanstack/queries/useProgramQuery'
import useTemplateQuery from 'hooks/tanstack/queries/useTemplateQuery'

import { useStore } from 'stores'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import customConfirm from 'utils/customConfirm'
import message, { someError } from 'utils/message'

import ProgramTabbar from './ProgramTabbar'

import BackLink from 'components/BackLink'
import CopyProgramModal from 'components/CopyProgramModal'
import CreateProgramBtn from 'components/CreateProgramBtn'
import CustomMenu from 'components/CustomComponents/CustomMenu'
import Loader from 'components/Loader'
import MenuItem from 'components/MenuItem'
import PageContainer from 'components/PageContainer'
import ProgramInfo from 'components/ProgramInfo'
import InfoEditModal from 'components/ProgramInfo/InfoEditModal'

import styles from './styles.module.scss'

import { ReactComponent as CopyIcon } from 'sources/images/copy.svg'

const ProgramDetail = () => {
  const [isInfoEditModalVisible, setIsInfoEditModalVisible] = useState(false)
  const [isCopyProgramModalVisible, setIsCopyProgramModalVisible] = useState(false)

  const { id } = useParams<{ id: string }>()

  const { state: navigationState } = useLocation()

  const queryClient = useQueryClient()

  const fromList = !!navigationState?.forceRedirect

  const { userStore } = useStore()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { program, isLoading: programLoading, isError: programError } = useProgramQuery(id)
  const {
    template,
    isLoading: templateLoading,
    isError: templateError,
  } = useTemplateQuery(program?.templateId || null, program?.id)

  const deleteProgramMutation = useDeleteProgramMutation({
    onSuccess: () => {
      queryClient.removeQueries([QueryKeysEnum.PROGRAMS])
      navigate('/programs')
    },
  })

  const leaveProgramConfirm = () => {
    customConfirm({
      title: t('programList.leaveProgramConfirm'),
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        try {
          if (!program) return

          await programsApi.leaveProgram(program.id)
          navigate('/programs')
          message.success(t('programList.leavedProgram'))
          queryClient.invalidateQueries([QueryKeysEnum.PROGRAMS])
        } catch (e) {
          if (e?.response?.data?.Message?.includes("You can't leave being the only mentor")) {
            message.error(t('companyPage.lastMentorLeaveError'))
          } else {
            console.error(e)
          }
        }
      },
      okText: t('programCreation.modalOK'),
      cancelText: t('programCreation.modalCancel'),
      className: styles.modalRtlCorrecting,
    })
  }

  const menu = useMemo(() => {
    if (!program?.id) return <></>

    const items = [
      {
        key: '1',
        label: <MenuItem label={t('programDetails.edit')} Icon={() => <EditOutlined width={15} />} />,
        onClick: () => setIsInfoEditModalVisible(true),
      },
      {
        key: '2',
        label: <MenuItem label={t('programDetails.copyId')} Icon={() => <CopyIcon width={18} />} />,
        onClick: async (e: any) => {
          e.domEvent.stopPropagation()
          await navigator.clipboard.writeText(program.id.toString())
          message.success(t('templateList.copiedId'))
        },
      },
      {
        key: '3',
        label: <MenuItem label={t('programDetails.copy')} Icon={() => <CopyIcon width={18} />} />,
        onClick: async (e: any) => {
          e.domEvent.stopPropagation()
          setIsCopyProgramModalVisible(true)
        },
      },
      {
        key: '4',
        label: <MenuItem label={t('programList.leaveProgram')} Icon={() => <UserDeleteOutlined width={18} />} />,
        onClick: (e: any) => {
          e.domEvent.stopPropagation()
          leaveProgramConfirm()
        },
      },
      {
        key: '5',
        label: <MenuItem label={t('programDetails.delete')} Icon={() => <DeleteOutlined width={18} />} />,
        onClick: () =>
          customConfirm({
            title: t('programList.deleteProgramTitle'),
            icon: <ExclamationCircleOutlined />,
            async onOk() {
              await deleteProgramMutation(program.id)

              navigate('/programs')
            },
            okText: t('programCreation.modalOK'),
            cancelText: t('programCreation.modalCancel'),
            className: styles.modalRtlCorrecting,
          }),
      },
    ]

    return <CustomMenu items={items} />
  }, [userStore.language, program?.id])

  const error = programError
  const loading = programLoading || templateLoading

  useEffect(() => {
    if (error) {
      someError()
      navigate('/programs')
    }
  }, [error])

  return (
    <PageContainer>
      {loading ? (
        <div className={styles.loaderContainer} data-loader-container="loaderContainer">
          <Loader />
        </div>
      ) : error || !program ? (
        <div>{t('programCreation.smtWrong')}</div>
      ) : (
        <>
          <div className={cn(styles.container, 'container')} data-container-card="container-card">
            {!fromList && (
              <div style={{ marginLeft: -5, marginRight: -5 }}>
                <BackLink backRoute="/programs" />
              </div>
            )}

            <div className={styles.block} data-block-1="block-1">
              <div className={styles.blockWrapper}>
                <Title className={styles.title} level={3} title-5="title-5">
                  {program.name}
                </Title>
                {fromList && <CreateProgramBtn isLoading={loading} />}
              </div>
            </div>

            <div className={styles.programInfo}>
              <ProgramInfo
                program={program}
                subtitle={program.description}
                title={program.goal ? `${t('programList.goal')}: ${program.goal}` : ''}
                menu={menu}
                isBigSubtitle
                isFromProgramDetail
              />
            </div>

            <ProgramTabbar
              programId={String(program.id)}
              template={template || null}
              currentDayCompleted={
                program.currentDay > program.daysAmount ? program.daysAmount - 1 : program.currentDay - 1
              }
            />
          </div>
          <InfoEditModal
            program={program}
            open={isInfoEditModalVisible}
            onCancel={() => setIsInfoEditModalVisible(false)}
            afterUpdate={() => setIsInfoEditModalVisible(false)}
          />
          <CopyProgramModal
            selectedProgram={program}
            isVisible={isCopyProgramModalVisible}
            setIsVisible={setIsCopyProgramModalVisible}
            fromList={fromList}
          />
        </>
      )}
    </PageContainer>
  )
}

export default observer(ProgramDetail)
