import { useEffect, useState } from 'react'

const useTrackScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = (e: Event) => {
    //@ts-ignore
    setScrollPosition(e.target?.scrollTop || 0)
  }

  useEffect(() => {
    const root = document.getElementById('root')

    if (!root) return

    root.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      root.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return { scrollPosition }
}

export default useTrackScrollPosition
