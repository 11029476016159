import React, { FC } from 'react'
import classNames from 'classnames'

import useAvatar from 'hooks/useAvatar'

import { ITrainees } from 'types/User'

import styles from './styles.module.scss'

interface IProps {
  option: ITrainees
}

const TraineeSelectOption: FC<IProps> = ({ option }) => {
  const { avatarUrl } = useAvatar({ avatarId: option.avatarId })

  return (
    <div className={classNames('demo-option-label-item', styles.option)} style={{ gap: 6 }}>
      <img src={avatarUrl} className={styles.optionAvatar} />
      <div>
        <div>{`${option.firstName} ${option.lastName}`}</div>
        <div>{option.phoneNumber}</div>
      </div>
    </div>
  )
}

export default TraineeSelectOption
