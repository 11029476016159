import { Typography } from 'antd'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import useUnboundStore from 'hooks/useUnboundStore'

import { useStore } from 'stores'

import {
  IAudioDayItem,
  IFileDayItem,
  IImageDayItem,
  ILinkDayItem,
  IQuestionnaireDayItem,
  ISummaryDayItem,
  IVideoDayItem,
  planingToolTypesEnum,
} from 'types/Template'

import styles from './styles.module.scss'

import { ReactComponent as AudioIcon } from 'sources/images/AudioIcon.svg'
import { ReactComponent as FileIcon } from 'sources/images/FileIcon.svg'
import { ReactComponent as ImageIcon } from 'sources/images/ImageIcon.svg'
import { ReactComponent as LinkIcon } from 'sources/images/LinkIcon.svg'
import { ReactComponent as QuestionIcon } from 'sources/images/QuestionIcon.svg'
import { ReactComponent as TextIcon } from 'sources/images/TextIcon.svg'
import { ReactComponent as VideoIcon } from 'sources/images/VideoIcon.svg'
import { IScenarioDayItem } from 'types/Template/Tools'
import { ShareAltOutlined } from '@ant-design/icons'

const defaultToolsToSelect = [
  planingToolTypesEnum.IMAGE,
  planingToolTypesEnum.VIDEO,
  planingToolTypesEnum.SUMMARY,
  planingToolTypesEnum.QUESTIONNAIRE,
  planingToolTypesEnum.FILE,
  planingToolTypesEnum.AUDIO,
  planingToolTypesEnum.LINK,
  planingToolTypesEnum.SCENARIO,
]

const toolIcon = {
  [planingToolTypesEnum.IMAGE]: <ImageIcon />,
  [planingToolTypesEnum.VIDEO]: <VideoIcon />,
  [planingToolTypesEnum.SUMMARY]: <TextIcon />,
  [planingToolTypesEnum.QUESTIONNAIRE]: <QuestionIcon />,
  [planingToolTypesEnum.FILE]: <FileIcon />,
  [planingToolTypesEnum.AUDIO]: <AudioIcon />,
  [planingToolTypesEnum.LINK]: <LinkIcon />,
  [planingToolTypesEnum.SCENARIO]: <ShareAltOutlined style={{ color: 'rgb(22, 73, 151)' }} />,
}

const getDefaultDropTypeContent = (type: planingToolTypesEnum) => {
  const repeatId = v4()
  const uniqueId = v4()

  const tools = {
    [planingToolTypesEnum.IMAGE]: {
      id: -1,
      type: planingToolTypesEnum.IMAGE,
      fileList: [],
      revealedTime: '',
      uniqueId: [uniqueId],
      entityIds: [],
      fileIds: [],
      position: 0,
      entityTags: [],
      isAllDaySelected: true,
      repeatId,
      daysSheduleValue: [],
    } as IImageDayItem,
    [planingToolTypesEnum.VIDEO]: {
      id: -1,
      type: planingToolTypesEnum.VIDEO,
      fileList: [],
      modifiedVideoLink: '',
      uniqueId: [uniqueId],
      entityIds: [],
      fileIds: [],
      videoLinks: [],
      videoLinksEntityIds: [],
      position: 0,
      entityTags: [],
      isAllDaySelected: true,
      revealedTime: '',
      videoLinksUIDs: [],
      repeatId,
      daysSheduleValue: [],
    } as IVideoDayItem,
    [planingToolTypesEnum.QUESTIONNAIRE]: {
      id: -1,
      type: planingToolTypesEnum.QUESTIONNAIRE,
      questionsData: [{ type: null, question: '' }],
      uniqueId: [uniqueId],
      position: 0,
      entityTags: [],
      closeTime: '',
      entityIds: [],
      isAllDaySelected: true,
      isGraphNeeded: false,
      openTime: '',
      questionMessageIds: [],
      revealedTime: '',
      repeatId,
      daysSheduleValue: [],
    } as IQuestionnaireDayItem,
    [planingToolTypesEnum.SUMMARY]: {
      id: -1,
      type: planingToolTypesEnum.SUMMARY,
      summary: '',
      uniqueId,
      position: 0,
      entityTags: [],
      isAllDaySelected: true,
      questionValues: [],
      revealedTime: '',
      entityIds: [],
      repeatId,
      eventDay: null,
      daysSheduleValue: [],
      isPublished: false,
    } as ISummaryDayItem,
    [planingToolTypesEnum.FILE]: {
      id: -1,
      type: planingToolTypesEnum.FILE,
      fileList: [],
      revealedTime: '',
      uniqueId: [uniqueId],
      entityIds: [],
      fileIds: [],
      position: 0,
      entityTags: [],
      isAllDaySelected: true,
      repeatId,
      daysSheduleValue: [],
    } as IFileDayItem,
    [planingToolTypesEnum.AUDIO]: {
      id: -1,
      type: planingToolTypesEnum.AUDIO,
      fileList: [],
      revealedTime: '',
      uniqueId: [uniqueId],
      entityIds: [],
      fileIds: [],
      position: 0,
      entityTags: [],
      isAllDaySelected: true,
      repeatId,
      daysSheduleValue: [],
    } as IAudioDayItem,
    [planingToolTypesEnum.LINK]: {
      id: -1,
      type: planingToolTypesEnum.LINK,
      linksValues: [''] as any,
      uniqueId,
      position: 0,
      entityTags: [],
      isAllDaySelected: true,
      entityIds: [],
      revealedTime: '',
      repeatId,
      daysSheduleValue: [],
    } as ILinkDayItem,
    [planingToolTypesEnum.SCENARIO]: {
      id: -1,
      type: planingToolTypesEnum.SCENARIO,
      name: '',
      uniqueId,
      position: 0,
      entityTags: [],
      isAllDaySelected: true,
      entityIds: [],
      revealedTime: '',
      repeatId,
      daysSheduleValue: [],
      isPublished: false,
    } as IScenarioDayItem,
  }

  return tools[type]
}

const { Title } = Typography

interface IProps {
  title: string
  isNewsMode?: boolean
  isUnbound?: boolean
}

const NewContentPanel: FC<IProps> = ({ title, isNewsMode, isUnbound }) => {
  const { t } = useTranslation()

  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const { unboundStore } = useUnboundStore()

  const toolsToSelect = isNewsMode ? [planingToolTypesEnum.SUMMARY] : defaultToolsToSelect

  const onClick = (type: planingToolTypesEnum) => {
    const tool = getDefaultDropTypeContent(type)

    if (isUnbound) {
      tool.position = 0

      unboundStore.addUnboundTool(tool)

      return
    }

    if (!templateEntity.currentDay) throw new Error('No current day')

    tool.position = templateEntity.lastPosition + 1

    templateEntity.addTool(tool)
  }

  const contentNumber = templateEntity.currentDay?.dayItems?.length ? templateEntity.currentDay.dayItems.length + 1 : 1

  return (
    <div className={styles.contentSelector}>
      {!isUnbound && !isNewsMode && (
        <Title level={5}>
          {t<string>('templateCreation.content')} {contentNumber}
        </Title>
      )}
      <div className={styles.contentSelectText}>{title}</div>
      <div className={styles.toolSelectOptions}>
        {toolsToSelect.map(toolType => (
          <div
            className={styles.toolSelectOption}
            onClick={() => onClick(toolType)}
            data-template-tool={`data-template-tool-${toolType}`}
            id={`tool-${toolType}`}
            key={toolType}
          >
            {toolIcon[toolType]} {t(`templateCreation.${toolType}`)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default observer(NewContentPanel)
