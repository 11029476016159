import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import userApi from 'api/user.api'

import message from 'utils/message'

interface IProps {
  currentSelectedProgramId: string
}

const useDashboardTraineesCountQuery = ({ currentSelectedProgramId }: IProps) => {
  const { t } = useTranslation()

  const { data: traineesCount, isLoading } = useQuery({
    queryKey: ['dashboard', 'trainees-count', currentSelectedProgramId],
    queryFn: async () => {
      const searchedTrainees = await userApi.getLeastPerformersTrainees(currentSelectedProgramId, '')

      return searchedTrainees.length
    },
    onError: () => {
      message.error(t<string>('programCreation.smtWrong'))
    },
    initialData: 0,
  })

  return {
    traineesCount,
    isLoading,
  }
}

export default useDashboardTraineesCountQuery
