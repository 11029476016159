import { Checkbox, Form, FormInstance, Input, Space, Switch, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useProgramValidate from 'hooks/programCreation/useProgramValidate'
import useMounted from 'hooks/useMounted'

import { useStore } from 'stores'

import { PostsSortingOptionsEnum, ProgramForm, ProgramImage } from 'types/Program'

import message from 'utils/message'

import LogoUpload from './LogoUpload'

import CustomFormItem from 'components/CustomComponents/CustomFormItem'
import LinkToBrandSelector from 'components/LinkToBrandSelector'
import PostsSortingOptionSelector from 'components/PostsSortingOptionSelector'

import styles from './styles.module.scss'

interface IProps {
  form: FormInstance<ProgramForm>
  logoImage: ProgramImage
  setLogoImage: (image: ProgramImage) => void
  handleSubmit: (data: any) => void
  handleChange: () => void
  programId?: number
  loading?: boolean
  sortingOption: PostsSortingOptionsEnum
  setSortingOption: Dispatch<SetStateAction<PostsSortingOptionsEnum>>
  isCreation?: boolean
}

const { Text } = Typography

const ProgramDetailsForm: React.FC<IProps> = ({
  form,
  programId,
  logoImage,
  setLogoImage,
  handleSubmit,
  handleChange,
  loading,
  sortingOption,
  setSortingOption,
  isCreation,
}) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const { mounted } = useMounted()

  useEffect(() => {
    if (!mounted) return

    const values = form.getFieldsValue()
    form.resetFields()
    form.setFieldsValue(values)
    message.destroy()
  }, [userStore.language])

  const { goalValidator, publicIdValidator } = useProgramValidate({ programId })

  return (
    <div className={styles.datails} data-details="details">
      <Form
        name="program"
        initialValues={{ remember: true }}
        layout="vertical"
        form={form}
        onValuesChange={handleChange}
        onFinish={handleSubmit}
        data-form-program="form-program"
        requiredMark={false}
      >
        <div className={styles.inline}>
          <LogoUpload
            title={t('programCreation.programLogoImage')}
            image={logoImage}
            loading={loading}
            setImage={setLogoImage}
            data-program-creation-logo="program-creation-logo"
          />
          <div className={styles.double} data-double="double">
            <CustomFormItem
              label={t('templateCreation.name')}
              name="name"
              // rules={[{ validator: basicStringFieldValidator({ required: true }) }]}
              className={styles.formItem}
              data-program-creation-name="program-creation-name"
              id="program-creation-name"
            >
              <Input data-form-name-input="form-name-input" autoComplete="off" className={styles.formInput} />
            </CustomFormItem>

            <CustomFormItem
              label={t('programList.goal')}
              name="goal"
              rules={[{ validator: goalValidator }]}
              className={styles.formItem}
              data-program-creation-goal="program-creation-goal"
              id="program-creation-goal"
            >
              <Input data-form-goal-input="form-goal-input" autoComplete="off" className={styles.formInput} />
            </CustomFormItem>
          </div>
        </div>

        <div>
          <CustomFormItem
            label={t('programCreation.description')}
            name="description"
            data-program-creation-description="program-creation-description"
            id="program-creation-description"
          >
            <TextArea rows={4} className={styles.textArea} autoComplete="off" />
          </CustomFormItem>
        </div>

        {/* {userStore.isEventTypeApp && ( */}
        <div>
          <PostsSortingOptionSelector sortingOption={sortingOption} setSortingOption={setSortingOption} />
        </div>
        {/* )} */}

        {!isCreation && !!userStore.userBrands.length && userStore.userBrands.length !== 1 && (
          <div>
            <CustomFormItem
              label={t('programCreation.linkToBrand')}
              name="companyId"
              data-program-creation-description="program-creation-description"
              id="program-creation-description"
            >
              <LinkToBrandSelector />
            </CustomFormItem>
          </div>
        )}

        <div className={styles.uniqueId} data-unique-id="uniqueId">
          <Space data-space-2="space-2">
            <Form.Item
              name="isPublic"
              valuePropName="checked"
              style={{ margin: 0 }}
              data-program-creation-is-public="program-creation-is-public"
              id="program-creation-is-public"
            >
              <Switch
                onChange={checked => (!checked ? form.setFields([{ name: 'uniqueId', errors: [] }]) : null)}
                data-switch="switch"
                defaultChecked={userStore.isForAnonSubdomain}
              />
            </Form.Item>
            <Text data-public="public">{t('programCreation.public')}</Text>
          </Space>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.isPublic !== currentValues.isPublic}
            className={styles.formItem}
            data-is-public="is-public"
          >
            {({ getFieldValue }) =>
              getFieldValue('isPublic') &&
              !userStore.isForAnonSubdomain &&
              !userStore.hasPublicProgramAccess && (
                <CustomFormItem
                  label={t('programCreation.publicIdentifier')}
                  name="uniqueId"
                  data-unique-id="unique-id"
                  hasFeedback={!!getFieldValue('isPublic')}
                  data-program-creation-public-id="program-creation-public-id"
                  id="program-creation-public-id"
                  style={{ marginTop: 20 }}
                  rules={getFieldValue('isPublic') ? [{ validator: publicIdValidator }] : [{ required: false }]}
                  className={styles.formItem}
                >
                  <Input
                    disabled={!getFieldValue('isPublic')}
                    data-input-is-public="input-is-public"
                    className={styles.publicId}
                    autoComplete="off"
                  />
                </CustomFormItem>
              )
            }
          </Form.Item>

          {!isCreation && (
            <CustomFormItem
              name="isVisible"
              valuePropName="checked"
              style={{ margin: 0, marginTop: 20 }}
              data-program-creation-is-public="program-creation-is-public"
              id="program-creation-is-public"
            >
              <Checkbox
                onChange={checked => (!checked ? form.setFields([{ name: 'isVisible', errors: [] }]) : null)}
                className="checkboxUI"
              >
                {t('programCreation.programVisible')}
              </Checkbox>
            </CustomFormItem>
          )}
        </div>
      </Form>
    </div>
  )
}

export default ProgramDetailsForm
