import { useMemo } from 'react'

import { useStore } from 'stores'

const useGetProgramCreationProgress = (programId: number | string) => {
  const { programStore } = useStore()

  const progress = useMemo(() => {
    const template = programStore.programsCreationProgress.find(item => item.programId === +programId)

    if (template) {
      return template.creationProgress
    }

    return -1
  }, [programStore.programsCreationProgress, programId])

  return progress
}

export default useGetProgramCreationProgress
