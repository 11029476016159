import { Dropdown, DropdownProps } from 'antd'
import React from 'react'

import styles from './styles.module.scss'

import { ReactComponent as BurgerIcon } from 'sources/images/burger.svg'

interface IProps extends DropdownProps {
  size?: number
}

const CustomDropdown: React.FC<IProps> = ({ size = 18, ...rest }) => {
  return (
    <Dropdown {...rest} overlayClassName={styles.customDropdown}>
      <div className={styles.iconContainer}>
        <BurgerIcon style={{ color: '#80838C' }} width={size} height={size} onClick={e => e.stopPropagation()} />
      </div>
    </Dropdown>
  )
}

export default CustomDropdown
