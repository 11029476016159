import { FC, memo, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import useComments from 'hooks/useComments'

import { IDailyReportAnswer } from 'types/Program'

import Comment from './Comment'
import Controls from './Controls'

import CustomModal from 'components/CustomComponents/CustomModal'

import styles from './styles.module.scss'

import { ReactComponent as EmptyPic } from 'sources/images/Empty.svg'

interface IProps {
  commentsData: IDailyReportAnswer | null
  visible: boolean
  onClose: () => void
  userId: number
}

let prevCommentsLength = 0

const CommentsModal: FC<IProps> = ({ commentsData, onClose, visible, userId }) => {
  const { t } = useTranslation()

  const { comments, addOptimisticComment, deleteOptimisticComment } = useComments({ commentsData })

  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollRef.current && visible) {
      if (comments.length && comments.length > prevCommentsLength) {
        scrollRef.current?.scrollTo({
          top: scrollRef.current?.scrollHeight,
          behavior: 'smooth',
        })
      }

      prevCommentsLength = comments.length || 0
    }
  }, [comments, visible])

  if (!commentsData) return <></>

  return (
    <CustomModal
      open={!!visible}
      onCancel={onClose}
      footer={null}
      className={styles.modal}
      title={<h2 className={styles.title}>{t<string>('dailyReport.comments')}</h2>}
      bodyStyle={{ padding: 0 }}
    >
      <div ref={scrollRef} className={styles.scroll}>
        <div className={styles.top}>
          <h3 className={styles.question}>{commentsData.question}</h3>
          <div className={styles.commentsNum}>
            {comments.length} {t<string>('dailyReport.comments')}
          </div>
        </div>
        <div className={styles.body}>
          {!comments.length ? (
            <div className={styles.emptyBody}>
              <EmptyPic />
              <div>{t<string>('dailyReport.noComments')}</div>
            </div>
          ) : (
            comments.map(comment => (
              <Comment
                key={comment.id}
                comment={comment.comment}
                avatarId={comment.user.avatarId}
                date={comment.createdAt}
                username={`${comment.user.firstName} ${comment.user.lastName}`}
                userId={comment.user.id}
                commentId={comment.id}
                deleteOptimisticComment={deleteOptimisticComment}
                temporary={comment.temporary}
              />
            ))
          )}
        </div>
      </div>
      <Controls questionId={commentsData.questionId} traineeId={userId} addOptimisticComment={addOptimisticComment} />
    </CustomModal>
  )
}

export default memo(CommentsModal)
