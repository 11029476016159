import { Button, DatePicker, Modal } from 'antd'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { useStore } from 'stores'

import message from 'utils/message'

import styles from './styles.module.scss'

type WallPostPropsType = {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

const WallPostModal: FC<WallPostPropsType> = ({ visible, setVisible }) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const [date, setDate] = useState<moment.Moment | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (templateEntity.currentDay?.dayDate) {
      setDate(moment(templateEntity.currentDay.dayDate))
    }
  }, [templateEntity.currentDay?.dayDate])

  const onSaveDate = async () => {
    try {
      if (!date) return

      setLoading(true)

      const newDate = date.format('YYYY-MM-DD') + 'T00:00:00'

      await templateStore.changeWallPostDayDate(newDate)

      setVisible(false)
    } catch (e) {
      message.error(t('programCreation.smtWrong'))
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const disabledDate = (current: moment.Moment) => {
    if (current && templateEntity.currentDay?.dayDate) {
      return (
        current <
          moment()
            .set('date', moment().get('date') - 1)
            .endOf('day') || current < moment(templateEntity.currentDay.dayDate).endOf('day')
      )
    }
    return false
  }

  if (!templateEntity.currentDay || !date) return <></>

  return (
    <Modal
      open={visible}
      title={t<string>('templateCreation.editDayModalContent')}
      onCancel={() => setVisible(false)}
      footer={null}
      data-modal-edit-day="modal-edit-day"
    >
      <div className={styles.dayEditModalContent} data-day-modal-content="day-modal-content">
        <DatePicker
          disabledDate={disabledDate}
          value={date}
          onChange={setDate}
          data-date-picker="date-picker"
          format={'DD/MM/YYYY'}
          className={styles.datePicker}
        />
        <Button onClick={onSaveDate} type="primary" data-select="select" loading={loading} className={styles.btn}>
          {t<string>('templateCreation.select')}
        </Button>
      </div>
    </Modal>
  )
}

export default observer(WallPostModal)
