import { useEffect } from 'react'

import { useStore } from 'stores'

const useSyncUser = () => {
  const { userStore } = useStore()

  useEffect(() => {
    if (userStore.isAdmin) return

    const handleWindowFocus = () => {
      if (!document.hidden) {
        userStore.setUserDataAPI()
      }
    }

    document.addEventListener('visibilitychange', handleWindowFocus)

    return () => {
      document.removeEventListener('visibilitychange', handleWindowFocus)
    }
  }, [userStore.isAdmin])
}

export default useSyncUser
