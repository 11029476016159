import { IGoal } from 'types/Goal'
import { IPagination } from 'types/common'

import { IProgramFilter } from 'types/Program'
import { FollowUpPostType } from './FollowUp'
import { planningToolsType } from './Tools'

export type {
  ITemplateFileAPI,
  ITemplateLinkAPI,
  ITemplateQuestionnaireAPI,
  ITemplateSummaryAPI,
} from './TemplateToServer'

export type {
  IExtendedFile,
  IFileLoadProgress,
  IFileUploadingProgress,
  IFilesInToolsInfo,
  IUploadedFile,
} from './Files'
export type { IAvailableFollowUp } from './FollowUp'
export type { IDayItemProps, IDefaultToolProps } from './Props'
export type {
  IMCQAnswerOption,
  IQuestionnaireCheckBox,
  IQuestionnaireCommonState,
  IQuestionnaireMultipleChoice,
  IQuestionnaireNumeric,
  IQuestionnaireRating,
  QuestionnaireState,
} from './Questionnaires'
export type { IEntityTag, ITagWithToolRelation } from './Tags'
export type {
  IAudioDayItem,
  IDeleteIds,
  IFileDayItem,
  IImageDayItem,
  ILinkDayItem,
  IPlanningToolFileType,
  IQuestionnaireDayItem,
  ISummaryDayItem,
  IVideoDayItem,
  planningToolsType,
} from './Tools'

export { FollowUpActionsEnum, FollowUpPostType } from './FollowUp'
export { QuestionTypeEnum } from './Questionnaires'
export { deleteEntityTypeEnum, planingToolTypesEnum, schedulerOptionEnum, schedulerSelectOptionEnum } from './Tools'

export interface ILightTemplatesWithPagination extends IPagination {
  items: ITemplate[]
}

export interface ITemplate {
  id: number
  name: string
  duration: number
  createdAt: string
  filter: IProgramFilter | null
}

export interface ITemplateInfo {
  templateData: IDayData[]
  name: string
  duration: number
  createdAt: string
  id: number
  templateProgramGoals: IGoal[]
}

export interface IDayData {
  dayNumber: number
  dayDate: string
  dayItems: planningToolsType[]
  id?: number
}

export interface ITemplateOption {
  label: string
  value: number
  createdAt: string
  duration: number
}

export interface ITemplateRedirectionInfo {
  backTo: 'program-creation/step2'
  isCreatingNewTemplate: boolean
}

export interface IProgramContent {
  postId: number
  postType: FollowUpPostType
  title: string | null
}

export enum WallMode {
  DEFAULT = 'default',
  NEWS = 'news',
}

export interface IProgramDays {
  date: string
  hasDate: boolean
}
