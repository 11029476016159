import { useEffect, useState } from 'react'

import { schedulerOptionEnum } from 'types/Template'
import { DayOfWeek } from 'types/Template/Tools'

interface IProps {
  initialSchedule?: schedulerOptionEnum
  initialDaysSchedule?: DayOfWeek[]
}

const useToolSchedule = ({ initialSchedule, initialDaysSchedule }: IProps) => {
  const [schedulerValue, setSchedulerValue] = useState<schedulerOptionEnum>(schedulerOptionEnum.doNotRepeat)
  const [daysSheduleValue, setDaysScheduleValue] = useState<DayOfWeek[]>([])

  useEffect(() => {
    if (initialSchedule) {
      setSchedulerValue(initialSchedule)
    }
  }, [initialSchedule])

  useEffect(() => {
    if (initialDaysSchedule) {
      setDaysScheduleValue(initialDaysSchedule)
    }
  }, [initialDaysSchedule])

  return { schedulerValue, setSchedulerValue, daysSheduleValue, setDaysScheduleValue }
}

export default useToolSchedule
