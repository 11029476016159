import { Checkbox } from 'antd'
import { FC, memo } from 'react'

import { IReportUserLocal } from 'types/Report'

import UserAvatar from 'components/UserAvatar'

import styles from './styles.module.scss'

interface IProps {
  selected: boolean
  selectOneChange: (trainee: IReportUserLocal) => void
  user: IReportUserLocal
}

const User: FC<IProps> = ({ selected, selectOneChange, user }) => {
  return (
    <div className={styles.trainee} onClick={() => selectOneChange(user)}>
      <Checkbox className="checkboxUI" checked={selected} />
      <div>
        <UserAvatar avatarId={user.avatarId} />
      </div>
      <div className={styles.traineeName}>
        {!user?.firstName && !user?.lastName ? '-' : `${user?.firstName} ${user?.lastName}`}
      </div>
    </div>
  )
}

export default memo(User)
