import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import templatesApi from 'api/templates.api'

import useDebounce from 'hooks/useDebounce'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

const TEMPLATE_OPTIONS_ON_PAGE = 19

const useTemplateOptionsQuery = () => {
  const [searchValue, setSearchValue] = useState('')

  const debouncedValue = useDebounce(searchValue, 500)

  const [pageNumber, setPageNumber] = useState(1)

  const { data, isLoading } = useQuery({
    keepPreviousData: true,
    queryKey: [QueryKeysEnum.TEMPLATE_OPTIONS, debouncedValue, pageNumber],
    queryFn: async () => {
      const response = await templatesApi.getLightTemplates(debouncedValue, pageNumber, TEMPLATE_OPTIONS_ON_PAGE)

      if (!response) return null

      const { items, ...paginationData } = response

      setPageNumber(paginationData.pageNumber)

      return {
        paginationData,
        options: items.map(template => ({
          value: template.id,
          label: template.name,
          createdAt: template.createdAt,
          duration: template.duration,
        })),
      }
    },
  })

  return {
    options: data?.options || [],
    isLoading,
    searchValue,
    setSearchValue,
    setPageNumber,
    pagination: data?.paginationData,
  }
}

export default useTemplateOptionsQuery
