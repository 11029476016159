import { v4 } from 'uuid'

import {
  IDayData,
  IFileDayItem,
  IImageDayItem,
  ILinkDayItem,
  IMCQAnswerOption,
  IQuestionnaireDayItem,
  ISummaryDayItem,
  ITemplateQuestionnaireAPI,
  IVideoDayItem,
  QuestionTypeEnum,
  planingToolTypesEnum,
} from 'types/Template'

import { audioMimeTypes, imageMimeTypes, videoMimeTypes } from 'utils/const'

import moment from 'moment'
import { ITemplateDayAPI, IWallPostDayAPI } from 'types/Template/TemplateToServer'
import { QuestionnaireFollowUpActionsParser } from './index'
import { IScenarioDayItem } from 'types/Template/Tools'

class ParseTemplateFromServer {
  private templateData: any
  private isWallPosts?: boolean
  private isNews?: boolean

  private questionnaireFollowUpActionsParser = new QuestionnaireFollowUpActionsParser()

  private fields = {
    questionnaires: '',
    files: '',
    summary: '',
    scenarios: '',
    links: '',
    answerOptions: '',
  }

  constructor() {}

  public setTemplateData(data: ITemplateDayAPI[]) {
    this.templateData = data
    this.isWallPosts = false
    this.isNews = false
    this.constructFields()
    return this
  }

  public setWallPostsData(data: IWallPostDayAPI[], isNews?: boolean) {
    this.templateData = data
    this.isWallPosts = true
    this.isNews = isNews
    this.constructFields()
    return this
  }

  private groupByPosition(template: any, objEntityName: string) {
    const mappedPosition = template[objEntityName]?.map((item: any) => item?.position) as number[]

    if (!mappedPosition) return []

    const filledArr: any[] = []

    mappedPosition.forEach(pos => {
      const tempArr = []
      for (let j = 0; j < template[objEntityName].length; j++) {
        const item = template[objEntityName][j]
        if (item.position === pos) tempArr.push(item)
      }
      tempArr.length && filledArr.push(tempArr)
    })

    return filledArr
  }

  private constructFields() {
    this.fields = {
      questionnaires: this.isWallPosts ? 'questionnaires' : 'questionnaireTemplates',
      files: this.isWallPosts ? 'programWallPostStoredFiles' : 'templateEntityFiles',
      summary: this.isWallPosts ? 'programWallPostSummaries' : 'templateEntitySummaries',
      scenarios: this.isWallPosts ? 'scenarios' : 'scenarios',
      links: this.isWallPosts ? 'programWallPostLinks' : 'templateEntityLinks',
      answerOptions: this.isWallPosts ? 'answerOptions' : 'templateAnswerOptions',
    }
  }

  private mapQuestionnaires(template: any) {
    const filledQuestionnaireArr = this.groupByPosition(template, this.fields.questionnaires) as any[][]

    return filledQuestionnaireArr.flatMap(questionnaires => {
      if (!questionnaires.some(questionnaire => questionnaire.questions.length)) {
        return []
      }

      const currentChunk = questionnaires.sort((current, next) => current.toolId - next.toolId)

      const mainQuestionnaire = currentChunk[0] as ITemplateQuestionnaireAPI
      const jsonForWeb = JSON.parse(mainQuestionnaire.jsonForWeb)

      const fromDay = jsonForWeb?.fromDay ?? undefined
      const schedulerOption = jsonForWeb?.schedulerOption ?? 1
      const daysSheduleValue = jsonForWeb?.daysOfWeek ?? []
      const isAllDaySelected =
        mainQuestionnaire.openQuestionnaire.includes('T00:00') &&
        mainQuestionnaire.closeQuestionnaire.includes('T23:59')

      const questionMessageIds = currentChunk.map(item => item.questions?.[0]?.id) || []

      return [
        {
          type: planingToolTypesEnum.QUESTIONNAIRE,
          id: mainQuestionnaire.id,
          position: mainQuestionnaire.position,
          questionsData: currentChunk.map(questionnaireData => {
            const firstQuestion = questionnaireData.questions[0]
            switch (questionnaireData.typeQuestionnaire) {
              case QuestionTypeEnum.NUMERIC:
                return {
                  type: QuestionTypeEnum.NUMERIC,
                  question: firstQuestion?.questionMessage,
                  min: questionnaireData.minAnswerRange + '',
                  max: questionnaireData.maxAnswerRange + '',
                }
              case QuestionTypeEnum.TEXT:
                return {
                  type: QuestionTypeEnum.TEXT,
                  question: firstQuestion?.questionMessage,
                }

              case QuestionTypeEnum.RATING:
                return {
                  type: QuestionTypeEnum.RATING,
                  question: questionnaireData.questions.map((item: any) => item?.questionMessage),
                }
              case QuestionTypeEnum.MULTIPLE_CHOICE:
                const { followUpAction, followUpActionField } =
                  this.questionnaireFollowUpActionsParser.fromServerToClient(firstQuestion[this.fields.answerOptions])

                return {
                  type: QuestionTypeEnum.MULTIPLE_CHOICE,
                  question: firstQuestion?.questionMessage,
                  options: firstQuestion[this.fields.answerOptions].map(
                    (answer: IMCQAnswerOption) => answer.answerText
                  ),
                  answerIds: firstQuestion[this.fields.answerOptions].map((answer: IMCQAnswerOption) => answer.id),
                  followUpAction,
                  followUpActionField,
                }
              case QuestionTypeEnum.CHECK_BOX:
                return {
                  type: QuestionTypeEnum.CHECK_BOX,
                  question: firstQuestion?.questionMessage,
                  options: firstQuestion[this.fields.answerOptions].map((answer: any) => answer.answerText),
                  answerIds: firstQuestion[this.fields.answerOptions].map((answer: any) => answer.id),
                }
            }
            return questionnaireData
          }),
          openTime: mainQuestionnaire.openQuestionnaire || '',
          closeTime: mainQuestionnaire.closeQuestionnaire || '',
          isAllDaySelected,
          isGraphNeeded: mainQuestionnaire.isGraphNeeded,
          questionMessageIds,
          uniqueId: currentChunk.map(chunk => JSON.parse(chunk.jsonForWeb).uniqueId),
          entityIds: currentChunk.map(questionnaireData => questionnaireData.id),
          fromDay,
          schedulerOption,
          entityTags: mainQuestionnaire.entityTags,
          repeatId: mainQuestionnaire.repeatId,
          daysSheduleValue,
        } as IQuestionnaireDayItem,
      ]
    })
  }

  private mapSummaries(template: any) {
    return template[this.fields.summary].map((summaryData: any[]) => {
      const mainSummary = summaryData as any

      const jsonForWeb = JSON.parse(mainSummary.jsonForWeb)
      const uniqueId = jsonForWeb?.uniqueId || v4()
      const fromDay = jsonForWeb?.fromDay ?? undefined
      const schedulerOption = jsonForWeb?.schedulerOption ?? 1
      const daysSheduleValue = jsonForWeb?.daysOfWeek ?? []

      return {
        type: planingToolTypesEnum.SUMMARY,
        summary: mainSummary.summary,
        id: mainSummary.id,
        position: mainSummary.position,
        revealedTime: mainSummary.revealedAt,
        isAllDaySelected: mainSummary.revealedAt.split('T')[1] === '00:00:00' ? true : false,
        entityIds: [mainSummary.id],
        uniqueId: uniqueId || v4(),
        fromDay,
        schedulerOption,
        entityTags: mainSummary.entityTags,
        title: mainSummary.title || '',
        subTitle: mainSummary.subTitle || '',
        imageLink: mainSummary.imageLink || '',
        buttonText: mainSummary.buttonText,
        buttonUrl: mainSummary.buttonUrl,
        repeatId: mainSummary.repeatId,
        eventDay: mainSummary.eventDay,
        programWallPostId: mainSummary.programWallPostId,
        progressValue: mainSummary.progressValue,
        isPublished: mainSummary.isPublished,
        eventPlace: mainSummary?.eventPlace,
        daysSheduleValue,
      } as ISummaryDayItem
    })
  }

  private mapScenarios(template: any) {
    return (template[this.fields.scenarios] || []).map((scenarioData: any[]) => {
      const mainScenario = scenarioData as any

      const jsonForWeb = JSON.parse(mainScenario.jsonForWeb)
      const uniqueId = jsonForWeb?.uniqueId || v4()
      const fromDay = jsonForWeb?.fromDay ?? undefined
      const schedulerOption = jsonForWeb?.schedulerOption ?? 1
      const daysSheduleValue = jsonForWeb?.daysOfWeek ?? []

      return {
        type: planingToolTypesEnum.SCENARIO,
        weight: mainScenario.weight,
        id: mainScenario.id,
        name: mainScenario.name,
        isPublished: mainScenario.isPublished,
        position: mainScenario.position,
        revealedTime: mainScenario.revealedAt,
        isAllDaySelected: mainScenario.revealedAt.split('T')[1] === '00:00:00' ? true : false,
        entityIds: [mainScenario.id],
        uniqueId: uniqueId || v4(),
        fromDay,
        schedulerOption,
        entityTags: mainScenario.entityTags,
        repeatId: mainScenario.repeatId,
        daysSheduleValue,
        originalEntity: scenarioData,
      } as IScenarioDayItem
    })
  }

  private mapLinks(template: any) {
    const filledLinksArr = this.groupByPosition(template, this.fields.links)

    return filledLinksArr.map((linksData: any[]) => {
      const currentChunk = linksData.sort((current, next) => current.toolId - next.toolId)

      const mainLink = currentChunk[0]
      const jsonForWeb = JSON.parse(mainLink.jsonForWeb)
      const uniqueId = jsonForWeb?.uniqueId || v4()
      const fromDay = jsonForWeb?.fromDay ?? undefined
      const schedulerOption = jsonForWeb?.schedulerOption ?? 1
      const daysSheduleValue = jsonForWeb?.daysOfWeek ?? []

      return {
        type: planingToolTypesEnum.LINK,
        linksValues: linksData.map((entity: any) => entity.postLink.split(' ')),
        id: mainLink.id,
        position: mainLink.position,
        revealedTime: mainLink.revealedAt,
        isAllDaySelected: mainLink.revealedAt.split('T')[1] === '00:00:00' ? true : false,
        entityIds: linksData.map((entity: any) => entity.id),
        uniqueId: uniqueId || v4(),
        fromDay,
        schedulerOption,
        entityTags: mainLink.entityTags,
        repeatId: mainLink.repeatId,
        daysSheduleValue,
      } as ILinkDayItem
    })
  }

  private mapFiles(template: any) {
    const filledFileArr = this.groupByPosition(template, this.fields.files) as any[][]

    const result = []

    for (let i = 0; i < filledFileArr.length; i++) {
      const fileList = [] as IImageDayItem['fileList']
      const entityIds: number[] = []
      const fileIds: number[] = []
      const videoLinksEntityIds: number[] = []

      const currentChunk = filledFileArr[i].sort((current, next) => current.toolId - next.toolId)
      const mainFile = currentChunk[0]

      const fileExtension = mainFile?.mimeType
      const jsonForWeb = JSON.parse(mainFile.jsonForWeb)
      const fromDay = jsonForWeb?.fromDay ?? undefined
      const schedulerOption = jsonForWeb?.schedulerOption ?? 1
      const uniqueIdsForLinks = []
      const daysSheduleValue = jsonForWeb?.daysOfWeek ?? []

      let type = ''

      if (imageMimeTypes?.includes(fileExtension)) {
        type = planingToolTypesEnum.IMAGE
      } else if (
        videoMimeTypes?.includes(mainFile.mimeType) ||
        currentChunk.some(videoEntity => videoEntity?.youTubeLink ?? videoEntity.vimeoLink)
      ) {
        type = planingToolTypesEnum.VIDEO
      } else if (audioMimeTypes.includes(fileExtension)) {
        type = planingToolTypesEnum.AUDIO
      } else {
        type = planingToolTypesEnum.FILE
      }

      for (let j = 0; j < currentChunk.length; j++) {
        const currentFile = currentChunk[j]

        // If entity is video link
        if (currentFile.fileId === 1) {
          videoLinksEntityIds.push(currentFile.id)
          if (currentFile.jsonForWeb) {
            uniqueIdsForLinks.push(JSON.parse(currentFile.jsonForWeb).uniqueId)
          }
          continue
        }

        fileIds.push(currentFile.fileId)
        entityIds.push(currentFile.id)
      }

      const videoLinks = currentChunk.filter(video => video?.youTubeLink ?? video.vimeoLink)
      const sortedVideoLinks = videoLinks.sort((current, next) => current.toolId - next.toolId)

      const fileObj = {
        id: mainFile.id,
        entityIds,
        fileIds,
        type,
        position: mainFile.position,
        revealedTime: mainFile.revealedAt,
        isAllDaySelected: mainFile.revealedAt.split('T')[1] === '00:00:00' ? true : false,
        fileList,
        videoLinks: sortedVideoLinks.map(video => video?.youTubeLink ?? video.vimeoLink),
        videoLinksEntityIds,
        uniqueId: currentChunk.map(chunk => JSON.parse(chunk.jsonForWeb).uniqueId),
        fromDay,
        schedulerOption,
        entityTags: mainFile.entityTags,
        repeatId: mainFile.repeatId,
        daysSheduleValue,
      } as IFileDayItem

      if (type === planingToolTypesEnum.VIDEO) {
        ;(fileObj as unknown as IVideoDayItem).videoLinksUIDs = uniqueIdsForLinks
      }

      result.push(fileObj)
    }

    return result
  }

  public run() {
    const items = this.templateData

    const result: IDayData[] = []

    for (let i = 0; i < items.length; i++) {
      const template = items[i]
      const index = i

      const dayData: IDayData = {
        dayDate: template.day,
        dayNumber: index + 1,
        dayItems: [],
        id: template.id,
      }
      const questionnaires = this.mapQuestionnaires(template)
      const summaries = this.mapSummaries(template)
      const scenarios = this.mapScenarios(template)
      const links = this.mapLinks(template)
      const files = this.mapFiles(template)

      const res = [
        ...(questionnaires as IQuestionnaireDayItem[]),
        ...(summaries as ISummaryDayItem[]),
        ...(scenarios as IScenarioDayItem[]),
        ...(links as ILinkDayItem[]),
        ...(files as IFileDayItem[]),
      ]

      dayData.dayItems = this.isNews
        ? res.sort((a, b) => {
            const aEvent = (a as ISummaryDayItem).eventDay
            const bEvent = (b as ISummaryDayItem).eventDay

            const aGreater = moment(aEvent).diff(moment(bEvent), 'm') > 0

            return aGreater ? 1 : -1
          })
        : res.sort((a, b) => {
            if (a.position === undefined || b.position === undefined) return 0

            return a?.position - b?.position
          })

      result.push(dayData)
    }
    return result
  }
}

export const templateParserFromServer = new ParseTemplateFromServer()
