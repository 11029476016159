import { t } from 'i18next'
import { action, computed, makeAutoObservable } from 'mobx'
import { Dispatch, SetStateAction } from 'react'

import { IUpdateWallPostEntityDto } from 'api/DTOs/wallPost.dto'
import fileApi from 'api/file.api'
import programsApi from 'api/programs.api'
import templatesApi from 'api/templates.api'
import wallPostApi from 'api/wallPost.api'

import { IProgramList } from 'types/Program'
import {
  IDayData,
  IFileUploadingProgress,
  IFilesInToolsInfo,
  IProgramDays,
  ITemplate,
  ITemplateRedirectionInfo,
  IUploadedFile,
  planningToolsType,
} from 'types/Template'
import { ITemplateDayAPI_V2, IWallPostDayAPI } from 'types/Template/TemplateToServer'
import { IUnboundContentToServer } from 'types/UnboundContent'

import TemplateDaysCache from 'utils/caches/TemplateDaysCache'
import message from 'utils/message'
import { UnboundTemplateToServerParser, templateParserFromServer, templateParserToServer } from 'utils/parseTemplate'
import removeIdFromItem from 'utils/removeIdFromItem'

import FollowUpStore from './DI-Stores/FollowUpContent'
import TemplateEntityStore from './DI-Stores/TemplateEntity'
import UnboundStore from './DI-Stores/UnboundContent'

let lastTemplateDayIdFetched: string | null = null

class Store {
  constructor() {
    makeAutoObservable(this)
  }

  private _cache = new TemplateDaysCache()

  public unboundContentStore = UnboundStore
  public followUpStore = FollowUpStore
  public templateEntity = TemplateEntityStore

  public wallProgram: IProgramList | null = null
  public programDays: IProgramDays[] = []
  public calendarTimestamp = Date.now()

  uploadedFilesId: IUploadedFile[] = []
  uploadInProgressFilesId: string[] = []
  uploadErrorFilesId: string[] = []

  filesInToolsEntityInfo: IFilesInToolsInfo[] = []
  templateCreatingName: string = ''
  templateCreatingDaysDuration: number = 0

  sourceTemplateData: IDayData[] = []

  tagIdsToolRelationsToDelete: number[] = []

  public templateRedirectionInfo: ITemplateRedirectionInfo | null = null

  public isToolEditing = false

  clearCache() {
    this._cache.wipe()
  }

  clearWallPostData() {
    this.wallProgram = null
    this.programDays = []
  }

  checkIfToolEditing(isUnbound?: boolean) {
    if (isUnbound) {
      return this.unboundContentStore.checkIfToolsEditing()
    } else {
      return this.isToolEditing
    }
  }

  setIsToolEditing(status: boolean, isUnbound?: boolean) {
    if (isUnbound) {
      this.unboundContentStore.setToolsEditing(status)
    } else {
      this.isToolEditing = status
    }
  }

  setTemplateRedirectionInfo(info: ITemplateRedirectionInfo | null) {
    this.templateRedirectionInfo = info
  }

  addTagsRelationsToDelete(ids: number[]) {
    this.tagIdsToolRelationsToDelete = [
      ...this.tagIdsToolRelationsToDelete.filter(tagIdRel => !ids.includes(tagIdRel)),
      ...ids,
    ]
  }

  clearTagsRelationsToDelete() {
    this.tagIdsToolRelationsToDelete = []
  }

  @action
  async postUnboundContentAPI(daysData: IDayData[], templateId: number) {
    const unboundSorted = new UnboundTemplateToServerParser(daysData).run()

    const mapped = unboundSorted.map(item => item?.toCreateOrUpdate)

    const toUpdate: IUnboundContentToServer[] = []
    const toCreate: IUnboundContentToServer[] = []

    mapped.forEach(item => {
      if (!item) return

      if (item?.id) {
        toUpdate.push(item)
        return
      }

      if (!item?.id) {
        toCreate.push(item)
        return
      }
    })

    if (toUpdate.length) {
      await templatesApi.updateUnboundContent(templateId, toUpdate as IUnboundContentToServer[])
    }

    if (toCreate.length) {
      const toCreateWithoutIds = toCreate.map(item => ({
        ...item,
        templateEntityLinks: item.templateEntityLinks.map(removeIdFromItem),
        files: item.files.map(removeIdFromItem),
        summaries: item.summaries.map(removeIdFromItem),
        questionnaires: item.questionnaires.map(removeIdFromItem),
        scenarios: item.scenarios.map(removeIdFromItem),
      }))

      await templatesApi.addUnboundContent(templateId, toCreateWithoutIds as IUnboundContentToServer[])
    }

    await this.setUnboundContentAPI(templateId)
  }

  @action
  async deleteUnboundDay(id: number, templateId: number, isFirstDrop: boolean) {
    this.unboundContentStore.deleteUnboundDay(id)

    if (isFirstDrop) return

    await templatesApi.deleteUnboundContent(id)
    this.unboundContentStore.resetUnboundEntitiesToDelete()
    await this.setUnboundContentAPI(templateId)
  }

  @action
  async setUnboundContentAPI(templateId: number) {
    try {
      this.unboundContentStore.unboundContentLoading = true

      const unboundContent = await templatesApi.getUnboundContent(templateId)
      const days = templateParserFromServer.setTemplateData(unboundContent).run()

      this.unboundContentStore.setUnboundContent(days)
      this.followUpStore.setAvailableContentForFollowUp(days)
      this.followUpStore.setFollowUpUnboundContent(days)
    } catch (e) {
    } finally {
      this.unboundContentStore.unboundContentLoading = false
    }
  }

  setFilesInToolsEntityInfo(infoChunk: IFilesInToolsInfo) {
    this.filesInToolsEntityInfo = [...this.filesInToolsEntityInfo, infoChunk]
  }

  cleanFilesInToolsEntityInfo() {
    this.filesInToolsEntityInfo = []
  }

  setUploadInProgressFilesId(ids: string[]) {
    this.uploadInProgressFilesId = ids
  }

  setUploadErrorFilesId(ids: string[]) {
    this.uploadErrorFilesId = ids
  }

  setUploadedFilesId(ids: IUploadedFile[]) {
    this.uploadedFilesId = ids
  }

  async uploadFiles(
    formData: FormData,
    isFile?: boolean,
    setUploadFilesProgress?: Dispatch<SetStateAction<IFileUploadingProgress[]>>,
    fileUid?: string,
    fileName?: string
  ): Promise<number[] | void> {
    try {
      const onUploadProgress = (progressEvent: any) => {
        if (!setUploadFilesProgress || !fileUid || !fileName) return

        const percent = Math.round((progressEvent.loaded / progressEvent.total) * 100)

        setUploadFilesProgress(prev => [
          ...prev.filter(item => item.uid !== fileUid),
          { uid: fileUid, percent, fileName },
        ])
      }

      if (isFile) {
        const response = await fileApi.uploadFiles(formData, onUploadProgress)

        if (response.data) {
          return response.data.map((item: any) => item?.id)
        }
      }
      const response = await fileApi.uploadImages(formData, onUploadProgress)

      if (response.data) {
        return response.data.map((item: any) => item?.id)
      }
    } catch (e) {
      console.error(e)
    }
  }

  async getProgramNewsDays(programId: number) {
    try {
      const days = await wallPostApi.getEvents(programId)

      const events = days.reduce((acc: string[], cur: string) => {
        const item = cur.split('T')[0] + 'T00:00:00'

        if (!acc.includes(item)) {
          acc.push(item)
        }

        return acc
      }, [])

      this.templateEntity.setEventsDates(events)
      this.calendarTimestamp = Date.now()
      return events
    } catch (e) {
      console.log('e', e)
    }
  }

  async setProgramDaysAPI(programId: string) {
    const days = await wallPostApi.getWallPostDays(+programId)
    this.programDays = days
    this.calendarTimestamp = Date.now()
  }

  async setProgramAPI(programId: string) {
    const program = await programsApi.getProgramById(programId)
    this.wallProgram = program
  }

  async mapWallPostDayInCache({ dayIndex, programId }: { dayIndex: number; programId: number }) {
    const cacheKey = this._cache.generateKey({ dayIndex, programId: +programId })

    const wallPost = await wallPostApi.getWallPost({ pageNumber: dayIndex + 1, programId: +programId })

    const result = templateParserFromServer.setWallPostsData(wallPost.items).run()

    const sortedArr = result.map(item => ({
      ...item,
      dayItems: item.dayItems.sort((a, b) => (a.position as number) - (b.position as number)),
    }))

    this.sourceTemplateData = sortedArr

    const day = sortedArr[0]

    this._cache.add(cacheKey, day)

    return { day, sortedArr, totalItems: wallPost.totalItems }
  }

  async getWallPostById({
    programId,
    dayIndex,
    skipLoading,
    useCache,
  }: {
    programId: string
    dayIndex: number
    skipLoading?: boolean
    useCache?: boolean
  }): Promise<void | ITemplate> {
    try {
      const cacheKey = this._cache.generateKey({ dayIndex, programId: +programId })

      lastTemplateDayIdFetched = cacheKey

      const existInCache = useCache ? this._cache.has(cacheKey) : false

      let withLoading = !skipLoading

      if (existInCache) {
        const dayFromCache = this._cache.extract(cacheKey)
        withLoading = false

        this.templateEntity.setProgramId(+programId)
        this.templateEntity.setCurrentDay(dayFromCache)
        this.templateEntity.setCurrentDayIndex(dayIndex)
        this.templateEntity.updateDayKey()
      }

      if (withLoading) this.templateEntity.setLoadingDay(true)

      const { day, sortedArr, totalItems } = await this.mapWallPostDayInCache({
        dayIndex,
        programId: +programId,
      })

      if (lastTemplateDayIdFetched !== cacheKey) return

      this.sourceTemplateData = sortedArr

      if (!day) throw new Error('no day')

      this.followUpStore.setAvailableContentForFollowUpBound({ id: +programId, isWallPost: true })

      const templateInfo = {
        createdAt: day.dayDate,
        duration: totalItems,
        id: +programId,
        name: '',
        filter: null,
      }

      this.templateEntity.setProgramId(+programId)
      this.templateEntity.setCurrentDay(day)
      this.templateEntity.setCurrentDayIndex(dayIndex)
      this.templateEntity.setTemplateInfo(templateInfo)

      this._cache.add(cacheKey, day)

      return templateInfo
    } catch (e: any) {
      console.error(e)
      message.error(t('programCreation.smtWrong'))
    } finally {
      this.templateEntity.setLoadingDay(false)
    }
  }

  async getWallNews({
    programId,
    dayIndex,
    skipLoading,
    useCache,
  }: {
    programId: string
    dayIndex: number
    skipLoading?: boolean
    useCache?: boolean
  }) {
    try {
      const cacheKey = this._cache.generateKey({ dayIndex, programId: +programId, isNews: true })

      lastTemplateDayIdFetched = cacheKey

      const existInCache = useCache ? this._cache.has(cacheKey) : false

      let withLoading = !skipLoading

      if (existInCache) {
        const dayFromCache = this._cache.extract(cacheKey)
        withLoading = false

        this.templateEntity.setProgramId(+programId)
        this.templateEntity.setCurrentDay(dayFromCache)
        this.templateEntity.setCurrentDayIndex(dayIndex)
        this.templateEntity.updateDayKey()
      }

      if (withLoading) this.templateEntity.setLoadingDay(true)

      const eventDay = this.templateEntity.eventsDates[dayIndex]

      const wallNews = await wallPostApi.wallNews({ trainingProgramId: +programId, eventDay })

      if (lastTemplateDayIdFetched !== cacheKey) return

      const result = templateParserFromServer
        .setWallPostsData(
          [
            {
              day: eventDay,
              id: dayIndex,
              programWallPostLinks: [],
              programWallPostStoredFiles: [],
              questionnaires: [],
              scenarios: [],
              programWallPostSummaries: wallNews,
              trainingProgramId: +programId,
            },
          ],
          true
        )
        .run()

      this.sourceTemplateData = result

      const day = result[0]

      if (!day) throw new Error('no day')

      this.followUpStore.setAvailableContentForFollowUpBound({ id: +programId, isWallPost: true })

      const templateInfo = {
        createdAt: day.dayDate,
        duration: this.templateEntity.eventsDates.length,
        id: +programId,
        name: '',
        filter: this.templateEntity.templateInfo?.filter || null,
      }

      this.templateEntity.setProgramId(+programId)
      this.templateEntity.setCurrentDay(day)
      this.templateEntity.setCurrentDayIndex(dayIndex)
      this.templateEntity.setTemplateInfo(templateInfo)

      this._cache.add(cacheKey, day)

      return templateInfo
    } catch (e: any) {
      console.error(e)
      message.error(t('programCreation.smtWrong'))
    } finally {
      this.templateEntity.setLoadingDay(false)
    }
  }

  async swapToolsPosition(tool1: planningToolsType, tool2: planningToolsType) {
    if (!this.templateEntity.templateInfo) return

    const day = this.templateEntity.currentDay

    const tool1Copy: planningToolsType = { ...tool1, position: tool2.position }
    const tool2Copy: planningToolsType = { ...tool2, position: tool1.position }

    const parsedTool = templateParserToServer
      .setTemplateData(
        [{ ...day, dayItems: [tool1Copy, tool2Copy] } as IDayData],
        this.templateEntity.programId || void 0
      )
      .run()

    const parsedDay = parsedTool[0]

    if (this.templateEntity.programId) {
      const post = parsedDay as IWallPostDayAPI

      const dayToUpdate: IUpdateWallPostEntityDto = {
        id: post.id,
        programWallPostLinks: post.programWallPostLinks,
        programWallPostSummary: post.programWallPostSummaries[0] || null,
        programWallPostStoredFiles: post.programWallPostStoredFiles,
        questionnaires: post.questionnaires,
        scenarios: post.scenarios,
        trainingProgramId: post.trainingProgramId,
      }

      await wallPostApi.updateEntities(dayToUpdate)
      await this.getWallPostById({
        dayIndex: this.templateEntity.currentDayIndex,
        programId: this.templateEntity.programId.toString(),
        skipLoading: true,
      })
    } else {
      await templatesApi.updateEntities({
        entity: parsedDay as ITemplateDayAPI_V2,
        templateId: this.templateEntity.templateInfo.id,
      })
      await this.getTemplateEntitiesDay({
        dayIndex: this.templateEntity.currentDayIndex,
        id: this.templateEntity.templateInfo.id,
        skipLoading: true,
      })
    }
  }

  async getTemplateEntitiesDay({
    id,
    dayIndex,
    skipLoading,
    useCache,
  }: {
    id: number | string
    dayIndex: number
    skipLoading?: boolean
    useCache?: boolean
  }) {
    try {
      const cacheKey = this._cache.generateKey({ dayIndex, templateId: +id })

      lastTemplateDayIdFetched = cacheKey

      const existInCache = useCache ? this._cache.has(cacheKey) : false

      let withLoading = !skipLoading

      if (existInCache) {
        const dayFromCache = this._cache.extract(cacheKey)
        withLoading = false

        this.templateEntity.setCurrentDay(dayFromCache)
        this.templateEntity.setCurrentDayIndex(dayIndex)
        this.templateEntity.updateDayKey()
      }

      if (withLoading) this.templateEntity.setLoadingDay(true)

      const res = await templatesApi.getTemplateEntities({ templateId: id, pageNumber: dayIndex + 1 })

      if (lastTemplateDayIdFetched !== cacheKey) return

      const result = templateParserFromServer.setTemplateData(res.items).run()

      this.followUpStore.setAvailableContentForFollowUpBound({ id: +id, isWallPost: false })

      const sortedArr = result.map(item => ({
        ...item,
        dayItems: item.dayItems.sort((a, b) => (a.position as number) - (b.position as number)),
      }))

      this.sourceTemplateData = sortedArr

      const day = sortedArr[0]

      this.templateEntity.setCurrentDay(day || null)
      this.templateEntity.setCurrentDayIndex(dayIndex)

      this._cache.add(cacheKey, day)
    } finally {
      this.templateEntity.setLoadingDay(false)
    }
  }

  async getTemplateInfoById(id: number | string, skipLoading?: boolean) {
    try {
      if (!skipLoading) {
        this.templateEntity.setLoadingTemplate(true)
      }

      const templateDetails = await templatesApi.getLightTemplate(+id)

      this.templateEntity.setTemplateInfo(templateDetails)
    } finally {
      this.templateEntity.setLoadingTemplate(false)
    }
  }

  async changeWallPostDayDate(dateTime: string) {
    const wallPostId = this.templateEntity.currentDay?.id
    const trainingProgramId = this.templateEntity.programId

    if (!trainingProgramId || !wallPostId || !this.templateEntity.currentDay) {
      console.error('changeWallPostDayDate err -> no trainingProgramId or wallPostId provided | or no current day')
      return
    }

    await wallPostApi.changeDate({ dateTime, trainingProgramId, wallPostId })
    this.templateEntity.setCurrentDay({ ...this.templateEntity.currentDay, dayDate: dateTime })
    this.getWallPostById({
      programId: trainingProgramId.toString(),
      dayIndex: this.templateEntity.currentDayIndex,
      skipLoading: true,
      useCache: false,
    })
  }

  @computed
  get templateModeVisible() {
    return this.templateEntity.eventsDates.length > 0
  }
}

export default new Store()
