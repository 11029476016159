import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { FC, ReactNode } from 'react'

import useIsRtl from 'hooks/useIsRtl'

import styles from './styles.module.scss'

interface IProps {
  children: ReactNode
}

const TableContainerWrapper: FC<IProps> = ({ children }) => {
  const isRtl = useIsRtl()

  return (
    <div
      className={classNames(styles.container, {
        [styles.rtl]: isRtl,
      })}
    >
      {children}
    </div>
  )
}

export default observer(TableContainerWrapper)
