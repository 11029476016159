import { CloseCircleOutlined } from '@ant-design/icons'
import { Checkbox } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { uniqueId } from 'lodash'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import userApi from 'api/user.api'

import { useStore } from 'stores'

import { ITraineeWithInvite, ITrainees } from 'types/User'

import isAlreadyInvitedTrainee from 'utils/isAlreadyInvitedTrainee'

import { TraineeGroopingEnum } from 'containers/Private/Mentor/ProgramDetail/ProgramTabbar/TraineesTab'
import TraineeStatus from 'containers/Private/Mentor/ProgramDetail/ProgramTabbar/TraineesTab/Trainees/TraineeStatus'
import TraineeNameCell from './TraineeNameCell'

import CustomTable from 'components/CustomComponents/CustomTable'
import EditableText from 'components/EditableText'

import styles from './styles.module.scss'

export interface ITraineesTable {
  id: number
  avatarId: number
  firstName: string
  lastName: string
  phoneNumber: string
  allowedApproval: boolean
  approvalPhoneNumber: string
  approvalAvailable: boolean
  email: string
}

interface IProps {
  trainees: ITraineesTable[]
  setSelectedTrainees: Dispatch<SetStateAction<ITraineesTable[]>>
  rowSelection?: {
    selectedRowKeys: React.Key[]
    onChange: (newSelectedRowKeys: React.Key[]) => void
  }
  removeTrainee?: (phoneNumber: string) => void
  alreadyInvitedTrainees: ITraineeWithInvite[]
}

const InviteTraineesTable: FC<IProps> = ({
  trainees,
  setSelectedTrainees,
  rowSelection,
  removeTrainee,
  alreadyInvitedTrainees,
}) => {
  const { userStore } = useStore()

  const { t } = useTranslation()

  const setValueForEditablePhoneNumber = async (val: string, row: ITraineesTable) => {
    let newTrainee: ITrainees | null = null

    try {
      newTrainee = (await userApi.getTrainee(val)) as unknown as ITrainees
    } catch (e) {
      console.error(e)
    }

    setSelectedTrainees(prev =>
      prev.map(trainee => {
        if (trainee.phoneNumber === row.phoneNumber) {
          if (newTrainee) {
            return {
              id: newTrainee.id,
              phoneNumber: newTrainee.phoneNumber,
              firstName: newTrainee.firstName,
              approvalPhoneNumber: '',
              allowedApproval: false,
              approvalAvailable: false,
              avatarId: newTrainee.avatarId,
              email: newTrainee.email,
              lastName: newTrainee.lastName,
            }
          }

          return {
            id: +uniqueId(),
            allowedApproval: trainee.allowedApproval,
            approvalPhoneNumber: trainee.approvalPhoneNumber,
            avatarId: 0,
            firstName: '',
            lastName: '',
            phoneNumber: val,
            email: '',
            approvalAvailable: true,
          }
        }
        return trainee
      })
    )
  }

  const columns: ColumnsType<ITraineesTable> = [
    {
      title: t<string>('programCreation.nameTable'),
      dataIndex: 'name',
      render: (value, item) => {
        const isInviteYourself = userStore.user?.phoneNumber === item.phoneNumber
        const trainee = alreadyInvitedTrainees?.find(trainee => trainee.phoneNumber === item.phoneNumber)
        const showStatus = !!trainee && isAlreadyInvitedTrainee(trainee)

        return (
          <div>
            <TraineeNameCell item={item} />
            {isInviteYourself ? (
              <div className={styles.errorMessage}>{t('programCreation.selfInviteError')}</div>
            ) : showStatus ? (
              <TraineeStatus trainee={trainee} currentTab={TraineeGroopingEnum.All} />
            ) : (
              <></>
            )}
          </div>
        )
      },
    },
    {
      title: t<string>('programCreation.phoneNumber'),
      dataIndex: 'phoneNumber',
      render: (value, row) => {
        return (
          <EditableText
            value={value}
            trainees={trainees}
            id="trainees-table-phone-number"
            itemId={row.id}
            addText={t<string>('programCreation.addPhone')}
            setValue={async val => await setValueForEditablePhoneNumber(val, row)}
          />
        )
      },
    },
    {
      title: t<string>('programCreation.parentApproval'),
      dataIndex: 'allowedApproval',
      render: (value, row) => {
        return (
          <div className={styles.approval}>
            <Checkbox
              checked={value}
              disabled={!row.approvalAvailable}
              data-trainees-table-checkbox="trainees-table-checkbox"
              onChange={e => {
                setSelectedTrainees(prev =>
                  prev.map(trainee => {
                    if (trainee.id === row.id && trainee.phoneNumber === row.phoneNumber) {
                      return { ...trainee, allowedApproval: e.target.checked }
                    }
                    return trainee
                  })
                )
              }}
            >
              {t<string>('programCreation.parentApproval')}
            </Checkbox>
          </div>
        )
      },
    },
    {
      title: t<string>('programCreation.parentPhone'),
      dataIndex: 'approvalPhoneNumber',
      render: (value, row) => {
        if (!row.allowedApproval) return '-'

        return (
          <EditableText
            isParent={true}
            value={value}
            id="trainees-table-parent-phone"
            addText={t<string>('programCreation.addPhone')}
            setValue={val =>
              setSelectedTrainees(prev =>
                prev.map(trainee => {
                  if (trainee.phoneNumber === row.phoneNumber) {
                    return {
                      ...trainee,
                      approvalPhoneNumber: val,
                    }
                  }
                  return trainee
                })
              )
            }
          />
        )
      },
      responsive: ['lg'],
    },
    !!removeTrainee
      ? {
          width: 10,
          title: '',
          dataIndex: 'phoneNumber',
          render: (value: string) => {
            return (
              <div className={styles.delete} onClick={() => removeTrainee(value)}>
                <CloseCircleOutlined />
              </div>
            )
          },
        }
      : {},
  ]

  return (
    <CustomTable
      variant="invites"
      rowSelection={rowSelection}
      columns={columns}
      dataSource={trainees}
      pagination={false}
      rowKey={item => `${item.id} ${item.phoneNumber}`}
    />
  )
}

export default InviteTraineesTable
