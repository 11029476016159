import { authApi } from 'config'
import { IEventPlace } from 'types/EventPlace'
import { PaginationResponse } from 'types/common'
import { IGetEventPlacesParams, IGetEventPlacesShortParams } from './DTOs/eventPlace.dto'

const controller = 'EventPlace'

const eventPlaceApi = {
  async getEventPlaces(params: IGetEventPlacesParams) {
    const response = await authApi.get<PaginationResponse<IEventPlace>>(controller, {
      params,
    })

    return response.data
  },
  async getEventPlacesShort(params: IGetEventPlacesShortParams) {
    const response = await authApi.get<PaginationResponse<IEventPlace>>(controller + '/short', {
      params,
    })

    return response.data
  },
}

export default eventPlaceApi
