import { AutoComplete, AutoCompleteProps } from 'antd'
import classNames from 'classnames'
import { FC } from 'react'

import styles from './styles.module.scss'

interface IProps extends AutoCompleteProps {
  className?: string
  big?: boolean
}

const CustomAutocomplete: FC<IProps> = ({ big, className, ...rest }) => {
  return (
    <AutoComplete
      {...rest}
      className={classNames(
        styles.customInput,
        {
          [styles.big]: big,
        },
        className
      )}
    />
  )
}

export default CustomAutocomplete
