import { FollowUpPostType } from './Template'
import { IUserBase } from './User'

export interface IScheduledNotification {
  message: string
  executeAt: string
  id: number
  cron: string
  url: string | null
  postType: FollowUpPostType | null
  postId: number | null
  isSent: boolean
  imageUrl: string
  trainingProgramId: number
  userFrom: IUserBase
  usersTo: IUserBase[]
  postTitle: string | null
}

export interface IScheduleNotificationsForUser {
  user: {
    id: number
    firstName: string
    lastName: string
    email: string
    avatarId: number
    avatarKey: string
    phoneNumber: string
    lastActiveTime: string
  }
  id: number
  trainingProgram: {
    id: number
    name: string
    avatarId: 0
    avatarKey: string
  }
  message: string
  createdAt: string
  executeAt: string | null
  status: NotificationStatus
  postId: number | null
  url: string | null
  postTitle: string | null
}

export interface IScheduledNotificationHistory {
  createdAt: string
  id: number
  message: string
  status: NotificationStatus
  trainingProgramId: number
  user: IUserBase
}

export enum schedulerReminderOptionEnum {
  doNotRepeat = 1,
  daily,
  weekly,
  monthly,
}

export enum NotificationStatus {
  Sent = 0,
  Delivered = 1,
  Seen = 2,
  Error = 3,
}

export enum NotificationDisplayFilter {
  Future = 0,
  History = 1,
  Repeating = 2,
}
