import React, { ReactElement, ReactNode } from 'react'

import styles from './styles.module.scss'

interface IProps {
  title: string
  Avatar: ReactElement<any, any>
  contentText: string
  contentSubText: string
  children?: ReactNode
}

const Block: React.FC<IProps> = ({ title, Avatar, contentText, contentSubText, children }) => {
  return (
    <div className={styles.block}>
      <div className={styles.blockTitle}>{title}</div>
      <div className={styles.blockContent}>
        <div>{Avatar}</div>
        <div className={styles.contentHeader}>
          <div className={styles.blockContentText}>{contentText}</div>
          <div>{contentSubText}</div>
        </div>
      </div>
      {children}
    </div>
  )
}

export default Block
