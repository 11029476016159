import { LeftCircleOutlined } from '@ant-design/icons'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CustomButton from 'components/CustomComponents/CustomButton'

import styles from './styles.module.scss'

const NoData = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <div className={styles.noData} data-no-data="no-data">
      <h1>{t<string>('dailyReport.noData')}</h1>
      <div>
        <CustomButton
          type="link"
          icon={<LeftCircleOutlined />}
          onClick={() => navigate('/programs')}
          data-go-back="go-back"
        >
          {t<string>('dailyReport.goBack')}
        </CustomButton>
      </div>
    </div>
  )
}

export default memo(NoData)
