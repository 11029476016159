import { TraineeGroopingEnum } from 'containers/Private/Mentor/ProgramDetail/ProgramTabbar/TraineesTab'
import { StatusInvitesEnum } from 'stores/Program'

const transformInviteTypeToServer = (activeTab: TraineeGroopingEnum) => {
  return activeTab === TraineeGroopingEnum.Approved
    ? StatusInvitesEnum.Succeed
    : activeTab === TraineeGroopingEnum.InvitationErr
    ? StatusInvitesEnum.Failed
    : activeTab === TraineeGroopingEnum.InvitationPending
    ? StatusInvitesEnum.Pending
    : activeTab === TraineeGroopingEnum.NeedParentalApproval
    ? StatusInvitesEnum.RequireParentalApproval
    : activeTab === TraineeGroopingEnum.Rejected
    ? StatusInvitesEnum.Rejected
    : undefined
}

export default transformInviteTypeToServer
