import { IAvailableFollowUp } from 'types/Template'

class BoundContentCache {
  private static instance: BoundContentCache

  public cache = new Map()

  constructor() {
    if (BoundContentCache.instance) {
      return BoundContentCache.instance
    }

    BoundContentCache.instance = this
  }

  public add(key: string, value: IAvailableFollowUp[]) {
    this.cache.set(key, value)
  }

  public has(key: string) {
    return this.cache.has(key)
  }

  public wipe() {
    this.cache.clear()
  }

  public generateKey({ id, isWallPost }: { id: number; isWallPost: boolean }) {
    return `i${id}-wp${isWallPost}`
  }

  public extract(key: string) {
    return this.cache.get(key)
  }
}

export default BoundContentCache
