import { IBrandInfo } from 'types/User'

import { DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR } from './const'

export const setBrandVariableToScss = (data: IBrandInfo) => {
  //@ts-ignore
  root?.style.setProperty('--brandColor', data.primaryColor)
  //@ts-ignore
  root?.style.setProperty('--secondaryColor', data.secondaryColor)

  document.documentElement.style.setProperty('--brandColor', data.primaryColor)
  document.documentElement.style.setProperty('--secondaryColor', data.secondaryColor)
}

export const clearBrandVariables = () => {
  //@ts-ignore
  root?.style.setProperty('--brandColor', DEFAULT_PRIMARY_COLOR)
  //@ts-ignore
  root?.style.setProperty('--secondaryColor', DEFAULT_SECONDARY_COLOR)

  document.documentElement.style.setProperty('--brandColor', DEFAULT_PRIMARY_COLOR)
  document.documentElement.style.setProperty('--secondaryColor', DEFAULT_SECONDARY_COLOR)
}
