import { Checkbox, Select } from 'antd'
import CustomInput from 'components/CustomComponents/CustomInput'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Constructor from '../Constructor'
import CustomTextarea from 'components/CustomComponents/CustomTextarea'
import { deliveryTypeEnum } from './useCreateMessage'
import CustomButton from 'components/CustomComponents/CustomButton'
import styles from './styles.module.scss'
import useCreateMessage from './useCreateMessage'
import BackLink from 'components/BackLink'
import { ItemPagination } from 'types/MessageTemplate'
import useMessageTemplateCreate from 'hooks/tanstack/mutations/useMessageTemplateCreate'
import useUpdateMessageTemplate from 'hooks/tanstack/mutations/useUpdateMessageTemplate'
interface IProps {
  template?: ItemPagination
  onCancel?: () => void
}

const CreateMessage: FC<IProps> = ({ template, onCancel }) => {
  const { t } = useTranslation()
  const messageTemplateCreateMutation = useMessageTemplateCreate()
  const messageTemplateUpdate = useUpdateMessageTemplate()

  const {
    infoAboutTemplate,
    error,
    formatTags,
    isLoading,
    isProgramsLoading,
    programsList,
    setInfoAboutTemplate,
    onIncludedChange,
    handleSubmit,
    handleChange,
    Disabled,
  } = useCreateMessage()
  const Submit = (id: number) => {
    if (id) {
      messageTemplateUpdate.mutate(
        { ...infoAboutTemplate, id },
        {
          onSuccess: () => {
            if (onCancel) {
              onCancel()
            }
          },
        }
      )

      return
    }
    return messageTemplateCreateMutation.mutate(infoAboutTemplate, { onError: () => {} })
  }

  useEffect(() => {
    if (template) {
      const tagIds = template.tags.map(el => el.id)
      const programIds = template.programs.map(el => el.id)
      let { programs, tags, ...newTemplate } = template
      setInfoAboutTemplate(prev => ({ ...prev, ...newTemplate, tagIds, programIds }))
    }
  }, [template])

  return (
    <div className={!template ? styles.container : undefined}>
      {!template && (
        <>
          <div className={styles.back}>
            <BackLink backRoute="/company/message" />
          </div>
          <p className={styles.title}>{t('messageTemplate.messageTemplate')}</p>
        </>
      )}
      <Constructor title={t('messageTemplate.deliveryType')}>
        <Select
          value={infoAboutTemplate.deliveryType}
          size="large"
          onChange={handleChange}
          optionFilterProp="label"
          options={[
            { value: deliveryTypeEnum.Post, label: t('messageTemplate.post') },
            { value: deliveryTypeEnum.Notification, label: t('messageTemplate.notification') },
            { value: deliveryTypeEnum.PostAndNotification, label: t('messageTemplate.postAndNotification') },
          ]}
          className="tags-selector"
          style={{ width: '100%', marginBottom: 20 }}
        />
      </Constructor>
      <Constructor title={t('messageTemplate.nameTemplate')} required>
        <CustomInput
          value={infoAboutTemplate.name}
          onChange={e => setInfoAboutTemplate(prev => ({ ...prev, name: e.target.value }))}
        />
      </Constructor>
      {deliveryTypeEnum.Post === infoAboutTemplate.deliveryType && (
        <>
          <Constructor title={t('messageTemplate.title')}>
            <CustomInput
              value={infoAboutTemplate.title}
              onChange={e => setInfoAboutTemplate(prev => ({ ...prev, title: e.target.value }))}
            />
          </Constructor>
          <Constructor title={t('messageTemplate.subTitle')}>
            <CustomInput
              value={infoAboutTemplate.subTitle || ''}
              onChange={e => setInfoAboutTemplate(prev => ({ ...prev, subTitle: e.target.value }))}
            />
          </Constructor>
        </>
      )}
      <Constructor title={t('messageTemplate.messageArea')} required>
        <CustomTextarea
          value={infoAboutTemplate.message}
          onChange={e => setInfoAboutTemplate(prev => ({ ...prev, message: e.target.value }))}
          maxLength={1000}
          rows={10}
          placeholder={t<string>('messageTemplate.textAreaLimit')}
          className={styles.textArea}
        />
      </Constructor>
      <Constructor title={t('messageTemplate.priority')}>
        <CustomInput
          type="number"
          value={infoAboutTemplate.priority}
          onChange={e => setInfoAboutTemplate(prev => ({ ...prev, priority: +e.target.value.trim() }))}
          min={1}
        />
      </Constructor>
      <Constructor title={t('messageTemplate.urlImage')}>
        <CustomInput
          type="url"
          value={infoAboutTemplate.image}
          onChange={handleSubmit}
          errorText={error ? [t('messageTemplate.errorURL')] : []}
        />
      </Constructor>

      <Checkbox
        checked={infoAboutTemplate.isForAll}
        onChange={e => setInfoAboutTemplate(prev => ({ ...prev, isForAll: e.target.checked }))}
        style={{ paddingBottom: '1em' }}
      >
        {t('messageTemplate.forAll')}
      </Checkbox>
      {(infoAboutTemplate.deliveryType === deliveryTypeEnum.Notification ||
        infoAboutTemplate.deliveryType === deliveryTypeEnum.PostAndNotification) && (
        <Constructor title={t('messageTemplate.tagsID')}>
          <Select
            mode="multiple"
            size="large"
            allowClear
            value={infoAboutTemplate.tagIds}
            onChange={onIncludedChange}
            placeholder={t('companyPage.selectTags')}
            optionFilterProp="label"
            className="tags-selector"
            loading={isLoading}
            style={{ width: '100%', paddingBottom: 20 }}
            getPopupContainer={trigger => trigger}
            options={formatTags}
          />
        </Constructor>
      )}
      {(infoAboutTemplate.deliveryType === deliveryTypeEnum.Post ||
        infoAboutTemplate.deliveryType === deliveryTypeEnum.PostAndNotification) && (
        <Constructor title={t('messageTemplate.programID')}>
          <Select
            mode="multiple"
            value={infoAboutTemplate.programIds}
            options={programsList}
            onChange={e => {
              setInfoAboutTemplate(prev => ({ ...prev, programIds: Array.isArray(e) ? e : [] }))
            }}
            loading={isProgramsLoading}
            placeholder={t<string>('dailyReport.selectProgram')}
            size="large"
            style={{ width: '100%' }}
            className="tags-selector"
          />
        </Constructor>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '1em' }}>
        <CustomButton type="primary" onClick={() => Submit(template?.id || 0)} disabled={Disabled() || error}>
          {t('programCreation.save')}
        </CustomButton>
      </div>
    </div>
  )
}

export default CreateMessage
