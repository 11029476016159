import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import { urlRe } from 'utils/const'

export enum ActionTypeEnum {
  None,
  Content,
  Url,
}

const useReminderActions = () => {
  const [selectedContent, setSelectedContent] = useState<string | null>(null)

  const [actionUrl, setActionUrl] = useState('')
  const [actionType, setActionType] = useState(ActionTypeEnum.None)
  const [initialActionType, setInitialActionType] = useState(ActionTypeEnum.None)

  const [urlTouched, setUrlTouched] = useState(false)
  const [contentTouched, setContentTouched] = useState(false)

  const { t } = useTranslation()

  const { userStore } = useStore()

  const actionTypeOptions = [
    {
      label: t('templateCreation.doNothing'),
      value: ActionTypeEnum.None,
    },
    {
      label: t('planButton.contentId'),
      value: ActionTypeEnum.Content,
    },
    {
      label: 'URL',
      value: ActionTypeEnum.Url,
    },
  ]

  const onActionUrlChange = (e: any) => {
    setActionUrl(e.target.value)
    setUrlTouched(true)
  }

  const onContentChange = (value: string, option: any) => {
    setSelectedContent(value)
    setContentTouched(true)
  }

  const onActionTypeChange = (value: ActionTypeEnum) => {
    setActionType(value)
    if (value === ActionTypeEnum.None) {
      setActionUrl('')
    }
  }

  const urlErrorMessage = useMemo(() => {
    if (!urlTouched) return ''

    if (!urlRe.test(actionUrl)) return t<string>('templateCreation.invalidUrl')
  }, [urlTouched, actionUrl, userStore.language])

  return {
    urlErrorMessage,
    onActionUrlChange,
    onContentChange,
    selectedContent,
    actionUrl,
    actionType,
    initialActionType,
    urlTouched,
    contentTouched,
    actionTypeOptions,
    setActionUrl,
    setActionType,
    setInitialActionType,
    onActionTypeChange,
    setSelectedContent,
    setUrlTouched,
    setContentTouched,
  }
}

export default useReminderActions
