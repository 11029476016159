import { ConfigProvider, Layout } from "antd";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { FC, Suspense, memo, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useStore } from "stores";

import useDefineDirection from "hooks/private/useDefineDirection";
import useLanguageFetch from "hooks/private/useLanguageFetch";
import useProgramCreationProgressBG from "hooks/programCreationProgress/useProgramCreationProgressBG";
import useDefaultAvatarIdFetch from "hooks/useDefaultAvatarIdFetch";
import useRedirectAfterAuth from "hooks/useRedirectAfterAuth";
import useSyncUser from "hooks/useSyncUser";

import Mentor from "./Mentor";
import SuperAdmin from "./SuperAdmin";

import LoadPage from "components/LoadPage";
import Sidebar from "components/Sidebar";

import styles from "./styles.module.scss";

const Registration = React.lazy(() => import("./Registration"));

interface IPrivateRoutesProps {
	isMentor: boolean;
	isCompanyModerator: boolean;
	isAdmin: boolean;
	isMessagesOnlyAccess: boolean;
	isMessagesAndProgramsAccess: boolean;
}

const PrivateRoutes: FC<IPrivateRoutesProps> = memo(
	({
		isMentor,
		isAdmin,
		isCompanyModerator,
		isMessagesOnlyAccess,
		isMessagesAndProgramsAccess,
	}) => {
		useRedirectAfterAuth();
		useDefaultAvatarIdFetch();
		useSyncUser();

		return (
			<Layout.Content
				className={cn(styles.container, styles.sidebarMargin)}
				data-content="content"
			>
				<div className={styles.content} data-content-2="data-content-2">
					<Suspense fallback={<></>}>
						{isMentor ? (
							<Mentor
								isCompanyModerator={isCompanyModerator}
								isMessagesOnlyAccess={isMessagesOnlyAccess}
								isMessagesAndProgramsAccess={isMessagesAndProgramsAccess}
							/>
						) : isAdmin ? (
							<SuperAdmin />
						) : (
							<Routes>
								<Route path="*" element={<Navigate to="/" replace />} />
							</Routes>
						)}
					</Suspense>
				</div>
			</Layout.Content>
		);
	},
);

const Private: React.FC = () => {
	const { userStore } = useStore();

	const { direction } = useDefineDirection();

	useLanguageFetch();

	useEffect(() => {
		if (!userStore.isAdmin) {
			userStore.setUserDataAPI();
			userStore.setVariables();
		}
	}, [userStore.isAdmin]);

	useProgramCreationProgressBG();

	return (
		<ConfigProvider direction={direction} data-config-provider-3="provider-3">
			<div data-div-ref="div-ref">
				<Layout className={styles.layout} data-layout="layout">
					{userStore.isNewUser ? (
						<Suspense fallback={<LoadPage />}>
							<Registration />
						</Suspense>
					) : (
						<>
							<Sidebar />
							<PrivateRoutes
								isAdmin={userStore.isAdmin}
								isCompanyModerator={userStore.isCompanyModerator}
								isMentor={userStore.isTrainer}
								isMessagesOnlyAccess={userStore.isMessagesOnlyAccess}
								isMessagesAndProgramsAccess={
									userStore.isMessagesAndProgramsAccess
								}
							/>
						</>
					)}
				</Layout>
			</div>
		</ConfigProvider>
	);
};

export default observer(Private);
