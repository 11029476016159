import { Collapse } from 'antd'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import useAtLeastOneQuestion from 'hooks/reports/useAtLeastOneQuestion'
import useExpandedRows from 'hooks/reports/useExpandedRows'
import useReportCommentsHub from 'hooks/reports/useReportCommentsHub'
import useReportDateRange from 'hooks/reports/useReportDateRange'
import useReportFilters from 'hooks/reports/useReportFilters'
import useDailyReportsQuery from 'hooks/tanstack/queries/useDailyReportsQuery'
import useProgramsWhereMentorQuery from 'hooks/tanstack/queries/useProgramsWhereMentorQuery'
import useShortTraineesQuery from 'hooks/tanstack/queries/useShortTraineesQuery'

import { useStore } from 'stores'

import { IDailyReport } from 'types/Program'

import sortingByLocale from 'utils/sortingByLocale'

import NoData from './NoData'
import ReportDayHeader from './ReportDayHeader'
import ReportProgramGroup from './ReportProgramGroup'
import ReportTable from './ReportTable'
import SecondaryPanel from './SecondaryPanel'
import TopPanel from './TopPanel'

import Loader from 'components/Loader'
import PageContainer from 'components/PageContainer'

import { IReportUserLocal } from 'types/Report'
import styles from './styles.module.scss'

export interface IGroupedByProgramReport {
  programId: number
  programName: string
  reports: IDailyReport[]
}

const Report = () => {
  const navigate = useNavigate()

  const location = useLocation()

  const { userStore } = useStore()

  const { id } = useParams<{ id: string }>()

  const { t } = useTranslation()

  const [programId, setProgramId] = useState<number | null>(() => (id ? +id : null))

  const { programs, isLoading: programsLoading } = useProgramsWhereMentorQuery()

  useEffect(() => {
    if (programs?.length === 1) {
      setProgramId(programs[0].id)
    }
  }, [programs])

  const currentProgram = useMemo(() => {
    if (!programId) return void 0

    return programs?.find(program => program.id === programId)
  }, [programs, programId])

  const mentors = useMemo(() => currentProgram?.mentors || [], [currentProgram])

  const { dateRange, setDateRange } = useReportDateRange({ currentProgram })

  const { dailyReports, isLoading, reportLoadingFirstTime } = useDailyReportsQuery({ programId, dateRange })

  const { trainees, isLoading: traineesLoading } = useShortTraineesQuery({ programId })

  const loading = programsLoading || isLoading || reportLoadingFirstTime

  const allUsers: IReportUserLocal[] = useMemo(() => {
    const mentorsAsUsers = mentors.map(mentor => ({
      id: mentor.id,
      avatarId: mentor.avatarId,
      firstName: mentor.firstName,
      lastName: mentor.lastName,
      phoneNumber: mentor.phoneNumber,
    }))

    const traineesAsUsers = trainees.map(trainee => ({
      id: trainee.id,
      avatarId: trainee.avatarId,
      firstName: trainee.firstName,
      lastName: trainee.lastName,
      phoneNumber: trainee.phoneNumber,
    }))

    return [...mentorsAsUsers, ...traineesAsUsers]
  }, [mentors, trainees])

  useReportCommentsHub()

  const {
    mainSearchValue,
    setMainSearchValue,
    dailyReportsWithFilters,
    repeatedQuestions,
    selectedQuestionNames,
    setSelectedQuestionNames,
    questionIdsToXlsxReport,
    resetQuestions,
    isShowOnlyFirsDayTrainee,
    selectedUserIds,
    setSelectedUserIds,
  } = useReportFilters({
    dailyReports,
    allUsers,
    initialUserFilter: location.state?.userId,
    loading,
    mentors,
  })

  const atLeastOneQuestion = useAtLeastOneQuestion(dailyReports)

  const dailyReportsWithFiltersGroupedByProgram = useMemo(() => {
    if (currentProgram || loading) return []

    let res: IGroupedByProgramReport[] = []

    dailyReportsWithFilters.forEach(report => {
      const existProgramIdx = res.findIndex(item => item.programId === report.trainingProgramId)

      if (existProgramIdx === -1) {
        res.push({ programId: report.trainingProgramId, programName: report.trainingProgramName, reports: [report] })
      } else {
        res[existProgramIdx].reports.push(report)
      }
    })

    return sortingByLocale(res, userStore.language, 'programName') as IGroupedByProgramReport[]
  }, [dailyReportsWithFilters, currentProgram, loading])

  const changeProgram = useCallback(
    (id: number | null) => {
      navigate(id ? '/report/' + id : '/report/')

      setProgramId(id)
      resetQuestions()
    },
    [resetQuestions]
  )

  const {
    handleExpandQuestions,
    handleCollapseQuestions,
    handleCollapseAll,
    handleExpandAll,
    setExpandedRowDays,
    expandedRowDays,
    isAllRowsExpanded,
    expandedRowKeys,
  } = useExpandedRows({
    dailyReportsSource: dailyReports || [],
    dailyReports: dailyReportsWithFilters || [],
    isForAllPrograms: !programId,
    loading,
  })

  return (
    <PageContainer fullScreen>
      {programsLoading ? (
        <div className={styles.loading}>
          <Loader />
        </div>
      ) : (
        <>
          <TopPanel
            changeProgram={changeProgram}
            currentProgram={currentProgram}
            programId={programId}
            programs={programs || []}
            searchValue={mainSearchValue}
            setSearchValue={setMainSearchValue}
          />
          <SecondaryPanel
            dateTimeFrom={dailyReportsWithFilters?.[0]?.dateTime}
            dateTimeTill={dailyReportsWithFilters?.[dailyReportsWithFilters.length - 1]?.dateTime}
            programId={programId}
            isAllRowsExpanded={isAllRowsExpanded}
            handleCollapseAll={handleCollapseAll}
            handleExpandAll={() => handleExpandAll(dailyReportsWithFilters)}
            allUsers={allUsers}
            selectedUserIds={selectedUserIds}
            setSelectedUserIds={setSelectedUserIds}
            traineesLoading={traineesLoading}
            dateRange={dateRange}
            setDateRange={setDateRange}
            currentProgram={currentProgram}
            repeatedQuestions={repeatedQuestions}
            selectedQuestionNames={selectedQuestionNames}
            setSelectedQuestionNames={setSelectedQuestionNames}
            questionIdsToXlsxReport={questionIdsToXlsxReport}
            loading={isLoading || reportLoadingFirstTime}
          />
          {isLoading || reportLoadingFirstTime ? (
            <div className={styles.loading}>
              <Loader />
            </div>
          ) : currentProgram && dailyReportsWithFilters?.length ? (
            <>
              {atLeastOneQuestion ? (
                <Collapse
                  expandIconPosition={'end'}
                  activeKey={expandedRowDays}
                  onChange={keys => setExpandedRowDays(typeof keys === 'string' ? [keys] : keys)}
                  data-collapse="Collapse"
                  className="report-collapse"
                >
                  {dailyReportsWithFilters.map((item, index) => (
                    <Collapse.Panel
                      key={item.day - 1}
                      className={styles.panel}
                      header={<ReportDayHeader item={item} />}
                      data-panel="panel"
                    >
                      <ReportTable
                        tableKeyField="id"
                        item={item}
                        expandedRowKeys={expandedRowKeys}
                        handleCollapseQuestions={handleCollapseQuestions}
                        handleExpandQuestions={handleExpandQuestions}
                        isShowOnlyFirsDayTrainee={isShowOnlyFirsDayTrainee && index !== 0}
                      />
                    </Collapse.Panel>
                  ))}
                </Collapse>
              ) : (
                <div className={styles.noQuestionsMessage}>{t('dailyReport.noQuestionsInProgram')}</div>
              )}
            </>
          ) : !currentProgram && dailyReportsWithFiltersGroupedByProgram.length ? (
            dailyReportsWithFiltersGroupedByProgram.map((programGroup, index) => (
              <ReportProgramGroup
                key={programGroup.programId}
                expandedRowDays={expandedRowDays}
                expandedRowKeys={expandedRowKeys}
                handleCollapseQuestions={handleCollapseQuestions}
                handleExpandQuestions={handleExpandQuestions}
                programGroup={programGroup}
                originalReports={dailyReports.filter(report => report.trainingProgramId === programGroup.programId)}
                setExpandedRowDays={setExpandedRowDays}
              />
            ))
          ) : (
            <NoData />
          )}
        </>
      )}
    </PageContainer>
  )
}

export default observer(Report)
