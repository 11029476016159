import { FollowUpActionsEnum, FollowUpPostType } from './index'

export enum QuestionTypeEnum {
  NUMERIC,
  TEXT,
  RATING,
  MULTIPLE_CHOICE,
  CHECK_BOX,
}
export interface IQuestionnaireCommonState {
  type: QuestionTypeEnum | null
  question: string
}

export interface IQuestionnaireNumeric extends IQuestionnaireCommonState {
  min: number
  max: number
}

export interface IMCQAnswerOption {
  answerText: string
  createdAt: string
  id: number
  jsonForWeb: string | null
  questionTemplateId: number
  updatedAt: string | null
  url: string
  webHook: string
  postId: number | null
  postType: FollowUpPostType
}

export interface IQuestionnaireMultipleChoice extends IQuestionnaireCommonState {
  options: string[]
  answerIds: string[]
  followUpAction: FollowUpActionsEnum[]
  followUpActionField: string[]
}

export interface IQuestionnaireCheckBox extends IQuestionnaireCommonState {
  options: string[]
  answerIds: string[]
}

export interface IQuestionnaireRating {
  type: QuestionTypeEnum
  question: string[]
}

export type QuestionnaireState =
  | IQuestionnaireNumeric
  | IQuestionnaireCommonState
  | IQuestionnaireCheckBox
  | IQuestionnaireMultipleChoice
  | IQuestionnaireRating
