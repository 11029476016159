import React from 'react'
import dayjs from 'dayjs'
import { Typography } from 'antd'

import { TextMessageDto } from 'api/swagger.api'
import UserAvatar from 'components/UserAvatar'
import ChatMessageFileList from './ChatMessageFileList'
import ChatMessageImage from './ChatMessageImage'

import styles from './styles.module.scss'

type Props = {
  message: TextMessageDto
}

const ChatMessage: React.FC<Props> = ({ message: { message = '', user, fileMessages, createdAt } }) => {
  const files = (fileMessages || []).filter(file => file.isFile)
  const pictures = (fileMessages || []).filter(file => file.isPicture)

  return (
    <div className={styles.container}>
      <UserAvatar avatarId={user?.avatarId || 0} />
      <div className={styles.content}>
        <Typography.Text strong>{user?.fullName}</Typography.Text>

        {message !== '[media]' && <Typography.Text>{message}</Typography.Text>}

        {pictures.length > 0 && <ChatMessageImage file={pictures[0]} />}

        {files.length > 0 && <ChatMessageFileList files={files} />}

        <Typography.Text type="secondary" className={styles.time}>
          {dayjs(createdAt).format('HH:mm')}
        </Typography.Text>
      </div>
    </div>
  )
}

export default ChatMessage
