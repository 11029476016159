import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormInstance } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { IEditProgramDTO } from 'api/DTOs/programs.dto'
import fileApi from 'api/file.api'
import programsApi from 'api/programs.api'

import { useStore } from 'stores'

import { PostsSortingOptionsEnum, ProgramForm, ProgramImage } from 'types/Program'
import { QueryKeysEnum } from 'types/QueryKeysEnum'

import message from 'utils/message'

interface IProps {
  programId: number
  avatarId: number
  newAvatar: ProgramImage
  afterUpdate: () => void
  form: FormInstance<ProgramForm>
  sortingOption: PostsSortingOptionsEnum
}

const useUpdateProgramMutation = ({
  programId,
  avatarId: avatarIdFromProps,
  newAvatar,
  afterUpdate,
  form,
  sortingOption,
}: IProps) => {
  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const { userStore } = useStore()

  const navigate = useNavigate()

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async () => {
      await form.validateFields()

      let avatarId = avatarIdFromProps || null

      if (newAvatar?.file) {
        const formData = new FormData()

        formData.append('pictures', newAvatar.file as unknown as Blob)
        formData.append('imageSense', 'logo')

        const imageId = await fileApi.uploadImage(formData)

        if (imageId) {
          avatarId = imageId
        }
      }

      const { isVisible, ...rest } = form.getFieldsValue()

      const programData: IEditProgramDTO = {
        ...rest,
        trainingProgramAvatarId: avatarId,
        groupChatAvatarId: avatarId,
        sortingOption,
        companyId:
          userStore.userBrands.length === 1 ? userStore.userBrands[0].id : !rest.companyId ? null : rest.companyId,
        isForAnonymous: userStore.isForAnonSubdomain ? rest.isPublic : false,
        isPublic: userStore.isForAnonSubdomain ? false : rest.isPublic,
        uniqueId: userStore.isForAnonSubdomain ? '' : rest.uniqueId,
        isInvisible: !isVisible,
      }

      await programsApi.updateProgram(programId, programData)

      message.success(t('programCreation.programUpdateSuccess'))

      navigate('/programs/' + programId)

      return true
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeysEnum.PROGRAM] })
      queryClient.invalidateQueries({ queryKey: [QueryKeysEnum.PROGRAMS] })
      afterUpdate()
    },
    onError: (e: any) => {
      if (!e.response) {
        message.error(t('programCreation.checkWarnings'))
        return
      }

      const { StatusCode, Message } = e.response.data
      const { name } = form.getFieldsValue()

      if (StatusCode === 400) {
        message.error(t('programList.programExist', { name }))
      } else {
        message.error(t('programCreation.smtWrong'))
      }
    },
  })

  return {
    updateProgram: mutateAsync,
    isLoading,
  }
}

export default useUpdateProgramMutation
