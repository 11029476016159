import { useEffect, useState } from 'react'
import { useStore } from 'stores'

import { IDailyReportAnswer, IOptimisticComment } from 'types/Program'

interface IProps {
  commentsData: IDailyReportAnswer | null
}

const useComments = ({ commentsData }: IProps) => {
  const { userStore } = useStore()

  const [comments, setComments] = useState<IOptimisticComment[]>([])

  useEffect(() => {
    if (commentsData) {
      setComments(commentsData.coments)
    }
  }, [commentsData])

  const addOptimisticComment = (comment: string) => {
    setComments(prev => {
      if (!userStore.user) return prev

      return [
        ...prev,
        {
          user: {
            avatarId: userStore.user.avatarId,
            avatarKey: userStore.user.avatarKey,
            firstName: userStore.user.firstName,
            lastName: userStore.user.lastName,
            id: userStore.user.id,
            isDeleted: false,
            isMentor: true,
            phoneNumber: userStore.user.phoneNumber,
          },
          comment,
          createdAt: new Date().toISOString(),
          id: Date.now(),
          isPinned: false,
          questionCommentSectionId: Date.now(),
          temporary: true,
        },
      ]
    })
  }

  const deleteOptimisticComment = (id: number) => {
    setComments(prev => prev.filter(comment => comment.id !== id))
  }

  return { comments, addOptimisticComment, deleteOptimisticComment }
}

export default useComments
