import { observer } from 'mobx-react-lite'
import { FC } from 'react'

import { IDefaultToolProps, planingToolTypesEnum, planningToolsType } from 'types/Template'

import AudioTool from './PlaningTools/AudioTool'
import FileTool from './PlaningTools/FileTool'
import ImageTool from './PlaningTools/ImageTool'
import LinkTool from './PlaningTools/LinkTool'
import QuestionnaireTool from './PlaningTools/QuestionnaireTool'
import SummaryTool from './PlaningTools/SummaryTool'
import VideoTool from './PlaningTools/VideoTool'
import ScenarioTool from './PlaningTools/ScenarioTool'

interface IProps {
  tool: planningToolsType
  toolIndex: number
  dayIndex: number
  isDraggingItem: boolean
  dayId?: number
  isUnbound?: boolean
  onDelete: (onAfterDelete?: () => void) => void
  isNewsMode?: boolean
  inSearch?: boolean
}

const ToolSwitch: FC<IProps> = ({
  dayIndex,
  isDraggingItem,
  tool,
  toolIndex,
  isUnbound,
  dayId,
  onDelete,
  isNewsMode,
  inSearch,
}) => {
  const defaultToolProps = {
    tool,
    dayIndex,
    toolIndex,
    key: dayIndex,
    isDraggingItem,
    isUnbound,
    dayId,
    onDelete,
    isNewsMode,
    inSearch,
  } as IDefaultToolProps

  if (!tool) return <></>

  switch (tool.type) {
    case planingToolTypesEnum.IMAGE:
      return <ImageTool {...defaultToolProps} />
    case planingToolTypesEnum.VIDEO:
      return <VideoTool {...defaultToolProps} />
    case planingToolTypesEnum.AUDIO:
      return <AudioTool {...defaultToolProps} />
    case planingToolTypesEnum.FILE:
      return <FileTool {...defaultToolProps} />
    case planingToolTypesEnum.QUESTIONNAIRE:
      return <QuestionnaireTool {...defaultToolProps} />
    case planingToolTypesEnum.LINK:
      return <LinkTool {...defaultToolProps} />
    case planingToolTypesEnum.SUMMARY:
      return <SummaryTool {...defaultToolProps} />
    case planingToolTypesEnum.SCENARIO:
      return <ScenarioTool {...defaultToolProps} />
    default:
      return <></>
  }
}

export default observer(ToolSwitch)
