import { LoadingOutlined } from '@ant-design/icons'
import { Image, Space } from 'antd'
import { FC, memo } from 'react'

import useImageQuery from 'hooks/tanstack/queries/useImageQuery'

import styles from './styles.module.scss'

import defaultUserPic from 'sources/images/profile.png'

interface IProps {
  avatarId: number
  size?: number
  allowPreview?: boolean
}

const UserAvatar: FC<IProps> = ({ avatarId, size, allowPreview }) => {
  const { image, isLoading } = useImageQuery({ id: avatarId })

  const commonStyles = {
    width: size || 40,
    height: size || 40,
  }

  return (
    <Space>
      {isLoading ? (
        <div className={styles.loader} style={commonStyles}>
          <LoadingOutlined style={{ color: '#333', fontSize: 15 }} />
        </div>
      ) : (
        <div className={styles.imageContainer}>
          <Image
            src={avatarId > 1 && image ? image : defaultUserPic}
            className={styles.avatarImage}
            style={commonStyles}
            preview={!!allowPreview}
          />
        </div>
      )}
    </Space>
  )
}

export default memo(UserAvatar)
