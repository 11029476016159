import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import programsApi from 'api/programs.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import message from 'utils/message'

interface IProps {
  onSuccess?: () => void
}

const useDeleteProgramMutation = ({ onSuccess }: IProps) => {
  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const { mutateAsync } = useMutation({
    mutationFn: async (id: string | number) => {
      const deleted = await programsApi.deleteProgram(id)

      if (!deleted) {
        message.error(t<string>('programCreation.smtWrong'))
        return
      }

      message.success(t<string>('programCreation.programDeleteSuccess'))
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeysEnum.PROGRAMS])

      if (onSuccess) {
        onSuccess()
      }
    },
  })

  return mutateAsync
}

export default useDeleteProgramMutation
