import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useStore } from 'stores'

import { someError } from 'utils/message'

import TemplateModule from 'modules/TemplateModule'

import Loader from 'components/Loader'

import styles from './styles.module.scss'

const TemplateEditing = () => {
  const { id } = useParams<{ id: string }>()

  const { state: navigationState } = useLocation()

  const fromList = !!navigationState?.forceRedirect

  const { templateStore, goalsStore } = useStore()
  const { templateEntity } = templateStore

  const navigate = useNavigate()

  const [initialLoading, setInitialLoading] = useState<boolean>(false)

  useEffect(() => {
    return () => {
      templateStore.setTemplateRedirectionInfo(null)
    }
  }, [])

  useEffect(() => {
    const getTemplateData = async () => {
      try {
        if (!id) return

        setInitialLoading(true)

        await Promise.all([
          templateStore.getTemplateInfoById(id),
          templateStore.getTemplateEntitiesDay({
            dayIndex: 0,
            id,
          }),
          goalsStore.setTemplateGoals(+id),
        ])
      } catch (e) {
        console.error(e)
        if (!fromList) {
          navigate('/template')
        }
        someError()
      } finally {
        setInitialLoading(false)
      }
    }

    getTemplateData()
  }, [id])

  return (
    <>
      {initialLoading || templateEntity.loadingTemplate ? (
        <div className={styles.loaderContainer} data-loader-container="loader-container">
          <Loader />
        </div>
      ) : (
        <TemplateModule key={id} fromList={fromList} />
      )}
    </>
  )
}

export default observer(TemplateEditing)
