import { DeleteOutlined, FileOutlined } from '@ant-design/icons'
import { Tooltip, UploadFile } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Dispatch, FC, SetStateAction, useMemo, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import ReactPlayer from 'react-player'

import { useStore } from 'stores'

import { formatBytes } from 'utils/helpers'

import ErrorUploadFile from '../ErrorUploadFile'

import ContentId from 'components/ContentId'
import CustomDeleteIcon from 'components/CustomComponents/CustomDeteleIcon'

import styles from './styles.module.scss'

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  file: UploadFile
  fileList: UploadFile[]
  moveRow: (dragIndex: any, hoverIndex: any) => void
  isImageCard?: boolean
  isEditing: boolean
  setFileList: Dispatch<SetStateAction<UploadFile<any>[]>>
  isVideo?: boolean
  handleRemove: (file: any) => void
  entityId?: number | null
}

const type = 'DraggableUploadList'

const DraggableUploadListItem: FC<DraggableUploadListItemProps> = ({
  originNode,
  moveRow,
  file,
  fileList,
  isImageCard,
  isEditing,
  setFileList,
  isVideo,
  handleRemove,
  entityId,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const index = fileList.indexOf(file)

  const { templateStore } = useStore()

  const isFileInUploadProcess = useMemo(() => {
    const finedItem = templateStore.uploadInProgressFilesId.find(loadingId => loadingId === file.uid)

    return !!finedItem
  }, [templateStore.uploadInProgressFilesId, file])

  const isFileUploadingError = useMemo(() => {
    const finedItem = templateStore.uploadErrorFilesId.find(fileIdErr => fileIdErr === file.uid)

    return !!finedItem
  }, [templateStore.uploadErrorFilesId, file.uid])

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      }
    },
    drop: (item: any) => {
      moveRow(item.index, index)
    },
  })

  const [, drag] = useDrag(
    {
      type,
      item: { index },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => isEditing,
    },
    [isEditing, index]
  )

  drop(drag(ref))

  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>

  const cachedFileUrl = useMemo(() => {
    if (!isVideo) return file.url || ''

    if (file.url) return file.url

    if (file?.originFileObj && Object.keys(file.originFileObj)?.length > 1) {
      return URL.createObjectURL(file.originFileObj)
    }

    return ''
  }, [file, isVideo])

  if (!isVideo && !isImageCard) {
    return (
      <div
        ref={ref}
        className={classNames(
          `ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`,
          styles.container,
          styles.defaultFileCardItem,
          {
            [styles.loadingContainer]: isFileInUploadProcess || isFileUploadingError,
            [styles.cardLoading]: isImageCard && isFileInUploadProcess,
            [styles.showDelete]: isEditing,
          }
        )}
        style={{ cursor: 'move' }}
      >
        <div>
          <div className={styles.fileIcon}>
            <FileOutlined />
          </div>
          <div className={styles.fileInfo}>
            <ContentId entityId={entityId} />
            <div>{file.name}</div>
            {file.size && <div>{formatBytes(file.size)}</div>}
          </div>
        </div>
        {isEditing && (
          <div onClick={() => handleRemove(file)} className={styles.deleteIconInFileItem}>
            <DeleteOutlined />
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      {isVideo ? (
        <div className={styles.videoContainer}>
          <ContentId entityId={entityId} />
          <div
            ref={ref}
            className={classNames(
              `ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`,
              styles.container,
              styles.videoItem
            )}
          >
            <ReactPlayer width={320} height={180} controls={true} url={cachedFileUrl} />
            {isEditing && <CustomDeleteIcon onClick={() => handleRemove(file)} />}
          </div>
        </div>
      ) : (
        <div
          ref={ref}
          className={classNames(`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`, styles.container, {
            [styles.cardItem]: isImageCard,
            [styles.defaultFileCardItem]: !isImageCard,
            [styles.loadingContainer]: isFileInUploadProcess || isFileUploadingError,
            [styles.cardLoading]: isImageCard && isFileInUploadProcess,
          })}
          style={{ cursor: 'move' }}
        >
          <div className={styles.imgContentIdContainer}>
            <ContentId entityId={entityId} />
          </div>
          {file.status === 'error' ? errorNode : originNode}
          {isFileUploadingError && !isFileInUploadProcess && (
            <ErrorUploadFile file={file} isEditing={isEditing} setFileList={setFileList} isImageCard={isImageCard} />
          )}
        </div>
      )}
    </>
  )
}

export default observer(DraggableUploadListItem)
