import { useMutation } from '@tanstack/react-query'

import { useTranslation } from 'react-i18next'

import message from 'utils/message'
import messageTemplate from 'api/messageTemplates.api'
import { PatchMessageTemplate } from 'types/MessageTemplate'
import queryClient from 'utils/queryClient'
import { ApiError } from 'types/common'
const errorMessage =
  'Message template can`t be assigned to tags and programs at the same time when delivery type is not PostAndNotification'
const useUpdateMessageTemplate = () => {
  const { t } = useTranslation()

  return useMutation((dto: PatchMessageTemplate) => messageTemplate.patchMessage(dto), {
    onSuccess: () => {
      queryClient.invalidateQueries()
      message.success(t('messageTable.messageTemplateUpdated'))
    },
    onError: (error: ApiError) => {
      const errMessage = error?.response?.data?.Message

      message.error(t<string>(`messageTable.${errMessage}`))
    },
  })
}

export default useUpdateMessageTemplate
