import { useMemo } from 'react'

import { IDailyReport } from 'types/Program'

const useAtLeastOneQuestion = (dailyReports: IDailyReport[]) => {
  const atLeastOneQuestion = useMemo(() => {
    let result = false

    if (!dailyReports?.length) return result

    dailyReports.forEach(report => {
      report.dailyReportModelForUsers.forEach(userReport => {
        if (userReport.userQuestionsAndAnswers.length || userReport.userGoalChecks.length) {
          result = true
        }
      })
    })

    return result
  }, [dailyReports])

  return atLeastOneQuestion
}

export default useAtLeastOneQuestion
