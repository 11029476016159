import { LoadingOutlined } from '@ant-design/icons'
import { Avatar, Tooltip } from 'antd'
import { FC } from 'react'

import useImageQuery from 'hooks/tanstack/queries/useImageQuery'

import { ITraineesTable } from 'components/InviteTraineesTable'

import styles from './styles.module.scss'

import defaultUserPic from 'sources/images/profile.png'

interface IProps {
  trainee: ITraineesTable
}

const SelectedTraineeMark: FC<IProps> = ({ trainee }) => {
  const { image, isLoading } = useImageQuery({ id: trainee.avatarId })

  const title =
    !!trainee.firstName && !!trainee.lastName ? `${trainee.firstName} ${trainee.lastName}` : trainee.phoneNumber

  return (
    <Tooltip title={title} placement="top" key={`${trainee.id} ${trainee.phoneNumber}`}>
      {isLoading ? (
        <div className={styles.loader}>
          <LoadingOutlined style={{ color: '#333' }} />
        </div>
      ) : (
        <Avatar className={styles.avatar} src={image || defaultUserPic} />
      )}
    </Tooltip>
  )
}

export default SelectedTraineeMark
