import { langLabels } from 'components/LanguageSelectButton'

import IsraelFlagIcon from 'sources/images/IsraelFlag.svg'
import RUFlagIcon from 'sources/images/RUFlag.svg'
import USAFlagIcon from 'sources/images/USAFlag.svg'
import { LanguagesEnum } from 'types/InitialSettings'

const getProps = (id: string) => ({
  ['data-language-item']: 'language-select-item' + id,
  id,
})

export const languages = [
  {
    label: (
      <div {...getProps('en')}>
        <img src={USAFlagIcon} alt="Usa" /> <span>{langLabels[LanguagesEnum.en]}</span>
      </div>
    ),
    value: 'en',
  },
  {
    label: (
      <div {...getProps('ru')}>
        <img src={RUFlagIcon} alt="ru" /> <span>{langLabels[LanguagesEnum.ru]}</span>
      </div>
    ),
    value: 'ru',
  },
  {
    label: (
      <div {...getProps('heMale')}>
        <img src={IsraelFlagIcon} alt="Israel" /> <span>{langLabels[LanguagesEnum.heMale]}</span>
      </div>
    ),
    value: 'heMale',
  },
  {
    label: (
      <div {...getProps('heFemale')}>
        <img src={IsraelFlagIcon} alt="Israel" /> <span>{langLabels[LanguagesEnum.heFemale]}</span>
      </div>
    ),
    value: 'heFemale',
  },
  {
    label: (
      <div {...getProps('heUndefined')}>
        <img src={IsraelFlagIcon} alt="Israel" /> <span>{langLabels[LanguagesEnum.heUndefined]}</span>
      </div>
    ),
    value: 'heUndefined',
  },
  {
    label: (
      <div {...getProps('heSababa')}>
        <img src={IsraelFlagIcon} alt="Israel" /> <span>{langLabels[LanguagesEnum.heSababa]}</span>
      </div>
    ),
    value: 'heSababa',
  },
]
