import classNames from 'classnames'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import useTraineeStatusMap from 'hooks/trainees/useTraineeStatusMap'

import { ITraineeWithInvite } from 'types/User'
import { TraineeGroopingEnum } from '../../index'

import styles from './styles.module.scss'

interface IProps {
  trainee: ITraineeWithInvite
  currentTab: TraineeGroopingEnum
}

const TraineeStatus: FC<IProps> = ({ trainee, currentTab }) => {
  const { t } = useTranslation()

  // TODO -> map this using object notation
  const { isApproved, isError, isParentalApproval, isPending, isRejected } = useTraineeStatusMap({
    currentTab,
    trainee,
  })

  return (
    <div className={styles.statusContainer}>
      <div
        className={classNames(styles.status, {
          [styles.statusPending]: isPending,
          [styles.statusSuccess]: isApproved,
          [styles.statusRejected]: isRejected,
          [styles.statusError]: isError,
          [styles.statusParentalApproval]: isParentalApproval,
        })}
      >
        {isPending && !isApproved
          ? t('programCreation.InvitationPending')
          : isApproved
          ? t('programCreation.Accepted')
          : isRejected
          ? t('programCreation.Rejected')
          : isError && !isRejected
          ? t('programCreation.InvitationErr')
          : isParentalApproval && !isRejected
          ? `${t('programCreation.NeedParentalApproval')} ${
              trainee.approvals?.[0]?.phoneNumber ? '‎\u200e' + trainee.approvals[0].phoneNumber : ''
            }`
          : ''}
      </div>
    </div>
  )
}

export default memo(TraineeStatus)
