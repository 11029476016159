import React, { FC, useMemo } from 'react'
import { Progress } from 'antd'

import { IFileLoadProgress } from 'types/Template'

import styles from '../styles.module.scss'

interface IProps {
  loadingProgress: IFileLoadProgress[]
  id: number
  isImage?: boolean
  isUploading?: boolean
}

const LoadingItem: FC<IProps> = ({ loadingProgress, id, isImage, isUploading }) => {
  const progressTargetInfo = useMemo(() => {
    const target = loadingProgress?.find(progress => progress.id === id)

    if (!target) return { name: '', percent: 0 }

    return {
      name: target.name,
      percent: target.percent,
    }
  }, [loadingProgress, id])

  if (isImage) {
    return (
      <div className={styles.imageLoader}>
        <Progress width={50} percent={progressTargetInfo.percent} type={'circle'} />
      </div>
    )
  }

  return (
    <div className={styles.loader}>
      <div style={{ flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
        {progressTargetInfo.name}
      </div>
      <div style={{ flex: 1 }}>
        <Progress width={25} percent={progressTargetInfo.percent} />
      </div>
    </div>
  )
}

export default LoadingItem
