import { IDayData } from 'types/Template'
import {
  IUnboundContentToServer,
  IUnboundContentWallPosts,
  IUnboundFile,
  IUnboundLink,
  IUnboundQuestionnnaire,
  IUnboundScenario,
  IUnboundSummary,
} from 'types/UnboundContent'
import type { ParseTemplateToServer } from './ParseTemplateToServer'

import { templateParserToServer } from './index'

export class UnboundTemplateToServerParser {
  private templateToServerParser: ParseTemplateToServer
  private programId?: number

  constructor(templateData: IDayData[], programId?: number) {
    this.templateToServerParser = templateParserToServer.setTemplateData(templateData, programId, true)
    this.programId = programId
  }

  run() {
    let contentDays = this.templateToServerParser.run() as unknown as
      | IUnboundContentToServer[]
      | IUnboundContentWallPosts[]

    const result = contentDays.map(content => {
      if (!content) return

      const res = {
        toCreateOrUpdate: {
          id: content?.id,
          files: [] as IUnboundFile[],
          summaries: [] as IUnboundSummary[],
          scenarios: [] as IUnboundScenario[],
          questionnaires: [] as IUnboundQuestionnnaire[],
          templateEntityLinks: [] as IUnboundLink[],
        },
        toCreateOrUpdateFromWallPosts: {
          id: content?.id,
          trainingProgramId: this.programId,
          programWallPostStoredFiles: [] as IUnboundFile[],
          programWallPostSummaries: [] as IUnboundSummary[],
          questionnaires: [] as IUnboundQuestionnnaire[],
          scenarios: [] as IUnboundScenario[],
          programWallPostLinks: [] as IUnboundLink[],
        },
      }

      if ('programWallPostStoredFiles' in content) {
        const key = 'toCreateOrUpdateFromWallPosts'

        content.programWallPostStoredFiles.forEach(item => {
          res[key].programWallPostStoredFiles.push(item)
        })

        content.programWallPostSummaries.forEach(item => {
          res[key].programWallPostSummaries.push(item)
        })

        content.questionnaires.forEach(item => {
          res[key].questionnaires.push(item)
        })

        content.scenarios.forEach(item => {
          res[key].scenarios.push(item)
        })

        content.programWallPostLinks.forEach(item => {
          res[key].programWallPostLinks.push(item)
        })
      } else {
        const key = 'toCreateOrUpdate'

        content.files.forEach(item => {
          res[key].files.push(item)
        })

        content.summaries.forEach(item => {
          res[key].summaries.push(item)
        })

        content.scenarios.forEach(item => {
          res[key].scenarios.push(item)
        })

        content.questionnaires.forEach(item => {
          res[key].questionnaires.push(item)
        })

        content.templateEntityLinks.forEach(item => {
          res[key].templateEntityLinks.push(item)
        })
      }

      return res
    })

    return result
  }
}
