import { t } from 'i18next'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { v4 } from 'uuid'

import useDeleteEntity from 'hooks/tools/useDeleteEntity'
import useSaveTool from 'hooks/tools/useSaveTool'
import useSaveToolWithPlanning from 'hooks/tools/useSaveToolWithPlanning'
import useToolSchedule from 'hooks/tools/useToolSchedule'
import useToolScroll from 'hooks/tools/useToolScroll'
import useToolState from 'hooks/tools/useToolState'
import useToolTags from 'hooks/tools/useToolTags'

import { useStore } from 'stores'

import {
  FollowUpActionsEnum,
  IDefaultToolProps,
  IQuestionnaireDayItem,
  IQuestionnaireMultipleChoice,
  ITagWithToolRelation,
  QuestionnaireState,
  QuestionTypeEnum,
} from 'types/Template'
import { RepeatingTypeEnum, schedulerOptionEnum } from 'types/Template/Tools'

import message from 'utils/message'
import { QuestionnaireFollowUpActionsParser } from 'utils/parseTemplate'
import checkIfNeedShedulerConfirm from 'utils/tools/checkIfNeedShedulerConfirm'
import validateFollowUpActions from 'utils/tools/validateFollowUpActions'

import ToolRevealedPanel from 'modules/TemplateModule/DayItem/DayTool/ToolSwitch/ToolRevealedPanel'
import ToolWrapper from 'modules/TemplateModule/DayItem/DayTool/ToolSwitch/ToolWrapper'
import QuestionItem from './QuestionItem'

import styles from './styles.module.scss'

let stateCopy: {
  questionMessage?: string
  questionMessages?: string[]
  isAllDaySelected: null | boolean
  schedulerValue: number
  openToolTime: moment.Moment | undefined
  closeToolTime: moment.Moment | undefined
  questionnaireState: QuestionnaireState[] | undefined
  answerIds?: number[][]
  entityTags: number[]
  daysSheduleValue: number[]
} = {
  isAllDaySelected: null,
  schedulerValue: 1,
  openToolTime: undefined,
  closeToolTime: undefined,
  questionnaireState: undefined,
  answerIds: undefined,
  entityTags: [],
  daysSheduleValue: [],
}

const QuestionnaireTool = ({
  tool,
  toolIndex,
  isDraggingItem,
  isUnbound,
  dayId,
  dayIndex,
  onDelete,
}: IDefaultToolProps) => {
  const { templateStore, userStore } = useStore()
  const { templateEntity } = templateStore

  const [isGraphNeeded, setIsGraphNeeded] = useState(false)
  const [toolData, setToolData] = useState<IQuestionnaireDayItem | null>(null)
  const [isMounted, setIsMounted] = useState(false)
  const [openToolTime, setOpenToolTime] = useState<moment.Moment>()
  const [closeToolTime, setCloseToolTime] = useState<moment.Moment>()
  const [questionnaireState, setQuestionnaireState] = useState<QuestionnaireState[]>([])
  const [isValidationError, setIsValidationError] = useState(false)
  const [canValidate, setCanValidate] = useState(false)

  const {
    isAllDaySelected,
    isEditing,
    isFirstDrop,
    isSchedulerModalVisible,
    isUpdating,
    revealedTime,
    setIsAllDaySelected,
    setIsEditing,
    setIsFirstDrop,
    setIsSchedulerModalVisible,
    setIsUpdating,
    setRevealedTime,
  } = useToolState()

  const thisTool = tool as IQuestionnaireDayItem

  const rootRef = useRef(document.getElementById('root'))

  const { handleTagsChange, selectedTagIds, setSelectedTagIds, selectedTags, entityTagsToRemove } = useToolTags({
    tags: thisTool.entityTags as ITagWithToolRelation[],
  })

  const { schedulerValue, setSchedulerValue, daysSheduleValue, setDaysScheduleValue } = useToolSchedule({
    initialSchedule: thisTool.schedulerOption,
    initialDaysSchedule: thisTool.daysSheduleValue,
  })

  const {
    addEntityToDeleting,
    onDeleteEntities,
    resetEntityIdsToDelete,
    addUniqueIdToExclude,
    resetUniqueIdToExclude,
    uniqIdsToExclude,
  } = useDeleteEntity({ isUnbound })

  const { onSave, savingLoading } = useSaveTool({ entityTagsToRemove, isUnbound, dayId })

  useEffect(() => {
    if (canValidate) {
      const element = document.querySelector('[data-scroll]')

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [canValidate])

  const checkIfEmptyState = () => {
    return !questionnaireState.every(question => {
      return Object.entries(question).every(entry => {
        const [key, value] = entry

        if (key === 'followUpAction' || key === 'followUpActionField' || key === 'answerIds') return true

        if (Array.isArray(value) && value[0] === '') {
          return false
        } else if (typeof value === 'number') {
          return value >= 0
        } else {
          return !_.isEmpty(value)
        }
      })
    })
  }

  const validateFollowUpActionsInAllQuestions = () => {
    const questionnaireFollowUpParser = new QuestionnaireFollowUpActionsParser()

    return questionnaireState.every((question, idx) => {
      if (question.type !== QuestionTypeEnum.MULTIPLE_CHOICE) return true

      const MCQuestions = question as IQuestionnaireMultipleChoice

      let actionsValid = true

      MCQuestions.followUpAction.forEach((action, idx) => {
        const field = MCQuestions.followUpActionField[idx]

        const valid = validateFollowUpActions(action, field)

        if (!valid) {
          actionsValid = false
          return
        }

        if (action === FollowUpActionsEnum.content) {
          if (+field === templateEntity.currentDay?.id || +field === thisTool.id) {
            actionsValid = false
            return
          }

          const inWallPosts = questionnaireFollowUpParser.findAvailableContentToFollowUpById(+field, true, !isUnbound)

          if (!!inWallPosts?.postType) {
            return
          }

          const inTemplate = questionnaireFollowUpParser.findAvailableContentToFollowUpById(+field, false, !isUnbound)

          if (!!inTemplate?.postType) {
            return
          }

          actionsValid = false
        }
      })

      return actionsValid
    })
  }

  const validationBeforeActions = () => {
    if (checkIfEmptyState() || isValidationError) {
      message.error(t<string>('templateCreation.allFields'))
      return true
    }
    if (!openToolTime && !isAllDaySelected) {
      message.error(t<string>('templateCreation.selectOpenTime'))
      return true
    } else if (!closeToolTime && !isAllDaySelected) {
      message.error(t<string>('templateCreation.selectCloseTime'))
      return true
    }
    if (!isAllDaySelected && moment(closeToolTime).diff(openToolTime, 'h') < 2) {
      message.error(t<string>('templateCreation.minTimeAppearing'))
      return true
    }
    if (!validateFollowUpActionsInAllQuestions()) {
      message.error(t<string>('programCreation.checkWarnings'))
      return true
    }
    if (schedulerValue === schedulerOptionEnum.specificDay && !daysSheduleValue.length) {
      message.error(t('templateCreation.selectAtLeastOneDay'))
      return true
    }
    return false
  }

  const saveToolFlagsSet = () => {
    templateStore.setIsToolEditing(false, isUnbound)

    setIsEditing(false)
    setIsFirstDrop(false)
    setIsUpdating(false)
    resetUniqueIdToExclude()
  }

  const scrollToError = () => {
    const element = document.querySelector('[data-scroll]')

    if (!element) return

    const root = document.querySelector('#root')

    if (!root) return

    const elementPositionOnView = element.getBoundingClientRect().top

    if (elementPositionOnView > 100) return

    root.scroll({
      top: root.scrollTop - Math.abs(elementPositionOnView) - 200,
      behavior: 'smooth',
    })
  }

  const handleSaveClick = async (repeatingOption?: RepeatingTypeEnum) => {
    setCanValidate(true)

    const invalid = validationBeforeActions()

    if (invalid) {
      scrollToError()
      return
    }

    if (!toolData) return

    setIsUpdating(true)

    if (checkIfNeedShedulerConfirm({ repeatingOption, schedulerValue, thisTool })) {
      return setIsSchedulerModalVisible(true)
    }

    const questionnaireStateWithAnswerIds = questionnaireState.map(question =>
      question.type === QuestionTypeEnum.CHECK_BOX || question.type === QuestionTypeEnum.MULTIPLE_CHOICE
        ? { ...question, answerIds: stateCopy.answerIds?.shift() || [] }
        : question
    )

    setQuestionnaireState(questionnaireStateWithAnswerIds)

    const uniqIds = toolData.uniqueId.filter(id => !uniqIdsToExclude.includes(id))

    const updatedTool = {
      ...toolData,
      id: toolData.id ?? -1,
      questionsData: questionnaireStateWithAnswerIds,
      openTime: openToolTime?.toISOString(true).slice(0, -6) as string,
      closeTime: closeToolTime?.toISOString(true).slice(0, -6) as string,
      isAllDaySelected,
      isGraphNeeded,
      uniqueId: questionnaireState.map((file, idx) => uniqIds[idx] || v4()),
      schedulerOption: schedulerValue,
      entityTags: selectedTags,
      position: isFirstDrop ? (isUnbound ? 0 : templateEntity.lastPosition) : toolData?.position || toolIndex,
      repeatId: toolData?.repeatId || v4(),
      daysSheduleValue,
    } as IQuestionnaireDayItem

    try {
      await onSave({ tool: updatedTool, repeatingOption, onDeleteEntities })

      saveToolFlagsSet()
      setCanValidate(false)
    } catch (e) {
      message.error(t('programCreation.smtWrong'))
    }
  }

  const { onSaveWithPlanning } = useSaveToolWithPlanning({ onSave: handleSaveClick, setIsSchedulerModalVisible })

  const handleToolStateChange = (questionState: QuestionnaireState, questionIndex: number) => {
    const stateCopy = _.cloneDeep(questionnaireState)
    stateCopy[questionIndex] = questionState

    setQuestionnaireState(stateCopy)
  }

  useEffect(() => {
    if (!isEditing) return
    const answerIdsContainer: number[][] = []
    questionnaireState.forEach(
      question =>
        (question.type === QuestionTypeEnum.CHECK_BOX || question.type === QuestionTypeEnum.MULTIPLE_CHOICE) &&
        (question as IQuestionnaireMultipleChoice)?.answerIds?.length &&
        answerIdsContainer.push((question as IQuestionnaireMultipleChoice)?.answerIds?.map(id => +id))
    )
    stateCopy.answerIds = answerIdsContainer
  }, [questionnaireState, isEditing])

  const handleToolEdit = () => {
    if (templateStore.checkIfToolEditing(!!isUnbound)) {
      message.error(t('templateCreation.completePreviousEdit'))
      return
    }

    if (!isEditing) {
      templateStore.setIsToolEditing(true, isUnbound)

      stateCopy = {
        openToolTime,
        closeToolTime,
        isAllDaySelected,
        schedulerValue,
        questionnaireState: _.cloneDeep(questionnaireState),
        entityTags: selectedTagIds,
        daysSheduleValue,
      }
    } else rollBackChanges()

    setIsEditing(prevState => !prevState)
  }

  const handleSelectSchedulerOption = (value: number) => {
    if (!toolData || schedulerValue === value || validationBeforeActions()) return

    setSchedulerValue(value)
  }

  const rollBackChanges = () => {
    if (isFirstDrop) {
      onDelete(() => setIsEditing(false))
      return
    }

    setDaysScheduleValue(stateCopy.daysSheduleValue)

    stateCopy.isAllDaySelected !== null && setIsAllDaySelected(stateCopy.isAllDaySelected)

    if (stateCopy.schedulerValue) {
      setSchedulerValue(stateCopy.schedulerValue)
      handleSelectSchedulerOption(stateCopy.schedulerValue)
    }

    setOpenToolTime(stateCopy.openToolTime)
    setCloseToolTime(stateCopy.closeToolTime)

    templateStore.setIsToolEditing(false, isUnbound)
    setIsEditing(false)

    setSelectedTagIds(stateCopy.entityTags)

    if (stateCopy.questionnaireState) {
      setQuestionnaireState(_.cloneDeep(stateCopy.questionnaireState))
    }

    resetEntityIdsToDelete()
    resetUniqueIdToExclude()
  }

  const handleQuestionDelete = (deleteIndex: number) => {
    if (toolData?.entityIds?.[deleteIndex]) {
      const filteredEntityIds = toolData.entityIds.filter((id, index) => index !== deleteIndex)
      setToolData(
        prevState =>
          prevState && {
            ...prevState,
            entityIds: filteredEntityIds,
          }
      )
    }
    const filteredQuestionnaireState = questionnaireState.filter((_, questionIndex) => {
      if (questionIndex !== deleteIndex) return true

      if (tool.uniqueId[questionIndex] && !isUnbound) {
        addUniqueIdToExclude(tool.uniqueId[questionIndex])
      }

      return false
    })

    setQuestionnaireState(filteredQuestionnaireState)
  }

  const handleQuestionAdd = () => {
    //@ts-ignore
    setQuestionnaireState(question => [...question, { type: null, question: '' }])

    if (rootRef.current && !isUnbound) {
      rootRef.current.scrollBy({ top: 300, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    setIsMounted(true)
    const questionnaireData = thisTool as IQuestionnaireDayItem

    if (!questionnaireData) return

    setToolData(questionnaireData)

    setQuestionnaireState(_.cloneDeep(questionnaireData?.questionsData))

    if (questionnaireData?.questionsData?.[0]?.type === null) {
      setIsFirstDrop(true)
      setIsEditing(true)
      templateStore.setIsToolEditing(true, isUnbound)
      return
    }
    setIsEditing(false)

    setIsAllDaySelected(!!questionnaireData?.isAllDaySelected)
    questionnaireData.openTime && setOpenToolTime(moment(questionnaireData.openTime).local())
    questionnaireData.closeTime && setCloseToolTime(moment(questionnaireData.closeTime).local())

    setIsGraphNeeded(questionnaireData.isGraphNeeded)
  }, [thisTool])

  useToolScroll({
    isMounted,
    isFirstDrop,
    uniqueId: toolData?.uniqueId,
  })

  useEffect(() => {
    if (isMounted) {
      message.destroy()
    }
  }, [userStore.language])

  useEffect(() => {
    if (!isMounted || isDraggingItem) return

    const questionnaireData = thisTool as IQuestionnaireDayItem

    if (!questionnaireData) return

    setIsGraphNeeded(questionnaireData.isGraphNeeded)
    setToolData(questionnaireData)

    setQuestionnaireState(questionnaireData.questionsData)

    setIsAllDaySelected(!!questionnaireData?.isAllDaySelected)
    questionnaireData.openTime && setOpenToolTime(moment(questionnaireData.openTime).local())
    questionnaireData.closeTime && setCloseToolTime(moment(questionnaireData.closeTime).local())
  }, [thisTool])

  return (
    <ToolWrapper
      data-container-questionnaire="container-questionnaire"
      isEditing={isEditing}
      daysValue={daysSheduleValue}
      onDaysValueChange={setDaysScheduleValue}
      handleSaveClick={() => handleSaveClick()}
      rollBackChanges={rollBackChanges}
      id={isUnbound ? dayId?.toString() || '' : toolData?.uniqueId?.[0]}
      entityId={toolData?.id}
      handleToolEdit={handleToolEdit}
      isFirstDrop={isFirstDrop}
      toolNumber={isUnbound ? dayIndex + 1 : toolIndex + 1}
      revealedAt={isAllDaySelected || openToolTime}
      isUnbound={isUnbound}
      handleSelectSchedulerOption={handleSelectSchedulerOption}
      schedulerValue={schedulerValue}
      handleTagsChange={handleTagsChange}
      selectedTagIds={selectedTagIds}
      onDelete={() => {
        onDelete(() => setIsEditing(false))
      }}
      savingLoading={savingLoading}
      handleSchedulerApplyModalOk={onSaveWithPlanning}
      isSchedulerModalVisible={isSchedulerModalVisible}
      isUpdating={isUpdating}
      setIsSchedulerModalVisible={setIsSchedulerModalVisible}
      srcTool={thisTool}
      dayId={dayId}
    >
      {questionnaireState?.map((question, questionIndex) => (
        <QuestionItem
          question={question}
          toolId={thisTool.id}
          questionIndex={questionIndex}
          handleChange={handleToolStateChange}
          handleDelete={handleQuestionDelete}
          setIsValidationError={setIsValidationError}
          isEditing={isEditing}
          questionCount={questionnaireState.length}
          addEntityToDeleting={addEntityToDeleting}
          key={`${question}-${questionIndex}`}
          canValidate={canValidate}
          isUnbound={isUnbound}
        />
      ))}
      {isEditing && (
        <div className={styles.addQuestionText} onClick={handleQuestionAdd}>
          {t<string>('templateCreation.addAnotherQuestion')}
        </div>
      )}

      {isEditing && !isUnbound && (
        <div style={{ margin: '10px 0' }} className={styles.subContent} data-sub-content-2="subContent-2">
          <ToolRevealedPanel
            isAllDaySelected={isAllDaySelected}
            isEditing={isEditing}
            onChangeCheckbox={e => setIsAllDaySelected(e.target.checked)}
            onChangeTimePicker={value => setOpenToolTime(value ?? undefined)}
            onChangeRangePicker={value => {
              if (value && value.length === 2) {
                setOpenToolTime(value[0])
                setCloseToolTime(value[1])
              }
            }}
            revealedTime={openToolTime}
            closeTime={closeToolTime}
            isRangePick={true}
            isQuestion
          />
        </div>
      )}
    </ToolWrapper>
  )
}

export default observer(QuestionnaireTool)
