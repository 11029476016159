import { uniqueId } from 'lodash'

import { ICommonUser } from 'types/Dashboard'

const parseCommonUsersData = (trainees: any, isTrainee: boolean) => {
  return trainees.map((item: any, key: number) => {
    const firstChildren = isTrainee ? item.trainerAppRelationships[0] : undefined
    const trainer =
      firstChildren?.trainer?.firstName && firstChildren?.trainer.lastName
        ? `${firstChildren?.trainer.firstName} ${firstChildren?.trainer.lastName}`
        : '–'

    return {
      ...item,
      key,
      fullName: `${item.firstName ?? '–'} ${item?.lastName ?? '–'}`,
      company: item.company ?? '–',
      email: item?.email ?? '–',
      lastActiveTime: item.lastActiveTime,
      trainingProgram: firstChildren?.trainingProgram.name,
      trainer,
      children: item.trainerAppRelationships?.slice(1).map(
        (data: any, index: number) =>
          ({
            key: index + uniqueId(),
            ...data,
            trainingProgram: data.trainingProgram.name,
            trainers: data.trainers,
          } as ICommonUser)
      ),
    }
  })
}

export default parseCommonUsersData
