import i18n from 'i18nextConf'
import { useEffect } from 'react'

import { useStore } from 'stores'

const FIVE_MIN_MS = 300000

const useLanguageFetch = () => {
  const { userStore } = useStore()

  useEffect(() => {
    const interval = setInterval(async () => {
      const lang = await userStore.getUserLanguage()

      if (!lang) return

      localStorage.setItem('i18nextLng', lang)

      if (lang === 'ru' || lang === 'en') {
        document.body.dir = 'ltr'
      } else {
        document.body.dir = 'rtl'
      }

      i18n.changeLanguage(lang)
    }, FIVE_MIN_MS)
    // notificationStore.connectToNotificationHub()

    return () => {
      clearInterval(interval)
    }
  }, [])
}

export default useLanguageFetch
