import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import LoadPage from 'components/LoadPage'
import Scraping from './Scraping'

const Activity = React.lazy(() => import('./Activity'))
const DashboardUsers = React.lazy(() => import('./DashboardUsers'))
const InternalAppPrefixes = React.lazy(() => import('./InternalAppPrefixes'))
const EventPlaces = React.lazy(() => import('./EventPlaces'))

const SuperAdmin = () => {
  return (
    <Routes>
      <Route
        path="/dashboard/users"
        element={
          <React.Suspense fallback={<LoadPage />}>
            <DashboardUsers />
          </React.Suspense>
        }
      />
      <Route
        path="/dashboard/prefixes"
        element={
          <React.Suspense fallback={<LoadPage />}>
            <InternalAppPrefixes />
          </React.Suspense>
        }
      />
      <Route
        path="/dashboard/activity"
        element={
          <React.Suspense fallback={<LoadPage />}>
            <Activity />
          </React.Suspense>
        }
      />
      <Route
        path="/dashboard/event-places"
        element={
          <React.Suspense fallback={<LoadPage />}>
            <EventPlaces />
          </React.Suspense>
        }
      />
      <Route
        path="/dashboard/scraping"
        element={
          <React.Suspense fallback={<LoadPage />}>
            <Scraping />
          </React.Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/dashboard/users" replace />} />
    </Routes>
  )
}

export default SuperAdmin
