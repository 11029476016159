import { t } from 'i18next'
import { action, makeAutoObservable, observable } from 'mobx'
import { Dispatch, SetStateAction } from 'react'

import adminApi from 'api/admin.api'
import fileApi from 'api/file.api'
import programsApi from 'api/programs.api'

import { IProgramList } from 'types/Program'
import { INumeric, IRating, IText } from 'types/questions/Questions'
import { IExtendedFile, IFileLoadProgress } from 'types/Template'

import message from 'utils/message'

export enum StatusInvitesEnum {
  Pending,
  Succeed,
  Rejected,
  Failed,
  RequireParentalApproval,
}

interface IFilesCache {
  [key: number]: Promise<IExtendedFile>
}

class Store {
  constructor() {
    makeAutoObservable(this)
  }

  @observable questionsData: INumeric[] | IText[] | IRating[] = []
  @observable userPrograms: IProgramList[] = []

  private _filesCache: IFilesCache = {}

  @observable filesLoadProgress: { fileId: number; percent: number }[] = []

  @observable programsIdsToCheckProgress: number[] = []
  @observable programsCreationProgress: { programId: number; creationProgress: number }[] = []

  @observable defaultProgramAvatarId: number | null = null

  @action
  async setDefaultProgramAvatarId() {
    const id = await programsApi.getDefaultProgramAvatar()

    if (id) {
      this.defaultProgramAvatarId = id
    }
  }

  @action
  setProgramCreationProgress = (programId: number, percent: number) => {
    const res = this.programsCreationProgress.find(progressItem => progressItem.programId === programId)

    if (res) {
      res.creationProgress = percent
      const filtered = this.programsCreationProgress.filter(progressItem => progressItem.programId !== programId)

      if (percent === 100) {
        this.programsCreationProgress = filtered
        this.programsIdsToCheckProgress = this.programsIdsToCheckProgress.filter(id => id !== programId)
      } else {
        this.programsCreationProgress = [...filtered, res]
      }

      return
    }

    this.programsCreationProgress = [...this.programsCreationProgress, { programId, creationProgress: percent }]
    return
  }

  @action
  async getUserPrograms(programId?: number | string): Promise<void | IProgramList[]> {
    try {
      const programs = await programsApi.getProgramsWhereMentor(programId)

      this.userPrograms = programs

      return programs
    } catch (e) {
      message.error(t<string>('programList.listLoadError'))
    }
  }

  @action
  async getProgramImage(
    id: number,
    isProgram?: boolean,
    setProgress?: Dispatch<SetStateAction<IFileLoadProgress[]>>,
    isAdmin?: boolean
  ): Promise<any | void> {
    if (this._filesCache[id] !== undefined) return this._filesCache[id]

    const onDownloadProgress = (progressEvent: any) => {
      if (!setProgress) return

      const percent = Math.round((progressEvent.loaded / progressEvent.total) * 100)

      setProgress(prev => {
        const thisEl = prev.find(item => item.id === id)
        const withoutThisEl = [...prev.filter(item => item.id !== id)]

        if (thisEl) {
          return [...withoutThisEl, { ...thisEl, percent }]
        }

        const fileNameHeader = progressEvent.srcElement.getResponseHeader('content-disposition')
        const fileNameUTF8 = fileNameHeader?.split(';')[2].split('=')[1].slice(7) ?? 'file'
        const fileName = decodeURIComponent(fileNameUTF8)

        return [...withoutThisEl, { id, name: fileName, percent }]
      })
    }

    this._filesCache[id] = new Promise(async (resolve, reject) => {
      try {
        const response = isAdmin
          ? await adminApi.getFile(id, onDownloadProgress)
          : await fileApi.getFile(id, onDownloadProgress)

        if (response.status === 404) return reject()

        if (!response?.data) return

        const fileNameHeader = response.headers['content-disposition']
        const fileNameUTF8 = fileNameHeader?.split(';')[2].split('=')[1].slice(7) ?? 'file'
        const fileName = decodeURIComponent(fileNameUTF8)

        if (isProgram) {
          return resolve(URL.createObjectURL(response.data) as unknown as IExtendedFile)
        }

        const file = new File([response.data], fileName) as IExtendedFile

        const urlObj = URL.createObjectURL(response.data)
        file.url = urlObj

        if (!file.type) {
          const fileNameParts = fileName.split('.')
          file.subType = fileNameParts[fileNameParts.length - 1]
        }

        return resolve(file)
      } catch (e) {
        return reject(e)
      }
    })

    return this._filesCache[id]
  }
}

export default new Store()
