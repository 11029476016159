import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Space, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { FileMessageDto } from 'api/swagger.api'
import useFileInfoQuery from 'hooks/tanstack/queries/useFileInfoQuery'
import { FileType, getFileType } from 'utils/fileTypes'
import fileApi from 'api/file.api'

import styles from './styles.module.scss'
import { ReactComponent as AudioIcon } from 'sources/images/files/audio.svg'
import { ReactComponent as CsvIcon } from 'sources/images/files/csv.svg'
import { ReactComponent as DocIcon } from 'sources/images/files/doc.svg'
import { ReactComponent as FileIcon } from 'sources/images/files/file.svg'
import { ReactComponent as PdfIcon } from 'sources/images/files/pdf.svg'
import { ReactComponent as VideoIcon } from 'sources/images/files/video.svg'
import { ReactComponent as XlsxIcon } from 'sources/images/files/xlsx.svg'

type Props = {
  file: FileMessageDto
}

const ChatMessageFileListItem: React.FC<Props> = ({ file }) => {
  const { t } = useTranslation()

  const { data, isLoading } = useFileInfoQuery(file.fileId)

  const handleFileClick = useCallback(async () => {
    if (!data) return

    try {
      const objectUrl = await fileApi.getImageById(data.id)

      if (objectUrl) {
        const a = document.createElement('a')
        a.href = objectUrl
        a.download = data.name
        a.click()
      }
    } catch (e) {}
  }, [data?.id])

  const fileIcon = useMemo(() => {
    const fileType = getFileType(data?.name) || getFileType(data?.mimeType)

    switch (fileType) {
      case FileType.Audio:
        return <AudioIcon className={styles.icon} />
      case FileType.Csv:
        return <CsvIcon className={styles.icon} />
      case FileType.Word:
        return <DocIcon className={styles.icon} />
      case FileType.Pdf:
        return <PdfIcon className={styles.icon} />
      case FileType.Video:
        return <VideoIcon className={styles.icon} />
      case FileType.Excel:
        return <XlsxIcon className={styles.icon} />
      default:
        return <FileIcon className={styles.icon} />
    }
  }, [data?.id])

  if (isLoading || !data) {
    return (
      <Space size="small">
        <LoadingOutlined />
        <Typography.Text type="secondary">{t('shared.loading')}</Typography.Text>
      </Space>
    )
  }

  return (
    <button onClick={handleFileClick} className={styles.button}>
      <Space size="small" align="center">
        <Typography.Text>{fileIcon}</Typography.Text>
        <Typography.Text>{data.name}</Typography.Text>
      </Space>
    </button>
  )
}

export default ChatMessageFileListItem
