interface Base64Obj {
  data: string
  name: string
  type: string
}

class FileTranform {
  toBase64(file: File): Promise<Base64Obj> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve({ data: reader.result as string, name: file.name, type: file.type })
      reader.onerror = error => reject(error)
    })
  }
  async fromBase64(base64Obj: Base64Obj): Promise<File> {
    const res: Response = await fetch(base64Obj.data)
    const blob: Blob = await res.blob()

    return new File([blob], base64Obj.name, { type: base64Obj.type })
  }
}

export default new FileTranform()
