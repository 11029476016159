import { useQueryClient } from '@tanstack/react-query'
import { Spin } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IScheduledNotificationCreateDto } from 'api/DTOs/scheduleNotification.dto'
import scheduleNotificationApi from 'api/scheduleNotification.api'

import useScheduledNotificationQuery from 'hooks/tanstack/queries/useScheduledNotificationQuery'
import useDebounce from 'hooks/useDebounce'

import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { IScheduledNotification, NotificationDisplayFilter } from 'types/ScheduleNotification'
import message from 'utils/message'

import Header from './Header'
import ReminderInfoModal from './ReminderInfoModal'
import ReminderItem from './ReminderItem'

import Loader from 'components/Loader'
import Pagination from 'components/Pagination'
import ReminderModal from 'components/ReminderModal'

import styles from './styles.module.scss'

interface IProps {
  programId: number
}

const RemindersList: React.FC<IProps> = ({ programId }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [initialReminderData, setInitialReminderData] = useState<IScheduledNotification | null>(null)
  const [isReminderModalVisible, setIsReminderModalVisible] = useState(false)
  const [filter, setFilter] = useState(NotificationDisplayFilter.Future)
  const [activeHistoryReminder, setActiveHistoryReminder] = useState<IScheduledNotification | null>(null)
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 300)

  const { isLoading, isError, isFetching, scheduledNotifications, propsToPaginationComponent, setPagination } =
    useScheduledNotificationQuery({
      filter,
      programId,
      search: debouncedSearch,
    })

  const onDelete = async (id: number) => {
    try {
      await scheduleNotificationApi.deleteScheduledNotification(id)
      message.success(t<string>('programSettings.reminderDeleteSuccess'))
      queryClient.invalidateQueries([QueryKeysEnum.SCHEDULED_NOTIFICATIONS])
    } catch (e) {
      message.error(t<string>('programCreation.smtWrong'))
    }
  }

  const onCreate = async (data: IScheduledNotificationCreateDto) => {
    const ok = await scheduleNotificationApi.createScheduledNotification(data)

    if (!ok) return false

    queryClient.invalidateQueries([QueryKeysEnum.SCHEDULED_NOTIFICATIONS])
    setIsReminderModalVisible(false)

    return true
  }

  const onEdit = async (data: IScheduledNotificationCreateDto, id: number) => {
    try {
      await scheduleNotificationApi.editScheduledNotification(data, id)
      message.success(t('programSettings.reminderEditSuccess'))

      queryClient.invalidateQueries([QueryKeysEnum.SCHEDULED_NOTIFICATIONS])
      setIsReminderModalVisible(false)

      return true
    } catch (e) {
      const status = e.response.status
      if (status === 400) {
        message.error(t('dashboard.incorrectReminderTime'))
      } else if (status === 404) {
        message.error(t('programSettings.reminderAlreadySent'))
      } else {
        message.error(t('programCreation.smtWrong'))
      }

      return false
    }
  }

  return (
    <>
      <div className={styles.container}>
        <Header
          onCreateClick={() => setIsReminderModalVisible(true)}
          filter={filter}
          onChangeFilter={val => {
            setFilter(val)
            setPagination(prev => ({ ...prev, pageNumber: 1 }))
          }}
          search={search}
          setSearch={search => {
            setSearch(search)
            setPagination(prev => ({ ...prev, pageNumber: 1 }))
          }}
        />
        <div className={styles.remindersContainer}>
          {isError ? (
            <div className={styles.message}>{t('programCreation.smtWrong')}</div>
          ) : isLoading ? (
            <div className={styles.loading}>
              <Loader />
            </div>
          ) : (
            <Spin spinning={isFetching}>
              {scheduledNotifications?.length ? (
                scheduledNotifications.map(data => (
                  <ReminderItem
                    data={data}
                    onDelete={onDelete}
                    key={data.id}
                    setIsReminderModalVisible={setIsReminderModalVisible}
                    setInitialReminderData={setInitialReminderData}
                    setActiveHistoryReminder={setActiveHistoryReminder}
                    isHistory={filter === NotificationDisplayFilter.History}
                  />
                ))
              ) : (
                <div className={styles.message}>
                  {t(debouncedSearch ? 'programSettings.noResults' : 'programSettings.reminderEmpty')}
                </div>
              )}
            </Spin>
          )}
        </div>
        {!isError && !isLoading && <Pagination {...propsToPaginationComponent} />}
      </div>
      <ReminderModal
        visible={isReminderModalVisible}
        setVisible={setIsReminderModalVisible}
        onCreate={onCreate}
        onEdit={onEdit}
        initialReminderData={initialReminderData}
        setInitialReminderData={setInitialReminderData}
        programId={programId}
      />
      <ReminderInfoModal
        activeHistoryReminder={activeHistoryReminder}
        onCancel={() => setActiveHistoryReminder(null)}
      />
    </>
  )
}

export default RemindersList
