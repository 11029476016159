import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useMentorTagsQuery from 'hooks/tanstack/queries/useMentorTagsQuery'

import CustomSelect from 'components/CustomComponents/CustomSelect'

import styles from './styles.module.scss'

interface IProps {
  handleTagsChange: (ids: number[]) => void
  selectedTagIds: number[]
}

const TagsSelector: FC<IProps> = ({ handleTagsChange, selectedTagIds }) => {
  const { t } = useTranslation()

  const { isError, isLoading, tags, fromMoreThanOneCompany } = useMentorTagsQuery()

  const options = useMemo(() => {
    return tags.map(tag => ({
      label: fromMoreThanOneCompany ? `${tag.name} (${tag.companyName})` : tag.name,
      value: tag.id,
    }))
  }, [tags, fromMoreThanOneCompany])

  if (!tags.length || isLoading) return <></>

  return (
    <>
      <div className={styles.title}>{t<string>('companyPage.tags')}</div>
      <CustomSelect
        value={selectedTagIds}
        options={options}
        filterOption={true}
        optionFilterProp="label"
        mode="multiple"
        className={styles.select}
        onChange={handleTagsChange}
        placeholder={t<string>('companyPage.selectTags')}
      />
    </>
  )
}

export default TagsSelector
