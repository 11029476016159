import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import QuickProgramModal from 'containers/Private/Mentor/ProgramList/Modals/QuickProgramModal'

import CustomButton from 'components/CustomComponents/CustomButton'

import styles from './styles.module.scss'

import { ReactComponent as PlusSign } from 'sources/images/PlusSign.svg'

interface IProps {
  isLoading?: boolean
  type?: 'default' | 'link'
}

const CreateProgramBtn: FC<IProps> = ({ isLoading, type }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <>
      {type === 'link' ? (
        <CustomButton icon={<PlusCircleOutlined />} onClick={() => navigate('/programs/create')} type="link">
          {t<string>('templateList.createFirst')}
        </CustomButton>
      ) : (
        <Dropdown
          menu={{
            items: [
              {
                key: '1',
                label: t<string>('programList.newProgram'),
                onClick: e => {
                  e.domEvent.stopPropagation()
                  navigate('/programs/create')
                },
              },
              {
                key: '2',
                label: t<string>('programList.quickProgram'),
                onClick: e => {
                  e.domEvent.stopPropagation()
                  setModalVisible(true)
                },
              },
            ],
          }}
          disabled={isLoading}
          overlayClassName={styles.more}
          trigger={['hover']}
        >
          <Button
            icon={<PlusSign style={{ marginInlineEnd: 7, color: '#232631' }} />}
            loading={isLoading}
            data-program-list-add-program-btn="program-list-add-program-btn"
            id="program-list-add-program-btn"
            className={styles.btn}
          >
            {t<string>('programCreation.createProgram')}
          </Button>
        </Dropdown>
      )}
      <QuickProgramModal visible={modalVisible} setVisible={setModalVisible} />
    </>
  )
}

export default CreateProgramBtn
