import { useInfiniteQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import chatApi, { GetMessagesFromChatParams } from 'api/chat.api'
import { TextMessageDtoPageResponse } from 'api/swagger.api'
import { QueryKeysEnum } from 'types/QueryKeysEnum'

import message from 'utils/message'

const useChatMessagesQuery = (params: GetMessagesFromChatParams) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return useInfiniteQuery<TextMessageDtoPageResponse, AxiosError>({
    keepPreviousData: true,
    queryKey: [
      QueryKeysEnum.CHAT_MESSAGES,
      params.chatRelationshipId,
      params.returnReadMessage,
      params.startTextMessageId,
      params.endTextMessageId,
    ],
    getNextPageParam: ({ pageNumber = 1, totalPages = 1 }) => (pageNumber < totalPages ? pageNumber + 1 : undefined),
    queryFn: ({ pageParam = 1 }) => chatApi.getMessagesFromChat({ ...params, pageNumber: pageParam }),
    onError: error => {
      message.error(t<string>('programCreation.smtWrong'))
      navigate('/dashboard')
    },
  })
}

export default useChatMessagesQuery
