import ReactDOM from 'react-dom/client'

import './config'

import 'antd/dist/antd.css'
import 'sources/styles/styles.scss'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import App from './containers/App'

const domNode = document.getElementById('root')

if (!domNode) {
  throw new Error('DOM node is not found')
}

const root = ReactDOM.createRoot(domNode)

root.render(<App />)
