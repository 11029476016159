import { t } from 'i18next'
import { memo, useState } from 'react'

import useTemplateListQuery from 'hooks/tanstack/queries/useTemplateListQuery'
import useRedirectToOneItem from 'hooks/useRedirectToOneItem'

import { TEMPLATES_ON_PAGE } from 'utils/const'

import { ITemplate } from 'types/Template'

import TemplateItem from './TemplateItem'

import CopyTemplateModal from 'components/CopyTemplateModal'
import CreateTemplateBtn from 'components/CreateTemplateBtn'
import Loader from 'components/Loader'
import NoItemsInList from 'components/NoItemsInList'
import PageContainer from 'components/PageContainer'
import Pagination from 'components/Pagination'
import SearchBar from 'components/SearchBar'

import styles from './styles.module.scss'

const TemplateList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentTemplateDropdownOpened, setCurrentTemplateDropdownOpened] = useState(0)

  const { templates, setPageNumber, isLoading, pagination, debouncedSearch, search, setSearch } = useTemplateListQuery()

  useRedirectToOneItem<ITemplate>({
    items: debouncedSearch ? [] : templates || [],
    pageUrl: '/template/',
    total: pagination?.totalItems || 0,
  })

  return (
    <PageContainer>
      <CopyTemplateModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        currentTemplateDropdownOpened={currentTemplateDropdownOpened}
      />

      {!templates?.length && isLoading ? (
        <div className={styles.loaderContainer} data-loader-container="loader-container">
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.addTemplateTop} data-add-template-top="add-template-top">
            <div className={styles.titleWithSearch}>
              <div className={styles.listTitle}>{t<string>('templateList.myTemplates')}</div>
              <SearchBar
                value={search}
                placeholder={t<string>('templateList.searchTemplate')}
                onChangeCustom={value => setSearch(value)}
                data-template-list-search="template-list-search"
                id="template-list-search"
                style={{
                  width: 300,
                }}
              />
            </div>
            <CreateTemplateBtn isLoading={isLoading} />
          </div>

          {templates?.length ? (
            <div className={styles.templateListContainer}>
              {templates.map(template => (
                <TemplateItem
                  key={template.id}
                  template={template}
                  currentTemplateDropdownOpened={currentTemplateDropdownOpened}
                  setCurrentTemplateDropdownOpened={setCurrentTemplateDropdownOpened}
                  setIsModalVisible={setIsModalVisible}
                />
              ))}
            </div>
          ) : (
            <NoItemsInList
              message={
                debouncedSearch ? t<string>('templateList.noTemplatesReq') : t<string>('dashboard.thereIsNoTemplates')
              }
              showActionButton={!debouncedSearch}
              type="templates"
            />
          )}

          {pagination && pagination.totalItems > TEMPLATES_ON_PAGE && (
            <Pagination
              currentPage={pagination.pageNumber}
              totalItems={pagination.totalItems}
              size={pagination.pageLength}
              onPageChange={setPageNumber}
              loading={!templates?.length}
            />
          )}
        </>
      )}
    </PageContainer>
  )
}

export default memo(TemplateList)
