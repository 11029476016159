import { FC } from 'react'

import styles from './styles.module.scss'

interface IProps {
  label: string
  Icon: () => JSX.Element
}

const MenuItem: FC<IProps> = ({ label, Icon }) => {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <Icon />
      </div>
      {label}
    </div>
  )
}

export default MenuItem
