import { CloseOutlined } from '@ant-design/icons'
import { message as antDMessage } from 'antd'
import cn from 'classnames'
import { t } from 'i18next'
import { createElement } from 'react'

import { ReactComponent as ErrorMessageIcon } from 'sources/images/message/ErrorMessageIcon.svg'
import { ReactComponent as InfoMessageIcon } from 'sources/images/message/InfoMessageIcon.svg'
import { ReactComponent as SuccessMessageIcon } from 'sources/images/message/SuccessMessageIcon.svg'
import { ReactComponent as WarningMessageIcon } from 'sources/images/message/WarningMessageIcon.svg'

import styles from 'sources/styles/customMessage.module.scss'

const getContent = (content: string) =>
  createElement(
    'div',
    { className: styles.content },
    content,
    createElement(CloseOutlined, { onClick: () => antDMessage.destroy() })
  )

const message = {
  success: (content: string) =>
    antDMessage.success({
      className: cn(styles.customMessage, styles.success),
      icon: createElement(SuccessMessageIcon),
      content: getContent(content),
    }),
  error: (content: string) =>
    antDMessage.error({
      className: cn(styles.customMessage, styles.error),
      icon: createElement(ErrorMessageIcon),
      content: getContent(content),
    }),
  warn: (content: string) =>
    antDMessage.error({
      className: cn(styles.customMessage, styles.warn),
      icon: createElement(WarningMessageIcon),
      content: getContent(content),
    }),
  info: (content: string) =>
    antDMessage.error({
      className: cn(styles.customMessage, styles.info),
      icon: createElement(InfoMessageIcon),
      content: getContent(content),
    }),
}
antDMessage.config({
  maxCount: 2,
})

export const someError = () => message.error(t<string>('programCreation.smtWrong'))

export default { ...antDMessage, ...message }
