import { RiseOutlined } from '@ant-design/icons'
import { Progress, Tag } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useGetProgramCreationProgress from 'hooks/programCreationProgress/useGetProgramCreationProgress'

import { useStore } from 'stores'

import { DEFAULT_PRIMARY_COLOR } from 'utils/const'
import dateTime from 'utils/dateTime'

import { IProgramList } from 'types/Program'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomDropdown from 'components/CustomComponents/CustomDropdown'
import ProgramAvatar from 'components/ProgramAvatar'

import styles from './styles.module.scss'
import moment from 'moment-timezone'
interface IProps {
  program: IProgramList
  menu: JSX.Element
  title: string
  subtitle?: string
  isBigSubtitle?: boolean
  isFromProgramDetail?: boolean
}

const ProgramInfo: FC<IProps> = ({ program, menu, isBigSubtitle, isFromProgramDetail, title, subtitle }) => {
  const { t } = useTranslation()

  const {
    currentDay,
    daysAmount,
    trainingProgramAvatarId,
    id,
    numberOfStudent,
    description,
    name,
    uniqueId,
    goal,
    sortingOption,
    companyId,
    isForAnonymous,
    isInvisible,
    numberOfPosts,
    lastPublished,
  } = program

  const { userStore } = useStore()

  const isPublic = userStore.isForAnonSubdomain ? program.isForAnonymous : program.isPublic

  const navigate = useNavigate()

  const progress = useGetProgramCreationProgress(id)

  const programInCreation = progress !== undefined && progress !== -1 && progress < 100
  const fixedCurrentDay = currentDay > daysAmount ? daysAmount : currentDay
  const israelTime = moment.utc(lastPublished).format()

  return (
    <div className={styles.program}>
      <div className={styles.programImage}>
        <ProgramAvatar
          trainingProgramAvatarId={+trainingProgramAvatarId}
          programDetails={{
            id,
            description: description || '',
            goal,
            isPublic,
            name,
            uniqueId,
            sortingOption,
            companyId,
            isForAnonymous,
            isInvisible,
          }}
          withActions={isFromProgramDetail}
        />
      </div>
      <div className={styles.body}>
        <div className={styles.topLine}>
          <div className={styles.text}>
            <div className={styles.programTitle}>
              {!!title && <span>{title}</span>}
              <div
                className={classNames(styles.isPublic, {
                  [styles.isPublicNoMargin]: !title,
                })}
              >
                {isPublic ? (
                  <Tag className={classNames(styles.tag, styles.public)}>{t('programCreation.public')}</Tag>
                ) : (
                  <Tag className={classNames(styles.tag, styles.private)}>{t('programCreation.private')}</Tag>
                )}
              </div>
            </div>
            {!!subtitle && (
              <div
                className={classNames(styles.subtitle, {
                  [styles.subtitleBig]: isBigSubtitle,
                })}
              >
                {subtitle}
              </div>
            )}
          </div>
          <div className={styles.progressBlock}>
            <div className={styles.programProgressBar}>
              <Progress
                percent={(fixedCurrentDay / +daysAmount) * 100 || 0}
                showInfo={false}
                className={styles.progress}
                strokeColor={userStore.brandData?.primaryColor || DEFAULT_PRIMARY_COLOR}
              />
              {fixedCurrentDay}/{daysAmount} {t('programList.daysCompleted')}
            </div>
            <div className={styles.menuDropdown}>
              <CustomDropdown overlay={menu} size={28} placement="bottom" />
            </div>
          </div>
        </div>

        <div className={styles.bottomLine}>
          <div className={styles.programMainInfo}>
            <div className={styles.programInfoItem}>
              <div>ID</div>
              <div>{id}</div>
            </div>
            <div className={styles.programInfoItem}>
              <div>{t('programCreation.days')}</div>
              <div>{daysAmount}</div>
            </div>
            <div className={styles.programInfoItem}>
              <div>{t('programList.trainees')}</div>
              <div>{numberOfStudent}</div>
            </div>
            {!!numberOfPosts && (
              <div className={styles.programInfoItem}>
                <div>{t('programList.postsNum')}</div>
                <div>{numberOfPosts}</div>
              </div>
            )}
            {!!lastPublished && (
              <div className={styles.programInfoItem}>
                <div>{t('programList.lastPublished')}</div>
                <div>{dateTime.dateWithTime(israelTime)}</div>
              </div>
            )}
          </div>

          <div className={styles.btnOrPercentsContainer}>
            {programInCreation ? (
              <>
                {isFromProgramDetail ? (
                  <></>
                ) : (
                  <Progress type="circle" percent={progress} width={40} strokeWidth={10} />
                )}
              </>
            ) : (
              <CustomButton
                icon={<RiseOutlined />}
                size="small"
                onClick={e => {
                  e.stopPropagation()
                  navigate('/report/' + id)
                }}
                data-dashboard-report-btn="dashboard-report-btn"
                id="dashboard-report-btn"
                className={styles.btn}
              >
                {t('programList.dailyReport')}
              </CustomButton>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(ProgramInfo)
