import { UploadFile } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import fileApi from 'api/file.api'
import s3Api from 'api/s3.api'

import { useStore } from 'stores'

import message from 'utils/message'

interface ICompanyAvatarProps {
  avatarId: number | null
  s3Key?: string
  isAdmin?: boolean
}

const useCompanyAvatar = ({ avatarId, isAdmin, s3Key }: ICompanyAvatarProps) => {
  const { programStore, dashboardStore } = useStore()

  const [logo, setLogo] = useState('')
  const [logoLoading, setLogoLoading] = useState(false)
  const [newLogoToUpload, setNewLogoToUpload] = useState<UploadFile<any> | null>(null)

  const { t } = useTranslation()

  useEffect(() => {
    const getLogo = async () => {
      try {
        if (s3Key) {
          const res = await s3Api.getFileFromS3(s3Key)

          if (!res) return

          const fileUrl = URL.createObjectURL(res)

          setLogo(fileUrl)

          return
        }

        if (!avatarId) return

        setLogoLoading(true)

        const res = await programStore.getProgramImage(avatarId, true, undefined, isAdmin)

        if (!res) return

        if (typeof res === 'string') {
          setLogo(res)
        } else {
          const fileUrl = URL.createObjectURL(res)
          setLogo(fileUrl)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLogoLoading(false)
      }
    }

    getLogo()

    return () => {
      setLogo('')
      setNewLogoToUpload(null)
    }
  }, [avatarId, isAdmin])

  const uploadCompanyLogo = async () => {
    if (!newLogoToUpload) return null

    const formData = new FormData()

    formData.append('pictures', newLogoToUpload as unknown as Blob)

    if (isAdmin) {
      const response = await dashboardStore.uploadImagesByAdmin(formData)

      if (!response?.data?.[0]?.id) {
        message.error(t<string>('programCreation.smtWrong'))
        return
      }

      return response.data[0].id
    } else {
      const imageId = await fileApi.uploadImage(formData)

      if (!imageId) {
        message.error(t<string>('programCreation.smtWrong'))
        return
      }

      return imageId
    }
  }

  return {
    logo,
    setLogo,
    newLogoToUpload,
    setNewLogoToUpload,
    logoLoading,
    uploadCompanyLogo,
  }
}

export default useCompanyAvatar
