import { Progress } from 'antd'
import cn from 'classnames'
import { t } from 'i18next'
import { FC } from 'react'

import styles from '../styles.module.scss'

interface IProps {
  percent: number
  isImage?: boolean
  fileName?: string
  isVideo?: boolean
}

const UploadingItem: FC<IProps> = ({ percent, isImage, fileName, isVideo }) => {
  if (isImage)
    return (
      <div className={styles.imageLoader}>
        <Progress width={50} percent={percent} type={'circle'} />
        <div>{t<string>('templateCreation.uploadingFile')}</div>
        <div className={styles.filename}>{fileName || ''}</div>
      </div>
    )

  return (
    <div
      className={cn(styles.loader, {
        [styles.videoLoader]: isVideo,
      })}
    >
      {isVideo ? (
        <>
          <div>
            <Progress width={45} percent={percent} type="circle" />
          </div>
          <div>{t<string>('templateCreation.uploadingFile')}</div>
          <div>{fileName || ''}</div>
        </>
      ) : (
        <>
          <div style={{ flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {fileName || ''}
          </div>
          <div style={{ flex: 1 }}>
            <Progress width={25} percent={percent} type={isVideo ? 'circle' : 'line'} />
          </div>
        </>
      )}
    </div>
  )
}

export default UploadingItem
