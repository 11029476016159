import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

import wallPostApi from 'api/wallPost.api'

import { IProgramContent } from 'types/Template'

import transformPostTypeToText from 'utils/transformPostTypeToText'

interface IProps {
  programId: number | null
  date?: moment.Moment | null
  time?: moment.Moment | null
  canRequest: boolean
}

const useContentForActions = ({ programId, date, time, canRequest }: IProps) => {
  const [availableContentInPosts, setAvailableContentInPosts] = useState<IProgramContent[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getWallPost = async () => {
      if (!programId || !canRequest || !date || !time) return

      try {
        setLoading(true)

        const dateString = date.toISOString().split('T')[0]
        const timeString = time.toISOString().split('T')[1]

        const content = await wallPostApi.getProgramContent(programId, `${dateString}T${timeString}`)

        setAvailableContentInPosts(content)
      } finally {
        setLoading(false)
      }
    }

    getWallPost()
  }, [programId, canRequest, date, time])

  const actionContentOptions = useMemo(() => {
    if (!availableContentInPosts.length) return []

    return availableContentInPosts.map(content => ({
      label: content.title
        ? `${content.title} (${content.postId})`
        : `Content ${content.postId} • ${transformPostTypeToText(content.postType)}`,
      value: `${content.postId}-${transformPostTypeToText(content.postType)}`,
    }))
  }, [availableContentInPosts])

  return { actionContentOptions, loading }
}

export default useContentForActions
