import React from 'react'
import ExcelUpload from './ExcelUpload'
import styles from './styles.module.scss'
import PageContainer from 'components/PageContainer'
import { useTranslation } from 'react-i18next'

const UploadFilePage = () => {
  const { t } = useTranslation()
  return (
    <PageContainer>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>{t('uploadFile.uploadFile')}</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <ExcelUpload />
        </div>
      </div>
    </PageContainer>
  )
}

export default UploadFilePage
