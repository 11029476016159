import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { useStore } from 'stores'

import TemplateModule from 'modules/TemplateModule'

const TemplateCreation = () => {
  const { templateStore } = useStore()

  useEffect(() => {
    return () => {
      templateStore.setTemplateRedirectionInfo(null)
    }
  }, [])

  return <TemplateModule isCreation />
}

export default observer(TemplateCreation)
