import { Menu, MenuProps } from 'antd'
import classNames from 'classnames'

import styles from './styles.module.scss'

const CustomMenu = ({ className, ...rest }: MenuProps) => {
  return <Menu {...rest} className={classNames(styles.menu, className)} />
}

export default CustomMenu
