import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { PostsSortingOptionsEnum } from 'types/Program'

import CustomSelect from 'components/CustomComponents/CustomSelect'

import styles from './styles.module.scss'

interface IProps {
  sortingOption: PostsSortingOptionsEnum
  setSortingOption: Dispatch<SetStateAction<PostsSortingOptionsEnum>>
}

const PostsSortingOptionSelector: FC<IProps> = ({ setSortingOption, sortingOption }) => {
  const { t } = useTranslation()

  const sortingOptions = [
    { value: PostsSortingOptionsEnum.RevealDate, label: t<string>('programDetails.revealDate') },
    { value: PostsSortingOptionsEnum.EventDay, label: t<string>('programDetails.eventDay') },
  ]

  return (
    <div className={styles.container}>
      <CustomSelect
        value={sortingOption}
        options={sortingOptions}
        onChange={setSortingOption}
        label={t<string>('programDetails.sortPostsBy')}
        className={styles.selector}
      />
    </div>
  )
}

export default PostsSortingOptionSelector
