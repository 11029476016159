import {
  IQuestionnaireDayItem,
  IQuestionnaireMultipleChoice,
  QuestionTypeEnum,
  QuestionnaireState,
  planingToolTypesEnum,
  planningToolsType,
} from 'types/Template'

import { QuestionnaireFollowUpActionsParser } from './index'

export class ParseTemplateUtil {
  constructor() {}

  private followUpActionParser = new QuestionnaireFollowUpActionsParser()

  public generateJsonForWeb(tool: planningToolsType, fileIndex?: number) {
    return JSON.stringify({
      fromDay: tool.fromDay,
      uniqueId: fileIndex !== void 0 ? tool.uniqueId[fileIndex] : tool.uniqueId,
      schedulerOption: tool.schedulerOption,
      daysOfWeek: tool.daysSheduleValue,
    })
  }

  public getCorrectRevealedTime(tool: planningToolsType, currentDay: string) {
    if (tool.type !== planingToolTypesEnum.QUESTIONNAIRE) {
      return (currentDay.split('T')[0] + 'T' + tool?.revealedTime?.split('T')[1]) as string
    }

    return ''
  }

  private getChoiceQuestionModel(
    question: IQuestionnaireMultipleChoice,
    tool: IQuestionnaireDayItem,
    questionIndex: number,
    isForExistingProgram?: boolean,
    withFollowUpActions?: boolean,
    isUnbound?: boolean
  ) {
    const answerTool = question
    const answerMessageIds = tool?.questionMessageIds as number[]

    const fieldNameForAnswerOptions = isForExistingProgram ? 'answerOptions' : 'templateAnswerOptions'

    const answerVariants = answerTool.options.map((answerText, answerIndex) => ({
      id: answerTool.answerIds?.[answerIndex] ?? -1,
      answerText,
      questionId: answerMessageIds?.[questionIndex] ?? -1,
    }))

    return [
      {
        id: answerMessageIds?.[questionIndex] ?? -1,
        questionMessage: question.question,
        [fieldNameForAnswerOptions]: withFollowUpActions
          ? this.followUpActionParser.fromClientToServer(answerVariants, question, !!isForExistingProgram, !isUnbound)
          : answerVariants,
      },
    ]
  }

  public getQuestionsInQuestionnaire(
    question: QuestionnaireState,
    questionIndex: number,
    tool: IQuestionnaireDayItem,
    isForExistingProgram?: boolean,
    isUnbound?: boolean
  ) {
    switch (question.type) {
      case QuestionTypeEnum.RATING:
        const questionMessageIdsRating = tool?.questionMessageIds as number[]
        // @ts-ignore
        const ratingTool = question as IQuestionnaireRating

        if (!Array.isArray(ratingTool.question)) {
          return [
            {
              id: questionMessageIdsRating?.[questionIndex] ?? -1,
              questionMessage: ratingTool.question,
            },
          ]
        }

        return ratingTool.question.map((questionMessage: string) => ({
          id: questionMessageIdsRating?.[questionIndex] ?? -1,
          questionMessage,
        }))

      case QuestionTypeEnum.MULTIPLE_CHOICE:
        return this.getChoiceQuestionModel(
          question as IQuestionnaireMultipleChoice,
          tool,
          questionIndex,
          isForExistingProgram,
          true,
          isUnbound
        )

      case QuestionTypeEnum.CHECK_BOX:
        return this.getChoiceQuestionModel(
          question as IQuestionnaireMultipleChoice,
          tool,
          questionIndex,
          isForExistingProgram
        )

      default:
        const questionMessageIds = tool?.questionMessageIds as number[]
        return [
          {
            id: questionMessageIds?.[questionIndex] ?? -1,
            questionMessage: question.question,
          },
        ]
    }
  }
}
