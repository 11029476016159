const mergeTextAndVariable = (text: string, variable: string) => {
  if (!text.length) {
    return variable
  }

  if (text[text.length - 1] === ' ') {
    return text + variable
  }

  return text + ' ' + variable
}

export default mergeTextAndVariable
