import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { IScheduledNotificationCreateDto } from 'api/DTOs/scheduleNotification.dto'

import { IScheduledNotification } from 'types/ScheduleNotification'

import ReminderModule from 'modules/ReminderModule'

import CustomModal from 'components/CustomComponents/CustomModal'

interface IProps {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  onCreate: (data: IScheduledNotificationCreateDto) => Promise<boolean>
  onEdit: (data: IScheduledNotificationCreateDto, id: number) => Promise<boolean>
  programId: number | null
  initialReminderData: IScheduledNotification | null
  setInitialReminderData: Dispatch<IScheduledNotification | null>
  toTraineeId?: number | null
}

const ReminderModal: FC<IProps> = ({
  visible,
  setVisible,
  onCreate,
  onEdit,
  programId,
  initialReminderData,
  setInitialReminderData,
  toTraineeId,
}) => {
  const { t } = useTranslation()

  const onCancel = useCallback(() => setVisible(false), [])

  return (
    <CustomModal
      title={t('programSettings.sendNotification')}
      footer={null}
      open={visible}
      onCancel={onCancel}
      destroyOnClose
    >
      <ReminderModule
        onCancel={onCancel}
        visible={true}
        onCreate={onCreate}
        onEdit={onEdit}
        programId={programId}
        initialReminderData={initialReminderData}
        setInitialReminderData={setInitialReminderData}
        toTraineeId={toTraineeId}
        withProgramSelector={!!toTraineeId}
      />
    </CustomModal>
  )
}

export default ReminderModal
