import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import templatesApi from 'api/templates.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import customConfirm from 'utils/customConfirm'
import message from 'utils/message'

interface IProps {
  onSuccess?: () => void
}

const useDeleteTemplateMutation = ({ onSuccess }: IProps) => {
  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const { mutateAsync } = useMutation({
    mutationFn: async (id: string | number) => {
      await templatesApi.deleteTemplate(+id)
    },
    onSuccess: async () => {
      message.success(t('templateCreation.templateSuccessfulDeleted'))

      queryClient.invalidateQueries([QueryKeysEnum.TEMPLATES])

      if (onSuccess) {
        onSuccess()
      }
    },
    onError(e: any) {
      const { StatusCode, Message } = e.response.data

      if (
        StatusCode === 400 &&
        Message &&
        (Message.includes('has training program') || Message.includes('it is being used'))
      ) {
        const programs = Message.split("'")[1]

        message.error(
          t('templateCreation.deleteTemplateError', {
            programs,
          })
        )

        return false
      }

      message.error(t('programCreation.smtWrong'))
      return false
    },
  })

  async function handleTemplateDelete(id: number) {
    customConfirm({
      title: <span>{t<string>('templateCreation.confirmDelete')}</span>,
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        await mutateAsync(id)
      },
      onCancel() {},
      cancelText: t<string>('programCreation.modalCancel'),
      okText: t<string>('programCreation.modalOK'),
    })
  }

  return handleTemplateDelete
}

export default useDeleteTemplateMutation
