import { makeAutoObservable } from 'mobx'

import programsApi from 'api/programs.api'
import templatesApi from 'api/templates.api'

import { IGoal, IGoalToAction } from 'types/Goal'

class Store {
  constructor() {
    makeAutoObservable(this)
  }

  templateGoals: IGoal[] = []

  async setWallPostsProgramGoals(programId: number) {
    try {
      const goals = await programsApi.getProgramGoals(programId)

      this.templateGoals = goals
    } catch (e) {
      console.log(e)
    }
  }

  async setTemplateGoals(templateId: number) {
    try {
      const goals = await templatesApi.getTemplateGoals(templateId)

      this.templateGoals = goals
    } catch (e) {
      console.log(e)
    }
  }

  async saveTempalteGoals({
    id,
    templateGoalIdsToRemove,
    templateGoalsToCreate,
    templateGoalsToUpdate,
  }: IGoalToAction) {
    try {
      if (templateGoalIdsToRemove.length) {
        await Promise.all([templateGoalIdsToRemove.map(id => templatesApi.deleteTemplateGoal(id))])
      }
      if (templateGoalsToCreate.length) {
        await templatesApi.createTemplateGoals(id, templateGoalsToCreate)
      }
      if (templateGoalsToUpdate.length) {
        for (let i = 0; i < templateGoalsToUpdate.length; i++) {
          const item = templateGoalsToUpdate[i]

          await templatesApi.editTemplateGoal(item.id, item)
        }
      }

      await this.setTemplateGoals(id)
    } catch (e) {
      console.log('e', e)
    }
  }

  async saveProgramGoals({ id, templateGoalIdsToRemove, templateGoalsToCreate, templateGoalsToUpdate }: IGoalToAction) {
    try {
      if (templateGoalIdsToRemove.length) {
        await Promise.all([templateGoalIdsToRemove.map(id => programsApi.deleteProgramGoal(id))])
      }
      if (templateGoalsToCreate.length) {
        await programsApi.createProgramGoals(id, templateGoalsToCreate)
      }
      if (templateGoalsToUpdate.length) {
        for (let i = 0; i < templateGoalsToUpdate.length; i++) {
          const item = templateGoalsToUpdate[i]

          await programsApi.editProgramGoal(item.id, item)
        }
      }

      await this.setWallPostsProgramGoals(id)
    } catch (e) {
      console.log('e', e)
    }
  }
}

export default new Store()
