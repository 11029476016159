import { useMutation, useQueryClient } from '@tanstack/react-query'
import { message } from 'antd'
import messageTemplate from 'api/messageTemplates.api'
import { useTranslation } from 'react-i18next'
import { ApiError } from 'types/common'

const usePostMessageClickMutation = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(
    ({ templateId, userId }: { templateId: number; userId: number }) =>
      messageTemplate.postMessageClick(templateId, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries()
      },
      onError: (error: ApiError) => {
        const errMessage = error?.response?.data?.Message
        console.log(errMessage)
        message.error(t<string>(`messageTable.${errMessage}`))
      },
    }
  )
}

export default usePostMessageClickMutation
