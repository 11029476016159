import { UploadFile } from 'antd'
import { t } from 'i18next'

import { audioMimeTypes } from 'utils/const'
import message from 'utils/message'
import preventSecondCall from './preventSecondCall'

const mimeWhitelist = [...audioMimeTypes]
const maxFileSize = 1024 * 1024 * 10 // 10Mb

const pickAudioValidation = (fileList: UploadFile<any>[]) => {
  const currentVideoIds = fileList.map(file => file.uid)

  if (preventSecondCall(currentVideoIds)) return
  const filteredFileList = fileList.filter((file: any) => mimeWhitelist.includes(file?.type || file.subType))

  if (filteredFileList.length !== fileList.length) {
    message.error(t<string>('templateCreation.fileMimeError'))
    return
  }
  const incorrectSize = filteredFileList?.every(file => (file.size ? file?.size > maxFileSize : false))

  if (incorrectSize) {
    message.error(t<string>('templateCreation.fileSizeErr'))
    return
  }

  const isEmptyFile = fileList.every(file => file.size === 0)

  if (isEmptyFile) {
    message.error(t<string>('templateCreation.emptyFileErr'))
    return
  }

  return filteredFileList
}

export default pickAudioValidation
