import { useQuery } from '@tanstack/react-query'

import initialSettingsApi from 'api/initialSettings.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

const useInitialSettingsForMentorQuery = () => {
  const { data: initialSettings, isLoading } = useQuery({
    keepPreviousData: true,
    staleTime: Infinity,
    queryKey: [QueryKeysEnum.INITIAL_SETTINGS_MENTOR],
    queryFn: async () => {
      const res = await initialSettingsApi.getInitialSettingsByMentor()

      return res
    },
  })

  return { initialSettings, isLoading }
}

export default useInitialSettingsForMentorQuery
