import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import styles from './styles.module.scss'

interface IProps {
  fullScreen?: boolean
  topOffset?: boolean
  children: ReactNode
  className?: string
}

const PageContainer: FC<IProps> = ({ children, fullScreen, topOffset, className }) => {
  return (
    <div
      className={classNames([
        {
          [styles.fullScreenContainer]: fullScreen,
          [styles.container]: !fullScreen,
          [styles.topOffset]: topOffset,
        },
        className,
      ])}
    >
      {children}
    </div>
  )
}

export default PageContainer
