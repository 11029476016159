import { configure, observable } from 'mobx'
import { createContext, useContext } from 'react'

import companyStore from './Company'
import dashboardStore from './Dashboard'
import goalsStore from './Goals'
import mentorDashboardStore from './MentorDashboard'
import notificationStore from './Notifications'
import planBtn from './PlanBtn'
import programStore from './Program'
import templateStore from './Template'
import userStore from './User'
import wallPostStore from './WallPost'

configure({ enforceActions: 'observed' })

class RootStore {
  @observable userStore = userStore
  @observable programStore = programStore
  @observable dashboardStore = dashboardStore
  @observable companyStore = companyStore
  @observable templateStore = templateStore
  @observable wallPostStore = wallPostStore
  @observable notificationStore = notificationStore
  @observable mentorDashboardStore = mentorDashboardStore
  @observable goalsStore = goalsStore
  @observable planBtnStore = planBtn
}

export const rootStore = new RootStore()

export const StoreContext = createContext<RootStore>(rootStore)

export const useStore = (): RootStore => {
  const store = useContext(StoreContext)
  if (!store) {
    throw new Error('You have forgot to use StoreProvider, shame on you.')
  }
  return store
}

export default new RootStore()
