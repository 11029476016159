import cn from 'classnames'
import React from 'react'
import styles from './styles.module.scss'
import TextArea from 'antd/lib/input/TextArea'
import { TextAreaProps } from 'antd/lib/input'

interface IProps extends TextAreaProps {
  label?: string
  labelType?: 'default' | 'primary' | 'shadow'
  labelClassName?: string
  errorText?: string[]
  containerClassName?: string
  isErrorNeeded?: boolean
}

const CustomTextarea: React.FC<IProps> = ({
  label,
  labelType = 'default',
  labelClassName,
  className,
  errorText,
  containerClassName,
  isErrorNeeded = true,
  ...rest
}) => {
  return (
    <div className={cn(styles.customTextareaContainer, containerClassName)}>
      {label && (
        <label htmlFor="name" className={cn(styles['label' + labelType], labelClassName)}>
          {label}
        </label>
      )}
      <TextArea
        className={cn(styles.customTextarea, className)}
        status={errorText?.length ? 'error' : undefined}
        {...rest}
      />
      {isErrorNeeded && (
        <div
          className={cn(styles.errorText, {
            [styles.show]: errorText?.length,
          })}
        >
          {errorText?.join(' ')}
        </div>
      )}
    </div>
  )
}

export default CustomTextarea
