import { Radio } from 'antd'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import ContextMenu from 'components/ContextMenu'

import styles from './styles.module.scss'

import FilterIcon from 'components/FilterIcon'

interface ICommonProps {
  options: { id: number; name: string }[]
  onChange?: () => void
  menuTitle: string
}

interface IProps extends ICommonProps {
  onReset: () => void
  filter: number | null
  setFilter: Dispatch<SetStateAction<number | null>>
}

interface IHookProps extends ICommonProps {
  disable?: boolean
  filter?: number | null
  setFilter?: Dispatch<SetStateAction<number | null>>
  onReset?: () => void
  defaultFilter?: number | null
}

export const useFilterTable = ({
  filter,
  setFilter,
  menuTitle,
  options,
  onChange,
  disable,
  onReset,
  defaultFilter = null,
}: IHookProps) => {
  if (disable || filter === void 0 || !setFilter) return {}

  return {
    onFilter: (value: string, record: any) => record.address.indexOf(value) === 0,
    filterDropdown: (props: FilterDropdownProps) => (
      <FilterTable
        filter={filter}
        setFilter={setFilter}
        menuTitle={menuTitle}
        onReset={() => {
          setFilter(defaultFilter)
          props.close()
          if (onReset) {
            onReset()
          }
        }}
        options={options}
        onChange={onChange}
      />
    ),
    filteredValue: filter,
    filterIcon: () => <FilterIcon active={filter !== defaultFilter && filter !== 0} />,
  }
}

const FilterTable: FC<IProps> = ({ options, filter, setFilter, onChange, onReset, menuTitle }) => {
  const { t } = useTranslation()

  return (
    <ContextMenu className={styles.menu}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{menuTitle}</div>
        <div
          className={styles.reset}
          onClick={() => {
            onReset()
          }}
        >
          {t<string>('companyPage.reset')}
        </div>
      </div>
      <div className={styles.radiosContainer}>
        {options.map(statusFilter => (
          <Radio
            key={statusFilter.id}
            checked={filter === statusFilter.id}
            onChange={() => {
              setFilter(statusFilter.id)
              if (onChange) {
                onChange()
              }
            }}
            className={styles.radio}
          >
            {statusFilter.name}
          </Radio>
        ))}
      </div>
    </ContextMenu>
  )
}

export default FilterTable
