import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import programsApi from 'api/programs.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import message from 'utils/message'

const useProgramQuery = (programId?: string, preventErrorRedirect?: boolean) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const {
    data: program,
    isLoading,
    isError,
  } = useQuery({
    keepPreviousData: true,
    queryKey: [QueryKeysEnum.PROGRAM, programId],
    enabled: !!programId,
    queryFn: async () => {
      if (!programId) throw new Error()

      const program = await programsApi.getProgramById(programId)

      return program
    },
    onError: error => {
      message.error(t<string>('programCreation.trainingProgramNotFound'))
      if (!preventErrorRedirect) {
        navigate('/programs')
      }
    },
  })

  return { program, isLoading, isError }
}

export default useProgramQuery
