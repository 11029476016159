import { FollowUpPostType } from 'types/Template'

const transformPostTypeToText = (type: FollowUpPostType) => {
  switch (type) {
    case FollowUpPostType.File:
      return 'media'

    case FollowUpPostType.Link:
      return 'link'

    case FollowUpPostType.Questionnaire:
      return 'questionnaire'

    case FollowUpPostType.Summary:
      return 'summary'

    default:
      return ''
  }
}

export default transformPostTypeToText
