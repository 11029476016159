import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { useStore } from 'stores'

import LS, { LSKeys } from 'utils/LS'

import { IUser } from 'types/User'

import Auth from 'containers/Auth'
import Private from 'containers/Private'
import DeleteAccount from 'containers/Public/DeleteAccount'

const EmailVerification = React.lazy(() => import('containers/Public/EmailVerification'))
const Terms = React.lazy(() => import('containers/Public/Terms'))
const Invitation = React.lazy(() => import('containers/Public/Invitation'))

const Router = () => {
  const { userStore } = useStore()

  useMemo(() => {
    const user = LS.get(LSKeys.USER) as IUser | null
    const adminToken = LS.get(LSKeys.ADMIN_TOKEN)

    if (user) {
      !userStore.user && userStore.setUser(user)

      userStore.setIsCompanyModerator(!!user.companyId)
      userStore.setIsTrainer(user.haveTrainerPermission)
    } else if (adminToken) {
      userStore.setIsAdmin(!!adminToken)
      userStore.setIsTrainer(false)
    }
  }, [userStore.user])

  const authorized = userStore.isAdmin || userStore.isTrainer || userStore.isCompanyModerator

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/delete-account"
          element={
            <React.Suspense fallback={<></>}>
              <DeleteAccount />
            </React.Suspense>
          }
        />
        <Route
          path="/terms"
          element={
            <React.Suspense fallback={<></>}>
              <Terms />
            </React.Suspense>
          }
        />
        <Route
          path="/invitation"
          element={
            <React.Suspense fallback={<></>}>
              <Invitation />
            </React.Suspense>
          }
        />
        <Route
          path="/verification"
          element={
            <React.Suspense fallback={<></>}>
              <EmailVerification />
            </React.Suspense>
          }
        />
        <Route path="*" element={!authorized ? <Auth /> : <Private />} />
      </Routes>
    </BrowserRouter>
  )
}

export default observer(Router)
