import { Checkbox } from 'antd'
import classNames from 'classnames'
import { Dispatch, FC, SetStateAction, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { List } from 'react-virtualized'

import { useStore } from 'stores'

import sortingByLocale from 'utils/sortingByLocale'

import ContextMenu from 'components/ContextMenu'

import styles from './styles.module.scss'

interface IProps {
  setSelectedIds: Dispatch<SetStateAction<number[]>>
  selectedIds: number[]
  items: { name: string; id: number }[]
  onReset?: () => void
  title: string
  onPageReset: () => void
}

const FilterMenu: FC<IProps> = ({ setSelectedIds, selectedIds, items, onReset, title, onPageReset }) => {
  const { userStore } = useStore()

  const { t } = useTranslation()

  const itemsToShow = useMemo(() => {
    return sortingByLocale(items, userStore.language, 'name')
  }, [items, userStore.language])

  return (
    <ContextMenu className={styles.menu}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        {onReset && (
          <div className={styles.reset} onClick={onReset}>
            {t('companyPage.reset')}
          </div>
        )}
      </div>
      <List
        height={300}
        width={300}
        rowCount={itemsToShow.length}
        rowHeight={50}
        className={styles.list}
        rowRenderer={({ key, index, style }) => {
          const item = itemsToShow[index]

          return (
            <Checkbox
              key={key}
              checked={selectedIds.includes(item.id)}
              onChange={e => {
                setSelectedIds(prev => {
                  if (e.target.checked) {
                    return [...prev, item.id]
                  } else {
                    return prev.filter(selectedId => selectedId !== item.id)
                  }
                })
                onPageReset()
              }}
              style={style}
              className={classNames('checkboxUI', styles.program)}
            >
              {item.name}
            </Checkbox>
          )
        }}
      />
    </ContextMenu>
  )
}

export default memo(FilterMenu)
