import { Progress } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import UserAvatar from 'components/UserAvatar'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import useClientSidePaginarion from 'hooks/useClientSidePaginarion'

import { useStore } from 'stores'

import { IDailyReportUser } from 'types/Program'

import Pagination from 'components/Pagination'

import CustomTable from 'components/CustomComponents/CustomTable'
import styles from './styles.module.scss'

interface IProps {
  recentEntriesTrainees: IDailyReportUser[]
  programId?: string
}

const RecentEntries = ({ recentEntriesTrainees, programId }: IProps) => {
  const { userStore } = useStore()

  const { itemsPortion, propsToPaginationComponent } = useClientSidePaginarion<IDailyReportUser>({
    items: recentEntriesTrainees,
  })

  const navigate = useNavigate()

  const toReport = (row: IDailyReportUser) => navigate('/report/' + row.programId, { state: { userId: row.userId } })

  const columns = useMemo(() => {
    let items: ColumnsType<IDailyReportUser> = [
      {
        title: t<string>('programCreation.nameTable'),
        dataIndex: 'userName',
        key: 'name',
        width: 200,
        render: (text: string, row: IDailyReportUser) => (
          <div className={styles.nameCol} onClick={() => toReport(row)}>
            <UserAvatar avatarId={row.avatarId} />
            <div>{text || '-'}</div>
          </div>
        ),
      },
      {
        title: t<string>('mentorDashboard.programName'),
        dataIndex: 'programName',
        key: 'programName',
        render: (text: string, row: IDailyReportUser) => (
          <div style={{ cursor: 'pointer' }} onClick={() => toReport(row)}>
            {text}
          </div>
        ),
      },
      {
        title: t<string>('mentorDashboard.postViewed'),
        dataIndex: 'postViewCount',
        key: 'postViewCount',
        render: (postViewCount: string, row) => `${postViewCount}/${row.postCount}`,
        responsive: ['lg'],
      },
      {
        title: t<string>('mentorDashboard.date'),
        dataIndex: 'dateTime',
        key: 'dateTime',
        render: date => moment(date).format('DD/MM/yyyy'),
        responsive: ['sm'],
      },
      {
        title: t<string>('mentorDashboard.questionsAnswered'),
        key: 'questionnaireAnsweredCount',
        dataIndex: 'questionnaireAnsweredCount',
        render: (questionnaireAnsweredCount, row) => {
          const percent = Math.round((questionnaireAnsweredCount * 100) / row.questionnaireCount)

          return (
            <Progress
              strokeColor={percent > 80 ? '#52c41a' : percent >= 50 ? '#FCCA59' : percent > 30 ? '#F67F3C' : '#D75370'}
              percent={percent}
            />
          )
        },
      },
    ]

    if (programId) {
      items = items.filter(item => item.key !== 'programName')
    }

    return items
  }, [userStore.language, programId])

  return (
    <div className={styles.recentEntries}>
      <div className={styles.blockTitle}>{t<string>('mentorDashboard.recentEntries')}</div>
      <div className={styles.table}>
        <CustomTable variant="clear" rowKey={'id'} columns={columns} pagination={false} dataSource={itemsPortion} />
        <div className={styles.paginationContainer}>
          <Pagination {...propsToPaginationComponent} />
        </div>
      </div>
    </div>
  )
}

export default observer(RecentEntries)
