import { Checkbox } from 'antd'
import { FC, memo } from 'react'

import { IDailyReportAnswer } from 'types/Program'

import styles from './styles.module.scss'

interface IProps {
  selectOneChange: (e: any, question: IDailyReportAnswer) => void
  question: IDailyReportAnswer
  selected: boolean
}

const Question: FC<IProps> = ({ selectOneChange, question, selected }) => {
  return (
    <div className={styles.question} onClick={e => selectOneChange(e, question)}>
      <Checkbox className="checkboxUI" checked={selected} />
      <div className={styles.name}>{question.question}</div>
    </div>
  )
}

export default memo(Question)
