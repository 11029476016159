import { EditOutlined } from '@ant-design/icons'
import { Progress } from 'antd'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useGetProgramCreationProgress from 'hooks/programCreationProgress/useGetProgramCreationProgress'

import { ITemplateOption } from 'types/Template'
import { initialDayWallPostQuery } from 'utils/const'

import CustomButton from 'components/CustomComponents/CustomButton'

import styles from './styles.module.scss'

interface IProps {
  programId: string
  selectedTemplate: ITemplateOption | undefined
  currentDayCompleted: number
}

const TemplateTab: React.FC<IProps> = ({ programId, selectedTemplate, currentDayCompleted }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onEditClick = () => {
    navigate('/wall-post/' + programId + `?${initialDayWallPostQuery}=${currentDayCompleted}`, {
      state: {
        from: '/programs/' + programId,
      },
    })
  }

  const progress = useGetProgramCreationProgress(programId)

  return (
    <div className={styles.container}>
      {progress !== undefined && progress !== -1 && progress < 100 ? (
        <div className={styles.progress}>
          <Progress type="circle" percent={progress} width={40} strokeWidth={10} />
        </div>
      ) : (
        <CustomButton onClick={onEditClick} icon={<EditOutlined className={styles.icon} />}>
          {t('programDetails.programContentTab')}
        </CustomButton>
      )}
    </div>
  )
}

export default observer(TemplateTab)
