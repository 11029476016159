import { IDayData } from 'types/Template'

class TemplateDaysCache {
  private static instance: TemplateDaysCache

  public cache = new Map()

  constructor() {
    if (TemplateDaysCache.instance) {
      return TemplateDaysCache.instance
    }

    TemplateDaysCache.instance = this
  }

  public add(key: string, value: IDayData) {
    this.cache.set(key, value)
  }

  public has(key: string) {
    return this.cache.has(key)
  }

  public wipe() {
    this.cache.clear()
  }

  public generateKey({
    dayIndex,
    programId,
    templateId,
    isNews,
  }: {
    programId?: number
    templateId?: number
    dayIndex: number
    isNews?: boolean
  }) {
    return `i${dayIndex}-t${templateId}-p${programId}-isNews=${isNews}`
  }

  public extract(key: string) {
    return this.cache.get(key)
  }
}

export default TemplateDaysCache
