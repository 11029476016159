import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import programsApi from 'api/programs.api'

import { useStore } from 'stores'

import { WallMode } from 'types/Template'

import CustomSelect from 'components/CustomComponents/CustomSelect'

import styles from './styles.module.scss'

interface IProps {
  isNewsMode: boolean
}

const ModeSelector: FC<IProps> = ({ isNewsMode }) => {
  const { templateStore } = useStore()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const wallModeOptions = [
    { label: t('templateCreation.modeDefault'), value: WallMode.DEFAULT },
    { label: t('templateCreation.modeNews'), value: WallMode.NEWS },
  ]

  const onChange = async (val: WallMode) => {
    try {
      if (!templateStore.templateEntity.programId) return

      setLoading(true)

      if (val === WallMode.NEWS) {
        navigate(`/wall-post/${templateStore.templateEntity.programId}?mode=news`, { replace: true })
      } else {
        const program = await programsApi.getProgramById(templateStore.templateEntity.programId.toString())
        const initialDay = program.currentDay > program.daysAmount ? program.daysAmount - 1 : program.currentDay - 1
        navigate(`/wall-post/${templateStore.templateEntity.programId}?initialDay=${initialDay}`)
      }
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={styles.container}>
      <div>{t('templateCreation.mode')}</div>
      <CustomSelect
        onChange={onChange}
        defaultValue={isNewsMode ? WallMode.NEWS : WallMode.DEFAULT}
        options={wallModeOptions}
        loading={loading}
        className={styles.selector}
      />
    </div>
  )
}

export default ModeSelector
