import { observer } from 'mobx-react-lite'
import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import useUnboundStore from 'hooks/useUnboundStore'

import UnboundedContentModal from './UnboundContentModal'

import CustomButton from 'components/CustomComponents/CustomButton'

import styles from '../styles.module.scss'

interface IProps {
  setVisible: Dispatch<SetStateAction<boolean>>
  visible: boolean
  isBackToPlan: boolean
  onBack: () => void
  setIsBackToPlan: Dispatch<SetStateAction<boolean>>
}

const UnboundContent: FC<IProps> = ({ visible, setVisible, isBackToPlan, onBack, setIsBackToPlan }) => {
  const { t } = useTranslation()

  const { unboundStore } = useUnboundStore()

  return (
    <>
      <CustomButton
        loading={unboundStore.unboundContentLoading}
        onClick={() => setVisible(true)}
        className={styles.btn}
      >
        {t('templateCreation.unboundContent')}{' '}
        {!unboundStore.unboundContentLoading && `(${unboundStore.unboundContentLength})`}
      </CustomButton>
      <UnboundedContentModal
        visible={visible}
        setVisible={setVisible}
        isBackToPlan={isBackToPlan}
        onBack={onBack}
        setIsBackToPlan={setIsBackToPlan}
      />
    </>
  )
}

export default observer(UnboundContent)
