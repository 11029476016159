import { api } from 'config'

const controller = 'Verification'

const verificationApi = {
  async verify(email: string) {
    const response = await api.post(controller + '/verify', { email })
    return response.data
  },
  async resend(email: string) {
    const response = await api.post(controller + '/resend', { email })
    return response.data
  },
}

export default verificationApi
