export enum QueryKeysEnum {
  PROGRAMS = 'programs',
  LIGHT_TEMPLATES = 'light_templates',
  TEMPLATE_OPTIONS = 'template_options',
  IMAGE = 'image',
  TEMPLATES = 'template',
  AVATAR = 'avatar',
  ACTIVITY = 'activity',
  API_KEY = 'api_key',
  PROGRAM = 'program',
  TEMPLATE = 'template',
  TEMPLATE_FULL = 'template-full',
  TRAINEE = 'trainee',
  MESSAGE = 'message',
  PROGRAM_TRAINEES = 'program_trainees',
  PROGRAM_UNIQUE_ID = 'program_unique_id',
  TRAINEES_IN_PROGRAM = 'trainees-in-program',
  TRAINEES_WITH_STATUS = 'trainees-with-status',
  ALL_TRAINEES_IN_PROGRAM = 'all_trainees_in_program',
  DAILY_REPORTS = 'daily_reports',
  ALL_PROGRAMS_WHERE_MENTOR = 'all_programs_where_mentor',
  COMPANY_USERS = 'company_users',
  COMPANY_ALL_MENTORS = 'company_all_mentors',
  COMPANY_MENTORS = 'company_mentors',
  COMPANY_TRAINEES = 'company_trainees',
  COMPANY_PROGRAMS = 'company_programs',
  ADMIN_COMMON_USERS = 'admin_common_users',
  COMPANY_LIGHT_PROGRAMS = 'company_light_programs',
  ADMIN_ALL_PROGRAMS = 'admin_all_programs',
  ALL_USER_TRAINEES = 'all_user_trainees',
  USER_SHORT_TRAINEES = 'user_short_trainees',
  ALL_USER_PROGRAMS = 'all_trainee_programs',
  COMPANY_TAGS = 'company_tags',
  MENTOR_TAGS = 'mentor_tags',
  INITIAL_SETTINGS_COMPANY = 'initial_settings_company',
  INITIAL_SETTINGS_MENTOR = 'initial_settings_mentor',
  LIGHT_TAGS = 'light_tags',
  TRAINERS_FOR_PROGRAM = 'trainers_for_program',
  COMPANY_TAG_CATEGORIES = 'company_tag_categories',
  SCHEDULED_NOTIFICATIONS = 'scheduled-notifications',
  SCHEDULED_NOTIFICATIONS_USER = 'scheduled-notifications-user',
  SCHEDULED_NOTIFICATION_HISTORY = 'scheduled-notification-history',
  COMPANY_TRAINEES_COUNT = 'company_trainees_count',
  COMPANY_MENTORS_COUNT = 'company_mentors_count',
  TRAINEES_COUNT = 'trainees_count',
  TRAINEE_PROGRAMS = 'trainee-programs',
  ADMIN_INTERNAL_PREFIXES = 'admin-internal-prefixes',
  UPPER_STRIP_NOTIFICATION = 'upper-strip-notification',
  ACTIVITY_LOGS_ADMIN = 'activity-logs-admin',
  BRANDS = 'brands',
  ADMIN_COMPANY_BRANDS = 'admin-company-brands',
  ADMIN_COMPANY_PROGRAMS = 'admin-company-programs',
  ADMIN_LOGIN_ACTIVITY = 'admin-login-activity',
  WALL_SEARCHED_CONTENT = 'wall-searched-content',
  LIGHT_PROGRAMS_WHERE_MENTOR = 'light-programs-where-mentor',
  EVENT_PLACES = 'event-places',
  EVENT_PLACES_SHORT = 'event-places-short',
  TAG_RULE = 'tag-rule',
  ADMIN_LOCATION_TAGS = 'admin-location-tags',
  ADMIN_SCRAPING = 'admin-scraping',
  INTEREST = 'interest',
  WALL_POST_SCENARIO = 'wall-post-scenario',
  WALL_POST_SCENARIO_FULL = 'wall-post-scenario-full',
  TEMPLATE_SCENARIO = 'template-scenario',
  TEMPLATE_SCENARIO_FULL = 'template-scenario-full',
  WALL_POST = 'wall-post',
  TEMPLATE_ENTITY_SHORT = 'template-entity-short',
  CHAT_LIST_FOR_USER = 'chat-list-for-user',
  CHAT_MESSAGES = 'chat-messages',
  FILE_INFO = 'file-info',
}
