import stores from 'stores'

import {
  IAudioDayItem,
  IEntityTag,
  IFileDayItem,
  IImageDayItem,
  ITemplateFileAPI,
  IVideoDayItem,
  planingToolTypesEnum,
} from 'types/Template'

import { ParseTemplateUtil } from './index'

export class TemplateFileToServerConstructor {
  private utils = new ParseTemplateUtil()

  constructor() {}

  private constructResultObject({
    id,
    fileId,
    revealedAt,
    position,
    toolId,
    jsonForWeb,
    vimeoLink,
    youTubeLink,
    entityTags,
    repeatId,
  }: {
    id: number
    fileId: number
    revealedAt: string
    position: number
    toolId: number
    jsonForWeb: string
    youTubeLink?: string
    vimeoLink?: string
    entityTags: IEntityTag[]
    repeatId: string
  }) {
    const result: ITemplateFileAPI = {
      id,
      fileId,
      revealedAt,
      position,
      toolId,
      jsonForWeb,
      youTubeLink,
      vimeoLink,
      entityTags,
      repeatId,
    }

    return result
  }

  public run(
    tool: IImageDayItem | IVideoDayItem | IFileDayItem | IAudioDayItem,
    position: number,
    defaultDayStart: string,
    correctRevealedTime: string
  ) {
    const result: ITemplateFileAPI[] = []

    let fileListCopy = [...tool.fileList]

    if (tool.type === planingToolTypesEnum.VIDEO) {
      const videoTool = tool as IVideoDayItem

      videoTool.videoLinks.forEach((videoLink, linkId) => {
        const jsonForWeb = {
          fromDay: videoTool.fromDay,
          schedulerOption: videoTool.schedulerOption,
          uniqueId: videoTool.videoLinksUIDs[linkId],
          daysOfWeek: videoTool.daysSheduleValue,
        }

        const video = this.constructResultObject({
          id: videoTool?.videoLinksEntityIds?.[linkId] ?? -1,
          fileId: 1,
          toolId: linkId,
          revealedAt: videoTool.isAllDaySelected ? defaultDayStart : correctRevealedTime,
          position,
          jsonForWeb: Object.values(jsonForWeb).filter(key => key !== undefined).length
            ? JSON.stringify(jsonForWeb)
            : '',
          [videoLink?.includes('youtube') || videoLink?.includes('youtu.be') ? 'youTubeLink' : 'vimeoLink']: videoLink,
          entityTags: tool.entityTags,
          repeatId: tool.repeatId,
        })

        result.push(video)
      })
    }

    if (tool.fileIds?.length) {
      tool.fileIds.forEach((fileId, fileIdIndex) => {
        if (fileId === -1) return

        const fileObj = this.constructResultObject({
          id: tool.entityIds[fileIdIndex],
          fileId,
          revealedAt: tool.isAllDaySelected ? defaultDayStart : correctRevealedTime,
          position,
          toolId: fileIdIndex,
          jsonForWeb: this.utils.generateJsonForWeb(tool, fileIdIndex),
          entityTags: tool.entityTags,
          repeatId: tool.repeatId,
        })

        result.push(fileObj)
      })
      fileListCopy = fileListCopy.filter(item => item)
    }

    if (fileListCopy.length) {
      const fileIds = stores.templateStore.uploadedFilesId

      fileListCopy.forEach((file, currentIndex) => {
        if (fileIds) {
          const fileIdItem = fileIds.find(id => id.uuid === file.uid)

          if (file.uid?.includes('rc-upload') && fileIdItem) {
            const fileObj = this.constructResultObject({
              id: -1,
              fileId: fileIdItem.id,
              revealedAt: tool.isAllDaySelected ? defaultDayStart : correctRevealedTime,
              position,
              toolId: currentIndex,
              jsonForWeb: this.utils.generateJsonForWeb(tool, currentIndex),
              entityTags: tool.entityTags,
              repeatId: tool.repeatId,
            })

            result.push(fileObj)
          }
        }
      })
    }

    return result.filter((item: any) => item.fileId)
  }
}
