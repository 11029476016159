import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import useUnboundStore from 'hooks/useUnboundStore'

import { useStore } from 'stores'

import { IDayData, deleteEntityTypeEnum, planningToolsType } from 'types/Template'

import message from 'utils/message'

import DayTool from 'modules/TemplateModule/DayItem/DayTool'

interface IProps {
  dayData: IDayData
  dayIndex: number
}

const UnboundedContentBlock: FC<IProps> = ({ dayData, dayIndex }) => {
  const { templateStore, wallPostStore } = useStore()

  const { t } = useTranslation()

  const { unboundStore } = useUnboundStore()

  const checkIfDayFollowUp = () => {
    const firstContent = dayData.dayItems?.[0]

    const inTemplateStore = templateStore.followUpStore.followUpContentIds.findIndex(id => id === firstContent?.id)
    const inTemplateStoreUnbound = templateStore.followUpStore.followUpUnboundContentIds.findIndex(
      id => id === firstContent?.id
    )
    const inWallStore = wallPostStore.followUpStore.followUpContentIds.findIndex(id => id === firstContent?.id)
    const inWallStoreUnbound = wallPostStore.followUpStore.followUpUnboundContentIds.findIndex(
      id => id === firstContent?.id
    )

    return inTemplateStore !== -1 || inWallStore !== -1 || inTemplateStoreUnbound !== -1 || inWallStoreUnbound !== -1
  }

  const onDeleteDay = (tool: planningToolsType) => {
    const isFollowUp = checkIfDayFollowUp()

    if (isFollowUp) {
      message.error(t('templateCreation.UnableDeleteFollowUp'))
      return
    }

    templateStore.setIsToolEditing(false, true)

    if (tool.entityIds.length) {
      unboundStore.addUnboundEntitiesToDelete([{ entity: deleteEntityTypeEnum[tool.type], id: tool.entityIds }])
    }

    unboundStore.deleteDayByIndex(dayIndex)
  }

  return (
    <div>
      {dayData?.dayItems.map((item, toolIndex) => (
        <DayTool
          key={typeof item.uniqueId === 'object' ? item.uniqueId?.[0] || v4() : item.uniqueId}
          item={item}
          toolIndex={toolIndex}
          dayIndex={dayIndex}
          dayId={dayData.id}
          onDelete={onAfterDeleting => {
            onDeleteDay(item)
            if (onAfterDeleting) onAfterDeleting()
          }}
          isUnbound
        />
      ))}
    </div>
  )
}

export default observer(UnboundedContentBlock)
