import { useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'

import invitationsApi from 'api/invitations.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { ITraineesServerModel } from 'types/User'
import { InviteSMSEnum } from 'types/common'

import { applicationBrand } from 'utils/brand/brandDetector'
import message from 'utils/message'

import { ITraineesTable } from 'components/InviteTraineesTable'

interface IProps {
  trainees: ITraineesTable[]
  selectedRowKeys?: React.Key[]
  programId: number | null
  onAfterInvite: () => void
  onSendInvite?: (trainees: ITraineesServerModel[]) => void
  disableSms: boolean
  settingsProfileId: number | null
}

const INVITES_PER_REQUEST = 10

const useMultipleInvite = ({
  trainees,
  selectedRowKeys,
  programId,
  onAfterInvite,
  onSendInvite,
  disableSms,
  settingsProfileId,
}: IProps) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [autoAcceptInvite, setAutoAcceptInvite] = useState(false)

  const queryClient = useQueryClient()

  const validationBeforSendingInvite = (selectedTrainees: ITraineesTable[]) => {
    const unfilledParentPhone = selectedTrainees.find(
      trainee => trainee.allowedApproval && !isValidPhoneNumber(trainee.approvalPhoneNumber)
    )

    const unfilledPhone = selectedTrainees.find(trainees => !isValidPhoneNumber(trainees.phoneNumber))

    if (unfilledParentPhone || unfilledPhone) {
      message.error(t<string>('programCreation.checkWarnings'))
      return false
    }

    return true
  }

  const sendInvite = async () => {
    try {
      setLoading(true)

      const selectedTrainees = selectedRowKeys
        ? trainees.filter(trainee => selectedRowKeys.includes(`${trainee.id} ${trainee.phoneNumber}`))
        : trainees

      const valid = validationBeforSendingInvite(selectedTrainees)

      if (!valid) return

      const listToInvite: ITraineesServerModel[] = selectedTrainees.map(item => {
        if (item.approvalPhoneNumber && item.allowedApproval) {
          return {
            phoneNumber: item.phoneNumber,
            applicationBrand,
            isInvite: !autoAcceptInvite,
            approvals: [
              {
                phoneNumber: item.approvalPhoneNumber,
              },
            ],
          }
        }

        return { phoneNumber: item.phoneNumber, applicationBrand, isInvite: !autoAcceptInvite }
      })

      if (onSendInvite) {
        onSendInvite(listToInvite)
        onAfterInvite()
        return
      }

      if (!programId) return

      const chunks = _.chunk(listToInvite, INVITES_PER_REQUEST)

      const chunkPercent = 100 / chunks.length

      setLoading(true)

      for (let i = 0; i < chunks.length; i++) {
        const currentChunk = chunks[i]

        await invitationsApi.inviteTraineesToProgram(
          currentChunk,
          programId,
          disableSms ? InviteSMSEnum.Disable : InviteSMSEnum.Enable,
          settingsProfileId
        )

        setProgress((i + 1) * chunkPercent)
      }

      queryClient.invalidateQueries([QueryKeysEnum.PROGRAM])

      message.success(t<string>('programCreation.invitationsSent'))

      onAfterInvite()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
      setProgress(0)
    }
  }

  return { sendInvite, validationBeforSendingInvite, loading, progress, autoAcceptInvite, setAutoAcceptInvite }
}

export default useMultipleInvite
