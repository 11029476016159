import { useQuery } from '@tanstack/react-query'

import programsApi from 'api/programs.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import { someError } from 'utils/message'

const useLightProgramsWhereMentorQuery = () => {
  const { data: programsOptions, isLoading } = useQuery({
    queryKey: [QueryKeysEnum.LIGHT_PROGRAMS_WHERE_MENTOR],
    queryFn: async () => {
      const programs = await programsApi.getLightPrograms()
      return programs.map(program => ({ id: program.id, name: program.name }))
    },
    onError: () => {
      someError()
    },
    initialData: [],
  })

  return {
    programsOptions,
    isLoading,
  }
}

export default useLightProgramsWhereMentorQuery
