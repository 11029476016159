import { DownloadOutlined } from '@ant-design/icons'
import { Checkbox } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import classNames from 'classnames'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import scheduleNotificationApi from 'api/scheduleNotification.api'

import useScheduleHistoryQuery from 'hooks/tanstack/queries/useScheduleHistoryQuery'
import useDebounce from 'hooks/useDebounce'

import dateTime from 'utils/dateTime'
import { someError } from 'utils/message'

import { IScheduledNotification, IScheduledNotificationHistory, NotificationStatus } from 'types/ScheduleNotification'
import { IUserBase } from 'types/User'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomModal from 'components/CustomComponents/CustomModal'
import CustomTable from 'components/CustomComponents/CustomTable'
import { useFilterTable } from 'components/FilterTable'
import Pagination from 'components/Pagination'
import ReminderStatus from 'components/ReminderStatus'
import SearchBar from 'components/SearchBar'
import UserAvatar from 'components/UserAvatar'

import styles from './styles.module.scss'

interface IProps {
  activeHistoryReminder: IScheduledNotification | null
  onCancel: () => void
}

const ReminderInfoModal: FC<IProps> = ({ activeHistoryReminder, onCancel }) => {
  const { t } = useTranslation()

  const [statusFilter, setStatusFilter] = useState<NotificationStatus | null>(null)
  const [exportLoading, setExportLoading] = useState(false)
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 300)

  const resetPage = () => setPagination(prev => ({ ...prev, pageNumber: 1 }))

  const { isError, isFetching, isLoading, notificationHistory, propsToPaginationComponent, setPagination } =
    useScheduleHistoryQuery({
      scheduleId: activeHistoryReminder?.id,
      statusFilter,
      search: debouncedSearch,
    })

  useEffect(() => {
    if (!activeHistoryReminder) {
      setStatusFilter(null)
      setSearch('')
    }
  }, [activeHistoryReminder])

  const onExport = async () => {
    if (!activeHistoryReminder) return
    try {
      setExportLoading(true)
      await scheduleNotificationApi.exportHistory(activeHistoryReminder.id)
    } catch (e) {
      someError()
    } finally {
      setExportLoading(false)
    }
  }

  const filter = useFilterTable({
    menuTitle: t('programSettings.reminderStatusFilter'),
    options: [
      {
        id: NotificationStatus.Delivered,
        name: t('programSettings.reminderDeliveredStatus'),
      },
      {
        id: NotificationStatus.Seen,
        name: t('programSettings.reminderSeenStatus'),
      },
      {
        id: NotificationStatus.Sent,
        name: t('programSettings.reminderSentStatus'),
      },
      {
        id: NotificationStatus.Error,
        name: t('programSettings.reminderErrorStatus'),
      },
    ],
    filter: statusFilter,
    setFilter: setStatusFilter,
    onChange: resetPage,
    onReset: () => {
      setStatusFilter(null)
      resetPage()
    },
  })

  const columns = [
    {
      title: t('templateCreation.name'),
      dataIndex: 'user',
      key: 'user',
      render: (user: IUserBase) => (
        <div className={styles.fullNameCell}>
          <UserAvatar avatarId={user.avatarId} />
          {user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : ''}
        </div>
      ),
    },
    {
      title: t('dashboard.phoneNumber'),
      dataIndex: 'user',
      key: 'user',
      render: (user: IUserBase) => <div>{user.phoneNumber}</div>,
    },
    {
      title: t('programCreation.status'),
      dataIndex: 'status',
      key: 'status',
      ...filter,
      render: status => <ReminderStatus status={status} />,
    },
  ] as ColumnsType<IScheduledNotificationHistory>

  const withAction = !!activeHistoryReminder?.postId || !!activeHistoryReminder?.url

  return (
    <CustomModal
      title={<h2 className="modal-title">{t<string>('programSettings.reminderDetails')}</h2>}
      open={!!activeHistoryReminder}
      onCancel={onCancel}
      footer={null}
      className={styles.modal}
    >
      <div className={styles.infoContainer}>
        <div>
          <div className={styles.textItem}>
            <div className={styles.label}>{t('programSettings.notificationMessage')}:</div>
            <div className={styles.message}>{activeHistoryReminder?.message}</div>
          </div>
          <div className={styles.textItem}>
            <div className={styles.label}>{t('programSettings.reminderExecutedAt')}:</div>
            <div className={styles.message}>{dateTime.dateWithTime(activeHistoryReminder?.executeAt || '')}</div>
          </div>
          <div className={classNames(styles.textItem, styles.checkboxContainer)}>
            <div className={styles.label}>{t('programSettings.reminderFollowUp')}</div>
            <Checkbox disabled checked={withAction} className={styles.checkbox} />
          </div>
          {withAction && (
            <div>
              <div className={styles.textItem}>
                <div className={styles.label}>{t('programSettings.actionType')}:</div>
                <div className={styles.message}>{activeHistoryReminder.postId ? t('planButton.contentId') : 'URL'}</div>
              </div>
              <div className={styles.textItem}>
                <div className={styles.label}>{t('programSettings.actionValue')}:</div>
                <div className={styles.message}>
                  {activeHistoryReminder.postTitle && !!activeHistoryReminder.postId
                    ? `${activeHistoryReminder.postTitle} (${activeHistoryReminder.postId})`
                    : !!activeHistoryReminder.postId
                    ? activeHistoryReminder.postId
                    : activeHistoryReminder.url}
                </div>
              </div>
            </div>
          )}
        </div>
        <CustomButton type="default" onClick={onExport} loading={exportLoading}>
          <DownloadOutlined style={{ fontSize: 18 }} />
        </CustomButton>
      </div>
      <div className={styles.searchContainer}>
        <SearchBar
          value={search}
          placeholder={t('mentorDashboard.search')}
          onChangeCustom={str => {
            setPagination(prev => ({ ...prev, pageNumber: 1 }))
            setSearch(str)
          }}
          className={styles.search}
        />
      </div>
      <div>
        {isError ? (
          <div className={styles.tableError}>{t('programCreation.smtWrong')}</div>
        ) : (
          <CustomTable
            variant="invites"
            rowKey="id"
            //@ts-ignore
            dataSource={notificationHistory || []}
            columns={columns}
            loading={isLoading || isFetching}
            pagination={false}
            locale={{
              emptyText: <div className={styles.emptyText}>{t('programSettings.noResults')}</div>,
            }}
          />
        )}
        <Pagination {...propsToPaginationComponent} />
      </div>
    </CustomModal>
  )
}

export default ReminderInfoModal
