import { useStore } from 'stores'

import { DEFAULT_PRIMARY_COLOR } from 'utils/const'

import { ReactComponent as FilterPic } from 'sources/images/filter.svg'

interface IProps {
  active: boolean
}

const FilterIcon = ({ active }: IProps) => {
  const { userStore } = useStore()

  return (
    <FilterPic
      width={14}
      height={14}
      style={{
        color: active ? userStore.brandData?.primaryColor || DEFAULT_PRIMARY_COLOR : '#525252',
      }}
    />
  )
}

export default FilterIcon
