import { useQuery } from '@tanstack/react-query'

import userApi from 'api/user.api'

import { useStore } from 'stores'

import { LastActiveTraineesTimeRange, TraineesLastActiveOrderBy } from 'types/Program'
import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { IPagination } from 'types/common'

interface IProps {
  searchTrainee: string
  pagination: IPagination
  updatePaginationWithQuery: (paginationFromResponse: IPagination) => void
  orderBy: TraineesLastActiveOrderBy
  filter: LastActiveTraineesTimeRange
  programIds: number[]
}

const useAllUserTraineesQuery = ({
  searchTrainee,
  pagination,
  updatePaginationWithQuery,
  orderBy,
  filter,
  programIds,
}: IProps) => {
  const { userStore } = useStore()

  const {
    data: trainees,
    isLoading,
    isError,
    isFetching,
    isFetched,
  } = useQuery({
    keepPreviousData: true,
    queryKey: [
      QueryKeysEnum.ALL_USER_TRAINEES,
      userStore.user?.id,
      searchTrainee,
      pagination.pageNumber || 1,
      pagination.pageLength,
      orderBy,
      filter,
      programIds,
    ],
    queryFn: async () => {
      const queryZeroCheck = searchTrainee.length && searchTrainee[0] === '0' ? searchTrainee.slice(1) : searchTrainee

      const data = await userApi.getAllUserTrainees({
        str: queryZeroCheck,
        pageLength: pagination.pageLength,
        pageNumber: pagination.pageNumber || 1,
        orderBy,
        lastActiveFilter: filter,
        programIds,
      })

      updatePaginationWithQuery(data)

      return data
    },
  })

  return { trainees, isLoading, isError, isFetching: isFetching && !isFetched }
}

export default useAllUserTraineesQuery
