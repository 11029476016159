import { useQueryClient } from '@tanstack/react-query'
import { Form, Switch } from 'antd'
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useCreateQuickProgramMutation from 'hooks/tanstack/mutations/useCreateQuickProgramMutation'
import useQuickProgramTemplatesQuery from 'hooks/tanstack/queries/useQuickProgramTemplatesQuery'

import { useStore } from 'stores'

import LS, { LSKeys } from 'utils/LS'

import { IProgramDetailsForm, PostsSortingOptionsEnum } from 'types/Program'
import { QueryKeysEnum } from 'types/QueryKeysEnum'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomInput from 'components/CustomComponents/CustomInput'
import CustomSelect from 'components/CustomComponents/CustomSelect'
import PhoneNumberField from 'components/PhoneNumberField'
import PostsSortingOptionSelector from 'components/PostsSortingOptionSelector'
import ProgramSettingsCheckboxes from 'components/ProgramSettingsCheckboxes'
import SettingsProfileSelector from 'components/SettingsProfileSelector'

import styles from './styles.module.scss'

interface FormFields {
  programName: string | undefined
  selectedTemplate: number
  traineePhone: string
}

interface IProps {
  onAction: () => void
}

const QuickProgramForm: FC<IProps> = ({ onAction }) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()
  const [formDetails] = Form.useForm<IProgramDetailsForm>()

  const { userStore } = useStore()

  const abortControllerRef = useRef<AbortController | null>(null)

  const queryClient = useQueryClient()

  const [isForAnonymous, setisForAnonymous] = useState(true)
  const [initialSettingId, setInitialSettingId] = useState<number | null>(null)
  const [sortingOption, setSortingOption] = useState(() =>
    userStore.isEventTypeApp ? PostsSortingOptionsEnum.EventDay : PostsSortingOptionsEnum.RevealDate
  )

  const {
    templates,
    selectedTemplateId,
    setSelectedTemplateId,
    templatesLoading,
    selectedTemplateError,
    setSelectedTemplateError,
  } = useQuickProgramTemplatesQuery()

  useEffect(() => {
    const lastUsedTemplate = LS.get(LSKeys.LAST_USED_TEMPLATE_ID)

    if (lastUsedTemplate) {
      const exist = templates.findIndex(template => template.id === lastUsedTemplate) !== -1

      if (!exist) return

      form.setFieldValue('selectedTemplate', +lastUsedTemplate)
      setSelectedTemplateId(+lastUsedTemplate)
    }
  }, [templates])

  const selectTemplateOptions = useMemo(
    () =>
      templates.map(template => ({
        label: template.name,
        value: template.id,
      })),
    [templates]
  )

  const onSelectTemplate = useCallback((templateId: number) => {
    setSelectedTemplateId(templateId)
    setSelectedTemplateError(false)
  }, [])

  const { createQuickProgram, isLoading, checkIfTemplateSelected } = useCreateQuickProgramMutation({
    selectedTemplateId,
    setSelectedTemplateError,
    onSuccess: () => {
      onAction()
      queryClient.removeQueries([QueryKeysEnum.PROGRAMS])
    },
  })

  const onFinish = async (values: FormFields) => {
    const controller = new AbortController()
    abortControllerRef.current = controller

    const formDetailsValues = await formDetails.validateFields()

    await createQuickProgram({
      customValue: values.programName?.trim(),
      phoneNumber: values.traineePhone,
      templateId: values.selectedTemplate,
      disableSMS: !formDetailsValues.sendSms,
      signal: controller.signal,
      initialSettingId: initialSettingId || void 0,
      disableChat: !formDetailsValues.enableChat,
      hideParticipants: !formDetailsValues.showParticipants,
      sortingOption,
      isForAnonymous,
      companyId: userStore.userBrands?.[0]?.id || null,
    })

    LS.set(LSKeys.LAST_USED_TEMPLATE_ID, values.selectedTemplate)
  }

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
        abortControllerRef.current = null
      }
    }
  }, [])

  return (
    <Form
      name="basic"
      layout="vertical"
      initialValues={{ remember: true }}
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={checkIfTemplateSelected}
      form={form}
      data-form-4="form-4"
      requiredMark={false}
    >
      <div className={styles.phoneInput}>
        <PhoneNumberField customName="traineePhone" form={form} isTrainer={true} />
      </div>

      {/* optional */}
      <Form.Item
        name="programName"
        data-program-name="form-programName"
        // rules={[{ validator: basicStringFieldValidator({ required: false }) }]}
      >
        <CustomInput
          data-quick-program-name-input="quick-program-name-input"
          id="quick-program-name-input"
          labelType="shadow"
          label={t('programList.customTitle')}
          isErrorNeeded={false}
          className={styles.copyInput}
        />
      </Form.Item>

      <Form.Item name="selectedTemplate" data-selected-template="form-selectedTemplate">
        <CustomSelect
          value={selectedTemplateId}
          onChange={onSelectTemplate}
          options={selectTemplateOptions}
          loading={templatesLoading}
          isError={selectedTemplateError}
          label={t('programList.selectTemplate')}
          errorMessage={t('programList.pleaseSelectTemplate')}
          placeholder={t('programList.selectTemplate')}
          data-quick-program-template-select="quick-program-template-select"
          id="quick-program-template-select"
          autoSelect
        />
      </Form.Item>

      <div>
        <SettingsProfileSelector
          value={initialSettingId}
          setValue={setInitialSettingId}
          className={styles.profileSelect}
        />
      </div>

      {userStore.isForAnonSubdomain && (
        <div className={styles.item}>
          <div className={styles.label}>{t('programCreation.public')}</div>
          <Switch checked={isForAnonymous} onChange={setisForAnonymous} className="switchUI" />
        </div>
      )}

      {/* {userStore.isEventTypeApp && ( */}
      <div>
        <PostsSortingOptionSelector sortingOption={sortingOption} setSortingOption={setSortingOption} />
      </div>
      {/* )} */}

      <div className={styles.footer}>
        <ProgramSettingsCheckboxes form={formDetails} isQuickProgram />
        <div className={styles.btns}>
          <CustomButton
            type="default"
            data-quick-program-cancel="quick-program-cancel"
            id="quick-program-cancel"
            onClick={onAction}
          >
            {t('dashboard.cancel')}
          </CustomButton>
          <CustomButton
            type="primary"
            htmlType="submit"
            data-quick-program-save="quick-program-save"
            id="quick-program-save"
            loading={isLoading}
          >
            {t('programCreation.save')}
          </CustomButton>
        </div>
      </div>
    </Form>
  )
}

export default memo(QuickProgramForm)
