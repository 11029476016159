import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

const url = `${process.env.REACT_APP_HOST?.replace('/api', '')}/wallPostHub`

const wallPostHub = {
  connect: async (token: string) => {
    try {
      //@ts-ignore
      if (!globalThis.document) {
        //@ts-ignore
        globalThis.document = undefined
      }

      const hubConnection = new HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => token,
          withCredentials: true,
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build()

      hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 10

      console.log('Connected to server with wallPostHub', hubConnection)

      return hubConnection
    } catch (e) {
      console.log('Error when connected to server with wallPostHub', e)
      return null
    }
  },
  disconnect: async (connection: HubConnection) => {
    try {
      connection.stop()
      console.log('Disconnect from server with wallPostHub')
    } catch (e) {
      console.log(e)
    }
  },
}

export default wallPostHub
