import { AutoComplete, Button, Checkbox, Input, InputRef, Modal, SelectProps } from 'antd'
import classNames from 'classnames'
import { uniqueId } from 'lodash'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input'

import userApi from 'api/user.api'

import useMultipleInvite from 'hooks/invite/useMultipleInvite'
import useShortTraineesQuery from 'hooks/tanstack/queries/useShortTraineesQuery'

import { ITraineeWithInvite, ITrainees, ITraineesServerModel } from 'types/User'
import LS, { LSKeys } from 'utils/LS'
import { getCountryCode } from 'utils/helpers'
import message from 'utils/message'

import TraineeSelectOption from './TraineeSelectOption'

import InviteTraineesTable, { ITraineesTable } from 'components/InviteTraineesTable'
import Loader from 'components/Loader'
import ProgressBar from 'components/ProgressBar'
import SettingsProfileSelector from 'components/SettingsProfileSelector'

import styles from './styles.module.scss'

import noTraineeImg from 'sources/images/no-trainees.png'

let timer: NodeJS.Timeout | null = null

interface IProps {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  programId: string
  onSendInvite?: (selectedTrainees: ITraineesTable[], parsedTrainees: ITraineesServerModel[]) => void
  alreadyInvitedTrainees: ITraineeWithInvite[]
  selectedTrainees?: ITraineesTable[]
  setTraineesPhonesToRemove?: Dispatch<SetStateAction<string[]>>
  onAfterInvite?: () => void
}

// TODO refactor this
const InviteTraineesModal: FC<IProps> = ({
  visible,
  setVisible,
  programId,
  onSendInvite,
  alreadyInvitedTrainees,
  selectedTrainees: selectedTraineesFromProps,
  setTraineesPhonesToRemove,
  onAfterInvite,
}) => {
  const { t } = useTranslation()

  const inputRef = useRef<InputRef | null>(null)

  const [selectedTrainees, setSelectedTrainees] = useState<ITraineesTable[]>([])
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [selectedItemsInSystem, setSelectedItemsInSystem] = useState<ITrainees[]>([])
  const [countryCode, setCountryCode] = useState('')

  const [valuesForSearch, setValuesForSearch] = useState<SelectProps<object>['options']>([])
  const [searchVal, setSearchVal] = useState('')

  const [disableSms, setDisableSms] = useState(true)
  const [settingsProfileId, setSettingsProfileId] = useState<number | null>(null)

  const [touched, setTouched] = useState(false)

  const { trainees: traineesFromQuery, isLoading: traineesLoading } = useShortTraineesQuery({ programId: null })

  const trainees = traineesFromQuery || []

  const transformValueWithCode = useCallback(
    (searchVal: string) => {
      const isFullFormat = isValidPhoneNumber(searchVal)

      let valueToProcess = searchVal[0] === '0' ? searchVal.substring(1) : searchVal

      if (!isFullFormat && searchVal && countryCode) {
        const withCountryCode = countryCode + valueToProcess
        valueToProcess = withCountryCode
      }

      return valueToProcess
    },
    [countryCode]
  )

  const phoneToProcess = useMemo(() => {
    return transformValueWithCode(searchVal)
  }, [transformValueWithCode, searchVal])

  useEffect(() => {
    const getUserCountryLocation = async () => {
      const countryCode = getCountryCode()
      if (!countryCode) return

      const code = getCountryCallingCode(countryCode)
      if (!code) return

      setCountryCode('+' + code)
    }

    getUserCountryLocation()
  }, [])

  const onSearch = (value: string) => {
    if (!value) return

    const valueToProcess = transformValueWithCode(value)

    const result = trainees
      .filter(
        trainee =>
          trainee.phoneNumber.includes(valueToProcess) ||
          trainee.phoneNumber.includes(value) ||
          `${trainee.firstName} ${trainee.lastName}`.includes(value)
      )
      .map(trainee => {
        return {
          value: trainee.phoneNumber,
          label: <TraineeSelectOption option={trainee} />,
        }
      })

    setValuesForSearch(result)
  }

  const onSelect = (value: string) => {
    if (!value) return

    setSearchVal('')
    if (alreadyInvitedTrainees) {
      const isAlreadyExist = alreadyInvitedTrainees.some(trainee => trainee.phoneNumber === value)

      if (isAlreadyExist) return message.error(t('programCreation.userExistInList'))
    }

    setSelectedItems(prev => [...prev.filter(prevItem => prevItem !== value), value])
  }

  const removeTrainee = async (phoneNumber: string) => {
    setSelectedTrainees(prev => prev.filter(item => item.phoneNumber !== phoneNumber))
    setSelectedItems(prev => prev.filter(item => item !== phoneNumber))
    setSearchVal('')

    if (setTraineesPhonesToRemove) {
      setTraineesPhonesToRemove(prev => [...prev.filter(phone => phone !== phoneNumber), phoneNumber])
    }
  }

  const addTrainee = async () => {
    setTouched(true)

    const incorrectPhoneNumber = !isValidPhoneNumber(phoneToProcess) || !phoneToProcess.match(/^\+[0-9]+$/)

    if (incorrectPhoneNumber) {
      message.error(
        t('programCreation.phoneValidationError', {
          phoneNumbers: phoneToProcess,
        })
      )
      return
    }

    const inviteYourself = phoneToProcess === LS.get(LSKeys.PHONE)

    if (inviteYourself) {
      message.error(t('programCreation.selfInviteError'))
      return
    }

    let traineeData: ITrainees | undefined

    try {
      traineeData = (await userApi.getTrainee(phoneToProcess)) as unknown as ITrainees
    } catch (e) {}

    if (!!traineeData) {
      setSelectedItemsInSystem(prev => [...prev, traineeData!])
    }

    setSearchVal('')
    setTouched(false)
    setSelectedItems(prev => [...prev.filter(item => item !== phoneToProcess), phoneToProcess])
  }

  const { sendInvite, loading, progress, autoAcceptInvite, setAutoAcceptInvite } = useMultipleInvite({
    trainees: selectedTrainees,
    programId: +programId,
    onAfterInvite: () => {
      if (onAfterInvite) {
        onAfterInvite()
      }
      setVisible(false)
    },
    disableSms,
    onSendInvite: onSendInvite?.bind(null, selectedTrainees),
    settingsProfileId,
  })

  useEffect(() => {
    if (selectedTraineesFromProps?.length && visible) {
      setSelectedTrainees(selectedTraineesFromProps)
      setSelectedItems(selectedTraineesFromProps.map(trainee => trainee.phoneNumber))
    }
  }, [selectedTraineesFromProps, visible])

  useEffect(() => {
    return () => {
      setSelectedTrainees([])
      setSelectedItems([])

      if (setTraineesPhonesToRemove) {
        setTraineesPhonesToRemove([])
      }
    }
  }, [visible])

  useEffect(() => {
    if (traineesLoading) return

    const res: ITraineesTable[] = []

    selectedItems.forEach(item => {
      const findedIdx = trainees.findIndex(trainee => trainee.phoneNumber === item)

      if (findedIdx !== -1) {
        const findedItem = trainees[findedIdx]

        res.push({
          id: findedItem.id,
          allowedApproval: false,
          approvalPhoneNumber: findedItem?.approvals?.[0]?.phoneNumber || '',
          avatarId: findedItem.avatarId,
          firstName: findedItem.firstName,
          lastName: findedItem.lastName,
          phoneNumber: findedItem.phoneNumber,
          email: findedItem.email,
          approvalAvailable: !!findedItem?.approvals?.[0]?.phoneNumber,
        })
      } else {
        const existInSystem = selectedItemsInSystem.find(trainee => trainee.phoneNumber === item)

        if (existInSystem) {
          res.push({
            id: existInSystem.id,
            allowedApproval: false,
            approvalPhoneNumber: '',
            avatarId: existInSystem.avatarId,
            firstName: existInSystem.firstName,
            lastName: existInSystem.lastName,
            phoneNumber: existInSystem.phoneNumber,
            email: existInSystem.email,
            approvalAvailable: false,
          })

          return
        }

        const id = +uniqueId()

        res.push({
          id: id,
          allowedApproval: false,
          approvalPhoneNumber: '',
          avatarId: 0,
          firstName: '',
          lastName: '',
          phoneNumber: item,
          email: '',
          approvalAvailable: true,
        })
      }
    })

    setSelectedTrainees(prev => {
      return [
        ...prev,
        ...res.filter(resItem => prev.findIndex(prevItem => prevItem.phoneNumber === resItem.phoneNumber) === -1),
      ]
    })
  }, [selectedItems, trainees, selectedItemsInSystem, traineesLoading])

  useEffect(() => {
    if (!visible) {
      setSelectedItems([])
    } else {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => inputRef.current?.focus(), 500)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
    }
  }, [visible])

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      destroyOnClose={true}
      title={t('programCreation.inviteTrainee')}
      className="inviteTraineeModal"
      footer={
        <div className={styles.footer}>
          {!!programId ? (
            <>
              <SettingsProfileSelector value={settingsProfileId} setValue={setSettingsProfileId} />
              <Checkbox
                checked={!disableSms}
                onChange={e => setDisableSms(!e.target.checked)}
                className={classNames('checkboxUI', styles.checkbox)}
              >
                {t('programCreation.sendSms')}
              </Checkbox>
              <Checkbox
                checked={autoAcceptInvite}
                onChange={e => setAutoAcceptInvite(e.target.checked)}
                className={styles.checkbox}
              >
                {t('programCreation.autoAcceptInvite')}
              </Checkbox>
            </>
          ) : (
            <div />
          )}
          {progress !== 0 ? (
            <ProgressBar percent={progress} />
          ) : (
            <Button
              onClick={sendInvite}
              disabled={!!programId && !selectedItems.length}
              data-send-invite-btn="send-invite-btn"
              className={styles.sendBtn}
              id="send-invite-btn"
              loading={loading}
            >
              {`${t(programId ? 'programCreation.sendInvite' : 'dashboard.ok')} (${selectedItems.length})`}
            </Button>
          )}
        </div>
      }
    >
      <div className={styles.actionPanel}>
        <AutoComplete
          options={valuesForSearch}
          value={searchVal}
          onChange={text => setSearchVal(text)}
          onSelect={onSelect}
          onSearch={onSearch}
          showSearch={false}
          className={classNames('inviteTraineeModalSelector', {
            [styles.invalidPhoneInput]: !isValidPhoneNumber(phoneToProcess) && touched,
          })}
          autoFocus
          getPopupContainer={trigger => trigger}
          data-invite-trainee-input="invite-trainee-input"
          id="invite-trainee-input"
        >
          <Input.Search ref={inputRef} placeholder={t('programCreation.searchTraineeMessage')} />
        </AutoComplete>
        <Button className={styles.sendBtn} onClick={addTrainee} data-invite-btn="invite-btn" id="invite-btn">
          {t('programCreation.list')}
        </Button>
      </div>
      {traineesLoading ? (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      ) : !selectedTrainees.length ? (
        <div className={styles.emptyTextContainer}>
          <img src={noTraineeImg} className={styles.emptyTextImg} alt="traineesChooseMess" />
          <div className={styles.emptyText}>{t('programCreation.traineesChooseMess')}</div>
        </div>
      ) : (
        <InviteTraineesTable
          trainees={selectedTrainees}
          setSelectedTrainees={setSelectedTrainees}
          removeTrainee={removeTrainee}
          alreadyInvitedTrainees={alreadyInvitedTrainees}
        />
      )}
    </Modal>
  )
}

export default InviteTraineesModal
