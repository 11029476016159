import { useQuery } from '@tanstack/react-query'

import adminApi from 'api/admin.api'
import fileApi from 'api/file.api'

import { useStore } from 'stores'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

interface IProps {
  id?: number
}

const useImageQuery = ({ id }: IProps) => {
  const { userStore } = useStore()

  const {
    data: image,
    isLoading,
    isFetching,
  } = useQuery({
    keepPreviousData: true,
    staleTime: Infinity,
    queryKey: [QueryKeysEnum.IMAGE, id, userStore.isAdmin],
    queryFn: async () => {
      if (!id || id <= 1) return null

      const res = userStore.isAdmin ? await adminApi.getImageById(id) : await fileApi.getImageById(id)

      return res || null
    },
  })

  return { image, isLoading: isLoading || isFetching }
}

export default useImageQuery
