import { DownOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { Dispatch, FC, memo, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IProgramList } from 'types/Program'

import SearchBar from 'components/SearchBar'
import ProgramSelectMenu from 'components/SelectContextMenu'

import styles from './styles.module.scss'

interface IProps {
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  currentProgram: IProgramList | undefined
  programs: IProgramList[]
  programId: number | null
  changeProgram: (id: number | null) => void
}

const TopPanel: FC<IProps> = ({ searchValue, setSearchValue, currentProgram, programs, changeProgram, programId }) => {
  const { t } = useTranslation()

  const [programMenuVisible, setProgramMenuVisible] = useState(false)

  const handleMenuVisibleChange = useCallback((flag: boolean) => {
    setProgramMenuVisible(flag)
  }, [])

  return (
    <div className={styles.topPanel} data-action-buttons="action-buttons">
      <div>
        <Dropdown
          overlay={
            <ProgramSelectMenu
              programId={programId}
              onChange={changeProgram}
              items={programs || []}
              title={t<string>('dailyReport.selectProgram')}
              noItemsMessage={t<string>('dailyReport.noPrograms')}
              searchPlaceholder={t<string>('programList.searchProgram')}
            />
          }
          open={programMenuVisible}
          onOpenChange={handleMenuVisibleChange}
          trigger={['click']}
        >
          <h2 className={styles.title} data-name="name">
            {t<string>('programList.dailyReport')}: {currentProgram?.name || t<string>('programCreation.All')}{' '}
            <DownOutlined style={{ fontSize: 17 }} />
          </h2>
        </Dropdown>
      </div>
      <div>
        <SearchBar
          placeholder={t<string>('mentorDashboard.search')}
          value={searchValue}
          onChangeCustom={setSearchValue}
          className={styles.search}
        />
      </div>
    </div>
  )
}

export default memo(TopPanel)
