import { DownloadOutlined, ExportOutlined, LinkOutlined, PlusOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Space, Tabs, Typography } from 'antd'
import classNames from 'classnames'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import programsApi from 'api/programs.api'

import useTraineesWithStatusQuery from 'hooks/tanstack/queries/useTraineesWithStatusQuery'
import useDebounce from 'hooks/useDebounce'

import { LastActiveFilterEnum } from 'types/Program'
import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { IImportedTraineesCSV } from 'types/User'

import message, { someError } from 'utils/message'

import Trainees from './Trainees'

import ImportFromCvsModal from 'components/InviteTraineeModals/ImportFromCvsModal'
import ImportedFromCsvModal from 'components/InviteTraineeModals/ImportedFromCsvModal'
import InviteTraineesModal from 'components/InviteTraineeModals/InviteTraineesModal'
import LastActiveTraineeFilter from 'components/LastActiveTraineeFilter'
import PageError from 'components/PageError'
import PageLoader from 'components/PageLoader'
import SearchBar from 'components/SearchBar'

import userApi from 'api/user.api'
import styles from './styles.module.scss'

const { Title } = Typography

export enum TraineeGroopingEnum {
  All,
  Approved,
  NeedParentalApproval,
  InvitationErr,
  InvitationPending,
  Rejected,
}

interface IProps {
  programId: string
}

const TraineesTab: FC<IProps> = ({ programId }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [activeTab, setActiveTab] = useState(TraineeGroopingEnum.All)
  const [filter, setFilter] = useState(LastActiveFilterEnum.AllActive)
  const [search, setSearch] = useState('')

  const [exportLoading, setExportLoading] = useState(false)

  const [inviteTraineesModalVisible, setInviteTraineesModalVisible] = useState(false)
  const [importTraineesModalVisible, setImportTraineesModalVisible] = useState(false)
  const [importedTraineesModalVisible, setImportedTraineesModalVisible] = useState(false)

  const [importedTrainees, setImportedTrainees] = useState<IImportedTraineesCSV[]>([])

  const debouncedSearch = useDebounce(search, 500)

  const { isError, isFetching, isLoading, propsToPaginationComponent, trainees, numbers } = useTraineesWithStatusQuery({
    search: debouncedSearch,
    activeTab,
    filter,
    programId,
  })

  const handleCopy = async () => {
    if (!programId) return
    const link = await programsApi.getInviteLink(programId)

    if (!link) return

    await navigator.clipboard.writeText(link)
    message.success(t<string>('programList.invitationLinkCopied'))
  }

  const onExportTrainees = async () => {
    try {
      setExportLoading(true)
      await userApi.exportTraineesForProgram(+programId)
    } catch (e) {
      someError()
    } finally {
      setExportLoading(false)
    }
  }

  const invalidateData = () => {
    queryClient.invalidateQueries([QueryKeysEnum.PROGRAM])
    queryClient.invalidateQueries([QueryKeysEnum.TRAINEES_WITH_STATUS])
  }

  const onAfterImportTrainees = (traineesFromParam: IImportedTraineesCSV[]) => {
    setImportTraineesModalVisible(false)
    setImportedTrainees(traineesFromParam)
    setImportedTraineesModalVisible(true)
  }

  const tabsItems = Array.from({ length: numbers.length }, (_, index) => ({
    label: (
      <span
        className={classNames(styles.tab, { [styles.active]: index === activeTab })}
        onClick={() => setActiveTab(index)}
      >
        {t(`programCreation.${TraineeGroopingEnum[index]}`)} <span className={styles.label}>{numbers[index]}</span>
      </span>
    ),
    key: String(index + 1),
    children: (
      <Trainees
        programId={programId}
        currentTab={index}
        trainees={trainees}
        activeTab={activeTab}
        isFetching={isFetching}
        onInvalidate={invalidateData}
        propsToPaginationComponent={propsToPaginationComponent}
      />
    ),
  }))

  return (
    <div className={styles.block} data-block-2="block-2">
      <div className={styles.controlsWithTitle}>
        <Space direction="horizontal" className={styles.mainSubcontrols}>
          <Title className={styles.title} level={4} data-title-6="title-6">
            {t('programCreation.traineesList')}
          </Title>
          <div style={{ margin: '0 10px' }}>
            <SearchBar
              value={search}
              placeholder={t('dashboard.searchUser')}
              onChangeCustom={setSearch}
              data-search-user="search-user"
              id="search-user"
            />
          </div>
        </Space>
        <div className={styles.controls} data-controls="controls">
          <Button
            type="primary"
            icon={<LinkOutlined style={{ fontSize: 12 }} />}
            className={styles.btn}
            onClick={handleCopy}
            data-copy-invite="copy-invite"
            id="copy-invite"
          >
            {t('programList.copyInvitationLink')}
          </Button>

          <Button
            type="primary"
            className={styles.btn}
            onClick={() => setInviteTraineesModalVisible(true)}
            icon={<PlusOutlined data-user-add="user-add" style={{ fontSize: 12 }} />}
            data-invite-trainee="invite-trainee"
            id="invite-trainee"
          >
            {t('programCreation.inviteTrainee')}
          </Button>
          <Button
            type="primary"
            className={styles.btn}
            onClick={() => setImportTraineesModalVisible(true)}
            icon={<DownloadOutlined data-user-add="user-add" style={{ fontSize: 12 }} />}
            data-import-trainee="import-trainee"
            id="import-trainee"
          >
            {t('programCreation.import')}
          </Button>
          <Button
            type="primary"
            className={styles.btn}
            onClick={onExportTrainees}
            icon={<ExportOutlined data-user-add="user-add" style={{ fontSize: 12 }} />}
            data-import-trainee="import-trainee"
            id="import-trainee"
            loading={exportLoading}
          >
            {t('shared.export')}
          </Button>
        </div>
      </div>

      <div className={styles.filter}>
        <LastActiveTraineeFilter value={filter} onChange={setFilter} />
      </div>

      {isError ? (
        <PageError />
      ) : isLoading ? (
        <PageLoader />
      ) : (
        <Tabs defaultActiveKey={TraineeGroopingEnum.All.toString()} destroyInactiveTabPane items={tabsItems} />
      )}

      <InviteTraineesModal
        visible={inviteTraineesModalVisible}
        setVisible={setInviteTraineesModalVisible}
        programId={programId}
        alreadyInvitedTrainees={trainees}
        onAfterInvite={invalidateData}
      />
      <ImportFromCvsModal
        visible={importTraineesModalVisible}
        setVisible={setImportTraineesModalVisible}
        onAfterImportTrainees={onAfterImportTrainees}
      />
      <ImportedFromCsvModal
        visible={importedTraineesModalVisible}
        setVisible={setImportedTraineesModalVisible}
        programId={programId}
        importedTrainees={importedTrainees}
        alreadyInvitedTrainees={trainees}
        onAfterInvite={invalidateData}
      />
    </div>
  )
}

export default TraineesTab
