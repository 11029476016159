import { useQuery } from '@tanstack/react-query'

import userApi from 'api/user.api'

import usePagination from 'hooks/usePagination'

import { LastActiveFilterEnum } from 'types/Program'
import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { ITraineeWithInvite } from 'types/User'

import transformInviteTypeToServer from 'utils/transformInviteTypeToServer'

import { TraineeGroopingEnum } from 'containers/Private/Mentor/ProgramDetail/ProgramTabbar/TraineesTab'

interface IProps {
  search: string
  programId: string
  activeTab: TraineeGroopingEnum
  filter: LastActiveFilterEnum
}

const PAGE_LENGTH = 10

const useTraineesWithStatusQuery = ({ search, programId, activeTab, filter }: IProps) => {
  const { pagination, setPagination, propsToPaginationComponent } = usePagination({
    pageLength: PAGE_LENGTH,
    hideSizer: true,
  })

  const { data, isLoading, isError, isFetching, isFetched } = useQuery({
    keepPreviousData: true,
    queryKey: [QueryKeysEnum.TRAINEES_WITH_STATUS, programId, activeTab, pagination.pageNumber || 1, filter, search],
    queryFn: async () => {
      const queryZeroCheck = search.length && search[0] === '0' ? search.slice(1) : search

      const response = await userApi.getProgramTraineeWithInviteInfo({
        programId,
        pageNumber: pagination.pageNumber || 1,
        pageLength: PAGE_LENGTH,
        str: queryZeroCheck,
        inviteStatus: transformInviteTypeToServer(activeTab),
        lastActiveFilter: filter,
      })

      const { all, succeedStatus, requireParentalApprovalStatus, failedStatus, pendingStatus, rejectedStatus } =
        response.item2

      const { itemCount, pageNumber, totalItems, totalPages } = response.item1

      setPagination(prev => {
        const paginationToSet = {
          ...prev,
          itemCount,
          totalItems,
          totalPages,
        }

        if (pageNumber > totalPages) {
          paginationToSet.pageNumber = totalPages
        }

        return paginationToSet
      })

      return {
        numbers: [
          all,
          succeedStatus,
          requireParentalApprovalStatus,
          failedStatus,
          pendingStatus,
          rejectedStatus,
        ] as number[],
        trainees: response.item1.items as ITraineeWithInvite[],
      }
    },
  })

  return {
    trainees: data?.trainees || [],
    numbers: data?.numbers || [],
    isLoading,
    isError,
    isFetching: isFetching && !isFetched,
    propsToPaginationComponent,
    setPagination,
  }
}

export default useTraineesWithStatusQuery
