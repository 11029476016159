import SecureLS from 'secure-ls'

export enum LSKeys {
  TOKEN = 'token',
  ADMIN_TOKEN = 'adminToken',
  USER = 'user',
  PHONE = 'userPhoneNumberToLogin',
  COUNTRY = 'userCountryCode',
  PROGRAM = 'program',
  PROGRAM_LOGO = 'program-logo-image',
  PROGRAM_GROUP_LOGO = 'program-group-image',
  PROGRAM_TRAINEES = 'program-trainees',
  PROGRAM_TRAINEES_SELECTED = 'program-trainees-selected',
  PROGRAM_TEMPLATE_ID = 'templateId_program',
  PROGRAM_TEMPLATE = 'template_program',
  LAST_USED_TEMPLATE_ID = 'used-template-id',
  BRAND_DATA = 'brandData',
  BRAND_LOGO = 'brandLogo',
  BRAND_BG = 'brandBGImage',
  LAST_BRAND_COMPANY_ID_SELECTED = 'last-brand-company-id-selected',
  REMINDER = 'reminder',
  SCRAPING_TOKEN = 'scraping-token',
}

const secureStorage = new SecureLS()

class LS {
  get(key: LSKeys) {
    return secureStorage.get(key)
  }

  set(key: LSKeys, value: any) {
    return secureStorage.set(key, value)
  }

  remove(key: LSKeys) {
    return secureStorage.remove(key)
  }
}

export default new LS()
