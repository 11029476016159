import type { UploadFile } from 'antd/es/upload/interface'
import Dragger from 'antd/lib/upload/Dragger'
import { UploadChangeParam, UploadListType } from 'antd/lib/upload/interface'
import cn from 'classnames'
import { t } from 'i18next'
import update from 'immutability-helper'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useMemo } from 'react'

import {
  IAudioDayItem,
  IFileDayItem,
  IFileLoadProgress,
  IFileUploadingProgress,
  IImageDayItem,
  IVideoDayItem,
} from 'types/Template'

import { swapArrayElements } from 'utils/helpers'

import DraggableUploadListItem from './DraggableUploadListItem'
import LoadingItem from './LoadingItem'
import UploadingItem from './UploadingItem'

import styles from './styles.module.scss'

import { ReactComponent as UploadIcon } from 'sources/images/UploadIcon.svg'

export interface IDraggableUploadProps {
  fileList: UploadFile<any>[]
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>
  handleFileUpload: (a: any) => void
  handleRemove: (file: UploadFile<any>) => void
  handlePreview: (file: UploadFile<any>) => void
  isEditing: boolean
  listType?: UploadListType
  isVideo?: boolean
  videoLink?: string
  toolData: IImageDayItem | IVideoDayItem | IFileDayItem | IAudioDayItem | undefined | null
  setToolData: React.Dispatch<React.SetStateAction<any>>
  loading?: boolean
  setIsTouched?: React.Dispatch<React.SetStateAction<boolean>>
  fileIds?: number[]
  loadingProgress?: IFileLoadProgress[]
  uploadingProgress?: IFileUploadingProgress[]
  isImage?: boolean
}

const DraggableUpload: React.FC<IDraggableUploadProps> = ({
  fileList,
  setFileList,
  handleFileUpload,
  handleRemove,
  handlePreview,
  isEditing,
  listType,
  isVideo,
  videoLink,
  toolData,
  setToolData,
  loading,
  setIsTouched,
  fileIds,
  loadingProgress,
  uploadingProgress,
  isImage,
}) => {
  const entityIdsMap = useMemo(() => {
    let result: [any, number][] = []

    toolData?.entityIds.forEach((id, idx) => {
      const file = fileList[idx]

      if (file) {
        result.push([file.url, id])
      }
    })

    return result
  }, [fileList, toolData])

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (toolData && toolData?.fileIds && toolData?.entityIds) {
        const toolDataCopy = { ...toolData }
        toolDataCopy.fileIds = swapArrayElements(toolDataCopy.fileIds, dragIndex, hoverIndex)
        toolDataCopy.entityIds = swapArrayElements(toolDataCopy.entityIds, dragIndex, hoverIndex)
        setToolData(toolDataCopy)
      }

      const dragRow = fileList[dragIndex]

      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      )

      if (setIsTouched) {
        setIsTouched(true)
      }
    },
    [fileList]
  )

  const isPictureItem = listType === 'picture-card'

  const placeholderArr = Array(isPictureItem ? (fileIds?.length || 0) + 1 : fileIds?.length).fill('*')

  const disableUploadBtn =
    !isEditing ||
    (!!uploadingProgress && uploadingProgress.some(progress => progress.percent !== 0 && progress.percent !== 100))

  const onChange = useCallback(
    (data: UploadChangeParam<UploadFile<any>>) => {
      if (data.file.status === 'removed') return

      handleFileUpload(data)
    },
    [handleFileUpload]
  )

  return (
    <div
      className={cn(styles.dragger, {
        [styles.hidden]: !isEditing,
        [styles.video]: isVideo,
        [styles.defaultFiles]: !isVideo && !isImage,
      })}
    >
      <Dragger
        disabled={disableUploadBtn || !!videoLink}
        name="file"
        fileList={fileList}
        onChange={onChange}
        onRemove={handleRemove}
        beforeUpload={() => false}
        onPreview={handlePreview}
        style={{ width: 400 }}
        listType="picture-card"
        itemRender={(originNode, file, currFileList) => {
          if (loading) return <></>

          const fileOnLoading = uploadingProgress?.find(uploadingFile => uploadingFile.uid === file.uid)

          if (fileOnLoading && fileOnLoading.percent !== 0 && fileOnLoading.percent !== 100) {
            return (
              <UploadingItem
                percent={fileOnLoading.percent}
                fileName={fileOnLoading.fileName}
                isVideo={isVideo}
                isImage={isImage}
              />
            )
          }

          const entityId = entityIdsMap.find(([url]) => url === file.url)?.[1] || -1

          return (
            <DraggableUploadListItem
              originNode={originNode}
              file={file}
              fileList={currFileList}
              moveRow={moveRow}
              isEditing={isEditing}
              isVideo={isVideo}
              isImageCard={isImage}
              setFileList={setFileList}
              handleRemove={handleRemove}
              entityId={entityId}
            />
          )
        }}
        data-dragger="dragger"
        multiple
      >
        {isEditing && (
          <>
            <div style={{ marginBottom: 10 }}>
              <UploadIcon />
            </div>
            <div style={{ marginBottom: 0 }}>{t<string>('programCreation.clickOrDrag')}</div>

            <div></div>
            <div className="ant-upload-text" data-upload-text="upload-text">
              {isVideo && t<string>('templateCreation.videoSupportedTypes')}
              {isImage && t<string>('templateCreation.imageSupportedTypes')}
            </div>
          </>
        )}
      </Dragger>

      {loading &&
        !isPictureItem &&
        (fileIds?.length
          ? fileIds.map((id, idx) => <LoadingItem key={id} id={id} loadingProgress={loadingProgress || []} />)
          : placeholderArr.map((item, idx) => <div className={styles.loader} key={idx} />))}
    </div>
  )
}

export default observer(DraggableUpload)
