import { Editor } from '@tinymce/tinymce-react'
import { Checkbox, DatePicker, Form, TimePicker } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import { maxWordsValidator } from 'utils/validators/max-words.validator'
import { positiveNumberValidator } from 'utils/validators/positiveNumber.validator'
import { urlValidator } from 'utils/validators/url.validator'

import { IAdditionalFieldsForm } from '../index'

import CustomFormItem from 'components/CustomComponents/CustomFormItem'
import CustomInput from 'components/CustomComponents/CustomInput'
import TextEditor from 'modules/TemplateModule/DayItem/DayTool/ToolSwitch/PlaningTools/SummaryTool/SummaryForm/TextEditor'

import CustomSelect from 'components/CustomComponents/CustomSelect'
import moment from 'moment'
import { IEventPlace } from 'types/EventPlace'
import styles from './styles.module.scss'

const timeRegEx = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

interface IProps {
  onFormChange: () => void
  isAdditionalFields: boolean
  onAdditionalFieldsRevealingChange: (e: CheckboxChangeEvent) => void
  editorRef: React.MutableRefObject<Editor | null>
  handleInsert: (variable: string) => void
  summary: string
  additionalToolHandleChange: (value: string, index: number) => void
  form: FormInstance<IAdditionalFieldsForm>
  eventDate: moment.Moment | null
  setEventDate: Dispatch<SetStateAction<moment.Moment | null>>
  eventTime: moment.Moment | null
  setEventTime: Dispatch<SetStateAction<moment.Moment | null>>
  isEventDayError: boolean
  touched: boolean
  isError: boolean
  places: IEventPlace[]
}

const SummaryForm: FC<IProps> = ({
  onFormChange,
  isAdditionalFields,
  onAdditionalFieldsRevealingChange,
  editorRef,
  handleInsert,
  summary,
  additionalToolHandleChange,
  form,
  eventDate,
  eventTime,
  setEventDate,
  setEventTime,
  isEventDayError,
  touched,
  isError,
  places,
}) => {
  const { t } = useTranslation()
  const { templateStore } = useStore()

  const validateBtnUrl = async (rule: any, value: string) => {
    const btnText = form.getFieldValue('buttonText')

    if (btnText && !value) {
      return Promise.reject()
    }
    return Promise.resolve()
  }

  const validateBtnText = async (rule: any, value: string) => {
    const btnUrl = form.getFieldValue('buttonUrl')

    if (btnUrl && !value) {
      return Promise.reject()
    }
    return Promise.resolve()
  }

  const onSelectVariable = (variable: string, fieldName: string) => {
    let currentState = form.getFieldValue(fieldName)
    currentState = !currentState
      ? ''
      : currentState[currentState.length - 1] === ' '
      ? currentState
      : currentState + ' '

    form.setFieldValue(fieldName, currentState + variable)
    onFormChange()
  }

  const placesOptions = useMemo(
    () =>
      places.map(place => ({
        value: place.id,
        label: place.name,
      })),
    [places]
  )

  return (
    <Form
      form={form}
      onChange={onFormChange}
      onValuesChange={changedValues => {
        if ('buttonText' in changedValues) {
          form.validateFields(['buttonUrl'])
        } else if ('buttonUrl' in changedValues) {
          form.validateFields(['buttonText'])
        }
      }}
    >
      {isAdditionalFields && (
        <>
          <CustomFormItem name="imageLink" label={t('templateCreation.imageUrl')} rules={[{ validator: urlValidator }]}>
            <CustomInput
              big
              isErrorNeeded={false}
              onSelectVariable={variable => onSelectVariable(variable, 'imageLink')}
            />
          </CustomFormItem>

          <CustomFormItem
            name="title"
            label={t('templateCreation.title')}
            rules={[{ max: 30, message: t('shared.maxCharacters', { number: 30 }) }]}
          >
            <CustomInput big isErrorNeeded={false} onSelectVariable={variable => onSelectVariable(variable, 'title')} />
          </CustomFormItem>

          <CustomFormItem
            name="subTitle"
            label={t('templateCreation.subtitle')}
            rules={[{ validator: maxWordsValidator({ number: 11, required: false }) }]}
          >
            <CustomInput
              big
              isErrorNeeded={false}
              onSelectVariable={variable => onSelectVariable(variable, 'subTitle')}
            />
          </CustomFormItem>
          <CustomFormItem
            name="buttonText"
            label={t('templateCreation.registerBtnText')}
            rules={[
              {
                validator: validateBtnText,
                message: t('templateCreation.registerBtnTextError'),
              },
            ]}
          >
            <CustomInput
              big
              isErrorNeeded={false}
              onSelectVariable={variable => onSelectVariable(variable, 'buttonText')}
            />
          </CustomFormItem>
          <CustomFormItem
            name="buttonUrl"
            label={t('templateCreation.registerBtnUrl')}
            rules={[
              { validator: urlValidator },
              {
                validator: validateBtnUrl,
                message: t('templateCreation.registerBtnUrlError'),
              },
            ]}
          >
            <CustomInput
              big
              isErrorNeeded={false}
              onSelectVariable={variable => onSelectVariable(variable, 'buttonUrl')}
            />
          </CustomFormItem>
          <CustomFormItem name="eventDay" label={t('programDetails.eventDay')} rules={[]}>
            <div className={styles.pickers}>
              <DatePicker
                value={eventDate}
                onBlur={e => {
                  if (moment(e.target.value).isValid()) {
                    const [y, m, d] = e.target.value.split('-')
                    const updatedDate = moment(eventDate)
                      .set('year', +y)
                      .set('month', +m - 1)
                      .set('date', +d)
                    setEventDate(updatedDate)
                  }
                }}
                onChange={date => {
                  setEventDate(date)
                }}
                className={styles.picker}
                placeholder={t('dashboard.selectDate')}
              />
              <TimePicker
                value={eventTime}
                onChange={setEventTime}
                onBlur={e => {
                  if (timeRegEx.test(e.target.value)) {
                    const [h, m] = e.target.value.split(':')
                    const updatedTime = moment(eventDate).set('hour', +h).set('minute', +m)
                    setEventTime(updatedTime)
                  }
                }}
                className={styles.picker}
                placeholder={t('programSettings.selectTime')}
                format="HH:mm"
              />
            </div>
            {isEventDayError && touched && (
              <div className={styles.error}>{t('templateCreation.incorrectEventDay')}</div>
            )}
          </CustomFormItem>
          {templateStore.templateEntity.isWallPosts && (
            <CustomFormItem
              name="progressValue"
              label={t('templateCreation.summaryProgressValue')}
              rules={[
                {
                  validator: positiveNumberValidator,
                  required: false,
                },
              ]}
            >
              <CustomInput type="number" big isErrorNeeded={false} />
            </CustomFormItem>
          )}
          {!!placesOptions.length && (
            <CustomFormItem name="eventPlaceId" label={t('eventPlaces.eventPlace')}>
              <CustomSelect options={placesOptions} />
            </CustomFormItem>
          )}
        </>
      )}

      <CustomFormItem label={t('templateCreation.body')}>
        <TextEditor
          additionalToolHandleChange={additionalToolHandleChange}
          editorRef={editorRef}
          handleInsert={handleInsert}
          isEditing={true}
          isError={isError}
          html={summary}
          titleVariable
        />
      </CustomFormItem>

      <Checkbox className="checkboxUI" checked={isAdditionalFields} onChange={onAdditionalFieldsRevealingChange}>
        {t('templateCreation.additionalFields')}
      </Checkbox>
    </Form>
  )
}

export default SummaryForm
