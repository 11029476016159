import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const useRedirectToOneItem = <ItemType extends { id: number }>({
  items,
  pageUrl,
  total,
}: {
  items: ItemType[]
  pageUrl: string
  total: number
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (items.length === 1 && total <= 1) {
      const id = items[0].id

      navigate(pageUrl + id, {
        state: { forceRedirect: true },
        relative: 'path',
        replace: true,
      })
    }
  }, [items, total])

  return
}

export default useRedirectToOneItem
