import { authApi } from 'config'

import { ShortChatDtoPageResponse, TextMessageDtoPageResponse } from './swagger.api'

const endpoint = 'Chat/'

export type GetChatsForUserParams = {
  userId: number
  companyId: number
  pageLength?: number
  pageNumber?: number
}

export type GetMessagesFromChatParams = {
  chatRelationshipId: number
  pageLength?: number
  pageNumber?: number
  returnReadMessage?: boolean
  startTextMessageId?: number
  endTextMessageId?: number
}

const chatApi = {
  async getChatsForUser(params: GetChatsForUserParams) {
    const response = await authApi.get<ShortChatDtoPageResponse>(endpoint + 'forUser', {
      params,
    })

    return response.data
  },
  async getMessagesFromChat(params: GetMessagesFromChatParams) {
    const response = await authApi.get<TextMessageDtoPageResponse>(endpoint + 'getMessagesFromChat', {
      params,
    })

    return response.data
  },
}

export default chatApi
