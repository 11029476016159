import React from 'react'
import styles from './styles.module.scss'

interface IProps {
  title: string
  value: string | number
  Icon: React.CElement<any, any>
}

const AttendanceInfoItem = ({ Icon, title, value }: IProps) => {
  return (
    <div className={styles.attendanceInfoItem}>
      <div className={styles.icon}>{Icon}</div>
      <div className={styles.itemInfo}>
        <div>{title}</div>
        <div>{value}</div>
      </div>
    </div>
  )
}

export default AttendanceInfoItem
