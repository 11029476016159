import { authApi } from 'config'
import { t } from 'i18next'

import { IGoal, IGoalCreate } from 'types/Goal'
import { IDeleteIds, ILightTemplatesWithPagination, IProgramContent, ITemplate, ITemplateInfo } from 'types/Template'
import { ITemplateAPI, ITemplateDayAPI } from 'types/Template/TemplateToServer'
import { RepeatingTypeEnum } from 'types/Template/Tools'
import { IBtnBase, ITempalteBtn } from 'types/TemplateButton'
import { IUnboundContentToServer } from 'types/UnboundContent'
import { PaginationResponse } from 'types/common'

import {
  ICreateEntityTemplateDto,
  ICreateTemplateDto,
  IDeleteTemplateDayDto,
  IDeleteTemplateToolWithRepeatingDto,
  IGetTemplateEntitiesDto,
  IUpdateEntityTemplateDto,
  IUpdateFilterDto,
} from './DTOs/templates.dto'

import message from 'utils/message'
import { ProgramTemplateShortDto, TemplateEntityShortDto } from './swagger.api'

const endpoint = 'Template/'

const templatesApi = {
  async getLightTemplates(str: string, pageNumber: number, pageLength: number) {
    try {
      const response = await authApi.get<ILightTemplatesWithPagination>(endpoint + 'lightTemplatesWithPagination', {
        params: {
          str,
          pageLength,
          pageNumber,
        },
      })

      return response.data
    } catch (e) {
      console.log(e)
    }
  },
  async getLightTemplate(id: number) {
    const response = await authApi.get<ITemplate>(endpoint + 'lightTemplate/' + id)

    return response.data
  },
  async getTemplate(id: number) {
    const response = await authApi.get<ProgramTemplateShortDto>(endpoint + 'template/' + id)

    return response.data
  },
  async deleteTemplate(id: number) {
    await authApi.patch(endpoint + 'delete/' + id)

    return true
  },

  async copyTemplate(name: string, id: number): Promise<number | null> {
    try {
      const res = await authApi.post<ITemplateInfo>(
        endpoint + 'copy/',
        {},
        {
          params: {
            id,
            name,
            copyTemplateEntities: true,
          },
        }
      )

      return res.data.id || null
    } catch (e) {
      const { StatusCode, Message } = e.response.data

      if (StatusCode === 400) {
        const isDuplicate = Message.includes('already have template')
        const templateName = Message.split(':')[1]

        if (isDuplicate) {
          message.error(
            t<string>('templateCreation.templateDuplicate', {
              templateName,
            })
          )
          return null
        }
      }

      message.error(t<string>('programCreation.smtWrong'))

      return null
    }
  },
  async isTemplateNameExists(name: string): Promise<void | boolean> {
    try {
      const response = await authApi.get(endpoint + 'checkTemplateName', {
        params: {
          name,
        },
      })

      return response.data
    } catch (e) {
      console.log(e)
    }
  },
  async getTemplateGoals(templateId: number) {
    const response = await authApi.get<IGoal[]>(endpoint + 'templateProgramGoals/' + templateId)

    return response.data
  },
  async getTemplateEntityShort(templateEntityId: number) {
    const response = await authApi.get<TemplateEntityShortDto>(endpoint + 'templateEntity/short/' + templateEntityId)

    return response.data
  },
  async createTemplateGoals(templateId: number, data: IGoalCreate[]) {
    const response = await authApi.post(endpoint + 'createTemplateProgramGoals/' + templateId, data)

    return response.data
  },
  async editTemplateGoal(goalId: number, data: IGoal) {
    const response = await authApi.patch(endpoint + 'editTemplateProgramGoal/' + goalId, { ...data, fileId: 0 })

    return response.data
  },
  async deleteTemplateGoal(goalId: number) {
    const response = await authApi.delete(endpoint + 'deleteTemplateProgramGoal/' + goalId)

    return response.data
  },
  async getUnboundContent(templateId: number) {
    const response = await authApi.get(endpoint + 'unboundEntites/' + templateId)

    return response.data
  },
  async addUnboundContent(templateId: number, content: IUnboundContentToServer[]) {
    const response = await authApi.post<ITemplateDayAPI[]>(endpoint + 'addUnboundEntitesToTemplate', content, {
      params: {
        templateId,
      },
    })

    return response.data
  },
  async updateUnboundContent(templateId: number, content: IUnboundContentToServer[]) {
    const response = await authApi.patch(endpoint + 'updateUnboundEntitesInTemplate', content, {
      params: {
        templateId,
      },
    })

    return response.data
  },
  async deleteUnboundContent(id: number) {
    const response = await authApi.delete(endpoint + 'unboundEntity/' + id)

    return response.data
  },
  async getTemplateButton(templateId: number) {
    const response = await authApi.get<ITempalteBtn[]>(endpoint + 'templateButtons/' + templateId)

    return response.data
  },
  async createTemplateButton(btn: ITempalteBtn) {
    const response = await authApi.post(endpoint + 'createTemplateButton/', btn)

    return response.data
  },
  async editTemplateButton(btn: IBtnBase) {
    const response = await authApi.patch(endpoint + 'editTemplateButton', btn)

    return response.data
  },
  async deleteTemplateButton(templateButtonId: number) {
    const response = await authApi.delete(endpoint + 'deleteTemplateButton/' + templateButtonId)

    return response.data
  },
  async deleteTemplateEntityTag(id: number) {
    const response = await authApi.delete(endpoint + 'deleteTemplateEntityTag/' + id)

    return response.data
  },
  async deleteEntities(deleteTemplateToolsIds: IDeleteIds[]) {
    const res = await authApi.patch(endpoint + 'deleteEntities', deleteTemplateToolsIds)

    return res.data
  },
  async createEntities(dto: ICreateEntityTemplateDto) {
    const response = await authApi.post(endpoint + 'v2/createEntities/' + dto.templateId, dto.entity)

    return response.data
  },
  async updateEntities(dto: IUpdateEntityTemplateDto, repeatingType?: RepeatingTypeEnum) {
    const response = await authApi.patch(endpoint + 'v2/updateEntities/' + dto.templateId, dto.entity, {
      params: { repeatingType },
    })

    return response.data
  },
  async getTemplateEntities(dto: IGetTemplateEntitiesDto) {
    const { templateId, pageNumber } = dto

    const response = await authApi.get<PaginationResponse<ITemplateDayAPI>>(endpoint + 'templateEntities', {
      params: {
        templateId,
        pageNumber,
        pageLength: 1,
      },
    })

    return response.data
  },
  async createTemplate(dto: ICreateTemplateDto) {
    try {
      const response = await authApi.post<ITemplateAPI>(endpoint + 'create', {
        ...dto,
        filter: {
          hasCalendar: false,
          hasFilter: true,
          hasSearch: true,
        },
      })
      return response.data
    } catch (e) {
      const { StatusCode, Message } = e.response.data

      if (StatusCode === 400) {
        const isDuplicate = Message?.includes('already have template')
        const templateName = Message?.split(':')[1]

        if (isDuplicate) {
          message.error(
            t('templateCreation.templateDuplicate', {
              templateName,
            })
          )
          return
        }

        message.error(t('programCreation.smtWrong'))
        return
      }
      message.error(t('programCreation.smtWrong'))
    }
  },

  async deleteTemplateDays({ dayIds, templateId }: IDeleteTemplateDayDto) {
    const response = await authApi.patch(endpoint + 'deleteEntityRange', dayIds, {
      params: { templateId },
    })

    return response.data
  },
  async getTemplateContent(templateId: number) {
    const response = await authApi.get<PaginationResponse<IProgramContent>>(endpoint + 'templateContent', {
      params: {
        pageNumber: 1,
        pageLength: 99999,
        templateId,
      },
    })

    return response.data.items
  },
  async deleteToolWithRepeating(dto: IDeleteTemplateToolWithRepeatingDto, repeatingType: RepeatingTypeEnum) {
    const result = await authApi.delete(endpoint + 'templateContent', {
      params: {
        repeatingType,
      },
      data: dto,
    })

    return result.data
  },
  async addDay(templateId: number) {
    const response = await authApi.post(endpoint + 'addDay/' + templateId)

    return response.data
  },
  async rename(id: number, name: string) {
    const response = await authApi.patch(endpoint + 'rename', {
      id,
      name,
    })

    return response.data
  },
  async lightProgramTemplate(id: number) {
    const response = await authApi.get<ITemplate>(endpoint + `lightProgramTemplate/${id}`)

    return response.data
  },
  async updateFilter(dto: IUpdateFilterDto) {
    const response = await authApi.patch(endpoint + 'updateFilter', dto)

    return response.data
  },
}

export default templatesApi
