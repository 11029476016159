import { useMemo } from 'react'

import { TraineeGroopingEnum } from 'containers/Private/Mentor/ProgramDetail/ProgramTabbar/TraineesTab'
import { ITraineeWithInvite } from 'types/User'

interface IProps {
  currentTab: TraineeGroopingEnum
  trainee: ITraineeWithInvite
}

const useTraineeStatusMap = ({ currentTab, trainee }: IProps) => {
  const statusMap = useMemo(
    () => ({
      isPending: trainee.inviteDto?.inviteStatus === 'Pending' || currentTab === TraineeGroopingEnum.InvitationPending,
      isRejected: trainee.inviteDto?.inviteStatus === 'Rejected' || currentTab === TraineeGroopingEnum.Rejected,
      isApproved:
        trainee.inviteDto?.inviteStatus === 'Succeed' ||
        currentTab === TraineeGroopingEnum.Approved ||
        (!trainee.inviteDto && !trainee.approvals.some(approval => !approval.isApproved)),
      isParentalApproval:
        trainee.inviteDto?.inviteStatus === 'RequireParentalApproval' ||
        currentTab === TraineeGroopingEnum.NeedParentalApproval ||
        (!trainee.inviteDto && trainee.approvals.some(approval => !approval.isApproved)),
      isError:
        trainee.inviteDto?.inviteStatus === 'Error' ||
        trainee.inviteDto?.inviteStatus === 'Failed' ||
        currentTab === TraineeGroopingEnum.InvitationErr,
    }),
    [trainee, currentTab]
  )

  return statusMap
}

export default useTraineeStatusMap
