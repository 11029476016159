import React, { FC } from 'react'
import { Tooltip } from 'antd'
import { WarningOutlined } from '@ant-design/icons'

import styles from './styles.module.scss'

interface IProps {
  warning: string | null
}

const WarningLabel: FC<IProps> = ({ warning }) => {
  return (
    <Tooltip title={warning}>
      <WarningOutlined className={styles.warn} />
    </Tooltip>
  )
}

export default WarningLabel
