import { ExportOutlined } from '@ant-design/icons'
import { ColumnType } from 'antd/es/table'
import { FilterDropdownProps, SorterResult } from 'antd/lib/table/interface'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'

import { IScheduledNotificationCreateDto } from 'api/DTOs/scheduleNotification.dto'
import scheduleNotificationApi from 'api/scheduleNotification.api'
import userApi from 'api/user.api'

import useAllUserTraineesQuery from 'hooks/tanstack/queries/useAllUserTraineesQuery'
import useLightProgramsWhereMentorQuery from 'hooks/tanstack/queries/useLightProgramsWhereMentorQuery'
import useTraineesCountQuery from 'hooks/tanstack/queries/useTraineesCountQuery'
import useDebounce from 'hooks/useDebounce'
import usePagination from 'hooks/usePagination'

import { IDetails, LastActiveTraineesTimeRange, TraineesLastActiveOrderBy } from 'types/Program'
import { ITraineeWithProgranInfo } from 'types/User'

import dateTime from 'utils/dateTime'
import message, { someError } from 'utils/message'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomDropdown from 'components/CustomComponents/CustomDropdown'
import CustomMenu from 'components/CustomComponents/CustomMenu'
import CustomTable from 'components/CustomComponents/CustomTable'
import FilterIcon from 'components/FilterIcon'
import FilterMenu from 'components/FilterMenu'
import { useFilterTable } from 'components/FilterTable'
import Loader from 'components/Loader'
import PageContainer from 'components/PageContainer'
import Pagination from 'components/Pagination'
import ReminderModal from 'components/ReminderModal'
import SearchBar from 'components/SearchBar'
import TraineesCount from 'components/TraineesCount'

import styles from './styles.module.scss'

interface ITableTrainee extends ITraineeWithProgranInfo {
  children: {
    trainingPrograms: IDetails[]
  }[]
}

const Trainees = () => {
  const [searchTrainee, setSearchTrainee] = useState('')

  const [activeNotifyTraineeId, setActiveNotifyTraineeId] = useState<number | null>(null)
  const [lastActiveOrdering, setLastActiveOrdering] = useState(TraineesLastActiveOrderBy.None)
  const [filter, setFilter] = useState<null | LastActiveTraineesTimeRange>(LastActiveTraineesTimeRange.All)
  const [programIds, setProgramIds] = useState<number[]>([])

  const [exportLoading, setExportLoading] = useState(false)

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { pagination, propsToPaginationComponent, updatePaginationWithQuery, setPagination } = usePagination({
    pageLength: 10,
  })

  const { isLoading: isTraineesCountLoading, traineesCount } = useTraineesCountQuery()

  const searchTraineeQueryDebounced = useDebounce(searchTrainee, 500)

  const { isError, isLoading, trainees, isFetching } = useAllUserTraineesQuery({
    searchTrainee: searchTraineeQueryDebounced,
    pagination,
    updatePaginationWithQuery,
    orderBy: lastActiveOrdering,
    filter: filter === null ? LastActiveTraineesTimeRange.All : filter,
    programIds,
  })

  const tableTrainees = useMemo(() => {
    if (!trainees?.items?.length) return []

    const result: ITableTrainee[] = trainees.items.map(trainee => {
      return {
        ...trainee,
        trainingPrograms: trainee.trainingPrograms.slice(0, 1) || [],
        children: trainee.trainingPrograms.slice(1).map(trainingProgram => {
          return {
            id: v4(),
            trainingPrograms: [trainingProgram],
          }
        }),
      }
    })

    return result
  }, [trainees?.items])

  const { isLoading: optionsLoading, programsOptions } = useLightProgramsWhereMentorQuery()

  const lastActiveColFilter = useFilterTable({
    filter,
    setFilter,
    menuTitle: t('traineesStat.filterTitle'),
    options: [
      { id: LastActiveTraineesTimeRange.All, name: t('traineesStat.total') },
      { id: LastActiveTraineesTimeRange.Day, name: t('traineesStat.active24h') },
      { id: LastActiveTraineesTimeRange.Week, name: t('traineesStat.activeWeek') },
      { id: LastActiveTraineesTimeRange.Month, name: t('traineesStat.activeMonth') },
      { id: LastActiveTraineesTimeRange.Inactive, name: t('traineesStat.inactive') },
    ],
    defaultFilter: LastActiveTraineesTimeRange.All,
  })

  const onCreate = async (data: IScheduledNotificationCreateDto) => {
    const ok = await scheduleNotificationApi.createScheduledNotification(data)

    if (!ok) return false

    setActiveNotifyTraineeId(null)

    return true
  }

  const onExportXlsx = async () => {
    try {
      setExportLoading(true)
      await userApi.exportTrainees({ lastActiveFilter: filter, programIds })
    } catch (e) {
      someError()
    } finally {
      setExportLoading(false)
    }
  }

  const columns: ColumnType<ITableTrainee>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id, row) => (!row.children ? '' : id),
    },
    {
      title: t('dashboard.phoneNumber'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (value: string) => <span dir="ltr">{value}</span>,
    },
    {
      title: t('dashboard.firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: t('dashboard.lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: t('dashboard.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('dashboard.trainingProgram'),
      dataIndex: 'trainingProgram',
      key: 'trainingProgram',
      render: (value: string, row) => {
        if (row?.trainingPrograms?.[0]?.name) {
          return row?.trainingPrograms[0]?.name
        } else {
          return value ?? '–'
        }
      },
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterMenu
          selectedIds={programIds}
          setSelectedIds={setProgramIds}
          items={programsOptions}
          onPageReset={() => setPagination(prev => ({ ...prev, pageNumber: 1 }))}
          onReset={() => {
            setProgramIds([])
            setPagination(prev => ({ ...prev, pageNumber: 1 }))
            props.close()
          }}
          title={t('dashboard.filterPrograms')}
        />
      ),
      filteredValue: programIds,
      filterIcon: () => <FilterIcon active={!!programIds.length} />,
    },
    //@ts-ignore
    {
      title: t('dashboard.lastActive'),
      dataIndex: 'lastActiveTime',
      key: 'lastActiveTime',
      render: value => {
        if (!value) return ''
        return dateTime.dateWithTime(value)
      },
      sorter: true,
      showSorterTooltip: {
        title:
          lastActiveOrdering === TraineesLastActiveOrderBy.Ascending
            ? t('shared.ascending')
            : lastActiveOrdering === TraineesLastActiveOrderBy.Descending
            ? t('shared.descending')
            : t('shared.sortingDisabled'),
      },
      ...lastActiveColFilter,
    },
    {
      title: '',
      render: (_: string, row: ITraineeWithProgranInfo) => {
        const menu = (
          <CustomMenu
            items={[
              {
                key: '1',
                label: t('templateList.copyId'),
                onClick: async e => {
                  e.domEvent.stopPropagation()
                  await navigator.clipboard.writeText(row.id.toString())
                  message.success(t('templateList.copiedId'))
                },
              },
              {
                key: '2',
                label: t('integration.scheduledNotifications'),
                onClick: async e => {
                  e.domEvent.stopPropagation()
                  setActiveNotifyTraineeId(row.id)
                },
              },
              {
                key: '3',
                label: t('trainee.traineeProfile'),
                onClick: async e => {
                  e.domEvent.stopPropagation()
                  navigate('/trainees/' + row.phoneNumber)
                },
              },
            ]}
          />
        )

        return <CustomDropdown overlay={menu} size={22} />
      },
      width: 22,
    },
  ]

  const onTableChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<ITraineeWithProgranInfo> | SorterResult<ITraineeWithProgranInfo>[]
  ) => {
    //@ts-ignore
    const order = sorter?.order
    switch (order) {
      case 'ascend':
        setLastActiveOrdering(TraineesLastActiveOrderBy.Ascending)
        break
      case 'descend':
        setLastActiveOrdering(TraineesLastActiveOrderBy.Descending)
        break
      default:
        setLastActiveOrdering(TraineesLastActiveOrderBy.None)
        break
    }
    setPagination(prev => ({ ...prev, pageNumber: 1 }))
  }
  const searchBlocks = {
    [LastActiveTraineesTimeRange.All]: t('traineesStat.total'),
    [LastActiveTraineesTimeRange.Inactive]: t('traineesStat.inactive'),
    [LastActiveTraineesTimeRange.Day]: t('traineesStat.active24h'),
    [LastActiveTraineesTimeRange.Week]: t('traineesStat.activeWeek'),
    [LastActiveTraineesTimeRange.Month]: t('traineesStat.activeMonth'),
  }

  if (isLoading || isTraineesCountLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Loader />
      </div>
    )
  }

  if (isError || !trainees) {
    return <div className={styles.loadingContainer}>{t('programCreation.smtWrong')}</div>
  }

  return (
    <PageContainer fullScreen>
      <div className={styles.header}>
        <div className={styles.title}>{t('dashboard.trainees')}</div>
        <SearchBar
          value={searchTrainee}
          placeholder={t('traineesStat.searchUserIn', {
            block: searchBlocks[filter || LastActiveTraineesTimeRange.All],
          })}
          onChangeCustom={value => setSearchTrainee(value)}
          data-search-user="search-user"
          id="search-user"
          className={styles.searchInput}
        />
      </div>
      {!!traineesCount && (
        <div className={styles.statistic}>
          <TraineesCount count={traineesCount} filter={filter} setFilter={setFilter} />
        </div>
      )}
      <div className={styles.subheader}>
        <CustomButton
          className={styles.exportBtn}
          icon={<ExportOutlined />}
          loading={exportLoading}
          size="small"
          onClick={onExportXlsx}
        >
          {t('shared.export')}
        </CustomButton>
      </div>
      <CustomTable
        rowKey="id"
        //@ts-ignore
        columns={columns}
        dataSource={tableTrainees}
        loading={isFetching}
        onChange={onTableChange}
        onRow={data => {
          return {
            onClick(e) {
              navigate('/trainees/' + data.phoneNumber)
            },
          }
        }}
        pagination={false}
        variant="expandable"
        rowClassName={styles.row}
      />
      <Pagination
        {...propsToPaginationComponent}
        loading={isLoading}
        totalItems={trainees.totalItems}
        currentPage={propsToPaginationComponent.currentPage || 1}
        onPageChange={page => {
          setPagination(prev => ({ ...prev, pageNumber: page }))
        }}
        onSizeChange={size => {
          setPagination(prev => ({ ...prev, pageLength: size }))
        }}
      />
      <ReminderModal
        visible={!!activeNotifyTraineeId}
        setVisible={() => setActiveNotifyTraineeId(null)}
        initialReminderData={null}
        setInitialReminderData={() => void 0}
        onCreate={onCreate}
        onEdit={async () => false}
        programId={null}
        toTraineeId={activeNotifyTraineeId}
      />
    </PageContainer>
  )
}

export default observer(Trainees)
