import { CopyOutlined } from '@ant-design/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import message from 'utils/message'

import styles from './styles.module.scss'

interface IProps {
  entityId?: number | null
}

const ContentId: FC<IProps> = ({ entityId }) => {
  const { t } = useTranslation()

  if (!entityId || entityId === -1) return <></>

  return (
    <div className={styles.idContainer}>
      <div>{t<string>('templateCreation.contentNumber')}:</div>
      <div
        className={styles.copyBtn}
        onClick={async () => {
          await navigator.clipboard.writeText(entityId.toString())
          message.success(t<string>('templateList.copiedId'))
        }}
      >
        {entityId} <CopyOutlined />
      </div>
    </div>
  )
}

export default ContentId
