import { authApi } from 'config'

import { IDeleteIds, IProgramContent, IProgramDays } from 'types/Template'
import { ITemplateSummaryAPI, IWallPostDayAPI } from 'types/Template/TemplateToServer'
import { IBtnBase, IWallPostBtn } from 'types/TemplateButton'
import { IUnboundContentWallPosts } from 'types/UnboundContent'

import { RepeatingTypeEnum } from 'types/Template/Tools'
import { PaginationResponse } from 'types/common'
import {
  IChangeWallPostDateDto,
  ICreateWallPostEntityDto,
  IDeleteWallPostDays,
  IDeleteWallToolWithRepeatingDto,
  IGetNewsParams,
  IGetWallPostDto,
  ISearchContentParams,
  IUpdateWallPostEntityDto,
} from './DTOs/wallPost.dto'

const endpoint = 'WallPost/'

const wallPostApi = {
  async getUnboundWallPosts(trainingProgramId: number) {
    const response = await authApi.get(`${endpoint}unboundWallPosts/${trainingProgramId}`)

    return response.data
  },
  async createUnbound(programId: number, content: IUnboundContentWallPosts[]) {
    const response = await authApi.post<IWallPostDayAPI[]>(endpoint + 'createUnbound', content, {
      params: {
        programId,
      },
    })

    return response.data
  },
  async updateUnboundRange(content: IUnboundContentWallPosts[]) {
    const response = await authApi.patch(endpoint + 'updateUnboundRange', content)

    return response.data
  },
  async deleteUnboundContent(ids: number[]) {
    let queryString = ''

    ids.forEach((id, idx) => {
      if (idx === 0) {
        queryString += 'ids=' + id
      } else {
        queryString += '&ids=' + id
      }
    })

    const response = await authApi.delete(endpoint + `deleteUnboundRange?${queryString}`)

    return response.data
  },
  async getWallPostButtons(programId: number) {
    const response = await authApi.get<IWallPostBtn[]>(endpoint + 'wallPostButtons/' + programId)

    return response.data
  },
  async createWallPostButton(btn: IWallPostBtn) {
    const response = await authApi.post(endpoint + 'createWallPostButton/', btn)

    return response.data
  },
  async editWallPostButton(btn: IBtnBase) {
    const response = await authApi.patch(endpoint + 'editWallPostButton/', btn)

    return response.data
  },
  async deleteTemplateButton(buttonId: number) {
    const response = await authApi.delete(endpoint + 'deleteWallPostButton/' + buttonId)

    return response.data
  },
  async deletePostEntityTag(id: number) {
    const response = await authApi.delete(endpoint + 'deletePostEntityTag/' + id)

    return response.data
  },
  async changeButtonActiveStatus({ buttonId, isActive }: { buttonId: number; isActive: boolean }) {
    const response = await authApi.patch(
      endpoint + 'changeButtonActiveStatus/',
      {},
      {
        params: {
          buttonId,
          isActive,
        },
      }
    )

    return response.data
  },
  async deleteEntities(deleteTemplateToolsIds: IDeleteIds[]) {
    const res = await authApi.patch(endpoint + 'deleteEntities', deleteTemplateToolsIds)

    return res.data
  },
  async createEntities(dto: ICreateWallPostEntityDto) {
    const response = await authApi.post(endpoint + 'v2/createWallPostEntities', dto)

    return response.data
  },
  async updateEntities(dto: IUpdateWallPostEntityDto, repeatingType?: RepeatingTypeEnum) {
    const response = await authApi.patch(endpoint + 'v2/updateWallPostEntities', dto, {
      params: { repeatingType },
    })

    return response.data
  },
  async getWallPost(dto: IGetWallPostDto) {
    const { pageNumber, programId } = dto

    const response = await authApi.get(endpoint + 'wallPosts/' + programId, {
      params: {
        pageLength: 1,
        pageNumber,
      },
    })

    return response.data
  },
  async deleteWallPostDays({ dayIds, trainingProgramId }: IDeleteWallPostDays) {
    const response = await authApi.patch(endpoint + 'deleteRange', dayIds, {
      params: {
        trainingProgramId,
      },
    })

    return response.data
  },
  async getProgramContent(programId: number, dateTimeTill?: string) {
    const response = await authApi.get<IProgramContent[]>(endpoint + 'programContent/' + programId, {
      params: {
        dateTimeTill,
      },
    })

    return response.data
  },
  async deleteToolWithRepeating(dto: IDeleteWallToolWithRepeatingDto, repeatingType: RepeatingTypeEnum) {
    const result = await authApi.delete(endpoint + 'content', {
      params: {
        repeatingType,
      },
      data: dto,
    })

    return result.data
  },
  async addDay(programId: number) {
    const response = await authApi.post(endpoint + 'addDay/' + programId)

    return response.data
  },
  async changeDate(dto: IChangeWallPostDateDto) {
    const response = await authApi.patch('WallPost/changeDate', dto)

    return response.data
  },
  async deleteRange(deleteTemplateDaysIds: number[], trainingProgramId: string) {
    await authApi.patch('WallPost/deleteRange', deleteTemplateDaysIds, {
      params: {
        trainingProgramId,
      },
    })
  },
  async getEvents(trainingProgramId: number) {
    const response = await authApi.get<string[]>(`WallPost/events/${trainingProgramId}`)

    return response.data
  },
  async wallNews(dto: IGetNewsParams) {
    const defaultDto: IGetNewsParams = { ...dto, pageLength: 9999 }
    const response = await authApi.post<PaginationResponse<ITemplateSummaryAPI>>('WallPost/wallNews', defaultDto)
    return response.data.items
  },
  async getWallPostDays(trainingProgramId: number) {
    const response = await authApi.get<IProgramDays[]>(`WallPost/days/${trainingProgramId}`)
    return response.data
  },
  async searchContent(params: ISearchContentParams) {
    const response = await authApi.get<IWallPostDayAPI[]>(`WallPost/searchContent`, { params })
    return response.data
  },
  async getWallPostById(wallPostId: number) {
    const response = await authApi.get(`${endpoint}wallPost/${wallPostId}`)

    return response.data
  },
}

export default wallPostApi
