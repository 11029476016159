import { useQuery } from '@tanstack/react-query'

import userApi from 'api/user.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

interface IProps {
  programId: number | null
}

const useShortTraineesQuery = ({ programId }: IProps) => {
  const {
    data: trainees,
    isLoading,
    isError,
  } = useQuery({
    keepPreviousData: true,
    queryKey: [QueryKeysEnum.USER_SHORT_TRAINEES, programId],
    queryFn: async () => {
      const trainees = await userApi.getShortAllTrainees(programId)
      return trainees
    },
    initialData: [],
  })

  return { trainees, isLoading, isError }
}

export default useShortTraineesQuery
