import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import tagsApi from 'api/tags.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { IMentorTag } from 'types/Tags'

const checkIfFromMoreThanOneCompany = (tags: IMentorTag[]) => {
  const ids: number[] = []

  tags.forEach(tag => {
    if (!ids.includes(tag.companyId)) {
      ids.push(tag.companyId)
    }
  })

  return ids.length > 1
}

const useMentorTagsQuery = () => {
  const {
    data: tags,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QueryKeysEnum.MENTOR_TAGS],
    initialData: [],
    queryFn: async () => {
      const tags = await tagsApi.getMentorTags()

      return tags
    },
  })

  const fromMoreThanOneCompany = useMemo(() => checkIfFromMoreThanOneCompany(tags), [tags])

  return { tags, fromMoreThanOneCompany, isLoading, isError }
}

export default useMentorTagsQuery
