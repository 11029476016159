import React from 'react'
import { useTranslation } from 'react-i18next'

import PageContainer from 'components/PageContainer'
import ChatInput from './ChatInput'
import ChatMessages from './ChatMessages'

import styles from './styles.module.scss'

const Chat: React.FC = () => {
  const { t } = useTranslation()

  return (
    <PageContainer className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t('chat.chat')}</h2>
      </div>
      <div className={styles.content}>
        <ChatMessages />
        <ChatInput />
      </div>
    </PageContainer>
  )
}

export default Chat
