import { Input } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import useCheckIfUploadingFiles from 'hooks/tools/useCheckIfUploadingFiles'
import useDeleteEntity from 'hooks/tools/useDeleteEntity'
import useFileUpload from 'hooks/tools/useFileUpload'
import useInstallToolFileType from 'hooks/tools/useInstallToolFileType'
import useSaveTool from 'hooks/tools/useSaveTool'
import useSaveToolWithPlanning from 'hooks/tools/useSaveToolWithPlanning'
import useToolFileLoading from 'hooks/tools/useToolFileLoading'
import useToolSchedule from 'hooks/tools/useToolSchedule'
import useToolScroll from 'hooks/tools/useToolScroll'
import useToolState from 'hooks/tools/useToolState'
import useToolTags from 'hooks/tools/useToolTags'
import useVideoLinkValidate from 'hooks/tools/useVideoLinkValidate'

import { useStore } from 'stores'

import { deleteEntityTypeEnum, IDefaultToolProps, ITagWithToolRelation, IVideoDayItem } from 'types/Template'
import { RepeatingTypeEnum, schedulerOptionEnum } from 'types/Template/Tools'

import message from 'utils/message'
import checkIfNeedShedulerConfirm from 'utils/tools/checkIfNeedShedulerConfirm'
import pickVideoValidation from 'utils/tools/pickVideoValidation'
import { toolStateCopyInitForVideoTypeTool } from 'utils/toolStateCopyInit'

import ToolRevealedPanel from 'modules/TemplateModule/DayItem/DayTool/ToolSwitch/ToolRevealedPanel'
import ToolWrapper from 'modules/TemplateModule/DayItem/DayTool/ToolSwitch/ToolWrapper'
import VideoLinkItem from './VideoLinkItem'

import CustomButton from 'components/CustomComponents/CustomButton'
import DraggableUpload from 'components/DragableUploadList'

import styles from './styles.module.scss'

let stateCopy = toolStateCopyInitForVideoTypeTool()

const VideoTool = ({ tool, toolIndex, isDraggingItem, isUnbound, dayId, dayIndex, onDelete }: IDefaultToolProps) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const [videoFileList, setVideoFileList] = useState<UploadFile<any>[]>([])
  const [videoLink, setVideoLink] = useState('')
  const [modifiedVideoLink, setModifiedVideoLink] = useState('')
  const [currentVideo, setCurrentVideo] = useState<UploadFile<any>>()
  const [toolData, setToolData] = useState<IVideoDayItem | null>(null)
  const [videoLinks, setVideoLinks] = useState<string[]>([])

  const {
    isAllDaySelected,
    isEditing,
    isFirstDrop,
    isSchedulerModalVisible,
    isUpdating,
    revealedTime,
    setIsAllDaySelected,
    setIsEditing,
    setIsFirstDrop,
    setIsSchedulerModalVisible,
    setIsUpdating,
    setRevealedTime,
  } = useToolState()

  const thisTool = tool as IVideoDayItem

  const { handleTagsChange, selectedTagIds, setSelectedTagIds, selectedTags, entityTagsToRemove } = useToolTags({
    tags: thisTool.entityTags as ITagWithToolRelation[],
  })
  const { schedulerValue, setSchedulerValue, daysSheduleValue, setDaysScheduleValue } = useToolSchedule({
    initialSchedule: thisTool.schedulerOption,
    initialDaysSchedule: thisTool.daysSheduleValue,
  })

  const { toolLoading, loadingProgress } = useToolFileLoading({
    item: thisTool,
    setFiles: setVideoFileList,
    toolIndex,
  })

  const { someFilesUploadInProgress } = useCheckIfUploadingFiles({ fileList: videoFileList })

  const { forceUploadFile, uploadingProgress } = useFileUpload({
    toolIndex,
  })

  useEffect(() => {
    if (thisTool?.videoLinks) {
      setVideoLinks(thisTool?.videoLinks)
    }
  }, [thisTool?.videoLinks])

  const { isMounted } = useInstallToolFileType<IVideoDayItem>({
    setIsAllDaySelected,
    setRevealedTime,
    toolInstance: thisTool,
    setFileList: setVideoFileList,
    setToolData,
    setIsEditing,
    setIsFirstDrop,
    isUnbound,
  })

  const {
    addEntityToDeleting,
    onDeleteEntities,
    resetEntityIdsToDelete,
    addUniqueIdToExclude,
    resetUniqueIdToExclude,
    uniqIdsToExclude,
  } = useDeleteEntity({ isUnbound })

  const { onSave, savingLoading } = useSaveTool({ entityTagsToRemove, isUnbound, dayId })

  const { validateVideoLink } = useVideoLinkValidate({ setModifiedVideoLink })

  useToolScroll({
    isMounted,
    isFirstDrop,
    uniqueId: toolData?.uniqueId,
  })

  useEffect(() => {
    if (isMounted) {
      if (isDraggingItem) return

      const videoData = thisTool

      if (!videoData) return

      setToolData(videoData)

      setVideoLinks(videoData.videoLinks)
      setVideoFileList(videoData.fileList)

      if (videoData?.isAllDaySelected) {
        setIsAllDaySelected(videoData.isAllDaySelected)
      } else {
        setRevealedTime(videoData.revealedTime ? moment(videoData.revealedTime).local() : undefined)
        setIsAllDaySelected(false)
      }
    }
  }, [thisTool])

  const onChangeVideoFile = async ({ fileList }: { fileList: UploadFile<any>[]; file: UploadFile<any> }) => {
    if (!fileList.length) return setVideoFileList([])

    const filteredExisted = fileList.filter(file => !videoFileList.some(existedFile => existedFile.uid === file.uid))
    const filteredFileListByValidation = pickVideoValidation(filteredExisted)

    if (!filteredFileListByValidation) return

    filteredFileListByValidation.forEach((file: any) => {
      const objectURL = URL.createObjectURL(file?.originFileObj || file)
      if (!file.url) file.url = objectURL
    })

    for (let i = 0; i < filteredFileListByValidation.length; i++) {
      forceUploadFile(filteredFileListByValidation[i])
    }

    setVideoFileList(prevState => [...prevState, ...filteredFileListByValidation])
  }

  const validationBeforeActions = () => {
    if (!videoFileList.length && !videoLinks.length) {
      message.error(t('templateCreation.uploadVideoError'))
      return true
    }

    if (!revealedTime && !isAllDaySelected) {
      message.error(t('templateCreation.revealedTimeError'))
      return true
    }
    if (
      videoLink &&
      !videoLink.includes('player.vimeo.com') &&
      !videoLink.includes('vimeo.com') &&
      !videoLink.includes('youtube.com') &&
      !videoLink.includes('youtu.be')
    ) {
      message.error(t('templateCreation.vimeoAndYoutubeLinkOnly'))
      return true
    }
    if (schedulerValue === schedulerOptionEnum.specificDay && !daysSheduleValue.length) {
      message.error(t('templateCreation.selectAtLeastOneDay'))
      return true
    }
    return false
  }

  const handleSaveClick = async (repeatingOption?: RepeatingTypeEnum) => {
    if (validationBeforeActions()) return

    setIsUpdating(true)

    if (checkIfNeedShedulerConfirm({ repeatingOption, schedulerValue, thisTool })) {
      return setIsSchedulerModalVisible(true)
    }

    if (!toolData) return

    const uniqIds = toolData.uniqueId.filter(id => !uniqIdsToExclude.includes(id))
    const videoLinksUIDs = toolData.videoLinksUIDs.filter(id => !uniqIdsToExclude.includes(id))

    const tool = {
      ...thisTool,
      id: toolData?.id ?? -1,
      fileIds: toolData?.fileIds,
      entityIds: toolData?.entityIds,
      fileList: videoFileList,
      modifiedVideoLink,
      revealedTime: revealedTime?.toISOString(true).slice(0, -6) as string, // ISO string w/o offset
      isAllDaySelected,
      uniqueId: videoFileList.map((file, idx) => uniqIds[idx] || v4()),
      // uniqueId: templateEntity.currentDay?.dayItems?.[toolIndex]?.uniqueId || v4(),
      fromDay: toolData?.fromDay,
      position: isFirstDrop ? (isUnbound ? 0 : templateEntity.lastPosition) : toolData?.position || toolIndex,
      schedulerOption: schedulerValue,
      videoLinks,
      videoLinksEntityIds: toolData.videoLinksEntityIds,
      entityTags: selectedTags,
      videoLinksUIDs: videoLinks.map((link, idx) => videoLinksUIDs[idx] || v4()),
      repeatId: toolData?.repeatId || v4(),
      daysSheduleValue,
    } as IVideoDayItem

    try {
      await onSave({ tool, onDeleteEntities, repeatingOption })

      setModifiedVideoLink('')
      saveToolFlagsSet()
    } catch (e) {
      message.error(t('programCreation.smtWrong'))
    }
  }

  const saveToolFlagsSet = () => {
    templateStore.setIsToolEditing(false, isUnbound)

    setIsEditing(false)
    setIsFirstDrop(false)
    setIsUpdating(false)
    resetUniqueIdToExclude()
  }

  const { onSaveWithPlanning } = useSaveToolWithPlanning({ onSave: handleSaveClick, setIsSchedulerModalVisible })

  const handleLoadVideo = async () => {
    if (!videoLink) {
      message.error(t('templateCreation.linkRequired'))
      return
    }
    const isValid = await validateVideoLink(videoLink)

    if (isValid) {
      setVideoLink('')
      setModifiedVideoLink('')
      setVideoLinks(prevState => [...prevState, videoLink])
      setToolData(prevState =>
        prevState
          ? {
              ...prevState,
              videoLinksEntityIds: [...prevState.videoLinksEntityIds, -1],
            }
          : null
      )
    }
  }

  const handleToolEdit = () => {
    if (templateStore.checkIfToolEditing(isUnbound)) {
      message.error(t('templateCreation.completePreviousEdit'))
      return
    }

    if (!isEditing) {
      templateStore.setIsToolEditing(true, isUnbound)

      stateCopy = {
        videoFileList: toolData?.fileList || [],
        videoLink,
        modifiedVideoLink,
        revealedTime,
        isAllDaySelected,
        schedulerValue,
        entityIds: thisTool.entityIds || [],
        entityTags: selectedTagIds,
        daysSheduleValue,
      }
    } else {
      rollBackChanges()
    }
    setIsEditing(prevState => !prevState)
  }

  const rollBackChanges = () => {
    if (isFirstDrop) {
      onDelete(() => setIsEditing(false))
      return
    }

    setDaysScheduleValue(stateCopy.daysSheduleValue)

    stateCopy.isAllDaySelected && setIsAllDaySelected(stateCopy.isAllDaySelected)

    if (stateCopy.videoFileList) {
      setVideoFileList(stateCopy.videoFileList)
    }

    setRevealedTime(stateCopy.revealedTime)
    setVideoLink(stateCopy.videoLink)
    setModifiedVideoLink(stateCopy.modifiedVideoLink)
    setSelectedTagIds(stateCopy.entityTags)
    if (stateCopy.schedulerValue) {
      setSchedulerValue(stateCopy.schedulerValue)
      handleSelectSchedulerOption(stateCopy.schedulerValue)
    }

    templateStore.setIsToolEditing(false, isUnbound)

    resetUniqueIdToExclude()
    resetEntityIdsToDelete()
    setIsEditing(false)
  }

  const handleRemove = (file: UploadFile<any>) => {
    if (!toolData) return

    const imageDataCopy = { ...toolData }

    setVideoFileList(prevState =>
      prevState.filter((item, fileIndex) => {
        if (item.uid === file.uid) {
          const filteredFileIds = toolData?.fileIds?.filter((item, fileIdIndex) => fileIdIndex !== fileIndex) ?? []
          const filteredItemIds =
            toolData?.entityIds?.filter((entityId, fileIdIndex) => {
              if (fileIdIndex === fileIndex) {
                addEntityToDeleting({ id: [entityId], entity: deleteEntityTypeEnum.video })

                if (tool.uniqueId[fileIndex] && !isUnbound) {
                  addUniqueIdToExclude(tool.uniqueId[fileIndex])
                }

                return false
              }

              return true
            }) ?? []

          imageDataCopy.fileIds = filteredFileIds
          imageDataCopy.entityIds = filteredItemIds
          setToolData(imageDataCopy)
          return false
        }
        return true
      })
    )
    setCurrentVideo(undefined)
  }

  const handleSelectSchedulerOption = (value: number) => {
    if (!toolData || schedulerValue === value || validationBeforeActions()) return

    setSchedulerValue(value)
  }

  const handleDeleteVideoLink = (index: number) => {
    if (!toolData?.videoLinksEntityIds) return

    const newEntityIds = toolData?.videoLinksEntityIds.filter((entityId, filterIndex) => index !== filterIndex)
    const newVideoLinksEntityIds = toolData.videoLinksEntityIds.filter((link, filterIndex) => index !== filterIndex)
    setToolData(prevState =>
      prevState
        ? {
            ...prevState,
            videoLinksEntityIds: newVideoLinksEntityIds,
          }
        : null
    )
    const newVideoLinks = videoLinks.filter((link, filterIndex) => {
      if (filterIndex !== index) return true

      const videoLinkUIDToExclude = toolData.videoLinksUIDs[filterIndex]

      if (videoLinkUIDToExclude && !isUnbound) {
        addUniqueIdToExclude(videoLinkUIDToExclude)
      }

      return false
    })

    setVideoLinks(newVideoLinks)
    setToolData(prevState =>
      prevState
        ? {
            ...prevState,
            videoLinksEntityIds: newEntityIds,
          }
        : null
    )
    setModifiedVideoLink('')
  }

  return (
    <ToolWrapper
      id={isUnbound ? dayId?.toString() || '' : toolData?.uniqueId?.[0]}
      isEditing={isEditing}
      handleSaveClick={() => handleSaveClick()}
      rollBackChanges={rollBackChanges}
      daysValue={daysSheduleValue}
      onDaysValueChange={setDaysScheduleValue}
      data-container-video="container-video"
      handleToolEdit={handleToolEdit}
      isFirstDrop={isFirstDrop}
      toolNumber={isUnbound ? dayIndex + 1 : toolIndex + 1}
      revealedAt={isAllDaySelected || revealedTime}
      isUnbound={isUnbound}
      handleSelectSchedulerOption={handleSelectSchedulerOption}
      schedulerValue={schedulerValue}
      handleTagsChange={handleTagsChange}
      selectedTagIds={selectedTagIds}
      onDelete={() => {
        onDelete(() => setIsEditing(false))
      }}
      savingLoading={savingLoading}
      handleSchedulerApplyModalOk={onSaveWithPlanning}
      isSchedulerModalVisible={isSchedulerModalVisible}
      isUpdating={isUpdating}
      setIsSchedulerModalVisible={setIsSchedulerModalVisible}
      srcTool={thisTool}
      isSaveDisabled={someFilesUploadInProgress}
      dayId={dayId}
    >
      {isEditing && (
        <div className={styles.uploadFromOutside} data-upload-from-outside="uploadFromOutside">
          <Input
            placeholder={t<string>('templateCreation.videoLinkInTool')}
            value={videoLink}
            onChange={e => {
              setVideoLink(e.target.value)
              setModifiedVideoLink('')
            }}
            disabled={!isEditing}
            data-insert-link-here="insertLinkHere"
            className={styles.linkInput}
            suffix={
              <CustomButton
                onClick={handleLoadVideo}
                disabled={!isEditing}
                style={{ paddingTop: '0px !important' }}
                buttonSize="small"
                data-load-video="load-video"
                id="load-video"
              >
                {t<string>('templateCreation.loadVideo')}
              </CustomButton>
            }
          />
        </div>
      )}

      {isEditing && (
        <div className={styles.or} data-or="or">
          {t<string>('templateCreation.orBig').toLocaleLowerCase()}
        </div>
      )}
      <DraggableUpload
        fileList={videoFileList}
        setFileList={setVideoFileList}
        handleFileUpload={onChangeVideoFile}
        handleRemove={handleRemove}
        isEditing={isEditing}
        handlePreview={file => (currentVideo ? setCurrentVideo(undefined) : setCurrentVideo(file))}
        isVideo={true}
        videoLink={videoLink}
        toolData={toolData}
        setToolData={setToolData}
        loading={toolLoading}
        fileIds={thisTool.fileIds}
        uploadingProgress={uploadingProgress}
        loadingProgress={loadingProgress}
        data-video-tool="video-tool"
      />

      {videoLinks.length ? (
        <div className={styles.linksContainer} data-links-container="linksContainer">
          {videoLinks.map((videoLink, index) => (
            <VideoLinkItem
              key={`${videoLink}-${index}`}
              entityId={thisTool.videoLinksEntityIds[index]}
              isEditing={isEditing}
              handleDeleteVideoLink={() => handleDeleteVideoLink(index)}
              videoLink={videoLink}
            />
          ))}
        </div>
      ) : null}

      {isEditing && !isUnbound && (
        <>
          <ToolRevealedPanel
            isAllDaySelected={isAllDaySelected}
            isEditing={isEditing}
            onChangeCheckbox={e => setIsAllDaySelected(e.target.checked)}
            onChangeTimePicker={value => setRevealedTime(value ?? undefined)}
            revealedTime={revealedTime}
          />
        </>
      )}
    </ToolWrapper>
  )
}

export default observer(VideoTool)
