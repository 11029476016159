import { FC } from 'react'

import CreateProgramBtn from 'components/CreateProgramBtn'
import CreateTemplateBtn from 'components/CreateTemplateBtn'

import styles from './styles.module.scss'

interface IProps {
  message: string
  type: 'templates' | 'programs'
  showActionButton: boolean
}

const NoItemsInList: FC<IProps> = ({ message, type, showActionButton }) => {
  return (
    <div className={styles.container} data-no-data="no-data">
      <div className={styles.message}>{message}</div>
      {showActionButton && (
        <div className={styles.btn}>
          {type === 'templates' ? <CreateTemplateBtn type="link" /> : <CreateProgramBtn type="link" />}
        </div>
      )}
    </div>
  )
}

export default NoItemsInList
