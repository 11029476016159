import { ConfigProvider, Modal, Radio, RadioChangeEvent } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { LanguagesType } from 'types/common'

import { useStore } from 'stores'
import { languages } from 'utils/getLanguageFlag'

import styles from './styles.module.scss'

interface IProps {
  isVisible: boolean
  setIsVisible: (flag: boolean) => void
}

const LanguageSelect: React.FC<IProps> = ({ isVisible, setIsVisible }) => {
  const { t, i18n } = useTranslation()
  const { userStore } = useStore()

  const changeLanguage = (lang: LanguagesType) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('i18nextLng', lang)
    userStore.setLanguage(lang, !userStore.isAdmin)

    if (lang === 'heMale' || lang === 'heFemale' || lang === 'heUndefined' || lang === 'heSababa') {
      document.body.dir = 'rtl'
    } else {
      document.body.dir = 'ltr'
    }
  }

  const handleChange = (e: RadioChangeEvent) => {
    changeLanguage(e.target.value)
  }

  return (
    <ConfigProvider direction="ltr">
      <Modal
        title={t<string>('sidebar.selectLang')}
        open={isVisible}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        className={styles.languageModal}
        footer={null}
      >
        <Radio.Group
          options={languages}
          defaultValue={userStore.language}
          onChange={handleChange}
          className={styles.langSelect}
          data-language-select="language-select"
          id="language-select"
        />
      </Modal>
    </ConfigProvider>
  )
}

export default LanguageSelect
