import { AxiosError } from 'axios'

export interface IErrorMsg {
  message: string
  isBanned?: boolean
}

export interface IPagination {
  pageLength: number
  pageNumber: number
  totalItems: number
  totalPages: number
  itemCount: number
}

export type LanguagesType = 'en' | 'ru' | 'heMale' | 'heFemale' | 'heUndefined' | 'heSababa'

export type PaginationResponse<T> = {
  itemCount: number
  items: T[]
  pageLength: number
  pageNumber: number
  totalItems: number
  totalPages: number
  skip: number
  take: number
}

export enum UserLanguagesEnum {
  RU = 'ru',
  EN = 'en',
  HE_UNDEFINED = 'heUndefined',
  HE_MALE = 'heMale',
  HE_FEMALE = 'heFemale',
  HE_LIGHT = 'heSababa',
}

export enum UserLanguagesReversedEnum {
  ru = 'RU',
  en = 'EN',
  heUndefined = 'HE_UNDEFINED',
  heMale = 'HE_MALE',
  heFemale = 'HE_FEMALE',
  heSababa = 'HE_LIGHT',
}

export interface IFileName {
  id: number
  key: string
  mimeType: string
  name: string
  size: number
}

export enum InviteSMSEnum {
  Default,
  Enable,
  Disable,
}

export type ApiError = AxiosError<{ Message: string; StatusCode: number }>

export interface ISelectorData {
  value: number
  label: string
}

export interface ISorter {
  columnKey: string
  field: string
  order: 'ascend' | 'descend' | undefined
}
