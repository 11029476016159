import classNames from 'classnames'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

interface IProps {
  tabs: {
    name: string
    key: any
  }[]
  activeTab: any
  setActiveTab: (activeTab: any) => void
  TabBarExtraContent?: FC
}

const Tabbar: FC<IProps> = ({ tabs, activeTab, setActiveTab, TabBarExtraContent }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.tabs}>
      <div className={styles.tabsInner}>
        {tabs.map(tab => (
          <div
            key={tab.key}
            className={classNames(styles.tab, { [styles.activeTab]: activeTab === tab.key })}
            onClick={() => setActiveTab(tab.key)}
            data-tab={`tab-${tab.key}`}
            id={`tab-${tab.key}`}
          >
            {t(tab.name)}
          </div>
        ))}
      </div>
      {TabBarExtraContent && <TabBarExtraContent />}
    </div>
  )
}

export default memo(Tabbar)
