import { IDeleteWallToolWithRepeatingDto } from 'api/DTOs/wallPost.dto'

import { FollowUpPostType, IDeleteIds, deleteEntityTypeEnum } from 'types/Template'

const swapEnums = (src: deleteEntityTypeEnum): FollowUpPostType => {
  switch (src) {
    case deleteEntityTypeEnum.file:
      return FollowUpPostType.File
    case deleteEntityTypeEnum.text:
      return FollowUpPostType.Summary
    case deleteEntityTypeEnum.link:
      return FollowUpPostType.Link
    case deleteEntityTypeEnum.questionnaire:
      return FollowUpPostType.Questionnaire
    default:
      return FollowUpPostType.None
  }
}

const transformDeleteIdsToDeleteWithRepeating = (deleteIds: IDeleteIds[]): IDeleteWallToolWithRepeatingDto[] => {
  const result: IDeleteWallToolWithRepeatingDto[] = []

  deleteIds.forEach(deleteId => {
    deleteId.id.forEach(id => {
      result.push({ postId: id, postType: swapEnums(deleteId.entity) })
    })
  })

  return result
}

export default transformDeleteIdsToDeleteWithRepeating
