import { DownOutlined } from '@ant-design/icons'
import { InputNumber, Radio, RadioChangeEvent, TimePicker } from 'antd'
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox'
import classNames from 'classnames'
import moment from 'moment'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import styles from './styles.module.scss'

interface IProps {
  isAllDaySelected: boolean
  isEditing: boolean
  onChangeTimePicker: (value: moment.Moment | null, dateString: string) => void
  onChangeCheckbox: (e: CheckboxChangeEvent) => void
  onChangeRangePicker?: ((values: Array<moment.Moment>, formatString: [string, string]) => void) | undefined
  revealedTime: moment.Moment | undefined
  closeTime?: moment.Moment
  isRangePick?: boolean
  isQuestion?: boolean
}

const ToolRevealedPanel: FC<IProps> = ({
  isAllDaySelected,
  isEditing,
  onChangeTimePicker,
  onChangeRangePicker,
  onChangeCheckbox,
  revealedTime,
  closeTime,
  isRangePick,
  isQuestion,
}) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const { t } = useTranslation()

  const handleRadioSelect = (e: RadioChangeEvent) => {
    e.target.checked = e.target.value === 1
    onChangeCheckbox(e)
  }

  const [moreThanOneDayChecked, setMoreThanOneDayChecked] = useState(() => {
    if (revealedTime && closeTime) {
      return closeTime.diff(revealedTime, 'days') >= 1
    }

    return false
  })
  const [days, setDays] = useState(() => {
    if (revealedTime && closeTime) {
      return closeTime.diff(revealedTime, 'days') + 1
    }

    return 1
  })

  const moreThanOneDayCheckedChange = (e: CheckboxChangeEvent) => {
    if (!onChangeRangePicker) return

    if (!templateEntity.templateInfo) return

    if (isAllDaySelected) {
      const entryDate = templateEntity.templateInfo.createdAt

      onChangeRangePicker(
        [
          moment(entryDate).set('hours', 0).set('minutes', 0).set('seconds', 0),
          moment(entryDate)
            .set('hours', 23)
            .set('minutes', 59)
            .set('seconds', 59)
            .add(!e.target.checked ? 0 : days - 1, 'days'),
        ],
        ['', '']
      )

      if (!e.target.checked) {
        setDays(1)
      }

      setMoreThanOneDayChecked(e.target.checked)

      return
    }

    if (closeTime && revealedTime) {
      if (!e.target.checked) {
        onChangeRangePicker(
          [
            revealedTime,
            moment(templateEntity.templateInfo.createdAt)
              .set('hours', closeTime.get('hours'))
              .set('minutes', closeTime.get('minutes')),
          ],
          ['', '']
        )
      } else {
        onChangeRangePicker([revealedTime, closeTimeAdapt(closeTime)], ['', ''])
      }
    }

    setMoreThanOneDayChecked(e.target.checked)
  }

  const daysCountChange = (value: number | null) => {
    if (value) {
      setDays(value)

      if (closeTime && revealedTime && onChangeRangePicker) {
        onChangeRangePicker([revealedTime, closeTimeAdapt(closeTime, value)], ['', ''])
      }
    }
  }

  const closeTimeAdapt = (momentDate: moment.Moment, daysParam?: number) => {
    if (!templateEntity.templateInfo) return momentDate

    const fixedTime = moment(templateEntity.templateInfo.createdAt)
      .set('hours', momentDate.get('hours'))
      .set('minutes', momentDate.get('minutes'))

    if (daysParam !== undefined) {
      fixedTime.add('days', daysParam ? daysParam - 1 : 0)
    } else {
      fixedTime.add('days', days ? days - 1 : 0)
    }

    return fixedTime
  }

  const onOkRangePicker = (dates: [moment.Moment | undefined, moment.Moment | undefined]) => {
    if (!onChangeRangePicker) return

    if (dates?.[0] && dates?.[1]) {
      const endDate = closeTimeAdapt(dates[1])

      onChangeRangePicker([dates[0], endDate], ['HH:mm', 'HH:mm'])
      return
    }

    if (dates?.[0]) {
      onChangeRangePicker([dates[0], moment(dates[0]).set('h', 23).set('m', 59)], ['HH:mm', 'HH:mm'])
      return
    }

    if (dates?.[1]) {
      onChangeRangePicker([moment(dates[1]).set('h', 0).set('m', 0), closeTimeAdapt(dates[1])], ['HH:mm', 'HH:mm'])
      return
    }
  }

  return (
    <>
      <div className={styles.toolTimePick}>
        <div data-time="time">
          <div className={styles.revealedText}>{t<string>('templateCreation.revealedContent')}</div>
          <Radio.Group onChange={handleRadioSelect} value={isAllDaySelected ? 1 : 2} disabled={!isEditing}>
            <Radio value={1} data-time-option="time-option-all-day">
              {t<string>('templateCreation.allDay')}
            </Radio>
            <Radio value={2} data-time-option="time-option-specific-time">
              {t<string>('templateCreation.atSpecificTime')}
            </Radio>
          </Radio.Group>
        </div>
        <div
          className={classNames(styles.timePickerContainer, {
            [styles.show]: !isAllDaySelected,
          })}
        >
          {!isAllDaySelected && (
            <div className={styles.timePickersContainer}>
              <div>
                {isRangePick ? (
                  <div className={styles.dateLine}>
                    <div>
                      <div className={styles.timePickerLabel}>{t<string>('templateCreation.selectTime')}</div>
                      <TimePicker.RangePicker
                        format="HH:mm"
                        disabled={!isEditing}
                        className={styles.timePicker}
                        //@ts-ignore
                        value={[revealedTime, closeTime]}
                        //@ts-ignore
                        onChange={onChangeRangePicker}
                        //@ts-ignore
                        onOk={onOkRangePicker}
                        suffixIcon={<DownOutlined style={{ color: '#130F26', fontSize: 12 }} />}
                        data-time-picker="time-picker"
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={styles.timePickerLabel}>{t<string>('templateCreation.startTime')}</div>
                    <TimePicker
                      format="HH:mm"
                      disabled={!isEditing}
                      onChange={onChangeTimePicker}
                      value={revealedTime}
                      size="middle"
                      placeholder={t<string>('templateCreation.selectTime')}
                      data-select-time="selectTime"
                      className={styles.timePicker}
                      suffixIcon={<DownOutlined style={{ color: '#130F26', fontSize: 12 }} />}
                      data-time-picker="time-picker"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {isQuestion && (
        <div className={styles.days}>
          <Checkbox
            checked={moreThanOneDayChecked}
            onChange={moreThanOneDayCheckedChange}
            className={classNames('checkboxUI', styles.checkbox)}
          >
            {t<string>('templateCreation.moreThanOneDay')}
          </Checkbox>
          {moreThanOneDayChecked && (
            <InputNumber min={1} value={days} onChange={daysCountChange} className={styles.dayInput} />
          )}
        </div>
      )}
    </>
  )
}

export default ToolRevealedPanel
