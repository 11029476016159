import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { InputNumber, ModalProps } from 'antd'
import { t } from 'i18next'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import templatesApi from 'api/templates.api'

import useDebounce from 'hooks/useDebounce'

import { defaultProgramFilter } from 'utils/const'
import message from 'utils/message'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomInput from 'components/CustomComponents/CustomInput'
import CustomModal from 'components/CustomComponents/CustomModal'

import styles from './styles.module.scss'

interface IProps extends ModalProps {
  setModalVisible?: Dispatch<SetStateAction<boolean>>
}

const TemplateCreateModal: React.FC<IProps> = props => {
  const [templateName, setTemplateName] = useState('')
  const [numberOfDays, setNumberOfDays] = useState(1)
  const [loading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState('')

  const debounceParams = useDebounce(templateName, 500)

  const navigate = useNavigate()

  const validateTemplateName = (name: string) => {
    const wordsLength = name.split(' ').filter(val => val).length

    if (wordsLength > 5) {
      setErrorText(t('shared.maxWords', { number: 5 }))
      return false
    } else {
      setErrorText('')
      return true
    }
  }

  const onTextChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setLoading(true)
    setTemplateName(e.target.value)
  }

  const handleTemplateNameCheck = useCallback(async (value: string) => {
    try {
      if (!value) return

      const valid = validateTemplateName(value.trim())

      if (!valid) return

      const isClear = await templatesApi.isTemplateNameExists(value.trim())

      if (!isClear) {
        return setErrorText(t<string>('templateCreation.templateExists'))
      }
      setErrorText('')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    handleTemplateNameCheck(debounceParams)
  }, [debounceParams, handleTemplateNameCheck])

  const handleContinueTemplate = async () => {
    try {
      setLoading(true)

      const generateDays = []

      for (let i = 0; i < numberOfDays; i++) {
        generateDays.push({
          day: new Date(new Date().setDate(new Date().getDate() + i)).toISOString(),
          files: [],
          questionnaires: [],
          summaries: [],
          templateEntityLinks: [],
          scenarios: [],
        })
      }

      const created = await templatesApi.createTemplate({
        name: templateName.trim(),
        createTemplateEntityModels: generateDays,
        duration: numberOfDays,
      })

      if (!created?.id) {
        message.error(t('programCreation.smtWrong'))
        return
      }

      await templatesApi.updateFilter({
        id: created.id,
        filter: defaultProgramFilter,
      })

      message.success(t('templateCreation.templateCreateSuccess'))

      navigate('/template/' + created.id)
    } finally {
      setLoading(false)
    }
  }

  const isError = !!errorText

  return (
    <CustomModal title={t<string>('templateList.newTemplate')} footer={null} {...props}>
      <div className={styles.modalCreateContainer}>
        <CustomInput
          value={templateName}
          status={isError && errorText ? 'error' : undefined}
          onChange={onTextChange}
          className={styles.modalInput}
          autoFocus
          errorText={isError ? [errorText] : undefined}
          label={t<string>('templateCreation.templateName')}
          data-template-modal-name-input="template-modal-name-input"
          id="template-modal-name-input"
          big
        />
        <div>
          <label>{t<string>('templateList.numberOfDays')}</label>
          <InputNumber
            className={styles.modalInputNumber}
            keyboard={true}
            min={1}
            controls={{
              downIcon: <DownOutlined />,
              upIcon: <UpOutlined />,
            }}
            value={numberOfDays}
            onChange={value => value !== null && setNumberOfDays(value)}
            data-template-modal-days-input="template-modal-days-input"
            id="template-modal-days-input"
          />
        </div>
      </div>
      <div className={styles.footer}>
        <CustomButton
          type="primary"
          disabled={!!errorText || !templateName}
          onClick={handleContinueTemplate}
          loading={loading}
          data-template-modal-continue-btn="template-modal-continue-btn"
          id="template-modal-continue-btn"
        >
          {t<string>('templateCreation.continueTemplate')}
        </CustomButton>
      </div>
    </CustomModal>
  )
}

export default TemplateCreateModal
