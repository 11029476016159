import { UploadFile } from 'antd'
import { IEventPlace } from 'types/EventPlace'

import { IEntityTag, ITagWithToolRelation, QuestionnaireState } from 'types/Template'

export type planningToolsType =
  | IImageDayItem
  | IVideoDayItem
  | IQuestionnaireDayItem
  | ISummaryDayItem
  | IFileDayItem
  | IAudioDayItem
  | ILinkDayItem
  | IScenarioDayItem

interface IToolBase {
  entityTags: ITagWithToolRelation[] | IEntityTag[]
  id?: number
  position?: number
  entityIds: number[]
  fromDay?: number
  schedulerOption?: schedulerOptionEnum
  isAllDaySelected: boolean
  revealedTime: string
  repeatId: string
  daysSheduleValue: number[]
}

export interface IPlanningToolFileType extends IToolBase {
  fileIds: number[]
  type: planingToolTypesEnum.IMAGE | planingToolTypesEnum.VIDEO | planingToolTypesEnum.FILE | planingToolTypesEnum.AUDIO
  fileList: UploadFile<any>[]
  uniqueId: string[]
}

export interface IImageDayItem extends IPlanningToolFileType {
  type: planingToolTypesEnum.IMAGE
}

export interface IVideoDayItem extends IPlanningToolFileType {
  type: planingToolTypesEnum.VIDEO
  modifiedVideoLink?: string
  videoLinks: string[]
  videoLinksEntityIds: number[]
  videoLinksUIDs: string[]
}

export interface IFileDayItem extends IPlanningToolFileType {
  type: planingToolTypesEnum.FILE
}

export interface IAudioDayItem extends IPlanningToolFileType {
  type: planingToolTypesEnum.AUDIO
}

export interface IQuestionnaireDayItem extends IToolBase {
  type: planingToolTypesEnum.QUESTIONNAIRE
  questionsData: QuestionnaireState[]
  isGraphNeeded: boolean
  questionMessageIds: number[] | number[][]
  fromTool?: number
  openTime: string
  closeTime: string
  uniqueId: string[]
}

export interface ISummaryDayItem extends IToolBase {
  type: planingToolTypesEnum.SUMMARY
  summary: string
  fromTool?: number
  title?: string
  subTitle?: string
  imageLink?: string
  buttonText?: string
  buttonUrl?: string
  uniqueId: string
  eventDay: string | null
  programWallPostId?: number
  progressValue?: number
  isPublished: boolean
  eventPlace?: IEventPlace | null
}

export interface ILinkDayItem extends IToolBase {
  type: planingToolTypesEnum.LINK
  linksValues: string[][]
  uniqueId: string
}

export interface IScenarioDayItem extends IToolBase {
  type: planingToolTypesEnum.SCENARIO
  uniqueId: string
  name: string
  isPublished: boolean
  originalEntity?: any
}

export interface IDeleteIds {
  id: number[]
  entity: deleteEntityTypeEnum
}

// 0 = Questionnaires, 1 = Questions, 2 = Files, 3 = Summaries, 4 = AnswerOptions, 5 = Links

export enum deleteEntityTypeEnum {
  questionnaire = 0,
  image = 2,
  video = 2,
  file = 2,
  audio = 2,
  text = 3,
  answerOption = 4,
  link = 5,
  scenario = 6,
  ratingQuestions = 1,
}

export enum schedulerOptionEnum {
  doNotRepeat = 1,
  daily = 2,
  weekly = 3,
  monthly = 4,
  everyWeekday = 5,
  specificDay = 6,
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum schedulerSelectOptionEnum {
  ONLY_THIS = 1,
  THIS_AND_SUBSEQUENT = 2,
  ALL = 3,
}

export enum planingToolTypesEnum {
  IMAGE = 'image',
  VIDEO = 'video',
  QUESTIONNAIRE = 'questionnaire',
  SUMMARY = 'text',
  FILE = 'file',
  AUDIO = 'audio',
  LINK = 'link',
  SCENARIO = 'scenario',
}

export enum ReminderToolTypesEnum {
  LINK = 'link',
  SUMMARY = 'summary',
  QUESTIONNAIRE = 'questionnaire',
  MEDIA = 'media',
}

export enum RepeatingTypeEnum {
  ALL,
  ONLY_THIS,
  THIS_AND_SUBSEQUENT,
}
