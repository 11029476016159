import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useStore } from 'stores'

import { initialDayWallPostQuery } from 'utils/const'

import { WallMode } from 'types/Template'

import TemplateModule from 'modules/TemplateModule'

import Loader from 'components/Loader'

import styles from './styles.module.scss'

const WallPost = () => {
  const { wallPostStore, goalsStore, templateStore } = useStore()

  const { id: programId } = useParams<{ id: string }>()

  const [searchParams] = useSearchParams()

  const dayIdx = searchParams.get(initialDayWallPostQuery)
  const isNewsMode = searchParams.get('mode') === WallMode.NEWS

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getWallPost = async () => {
      if (!programId) return

      try {
        setLoading(true)
        wallPostStore.followUpStore.setFollowUpContent(+programId)

        const events = await templateStore.getProgramNewsDays(+programId)

        if (isNewsMode && events?.length) {
          return await templateStore.getWallNews({ dayIndex: 0, programId })
        }

        await Promise.all([
          templateStore.getWallPostById({ programId, dayIndex: dayIdx ? +dayIdx : 0 }),
          goalsStore.setWallPostsProgramGoals(+programId),
        ])
      } catch (e) {
        navigate(-1)
      } finally {
        setLoading(false)
      }
    }

    getWallPost()
  }, [programId, dayIdx, isNewsMode])

  useEffect(() => {
    if (!programId) return

    templateStore.setProgramAPI(programId)
    templateStore.setProgramDaysAPI(programId)

    return () => {
      templateStore.clearWallPostData()
    }
  }, [programId])

  return loading ? (
    <div className={styles.center} data-center="center">
      <Loader />
    </div>
  ) : (
    <TemplateModule isNewsMode={isNewsMode && templateStore.templateModeVisible} />
  )
}

export default WallPost
