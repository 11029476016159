import { CopyOutlined, DeleteOutlined, EditOutlined, InfoOutlined } from '@ant-design/icons'
import { Image } from 'antd'
import moment from 'moment'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import { IScheduledNotification } from 'types/ScheduleNotification'

import message from 'utils/message'
import { getCronLabel } from 'utils/reminderCronHandler'

import styles from './styles.module.scss'

import IconButton from 'components/IconBtn'

import { ReactComponent as NotificationSendIcon } from 'sources/images/NotificationSend.svg'
import { ReactComponent as NotificationRepeatIcon } from 'sources/images/reverse.svg'

interface IProps {
  onDelete: (id: number) => void
  data: IScheduledNotification
  setIsReminderModalVisible: Dispatch<SetStateAction<boolean>>
  setInitialReminderData: Dispatch<IScheduledNotification | null>
  setActiveHistoryReminder: Dispatch<SetStateAction<IScheduledNotification | null>>
  isHistory: boolean
}

const ReminderItem: React.FC<IProps> = ({
  onDelete,
  data,
  setIsReminderModalVisible,
  setInitialReminderData,
  setActiveHistoryReminder,
  isHistory,
}) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const date = moment(data.executeAt)

  const scheduledTo = useMemo(() => {
    if (data.usersTo.length !== 1) return ''

    const user = data.usersTo[0]

    return `${user.firstName} ${user.lastName}`
  }, [data.usersTo])

  return (
    <div className={styles.notification}>
      <div className={styles.mainLine}>
        <div className={styles.main}>
          <div>{data.cron ? <NotificationRepeatIcon /> : <NotificationSendIcon />}</div>
          {!data.cron
            ? t('programSettings.notificationSchedulerText', {
                date: date.format('DD/MM/yyyy'),
                time: date.format('HH:mm'),
              })
            : `${t('programSettings.notificationSchedulerTextForCron')} ${getCronLabel(data.cron, userStore.language)}`}
        </div>
        <div className={styles.notificationPlaceholder}>
          {!!data.imageUrl && <Image className={styles.img} preview src={data.imageUrl} />} {data.message}
        </div>
      </div>
      <div>{scheduledTo ? `${t('shared.to')} ${scheduledTo}` : ''}</div>
      <div className={styles.btns}>
        {!isHistory && (
          <IconButton
            onClick={async () => {
              await navigator.clipboard.writeText(data.id.toString())
              message.success(t('templateList.copiedId'))
            }}
            small
          >
            <CopyOutlined />
          </IconButton>
        )}
        {isHistory && !!data.usersTo.length && (
          <IconButton onClick={() => setActiveHistoryReminder(data)} small>
            <InfoOutlined />
          </IconButton>
        )}
        {!data.isSent && !isHistory && (
          <IconButton
            onClick={() => {
              setIsReminderModalVisible(true)
              setInitialReminderData(data)
            }}
            small
          >
            <EditOutlined />
          </IconButton>
        )}
        <IconButton onClick={() => onDelete(data.id)} danger small>
          <DeleteOutlined />
        </IconButton>
      </div>
    </div>
  )
}

export default ReminderItem
