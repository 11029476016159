import { useStore } from 'stores'

const useUnboundStore = () => {
  const { templateStore, wallPostStore } = useStore()
  const { templateEntity } = templateStore

  const unboundStore = templateEntity.isWallPosts
    ? wallPostStore.unboundContentStore
    : templateStore.unboundContentStore

  return { unboundStore }
}

export default useUnboundStore
