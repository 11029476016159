import { ConfigProvider } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { FC, ReactNode, useEffect, useState } from 'react'

import { useStore } from 'stores'

import isBrowserSupportWebP from 'utils/isBrowserSupportWebP'

import LanguageSelectButton from 'components/LanguageSelectButton'
import Loader from 'components/Loader'

import styles from './styles.module.scss'

interface IProps {
  hideLanguageSelector?: boolean
  children: ReactNode
}

const canShowWebP = isBrowserSupportWebP()

const LoginLayout: FC<IProps> = ({ children, hideLanguageSelector }) => {
  const [direction, setDirection] = useState<'ltr' | 'rtl'>('ltr')

  const { userStore } = useStore()

  useEffect(() => {
    if (!userStore.language) return

    if (userStore.language === 'en' || userStore.language === 'ru') {
      setDirection('ltr')
    } else {
      setDirection('rtl')
    }
  }, [userStore.language])

  return (
    <ConfigProvider direction={direction} data-config-provider="config-provider">
      <div className={styles.container} id="container" data-container="container">
        <div
          className={classNames(styles.imageContainer, {
            [styles.rtlBgImage]: direction === 'rtl',
            [styles.imageWebP]: canShowWebP,
            [styles.imageJpeg]: !canShowWebP,
          })}
          id="imageContainer"
          data-image-container="imageContainer"
        />
        {!hideLanguageSelector && <LanguageSelectButton />}

        {userStore.userBrandLoading ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : (
          children
        )}
      </div>
    </ConfigProvider>
  )
}

export default observer(LoginLayout)
