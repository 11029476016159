export enum FontSizesEnum {
  DEFAULT,
  MEDIUM,
  LARGE,
  GIGANTIC,
}

export enum ThemeEnum {
  light,
  dark,
}

export enum LanguagesEnum {
  ru,
  en,
  heUndefined,
  heMale,
  heFemale,
  heSababa,
}

interface ISettingProfileBase {
  id: number
  name: string
  textSizeSetting: FontSizesEnum
  themeSetting: ThemeEnum
  focusMod: boolean
  automaticSavingAnswers: boolean
  pushNotifications: boolean
}

export interface ISettingProfile extends ISettingProfileBase {
  languageCode: LanguagesEnum
}

export interface ISettingProfileClient extends ISettingProfileBase {
  languageCode: LanguagesEnum
}
