import { useTranslation } from 'react-i18next'

import useWallContentSearchQuery from 'hooks/tanstack/queries/useWallContentSearchQuery'
import useClientSidePaginarion from 'hooks/useClientSidePaginarion'

import PageError from 'components/PageError'
import PageLoader from 'components/PageLoader'
import Pagination from 'components/Pagination'

import DayItem from '../DayItem'

import styles from './styles.module.scss'

interface IProps {
  search: string
  programId: number
  isNewsMode?: boolean
}

const SearchedContent = ({ programId, search, isNewsMode }: IProps) => {
  const { t } = useTranslation()

  const { days, isError, isLoading } = useWallContentSearchQuery({ programId, search })
  const { itemsPortion, propsToPaginationComponent } = useClientSidePaginarion({
    items: days,
    hideSizer: true,
    pageLength: 30,
  })

  return (
    <div>
      {isError ? (
        <PageError />
      ) : isLoading ? (
        <PageLoader />
      ) : !days.length ? (
        <div className={styles.empty}>{t('templateCreation.noContentFound')}</div>
      ) : (
        <div>
          {itemsPortion.map((day, idx) => (
            <DayItem
              key={`${day.id}-${idx}`}
              isNewsMode={isNewsMode}
              dayId={day.id}
              dayContent={day.dayItems}
              dayIndex={idx}
              preventDrag
              inSearch
            />
          ))}
          <Pagination {...propsToPaginationComponent} />
        </div>
      )}
    </div>
  )
}

export default SearchedContent
