import React from 'react'

import styles from './styles.module.scss'
import { useChatMessages } from './useChatMessages'
import { Divider, Empty, Spin, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import ChatMessage from './ChatMessage'
import dayjs from 'dayjs'

const ChatMessages: React.FC = () => {
  const { t } = useTranslation()
  const { messages, dateGroups, isLoading, nextPageTriggerRef } = useChatMessages()

  return (
    <div className={styles.container}>
      {!isLoading && messages.length === 0 && (
        <Empty className={styles.empty} description={t('chat.noMessages')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      {messages.map(message => (
        <>
          <ChatMessage key={message.id} message={message} />
          {dateGroups.has(message.id || 0) && (
            <Divider plain>
              <Typography.Text type="secondary">
                {dayjs(dateGroups.get(message.id || 0)).format('DD/MM/YYYY')}
              </Typography.Text>
            </Divider>
          )}
        </>
      ))}

      <div ref={nextPageTriggerRef}>&nbsp;</div>
      <Spin className={styles.loading} spinning={isLoading} />
    </div>
  )
}

export default ChatMessages
