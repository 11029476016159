import { useState } from 'react'

import DailyGoal from './DailyGoal'
import PlanBtn from './PlanBtn'
import Settings from './Settings'
import UnboundContent from './UnboundContent'

import styles from './styles.module.scss'

const TopBtns = () => {
  const [unboundContentModalVisible, setUnboundContentModalVisible] = useState(false)
  const [planBtnModalVisible, setPlanBtnModalVisible] = useState(false)
  const [isBackToPlan, setIsBackToPlan] = useState(false)

  return (
    <div className={styles.btns}>
      <DailyGoal />
      <UnboundContent
        visible={unboundContentModalVisible}
        setVisible={setUnboundContentModalVisible}
        onBack={() => {
          setIsBackToPlan(false)
          setPlanBtnModalVisible(true)
        }}
        isBackToPlan={isBackToPlan}
        setIsBackToPlan={setIsBackToPlan}
      />
      <PlanBtn
        showUnboundContentModal={() => {
          setIsBackToPlan(true)
          setUnboundContentModalVisible(true)
        }}
        visible={planBtnModalVisible}
        setVisible={setPlanBtnModalVisible}
      />
      <Settings />
    </div>
  )
}

export default TopBtns
