import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import userApi from 'api/user.api'

import message from 'utils/message'

interface IProps {
  currentSelectedProgramId: string
  debounceParams: string
}

const useLeastPerformanceTrainee = ({ currentSelectedProgramId, debounceParams }: IProps) => {
  const { t } = useTranslation()

  const {
    data: leastPerformersTrainees,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['dashboard', 'leastPerformers', currentSelectedProgramId, debounceParams],
    queryFn: async () => {
      const debounceParamZeroCheck =
        debounceParams.length && debounceParams[0] === '0' ? debounceParams.slice(1) : debounceParams

      const searchedTrainees = await userApi.getLeastPerformersTrainees(
        currentSelectedProgramId,
        debounceParamZeroCheck
      )

      return searchedTrainees.sort((a, b) => {
        if (!a.lastActiveTime) return -1

        if (!b.lastActiveTime) return 1

        if (a.lastActiveTime < b.lastActiveTime) return -1

        if (a.lastActiveTime > b.lastActiveTime) return 1

        return 0
      })
    },
    onError: () => {
      message.error(t<string>('programCreation.smtWrong'))
      return []
    },
    initialData: [],
  })

  return {
    leastPerformersTrainees,
    searchLoading: isFetching || isLoading,
  }
}

export default useLeastPerformanceTrainee
