import { useQuery } from '@tanstack/react-query'

import templatesApi from 'api/templates.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { ITemplate } from 'types/Template'

const useTemplateQuery = (templateId: number | null, programId?: number) => {
  const {
    data: template,
    isLoading,
    isError,
  } = useQuery({
    keepPreviousData: true,
    queryKey: [QueryKeysEnum.TEMPLATE, templateId, programId],
    queryFn: async () => {
      if (!templateId) {
        return {
          createdAt: '',
          duration: 0,
          label: '',
          value: 0,
        }
      }

      let template: ITemplate | null = null

      try {
        template = await templatesApi.getLightTemplate(templateId)
      } catch (e) {
        if (programId) {
          template = await templatesApi.lightProgramTemplate(programId)
        }
      }

      if (!template) throw new Error()

      return {
        createdAt: template.createdAt,
        duration: template.duration,
        label: template.name,
        value: template.id,
      }
    },
    enabled: !!programId,
  })

  return {
    template,
    isLoading,
    isError,
  }
}

export default useTemplateQuery
