import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationDisplayFilter } from 'types/ScheduleNotification'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomSelect from 'components/CustomComponents/CustomSelect'

import SearchBar from 'components/SearchBar'
import styles from './styles.module.scss'

interface IProps {
  onCreateClick: () => void
  filter: NotificationDisplayFilter
  onChangeFilter: (filter: NotificationDisplayFilter) => void
  search: string
  setSearch: (query: string) => void
}

const Header: FC<IProps> = ({ onCreateClick, filter, onChangeFilter, search, setSearch }) => {
  const { t } = useTranslation()

  const filterOptions = [
    {
      value: NotificationDisplayFilter.Future,
      label: t('programSettings.reminderScheduled'),
    },
    {
      value: NotificationDisplayFilter.History,
      label: t('programSettings.reminderHistory'),
    },
    {
      value: NotificationDisplayFilter.Repeating,
      label: t('programSettings.reminderRepeated'),
    },
  ]

  return (
    <div>
      <div className={styles.header}>
        <div>
          <h2 className={styles.title}>{t('programSettings.reminderForTrainees')}</h2>
          <h3 className={styles.subtitle}>{t('programSettings.reminderText')}</h3>
        </div>
        <CustomButton type="primary" onClick={onCreateClick}>
          {t('programSettings.reminderButton')}
        </CustomButton>
      </div>
      <div className={styles.queriesLine}>
        <div className={styles.filterContainer}>
          <div className="label">{t('programSettings.reminderFilter')}</div>
          <CustomSelect style={{ width: 200 }} value={filter} onChange={onChangeFilter} options={filterOptions} />
        </div>
        <div>
          <SearchBar placeholder={t('mentorDashboard.search')} value={search} onChangeCustom={setSearch} />
        </div>
      </div>
    </div>
  )
}

export default Header
