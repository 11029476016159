import { useState } from 'react'

const useToolState = () => {
  const [isAllDaySelected, setIsAllDaySelected] = useState(true)
  const [revealedTime, setRevealedTime] = useState<moment.Moment>()
  const [isEditing, setIsEditing] = useState(false)
  const [isFirstDrop, setIsFirstDrop] = useState(false)
  const [isSchedulerModalVisible, setIsSchedulerModalVisible] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  return {
    isAllDaySelected,
    setIsAllDaySelected,
    revealedTime,
    setRevealedTime,
    isEditing,
    setIsEditing,
    isFirstDrop,
    setIsFirstDrop,
    isSchedulerModalVisible,
    setIsSchedulerModalVisible,
    isUpdating,
    setIsUpdating,
  }
}

export default useToolState
