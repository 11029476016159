import { Collapse } from 'antd'
import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useAtLeastOneQuestion from 'hooks/reports/useAtLeastOneQuestion'

import { IDailyReport, IDailyReportUser } from 'types/Program'

import ReportDayHeader from '../ReportDayHeader'
import ReportTable from '../ReportTable'
import { IGroupedByProgramReport } from '../index'

import styles from '../styles.module.scss'

interface IProps {
  programGroup: IGroupedByProgramReport
  expandedRowDays: string[]
  setExpandedRowDays: Dispatch<SetStateAction<string[]>>
  expandedRowKeys: string[]
  handleCollapseQuestions: (item: IDailyReportUser) => void
  handleExpandQuestions: (item: IDailyReportUser) => void
  originalReports: IDailyReport[]
}

const ReportProgramGroup: FC<IProps> = ({
  programGroup,
  expandedRowDays,
  setExpandedRowDays,
  expandedRowKeys,
  handleCollapseQuestions,
  handleExpandQuestions,
  originalReports,
}) => {
  const { t } = useTranslation()

  const atLeastOneQuestion = useAtLeastOneQuestion(originalReports)

  const isShowOnlyFirsDayTrainee = useMemo(() => {
    let traineeId: number | null = null
    let isOnlyOne = true

    programGroup.reports.forEach(report => {
      if (report.dailyReportModelForUsers.length !== 1) {
        isOnlyOne = false
        return
      }

      report.dailyReportModelForUsers.forEach(userReport => {
        if (!traineeId) {
          traineeId = userReport.userId
        } else {
          if (userReport.userId !== traineeId) {
            isOnlyOne = false
          }
        }
      })
    })

    return isOnlyOne
  }, [programGroup])

  return (
    <div>
      <div className={styles.programName}>{programGroup.programName}</div>
      {atLeastOneQuestion ? (
        <Collapse
          expandIconPosition={'end'}
          activeKey={expandedRowDays}
          onChange={keys => setExpandedRowDays(typeof keys === 'string' ? [keys] : keys)}
          data-collapse="Collapse"
          className="report-collapse"
        >
          {programGroup.reports.map((report, idx) => {
            return (
              <Collapse.Panel
                key={`${programGroup.programId}-${report.day - 1}`}
                className={styles.panel}
                header={<ReportDayHeader item={report} />}
                data-panel="panel"
              >
                <ReportTable
                  tableKeyField="userId"
                  item={report}
                  expandedRowKeys={expandedRowKeys}
                  handleCollapseQuestions={handleCollapseQuestions}
                  handleExpandQuestions={handleExpandQuestions}
                  isShowOnlyFirsDayTrainee={isShowOnlyFirsDayTrainee && idx !== 0}
                />
              </Collapse.Panel>
            )
          })}
        </Collapse>
      ) : (
        <div className={styles.noQuestionsMessage}>{t('dailyReport.noQuestionsInProgram')}</div>
      )}
    </div>
  )
}

export default ReportProgramGroup
