import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import templatesApi from 'api/templates.api'

import message from 'utils/message'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

interface IProps {
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  form: FormInstance<any>
  onSuccess?: () => void
}

const useCopyTemplateMutation = ({ setIsModalVisible, form, onSuccess }: IProps) => {
  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async ({ name, id }: { name: string; id: number }) => {
      const newTemplateId = await templatesApi.copyTemplate(name, id)

      if (newTemplateId) {
        message.success(t<string>('templateCreation.templateCopiedSuccessful'))
        setIsModalVisible(false)
        form.resetFields()

        return newTemplateId
      }

      return null
    },
    onSuccess: newTemplateId => {
      queryClient.invalidateQueries({ queryKey: [QueryKeysEnum.TEMPLATES] })

      if (onSuccess) {
        onSuccess()
      }

      if (newTemplateId) {
        navigate('/template/' + newTemplateId)
      }
    },
  })

  return {
    copyTemplate: mutateAsync,
    isLoading,
  }
}

export default useCopyTemplateMutation
