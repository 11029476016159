import { useQuery } from '@tanstack/react-query'
import adminApi from 'api/admin.api'
import usePagination from 'hooks/usePagination'
import { QueryKeysEnum } from 'types/QueryKeysEnum'
import LS, { LSKeys } from 'utils/LS'

const pageLength = 10

const useAdminScrapingQuery = () => {
  const { pagination, propsToPaginationComponent, setPagination } = usePagination({
    pageLength: pageLength,
    hideSizer: true,
  })

  const { data, isLoading, isError, isFetching, isFetched } = useQuery({
    keepPreviousData: true,
    initialData: [],
    queryKey: [QueryKeysEnum.ADMIN_SCRAPING, pagination.pageNumber || 1],
    queryFn: async () => {
      const token = await LS.get(LSKeys.SCRAPING_TOKEN)
      if (!token) throw new Error('')

      const response = await adminApi.getScraperWebsites({ pageNumber: pagination.pageNumber || 1, pageLength, token })

      setPagination(prev => ({
        ...prev,
        totalItems: response.totalItems,
        pageLength,
        totalPages: response.totalPages,
      }))

      return response.items
    },
  })

  return { isLoading, isError, data, propsToPaginationComponent, isFetching: isFetching && !isFetched }
}

export default useAdminScrapingQuery
