import { makeAutoObservable } from 'mobx'

import { IDayData, IDeleteIds, planningToolsType } from 'types/Template'

class UnboundStore {
  constructor() {
    makeAutoObservable(this)
  }

  isUnboundToolsEditing: boolean = false

  unboundContent: IDayData[] = []
  unboundContentLoading: boolean = false

  unboundEntitiesToDelete: IDeleteIds[] = []

  deleteUnboundDay(id: number) {
    this.unboundContent = this.unboundContent.filter(day => day.id !== id)
  }

  resetUnboundEntitiesToDelete() {
    this.unboundEntitiesToDelete = []
  }

  addUnboundEntitiesToDelete(entities: IDeleteIds[]) {
    const result: IDeleteIds[] = [...this.unboundEntitiesToDelete]

    entities.forEach(entity => {
      const candidate = result.findIndex(item => item.entity === entity.entity)

      if (candidate === -1) {
        result.push(entity)
      } else {
        result[candidate].id = [...result[candidate].id, ...entity.id]
      }
    })

    this.unboundEntitiesToDelete = result
  }

  setUnboundContent(data: IDayData[]) {
    this.unboundContent = data
  }

  saveToolInDay(dayIdx: number, tool: planningToolsType) {
    this.unboundContent = this.unboundContent.map((day, idx) => {
      if (idx === dayIdx) {
        return { ...day, dayItems: [tool] }
      }

      return day
    })
  }

  addUnboundTool(tool: planningToolsType) {
    this.unboundContent = [
      ...this.unboundContent,
      { dayDate: '', dayItems: [tool], dayNumber: Math.max(...this.unboundContent.map(day => day.dayNumber)) + 1 },
    ]
  }

  cleanUnboundContent() {
    this.unboundContent = []
  }

  deleteDayByIndex(idx: number) {
    this.unboundContent = this.unboundContent.filter((day, index) => index !== idx)
  }

  get unboundContentLength() {
    return this.unboundContent.length
  }

  setToolsEditing(status: boolean) {
    this.isUnboundToolsEditing = status
  }

  checkIfToolsEditing() {
    return this.isUnboundToolsEditing
  }
}

export default new UnboundStore()
