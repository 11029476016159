import classNames from 'classnames'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useIsRtl from 'hooks/useIsRtl'

import CustomSelect from 'components/CustomComponents/CustomSelect'

import styles from './styles.module.scss'

import { ReactComponent as PaginationArrowPic } from 'sources/images/paginationArrow.svg'

import { ReactComponent as DoublePaginationLeft } from 'sources/images/doublePaginationLeft.svg'
import { ReactComponent as DoublePaginationRight } from 'sources/images/doublePaginationRight.svg'

const resizerOptions = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
]

enum BtnColorsEnum {
  Active = '#232631',
  Disabled = '#80838C',
}

interface IProps {
  loading?: boolean
  currentPage: number
  onPageChange: (page: number) => void
  totalItems: number
  size: number
  onSizeChange?: (size: number) => void
}

const Pagination: FC<IProps> = ({
  loading,
  currentPage: currentPageProps,
  onPageChange,
  onSizeChange,
  size,
  totalItems,
}) => {
  const { t } = useTranslation()

  const isRtl = useIsRtl()

  const currentPage = currentPageProps <= 0 ? 1 : currentPageProps

  const itemsScope = useMemo(() => {
    const startSize = (currentPage - 1) * size
    const start = startSize <= 0 ? 1 : startSize
    const end = currentPage * size

    return `${start} - ${end > totalItems ? totalItems : end}`
  }, [currentPage, size, totalItems])

  const maxPageAvailable = useMemo(() => {
    return Math.ceil(totalItems / size)
  }, [size, totalItems])

  const incrementDisabled = currentPage >= maxPageAvailable
  const decrementDisabled = currentPage <= 1

  const incrementPage = () => {
    if (incrementDisabled) return
    onPageChange(currentPage + 1)
  }

  const decrementPage = () => {
    if (decrementDisabled) return
    onPageChange(currentPage - 1)
  }
  const goToLastPage = () => {
    onPageChange(maxPageAvailable)
  }
  const goToFirstPage = () => {
    onPageChange(1)
  }

  if (loading || totalItems <= 0) return <></>

  return (
    <div className={styles.container}>
      {!!onSizeChange ? (
        <div className={styles.resizer}>
          <div className={styles.text}>{t('shared.rowsPerPage')}</div>
          <CustomSelect options={resizerOptions} value={size} onChange={onSizeChange} className={styles.select} />
        </div>
      ) : (
        <div />
      )}
      <div className={styles.pagination}>
        <div className={styles.text}>{t('shared.paginationMessage', { current: itemsScope, total: totalItems })}</div>
        <div className={styles.btns}>
          <div onClick={goToFirstPage} style={{ paddingTop: isRtl ? 5 : 2 }}>
            <DoublePaginationLeft
              fill={!incrementDisabled ? BtnColorsEnum.Disabled : BtnColorsEnum.Active}
              style={{ transform: isRtl ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </div>
          <div
            className={classNames(styles.btn, {
              [styles.disabled]: decrementDisabled,
            })}
            onClick={decrementPage}
          >
            <PaginationArrowPic
              style={{
                transform: isRtl ? 'rotate(0deg)' : 'rotate(180deg)',
                color: decrementDisabled ? BtnColorsEnum.Disabled : BtnColorsEnum.Active,
              }}
            />
          </div>
          <div
            className={classNames(styles.btn, {
              [styles.disabled]: incrementDisabled,
            })}
            onClick={incrementPage}
          >
            <PaginationArrowPic
              style={{
                color: incrementDisabled ? BtnColorsEnum.Disabled : BtnColorsEnum.Active,
                transform: isRtl ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </div>
          <div onClick={goToLastPage} style={{ paddingTop: isRtl ? 5 : 2 }}>
            <DoublePaginationRight
              fill={incrementDisabled ? BtnColorsEnum.Disabled : BtnColorsEnum.Active}
              style={{ transform: isRtl ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pagination
