import { ColumnType } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'

import CustomTable from 'components/CustomComponents/CustomTable'
import PageContainer from 'components/PageContainer'
import PageError from 'components/PageError'
import PageLoader from 'components/PageLoader'

import Pagination from 'components/Pagination'
import useAdminScrapingQuery from 'hooks/tanstack/queries/useAdminScrapingQuery'
import { IScrapeWebsite } from 'types/Scraping'
import dateTime from 'utils/dateTime'
import styles from './styles.module.scss'

const Scraping = () => {
  const { t } = useTranslation()

  const { isError, isLoading, data, propsToPaginationComponent, isFetching } = useAdminScrapingQuery()

  const columns: ColumnType<IScrapeWebsite>[] = [
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: t('scraping.lastUpdate'),
      dataIndex: 'lastScrapedTime',
      key: 'lastScrapedTime',
      render: date => dateTime.dateWithTime(date),
    },
    {
      title: t('scraping.Status'),
      dataIndex: 'scrapeStatus',
      key: 'scrapeStatus',
      render: status => (status === 1 ? t('scraping.active') : t('scraping.inactive')),
    },
  ]

  return (
    <PageContainer topOffset>
      <div>
        <div className={styles.header}>
          <h2 className={styles.title}>{t('scraping.scraping')}</h2>
        </div>
        {isLoading ? (
          <PageLoader />
        ) : isError ? (
          <PageError />
        ) : (
          <>
            <CustomTable rowKey="id" dataSource={data} columns={columns} pagination={false} loading={isFetching} />
            <Pagination {...propsToPaginationComponent} />
          </>
        )}
      </div>
    </PageContainer>
  )
}

export default Scraping
