import classNames from 'classnames'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'

import { IQuestionnaireNumeric, QuestionTypeEnum } from 'types/Template'

import mergeTextAndVariable from 'utils/mergeTextAndVariable'

import QuestionEditActions from '../../QuestionEditActions'

import CustomInput from 'components/CustomComponents/CustomInput'
import ToolWatchMode from 'components/ToolWatchMode'

import mainStyles from '../styles.module.scss'
import styles from './styles.module.scss'

interface IProps {
  min: number
  max: number
  question: string
  questionIndex: number
  handleChange: (questionState: IQuestionnaireNumeric, questionIndex: number) => void
  setIsValidationError: Dispatch<SetStateAction<boolean>>
  isEditing: boolean
  canValidate: boolean
  handleTypeChange: (option: QuestionTypeEnum | null) => void
  handleDelete: (questionIndex: number) => void
  questionCount: number
}

const Numeric: React.FC<IProps> = ({
  max,
  min,
  question,
  handleChange,
  questionIndex,
  setIsValidationError,
  isEditing,
  canValidate,
  handleDelete,
  handleTypeChange,
  questionCount,
}) => {
  const [maxErrorMessage, setMaxErrorMessage] = useState('')
  const [minErrorMessage, setMinErrorMessage] = useState('')
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (!isMounted) return

    if (max < 0) {
      setMaxErrorMessage(t('shared.positiveNumberError'))
      setIsValidationError(true)
      return
    }

    if (min < 0) {
      setMinErrorMessage(t('shared.positiveNumberError'))
      setIsValidationError(true)
      return
    }

    if (max !== null && min >= max) {
      setMaxErrorMessage(t<string>('programCreation.maxMinError'))
      setIsValidationError(true)
    } else if (maxErrorMessage) {
      setMaxErrorMessage('')
      setIsValidationError(false)
    }
  }, [min, max])

  useEffect(() => {
    if (isMounted && !min) handleChange({ type: QuestionTypeEnum.NUMERIC, question, max, min: 0 }, questionIndex)
  }, [max])

  const isError = canValidate && !question
  const isErrorMin = canValidate && ((!min && min !== 0) || min < 0)
  const isErrorMax = canValidate && ((!max && max !== 0) || min < 0)

  const scrollParam = useMemo(() => {
    const obj: any = {}

    if (isError || isErrorMin || isErrorMax) {
      obj['data-scroll'] = '1'
    }

    return obj
  }, [isError, isErrorMin, isErrorMax])

  return (
    <div {...scrollParam} className={styles.numeric} data-numeric-question="numeric-question" id="numeric-question">
      <ToolWatchMode isEditing={isEditing} value={question}>
        <div className={mainStyles.ToolWatchModeInner}>
          <CustomInput
            className={classNames(mainStyles.input, {
              [mainStyles.error]: canValidate && !question,
            })}
            dir="ltr"
            value={question}
            onChange={e =>
              handleChange({ type: QuestionTypeEnum.NUMERIC, question: e.target.value, max, min }, questionIndex)
            }
            onSelectVariable={variable =>
              handleChange(
                { type: QuestionTypeEnum.NUMERIC, question: mergeTextAndVariable(question, variable), max, min },
                questionIndex
              )
            }
            data-numeric-question-input="numeric-question-input"
            id="numeric-question-input"
          />
          <QuestionEditActions
            canValidate={canValidate}
            handleDelete={handleDelete}
            handleTypeChange={handleTypeChange}
            isEditing={isEditing}
            questionCount={questionCount}
            questionIndex={questionIndex}
            questionType={QuestionTypeEnum.NUMERIC}
          />
        </div>
      </ToolWatchMode>

      <div className={styles.maxMin}>
        <div>
          <label>{t<string>('programCreation.minPlaceholder')}</label>
          <ToolWatchMode isEditing={isEditing} value={min}>
            <CustomInput
              className={classNames(styles.numbers, {
                [styles.error]: isErrorMin,
              })}
              value={min}
              onChange={e =>
                !Number.isNaN(+e.target.value) &&
                handleChange({ type: QuestionTypeEnum.NUMERIC, question, max, min: +e.target.value }, questionIndex)
              }
              disabled={!isEditing}
              errorText={minErrorMessage ? [minErrorMessage] : []}
              data-numeric-question-min="numeric-question-min"
              id="numeric-question-min"
            />
          </ToolWatchMode>
        </div>
        <div>
          <label>{t<string>('programCreation.maxPlaceholder')}</label>
          <ToolWatchMode isEditing={isEditing} value={max}>
            <CustomInput
              value={max}
              className={classNames(styles.numbers, {
                [styles.error]: isErrorMax,
              })}
              onChange={e =>
                !Number.isNaN(+e.target.value) &&
                handleChange({ type: QuestionTypeEnum.NUMERIC, question, max: +e.target.value, min }, questionIndex)
              }
              disabled={!isEditing}
              errorText={maxErrorMessage ? [maxErrorMessage] : undefined}
              data-numeric-question-max="numeric-question-max"
              id="numeric-question-max"
            />
          </ToolWatchMode>
        </div>
      </div>
    </div>
  )
}

export default observer(Numeric)
