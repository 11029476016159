import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, UserDeleteOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import programsApi from 'api/programs.api'

import useGetProgramCreationProgress from 'hooks/programCreationProgress/useGetProgramCreationProgress'
import useDeleteProgramMutation from 'hooks/tanstack/mutations/useDeleteProgramMutation'

import { useStore } from 'stores'

import { IProgramList } from 'types/Program'

import { initialDayWallPostQuery } from 'utils/const'
import customConfirm from 'utils/customConfirm'
import message from 'utils/message'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import CopyProgramModal from 'components/CopyProgramModal'
import CustomMenu from 'components/CustomComponents/CustomMenu'
import MenuItem from 'components/MenuItem'
import ProgramInfo from 'components/ProgramInfo'

import styles from './styles.module.scss'

import { ReactComponent as CopyIcon } from 'sources/images/copy.svg'

interface IProps {
  program: IProgramList
}

const ProgramItem: FC<IProps> = ({ program }) => {
  const { t } = useTranslation()

  const [isCopyModalVisible, setIsCopyModalVisible] = useState(false)

  const { userStore } = useStore()

  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const handleClickProgram = () => {
    navigate('/programs/' + program.id, { state: { program } })
  }

  const deleteProgram = useDeleteProgramMutation({})

  const deleteConfirm = () => {
    customConfirm({
      title: t('programList.deleteTitle'),
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        await deleteProgram(program.id)
      },
      okText: t('programCreation.modalOK'),
      cancelText: t('programCreation.modalCancel'),
      className: styles.modalRtlCorrecting,
    })
  }

  const leaveProgramConfirm = () => {
    customConfirm({
      title: t('programList.leaveProgramConfirm'),
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        try {
          await programsApi.leaveProgram(program.id)
          message.success(t('programList.leavedProgram'))
          queryClient.invalidateQueries([QueryKeysEnum.PROGRAMS])
        } catch (e) {
          if (e?.response?.data?.Message?.includes("You can't leave being the only mentor")) {
            message.error(t('companyPage.lastMentorLeaveError'))
          } else {
            console.error(e)
          }
        }
      },
      okText: t('programCreation.modalOK'),
      cancelText: t('programCreation.modalCancel'),
      className: styles.modalRtlCorrecting,
    })
  }

  const progress = useGetProgramCreationProgress(program.id)

  const programInCreation = progress !== undefined && progress !== -1 && progress < 100
  const fixedCurrentDay = program.currentDay > program.daysAmount ? program.daysAmount : program.currentDay

  const menu = useMemo(
    () => (
      <CustomMenu
        items={[
          {
            key: '1',
            label: <MenuItem label={t('programList.editProgram')} Icon={() => <EditOutlined width={15} />} />,
            disabled: programInCreation,
            onClick: e => {
              e.domEvent.stopPropagation()

              navigate('/wall-post/' + program.id + `?${initialDayWallPostQuery}=${fixedCurrentDay - 1}`, {
                state: {
                  program,
                  from: '/programs',
                },
              })
            },
          },
          {
            key: '2',
            label: <MenuItem label={t('programList.copyProgramId')} Icon={() => <CopyIcon width={18} />} />,
            onClick: async e => {
              e.domEvent.stopPropagation()
              await navigator.clipboard.writeText(program.id.toString())
              message.success(t('templateList.copiedId'))
            },
          },
          {
            key: '3',
            label: <MenuItem label={t('programList.copyProgram')} Icon={() => <CopyIcon width={18} />} />,
            disabled: programInCreation,
            onClick: e => {
              e.domEvent.stopPropagation()
              setIsCopyModalVisible(true)
            },
          },
          {
            key: '4',
            label: <MenuItem label={t('programList.leaveProgram')} Icon={() => <UserDeleteOutlined width={18} />} />,
            disabled: programInCreation,
            onClick: e => {
              e.domEvent.stopPropagation()
              leaveProgramConfirm()
            },
          },
          {
            key: '5',
            label: <MenuItem label={t('programList.deleteProgram')} Icon={() => <DeleteOutlined width={18} />} />,
            onClick: e => {
              e.domEvent.stopPropagation()
              deleteConfirm()
            },
          },
        ]}
        className={styles.programMore}
      />
    ),
    [userStore.language, program, programInCreation]
  )

  return (
    <>
      <div onClick={handleClickProgram} className={styles.program}>
        <ProgramInfo
          program={program}
          title={program.name}
          subtitle={program.goal ? `${t('programList.goal')} ${program.goal}` : ''}
          menu={menu}
        />
      </div>

      <CopyProgramModal isVisible={isCopyModalVisible} setIsVisible={setIsCopyModalVisible} selectedProgram={program} />
    </>
  )
}

export default observer(ProgramItem)
