import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useIsRtl from 'hooks/useIsRtl'

import { useStore } from 'stores'

import { QuestionTypeEnum } from 'types/Template'

import CustomDropdown from 'components/CustomComponents/CustomDropdown'
import CustomMenu from 'components/CustomComponents/CustomMenu'
import CustomSelect from 'components/CustomComponents/CustomSelect'

import styles from './styles.module.scss'

interface IProps {
  handleDelete: (questionIndex: number) => void
  handleTypeChange: (option: QuestionTypeEnum | null) => void
  questionIndex: number
  questionCount: number
  questionType: QuestionTypeEnum | null
  isEditing: boolean
  canValidate: boolean
}

const QuestionEditActions: FC<IProps> = ({
  handleDelete,
  handleTypeChange,
  questionIndex,
  questionCount,
  questionType,
  canValidate,
  isEditing,
}) => {
  const { userStore } = useStore()

  const { t } = useTranslation()

  const questionsTypeOptions = useMemo(
    () => [
      {
        label: t<string>('programCreation.numericType'),
        value: QuestionTypeEnum.NUMERIC,
      },
      {
        label: t<string>('programCreation.textType'),
        value: QuestionTypeEnum.TEXT,
      },
      {
        label: t<string>('programCreation.ratingType'),
        value: QuestionTypeEnum.RATING,
      },
      {
        label: t<string>('templateCreation.multipleChoice'),
        value: QuestionTypeEnum.MULTIPLE_CHOICE,
      },
      {
        label: t<string>('templateCreation.checkBox'),
        value: QuestionTypeEnum.CHECK_BOX,
      },
    ],
    [userStore.language]
  )

  const menu = useMemo(() => {
    const items = [
      {
        key: '2',
        label: t<string>('programList.delete'),
        onClick: () => handleDelete(questionIndex),
      },
    ]

    return <CustomMenu items={items} />
  }, [userStore.language, handleDelete])

  const isRtl = useIsRtl()

  return (
    <div className={styles.container}>
      <div className={styles.answerType}>
        <label>{t<string>('templateCreation.answerType')}</label>
        <CustomSelect
          style={{ minWidth: 160 }}
          placeholder={t<string>('templateCreation.chooseQuestionType')}
          value={questionType}
          onSelect={handleTypeChange}
          options={questionsTypeOptions}
          disabled={!isEditing}
          isError={canValidate && questionType === null}
        />
      </div>
      <div
        className={classNames(styles.dropdown, {
          [styles.dropdownRtl]: isRtl,
          [styles.dropdownLtr]: !isRtl,
        })}
      >
        {questionCount > 1 && <CustomDropdown overlay={menu} />}
      </div>
    </div>
  )
}

export default observer(QuestionEditActions)
