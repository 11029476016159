import { useStore } from 'stores'

enum ChangeDayEnum {
  INCREMENT,
  DECREMENT,
}

interface IProps {
  isNewsMode?: boolean
}

const useDaysMoving = ({ isNewsMode }: IProps) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const onChangeDay = (type: ChangeDayEnum) => {
    if (!templateEntity.templateInfo) return

    const dayIndex =
      type === ChangeDayEnum.INCREMENT ? templateEntity.currentDayIndex + 1 : templateEntity.currentDayIndex - 1

    if (templateEntity.programId) {
      if (isNewsMode) {
        templateStore.getWallNews({ programId: templateEntity.programId.toString(), dayIndex, useCache: true })
      } else {
        templateStore.getWallPostById({
          programId: templateEntity.programId.toString(),
          dayIndex,
          useCache: true,
        })
      }
    } else {
      templateStore.getTemplateEntitiesDay({
        id: templateEntity.templateInfo.id,
        dayIndex,
        useCache: true,
      })
    }
  }

  const incrementDay = () => onChangeDay(ChangeDayEnum.INCREMENT)
  const decrementDay = () => onChangeDay(ChangeDayEnum.DECREMENT)

  return { incrementDay, decrementDay }
}

export default useDaysMoving
