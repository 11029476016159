import { Tooltip } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { NotificationStatus } from 'types/ScheduleNotification'

import styles from './styles.module.scss'

const transformNotificationStatus = (status: NotificationStatus) => {
  switch (status) {
    case NotificationStatus.Delivered:
      return 'programSettings.reminderDeliveredStatus'
    case NotificationStatus.Seen:
      return 'programSettings.reminderSeenStatus'
    case NotificationStatus.Sent:
      return 'programSettings.reminderSentStatus'
    case NotificationStatus.Error:
      return 'programSettings.reminderErrorStatus'
    default:
      return ''
  }
}

interface IProps {
  status: NotificationStatus
}

const ReminderStatus = ({ status }: IProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.statusContainer}>
      <Tooltip title={status === NotificationStatus.Error ? t('programSettings.reminderErrorDescription') : ''}>
        <div
          className={classNames(styles.status, {
            [styles.sent]: status === NotificationStatus.Sent,
            [styles.delivered]: status === NotificationStatus.Delivered,
            [styles.seen]: status === NotificationStatus.Seen,
            [styles.error]: status === NotificationStatus.Error,
          })}
        >
          {t(transformNotificationStatus(status))}
        </div>
      </Tooltip>
    </div>
  )
}

export default ReminderStatus
