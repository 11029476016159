import { DownOutlined } from '@ant-design/icons'
import { DatePicker, Select } from 'antd'
import { default as classNames, default as classnames } from 'classnames'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { Dispatch, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { IScheduledNotificationCreateDto } from 'api/DTOs/scheduleNotification.dto'
import useContentForActions from 'hooks/reminder/useContentForActions'
import useReminderActions, { ActionTypeEnum } from 'hooks/reminder/useReminderActions'
import useReminderTraineesAndPrograms from 'hooks/reminder/useReminderTraineesAndPrograms'
import useIsRtl from 'hooks/useIsRtl'

import { useStore } from 'stores'

import { IScheduledNotification, schedulerReminderOptionEnum } from 'types/ScheduleNotification'
import { FollowUpPostType } from 'types/Template'
import { ReminderToolTypesEnum } from 'types/Template/Tools'

import { urlRe } from 'utils/const'
import LS, { LSKeys } from 'utils/LS'
import mergeTextAndVariable from 'utils/mergeTextAndVariable'
import message from 'utils/message'
import { scheduleReminderCronGenerate, scheduleReminderCronToRepeatOption } from 'utils/reminderCronHandler'
import transformPostTypeToText from 'utils/transformPostTypeToText'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomInput from 'components/CustomComponents/CustomInput'
import CustomSelect from 'components/CustomComponents/CustomSelect'
import CustomTextarea from 'components/CustomComponents/CustomTextarea'
import TextVariables from 'components/TextVariables'

import styles from './styles.module.scss'
import useMentorTagsQuery from 'hooks/tanstack/queries/useMentorTagsQuery'

interface IBackupData {
  date: moment.Moment | null
  time: moment.Moment | null
  value: string
  imageUrl: string
  actionUrl: string
  actionType: ActionTypeEnum
  selectedProgramId: number | null
  selectedContent: string | null
  selectedTraineeIds: number[]
  repeatOption: schedulerReminderOptionEnum
  backupOwnerId: number
}

interface IProps {
  visible: boolean
  onCreate: (data: IScheduledNotificationCreateDto) => Promise<boolean>
  onEdit: (data: IScheduledNotificationCreateDto, id: number) => Promise<boolean>
  programId: number | null
  initialReminderData: IScheduledNotification | null
  setInitialReminderData: Dispatch<IScheduledNotification | null>
  toTraineeId?: number | null
  onCancel?: () => void
  withProgramSelector?: boolean
}

const customSelectWidth = {
  width: '100%',
  style: { width: '100%' },
}
export enum ScheduleSendingOption {
  All,
  Tags,
  Users,
}
export const transformToolTypeToPostType = (type: ReminderToolTypesEnum) => {
  switch (type) {
    case ReminderToolTypesEnum.MEDIA:
      return FollowUpPostType.File
    case ReminderToolTypesEnum.LINK:
      return FollowUpPostType.Link
    case ReminderToolTypesEnum.QUESTIONNAIRE:
      return FollowUpPostType.Questionnaire
    case ReminderToolTypesEnum.SUMMARY:
      return FollowUpPostType.Summary
    default:
      return FollowUpPostType.None
  }
}

const ReminderModule: React.FC<IProps> = ({
  visible,
  onCreate,
  onEdit,
  programId,
  initialReminderData,
  setInitialReminderData,
  toTraineeId,
  onCancel,
  withProgramSelector,
}) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const navigate = useNavigate()

  const [date, setDate] = useState<moment.Moment | null>(null)
  const [time, setTime] = useState<moment.Moment | null>(null)
  const [value, setValue] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [schedule, setSchedule] = useState<number | null>(ScheduleSendingOption.Users)
  const [tagList, setTagList] = useState<number[]>([])
  const { tags, isLoading } = useMentorTagsQuery()

  const onTagsChange = (value: number[]) => {
    setTagList(value)
  }

  const [repeatOption, setRepeatOption] = useState(schedulerReminderOptionEnum.doNotRepeat)

  const {
    selectTraineeOptions,
    selectProgramsOptions,
    selectedTraineeIds,
    setSelectedTraineeIds,
    selectedProgramId,
    onProgramChange,
    isTraineesLoading,
    isProgramsLoading,
  } = useReminderTraineesAndPrograms({ programId, toTraineeId, withProgramSelector })

  const onScheduleChange = (value: number) => {
    setSchedule(value)
    setTagList([])
    setSelectedTraineeIds([])
  }

  const isAllTraineesSelected = selectTraineeOptions.length === selectedTraineeIds.length

  const {
    onContentChange,
    onActionUrlChange,
    urlErrorMessage,
    actionType,
    actionUrl,
    contentTouched,
    initialActionType,
    selectedContent,
    urlTouched,
    actionTypeOptions,
    onActionTypeChange,
    setActionType,
    setActionUrl,
    setInitialActionType,
    setSelectedContent,
    setUrlTouched,
    setContentTouched,
  } = useReminderActions()

  const createBackup = () => {
    if (!userStore.user) return

    const backupData: IBackupData = {
      date,
      time,
      value,
      imageUrl,
      actionUrl,
      actionType,
      selectedProgramId,
      selectedContent,
      selectedTraineeIds,
      repeatOption,
      backupOwnerId: userStore.user.id,
    }
    LS.set(LSKeys.REMINDER, JSON.stringify(backupData))
  }

  useEffect(() => {
    const tryToBackup = () => {
      if (!visible || initialReminderData || !userStore.user) return

      const backup = LS.get(LSKeys.REMINDER)
      if (!backup) return

      const parsed: IBackupData = JSON.parse(backup)

      if (parsed.backupOwnerId === userStore.user.id && selectedProgramId === parsed.selectedProgramId) {
        onProgramChange(parsed.selectedProgramId)

        if (parsed.time) {
          setTime(moment(parsed.time))
        }
        if (parsed.date) {
          setDate(moment(parsed.date))
        }
        setValue(parsed.value)
        setImageUrl(parsed.imageUrl)
        setRepeatOption(parsed.repeatOption)
        setSelectedTraineeIds(parsed.selectedTraineeIds)
        setActionUrl(parsed.actionUrl)
        setActionType(parsed.actionType)
        setSelectedContent(parsed.selectedContent)
      }

      LS.remove(LSKeys.REMINDER)
    }

    tryToBackup()
  }, [visible, initialReminderData, userStore.user?.id])

  const { actionContentOptions, loading: contentLoading } = useContentForActions({
    programId: selectedProgramId,
    date,
    time,
    canRequest: !!selectedProgramId && actionType === ActionTypeEnum.Content,
  })

  useEffect(() => {
    if (programId && visible) {
      onProgramChange(programId)
    }
  }, [programId, visible])

  useEffect(() => {
    if (toTraineeId && visible) {
      setSelectedTraineeIds([toTraineeId])
    }
  }, [toTraineeId, visible])

  useEffect(() => {
    if (!visible || toTraineeId) return

    if (selectTraineeOptions.length === 1) {
      setSelectedTraineeIds([selectTraineeOptions[0].value])
    }
  }, [toTraineeId, visible, selectTraineeOptions])
  useEffect(() => {
    setSchedule(ScheduleSendingOption.Users)
    setTagList([])
  }, [selectedProgramId])

  const initialDataTransform = useMemo(() => {
    if (!initialReminderData) return null
    return {
      value: initialReminderData.message,
      dateTime: moment(initialReminderData.executeAt).utc(),
      userIdTo: initialReminderData.usersTo.map(user => user.id),
      repeatOption: scheduleReminderCronToRepeatOption(initialReminderData.cron),
    }
  }, [initialReminderData])

  const isEdited = !initialDataTransform
    ? false
    : value !== initialDataTransform.value ||
      !initialDataTransform.dateTime.isSame(date) ||
      !initialDataTransform.dateTime.isSame(time) ||
      !_.isEqual(initialDataTransform.userIdTo, selectedTraineeIds) ||
      initialDataTransform.repeatOption !== repeatOption ||
      urlTouched ||
      contentTouched ||
      initialActionType !== actionType

  const sendReminder = async () => {
    try {
      setLoading(true)

      if (!time || !date) return

      const executeAtDate = date?.format().split('T')[0]
      const executeAtTime = time?.utc().format().split('T')[1]

      const executeAt = `${executeAtDate}T${executeAtTime}`

      if ((!(selectedTraineeIds.length || tagList.length) && !userStore.isForAnonSubdomain) || !selectedProgramId)
        return

      const exucutedMoment = moment()

      if (exucutedMoment.isSameOrBefore(moment()) && exucutedMoment.diff(moment(), 'minutes') <= -1) {
        return message.error(t<string>('programSettings.cantChosePastTime'))
      }

      const body: IScheduledNotificationCreateDto = {
        executeAt,
        text: value,
        userIdTo: isAllTraineesSelected ? [] : selectedTraineeIds,
        tagIds: tagList,
        trainingProgramId: selectedProgramId,
        cron: scheduleReminderCronGenerate({
          executeAtDate,
          executeAtTime: time.local().format('HH:mm:ss'),
          option: repeatOption,
        }),
        postId: null,
        postType: 0,
        sendingOption: ScheduleSendingOption.All,
        url: '',
        imageUrl: imageUrl || null,
      }

      if (actionType === ActionTypeEnum.Url) {
        if (urlErrorMessage) return

        body.url = actionUrl
      } else if (actionType === ActionTypeEnum.Content) {
        if (!selectedContent) return

        const [selectedContentId, selectedContentType] = selectedContent.split('-')

        body.postId = +selectedContentId

        body.postType =
          selectedContentType !== null
            ? transformToolTypeToPostType(selectedContentType as ReminderToolTypesEnum)
            : initialReminderData?.postType || 0
      }

      let result = false

      if (initialReminderData) {
        result = await onEdit(body, initialReminderData.id)
      } else {
        result = await onCreate(body)
      }

      if (result) {
        cleanFields()
      }
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  const onNowPress = () => {
    setDate(moment())
    setTime(moment())
  }

  const cleanFields = () => {
    setDate(null)
    setTime(null)
    setValue('')
    setSelectedTraineeIds([])
    onProgramChange(null)
    setSelectedContent(null)
    setActionType(ActionTypeEnum.None)
    setActionUrl('')
    setUrlTouched(false)
    setContentTouched(false)
    setImageUrl('')
  }

  useEffect(() => {
    if (initialReminderData) {
      const { executeAt, message, cron, postId, url, imageUrl, postType } = initialReminderData

      setDate(moment(executeAt))
      setTime(moment(executeAt))
      setValue(message)
      setImageUrl(imageUrl)

      if (postId && postType !== null) {
        setSelectedContent(`${postId}-${transformPostTypeToText(postType)}`)
        setActionType(ActionTypeEnum.Content)
        setInitialActionType(ActionTypeEnum.Content)
      }

      if (url) {
        setActionType(ActionTypeEnum.Url)
        setInitialActionType(ActionTypeEnum.Url)
        setActionUrl(url)
      }

      const actioveRepeatedOption = scheduleReminderCronToRepeatOption(cron)

      setRepeatOption(actioveRepeatedOption)
    }
  }, [initialReminderData])

  useEffect(() => {
    if (initialReminderData) {
      const { usersTo } = initialReminderData
      setSelectedTraineeIds(
        usersTo.length === 0 ? selectTraineeOptions.map(trainee => trainee.value) : usersTo.map(user => user.id)
      )
    }
  }, [initialReminderData, selectTraineeOptions])

  useEffect(() => {
    return () => {
      cleanFields()
      setInitialReminderData(null)
      setRepeatOption(schedulerReminderOptionEnum.doNotRepeat)
    }
  }, [])

  const options = [
    {
      label: t<string>('templateCreation.doNotRepeat'),
      value: schedulerReminderOptionEnum.doNotRepeat,
    },
    {
      label: t<string>('templateCreation.daily'),
      value: schedulerReminderOptionEnum.daily,
    },
    {
      label: t<string>('templateCreation.weekly'),
      value: schedulerReminderOptionEnum.weekly,
    },
    {
      label: t<string>('templateCreation.monthly'),
      value: schedulerReminderOptionEnum.monthly,
    },
  ]

  const optionsSchedule = [
    { label: t<string>('programSettings.tags'), value: ScheduleSendingOption.Tags },
    { label: t<string>('programSettings.users'), value: ScheduleSendingOption.Users },
  ]

  const handleSelectAllTrainees = () => {
    setSelectedTraineeIds(selectTraineeOptions.map(option => option.value))
    setSchedule(ScheduleSendingOption.All)
  }
  const handleSelectTraineeChange = (values: number[]) => setSelectedTraineeIds(values)
  const handleClearAllTrainees = () => {
    setSelectedTraineeIds([])
    setSchedule(ScheduleSendingOption.Users)
  }

  const isRtl = useIsRtl()
  const isEditing = !!initialReminderData
  const invalidImgUrl = !!imageUrl && !urlRe.test(imageUrl)

  return (
    <div>
      {withProgramSelector && (
        <div className={styles.programSelect}>
          <div className={styles.label}>{t<string>('programSettings.program')}</div>
          {!!selectedProgramId && (
            <div
              onClick={() => {
                createBackup()
                navigate(
                  userStore.isMessagesOnlyAccess
                    ? '/message/in-program/' + selectedProgramId
                    : `/programs/${selectedProgramId}?activeTab=Settings`
                )
              }}
              className={classNames(styles.link, {
                [styles.linkRtl]: isRtl,
                [styles.linkLtr]: !isRtl,
              })}
            >
              {t('programCreation.toProgramReminders')}
            </div>
          )}
          <CustomSelect
            value={selectedProgramId}
            options={selectProgramsOptions}
            onChange={onProgramChange}
            className={styles.select}
            loading={isProgramsLoading}
            placeholder={t<string>('dailyReport.selectProgram')}
            autoSelect
            {...customSelectWidth}
          />
        </div>
      )}
      <div className={styles.textbox}>
        <div className={styles.label}>{t<string>('programSettings.notificationMessage')}</div>
        <CustomTextarea
          value={value}
          onChange={e => setValue(e.target.value)}
          maxLength={140}
          rows={4}
          placeholder={t<string>('programSettings.textAreaLimit')}
          className={styles.textArea}
        />
        <div
          className={classnames(styles.vars, {
            [styles.varsRtl]: isRtl,
          })}
        >
          <TextVariables onSelectVariable={variable => setValue(prev => mergeTextAndVariable(prev, variable))} />
        </div>
      </div>
      <div className={styles.label}>{t<string>('programSettings.sendTo')}</div>

      <CustomSelect
        value={schedule !== 0 ? schedule : ScheduleSendingOption.Users}
        options={optionsSchedule}
        onChange={onScheduleChange}
        className={styles.select}
        placeholder={t<string>('programSettings.sendTo')}
        autoSelect
        {...customSelectWidth}
      />

      <div className={styles.midLine}>
        {!toTraineeId &&
          !userStore.isForAnonSubdomain &&
          (schedule !== 1 ? (
            <div>
              <div className={styles.label}>{t<string>('programSettings.selectTrainee')}</div>
              <Select
                value={selectedTraineeIds}
                options={selectTraineeOptions}
                {...customSelectWidth}
                className={styles.select}
                onChange={handleSelectTraineeChange}
                placeholder={t<string>('programSettings.selectTrainee')}
                loading={isTraineesLoading}
                disabled={!selectedProgramId}
                filterOption={true}
                getPopupContainer={trigger => trigger}
                optionFilterProp="label"
                mode="multiple"
                dropdownRender={menu => {
                  return (
                    <div className={styles.menu}>
                      {!!selectTraineeOptions.length && (
                        <>
                          {selectedTraineeIds.length === selectTraineeOptions.length ? (
                            <div onClick={handleClearAllTrainees} className={styles.dropdownBtn}>
                              {t<string>('programSettings.clearAll')}
                            </div>
                          ) : (
                            <div onClick={handleSelectAllTrainees} className={styles.dropdownBtn}>
                              {t<string>('programCreation.selectAll')}
                            </div>
                          )}
                        </>
                      )}
                      {menu}
                    </div>
                  )
                }}
              />
            </div>
          ) : (
            <>
              <div className={styles.label}>{t<string>('companyPage.selectTags')}</div>
              <CustomSelect
                value={tagList}
                options={tags.map(tag => ({
                  value: tag.id,
                  label: tag.name,
                }))}
                onChange={onTagsChange}
                className={styles.select}
                loading={isLoading}
                autoSelect
                {...customSelectWidth}
                filterOption={true}
                disabled={!selectedProgramId}
                getPopupContainer={trigger => trigger}
                optionFilterProp="label"
                mode="multiple"
              />
            </>
          ))}

        {!programId && !withProgramSelector && (
          <div>
            <div className={styles.label}>{t<string>('dailyReport.selectProgram')}</div>
            <CustomSelect
              value={selectedProgramId}
              options={selectProgramsOptions}
              onChange={onProgramChange}
              className={styles.select}
              loading={isProgramsLoading}
              autoSelect
              {...customSelectWidth}
            />
          </div>
        )}

        <div>
          <div className={styles.label}>{t<string>('programSettings.schedule')}</div>
          <CustomSelect
            defaultValue={1}
            onSelect={(val: schedulerReminderOptionEnum) => setRepeatOption(val)}
            value={repeatOption}
            getPopupContainer={trigger => trigger}
            suffixIcon={<DownOutlined style={{ color: '#130F26', fontSize: 12 }} />}
            options={options}
            data-scheduler-select="scheduler-select"
            className={styles.select}
            {...customSelectWidth}
          />
        </div>
      </div>

      <div>
        <CustomInput
          label={t('templateCreation.imageUrl')}
          value={imageUrl}
          onChange={e => setImageUrl(e.target.value)}
          className={classNames(styles.select, styles.input)}
          {...customSelectWidth}
          errorText={invalidImgUrl ? [t('templateCreation.invalidUrl')] : []}
          big
        />
      </div>

      <div className={styles.pickerContainer}>
        <div>
          <div className={styles.label}>{t<string>('programSettings.selectDay')}</div>
          <DatePicker
            value={date}
            onChange={setDate}
            placeholder={t<string>('programSettings.selectADay')}
            className={styles.picker}
            disabledDate={date => date.diff(moment(), 'h') < 0}
          />
        </div>
        <div className={styles.picker}>
          <div className={styles.label}>{t<string>('programSettings.selectTime')}</div>
          <DatePicker
            picker="time"
            value={time}
            onChange={setTime}
            placeholder={t<string>('programSettings.selectATime')}
            className={styles.picker}
            format="HH:mm"
          />
        </div>
        <div className={styles.nowLabelContainer}>
          <div onClick={onNowPress} className={styles.nowLabel}>
            {t<string>('shared.now')}
          </div>
        </div>
      </div>

      <div className={styles.pickerContainer}>
        <div>
          <div className={styles.label}>{t<string>('programSettings.actionType')}</div>
          <CustomSelect
            value={actionType}
            onChange={onActionTypeChange}
            options={actionTypeOptions}
            filterOption={(inputValue, option) =>
              (option?.label?.toString() ?? '').toLowerCase().includes(inputValue.toLowerCase())
            }
            className={styles.select}
            {...customSelectWidth}
          />
        </div>
        <div>
          <div className={styles.label}>{t<string>('programSettings.actionValue')}</div>
          {actionType === ActionTypeEnum.Content ? (
            <CustomSelect
              options={actionContentOptions}
              value={selectedContent}
              onChange={onContentChange}
              loading={contentLoading}
              showSearch
              filterOption={(inputValue, option) =>
                (option?.label?.toString() ?? '').toLowerCase().includes(inputValue.toLowerCase())
              }
              className={styles.autocompleteSelect}
              disabled={!selectedProgramId || !date || !time}
              isError={
                (!selectedContent && contentTouched) ||
                (!!date && !!time && !!selectedProgramId && !actionContentOptions.length && !contentLoading) ||
                !selectedProgramId ||
                !date ||
                !time
              }
              errorMessage={
                !selectedContent && contentTouched
                  ? t('programCreation.required')
                  : !selectedProgramId
                  ? t('programSettings.selectProgramFirst')
                  : !date || !time
                  ? t('programSettings.selectDateFirst')
                  : date && time && !actionContentOptions.length
                  ? t('programCreation.noAvailableContentForReminder')
                  : ''
              }
              {...customSelectWidth}
            />
          ) : (
            <CustomInput
              value={actionUrl}
              onChange={onActionUrlChange}
              className={classNames(styles.select, styles.input)}
              {...customSelectWidth}
              disabled={actionType === ActionTypeEnum.None}
              errorText={urlErrorMessage && actionType !== ActionTypeEnum.None ? [urlErrorMessage] : []}
              big
            />
          )}
        </div>
      </div>

      {(!isEditing || (isEditing && isEdited)) && (
        <div
          className={classnames(styles.footerButtons, {
            [styles.footerOneBtn]: withProgramSelector,
          })}
        >
          {!!onCancel && <CustomButton onClick={onCancel}>{t<string>('dashboard.cancel')}</CustomButton>}
          <CustomButton
            type="primary"
            onClick={sendReminder}
            loading={loading}
            disabled={
              !date ||
              !time ||
              (!(selectedTraineeIds.length || tagList.length) && !userStore.isForAnonSubdomain) ||
              !value ||
              !selectedProgramId ||
              (actionType === ActionTypeEnum.Content && !selectedContent) ||
              (actionType === ActionTypeEnum.Url && !actionUrl) ||
              invalidImgUrl
            }
          >
            {t(isEditing ? 'programCreation.save' : 'programSettings.schedule')}
          </CustomButton>
        </div>
      )}
    </div>
  )
}

export default observer(ReminderModule)
