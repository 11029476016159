import { useQuery } from '@tanstack/react-query'
import userApi from 'api/user.api'

import { useStore } from 'stores'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

const useTraineesCountQuery = () => {
  const { userStore } = useStore()

  const { data: traineesCount, isLoading } = useQuery({
    keepPreviousData: true,
    retry: false,
    queryKey: [QueryKeysEnum.TRAINEES_COUNT, userStore.user?.companyId],
    queryFn: async () => {
      if (!userStore.user?.companyId) throw new Error('Company id not found')
      return userApi.getTraineesCount()
    },
  })

  return {
    traineesCount,
    isLoading,
  }
}

export default useTraineesCountQuery
