import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Space, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { imageMimeTypes, maxAvatarSize } from 'utils/const'
import message from 'utils/message'

import { ProgramImage } from 'types/Program'

import Loader from 'components/Loader'

import styles from './styles.module.scss'

interface IProps {
  title: string
  image: ProgramImage
  setImage: (image: ProgramImage) => void
  loading?: boolean
}

const LogoUpload: React.FC<IProps> = ({ title, image, setImage, loading }) => {
  const { t } = useTranslation()

  const handleChange = useCallback(({ fileList }: UploadChangeParam<UploadFile>) => {
    if (fileList.length !== 1) return

    const file = fileList[0].originFileObj as File

    if (!imageMimeTypes.includes(file.type)) return message.error(t<string>('programCreation.imgErrFileType'))
    if (file.size > maxAvatarSize) return message.error(t<string>('programCreation.imgErrFileSize'))

    setImage({ file, url: URL.createObjectURL(file) })
  }, [])

  return (
    <Space className={styles.imageUploadForm} data-image-upload-form="imageUploadForm">
      {loading ? (
        <div className={styles.loading}>
          <Loader />
        </div>
      ) : (
        <div className={styles.container}>
          <Upload
            listType="picture-card"
            fileList={[]}
            onChange={handleChange}
            beforeUpload={() => false}
            className={styles.upload}
            maxCount={1}
            data-upload-picture="upload-picture"
            style={{ position: 'relative' }}
            showUploadList={{ showRemoveIcon: false }}
          >
            {!image.url ? (
              <div data-icon="icon">
                <PlusOutlined data-file-image="file-image" />
                <div>{t<string>('programCreation.addLogo')}</div>
              </div>
            ) : (
              <>
                <img src={image.url} className={styles.img} />
                <div className={styles.hoverContainer}>
                  <EditOutlined className={styles.editIcon} />
                </div>
              </>
            )}
          </Upload>
        </div>
      )}
    </Space>
  )
}

export default LogoUpload
