import { Button, Card, Form, Input } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useStore } from 'stores'

import { IAdminSignIn } from 'types/forms/SignIn'

import AimpactHeader from 'components/AimpactHeader'

import LoginLayout from 'layouts/LoginLayout'

import styles from './styles.module.scss'

const AdminSignIn = observer(() => {
  const { userStore } = useStore()

  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)
  const [direction, setDirection] = useState<'rtl' | 'ltr'>('ltr')

  useEffect(() => {
    if (userStore.language !== 'en' && userStore.language !== 'ru') {
      setDirection('rtl')
    } else {
      setDirection('ltr')
    }
  }, [userStore.language])

  const onFinish = async (formData: IAdminSignIn) => {
    try {
      setLoading(true)
      await userStore.authAsAdmin(formData.username, formData.password)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <LoginLayout>
      <Card className={styles.card} data-card-3="data-card-3">
        <AimpactHeader />

        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          className={styles.form}
          requiredMark={false}
          dir={direction}
          data-form-3="form-3"
        >
          <span
            className={styles.title}
            children={t<string>('adminLogin.enterYourMobileNumber')}
            data-title-4="title-4"
          />
          <Form.Item
            className={styles.formItem}
            label={t<string>('adminLogin.username')}
            name="username"
            rules={[{ required: true, message: t('programCreation.required'), whitespace: true }]}
            data-form-item-3="form-item-3"
          >
            <Input data-input="data-input" />
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            label={t<string>('adminLogin.password')}
            name="password"
            rules={[{ required: true, message: t('programCreation.required'), whitespace: true }]}
            data-form-item-4="form-item-4"
          >
            <Input.Password data-input-password="input-password" />
          </Form.Item>

          <Form.Item data-form-item-4="form-item-4">
            <Button
              className={styles.btn}
              type="primary"
              htmlType="submit"
              block
              size="large"
              loading={loading}
              data-button-3="button-3"
            >
              {t('login.continue')}
            </Button>
            <Link to="/" data-link="link">
              {t('adminLogin.loginAsUser')}{' '}
            </Link>
          </Form.Item>
        </Form>
      </Card>
    </LoginLayout>
  )
})

export default AdminSignIn
