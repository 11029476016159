import { Dispatch, FC, SetStateAction, memo } from 'react'
import { useTranslation } from 'react-i18next'

import QuickProgramForm from './QuickProgramForm'

import CustomModal from 'components/CustomComponents/CustomModal'

interface IProps {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

const QuickProgramModal: FC<IProps> = ({ visible, setVisible }) => {
  const { t } = useTranslation()

  return (
    <CustomModal
      title={t<string>('programList.createQuickProgram')}
      open={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      bodyStyle={{ paddingBottom: 1 }}
      data-quick-program-modal="quick-program-modal"
      forceRender={false}
      destroyOnClose={true}
    >
      <QuickProgramForm onAction={() => setVisible(false)} />
    </CustomModal>
  )
}

export default memo(QuickProgramModal)
