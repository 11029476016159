import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useInitialSettingsForMentorQuery from 'hooks/tanstack/queries/useInitialSettingsForMentorQuery'

import CustomSelect from 'components/CustomComponents/CustomSelect'

import styles from './styles.module.scss'

interface IProps {
  value: number | null
  setValue: (value: number | null) => void
  preventResettingOnDestroy?: boolean
  className?: string
  labelClassName?: string
}

const SettingsProfileSelector: FC<IProps> = ({
  value,
  setValue,
  preventResettingOnDestroy,
  className,
  labelClassName,
}) => {
  const { t } = useTranslation()

  const { initialSettings, isLoading } = useInitialSettingsForMentorQuery()

  const options = useMemo(() => {
    const res =
      initialSettings?.map(profile => ({
        label: profile.name,
        value: profile.id,
      })) || []

    if (res.length) {
      res.unshift({ label: t<string>('userSettings.default'), value: 0 })
    }

    return res
  }, [initialSettings])

  useEffect(() => {
    return () => {
      if (preventResettingOnDestroy) return

      setValue(null)
    }
  }, [])

  if (!initialSettings?.length) return <></>

  return (
    <div className={styles.container}>
      <CustomSelect
        label={t<string>('userSettings.initialProfile')}
        value={value || 0}
        onChange={setValue}
        options={options}
        className={classNames(styles.select, className)}
        labelClassName={labelClassName}
      />
    </div>
  )
}

export default observer(SettingsProfileSelector)
