import { MenuProps } from 'antd'

type MenuItem = Required<MenuProps>['items'][number]

export const getItem = (
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem =>
  ({
    label,
    key,
    icon,
    children,
    type,
  } as MenuItem)
