import { useEffect, useMemo, useState } from 'react'

import { ITagWithToolRelation } from 'types/Template'

interface IProps {
  tags: ITagWithToolRelation[]
}

const useToolTags = ({ tags = [] }: IProps) => {
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([])

  const selectedTags = useMemo(() => {
    if (!selectedTagIds?.length) return []

    return selectedTagIds.map(tag => ({
      tagId: tag,
      exclude: false,
      id: tags.find(item => item.tagId === tag)?.id || -1,
    }))
  }, [selectedTagIds, tags])

  const entityTagsToRemove = useMemo(() => {
    return tags.filter(tag => !!tag.id && !selectedTagIds.includes(tag.tagId)).map(tag => tag.id)
  }, [selectedTagIds, tags])

  useEffect(() => {
    if (tags.length) {
      setSelectedTagIds(tags.map(tag => tag.tagId))
    }
  }, [tags])

  const handleTagsChange = (tags: number[]) => {
    setSelectedTagIds(tags)
  }

  return { selectedTagIds, handleTagsChange, setSelectedTagIds, selectedTags, entityTagsToRemove }
}

export default useToolTags
