import { ButtonType } from './Report'

export interface IDetails {
  id: number
  name: string
  goal: string
  trainingProgramAvatarId: number
  groupChatAvatarId: number
  formData: FormData
  isPublic?: boolean
  uniqueId?: string
  templateId: number
  daysAmount: number
  numberOfStudent: number
  filter: IProgramFilter
  dateTimeOfJoining?: string
}

export interface IProgramFilter {
  hasSearch: boolean
  hasFilter: boolean
  hasCalendar: boolean
}

export interface IProgramList extends IDetails {
  isPublic: boolean
  uniqueId: string
  endDate: string
  startDate: string
  currentDay: number
  description?: string
  sortingOption: PostsSortingOptionsEnum
  mentors: ILightMentor[]
  companyId: number
  isForAnonymous: boolean
  isInvisible: boolean
  lastPublished: string
  numberOfPosts: number
}

export interface ITraineeProgram extends IProgramList {
  daysAmount: number
  templateId: number
  dateTimeOfJoining: string
}

export interface IProgramCreate {
  programName: string
  avatar: string
  programGoal: string
}

export interface IDailyReport {
  dateTime: string
  day: number
  postCount: number
  complete: number
  dailyReportModelForUsers: IDailyReportUser[]
  trainingProgramName: string
  trainingProgramId: number
}

export interface IDailyReportUser {
  programName: string
  userName: string
  postViewCount: number
  buttonsClicksCount: number
  personalComplete: number
  wallUserQuestionsAndAnswers: IDailyReportAnswer[]
  userQuestionsAndAnswers: IDailyReportAnswer[]
  questionCount: 1
  questionAnsweredCount: 0
  questionnaireCount: 8
  questionnaireAnsweredCount: 8
  postCount: number
  userId: number
  avatarKey: string
  avatarId: number
  id: string
  userGoalChecks: IUserGoal[]
  programId: number
  userPhoneNumber: string
  buttonType: ButtonType
}

export interface IFileFromWall {
  fileId: number
  fileKey: string
  id: number
  jsonForWeb: string
  mimeType: string //'image/jpeg'
  position: number
  programWallPostUnboundId: number
  revealedAt: string
  toolId: number
  vimeoLink: string | null
  youTubeLink: string | null
}

export interface ISummaryWall {
  id: number
  jsonForWeb: string
  position: number
  programWallPostUnboundId: number
  revealedAt: string
  summary: string
  toolId: number
}

export interface ILinkWall {
  id: number
  jsonForWeb: string
  position: number
  postLink: string
  programWallPostUnboundId: number
  revealedAt: string
  toolId: number
}

export interface IUserGoal {
  completionAt: number
  counter: number
  programGoalId: number
  text: string
}

export interface IDailyReportAnswer {
  question: string
  answer: null | string
  jsonForWeb: string
  questionId: number
  coments: IComment[]
  isBound: boolean
}

export interface IComment {
  comment: string
  createdAt: string
  id: number
  isPinned: boolean
  questionCommentSectionId: number
  user: {
    avatarId: number
    avatarKey: string
    firstName: string
    id: number
    isDeleted: boolean
    isMentor: boolean | null
    lastName: string
    phoneNumber: string
  }
}

export interface IOptimisticComment extends IComment {
  temporary?: true
}

export enum ProgramCreationStepsEnum {
  DETAILS,
  TEMPLATE,
  INVITES,
  PREVIEW,
}

export interface ProgramForm {
  name: string
  goal: string
  isPublic: boolean
  uniqueId: string
  description: string
  startDate?: string
  companyId: number
  isVisible: boolean
}

export interface IProgramDetailsForm {
  sendSms: boolean
  enableChat: boolean
  showParticipants: boolean
  autoAcceptInvite: boolean
  isVisible: boolean
}

export interface ProgramImage {
  file?: File
  url?: string
}

export interface ILightMentor {
  avatarId: number
  firstName: string
  id: number
  lastName: string
  phoneNumber: string
}

export interface ILightProgram {
  createdAt: string
  duration: number
  id: number
  name: string
  traineesCount: number
  mentors: ILightMentor[]
}

export interface IUserTableProgram {
  id: number
  name: string
  mentors: ILightMentor[]
}

export enum PostsSortingOptionsEnum {
  RevealDate,
  EventDay,
}

export enum TraineesLastActiveOrderBy {
  None,
  Ascending,
  Descending,
}

export enum LastActiveFilterEnum {
  AllActive,
  OnlyFor30Days,
  Registered,
}

export enum LastActiveTraineesTimeRange {
  All,
  Day,
  Week,
  Month,
  Inactive,
}

export interface ISuperLightProgram {
  id: number
  name: string
}
