import { useCallback, useMemo, useState } from 'react'

import { useStore } from 'stores'

import useAllUserProgramsQuery from 'hooks/tanstack/queries/useAllUserProgramsQuery'
import useTraineesInProgramQuery from 'hooks/tanstack/queries/useTraineesInProgramQuery'

import { IProgramList } from 'types/Program'
import { ITraineeWithInvite } from 'types/User'

interface IProps {
  toTraineeId?: number | null
  programId: number | null
  withProgramSelector?: boolean
}

const mapTraineesToOptions = (trainees: ITraineeWithInvite[]) =>
  trainees.map(trainee => ({
    value: trainee.id,
    label: trainee.firstName && trainee.lastName ? `${trainee.firstName} ${trainee.lastName}` : trainee.phoneNumber,
  }))

const mapProgramsToOptions = (programs: IProgramList[]) =>
  programs.map(program => ({ value: program.id, label: program.name }))

const useReminderTraineesAndPrograms = ({ programId, toTraineeId, withProgramSelector }: IProps) => {
  const { userStore } = useStore()

  const [selectedTraineeIds, setSelectedTraineeIds] = useState<number[]>(() => (toTraineeId ? [toTraineeId] : []))
  const [selectedProgramId, setSelectedProgramId] = useState<number | null>(() => programId)

  const { isLoading: isTraineesLoading, trainees } = useTraineesInProgramQuery(programId || selectedProgramId)
  const { isLoading: isProgramsLoading, programs } = useAllUserProgramsQuery(
    withProgramSelector && toTraineeId ? toTraineeId : userStore.user?.id
  )

  const selectTraineeOptions = useMemo(() => mapTraineesToOptions(trainees), [trainees])
  const selectProgramsOptions = useMemo(() => mapProgramsToOptions(programs), [programs])

  const onProgramChange = useCallback(
    (id: number | null) => {
      setSelectedProgramId(id)
      setSelectedTraineeIds(toTraineeId ? [toTraineeId] : [])
    },
    [toTraineeId]
  )

  return {
    selectTraineeOptions,
    selectProgramsOptions,
    selectedTraineeIds,
    setSelectedTraineeIds,
    selectedProgramId,
    onProgramChange,
    isTraineesLoading,
    isProgramsLoading,
  }
}

export default useReminderTraineesAndPrograms
