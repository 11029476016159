import { useQuery } from '@tanstack/react-query'

import wallPostApi from 'api/wallPost.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { IDayData } from 'types/Template'

import { templateParserFromServer } from 'utils/parseTemplate'

interface IProps {
  programId: number
  search: string
}

const useWallContentSearchQuery = ({ programId, search }: IProps) => {
  const trimmedSearch = search.trim()

  const {
    data: days,
    isLoading,
    isFetching,
    isFetched,
    isError,
  } = useQuery({
    keepPreviousData: true,
    initialData: [],
    enabled: !!programId && !!trimmedSearch,
    queryKey: [QueryKeysEnum.WALL_SEARCHED_CONTENT, programId, trimmedSearch],
    queryFn: async () => {
      const posts = await wallPostApi.searchContent({ programId, search: trimmedSearch })
      const mapped = templateParserFromServer.setWallPostsData(posts).run()
      const result: IDayData[] = []

      mapped.forEach(day => {
        const dayItems = day.dayItems.map(item => ({
          ...day,
          dayItems: [item],
        }))
        result.push(...dayItems)
      })

      return result
    },
  })

  return { days, isLoading: isLoading || (isFetching && !isFetched), isError }
}

export default useWallContentSearchQuery
