import { useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useStore } from 'stores'

import useActivityQuery from 'hooks/tanstack/queries/useActivityQuery'
import useDashboardTraineesCountQuery from 'hooks/tanstack/queries/useDashboardTraineesCountQuery'
import useLeastPerformanceTrainee from 'hooks/tanstack/queries/useLeastPerformanceTrainee'
import useMentorDashboardProgramsQuery from 'hooks/tanstack/queries/useMentorDashboardProgramsQuery'
import useDebounce from 'hooks/useDebounce'

import Attendance from './Attendance'
import DashboardHeader from './DashboardHeader'
import LeastPerformers from './LeastPerformers'
import RecentEntries from './RecentEntries'
import SelectedProgramInfo from './SelectedProgramInfo'

import Loader from 'components/Loader'
import PageContainer from 'components/PageContainer'

import styles from './styles.module.scss'

const DashboardMentor = () => {
  const { programStore } = useStore()

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    search: '',
    programId: '',
  })

  const searchValue = searchParams.get('search') || ''
  const activeProgramId = searchParams.get('programId') || null

  const debounceParams = useDebounce(searchValue, 500)

  const setSearchValue = (search: string) => {
    setSearchParams(prev => {
      prev.set('search', search)
      prev.set('programId', searchParams.get('programId') || '')
      return prev
    })
  }

  const setActiveProgram = (programId: number | null) => {
    setSearchParams(prev => {
      prev.set('search', '')
      prev.set('programId', programId?.toString() || '')
      return prev
    })
  }

  const currentSelectedProgramId = useMemo(() => {
    if (activeProgramId) {
      const exist = programStore.userPrograms.find(program => program.id === +activeProgramId)

      if (exist) {
        return activeProgramId
      }
    }

    return ''
  }, [activeProgramId, programStore.userPrograms])

  const { isInitialLoading, isFetched, userPrograms, recentEntriesTrainees } = useMentorDashboardProgramsQuery({
    currentSelectedProgramId,
    setActiveProgram,
  })

  const { leastPerformersTrainees, searchLoading } = useLeastPerformanceTrainee({
    currentSelectedProgramId,
    debounceParams,
  })

  const { traineesCount } = useDashboardTraineesCountQuery({ currentSelectedProgramId })

  const { chartData, periodSelect, setPeriodSelect } = useActivityQuery(currentSelectedProgramId)

  const currentSelectedProgram = useMemo(() => {
    return programStore.userPrograms.find(program => program.id === +currentSelectedProgramId)
  }, [currentSelectedProgramId, programStore.userPrograms])

  const handleGoToProgramClick = () => {
    navigate('/programs/' + currentSelectedProgramId)
  }

  return (
    <PageContainer fullScreen>
      {isInitialLoading || !isFetched ? (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      ) : (
        <>
          <DashboardHeader
            currentSelectedProgramId={currentSelectedProgramId}
            handleProgramChange={setActiveProgram}
            periodSelect={periodSelect}
            searchValue={searchValue}
            setPeriodSelect={setPeriodSelect}
            setSearchValue={setSearchValue}
            userPrograms={userPrograms}
          />

          <div className={styles.dashboardGrid}>
            <div>
              {currentSelectedProgram && (
                <SelectedProgramInfo
                  goal={currentSelectedProgram.goal}
                  handleGoToProgramClick={handleGoToProgramClick}
                />
              )}
              <Attendance
                chartData={chartData || []}
                infoData={{
                  programs: userPrograms.length ? userPrograms.length - 1 : '',
                  trainees: traineesCount,
                  days: currentSelectedProgram?.daysAmount || 0,
                }}
                key={periodSelect}
                currentSelectedProgramId={currentSelectedProgramId}
              />
              <RecentEntries recentEntriesTrainees={recentEntriesTrainees} programId={currentSelectedProgramId} />
            </div>
            <LeastPerformers leastPerformersTrainees={leastPerformersTrainees} loading={searchLoading} />
          </div>
        </>
      )}
    </PageContainer>
  )
}

export default DashboardMentor
