import { Dispatch, FC, SetStateAction, memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IGoal } from 'types/Goal'

import GoalForm from '../GoalForm'

import ActionsDropdown from 'components/ActionsDropdown'

import styles from './styles.module.scss'

interface IProps {
  goal: IGoal
  idx: number
  setGoals: Dispatch<SetStateAction<IGoal[]>>
  setNumberOfEditItems: Dispatch<SetStateAction<number>>
  formVisible: boolean
}

const Goal: FC<IProps> = ({ goal, idx, setGoals, setNumberOfEditItems, formVisible }) => {
  const [isEditing, setIsEditing] = useState(false)

  const { t } = useTranslation()

  const items = useMemo(
    () => [
      {
        key: '1',
        label: t<string>('programList.edit'),
        disabled: formVisible,
        onClick: () => {
          setIsEditing(true)
          setNumberOfEditItems(prev => prev + 1)
        },
      },
      {
        key: '2',
        label: t<string>('programList.delete'),
        onClick: () => setGoals(prev => prev.filter((_, i) => i !== idx)),
      },
    ],
    [idx, formVisible]
  )

  if (isEditing) {
    return (
      <GoalForm
        editingIdx={idx}
        setFormVisible={value => {
          setIsEditing(value)
          setNumberOfEditItems(prev => (value ? prev + 1 : prev - 1))
        }}
        setGoals={setGoals}
        initialValues={{
          completionAt: goal.completionAt,
          goal: goal.text,
          iconString: goal.iconString,
          unit: goal.unit || '',
        }}
      />
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.emoji}>{goal.iconString}</div>
        <div>
          <div className={styles.text}>{goal.text}</div>
          <div className={styles.completionAt}>{`${goal.completionAt} ${goal.unit || ''}`}</div>
        </div>
      </div>
      <div className={styles.dropdown}>
        <ActionsDropdown items={items} />
      </div>
    </div>
  )
}

export default memo(Goal)
