import { planingToolTypesEnum } from 'types/Template'

const isFileType = (type: planingToolTypesEnum) => {
  return (
    type === planingToolTypesEnum.AUDIO ||
    type === planingToolTypesEnum.FILE ||
    type === planingToolTypesEnum.IMAGE ||
    type === planingToolTypesEnum.VIDEO
  )
}

export default isFileType
