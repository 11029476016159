import { Form, ModalProps } from 'antd'
import { UploadFile } from 'antd/lib/upload'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useUpdateProgramMutation from 'hooks/tanstack/mutations/useUpdateProgramMutation'
import useProgramImgInterceptor from 'hooks/useProgramImgInterceptor'

import { useStore } from 'stores'

import { imageMimeTypes } from 'utils/const'
import message from 'utils/message'

import { IProgramList, PostsSortingOptionsEnum, ProgramForm, ProgramImage } from 'types/Program'

import CustomButton from 'components/CustomComponents/CustomButton'
import CustomModal from 'components/CustomComponents/CustomModal'
import ProgramDetailsForm from 'components/ProgramDetailsForm'

import styles from './styles.module.scss'

import DefaultLogo from 'sources/images/logo.png'

const stateCopy = {
  programName: '',
  programGoal: '',
  programDescription: '',
  isPublic: false,
  uniqueId: '',
  sortingOption: PostsSortingOptionsEnum.RevealDate,
  companyId: 0,
  visible: true,
}

interface IProps extends ModalProps {
  program: IProgramList
  afterUpdate: () => void
}

const InfoEditModal: React.FC<IProps> = ({ onCancel, afterUpdate, program, ...rest }) => {
  const { image: initialProgramAvatar, isLoading } = useProgramImgInterceptor({ id: +program.trainingProgramAvatarId })

  const [newAvatar, setNewAvatar] = useState<ProgramImage>({})

  const { t } = useTranslation()
  const { userStore } = useStore()

  const [sortingOption, setSortingOption] = useState(() => program.sortingOption)

  const initialAvatar = initialProgramAvatar || DefaultLogo

  useEffect(() => {
    setNewAvatar({ url: initialAvatar })
  }, [initialAvatar])

  useEffect(() => {
    form.setFieldsValue({
      name: program.name,
      goal: program.goal,
      isPublic: userStore.isForAnonSubdomain ? program.isForAnonymous : program.isPublic,
      uniqueId: program.uniqueId,
      description: program.description,
      companyId: program.companyId,
      isVisible: !program.isInvisible,
    })

    stateCopy.programName = program.name
    stateCopy.programGoal = program.goal
    stateCopy.programDescription = program.description || ''
    stateCopy.isPublic = !!program.isPublic
    stateCopy.uniqueId = program.uniqueId || ''
    stateCopy.sortingOption = sortingOption
    stateCopy.companyId = program.companyId
    stateCopy.visible = !program.isInvisible
  }, [program])

  const handleChangeAvatar = (file: UploadFile<any> | undefined) => {
    if (file?.type && !imageMimeTypes.includes(file.type)) {
      message.error(t<string>('profile.userFileUploadError'))
      return
    }

    if (!file) {
      setNewAvatar({})
    }

    setNewAvatar({
      file: file as unknown as File,
      url: file ? URL.createObjectURL(file as unknown as Blob) : '',
    })
  }

  const [form] = Form.useForm<ProgramForm>()

  const { updateProgram, isLoading: updateProgramLoading } = useUpdateProgramMutation({
    avatarId: +program.trainingProgramAvatarId,
    programId: program.id,
    newAvatar,
    afterUpdate,
    //@ts-ignore
    form: form,
    sortingOption,
  })

  useEffect(() => {
    if (!rest.open) {
      setNewAvatar({
        url: initialAvatar,
      })

      form.setFieldsValue({
        name: stateCopy.programName,
        goal: stateCopy.programGoal,
        isPublic: stateCopy.isPublic,
        uniqueId: stateCopy.uniqueId,
        description: stateCopy.programDescription || '',
        companyId: stateCopy.companyId,
      })
    }
  }, [rest.open])

  return (
    <CustomModal
      {...rest}
      title={<h2 className="modal-title">{t('programCreation.generalInformation')}</h2>}
      footer={null}
      onCancel={onCancel}
      destroyOnClose
    >
      <ProgramDetailsForm
        form={form}
        logoImage={newAvatar}
        setLogoImage={image => handleChangeAvatar(image.file as unknown as UploadFile<any> | undefined)}
        programId={program.id}
        handleChange={() => {}}
        handleSubmit={() => {}}
        loading={isLoading}
        sortingOption={sortingOption}
        setSortingOption={setSortingOption}
      />
      <div className={styles.footerButtons}>
        <CustomButton onClick={onCancel}>{t('templateCreation.discard')}</CustomButton>
        <CustomButton type="primary" onClick={() => updateProgram()} loading={updateProgramLoading}>
          {t('programCreation.save')}
        </CustomButton>
      </div>
    </CustomModal>
  )
}

export default InfoEditModal
