import { FollowUpActionsEnum } from 'types/Template'

import { urlRe } from 'utils/const'
import zapHookValidate from 'utils/zapHookValidate'

const validateFollowUpActions = (currentAction: FollowUpActionsEnum, currentActionField: string) => {
  return currentAction === FollowUpActionsEnum.URL
    ? urlRe.test(currentActionField)
    : currentAction === FollowUpActionsEnum.content
    ? currentActionField && !isNaN(+currentActionField)
    : currentAction === FollowUpActionsEnum.Webhook
    ? zapHookValidate(currentActionField)
    : true
}

export default validateFollowUpActions
