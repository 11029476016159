import { Radio } from 'antd'
import { FC, memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import sortingByLocale from 'utils/sortingByLocale'

import ContextMenu from 'components/ContextMenu'
import SearchBar from 'components/SearchBar'

import styles from './styles.module.scss'

interface IProps {
  onChange: (id: number | null) => void
  programId: number | null
  items: { name: string; id: number }[]
  onReset?: () => void
  searchPlaceholder: string
  noItemsMessage: string
  title: string
}

const SelectContextMenu: FC<IProps> = ({
  onChange,
  programId,
  items,
  onReset,
  searchPlaceholder,
  noItemsMessage,
  title,
}) => {
  const [searchVal, setSearchVal] = useState('')

  const { userStore } = useStore()

  const { t } = useTranslation()

  const itemsToShow = useMemo(() => {
    return sortingByLocale(
      items.filter(item => item.name.toLowerCase().includes(searchVal.toLowerCase())),
      userStore.language,
      'name'
    )
  }, [items, searchVal, userStore.language])

  return (
    <ContextMenu>
      <SearchBar value={searchVal} onChangeCustom={setSearchVal} placeholder={searchPlaceholder} />
      <div className={styles.titleContainer}>
        <div className={styles.selectProgramTitle}>{title}</div>
        {!!onReset && (
          <div className={styles.reset} onClick={onReset}>
            {t<string>('companyPage.reset')}
          </div>
        )}
      </div>
      {!itemsToShow.length ? (
        <div className={styles.noPrograms}>{noItemsMessage}</div>
      ) : (
        <>
          {itemsToShow.length > 1 && (
            <div onClick={() => onChange(null)} className={styles.program} key={0}>
              <Radio checked={!programId}>{t<string>('programCreation.All')}</Radio>
            </div>
          )}
          {itemsToShow.map(item => (
            <div onClick={() => onChange(item.id)} className={styles.program} key={item.id}>
              <Radio checked={programId === item.id}>{item.name}</Radio>
            </div>
          ))}
        </>
      )}
    </ContextMenu>
  )
}

export default memo(SelectContextMenu)
