import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useStore } from 'stores'

import message from 'utils/message'

interface IProps {
  currentSelectedProgramId: string
  setActiveProgram: (programId: number | null) => void
}

const useMentorDashboardProgramsQuery = ({ currentSelectedProgramId, setActiveProgram }: IProps) => {
  const { programStore, mentorDashboardStore } = useStore()

  const { t } = useTranslation()

  const {
    data: { recentEntriesTrainees, userPrograms },
    isLoading,
    isInitialLoading,
    isFetched,
  } = useQuery({
    keepPreviousData: true,
    queryKey: ['dashboard', currentSelectedProgramId],
    queryFn: async () => {
      try {
        const [userProgramsResponse, recentEntriesTrainees] = await Promise.all([
          programStore.getUserPrograms(currentSelectedProgramId),
          mentorDashboardStore.getRecentEntriesTrainees({
            trainingProgrmId: currentSelectedProgramId,
            dateTimeFrom: moment()
              .add(-24, 'h')
              .utc()
              .set('hours', 0)
              .set('minutes', 0)
              .set('seconds', 0)
              .set('milliseconds', 0)
              .toISOString(),
            dateTimeTill: moment()
              .utc()
              .set('hours', 0)
              .set('minutes', 0)
              .set('seconds', 0)
              .set('milliseconds', 0)
              .toISOString(),
          }),
        ])

        const programIds = userProgramsResponse?.map(program => ({ value: program.id, label: program.name }))

        if (programIds?.length === 1) {
          setActiveProgram(programIds[0].value)
        }

        return {
          userPrograms: programIds ? [{ value: '', label: t<string>('programCreation.All') }, ...programIds] : [],
          recentEntriesTrainees: recentEntriesTrainees || [],
        }
      } catch (e) {
        message.error(e.message || t<string>('programCreation.smtWrong'))
        return {
          userPrograms: [],
          recentEntriesTrainees: [],
        }
      }
    },
    initialData: {
      userPrograms: [],
      recentEntriesTrainees: [],
    },
  })

  return {
    isInitialLoading,
    isFetched,
    userPrograms,
    recentEntriesTrainees,
  }
}

export default useMentorDashboardProgramsQuery
