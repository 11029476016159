import { authApi } from 'config'
import { t } from 'i18next'

import {
  IEditPermissionsInCompanyDto,
  IGetCompanyMentorsParams,
  IGetCompanyTraineesParams,
  IInviteMentorToCompanyDto,
} from './DTOs/company.dto'

import { PaginationResponse } from 'types/common'
import { ICompany, ICompanyProgram, ICompanyZappierSettings } from 'types/Company'
import { ICommonUser, ICompanyEditFields } from 'types/Dashboard'
import { ILightProgram } from 'types/Program'
import { ITraineesCount, IUserInfo } from 'types/User'

import message from 'utils/message'

const controller = 'Company/'

const companyApi = {
  createApiKey: async () => {
    const response = await authApi.patch(controller + 'createApiKey')

    return response.data
  },
  deleteApiKey: async () => {
    const response = await authApi.patch(controller + 'deleteApiKey')

    return response.data
  },
  getApiKey: async () => {
    const response = await authApi.get(controller + 'apiKey')

    return response.data
  },
  getSearchedPrograms: async (
    str: string,
    companyId: number,
    pageNumber: number,
    pageLength: number,
    programIds: number[]
  ) => {
    let queryString = ''

    programIds?.forEach((id, idx) => {
      if (idx === 0) {
        queryString += '?programIds=' + id
      } else {
        queryString += '&programIds=' + id
      }
    })

    const { data } = await authApi.get<PaginationResponse<ICompanyProgram>>(
      controller + 'companyProgramsByName' + queryString,
      {
        params: {
          companyId,
          str,
          pageLength,
          pageNumber,
        },
      }
    )

    return data
  },
  async getCompanyTraineesAutocomplete({
    params,
    selectedProgramIds,
  }: {
    params: IGetCompanyTraineesParams
    selectedProgramIds?: number[]
  }) {
    let queryString = ''

    selectedProgramIds?.forEach((id, idx) => {
      if (idx === 0) {
        queryString += '?programIds=' + id
      } else {
        queryString += '&programIds=' + id
      }
    })

    const response = await authApi.get<PaginationResponse<ICommonUser>>(controller + 'trainees' + queryString, {
      params,
    })

    return response.data
  },
  async getCompanyMentorsAutocomplete(params: IGetCompanyMentorsParams) {
    const response = await authApi.get<PaginationResponse<ICommonUser>>(controller + 'mentors', {
      params,
    })

    return response.data
  },
  async getCompanyById(companyId: number) {
    const { data } = await authApi.get<ICompany>(controller + companyId)

    return data
  },
  async getCompanyProgramsLight(companyId: number) {
    const { data } = await authApi.get<ILightProgram[]>(controller + 'companyProgramsLight', { params: { companyId } })

    return data
  },
  async editCompanyDataByCompanyAdmin(id: number, formFields: ICompanyEditFields): Promise<void> {
    await authApi.patch(controller + 'editCompany/', {
      id,
      ...formFields,
      name: formFields.companyName,
    })
  },
  async inviteMentorToCompany(dto: IInviteMentorToCompanyDto, skipMessage?: boolean) {
    try {
      const response = await authApi.post<number>(controller + 'inviteTrainerToCompany', dto)
      if (!skipMessage) message.success(t('dashboard.mentorInviteSuccess'))
      return response.data
    } catch (e) {
      if (skipMessage) return false

      const { StatusCode, Message } = e.response.data

      const messageContent = Message.includes('Users with')

      if (StatusCode === 400 && messageContent) {
        message.error(t('companyPage.trainerExist'))
        return false
      }

      message.error(t('programCreation.smtWrong'))
      return false
    }
  },
  async deleteTrainerFromCompany(companyId: number, trainerId: number) {
    await authApi.patch(
      controller + 'deleteTrainerFromCompany',
      {},
      {
        params: {
          companyId,
          trainerId,
        },
      }
    )

    return true
  },
  async editCompanySettings(companyId: number, companyZapierSetting: ICompanyZappierSettings) {
    await authApi.patch(controller + 'editSettings', {
      companyId,
      companyZapierSetting,
    })
  },
  async getCompanySettings(companyId: number) {
    const options = await authApi.get<ICompanyZappierSettings>(`${controller}settings/${companyId}`)

    return options.data
  },
  async blockUserByModerator(userId: number, until: string | undefined, description: string): Promise<void | boolean> {
    try {
      await authApi.post(controller + 'blockUserByModerator', {
        userId,
        until: until ?? null,
        description,
      })

      message.success(t('dashboard.userBlockedSuccess'))

      return true
    } catch (e) {
      message.error(t('programCreation.smtWrong'))
      return false
    }
  },
  async unBlockUserByModerator(id: number): Promise<void | boolean> {
    try {
      await authApi.patch(controller + 'unBlockUserByModerator/' + id)

      message.success(t('dashboard.userUnblockSuccess'))
      return true
    } catch (e) {
      console.log(e)

      const { StatusCode, Message } = e.response.data

      const isUnBanErr = Message.includes('unban')

      if (isUnBanErr) {
        message.error(t('companyPage.unBanError'))
        return
      }

      message.error(t('programCreation.smtWrong'))
      return
    }
  },
  async addMentorToProgram(programId: number, userId: number) {
    const response = await authApi.post(
      controller + 'addMentorToProgram',
      {},
      {
        params: {
          programId,
          userId,
        },
      }
    )

    return response.data
  },
  async removeMentorFromProgram(programId: number, userId: number) {
    const response = await authApi.delete(controller + 'removeMentorFromProgram', {
      params: {
        programId,
        userId,
      },
    })

    return response.data
  },
  async editPermission(dto: IEditPermissionsInCompanyDto) {
    const response = await authApi.patch(controller + 'editPermissions', dto)
    return response.data
  },
  async getTraineesCount(companyId: number) {
    const response = await authApi.get<ITraineesCount>(controller + 'traineesCount/' + companyId)
    return response.data
  },
  async getUserInfo(phoneNumber: string) {
    const response = await authApi.get<IUserInfo>(controller + 'userInfo', { params: { phoneNumber } })
    return response.data
  },
  async getMentorsCount(companyId: number) {
    const response = await authApi.get<ITraineesCount>(controller + 'mentorsCount/' + companyId)
    return response.data
  },
}

export default companyApi
