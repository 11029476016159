import { t } from 'i18next'
import React from 'react'

import { ProgramCreationStepsEnum } from 'types/Program'

import CustomButton from 'components/CustomComponents/CustomButton'

import styles from './styles.module.scss'

interface IProps {
  onNext: () => void
  onPrev: () => void
  isNextDisabled?: boolean
  currentStep: ProgramCreationStepsEnum
  isLoading: boolean
}

const BottomPanel: React.FC<IProps> = ({ onNext, onPrev, isNextDisabled, currentStep, isLoading }) => {
  return (
    <div className={styles.bottomFunctionalButtons}>
      <div>
        <CustomButton
          style={{ marginInlineEnd: 10 }}
          data-program-creation-back="program-creation-back"
          id="program-creation-back"
          onClick={onPrev}
          disabled={!currentStep}
        >
          {t<string>('templateCreation.back')}
        </CustomButton>

        <CustomButton
          type="primary"
          onClick={onNext}
          disabled={isNextDisabled}
          data-program-creation-back="program-creation-next"
          id="program-creation-next"
          loading={isLoading}
        >
          {currentStep === ProgramCreationStepsEnum.PREVIEW
            ? t<string>('programCreation.createProgram')
            : t<string>('dailyReport.next')}
        </CustomButton>
      </div>
    </div>
  )
}

export default BottomPanel
