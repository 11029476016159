import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import scheduleNotificationApi from 'api/scheduleNotification.api'

import usePagination from 'hooks/usePagination'

import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { NotificationDisplayFilter } from 'types/ScheduleNotification'

import message from 'utils/message'

interface IProps {
  programId: number
  filter: NotificationDisplayFilter
  search: string
}

const PAGE_LENGTH = 25

const useScheduledNotificationQuery = ({ filter, programId, search }: IProps) => {
  const { t } = useTranslation()

  const { propsToPaginationComponent, setPagination, pagination } = usePagination({
    pageLength: PAGE_LENGTH,
    hideSizer: true,
  })

  const { data, isLoading, isError, isFetched, isFetching } = useQuery({
    queryKey: [QueryKeysEnum.SCHEDULED_NOTIFICATIONS, programId, filter, search, pagination.pageNumber || 1],
    queryFn: async () => {
      const result = await scheduleNotificationApi.getScheduledNotifications({
        trainingProgramId: programId,
        filter,
        pageNumber: pagination.pageNumber || 1,
        pageLength: PAGE_LENGTH,
        search,
      })

      setPagination(prev => {
        const paginationToSet = {
          ...prev,
          itemCount: result.itemCount,
          totalItems: result.totalItems,
          totalPages: result.totalPages,
        }

        if (pagination.pageNumber > result.totalPages) {
          paginationToSet.pageNumber = result.totalPages
        }

        return paginationToSet
      })

      return result.items
    },
    onError: e => {
      console.log('e', e)
      message.error(t<string>('programCreation.smtWrong'))
    },
    keepPreviousData: true,
  })

  return {
    scheduledNotifications: data,
    isError,
    isLoading,
    propsToPaginationComponent,
    setPagination,
    isFetching: isFetching && !isFetched,
  }
}

export default useScheduledNotificationQuery
