import { authApi } from 'config'

import { IFileName } from 'types/common'
import { StoredFileDto } from './swagger.api'

const endpoint = '/File/'

interface IUploadImagesRes {
  id: number
  key: string
  mimeType: string // 'image/png'
  name: string
  size: number
}

const fileApi = {
  async deleteFiles(ids: number[]) {
    await authApi.patch(`${endpoint}delete`, { ids })
  },
  async getImageById(id: number) {
    try {
      const response = await authApi.get(endpoint + id, {
        headers: {
          Accept: 'application/json, text/plain',
          Connection: 'keep-alive',
          'Access-Control-Expose-Headers': 'Content-Disposition',
          'Accept-Encoding': 'identity',
          'Transfer-Encoding': 'chunked',
        },
        responseType: 'blob',
      })

      return URL.createObjectURL(response.data)
    } catch (e) {
      console.log(e)
    }
  },
  async getFileInfo(id: number) {
    try {
      const response = await authApi.get<IFileName>(`${endpoint}fileInfo/${id}`)

      return response.data
    } catch (e) {
      console.log(e)
      return null
    }
  },
  async uploadImage(formData: FormData) {
    const response = await authApi.post<IUploadImagesRes[]>(endpoint + 'UploadImages', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data?.[0]?.id || null
  },
  async uploadImages(formData: FormData, onDownloadProgress: (progressEvent: any) => void) {
    const response = await authApi.post<IUploadImagesRes[]>(endpoint + 'UploadImages', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onDownloadProgress,
    })

    return response
  },
  async getFile(id: number, onDownloadProgress: (progressEvent: any) => void) {
    const response = await authApi.get(endpoint + id, {
      headers: {
        Accept: 'application/json, text/plain',
        Connection: 'keep-alive',
        'Access-Control-Expose-Headers': 'Content-Disposition',
        'Accept-Encoding': 'identity',
        'Transfer-Encoding': 'chunked',
      },
      onDownloadProgress,
      responseType: 'blob',
    })

    return response
  },
  async uploadFiles(formData: FormData, onUploadProgress?: (progressEvent: any) => void) {
    const response = await authApi.post<StoredFileDto[]>('File/UploadFiles', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })

    return response
  },
}

export default fileApi
