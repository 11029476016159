import { useTranslation } from 'react-i18next'

import { IScheduledNotificationCreateDto } from 'api/DTOs/scheduleNotification.dto'
import scheduleNotificationApi from 'api/scheduleNotification.api'

import ReminderModule from 'modules/ReminderModule'

import PageContainer from 'components/PageContainer'

import styles from './styles.module.scss'

const Message = () => {
  const { t } = useTranslation()

  const onCreate = async (data: IScheduledNotificationCreateDto) => {
    const ok = await scheduleNotificationApi.createScheduledNotification(data)

    if (!ok) return false

    return true
  }

  const onEdit = async () => {
    return true
  }

  return (
    <PageContainer>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>{t<string>('programSettings.sendMessage')}</div>
        </div>
        <ReminderModule
          visible
          programId={null}
          toTraineeId={null}
          initialReminderData={null}
          setInitialReminderData={() => {}}
          onCreate={onCreate}
          onEdit={onEdit}
          withProgramSelector
        />
      </div>
    </PageContainer>
  )
}

export default Message
