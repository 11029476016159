import { Rule } from 'antd/lib/form'
import { t } from 'i18next'

export const positiveNumberValidator = (rule: Rule, value: string, callback: (error?: string) => void) => {
  const trimmedValue = value?.trim()

  if (+trimmedValue < 0) {
    return callback(t('shared.positiveNumberError'))
  }

  callback()
}
