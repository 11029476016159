import { LoadingOutlined } from '@ant-design/icons'
import { Avatar, Image } from 'antd'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import Block from '../Block'

import logoUrl from 'sources/images/logo.svg'

interface IProps {}

const CompanyProfile: React.FC<IProps> = () => {
  const { userStore } = useStore()
  const { t } = useTranslation()

  return (
    <Block
      Avatar={
        <Avatar
          icon={
            userStore.companyLogoLoading ? (
              <LoadingOutlined style={{ color: '#333' }} />
            ) : (
              <Image src={userStore.companyLogo || logoUrl} style={{ width: 80, height: 80 }} preview={false} />
            )
          }
          style={{
            width: 80,
            height: 80,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
          }}
        />
      }
      contentSubText=""
      contentText={userStore.user?.companyName || userStore?.user?.companiesAsMentor?.[0]?.name || ''}
      title={t<string>('profile.companyProfile')}
    />
  )
}

export default observer(CompanyProfile)
