import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { DateRangePicker, RangeKeyDict } from 'react-date-range'
import { useTranslation } from 'react-i18next'

import { ICalendarDateRange } from 'hooks/reports/useReportFilters'

import { useStore } from 'stores'

import { IProgramList } from 'types/Program'

import { DEFAULT_PRIMARY_COLOR } from 'utils/const'
import { defaultReportDate } from 'utils/dateTime'

import ContextMenu from 'components/ContextMenu'
import CustomButton from 'components/CustomComponents/CustomButton'

import styles from './styles.module.scss'

interface IProps {
  dateRange: ICalendarDateRange
  setDateRange: Dispatch<SetStateAction<ICalendarDateRange>>
  selectDateMenuVisible: boolean
  setSelectDateMenuVisible: Dispatch<SetStateAction<boolean>>
  currentProgram: IProgramList | undefined
}

const SelectDateMenu: FC<IProps> = ({
  dateRange,
  setDateRange,
  selectDateMenuVisible,
  setSelectDateMenuVisible,
  currentProgram,
}) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const [dateRangeState, setDateRangeState] = useState([defaultReportDate])

  useEffect(() => {
    setDateRangeState([dateRange])
  }, [dateRange])

  const handleSelect = useCallback((ranges: RangeKeyDict) => {
    //@ts-ignore
    setDateRangeState([ranges.selection])
  }, [])

  const ok = useCallback(() => {
    if (dateRangeState[0]) {
      const start = moment(dateRangeState[0].startDate).local()
      const end = moment(dateRangeState[0].endDate).local()

      const res = {
        startDate: new Date(`${start.format('YYYY-MM-DD')}T00:00:00Z`),
        endDate: new Date(`${end.format('YYYY-MM-DD')}T00:00:00Z`),
        key: dateRangeState[0].key,
      }

      setDateRange(res as ICalendarDateRange)
      setSelectDateMenuVisible(false)
    }
  }, [dateRangeState])

  const cancel = useCallback(() => {
    setDateRangeState([dateRange || defaultReportDate])
    setSelectDateMenuVisible(false)
  }, [dateRange])

  const disableDate = useCallback(
    (date: Date) => {
      if (!currentProgram) return moment(date).isAfter(moment())

      return (
        moment(date).isBefore(moment(currentProgram.startDate)) || moment(date).isAfter(moment(currentProgram.endDate))
      )
    },
    [currentProgram]
  )

  useEffect(() => {
    if (!selectDateMenuVisible) {
      cancel()
    }
  }, [selectDateMenuVisible])

  return (
    <ContextMenu>
      <div className={styles.container}>
        <div className={styles.title}>{t<string>('dailyReport.selectDate')}</div>
        <div dir="ltr">
          <DateRangePicker
            ranges={dateRangeState}
            onChange={handleSelect}
            showMonthAndYearPickers={false}
            disabledDay={disableDate}
            rangeColors={[userStore.brandData?.primaryColor || DEFAULT_PRIMARY_COLOR]}
          />
        </div>
        <div className={styles.btns}>
          <CustomButton type="link" onClick={cancel}>
            {t<string>('dashboard.cancel')}
          </CustomButton>
          <CustomButton type="link" onClick={ok}>
            {t<string>('dashboard.ok')}
          </CustomButton>
        </div>
      </div>
    </ContextMenu>
  )
}

export default observer(SelectDateMenu)
