import { observer } from 'mobx-react-lite'
import { Dispatch, SetStateAction, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import { useStore } from 'stores'

import { someError } from 'utils/message'

import { planningToolsType } from 'types/Template'

import ToolSwitch from './ToolSwitch'

interface IDayToolProps {
  item: planningToolsType
  toolIndex: number
  isUnbound?: boolean
  dayIndex: number
  dayId?: number
  onDelete: (onAfterDelete?: () => void) => void
  setCurrentDraggingTool?: Dispatch<SetStateAction<planningToolsType | undefined>>
  currentDraggingTool?: planningToolsType | undefined
  isNewsMode?: boolean
  preventDrag?: boolean
  inSearch?: boolean
}

const DayTool = ({
  item,
  toolIndex,
  isUnbound,
  dayIndex,
  dayId,
  onDelete,
  currentDraggingTool,
  setCurrentDraggingTool,
  isNewsMode,
  preventDrag,
  inSearch,
}: IDayToolProps) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const currentDay = templateEntity.currentDay

  const ref = useRef<HTMLDivElement>(null)

  const canDragItem =
    !preventDrag &&
    !isNewsMode &&
    !isUnbound &&
    !templateStore.isToolEditing &&
    !!currentDay &&
    currentDay.dayItems.length > 1

  const onDragItemStart = (item: planningToolsType) => {
    if (!setCurrentDraggingTool) return
    setCurrentDraggingTool(item)
  }

  const onDropItem = async () => {
    try {
      if (!currentDraggingTool || currentDraggingTool.id === item.id) return

      await templateStore.swapToolsPosition(currentDraggingTool, item)
    } catch (e) {
      console.error(e)
      someError()
    }
  }

  const [, drop] = useDrop({
    accept: 'item',
    drop: onDropItem,
  })

  const [{ isDraggingItem }, drag] = useDrag(
    () => ({
      type: 'item',
      collect: monitor => ({
        isDraggingItem: monitor.isDragging(),
      }),
      canDrag: () => canDragItem,
    }),
    [currentDraggingTool, toolIndex, canDragItem]
  )

  drag(drop(ref))

  if (!currentDay && !isUnbound) return <></>

  return (
    <div
      onDragStart={e => onDragItemStart(item)}
      style={{ position: 'relative' }}
      ref={ref}
      data-drag-container="dragContainer"
    >
      <ToolSwitch
        tool={item}
        toolIndex={toolIndex}
        dayIndex={dayIndex}
        isDraggingItem={isDraggingItem}
        isUnbound={isUnbound}
        dayId={dayId}
        onDelete={onDelete}
        isNewsMode={isNewsMode}
        inSearch={inSearch}
      />
    </div>
  )
}

export default observer(DayTool)
