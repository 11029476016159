import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'stores'

import { ButtonType } from 'types/Report'

import CustomTable from 'components/CustomComponents/CustomTable'
import TableContainerWrapper from '../TableContainerWrapper'

interface IProps {
  type: ButtonType
  clicked: number
}

const CommonTypeTable: FC<IProps> = ({ type, clicked }) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const columns = useMemo(() => {
    return [
      {
        dataIndex: 'type',
        render: () => <div />,
        width: '20%',
      },
      {
        dataIndex: 'type',
        render: () => <div style={{ fontWeight: 700 }}>{t<string>('planButton.btn')}</div>,
        width: '20%',
      },
      {
        dataIndex: 'type',
        key: 'type',
        render: (type: ButtonType) => {
          switch (type) {
            case ButtonType.ProgramWallPostLinks:
              return t('templateCreation.link')
            case ButtonType.ProgramWallPostStoredFiles:
              return t('templateCreation.file')
            case ButtonType.ProgramWallPostSummaries:
              return t('templateCreation.text')
            default:
              return ''
          }
        },
        width: '30%',
      },
      {
        dataIndex: 'clicked',
        key: 'clicked',
        render: (clicked: number) => <div>{t<string>('planButton.clickedTimes', { num: clicked })}</div>,
        width: '20%',
      },
      {
        dataIndex: 'type',
        render: () => <div></div>,
        width: '10%',
      },
    ]
  }, [userStore.language])

  if (type === ButtonType.NoContent) return <></>

  return (
    <TableContainerWrapper>
      <CustomTable
        rowKey="id"
        variant="transparent"
        dataSource={[{ type, clicked }]}
        columns={columns}
        pagination={false}
        showHeader={false}
      />
    </TableContainerWrapper>
  )
}

export default CommonTypeTable
