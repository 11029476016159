import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import CreateProgramBtn from 'components/CreateProgramBtn'
import SearchBar from 'components/SearchBar'

import styles from './styles.module.scss'

interface IProps {
  searchValue: string
  setSearchValue: (search: string) => void
  isLoading: boolean
}

const ListHeader: FC<IProps> = ({ searchValue, setSearchValue, isLoading }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.controlsTop} data-controls-top="controlsTop">
      <div className={styles.titleWithSearch}>
        <div className={styles.programsListTitle}>{t<string>('programList.myPrograms')}</div>
        <SearchBar
          value={searchValue}
          placeholder={t<string>('programList.searchProgram')}
          onChangeCustom={setSearchValue}
          data-program-list-search="program-list-search"
          id="program-list-search"
          className={styles.search}
        />
      </div>
      <CreateProgramBtn isLoading={isLoading} />
    </div>
  )
}

export default ListHeader
