import { Rule } from 'antd/lib/form'
import { t } from 'i18next'

import { urlRe } from 'utils/const'

export const urlValidator = (rule: Rule, value: string, callback: (error?: string) => void) => {
  const trimmedValue = value?.trim()

  if (!trimmedValue) {
    callback()
  } else if (!urlRe.test(trimmedValue)) {
    callback(t<string>('templateCreation.invalidUrl'))
  } else {
    callback()
  }
}
