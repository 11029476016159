import { useCallback, useEffect } from 'react'

import { useStore } from 'stores'
import getSubdomain from 'utils/getSubdomain'

interface IProps {
  syncOnlyManually?: boolean
}

const useBrandSync = ({ syncOnlyManually }: IProps) => {
  const { userStore } = useStore()

  const syncBrandBySubdomain = useCallback(() => {
    const subDomain = getSubdomain()
    userStore.getUserBrandInfo(subDomain, true)
  }, [])

  useEffect(() => {
    if (syncOnlyManually) return

    syncBrandBySubdomain()
  }, [syncBrandBySubdomain])

  return { syncBrandBySubdomain }
}

export default useBrandSync
