import { useQuery } from '@tanstack/react-query'

import programsApi from 'api/programs.api'

import { useStore } from 'stores'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

const useAllUserProgramsQuery = (traineeId?: number | null) => {
  const { userStore } = useStore()

  const {
    data: programs,
    isLoading,
    isError,
  } = useQuery({
    initialData: [],
    keepPreviousData: true,
    enabled: !!traineeId,
    queryKey: [QueryKeysEnum.ALL_USER_PROGRAMS, traineeId],
    queryFn: async () => {
      if (!traineeId || !userStore.user?.id) throw new Error()

      const programs = await programsApi.getAllTraineePrograms(traineeId)

      return programs.filter(program => program.mentors.find(mentor => mentor.id === userStore.user?.id))
    },
  })

  return {
    programs,
    isLoading,
    isError,
  }
}

export default useAllUserProgramsQuery
