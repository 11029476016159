import { DefaultOptionType } from 'antd/lib/select'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import CustomSelect, { ICustomSelectProps } from 'components/CustomComponents/CustomSelect'

import styles from './styles.module.scss'

interface IProps extends ICustomSelectProps {
  value?: number
  onChange?: (value: any, option: DefaultOptionType | DefaultOptionType[]) => void
}

const LinkToBrandSelector = ({ onChange, value, ...rest }: IProps) => {
  const { userStore } = useStore()
  const { t } = useTranslation()

  const options = useMemo(() => {
    if (!userStore.userBrands?.length) {
      return []
    }

    const res = [
      {
        value: 0,
        label: t('templateCreation.doNothing'),
      },
    ]

    res.push(
      ...userStore.userBrands.map(company => {
        return { label: company.name, value: company.id }
      })
    )

    return res
  }, [userStore.userBrands, userStore.language])

  if (!options.length) return <></>

  return (
    <CustomSelect
      {...rest}
      value={!value ? 0 : value}
      onChange={onChange}
      options={options}
      className={styles.selector}
    />
  )
}

export default observer(LinkToBrandSelector)
