import CustomSelect from 'components/CustomComponents/CustomSelect'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { LastActiveFilterEnum } from 'types/Program'

import styles from './styles.module.scss'

interface IProps {
  value: LastActiveFilterEnum
  onChange: (value: LastActiveFilterEnum) => void
}

const LastActiveTraineeFilter: FC<IProps> = ({ onChange, value }) => {
  const { t } = useTranslation()

  const options = [
    {
      value: LastActiveFilterEnum.AllActive,
      label: t('programCreation.All'),
    },
    {
      value: LastActiveFilterEnum.OnlyFor30Days,
      label: t('programDetails.activeTraineesFilter'),
    },
    {
      value: LastActiveFilterEnum.Registered,
      label: t('programDetails.registeredTraineesFilter'),
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.label}>{t('programDetails.filterTrainees')}:</div>
      <CustomSelect value={value} onChange={onChange} options={options} className={styles.select} />
    </div>
  )
}

export default LastActiveTraineeFilter
