import type { ColumnsType } from 'antd/es/table'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import { FC, useMemo, useState } from 'react'

import { useStore } from 'stores'

import dateTime from 'utils/dateTime'

import { ITraineeForDashboard } from 'types/User'

import CustomModal from 'components/CustomComponents/CustomModal'
import UserAvatar from 'components/UserAvatar'

import CustomTable from 'components/CustomComponents/CustomTable'
import styles from './styles.module.scss'

interface IProps {
  leastPerformersTrainees: ITraineeForDashboard[]
  loading: boolean
}

const LeastPerformers: FC<IProps> = ({ leastPerformersTrainees, loading }) => {
  const { userStore } = useStore()

  const [isSeeAllVisible, setIsSeeAllVisible] = useState(false)

  const columns = useMemo(
    () => [
      {
        title: t<string>('programCreation.nameTable'),
        dataIndex: 'firstName',
        key: 'firstName',
        render: (text: string, row: ITraineeForDashboard) => (
          <div className={styles.nameCol}>
            <UserAvatar avatarId={row.avatarId} />
            <div>{row.firstName ? row.firstName + ' ' + row.lastName : row.phoneNumber}</div>
          </div>
        ),
      },
      {
        title: t<string>('dashboard.lastActive'),
        dataIndex: 'lastActiveTime',
        key: 'lastActiveTime',
        render: (text: string) =>
          text && !dateTime.isNever(text) ? dateTime.getHebrewDate(text) : t<string>('mentorDashboard.notRegistered'),
      },
    ],
    [userStore.language]
  ) as ColumnsType<ITraineeForDashboard>

  return (
    <div className={styles.leastPerformers}>
      <CustomModal
        open={isSeeAllVisible}
        title={t<string>('mentorDashboard.leastPerformers')}
        onCancel={() => setIsSeeAllVisible(false)}
        footer={null}
      >
        <CustomTable
          variant="clear"
          rowKey="id"
          columns={columns}
          dataSource={leastPerformersTrainees}
          pagination={false}
        />
      </CustomModal>

      <div className={styles.blockTitle}>
        <div>
          <div className={styles.blockTitleText}>{t<string>('mentorDashboard.leastPerformers')}</div>
        </div>
        <div className={styles.seeAll} onClick={() => setIsSeeAllVisible(true)}>
          {t<string>('mentorDashboard.seeAll')}
        </div>
      </div>
      <div className={styles.blockTitleSubText}>{t<string>('mentorDashboard.leastPerformersSubText')}</div>
      <div className={styles.table}>
        <CustomTable
          variant="clear"
          loading={loading}
          columns={columns}
          dataSource={leastPerformersTrainees.slice(0, 10)}
          rowKey="id"
          pagination={false}
        />
      </div>
    </div>
  )
}

export default observer(LeastPerformers)
