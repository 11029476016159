import useMessagePaginationQuery from 'hooks/tanstack/queries/useMessagePaginationQuery'
import CustomTable from 'components/CustomComponents/CustomTable'
import ButtonsItem from './ButtonsItem'
import moment from 'moment'
import SearchBar from 'components/SearchBar'
import useDebounce from 'hooks/useDebounce'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import Pagination from 'components/Pagination'
import CustomModal from 'components/CustomComponents/CustomModal'
import useSendMessage from '../../../../hooks/useSendMessage'
import { IMessageTemplate } from 'types/MessageTemplate'
export enum DeliveryStatusEnum {
  Not_Send = 0,
  Sent = 1,
  Read = 2,
}

const SendMessage = () => {
  const {
    search,
    pagination,
    isModalOpen,
    LoadingButton,
    handleSearchChange,
    handleCancel,
    handleOk,
    handleButtonClick,
    setPagination,
  } = useSendMessage()
  const debouncedSearch = useDebounce(search, 300)
  const { data, isLoading, isFetched, done } = useMessagePaginationQuery(
    pagination.pageNumber,
    pagination.pageLength,
    debouncedSearch
  )

  const { t } = useTranslation()

  const columns = [
    {
      title: t('sendMessage.name'),
      dataIndex: 'firstName',
      key: 'firstName',
      render: (items: string) => <p>{items}</p>,
    },
    {
      title: t('sendMessage.lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      render: (items: string) => <p>{items}</p>,
    },
    {
      title: t('sendMessage.id'),
      dataIndex: 'id',
      key: 'id',
      render: (items: string) => <p>{items}</p>,
    },
    {
      title: t('sendMessage.dayOfCreating'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (items: string) => <p>{moment(items).format('DD/MM/YYYY')}</p>,
    },
    {
      title: t('sendMessage.lastActive'),
      dataIndex: 'lastActive',
      key: 'lastActive',
      render: (items: string) => <p>{moment(items).format('DD/MM/YYYY')}</p>,
    },
    {
      title: t('sendMessage.buttons'),
      dataIndex: 'messageTemplates',
      key: 'messageTemplates',
      render: (button: IMessageTemplate[], record: any) => {
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            {button.map(el => (
              <ButtonsItem
                name={el.name}
                id={el.id}
                userId={record.id}
                deliveryStatus={el.deliveryStatus}
                handleButtonClick={handleButtonClick}
              />
            ))}
          </div>
        )
      },
    },
  ]

  return (
    <div className={styles.container}>
      <CustomModal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <h2 className="modal-title">{t('sendMessage.messageDelete')}</h2>
      </CustomModal>
      <div className={styles.headerContainer}>
        <p className={styles.title}>{t('dashboard.trainees')}</p>
        <SearchBar
          value={search}
          placeholder={t<string>('dashboard.searchUser')}
          onChangeCustom={handleSearchChange}
          data-search-user="search-user"
          id="search-user"
          style={{ maxWidth: '300px' }}
        />
      </div>
      <CustomTable
        rowKey="id"
        columns={columns}
        dataSource={data?.items}
        loading={done || LoadingButton || !isFetched}
        pagination={false}
        locale={{ emptyText: t('sendMessage.noData') }}
      />
      <Pagination
        currentPage={pagination.pageNumber}
        onPageChange={pageNumber => setPagination(prev => ({ ...prev, pageNumber }))}
        onSizeChange={pageLength => setPagination(prev => ({ ...prev, pageLength }))}
        size={pagination.pageLength}
        totalItems={data?.totalItems || 0}
        loading={isLoading || LoadingButton}
      />
    </div>
  )
}

export default SendMessage
