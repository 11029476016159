import { observer } from 'mobx-react-lite'
import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import PlanBtnModal from './PlanBtnModal'

import CustomButton from 'components/CustomComponents/CustomButton'

import styles from '../styles.module.scss'

interface IProps {
  showUnboundContentModal: () => void
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

const PlanBtn: FC<IProps> = ({ showUnboundContentModal, setVisible, visible }) => {
  const { planBtnStore } = useStore()

  const { t } = useTranslation()

  return (
    <>
      <CustomButton loading={planBtnStore.loading} onClick={() => setVisible(true)} className={styles.btn}>
        {t<string>('planButton.btn')}
      </CustomButton>
      <PlanBtnModal visible={visible} setVisible={setVisible} showUnboundContentModal={showUnboundContentModal} />
    </>
  )
}

export default observer(PlanBtn)
