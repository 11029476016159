import { FontSizesEnum, ISettingProfile, LanguagesEnum, ThemeEnum } from 'types/InitialSettings'
import { IProgramFilter } from 'types/Program'

export const imageMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'jpeg', 'jpg', 'png']
export const maxAvatarSize = 1024 * 1024 * 10 // 10Mb

export const videoMimeTypes = [
  'video/mpeg',
  'video/mp2t',
  'video/webm',
  'video/mp4',
  'video/quicktime',
  'mpeg',
  'mp2t',
  'webm',
  'mp4',
  'quicktime',
]

export const audioMimeTypes = ['audio/mpeg', 'audio/wav', 'audio/aac', 'audio/vnd.dlna.adts', 'mp3', 'wav', 'aac']

export const urlRe =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

export const webhookRegexp = /^https?:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?\/?$/g

export const spaceRe = /\D*(\d{3})\D*(\d{4})\D*(\d{4})\D*/
export const deleteHTMLTags = /<(?:.|\n)*?>/gm

export const deleteURLRe = /(https?:\/\/[^\s]+)/g
export const onlyNumber = /'^[0-9]*$'/
export const emailRe = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

export const TEMPLATES_ON_PAGE = 10
export const PROGRAMS_ON_PAGE = 10

export const DEFAULT_PRIMARY_COLOR = '#0079cb'
export const DEFAULT_SECONDARY_COLOR = '#ecf0f3'
export const DEFAULT_APP_SPLASH_SCREEN_COLOR = '#fff'

export const initialProfileSwitchers = {
  focusMod: false,
  pushNotifications: true,
  automaticSavingAnswers: true,
}

export const initialProfileValues: Omit<ISettingProfile, 'id' | 'name'> = {
  ...initialProfileSwitchers,
  languageCode: LanguagesEnum.en,
  textSizeSetting: FontSizesEnum.DEFAULT,
  themeSetting: ThemeEnum.light,
}

export const initialDayWallPostQuery = 'initialDay'
export const LINK_TEXT_DELIMITER = '~'
export const TEXT_SPACES_REPLACER = '^'

export const TERMS_OF_SERVICE_PATH = '/latest/terms-of-service.pdf'
export const TASK_CANCELLED_MESSAGE = 'Task has been cancelled'

export const defaultProgramFilter: IProgramFilter = {
  hasCalendar: false,
  hasFilter: true,
  hasSearch: true,
}
