import { useCallback, useEffect, useMemo, useState } from 'react'

import { IDailyReport, IDailyReportUser } from 'types/Program'

interface IProps {
  dailyReportsSource: IDailyReport[]
  dailyReports: IDailyReport[]
  isForAllPrograms: boolean
  loading: boolean
}

const generateAllProgramId = (report: IDailyReport) => `${report.trainingProgramId}-${report.day - 1}`
const generateProgramId = (report: IDailyReport) => `${report.day - 1}`

const generateReportId = (report: IDailyReport, isForAllPrograms: boolean) =>
  isForAllPrograms ? generateAllProgramId(report) : generateProgramId(report)

const useExpandedRows = ({ dailyReportsSource, dailyReports, isForAllPrograms, loading }: IProps) => {
  const [expandedRowDays, setExpandedRowDays] = useState<string[]>([])
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

  const isAllRowsExpanded = useMemo(() => {
    if (!dailyReports.length || loading) return false

    for (const report of dailyReports) {
      if (!expandedRowDays.includes(generateReportId(report, isForAllPrograms))) {
        return false
      }

      for (const userReport of report.dailyReportModelForUsers) {
        if (!expandedRowKeys.includes(userReport.id)) {
          return false
        }
      }
    }

    return true
  }, [expandedRowKeys, expandedRowDays, dailyReports, isForAllPrograms, loading])

  const handleExpandQuestions = useCallback((item: IDailyReportUser) => {
    setExpandedRowKeys(prev => [...prev, item.id])
  }, [])

  const handleCollapseQuestions = useCallback((item: IDailyReportUser) => {
    setExpandedRowKeys(prev => prev.filter(key => key !== item.id))
  }, [])

  const handleExpandAll = useCallback(
    (dailyReports: IDailyReport[]) => {
      const keys = dailyReports.map(report => generateReportId(report, isForAllPrograms))

      setExpandedRowDays(keys)

      dailyReports.forEach(report => {
        report.dailyReportModelForUsers.forEach(userReport => {
          setExpandedRowKeys(prev => [...prev.filter(key => key !== userReport.id), userReport.id])
        })
      })
    },
    [isForAllPrograms]
  )

  const handleCollapseAll = useCallback(() => {
    setExpandedRowDays([])
    setExpandedRowKeys([])
  }, [])

  useEffect(() => {
    if (!dailyReportsSource.length) return

    handleExpandAll(dailyReportsSource)
  }, [dailyReportsSource, handleExpandAll])

  return {
    handleExpandQuestions,
    handleCollapseQuestions,
    handleExpandAll,
    handleCollapseAll,
    isAllRowsExpanded,
    expandedRowKeys,
    expandedRowDays,
    setExpandedRowDays,
  }
}

export default useExpandedRows
