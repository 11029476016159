import { ITagInterest } from 'api/DTOs/tags.dto'
import { BrandType } from './Brand'
import { AccessProfileEnum } from './Dashboard'
import { IDetails } from './Program'
import { ITraineeTag } from './Tags'

interface IApproval {
  email: string
  phoneNumber?: string
}

export interface IUserBase {
  id: number
  firstName: string
  lastName: string
  email: string
  avatarId: number
  avatarKey: string
  phoneNumber: string
  createdAt?: string
}

export interface ITraineeForDashboard extends IUserBase {
  approvals: IApproval[]
  isFailureInvite: boolean
  isHoldingInvite: boolean
  label: string | null
  lastActiveTime: string
  lastAnswer: string | null
}

export interface IUser extends IUserBase {
  language: string
  role: string
  token: string
  companyId: number
  companyName: string
  haveTrainerPermission: boolean
  companyAvatars: {
    id: number
    avatarKey: string
  }[]
  companiesAsMentor?: {
    id: number
    name: string
    customBrandAllowed: boolean
    companyBrand: IBrandInfo | null
  }[]
  companyAsAdmin?: {
    avatarId: number | null
    avatarKey: string | null
    companyBrand: IBrandInfo | null
    customBrandAllowed: boolean
    id: number | null
    name: string | null
  }
  isNew?: boolean
  isVerified?: boolean
  accessProfile: AccessProfileEnum
  companyAccessProfile: AccessProfileEnum
}

export interface ITraineeByPhoneNumber extends IUserBase {
  tags: ITraineeTag[]
}

export interface ITrainees extends IUserBase {
  answer: string
  key: number
  lastName: string
  lastAnswer: string | null
  isAnswerAlert: boolean
  isHoldingInvite: boolean
  label?: string
  isFailureInvite: boolean
  approvals: IApproval[]
  isDeleted: boolean
  userTags: ITraineeTag[]
}

export interface ITraineeWithProgranInfo extends ITrainees {
  trainingPrograms: IDetails[]
  fullName: string
}

export interface IShortTrainees extends ITrainees {
  userTags: []
}

export interface ITraineesTabsCount {
  all: number
  failedStatus: number
  pendingStatus: number
  rejectedStatus: number
  requireParentalApprovalStatus: number
  succeedStatus: number
}

export interface ITraineeWithInvite extends IUserBase {
  inviteDto: null | {
    inviteStatus: 'Pending' | 'Succeed' | 'Rejected' | 'Error' | 'Failed' | 'RequireParentalApproval'
  }
  approvals: {
    additionalInfo: string | null
    email: string
    id: number
    isApproved: boolean
    userId: number
    phoneNumber: string
  }[]
}

export interface IImportedTraineesCSV extends IUserBase {
  errorMessages: []
  parentPhoneNumber: string
}

export interface ITraineesServerModel {
  phoneNumber: string
  isInvite?: boolean
  firstName?: string
  lastName?: string
  applicationBrand: string
  approvals?: {
    email?: string
    phoneNumber?: string
  }[]
}

export interface IBrandInfo {
  slug: string
  name: string
  primaryColor: string
  secondaryColor: string
  appSplashScreenBackgroundColor: string
  webSubDomain: string
  messageBirdOrdinator: string
  webLoginLogoKey: string
  webBackgroundImgKey: string
  appSplashScreenImageKey: string
  appIconKey: string
  webLoginLogoId: number
  webBackgroundImgId: number
  appSplashScreenImageId: number
  appIconId: number
  companyId?: number
  hideChat: boolean
  initialWebhook: string | null
  isTrainerModeEnabled: boolean
  hasNoRegistration: boolean
  hasPublicProgramAccess: boolean
  brandType: BrandType
  tooltip: string
  alertsTitle?: string
  hasAlertsPage: boolean
  interestTags?: ITagInterest[]
}

export enum VariablesEnum {
  firstName = '{{first_name}}',
  lastName = '{{last_name}}',
  mentorName = '{{mentor_name}}',
  programName = '{{program_name}}',
  todayName = '{{today_name}}',
  phoneNumber = '{{mobile_number}}',
  title = '{{title}}',
}

export type VariablesType = [
  VariablesEnum.firstName,
  VariablesEnum.lastName,
  VariablesEnum.programName,
  VariablesEnum.mentorName,
  VariablesEnum.todayName,
  VariablesEnum.phoneNumber,
  VariablesEnum.title
]

export interface ITraineesCount {
  totalActiveNumber: number
  totalInActiveNumber: number
  activeForLastDay: number
  activeForLastWeek: number
  activeForLastMonth: number
}

export interface IUserInfo {
  id: number
  firstName: string
  lastName: string
  email: string
  avatarId: number
  avatarKey: string
  phoneNumber: string
  lastActiveTime: string
  isMentor: boolean
}
