import { UploadFile } from 'antd'
import { useMemo } from 'react'

import { useStore } from 'stores'

interface IProps {
  fileList: UploadFile<any>[]
}

const useCheckIfUploadingFiles = ({ fileList }: IProps) => {
  const { templateStore } = useStore()

  const fileListIds = useMemo(() => fileList.map(file => file.uid), [fileList])

  const someFilesUploadInProgress = useMemo(() => {
    return templateStore.uploadInProgressFilesId.some(id => fileListIds.includes(id))
  }, [templateStore.uploadInProgressFilesId, fileListIds])

  return { someFilesUploadInProgress }
}

export default useCheckIfUploadingFiles
