import { DownOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { FC, JSXElementConstructor, memo, ReactElement } from 'react'

import styles from './styles.module.scss'

interface IProps {
  text: string
  visible: boolean
  onVisibleChange: (status: boolean) => void
  Overlay: ReactElement<any, string | JSXElementConstructor<any>>
}

const FilterBtn: FC<IProps> = ({ text, onVisibleChange, Overlay, visible }) => {
  return (
    <Dropdown overlay={Overlay} open={visible} onOpenChange={onVisibleChange} trigger={['click']}>
      <div className={styles.btn}>
        <div>{text}</div>
        <DownOutlined />
      </div>
    </Dropdown>
  )
}

export default memo(FilterBtn)
