import { default as classNames, default as cn } from 'classnames'
import { t } from 'i18next'
import React, { useMemo } from 'react'

import { FollowUpActionsEnum } from 'types/Template'

import FollowUp from './FollowUp'

import CustomDeleteIcon from 'components/CustomComponents/CustomDeteleIcon'
import CustomInput from 'components/CustomComponents/CustomInput'
import ToolWatchMode from 'components/ToolWatchMode'

import styles from './styles.module.scss'

interface IProps {
  value?: string
  index?: number
  handleChange?: (value: string, index: number, newAction?: FollowUpActionsEnum, newActionField?: string) => void
  handleDelete?: (index: number) => void
  optionsLength?: number
  handleAddOption?: () => void
  isCheckBox?: boolean
  isEditing: boolean
  canValidate: boolean
  followUpAction?: FollowUpActionsEnum[]
  followUpActionField?: string[]
  isUnbound?: boolean
  dayId?: number
  toolId?: number
}

const OptionItem: React.FC<IProps> = ({
  value,
  handleChange,
  index,
  handleDelete,
  optionsLength,
  handleAddOption,
  isCheckBox,
  isEditing,
  canValidate,
  followUpAction,
  followUpActionField,
  isUnbound,
  dayId,
  toolId,
}) => {
  const isError = canValidate && !index && !value

  const currentAction = followUpAction && index !== undefined ? followUpAction?.[index] : null
  const currentActionField = followUpActionField && index !== undefined ? followUpActionField?.[index] : ''

  const showActions = !isCheckBox && currentAction !== null && followUpActionField && index !== undefined

  const scrollParam = useMemo(() => {
    const obj: any = {}

    if (isError) {
      obj['data-scroll'] = '1'
    }

    return obj
  }, [isError])

  const setFollowUpAction = (action: FollowUpActionsEnum, actionField = '') => {
    if (handleChange && index !== undefined) {
      handleChange(value || '', index, action, actionField)
    }
  }

  return (
    <div
      className={cn(styles.optionItem, {
        [styles.isWatchMode]: !isEditing,
      })}
      {...scrollParam}
    >
      <div
        className={cn(styles.selectButton, {
          [styles.checkBox]: isCheckBox,
          [styles.radio]: !isCheckBox,
          [styles.selectWatchingMode]: !isEditing,
        })}
      ></div>
      <ToolWatchMode
        isEditing={isEditing}
        value={value || ''}
        followUp={
          currentAction && currentActionField
            ? `${
                currentAction === FollowUpActionsEnum.content
                  ? t<string>('templateCreation.followUpContent')
                  : currentAction === FollowUpActionsEnum.Webhook
                  ? t<string>('templateCreation.callWebhook')
                  : currentAction === FollowUpActionsEnum.URL
                  ? t<string>('templateCreation.callUrl')
                  : ''
              } - ${currentActionField}`
            : ''
        }
      >
        <CustomInput
          className={classNames(styles.optionInput, {
            [styles.error]: isError,
          })}
          value={value}
          onChange={e => handleChange && index !== undefined && handleChange(e.target.value, index)}
          autoFocus={optionsLength ? index === optionsLength - 1 : undefined}
          onFocus={() => handleAddOption && handleAddOption()}
          placeholder={handleAddOption && t<string>('templateCreation.addOption')}
          disabled={!isEditing}
          data-choice-option-input="choice-option-input"
          id="choice-option-input"
        />
        {!!showActions && followUpAction && (
          <FollowUp
            currentAction={currentAction}
            currentActionField={currentActionField}
            setFollowUpAction={setFollowUpAction}
            canValidate={canValidate}
            isUnbound={isUnbound}
            dayId={dayId}
            toolId={toolId}
          />
        )}
      </ToolWatchMode>
      {handleDelete && index !== undefined && optionsLength && optionsLength > 1 && isEditing ? (
        <CustomDeleteIcon onClick={() => handleDelete(index)} style={{ marginBottom: 20 }} />
      ) : null}
    </div>
  )
}

export default OptionItem
