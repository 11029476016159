import { Button, Checkbox, Modal } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useMultipleInvite from 'hooks/invite/useMultipleInvite'

import { useStore } from 'stores'

import { IImportedTraineesCSV, ITraineeWithInvite } from 'types/User'

import isAlreadyInvitedTrainee from 'utils/isAlreadyInvitedTrainee'

import InviteTraineesTable, { ITraineesTable } from 'components/InviteTraineesTable'
import ProgressBar from 'components/ProgressBar'
import SettingsProfileSelector from 'components/SettingsProfileSelector'

import styles from './styles.module.scss'

interface IProps {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  programId: string | null
  importedTrainees: IImportedTraineesCSV[]
  onOk?: (trainees: ITraineesTable[]) => void
  alreadyInvitedTrainees: ITraineeWithInvite[]
  onAfterInvite?: () => void
}

const ImportedFromCsvModal: FC<IProps> = ({
  visible,
  setVisible,
  programId,
  importedTrainees,
  onOk,
  alreadyInvitedTrainees,
  onAfterInvite,
}) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const [trainees, setTrainees] = useState<ITraineesTable[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [settingsProfileId, setSettingsProfileId] = useState<number | null>(null)
  const [disableSms, setDisableSms] = useState(true)

  const { sendInvite, validationBeforSendingInvite, loading, progress, autoAcceptInvite, setAutoAcceptInvite } =
    useMultipleInvite({
      trainees,
      selectedRowKeys,
      programId: programId ? +programId : null,
      disableSms,
      onAfterInvite: () => {
        if (onAfterInvite) {
          onAfterInvite()
        }
        setSelectedRowKeys([])
        setVisible(false)
      },
      settingsProfileId,
    })

  const prepareToSendInviteOnProgramCreation = () => {
    if (!onOk) return

    const selectedTrainees = trainees.filter(trainee =>
      selectedRowKeys.includes(`${trainee.id} ${trainee.phoneNumber}`)
    )

    const valid = validationBeforSendingInvite(selectedTrainees)

    if (!valid) return

    onOk(selectedTrainees)
    setVisible(false)
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  useEffect(() => {
    if (!importedTrainees) return

    setTrainees(
      importedTrainees.map(trainee => ({
        id: trainee.id,
        firstName: trainee.firstName,
        lastName: trainee.lastName,
        email: trainee.email,
        phoneNumber: trainee.phoneNumber,
        avatarId: trainee.avatarId,
        approvalAvailable: trainee.id === 0,
        allowedApproval: !!trainee.parentPhoneNumber,
        approvalPhoneNumber: trainee.parentPhoneNumber,
      }))
    )
  }, [importedTrainees])

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (trainee: IImportedTraineesCSV) => {
      return {
        disabled: !!alreadyInvitedTrainees.find(
          invitedTrainee =>
            (invitedTrainee.phoneNumber === trainee.phoneNumber && isAlreadyInvitedTrainee(invitedTrainee)) ||
            trainee.phoneNumber === userStore.user?.phoneNumber
        ),
      }
    },
  }

  return (
    <Modal
      open={visible}
      onCancel={() => {
        setVisible(false)
        setSelectedRowKeys([])
      }}
      destroyOnClose={true}
      title={
        <div>
          <div>{t<string>('dashboard.sendInvite')}</div>
          <div className={styles.subtitle}>
            {trainees.length} {t<string>('dashboard.trainees')}
          </div>
        </div>
      }
      className="importedTraineesCVSModal"
      footer={
        <div className={styles.footer}>
          {programId && (
            <>
              <SettingsProfileSelector value={settingsProfileId} setValue={setSettingsProfileId} />
              <Checkbox
                checked={!disableSms}
                onChange={e => setDisableSms(!e.target.checked)}
                className={classNames('checkboxUI', styles.checkbox)}
              >
                {t<string>('programCreation.sendSms')}
              </Checkbox>
              <Checkbox
                checked={autoAcceptInvite}
                onChange={e => setAutoAcceptInvite(e.target.checked)}
                className={styles.checkbox}
              >
                {t('programCreation.autoAcceptInvite')}
              </Checkbox>
            </>
          )}
          {progress !== 0 ? (
            <ProgressBar percent={progress} />
          ) : (
            <Button
              onClick={onOk ? prepareToSendInviteOnProgramCreation : sendInvite}
              disabled={!selectedRowKeys.length}
              data-send-invite-btn="send-invite-btn"
              id="send-invite-btn"
              className={styles.sendBtn}
              loading={loading}
            >
              {`${onOk ? t<string>('dashboard.invite') : t<string>('dashboard.sendInvite')} (${
                selectedRowKeys.length
              })`}
            </Button>
          )}
        </div>
      }
    >
      <div className={styles.tableContainer}>
        <InviteTraineesTable
          alreadyInvitedTrainees={alreadyInvitedTrainees}
          trainees={trainees}
          rowSelection={rowSelection}
          setSelectedTrainees={setTrainees}
        />
      </div>
    </Modal>
  )
}

export default observer(ImportedFromCsvModal)
