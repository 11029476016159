import React, { FC } from 'react'

import ContentId from 'components/ContentId'
import CustomDeleteIcon from 'components/CustomComponents/CustomDeteleIcon'

import styles from './styles.module.scss'

interface IProps {
  isEditing: boolean
  videoLink: string
  entityId: number
  handleDeleteVideoLink: () => void
}

const VideoLinkItem: FC<IProps> = ({ isEditing, videoLink, entityId, handleDeleteVideoLink }) => {
  const getIFrameLink = (videoLink: string) => {
    if (videoLink.includes('vimeo.com/')) {
      return videoLink.replace('vimeo.com/', 'player.vimeo.com/video/')
    } else if (videoLink.includes('youtube.com')) {
      return videoLink.replace('youtube.com/watch?v=', 'youtube.com/embed/').replace('&', '?')
    } else if (videoLink.includes('youtu.be')) {
      return videoLink.replace('youtu.be/', 'youtube.com/embed/').replace('&', '?')
    }
  }

  return (
    <div className={styles.videoLinkContainer}>
      <ContentId entityId={entityId} />
      <div className={styles.videoContainer}>
        <iframe
          title="iframe"
          width="320px"
          height="180px"
          src={getIFrameLink(videoLink)}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          data-iframe="iframe"
        />
        {isEditing && <CustomDeleteIcon onClick={handleDeleteVideoLink} />}
      </div>
    </div>
  )
}

export default VideoLinkItem
