import stores from 'stores'

import {
  FollowUpActionsEnum,
  FollowUpPostType,
  IAvailableFollowUp,
  IDayData,
  IMCQAnswerOption,
  IQuestionnaireMultipleChoice,
  QuestionTypeEnum,
  planingToolTypesEnum,
} from 'types/Template'

export class QuestionnaireFollowUpActionsParser {
  constructor() {
    //@ts-ignore
    if (QuestionnaireFollowUpActionsParser.instance) {
      //@ts-ignore
      return QuestionnaireFollowUpActionsParser.instance
    }

    //@ts-ignore
    QuestionnaireFollowUpActionsParser.instance = this
  }

  findAvailableContentToFollowUpById(id: number, isWallPost: boolean, withBound: boolean) {
    const placeToSearch = isWallPost
      ? stores.wallPostStore.followUpStore.availableContentForFollowUp
      : stores.templateStore.followUpStore.availableContentForFollowUp

    let additionalPlaceToSearch: IAvailableFollowUp[] = []

    if (withBound) {
      additionalPlaceToSearch = isWallPost
        ? stores.wallPostStore.followUpStore.availableContentForFollowUpBound
        : stores.templateStore.followUpStore.availableContentForFollowUpBound
    }

    const res = [...placeToSearch, ...additionalPlaceToSearch].find(item => item.contentId === id)

    return res
  }

  findActiveContentToFollowUpById(days: IDayData[]) {
    let res: number[] = []

    days.forEach(day =>
      day.dayItems.forEach(item => {
        if (item.type === planingToolTypesEnum.QUESTIONNAIRE) {
          item.questionsData.forEach(question => {
            if (question.type === QuestionTypeEnum.MULTIPLE_CHOICE) {
              ;(question as IQuestionnaireMultipleChoice).followUpActionField.forEach(id => res.push(+id))
            }
          })
        }
      })
    )

    return res
  }

  getAvailableContentToFollowUp(targetList: IDayData[]) {
    let res: IAvailableFollowUp[] = []

    targetList.forEach(day => {
      day.dayItems.forEach(item => {
        item.entityIds.forEach(entityId => {
          if (day.id) {
            res.push({ contentId: entityId, postType: this.transformToolTypeToPostType(item.type), postId: day.id })
          }
        })
        if (item.type === planingToolTypesEnum.VIDEO) {
          item.videoLinksEntityIds.forEach(linkEntityId => {
            if (day.id) {
              res.push({
                contentId: linkEntityId,
                postType: this.transformToolTypeToPostType(item.type),
                postId: day.id,
              })
            }
          })
        }
      })
    })

    return res
  }

  transformToolTypeToPostType(contentType: planingToolTypesEnum) {
    let type

    switch (contentType) {
      case planingToolTypesEnum.AUDIO:
      case planingToolTypesEnum.VIDEO:
      case planingToolTypesEnum.FILE:
      case planingToolTypesEnum.IMAGE:
        type = FollowUpPostType.File
        break

      case planingToolTypesEnum.LINK:
        type = FollowUpPostType.Link
        break
      case planingToolTypesEnum.QUESTIONNAIRE:
        type = FollowUpPostType.Questionnaire
        break
      case planingToolTypesEnum.SUMMARY:
        type = FollowUpPostType.Summary
        break
      case planingToolTypesEnum.SCENARIO:
        type = FollowUpPostType.Scenario
        break
      default:
        type = FollowUpPostType.None
        break
    }

    return type
  }

  fromServerToClient(asnwers: IMCQAnswerOption[]) {
    const followUpAction: FollowUpActionsEnum[] = []
    const followUpActionField: string[] = []

    asnwers.forEach(answer => {
      if (answer.url) {
        followUpAction.push(FollowUpActionsEnum.URL)
        followUpActionField.push(answer.url)
      } else if (answer.webHook) {
        followUpAction.push(FollowUpActionsEnum.Webhook)
        followUpActionField.push(answer.webHook)
      } else if (answer.postId) {
        followUpAction.push(FollowUpActionsEnum.content)
        followUpActionField.push(answer.postId.toString())
      } else {
        followUpAction.push(FollowUpActionsEnum.doNothing)
        followUpActionField.push('')
      }
    })

    return {
      followUpAction,
      followUpActionField,
    }
  }

  fromClientToServer(
    answerVariants: {
      id: string
      answerText: string
      questionId: number
    }[],
    question: IQuestionnaireMultipleChoice,
    isWallPosts: boolean,
    withBound: boolean
  ) {
    if (question.followUpAction.length !== question.followUpActionField.length) {
      console.error(
        'followUpAction.length !== followUpActionField.length in QuestionnaireFollowUpActionsParser fromClientToServer -> need some debug'
      )
    }

    return answerVariants.map((variant, idx) => {
      const actionType = question.followUpAction[idx]
      const actionField = question.followUpActionField[idx]

      if (!actionField || !actionType) {
        return variant
      }

      if (actionType === FollowUpActionsEnum.URL) {
        return { ...variant, url: actionField }
      } else if (actionType === FollowUpActionsEnum.Webhook) {
        return { ...variant, webHook: actionField }
      } else if (actionType === FollowUpActionsEnum.content) {
        const postId = actionField

        if (isNaN(+postId)) {
          console.error('postId is NaN in QuestionnaireFollowUpActionsParser fromClientToServer')
          return variant
        }

        let res

        if (isWallPosts) {
          res = this.findAvailableContentToFollowUpById(+postId, true, withBound)
        } else {
          res = this.findAvailableContentToFollowUpById(+postId, false, withBound)
        }

        if (!res) return variant

        const { contentId, postType } = res

        return { ...variant, postId: contentId, postType }
      }

      return variant
    })
  }
}
