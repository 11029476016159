import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import fileApi from 'api/file.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

import message from 'utils/message'

const useFileInfoQuery = (fileId?: number | null) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return useQuery({
    enabled: !!fileId,
    keepPreviousData: true,
    queryKey: [QueryKeysEnum.FILE_INFO, fileId],
    queryFn: () => fileApi.getFileInfo(fileId as number),
    onError: error => {
      message.error(t<string>('programCreation.smtWrong'))
      navigate('/dashboard')
    },
  })
}

export default useFileInfoQuery
