import { FC } from 'react'

import { ITraineesTable } from './index'

import useAvatar from 'hooks/useAvatar'

import styles from './styles.module.scss'

import DefaultAvatar from 'sources/images/profile.png'

interface IProps {
  item: ITraineesTable
}

const TraineeNameCell: FC<IProps> = ({ item }) => {
  const { avatarUrl } = useAvatar({ avatarId: item.avatarId })

  const name = item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : '-'

  return (
    <div className={styles.nameCol} data-user-table-name-cell="user-table-name-cell">
      <img src={item.avatarId ? avatarUrl : DefaultAvatar} className={styles.image} alt="trainee avatar" />
      <div>
        <div className={styles.text}>{name}</div>
        <div className={styles.subtext}>{item.email}</div>
      </div>
    </div>
  )
}

export default TraineeNameCell
