import { UploadFile } from 'antd'
import moment from 'moment'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { useStore } from 'stores'

import { IPlanningToolFileType } from 'types/Template'

interface IProps<T extends IFileTypeCommonFields> {
  toolInstance: T | null
  setIsAllDaySelected: Dispatch<SetStateAction<boolean>>
  setRevealedTime: Dispatch<SetStateAction<moment.Moment | undefined>>
  setFileList: Dispatch<SetStateAction<UploadFile<any>[]>>
  setToolData: Dispatch<SetStateAction<T | null>>
  setIsFirstDrop: Dispatch<SetStateAction<boolean>>
  setIsEditing: Dispatch<SetStateAction<boolean>>
  isUnbound?: boolean
}

interface IFileTypeCommonFields {
  fileList: UploadFile<any>[]
  fileIds: number[]
  isAllDaySelected: boolean
  revealedTime: string
}

const useInstallToolFileType = <T extends IFileTypeCommonFields>({
  toolInstance,
  setIsAllDaySelected,
  setRevealedTime,
  setFileList,
  setToolData,
  setIsEditing,
  setIsFirstDrop,
  isUnbound,
}: IProps<T>) => {
  const { templateStore } = useStore()

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)

    if (!toolInstance) return

    setToolData(toolInstance)

    if (
      !toolInstance?.fileList?.length &&
      !toolInstance?.fileIds?.length &&
      //@ts-ignore
      !(toolInstance as IPlanningToolFileType)?.videoLinks?.length
    ) {
      setIsFirstDrop(true)
      setIsEditing(true)
      templateStore.setIsToolEditing(true, isUnbound)
      return
    }

    setIsEditing(false)

    setFileList(toolInstance.fileList)

    if (toolInstance?.isAllDaySelected) {
      setIsAllDaySelected(toolInstance.isAllDaySelected)
      return
    }

    setRevealedTime(toolInstance?.revealedTime ? moment(toolInstance.revealedTime).local() : undefined)

    setIsAllDaySelected(false)
  }, [toolInstance])

  return {
    isMounted,
  }
}

export default useInstallToolFileType
