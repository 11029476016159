import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import { IDailyReportAnswer } from 'types/Program'
import { ButtonType } from 'types/Report'

import decryptVariablesInString from 'utils/decryptVariablesInString'

import CustomTable from 'components/CustomComponents/CustomTable'
import TableContainerWrapper from '../TableContainerWrapper'

interface IProps {
  questions: IDailyReportAnswer[]
  userName: string
  date: string
  programName: string
  clicked: number
  type: ButtonType
  phoneNumber: string
}

const PlanBtnQuestionTable: FC<IProps> = ({ questions, userName, date, programName, clicked, type, phoneNumber }) => {
  const { userStore } = useStore()

  const { t } = useTranslation()

  const [firstName, lastName] = userName.split(' ')

  const columns = useMemo(() => {
    const decryptQuestion = (text: string) =>
      decryptVariablesInString({
        text,
        date,
        language: userStore.language,
        mentorFirstName: userStore.user?.firstName || '',
        mentorLastName: userStore.user?.lastName || '',
        programName,
        userFirstName: firstName,
        userLastName: lastName,
        phoneNumber,
      })

    return [
      {
        key: 'questionId',
        render: () => <div />,
        width: '20%',
      },
      {
        key: 'questionId',
        render: () => <div style={{ fontWeight: 700 }}>{t<string>('planButton.usedBtn')}</div>,
        width: '20%',
      },
      {
        dataIndex: 'question',
        key: 'question',
        render: (text: string, row: IDailyReportAnswer) => <div>{decryptQuestion(text)}</div>,
        width: '30%',
      },
      {
        dataIndex: 'answer',
        key: 'answer',
        render: (value: string) => <div>{value || '-'}</div>,
        width: '20%',
      },
      {
        key: 'questionId',
        render: () => <div>{t<string>('planButton.clickedTimes', { num: clicked })}</div>,
        width: '10%',
      },
    ]
  }, [date, programName, userStore.language])

  if (type !== ButtonType.Questionnaires || !questions.length) return <></>

  return (
    <TableContainerWrapper>
      <CustomTable
        variant="transparent"
        rowKey="questionId"
        dataSource={questions}
        columns={columns}
        pagination={false}
        showHeader={false}
      />
    </TableContainerWrapper>
  )
}

export default PlanBtnQuestionTable
