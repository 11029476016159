import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import DailyGoalModal from './DailyGoalModal'

import CustomButton from 'components/CustomComponents/CustomButton'

import styles from '../styles.module.scss'

import { ReactComponent as GoalIcon } from 'sources/images/GoalIcon.svg'

const DailyGoal = () => {
  const { t } = useTranslation()

  const { goalsStore } = useStore()

  const [modalVisible, setModalVisible] = useState(false)

  return (
    <div className={styles.container}>
      <CustomButton
        onClick={() => setModalVisible(true)}
        className={styles.btn}
        style={{ padding: '0 10px', height: 42, maxHeight: 42 }}
        icon={<GoalIcon width={30} height={30} style={{ width: 30, height: 30 }} />}
      >
        {t<string>('templateCreation.goals')} ({goalsStore.templateGoals.length})
      </CustomButton>
      <DailyGoalModal visible={modalVisible} setVisible={setModalVisible} />
    </div>
  )
}

export default observer(DailyGoal)
