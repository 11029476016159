import { LanguagesType } from 'types/common'

export const sorting = (a: any, b: any, field: string, regex: RegExp) => {
  const targetFieldA = a?.[field]?.toLowerCase()
  const targetFieldB = b?.[field]?.toLowerCase()

  if (!targetFieldA || !targetFieldB) return 0

  const isAExact = regex.test(targetFieldA[0])
  const isBExact = regex.test(targetFieldB[0])

  if (isAExact && isBExact) {
    if (targetFieldA < targetFieldB) return -1
    if (targetFieldA > targetFieldB) return 1
    return 0
  }

  if (isAExact) return -1
  if (isBExact) return 1

  if (targetFieldA < targetFieldB) return -1
  if (targetFieldA > targetFieldB) return 1

  return 0
}

const sortingByLocale = (array: any[], language: LanguagesType, field: string) => {
  if (language === 'ru') {
    return array.sort((a, b) => sorting(a, b, field, /^[а-я]/i))
  }

  if (language === 'en') {
    return array.sort((a, b) => sorting(a, b, field, /^[a-z]/i))
  }

  return array.sort((a, b) => sorting(a, b, field, /[\u0590-\u05FF]/i))
}

export default sortingByLocale
