import axios from 'axios'

import stores from 'stores'

import LS, { LSKeys } from 'utils/LS'
import { applicationBrand } from 'utils/brand/brandDetector'
import { TASK_CANCELLED_MESSAGE } from 'utils/const'
import { axiosRefreshTokenCheck } from 'utils/refreshTokenCheck'

const applicationBrandHeader = 'X-Application-Brand'

export const api = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  withCredentials: true,
  headers: {
    [applicationBrandHeader]: applicationBrand,
  },
})

export const controller = new AbortController()

export const authApi = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  withCredentials: true,
  signal: controller.signal,
  headers: {
    [applicationBrandHeader]: applicationBrand,
  },
})

authApi.defaults.headers.common[applicationBrandHeader] = applicationBrand

authApi.interceptors.request.use(async config => {
  const token = await LS.get(LSKeys.TOKEN)
  const adminToken = await LS.get(LSKeys.ADMIN_TOKEN)

  config.headers = {
    Authorization: `Bearer ${adminToken ? adminToken : token}`,
    [applicationBrandHeader]: applicationBrand,
  }
  return config
})

authApi.interceptors.response.use(response => {
  if (response?.data?.Message?.includes(TASK_CANCELLED_MESSAGE)) {
    throw new Error(TASK_CANCELLED_MESSAGE)
  }
  return response
}, axiosRefreshTokenCheck)

export const authFallback = () => {
  LS.remove(LSKeys.USER)
  LS.remove(LSKeys.TOKEN)
  LS.remove(LSKeys.ADMIN_TOKEN)
  stores.userStore.clearCache()
}

export const config = {
  PROD: {
    S3: 'aimpact-prod-stored-files',
  },
  DEV: {
    S3: 'tracking-owl-dev-stored-files',
  },
  STAGE: {
    S3: 'aimpact-stage-stored-files',
  },
}
