import { useEffect } from 'react'

import { getOffsetTop, scrollToTop } from 'utils/helpers'

interface IProps {
  isMounted: boolean
  isFirstDrop?: boolean
  uniqueId?: string | string[]
}

const useToolScroll = ({ isFirstDrop, isMounted, uniqueId }: IProps) => {
  useEffect(() => {
    if (isMounted && isFirstDrop && uniqueId?.length) {
      const element = document.getElementById(typeof uniqueId === 'object' ? uniqueId?.[0] : uniqueId)

      if (element) {
        scrollToTop(getOffsetTop(element) - 20)
      }
    }
  }, [isMounted, uniqueId])
}

export default useToolScroll
