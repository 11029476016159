import { authApi } from 'config'

import { IDailyReport } from 'types/Program'
import { IDailyReportForDashboard } from 'types/Report'
import { PaginationResponse } from 'types/common'
import { IDailyReportForDashboardDto, IDailyReportV2Dto } from './DTOs/reports.dto'

import { getLang } from 'utils/helpers'

const reportsApi = {
  async getDailyReport(
    programId: string | number,
    dateTimeFrom: string,
    dateTimeTill: string
  ): Promise<IDailyReport[]> {
    const response = await authApi.get('Report/dailyReports/' + programId, {
      params: {
        trainingProgramId: programId,
        dateTimeFrom,
        dateTimeTill,
        pageLength: 9999,
        pageNumber: 1,
      },
    })

    return response.data
  },
  async getDailyReportMultiplePrograms(
    dateTimeFrom: string,
    dateTimeTill: string
  ): Promise<PaginationResponse<IDailyReport>> {
    const response = await authApi.get('Report/dailyReportsByTrainer', {
      params: {
        dateTimeFrom,
        dateTimeTill,
        pageNumber: 1,
        pageLength: 9999,
      },
    })

    const { data } = response

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].dailyReportModelForUsers.length; j++) {
        data[i].dailyReportModelForUsers[j].postCount = data[i].postCount
      }
    }

    return data
  },
  async getXlsxReport({
    trainingProgramId,
    dateTimeFrom,
    dateTimeTill,
    traineesId,
    questionsId,
  }: {
    trainingProgramId: number
    dateTimeFrom: string
    dateTimeTill: string
    traineesId: number[]
    questionsId: number[]
  }): Promise<Blob | void> {
    const response = await authApi.patch(
      `Report/getXlsxReport`,
      {
        trainingProgramId,
        dateTimeFrom,
        dateTimeTill,
        traineesId,
        questionsId,
      },
      {
        responseType: 'blob',
        params: {
          cultureName: getLang(),
        },
      }
    )

    return response.data
  },
  async getDailyReportForDashboard(params: IDailyReportForDashboardDto) {
    const response = await authApi.get<IDailyReportForDashboard[]>('Report/dailyReportsForDashboard', { params })

    return response.data
  },
  async getDailyReportsV2(params: IDailyReportV2Dto) {
    const response = await authApi.get('Report/dailyReportsV2', { params })

    return response.data
  },
}

export default reportsApi
