import { useMutation, useQueryClient } from '@tanstack/react-query'
import message from 'utils/message'
import messageTemplate from 'api/messageTemplates.api'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PostMessageTemplate } from 'types/MessageTemplate'
import { ApiError } from 'types/common'
const invalidName = 'Name is invalid or already exists'
const useMessageTemplateCreate = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return useMutation((dto: PostMessageTemplate) => messageTemplate.createMessageTemplate(dto), {
    onSuccess: () => {
      queryClient.invalidateQueries()
      message.success(t('messageTable.messageTemplateCreated'))
      navigate('/company/message')
    },
    onError: (error: ApiError) => {
      const errMessage = error?.response?.data?.Message

      message.error(t<string>(`messageTemplate.${errMessage}`))
    },
  })
}

export default useMessageTemplateCreate
