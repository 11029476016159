import { Avatar, Checkbox, Tag } from 'antd'
import { t } from 'i18next'
import _ from 'lodash'
import React, { Dispatch, SetStateAction, useState } from 'react'

import { IImportedTraineesCSV, ITraineesServerModel } from 'types/User'

import { applicationBrand } from 'utils/brand/brandDetector'

import SelectedTraineeMark from './SelectedTraineeMark'

import CustomButton from 'components/CustomComponents/CustomButton'
import InviteTraineesModal from 'components/InviteTraineeModals//InviteTraineesModal'
import ImportedFromCsvModal from 'components/InviteTraineeModals/ImportedFromCsvModal'
import ImportFromCvsModal from 'components/InviteTraineeModals/ImportFromCvsModal'
import { ITraineesTable } from 'components/InviteTraineesTable'
import SettingsProfileSelector from 'components/SettingsProfileSelector'

import styles from './styles.module.scss'

interface IProps {
  selectedTrainees: ITraineesTable[]
  setTrainees: Dispatch<SetStateAction<ITraineesServerModel[]>>
  setSelectedTrainees: Dispatch<SetStateAction<ITraineesTable[]>>
  disableSMS: boolean
  setDisableSMS: Dispatch<SetStateAction<boolean>>
  settingsProfileId: number | null
  setSettingsProfileId: Dispatch<SetStateAction<number | null>>
}

const TraineesTab: React.FC<IProps> = ({
  setTrainees,
  setSelectedTrainees,
  selectedTrainees,
  disableSMS,
  setDisableSMS,
  settingsProfileId,
  setSettingsProfileId,
}) => {
  const [inviteTraineesModalVisible, setInviteTraineesModalVisible] = useState(false)
  const [importTraineesModalVisible, setImportTraineesModalVisible] = useState(false)
  const [importedTraineesModalVisible, setImportedTraineesModalVisible] = useState(false)
  const [traineesPhonesToRemove, setTraineesPhonesToRemove] = useState<string[]>([])

  const [importedTrainees, setImportedTrainees] = useState<IImportedTraineesCSV[]>([])
  const [isEdit, setIsEdit] = useState(false)

  const onAfterImportTrainees = (trainees: IImportedTraineesCSV[]) => {
    setImportTraineesModalVisible(false)
    setImportedTrainees(trainees)
    setImportedTraineesModalVisible(true)
  }

  const onSendInvite = (selectedTrainees: ITraineesTable[], trainees: ITraineesServerModel[]) => {
    setSelectedTrainees(prev =>
      _.uniqBy(
        [...prev, ...selectedTrainees].filter(item => !traineesPhonesToRemove.includes(item.phoneNumber)),
        'phoneNumber'
      )
    )
    setTrainees(prev =>
      _.uniqBy([...prev, ...trainees], 'phoneNumber').filter(item => !traineesPhonesToRemove.includes(item.phoneNumber))
    )

    setTraineesPhonesToRemove([])
  }

  const onSelectTraineesFromCsv = (trainees: ITraineesTable[]) => {
    setSelectedTrainees(prev => _.uniqBy([...prev, ...trainees], 'phoneNumber'))

    const traineesPreparedForServer: ITraineesServerModel[] = trainees.map(trainee => {
      if (trainee.approvalPhoneNumber && trainee.allowedApproval) {
        return {
          phoneNumber: trainee.phoneNumber,
          applicationBrand,
          approvals: [
            {
              phoneNumber: trainee.approvalPhoneNumber,
            },
          ],
        }
      }
      return { phoneNumber: trainee.phoneNumber, applicationBrand }
    })

    setTrainees(prev => _.uniqBy([...prev, ...traineesPreparedForServer], 'phoneNumber'))
  }

  return (
    <div className={styles.trainees} data-trainees="trainees">
      <InviteTraineesModal
        visible={inviteTraineesModalVisible}
        setVisible={status => {
          setInviteTraineesModalVisible(status)
          setIsEdit(false)
        }}
        programId=""
        onSendInvite={onSendInvite}
        selectedTrainees={isEdit ? selectedTrainees : undefined}
        setTraineesPhonesToRemove={setTraineesPhonesToRemove}
        alreadyInvitedTrainees={[]}
      />
      <ImportFromCvsModal
        visible={importTraineesModalVisible}
        setVisible={setImportTraineesModalVisible}
        onAfterImportTrainees={onAfterImportTrainees}
      />
      <ImportedFromCsvModal
        importedTrainees={importedTrainees}
        programId={null}
        visible={importedTraineesModalVisible}
        setVisible={setImportedTraineesModalVisible}
        onOk={onSelectTraineesFromCsv}
        alreadyInvitedTrainees={[]}
      />

      <div className={styles.traineesBlock}>
        <div className={styles.header}>
          <div className={styles.inviteTrainees}>{t<string>('programCreation.invitedTrainees')}</div>
          <Checkbox checked={!disableSMS} onChange={e => setDisableSMS(!e.target.checked)} className={styles.checkbox}>
            {t<string>('programCreation.sendSms')}
          </Checkbox>
        </div>
        <div className={styles.buttons}>
          <CustomButton
            type="primary"
            className={styles.btn}
            onClick={() => setInviteTraineesModalVisible(true)}
            data-program-creation-invite-trainee="program-creation-invite-trainee"
            id="program-creation-invite-trainee"
          >
            {t<string>('programCreation.inviteTraineesModalText')}
          </CustomButton>
          <CustomButton
            className={styles.btn}
            onClick={() => setImportTraineesModalVisible(true)}
            data-program-creation-import-trainee="program-creation-import-trainee"
            id="program-creation-import-trainee"
          >
            {t<string>('programCreation.importTrainees')}
          </CustomButton>
        </div>
        {!!selectedTrainees.length && (
          <>
            <div
              className={styles.traineesLine}
              onClick={() => {
                setInviteTraineesModalVisible(true)
                setIsEdit(true)
              }}
            >
              <div className={styles.traineesBlockInfo}>
                <div> {t<string>('programCreation.traineesInvited')}</div>
                <div>
                  <Tag color="purple" className={styles.tag}>
                    {t<string>('programCreation.total')} {selectedTrainees.length}
                  </Tag>
                </div>
              </div>
              <div className={styles.avatarGroup}>
                <Avatar.Group
                  maxCount={6}
                  size="large"
                  maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
                >
                  {selectedTrainees.map(trainee => (
                    <SelectedTraineeMark key={`${trainee.id} ${trainee.phoneNumber}`} trainee={trainee} />
                  ))}
                </Avatar.Group>
                {selectedTrainees.length > 6 && (
                  <div className={styles.others}>+{selectedTrainees.length - 6} others</div>
                )}
              </div>
            </div>
            <div className={styles.profiles}>
              <SettingsProfileSelector
                value={settingsProfileId}
                setValue={setSettingsProfileId}
                preventResettingOnDestroy
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default TraineesTab
