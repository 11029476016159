import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import templatesApi from 'api/templates.api'
import wallPostApi from 'api/wallPost.api'

import { useStore } from 'stores'

import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { deleteEntityTypeEnum, planningToolsType } from 'types/Template'
import { IDeleteIds, RepeatingTypeEnum, planingToolTypesEnum } from 'types/Template/Tools'

import customConfirm from 'utils/customConfirm'
import message from 'utils/message'
import isFileType from 'utils/tools/isFileType'
import localToolTypeToServerToolType from 'utils/tools/localToolTypeToServerToolType'

interface IDeleteProps {
  dayIndex: number
  toolIndex: number
  tool: planningToolsType
  isUnbound: boolean
  onAfterDelete?: () => void
  repeatingOption?: RepeatingTypeEnum
}

interface IProps {
  isNewsMode?: boolean
}

const useDeleteTool = ({ isNewsMode }: IProps) => {
  const { templateStore, wallPostStore } = useStore()
  const { templateEntity, unboundContentStore: unboundStore } = templateStore

  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const onDeleteEntities = async ({
    tool,
    repeatingOption,
    entityToRemove,
    isWallPost,
  }: {
    tool: planningToolsType
    repeatingOption: RepeatingTypeEnum
    entityToRemove: IDeleteIds[]
    isWallPost: boolean
  }) => {
    const api = isWallPost ? wallPostApi : templatesApi
    try {
      if (repeatingOption === undefined) {
        await api.deleteEntities(entityToRemove)
      } else {
        if (!tool.id) {
          message.error(t('programCreation.smtWrong'))
          return
        }

        if (isFileType(tool.type)) {
          for (let i = 0; i < tool.entityIds.length; i++) {
            await api.deleteToolWithRepeating(
              {
                postId: tool.entityIds[i],
                postType: localToolTypeToServerToolType(tool.type as planingToolTypesEnum),
              },
              repeatingOption
            )
          }

          if (tool.type === planingToolTypesEnum.VIDEO) {
            for (let i = 0; i < tool.videoLinksEntityIds.length; i++) {
              await api.deleteToolWithRepeating(
                {
                  postId: tool.videoLinksEntityIds[i],
                  postType: localToolTypeToServerToolType(tool.type as planingToolTypesEnum),
                },
                repeatingOption
              )
            }
          }
        }

        if (tool.type === planingToolTypesEnum.QUESTIONNAIRE) {
          for (let i = 0; i < tool.entityIds.length; i++) {
            await api.deleteToolWithRepeating(
              {
                postId: tool.entityIds[i],
                postType: localToolTypeToServerToolType(tool.type as planingToolTypesEnum),
              },
              repeatingOption
            )
          }
          return
        }

        await api.deleteToolWithRepeating(
          { postId: tool.id, postType: localToolTypeToServerToolType(tool.type as planingToolTypesEnum) },
          repeatingOption
        )
      }
    } catch (e) {}
  }

  const deleteTool = async ({
    dayIndex,
    toolIndex,
    tool,
    isUnbound,
    onAfterDelete,
    repeatingOption = RepeatingTypeEnum.ONLY_THIS,
  }: IDeleteProps) => {
    if (isUnbound) {
      unboundStore.deleteDayByIndex(dayIndex)
      unboundStore.isUnboundToolsEditing = false
    } else {
      if (!templateEntity.currentDay) return

      const target = templateEntity.currentDay.dayItems.find((item, itemIdx) => itemIdx === toolIndex)

      if (target && !!target.id && target.id !== -1) {
        const entityToRemove = [
          {
            entity: deleteEntityTypeEnum[tool.type],
            id: [target.id],
          },
        ]

        if (templateEntity.isWallPosts && templateEntity.programId) {
          await onDeleteEntities({ tool, entityToRemove, isWallPost: true, repeatingOption })
          wallPostStore.followUpStore.setAvailableContentForFollowUpBound({
            id: +templateEntity.programId,
            isWallPost: true,
            skipCache: true,
          })

          if (isNewsMode) {
            const events = await templateStore.getProgramNewsDays(templateEntity.programId)
            if (events) {
              templateStore.getWallNews({
                programId: templateEntity.programId.toString(),
                skipLoading: true,
                useCache: false,
                dayIndex: events[templateEntity.currentDayIndex] ? templateEntity.currentDayIndex : events.length - 1,
              })
            }
          } else {
            templateStore.getProgramNewsDays(templateEntity.programId)
          }
          queryClient.invalidateQueries([QueryKeysEnum.WALL_SEARCHED_CONTENT])
        } else {
          await onDeleteEntities({ tool, entityToRemove, isWallPost: false, repeatingOption })
          if (templateEntity.templateInfo) {
            templateStore.followUpStore.setAvailableContentForFollowUpBound({
              id: +templateEntity.templateInfo.id,
              isWallPost: false,
              skipCache: true,
            })
          }
        }
      }

      templateEntity.deleteTool(toolIndex)
      message.success(t('templateCreation.toolRemoved'))
    }
    templateStore.setIsToolEditing(false, isUnbound)

    if (onAfterDelete) {
      onAfterDelete()
    }
  }

  const deleteToolConfirm = (props: IDeleteProps) => {
    customConfirm({
      title: t('templateCreation.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        deleteTool(props)
      },
      onCancel() {
        return
      },
      okText: t('dashboard.ok'),
      cancelText: t('dashboard.cancel'),
    })
  }

  return { deleteToolConfirm, deleteTool }
}

export default useDeleteTool
