import { useMutation, useQueryClient } from '@tanstack/react-query'

import tagsApi from 'api/tags.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

interface ITagToRemove {
  tagId: number
  traineeId: number
}

const useRemoveTagToTraineeMutation = () => {
  const queryClient = useQueryClient()

  const { mutateAsync: removeTagToTrainee, isLoading } = useMutation({
    mutationFn: async (tagsToRemove: ITagToRemove[]) => {
      await Promise.all(tagsToRemove.map(({ tagId, traineeId }) => tagsApi.removeTagToTrainee({ tagId, traineeId })))

      return true
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeysEnum.ALL_USER_TRAINEES])
    },
    onError: () => {},
  })

  return { removeTagToTrainee, isLoading }
}

export default useRemoveTagToTraineeMutation
