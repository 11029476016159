import { CheckCircleFilled } from '@ant-design/icons'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'

import { useStore } from 'stores'

import LS, { LSKeys } from 'utils/LS'
import message from 'utils/message'

import WarningLabel from './WarningLabel'

import { ITraineesTable } from 'components/InviteTraineesTable'

import styles from './styles.module.scss'

let yPos = 0

interface IProps {
  value: string
  setValue: (value: string) => void
  addText: string
  trainees?: ITraineesTable[]
  isParent?: boolean
  id?: string
  itemId?: number
}

const EditableText: FC<IProps> = ({ value, setValue, addText, trainees, isParent, id, itemId }) => {
  const { userStore } = useStore()
  const { t } = useTranslation()

  const [editMode, setEditMode] = useState(false)
  const [editableValue, setEditableValue] = useState(() => value)

  const inputRef = useRef<HTMLInputElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const inputContainerRef = useRef<HTMLInputElement>(null)

  const valueWarning = useMemo(() => {
    if (isValidPhoneNumber(value)) {
      return null
    }

    if (!value) {
      return t<string>('programCreation.required')
    }

    return t<string>('programCreation.phoneValidationError', {
      phoneNumbers: value,
    })
  }, [value, t, userStore.language])

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      //@ts-ignore
      if (inputContainerRef.current && !inputContainerRef.current.contains(event.target)) {
        if (!isValidEditableValue()) setEditableValue(value)
        else setValue(editableValue)

        setEditMode(false)
      }
    },
    [editableValue, value]
  )

  useEffect(() => {
    if (editMode) {
      document.addEventListener('click', handleClickOutside, true)
    } else {
      document.removeEventListener('click', handleClickOutside, true)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [editMode, editableValue, handleClickOutside])

  useEffect(() => {
    if (editMode) {
      inputRef.current?.focus()
    }
  }, [editMode, inputRef.current])

  const isValidEditableValue = () => {
    if (!isValidPhoneNumber(editableValue) || !editableValue.match(/^\+[0-9]+$/)) {
      message.error(
        t<string>('programCreation.phoneValidationError', {
          phoneNumbers: editableValue,
        })
      )
      inputRef.current?.focus()
      return
    }

    if (trainees) {
      const alreadyExist = trainees.find(
        trainee => trainee.phoneNumber === editableValue && (!itemId || itemId !== trainee.id)
      )

      if (alreadyExist) {
        message.error(t<string>('programCreation.userExistInList'))
        inputRef.current?.focus()
        return
      }
    }

    const inviteYourself = editableValue === LS.get(LSKeys.PHONE)

    if (inviteYourself) {
      if (isParent) {
        message.error(t<string>('programCreation.selfAsParentInviteError'))
        return false
      }

      message.error(t<string>('programCreation.selfInviteError'))
      return false
    }

    return true
  }

  const onCheck = () => {
    if (!isValidEditableValue()) return

    setEditMode(false)
    setValue(editableValue)
  }

  return (
    <div style={{ display: 'flex' }} {...{ [`data-${id}`]: id }} ref={scrollRef}>
      {editMode ? (
        <div className={styles.inputContainer} ref={inputContainerRef}>
          <input
            ref={inputRef}
            className={styles.input}
            value={editableValue}
            onChange={e => {
              setEditableValue(e.target.value)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                onCheck()
              }
            }}
          />
          <CheckCircleFilled onClick={onCheck} className={styles.inputCheck} />
        </div>
      ) : value ? (
        <div
          onClick={() => {
            setEditMode(true)
          }}
          className={styles.text}
        >
          {value}
          {valueWarning && <WarningLabel warning={valueWarning} />}
        </div>
      ) : (
        <div
          onClick={() => {
            setEditMode(true)
          }}
          className={styles.addText}
        >
          {addText}
          {valueWarning && <WarningLabel warning={valueWarning} />}
        </div>
      )}
    </div>
  )
}

export default EditableText
