import { Dropdown } from 'antd'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import { VariablesEnum } from 'types/User'
import getVariableKey from 'utils/getVariableKey'

import CustomMenu from 'components/CustomComponents/CustomMenu'

import styles from './styles.module.scss'

import variablesPic from 'sources/images/variables.png'

interface IProps {
  onSelectVariable: (variable: string) => void
  withTitle?: boolean
}

const TextVariables: FC<IProps> = ({ onSelectVariable, withTitle }) => {
  const { userStore } = useStore()

  const { t } = useTranslation()

  const menu = useMemo(() => {
    const items = [{ key: -1, label: t<string>('variables.listTitle'), onClick: () => {}, disabled: true }]

    items.push(
      ...userStore.variables
        .filter(variable => {
          if (withTitle) return true
          return variable !== VariablesEnum.title
        })
        .map((variable, idx) => ({
          key: idx,
          label: t(`variables.${getVariableKey(variable)}`),
          onClick: () => onSelectVariable(variable),
          disabled: false,
        }))
    )

    return items
  }, [onSelectVariable, userStore.language, withTitle])

  if (!userStore.variables.length) return <></>

  return (
    <Dropdown overlay={<CustomMenu items={menu} className={styles.menu} />}>
      <div className={styles.imageContainer}>
        <img src={variablesPic} className={styles.image} />
      </div>
    </Dropdown>
  )
}

export default TextVariables
