import { useMutation, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ICreateProgramDTO } from 'api/DTOs/programs.dto'
import fileApi from 'api/file.api'
import invitationsApi from 'api/invitations.api'
import programsApi from 'api/programs.api'

import { useStore } from 'stores'

import LS, { LSKeys } from 'utils/LS'
import message from 'utils/message'
import { clearProgramStorage } from 'utils/programCreationStorage'

import { IProgramDetailsForm, PostsSortingOptionsEnum, ProgramForm, ProgramImage } from 'types/Program'
import { QueryKeysEnum } from 'types/QueryKeysEnum'
import { ITemplateOption } from 'types/Template'
import { ITraineesServerModel } from 'types/User'
import { InviteSMSEnum } from 'types/common'

import { ITraineesTable } from 'components/InviteTraineesTable'

interface IProps {
  onSuccess?: () => void
}

const useProgramCreateMutation = ({ onSuccess }: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { programStore, userStore } = useStore()

  const [logoImage, setLogoImage] = useState<ProgramImage>({})
  const [disableSMS, setDisableSMS] = useState(true)
  const [trainingProgramAvatarId, setTrainingProgramAvatarId] = useState<number | null>(null)
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplateOption>()
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null)
  const [startDate, setStartDate] = useState<moment.Moment>(moment())

  const [companyId, setCompanyId] = useState(() => {
    const saved = LS.get(LSKeys.LAST_BRAND_COMPANY_ID_SELECTED)
    if (userStore.userBrands.some(brand => brand.id === saved)) return +saved
    if (userStore.userBrands.length === 1) return userStore.userBrands[0].id
    return 0
  })

  const [trainees, setTrainees] = useState<ITraineesServerModel[]>([])
  const [selectedTrainees, setSelectedTrainees] = useState<ITraineesTable[]>([])
  const [settingsProfileId, setSettingsProfileId] = useState<number | null>(null)
  const [sortingOption, setSortingOption] = useState(() =>
    userStore.isEventTypeApp ? PostsSortingOptionsEnum.EventDay : PostsSortingOptionsEnum.RevealDate
  )
  const queryClient = useQueryClient()

  const convertImagesToFormData = useCallback(
    (logoImageProp?: any) => {
      const formData = new FormData()

      if (logoImage.file) {
        formData.append('pictures', logoImage.file || logoImageProp)
        formData.append('imageSense', 'logo')

        formData.append('pictures', logoImage.file || logoImageProp)
        formData.append('imageSense', 'group')
      }

      return formData
    },
    [logoImage]
  )

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (data: ProgramForm & IProgramDetailsForm) => {
      const imagesFormData = convertImagesToFormData(logoImage)

      const imageId = await fileApi.uploadImage(imagesFormData)

      const traineesToInvite = trainees.map(trainee => ({ ...trainee, isInvite: !data.autoAcceptInvite }))

      const dataWithTemplateId = {
        name: data.name,
        description: data.description,
        goal: data.goal,
        templateId: selectedTemplateId,
        startDate: startDate.format(),
        disableSMS,
        groupChatAvatarId: trainingProgramAvatarId ?? imageId,
        trainingProgramAvatarId: trainingProgramAvatarId ?? imageId,
        disableChat: !data.enableChat,
        hideParticipants: !data.showParticipants,
        sortingOption,
        companyId:
          userStore.userBrands.length === 1 ? userStore.userBrands[0].id : !data.companyId ? null : data.companyId,
        isForAnonymous: userStore.isForAnonSubdomain || userStore.hasPublicProgramAccess ? data.isPublic : false,
        isPublic: userStore.isForAnonSubdomain || userStore.hasPublicProgramAccess ? false : data.isPublic,
        uniqueId: userStore.isForAnonSubdomain || userStore.hasPublicProgramAccess ? '' : data.uniqueId,
        isInvisible: !data.isVisible,
      } as ICreateProgramDTO

      let newProgramId = 0

      const response = await programsApi.createProgram(dataWithTemplateId)

      if (!response) return

      newProgramId = response.id

      if (newProgramId && trainees.length) {
        const response = await invitationsApi.inviteTraineesToProgram(
          traineesToInvite,
          newProgramId,
          disableSMS ? InviteSMSEnum.Disable : InviteSMSEnum.Enable,
          settingsProfileId
        )

        if (!response) return
      }

      programStore.programsIdsToCheckProgress = [...programStore.programsIdsToCheckProgress, newProgramId]

      message.success(t<string>('programCreation.successProgCreate'))

      clearProgramStorage()

      navigate('/programs/' + newProgramId)

      return
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeysEnum.PROGRAMS] })

      if (onSuccess) {
        onSuccess()
      }
    },
    onError: () => {
      message.error(t<string>('programCreation.smtWrong'))
    },
  })

  return {
    createProgramHandler: mutateAsync,
    logoImage,
    isLoading,
    trainees,
    setTrainees,
    setLogoImage,
    selectedTemplateId,
    setSelectedTemplateId,
    startDate,
    setStartDate,
    selectedTemplate,
    setSelectedTemplate,
    selectedTrainees,
    setSelectedTrainees,
    setTrainingProgramAvatarId,
    settingsProfileId,
    setSettingsProfileId,
    sortingOption,
    setSortingOption,
    companyId,
    setCompanyId,
    disableSMS,
    setDisableSMS,
  }
}

export default useProgramCreateMutation
