import getSubdomain from "utils/getSubdomain";

const subDomain = getSubdomain();

export const isLocalhost = !!subDomain.toLowerCase().includes("localhost");

export const isHillelBrand = !!subDomain.toLowerCase().includes("hillel");
export const isMatnasBrand = !!subDomain.toLowerCase().includes("matnas");
export const isOoziBrand = !!subDomain.toLowerCase().includes("oozi");
export const isMigvanimBrand = !!subDomain.toLowerCase().includes("migvanim");
export const isPbshBrand = !!subDomain.toLowerCase().includes("kesherr");
export const isBeatzmi = !!subDomain.toLowerCase().includes("beatzmi");
export const isMehobarim = !!subDomain.toLowerCase().includes("mehobarim");
export const isGina = !!subDomain.toLowerCase().includes("gina");
export const isDialog = !!subDomain.toLowerCase().includes("dialog");

if (isMigvanimBrand) {
	document.title = "Migvanim";
} else if (isHillelBrand) {
	document.title = "Hillel";
} else if (isPbshBrand) {
	document.title = "Kesherr";
} else if (isOoziBrand) {
	document.title = "OOZI";
} else if (isMatnasBrand) {
	document.title = "MATNAS";
} else if (isBeatzmi) {
	document.title = "Beatzmi";
} else if (isMehobarim) {
	document.title = "Mehobarim";
} else if (isGina) {
	document.title = "Gina";
} else if (isDialog) {
	document.title = "Dialog";
}

export const applicationBrand = isLocalhost
	? "aimpact"
	: isHillelBrand
		? "hillel"
		: isOoziBrand
			? "oozi"
			: isMigvanimBrand
				? "migvanim"
				: isPbshBrand
					? "pbsh"
					: isMatnasBrand
						? "matnas"
						: isBeatzmi
							? "beatzmi"
							: isMehobarim
								? "mehobarim"
								: isGina
									? "gina"
									: isDialog
										? "dialog"
										: "aimpact";
