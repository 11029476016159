import { FC } from 'react'

import { ReactComponent as NotePic } from 'sources/images/note.svg'

import styles from './styles.module.scss'

interface IProps {
  title: string
}

const InfoMessage: FC<IProps> = ({ title }) => {
  return (
    <div className={styles.container}>
      <NotePic width={32} height={32} />
      {title}
    </div>
  )
}

export default InfoMessage
