import { UploadFile } from 'antd'
import { t } from 'i18next'

import { imageMimeTypes } from 'utils/const'
import message from 'utils/message'
import preventSecondCall from './preventSecondCall'

const mimeWhitelist = [...imageMimeTypes]
const maxFileSize = 1024 * 1024 * 10 // 10Mb

const pickImageValidation = (fileList: UploadFile<any>[]) => {
  const currentVideoIds = fileList.map(file => file.uid)

  if (preventSecondCall(currentVideoIds)) return
  const filteredFileList = fileList.filter((file: any) => mimeWhitelist.includes(file?.type || file.subType))

  if (filteredFileList.length !== fileList.length) {
    message.error(t<string>('programCreation.imgErrFileType'))
    return
  }

  const incorrectSize = filteredFileList.every(file => (file.size ? file?.size > maxFileSize : false))

  if (incorrectSize) {
    message.error(t<string>('programCreation.imgErrFileSize'))
    return
  }

  return filteredFileList
}

export default pickImageValidation
