import { Modal, ModalFuncProps } from 'antd'

import stores from 'stores'

import { DEFAULT_PRIMARY_COLOR } from './const'

import styles from 'sources/styles/customConfirm.module.scss'

const customConfirm = (config: ModalFuncProps) =>
  Modal.confirm({
    className: styles.customConfirm,
    okButtonProps: {
      className: styles.okBtn,
      style: {
        backgroundColor: stores.userStore.brandData?.primaryColor || DEFAULT_PRIMARY_COLOR,
      },
    },
    cancelButtonProps: {
      className: styles.cancelBtn,
    },
    ...config,
  })

export default customConfirm
