import React from 'react'
import { Typography } from 'antd'
import { DesktopOutlined } from '@ant-design/icons'

import styles from './styles.module.scss'

const { Text, Title } = Typography

const DisplayCover = () => {
  return (
    <div className={styles.onlyDesktop} data-only-desktop="only-desktop">
      <DesktopOutlined className={styles.icon} data-desktop-icon="desktop-icon" />
      <Title level={5} data-oops="oops">
        Oops!
      </Title>
      <Text type="secondary" data-secondary-text="secondary-text">
        This page is only available on desktop devices
      </Text>
    </div>
  )
}

export default DisplayCover
