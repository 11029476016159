import { authApi } from 'config'

import { ISettingProfile } from 'types/InitialSettings'

const controller = 'InitialSettings'

const initialSettingsApi = {
  async getInitialSettingsByMentor() {
    const response = await authApi.get<ISettingProfile[]>(controller + '/currentMentor')
    return response.data
  },
  async getInitialSettings() {
    const response = await authApi.get<ISettingProfile[]>(controller)

    return response.data
  },
  async createInitialSetting(initialSetting: Omit<ISettingProfile, 'id'>) {
    const response = await authApi.post<ISettingProfile>(controller, initialSetting)

    return response.data
  },
  async editInitialSetting(initialSetting: ISettingProfile) {
    const response = await authApi.put<ISettingProfile>(controller, initialSetting)

    return response.data
  },
  async deleteInitialSetting(id: number) {
    const response = await authApi.delete(controller + '/' + id)

    return response.data
  },
}

export default initialSettingsApi
