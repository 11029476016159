import { useCallback, useEffect, useMemo, useState } from 'react'

import { IPagination } from 'types/common'

interface IProps {
  pageLength?: number
  hideSizer?: boolean
}

export interface IPaginationProps {
  currentPage: number
  size: number
  onPageChange: (page: number) => void
  onSizeChange: ((size: number) => void) | undefined
  totalItems: number
}

const usePagination = ({ pageLength, hideSizer }: IProps) => {
  const [pagination, setPagination] = useState<IPagination>({
    pageLength: pageLength || 10,
    pageNumber: 1,
    totalItems: 0,
    totalPages: 1,
    itemCount: 0,
  })

  const [paginationSetted, setPaginationSetted] = useState(false)

  useEffect(() => {
    if (pagination.pageNumber > pagination.totalPages) {
      setPagination(prev => ({ ...prev, pageNumber: pagination.totalPages }))
    }
  }, [pagination])

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageNumber: 1 }))
  }, [pagination.pageLength])

  const propsToPaginationComponent = useMemo(
    () => ({
      currentPage: pagination.pageNumber,
      size: pagination.pageLength,
      onPageChange: (page: number) => setPagination(prev => ({ ...prev, pageNumber: page })),
      onSizeChange: hideSizer ? void 0 : (size: number) => setPagination(prev => ({ ...prev, pageLength: size })),
      totalItems: pagination.totalItems,
    }),
    [pagination, setPagination, hideSizer]
  )

  const updatePaginationWithQuery = useCallback((paginationFromResponse: IPagination) => {
    setPaginationSetted(prev => {
      if (!prev) {
        setPagination(paginationFromResponse)
      } else {
        setPagination(prev => ({
          ...prev,
          itemCount: paginationFromResponse.itemCount,
          totalItems: paginationFromResponse.totalItems,
          totalPages: paginationFromResponse.totalPages,
        }))
      }

      return true
    })
  }, [])

  return {
    pagination,
    setPagination,
    propsToPaginationComponent,
    paginationSetted,
    setPaginationSetted,
    updatePaginationWithQuery,
  }
}

export default usePagination
