import { useQueryClient } from "@tanstack/react-query";
import { Menu, MenuProps, Space } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import userApi from "api/user.api";

import { useStore } from "stores";

import useDefineDirection from "hooks/private/useDefineDirection";
import useBrandSync from "hooks/useBrandSync";
import useCompanyAvatar from "hooks/useCompanyAvatar";

import { MenuKeysEnum } from "types/Sidebar";

import { getItem } from "utils/getItems";
import LS, { LSKeys } from "utils/LS";

import RouteSidebar from "./RouteSidebar";
import UserAvatar from "./UserAvatar";

import LanguageSelect from "components/LanguageSelect";
import LoaderSmall from "components/LoaderSmall";

import styles from "./styles.module.scss";

import logoUrl from "sources/images/logo.svg";

import { ReactComponent as ActivityIcon } from "sources/images/sidebar/Activity.svg";
import { ReactComponent as CompanyIcon } from "sources/images/sidebar/Company.svg";
import { ReactComponent as DashboardIcon } from "sources/images/sidebar/Dashboard.svg";
import { ReactComponent as HomeIcon } from "sources/images/sidebar/Home.svg";
import { ReactComponent as LanguageIcon } from "sources/images/sidebar/Language.svg";
import { ReactComponent as LinkIcon } from "sources/images/sidebar/Link.svg";
import { ReactComponent as LocationIcon } from "sources/images/sidebar/Location.svg";
import { ReactComponent as MessagesIcon } from "sources/images/sidebar/Messages.svg";
import { ReactComponent as ProgramsIcon } from "sources/images/sidebar/Programs.svg";
import { ReactComponent as RiseIcon } from "sources/images/sidebar/Rise.svg";
import { ReactComponent as ScrapingIcon } from "sources/images/sidebar/Scrape.svg";
import { ReactComponent as SettingsIcon } from "sources/images/sidebar/Settings.svg";
import { ReactComponent as TemplateIcon } from "sources/images/sidebar/Template.svg";
import { ReactComponent as TraineesIcon } from "sources/images/sidebar/Trainees.svg";
import { ReactComponent as UploadFile } from "sources/images/sidebar/UploadFile.svg";
import { ReactComponent as LetterSend } from "sources/images/sidebar/LetterSend.svg";

export enum ActiveRoute {
	Company,
	Programs,
	Templates,
	DashboardUsers,
	DashboardStatistic,
	DashboardChangeLog,
	TrainerDashboard,
	Trainees,
	Report,
	Prefixes,
	Location,
	Scraping,
}

const Sidebar = () => {
	const { t } = useTranslation();

	const [isLanguageModalVisible, setIsLanguageModalVisible] = useState(false);
	const [logoutLoading, setLogoutLoading] = useState(false);

	const { userStore, programStore } = useStore();

	const { direction } = useDefineDirection();

	const navigate = useNavigate();

	const queryClient = useQueryClient();

	const { syncBrandBySubdomain } = useBrandSync({ syncOnlyManually: true });

	const items: MenuProps["items"] = useMemo(() => {
		const subItems = [
			getItem(t("sidebar.language"), MenuKeysEnum.LANGUAGE, <LanguageIcon />),
			getItem(t("sidebar.signout"), MenuKeysEnum.SIGN_OUT),
		];

		if (!userStore.isAdmin) {
			subItems.unshift(
				getItem(t("sidebar.settings"), MenuKeysEnum.SETTINGS, <SettingsIcon />),
			);
		}

		const items = [
			getItem(
				"Navigation One",
				"sub1",
				<UserAvatar
					avatarId={userStore?.user?.avatarId}
					loading={logoutLoading}
				/>,
				subItems,
			),
		];

		return items;
	}, [
		userStore?.user?.avatarId,
		t,
		userStore.language,
		userStore.isAdmin,
		logoutLoading,
	]);

	const handleLogout = useCallback(async () => {
		const isAdmin = userStore.isAdmin;

		try {
			setLogoutLoading(true);
			await userApi.revokeToken();
			userStore.clearCache();
		} catch (e) {
			console.log(e);
		} finally {
			setLogoutLoading(false);

			LS.remove(LSKeys.USER);
			LS.remove(LSKeys.TOKEN);
			LS.remove(LSKeys.ADMIN_TOKEN);
			LS.remove(LSKeys.PROGRAM);
			LS.remove(LSKeys.SCRAPING_TOKEN);
			LS.remove(LSKeys.PROGRAM_LOGO);
			LS.remove(LSKeys.PROGRAM_GROUP_LOGO);
			LS.remove(LSKeys.PROGRAM_TRAINEES);
			LS.remove(LSKeys.PROGRAM_TEMPLATE_ID);

			userStore.setUser(null);
			userStore.setIsAdmin(false);
			userStore.setIsCompanyModerator(false);
			userStore.setIsTrainer(false);
			userStore.clearBrandingData();

			syncBrandBySubdomain();

			queryClient.removeQueries();

			programStore.userPrograms = [];

			navigate(isAdmin ? "/adminLogin" : "");
		}
	}, [userStore.isAdmin, userStore.user]);

	const handleMenuClick = (key: MenuKeysEnum) => {
		switch (+key) {
			case MenuKeysEnum.SETTINGS:
				navigate("/profile");
				break;
			case MenuKeysEnum.LANGUAGE:
				setIsLanguageModalVisible(true);
				break;
			case MenuKeysEnum.SIGN_OUT:
				handleLogout();
				break;
		}
	};

	const { logo, logoLoading } = useCompanyAvatar({
		avatarId: userStore.brandData?.appIconId || null,
	});

	const topLogo = useMemo(() => {
		if (logo) {
			return logo;
		}

		if (userStore.companyLogo) {
			return userStore.companyLogo;
		}

		return logoUrl;
	}, [logo, userStore.companyLogo, logoUrl]);

	return (
		<div
			className={classNames(styles.sidebar, {
				[styles.sidebarRlt]: direction === "rtl",
			})}
			data-sidebar="sidebar"
		>
			<LanguageSelect
				isVisible={isLanguageModalVisible}
				setIsVisible={setIsLanguageModalVisible}
			/>
			<Link
				className={classNames(styles.logo, {
					[styles.isAdminOrCompany]:
						userStore.isCompanyModerator && !userStore.isTrainer,
				})}
				to={
					userStore.isCompanyModerator
						? "/company"
						: userStore.isTrainer
							? "/programs"
							: "/dashboard/users"
				}
				data-link-2="link-2"
			>
				{(userStore.companyLogoLoading || logoLoading) && !userStore.isAdmin ? (
					<div className={styles.logoLoader}>
						<LoaderSmall />
					</div>
				) : (
					<img src={topLogo} alt="Aimpact" data-logo-url="logo-url" />
				)}
			</Link>

			{userStore.isMessagesOnlyAccess ? (
				<>
					<RouteSidebar
						to="/message"
						permission={userStore.isTrainer}
						activeRouteTo={ActiveRoute.Report}
						Icon={MessagesIcon}
						text={t("programSettings.message")}
					/>
					<RouteSidebar
						to="/sendMessage"
						permission={userStore.isTrainer}
						activeRouteTo={ActiveRoute.Report}
						Icon={LetterSend}
						text={t("sendMessage.sendMessage")}
					/>
				</>
			) : userStore.isMessagesAndProgramsAccess ? (
				<>
					<RouteSidebar
						to="/message"
						permission={userStore.isTrainer}
						activeRouteTo={ActiveRoute.Report}
						Icon={MessagesIcon}
						text={t("programSettings.message")}
					/>
					<RouteSidebar
						to="/sendMessage"
						permission={userStore.isTrainer}
						activeRouteTo={ActiveRoute.Report}
						Icon={LetterSend}
						text={t("sendMessage.sendMessage")}
					/>
					<RouteSidebar
						to="/programs"
						permission={userStore.isTrainer}
						activeRouteTo={ActiveRoute.Programs}
						Icon={ProgramsIcon}
						text={t("programList.programsText")}
					/>
				</>
			) : userStore.isAdmin ? (
				<Space className={styles.routes}>
					<RouteSidebar
						to="/dashboard/users"
						permission={userStore.isAdmin}
						activeRouteTo={ActiveRoute.DashboardUsers}
						Icon={HomeIcon}
						text={t("dashboard.users")}
						textContent={
							<div
								className={classNames(styles.routeText, {
									[styles.textFort]: userStore.language !== "en",
								})}
							>
								{t("dashboard.users")}
							</div>
						}
					/>
					<RouteSidebar
						to="/dashboard/activity"
						permission={userStore.isAdmin}
						activeRouteTo={ActiveRoute.Prefixes}
						Icon={ActivityIcon}
						text={t("activity.title")}
						textContent={
							<div
								className={classNames(styles.routeText, {
									[styles.textFort]: userStore.language !== "en",
								})}
							>
								{t("activity.title")}
							</div>
						}
					/>
					<RouteSidebar
						to="/dashboard/prefixes"
						permission={userStore.isAdmin}
						activeRouteTo={ActiveRoute.Prefixes}
						Icon={LinkIcon}
						text={t("prefixes.sidebarRoute")}
						textContent={
							<div
								className={classNames(styles.routeText, {
									[styles.textFort]: userStore.language !== "en",
								})}
							>
								{t("prefixes.sidebarRoute")}
							</div>
						}
					/>
					<RouteSidebar
						to="/dashboard/event-places"
						permission={userStore.isAdmin}
						activeRouteTo={ActiveRoute.Location}
						Icon={LocationIcon}
						text={t("eventPlaces.eventPlaces")}
						textContent={
							<div
								className={classNames(styles.routeText, {
									[styles.textFort]: userStore.language !== "en",
								})}
							>
								{t("eventPlaces.eventPlaces")}
							</div>
						}
					/>
					<RouteSidebar
						to="/dashboard/scraping"
						permission={userStore.isAdmin}
						activeRouteTo={ActiveRoute.Scraping}
						Icon={ScrapingIcon}
						text={t("scraping.scraping")}
						textContent={
							<div
								className={classNames(styles.routeText, {
									[styles.textFort]: userStore.language !== "en",
								})}
							>
								{t("scraping.scraping")}
							</div>
						}
					/>
				</Space>
			) : (
				<>
					<RouteSidebar
						to="/dashboard"
						permission={userStore.isTrainer}
						activeRouteTo={ActiveRoute.TrainerDashboard}
						Icon={DashboardIcon}
						text={t("programList.dashboard")}
					/>

					<RouteSidebar
						to="/programs"
						permission={userStore.isTrainer}
						activeRouteTo={ActiveRoute.Programs}
						Icon={ProgramsIcon}
						text={t("programList.programsText")}
					/>

					<RouteSidebar
						to="/template"
						permission={userStore.isTrainer}
						activeRouteTo={ActiveRoute.Templates}
						Icon={TemplateIcon}
						text={t("programList.templates")}
					/>

					<RouteSidebar
						to="/trainees"
						permission={userStore.isTrainer}
						activeRouteTo={ActiveRoute.Trainees}
						Icon={TraineesIcon}
						text={t("dashboard.trainees")}
					/>

					<RouteSidebar
						to="/report"
						permission={userStore.isTrainer}
						activeRouteTo={ActiveRoute.Report}
						Icon={RiseIcon}
						text={t("programList.dailyReport")}
					/>

					<RouteSidebar
						to="/message"
						permission={userStore.isTrainer}
						activeRouteTo={ActiveRoute.Report}
						Icon={MessagesIcon}
						text={t("programSettings.message")}
					/>
					<RouteSidebar
						to="/sendMessage"
						permission={userStore.isTrainer}
						activeRouteTo={ActiveRoute.Report}
						Icon={LetterSend}
						text={t("sendMessage.sendMessage")}
					/>
					<RouteSidebar
						to="/uploadFile"
						permission={userStore.isCompanyModerator}
						activeRouteTo={ActiveRoute.Report}
						Icon={UploadFile}
						text={t("uploadFile.uploadFile")}
					/>

					<RouteSidebar
						to="/company"
						permission={userStore.isCompanyModerator}
						activeRouteTo={ActiveRoute.Company}
						Icon={CompanyIcon}
						text={t("dashboard.company")}
					/>
				</>
			)}

			<Space className={styles.userProfile}>
				<Menu
					rootClassName={styles.profileMenu}
					onClick={(e) => handleMenuClick(e.key as unknown as MenuKeysEnum)}
					mode="inline"
					items={items}
					inlineCollapsed={true}
					selectable={false}
					data-menu="data-sidebar-menu"
					id="sidebar-menu"
				/>
			</Space>
		</div>
	);
};

export default observer(Sidebar);
