import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { useStore } from 'stores'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

export enum periodSelectEnum {
  WEEK = 1,
  MONTH = 2,
  HALF_YEAR = 3,
}

const useActivityQuery = (currentSelectedProgramId: string) => {
  const [periodSelect, setPeriodSelect] = useState(periodSelectEnum.WEEK)

  const { mentorDashboardStore } = useStore()

  const { data: chartData } = useQuery({
    keepPreviousData: true,
    queryKey: [QueryKeysEnum.ACTIVITY, periodSelect, currentSelectedProgramId],
    queryFn: async () => {
      const res = await mentorDashboardStore.getChartData(currentSelectedProgramId, periodSelect)

      return res || []
    },
  })

  return {
    chartData,
    periodSelect,
    setPeriodSelect,
  }
}

export default useActivityQuery
