import { t } from 'i18next'
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx'

import adminApi from 'api/admin.api'
import approvalApi from 'api/approval.api'
import brandsApi from 'api/brands.api'
import s3Api from 'api/s3.api'
import userApi from 'api/user.api'
import variableApi from 'api/variable.api'

import stores from 'stores'

import { AccessProfileEnum } from 'types/Dashboard'
import { IBrandInfo, IUser, VariablesType } from 'types/User'
import { IErrorMsg, LanguagesType, UserLanguagesEnum, UserLanguagesReversedEnum } from 'types/common'

import { authApi } from 'config'
import { BrandType } from 'types/Brand'
import LS, { LSKeys } from 'utils/LS'
import handleOTPErrorMessage from 'utils/handleOTPErrorMessage'
import message from 'utils/message'
import { clearBrandVariables, setBrandVariableToScss } from 'utils/variablesToScss'

class Store {
  constructor() {
    makeAutoObservable(this)
  }

  @observable user: IUser | null = null
  @observable userAvatar: string | null = null
  @observable language: LanguagesType = 'heMale'
  @observable isTrainer: boolean = false
  @observable isAdmin: boolean = false
  @observable isCompanyModerator: boolean = false
  @observable companyLogo: string | null = null
  @observable companyLogoLoading = false
  @observable brandName: string | null = null
  @observable brandLogo: string | null = null
  @observable backgroundImg: string | null = null
  @observable brandData: IBrandInfo | null = null
  @observable brandingAllowed: boolean = false
  @observable variables: [] | VariablesType = []
  @observable _lastActiveDateOfTraineeCache = new Map<number, string>()
  @observable brandIconId: number | null = null
  @observable userBrandLoading: boolean = false
  @observable isNewUser: boolean = false

  setIsNewUserFlag = (status: boolean) => {
    this.isNewUser = status
  }

  @action
  setCompanyLogoLoading = (flag: boolean) => (this.companyLogoLoading = flag)

  @action
  clearCache() {
    this._lastActiveDateOfTraineeCache.clear()
  }

  @action
  addLastActiveDateOfTraineeCache(key: number, value: string) {
    this._lastActiveDateOfTraineeCache.set(key, value)
  }

  @action
  async setVariables() {
    try {
      const variables = await variableApi.getAll()

      this.variables = variables
    } catch (e) {
      console.log(e)
    }
  }

  @action
  async setLanguage(lang: LanguagesType, isChangeOnServer?: boolean) {
    if (isChangeOnServer) {
      const languageCode = UserLanguagesReversedEnum[lang]

      try {
        await userApi.changeLanguage(languageCode)
      } catch (e) {}
    }

    localStorage.setItem('i18nextLng', lang)
    this.language = lang
  }
  @action
  setUserAvatar = (avatar: string) => {
    this.userAvatar = avatar
  }

  @action
  setUser(user: IUser | null) {
    runInAction(() => {
      this.user = user
    })
  }

  @action
  setIsAdmin(flag: boolean) {
    runInAction(() => {
      this.isAdmin = flag
    })
  }

  @action
  setIsCompanyModerator(flag: boolean) {
    runInAction(() => {
      this.isCompanyModerator = flag
    })
  }

  @action
  setIsTrainer(flag: boolean) {
    runInAction(() => {
      this.isTrainer = flag
    })
  }

  @action
  async approveTerms(hash: string): Promise<number | void> {
    try {
      await approvalApi.approve(hash)

      message.success('You successfully approve terms of service')
      LS.remove(LSKeys.USER)
      LS.remove(LSKeys.TOKEN)
      LS.remove(LSKeys.ADMIN_TOKEN)
      return 1
    } catch (e) {
      message.error('Something went wrong')
    }
  }

  @action
  async sendSMS(phoneNumber: string) {
    try {
      const response = await userApi.sendSms(phoneNumber)

      return {
        statusCode: response.status,
        message: '',
      }
    } catch (e) {
      const data = e?.response?.data
      return {
        statusCode: data?.StatusCode,
        message: data?.Message,
      }
    }
  }

  @action
  async otpConfirm(otpCode: string) {
    if (!this.user?.phoneNumber) {
      console.error('User phone number is empty')
      return
    }
    try {
      await userApi.smsAuthenticate(this.user.phoneNumber, otpCode)
    } catch (e) {
      const { StatusCode, Message } = e.response.data
      return handleOTPErrorMessage({ Message, StatusCode })
    }
  }

  @action
  async sendOTP(phoneNumber: string | undefined, otpCode: string | undefined): Promise<IErrorMsg | void> {
    try {
      const data = await userApi.smsAuthenticate(phoneNumber, otpCode)

      authApi.defaults.headers.common.Authorization = 'Bearer ' + data?.token

      this.setIsCompanyModerator(!!data?.companyId)
      this.setIsTrainer(data.haveTrainerPermission)
      this.setIsAdmin(false)

      LS.set(LSKeys.USER, data)
      LS.set(LSKeys.TOKEN, data?.token)
      LS.remove(LSKeys.ADMIN_TOKEN)

      this.setUser(data)
      this.setIsNewUserFlag(!!data.isNew || !data.firstName || !data.lastName)
    } catch (e) {
      const { StatusCode, Message } = e.response.data
      return handleOTPErrorMessage({ Message, StatusCode })
    }
  }

  @action
  async authAsAdmin(login: string, password: string): Promise<void | boolean> {
    try {
      const data = await adminApi.authenticate(login, password)
      try {
        const scrapingToken = await adminApi.scraperAuth({ login, password })
        LS.set(LSKeys.SCRAPING_TOKEN, scrapingToken)
      } catch (e) {}

      this.setIsAdmin(true)
      this.setIsTrainer(false)
      this.setUser(null)

      LS.remove(LSKeys.USER)
      LS.remove(LSKeys.TOKEN)
      LS.set(LSKeys.ADMIN_TOKEN, data)

      this.setIsNewUserFlag(false)

      return true
    } catch (e) {
      const { StatusCode, Message } = e.response.data

      if (StatusCode === 400) {
        message.error(t('adminLogin.wrongCredentials'))
        return
      }

      message.error(t('programCreation.smtWrong'))
      return
    }
  }

  @action
  async setUserDataAPI() {
    try {
      this.setCompanyLogoLoading(true)

      const data = await userApi.getCurrentUser()

      if (!data) return

      const updatedUser = { ...this.user, ...data }

      if (data.companyAsAdmin?.id) {
        updatedUser.companyId = data.companyAsAdmin.id
      }

      this.setUser(updatedUser)

      this.setIsNewUserFlag(!!data.isNew || !data.firstName || !data.lastName)

      this.setIsCompanyModerator(!!data.companyAsAdmin?.id || !!this.user?.companyId)

      if (data?.companyAsAdmin?.id) {
        stores.companyStore.getCompanyById(data.companyAsAdmin.id)
      } else if (this.user?.companyId) {
        stores.companyStore.getCompanyById(this.user.companyId)
      }

      if (!data?.companyAvatars) return
      const companyAvatarIds = data.companyAvatars.map(avatar => avatar.id)

      if (companyAvatarIds.length > 1 || !companyAvatarIds.length) {
        this.companyLogo = null
        return
      }

      const id = +companyAvatarIds[0]

      const img = await stores.programStore.getProgramImage(id)

      if (img) {
        this.companyLogo = URL.createObjectURL(img)
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.setCompanyLogoLoading(false)
    }
  }

  async getUserLanguage(): Promise<void | LanguagesType> {
    try {
      if (this.isAdmin) return
      const language = await userApi.getUserLanguage()
      // @ts-ignore
      this.setLanguage(UserLanguagesEnum[language])
      // @ts-ignore
      return UserLanguagesEnum[language]
    } catch (e) {
      console.log(e)
    }
  }

  async clearBrandingData(): Promise<void> {
    this.brandName = null
    this.brandData = null
    this.brandLogo = null
    this.brandIconId = null
    this.companyLogo = null
    this.backgroundImg = null
    clearBrandVariables()
  }

  async getUserBrandInfo(brandName: string, isBySubdomain?: boolean): Promise<void> {
    try {
      this.userBrandLoading = true

      if (!brandName) return

      const data = await brandsApi.getUserBrandInfo(brandName, isBySubdomain)

      if (!data) return

      this.brandName = data.name
      this.brandData = data

      this.brandIconId = data.appIconId

      LS.set(LSKeys.BRAND_DATA, data)

      setBrandVariableToScss(data)

      const promiseToCall = []

      if (data.webLoginLogoKey)
        promiseToCall.push(this.setFileInfo(data.webLoginLogoKey, LSKeys.BRAND_LOGO, 'brandLogo'))

      if (data.webBackgroundImgKey)
        promiseToCall.push(this.setFileInfo(data.webBackgroundImgKey, LSKeys.BRAND_BG, 'backgroundImg'))

      await Promise.all(promiseToCall)
    } catch (e) {
    } finally {
      this.userBrandLoading = false
    }
  }

  private async setFileInfo(s3Key: string, localStorageKey: LSKeys, storeKey: 'brandLogo' | 'backgroundImg') {
    const file = await s3Api.getFileFromS3(s3Key)

    if (!file) return

    const url = URL.createObjectURL(file)

    this[storeKey] = url
    LS.set(LSKeys.BRAND_DATA, url)
    LS.set(localStorageKey, url)
  }

  async updateUserInfo(
    userInfo: Pick<IUser, 'firstName' | 'lastName' | 'avatarId' | 'email' | 'phoneNumber'>,
    newAvatar?: string
  ): Promise<void | string> {
    const id = stores.userStore?.user?.id

    if (!id) throw new Error()

    const res = await userApi.updateUser({ id, ...userInfo })

    stores.userStore.setUser({ ...stores?.userStore?.user, ...res })
    LS.set(LSKeys.USER, { ...stores.userStore.user, ...res })

    if (newAvatar) stores.userStore.setUserAvatar(newAvatar)
  }

  @computed
  get fullUserName() {
    if (this.user && this.user.firstName && this.user.lastName) {
      return `${this.user.firstName} ${this.user.lastName}`
    }

    return ''
  }

  @computed
  get isForAnonSubdomain() {
    return !!this.brandData?.hasNoRegistration
  }

  @computed
  get hasPublicProgramAccess() {
    return !!this.brandData?.hasPublicProgramAccess
  }

  @computed
  get isMessagesOnlyAccess() {
    return (
      this.user?.companyAccessProfile === AccessProfileEnum.MessagesOnly ||
      this.user?.accessProfile === AccessProfileEnum.MessagesOnly
    )
  }

  @computed
  get isMessagesAndProgramsAccess() {
    return (
      this.user?.companyAccessProfile === AccessProfileEnum.MessagesAndPrograms ||
      this.user?.accessProfile === AccessProfileEnum.MessagesAndPrograms
    )
  }

  @computed
  get userBrands() {
    const result = []

    if (this.user?.companyAsAdmin?.id && this.user?.companyAsAdmin?.name) {
      result.push({
        id: this.user.companyAsAdmin.id,
        name: this.user.companyAsAdmin.name,
      })
    }

    result.push(
      ...(this.user?.companiesAsMentor?.flatMap(company => {
        if (company.customBrandAllowed && !!company.companyBrand?.slug) {
          return [{ id: company.id, name: company.name }]
        }
        return []
      }) || [])
    )

    return result
  }

  @computed
  get isEventTypeApp() {
    return this.brandData?.brandType === BrandType.Events
  }
}

export default new Store()
