import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

interface IProps {
  error?: string
}

const PageError = ({ error }: IProps) => {
  const { t } = useTranslation()

  return <div className={styles.text}>{error || t('programCreation.smtWrong')}</div>
}

export default PageError
