import { Radio, Select } from 'antd'
import classNames from 'classnames'
import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

interface IDashboardMentorProgram {
  value: number | string
  label: string
}

interface IProps {
  userPrograms: {
    value: string | number
    label: string
  }[]
  currentSelectedProgramId: string
  handleProgramChange: (programId: number) => void
}

const ProgramsLine: FC<IProps> = ({ userPrograms, currentSelectedProgramId, handleProgramChange }) => {
  const { t } = useTranslation()

  const userProgramsToShow = useMemo(() => {
    const showDirectly = userPrograms.length === 2 ? userPrograms.slice(1) : userPrograms.slice(0, 4)
    const showInSelect = userPrograms.slice(4)

    return {
      showDirectly,
      showInSelect,
    }
  }, [userPrograms])

  const selectValue = useMemo(() => {
    return userProgramsToShow.showInSelect.find(program => +program.value === +currentSelectedProgramId)?.value || null
  }, [userProgramsToShow.showInSelect, currentSelectedProgramId])

  return (
    <div className={styles.programsSelect}>
      <Radio.Group
        value={currentSelectedProgramId}
        onChange={e => handleProgramChange(+e.target.value)}
        className={styles.programs}
      >
        {userProgramsToShow.showDirectly.map((program: IDashboardMentorProgram) => (
          <Radio.Button
            value={program.value}
            key={program.value}
            className={classNames(styles.selectButton, {
              [styles.activeButton]: +currentSelectedProgramId === +program.value,
            })}
            data-dashboard-program={program.label}
            id={'dashboard-program' + program.label}
          >
            {program.label}
          </Radio.Button>
        ))}
      </Radio.Group>
      {!!userProgramsToShow.showInSelect.length && (
        <Select
          placeholder={t<string>('programList.more')}
          value={selectValue || t<string>('programList.more')}
          onChange={value => handleProgramChange(+value)}
          getPopupContainer={trigger => trigger}
          options={userProgramsToShow.showInSelect}
          className={classNames(styles.selectMore, {
            [styles.selectMoreActive]: !!selectValue,
          })}
        />
      )}
    </div>
  )
}

export default memo(ProgramsLine)
