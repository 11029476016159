import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import useProgramListQuery from 'hooks/tanstack/queries/useProgramListQuery'
import useRedirectToOneItem from 'hooks/useRedirectToOneItem'

import { PROGRAMS_ON_PAGE } from 'utils/const'

import { IProgramList } from 'types/Program'

import ListHeader from './ListHeader'
import ProgramItem from './ProgramItem'

import Loader from 'components/Loader'
import NoItemsInList from 'components/NoItemsInList'
import PageContainer from 'components/PageContainer'
import Pagination from 'components/Pagination'

import { Spin } from 'antd'
import styles from './styles.module.scss'

const ProgramList = () => {
  const { debouncedSearch, isLoading, programs, search, setSearch, pagination, setPageNumber, fetching } =
    useProgramListQuery()

  useRedirectToOneItem<IProgramList>({
    items: debouncedSearch ? [] : programs || [],
    pageUrl: '/programs/',
    total: pagination?.totalItems || 0,
  })

  const { t } = useTranslation()

  return (
    <PageContainer>
      {!programs?.length && isLoading ? (
        <div className={styles.loader} data-loader="loader">
          <Loader />
        </div>
      ) : (
        <>
          <ListHeader isLoading={isLoading} searchValue={search} setSearchValue={setSearch} />
          {programs?.length ? (
            <>
              <Spin spinning={fetching}>
                {programs.map(program => (
                  <ProgramItem key={program.id} program={program} />
                ))}
              </Spin>
              {pagination && pagination.totalItems > PROGRAMS_ON_PAGE && (
                <Pagination
                  currentPage={pagination.pageNumber}
                  totalItems={pagination.totalItems}
                  size={pagination.pageLength}
                  onPageChange={setPageNumber}
                  loading={!programs.length}
                />
              )}
            </>
          ) : (
            <NoItemsInList
              message={t(debouncedSearch ? 'programList.noProgramsReq' : 'programList.noPrograms')}
              showActionButton={!debouncedSearch}
              type="programs"
            />
          )}
        </>
      )}
    </PageContainer>
  )
}

export default observer(ProgramList)
