import { authApi } from 'config'
import {
  GetMessageTemplatePagination,
  GetPaginateUser,
  ItemPagination,
  PatchMessageTemplate,
  PostMessageTemplate,
} from 'types/MessageTemplate'

const controller = 'MessageTemplates'

const messageTemplate = {
  async getMessagePaginate(pageNumber: number, pageLength: number, name: string) {
    const result = await authApi.get<GetMessageTemplatePagination>(controller + '/paginated', {
      params: { pageLength, pageNumber, name },
    })
    return result.data
  },
  async getUsers(pageNumber: number, pageLength: number, search: string) {
    const result = await authApi.get<GetPaginateUser>(controller + '/users', {
      params: { pageNumber, pageLength, search },
    })
    return result.data
  },

  async postMessageClick(templateId: number, userID: number) {
    try {
      const result = await authApi.post(controller + `/click/${templateId}/user/${userID}`)
      return result.data
    } catch (e) {
      console.log(e)
    }
  },
  async getMessageByID(id: number) {
    const result = await authApi.get<ItemPagination>(controller + `/${id}`)
    return result.data
  },
  async createMessageTemplate(dto: PostMessageTemplate) {
    const result = await authApi.post(controller, dto)

    return result.data
  },
  async patchMessage(dto: PatchMessageTemplate) {
    const result = await authApi.patch(controller, dto)
    return result.data
  },
  async deleteMessage(id: number | string) {
    const result = await authApi.delete(controller + `/${+id}`)
    return result.data
  },
}

export default messageTemplate
