import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import message from 'utils/message'

import IconButton from 'components/IconBtn'
import ToolTitle from 'components/ToolTitle'

import styles from './styles.module.scss'

interface IProps {
  handleToolEdit: () => void
  isFirstDrop: boolean
  idToCopy?: string
  isEditing: boolean
  toolNumber: number
  revealedAt: boolean | moment.Moment | undefined
  isUnbound?: boolean
  onDelete: () => void
  dayId: number
  isNewsMode?: boolean
  inSearch?: boolean
}

const ToolTopBar: FC<IProps> = ({
  idToCopy,
  handleToolEdit,
  isEditing,
  isFirstDrop,
  revealedAt,
  toolNumber,
  isUnbound,
  onDelete,
  dayId,
  isNewsMode,
  inSearch,
}) => {
  const { t } = useTranslation()

  const { planBtnStore, templateStore, wallPostStore } = useStore()
  const { templateEntity } = templateStore

  const disableRemoveBtn = useMemo(() => {
    if (idToCopy) {
      return planBtnStore.checkIfToolInBtn(+idToCopy)
    }

    return false
  }, [idToCopy])

  const onDeleteTool = async () => {
    if (templateStore.checkIfToolEditing(isUnbound) && !isEditing) {
      message.error(t('templateCreation.completePreviousEdit'))

      return
    }

    if (onDelete && !isUnbound) {
      return onDelete()
    }

    const toolIdx = toolNumber - 1

    if (isUnbound) {
      if (templateEntity.programId) {
        await wallPostStore.deleteUnboundDay(dayId, templateEntity.programId, isFirstDrop)
      } else {
        if (!templateEntity.templateInfo?.id) return

        await templateStore.deleteUnboundDay(dayId, templateEntity.templateInfo.id, isFirstDrop)
      }

      if (isEditing) {
        templateStore.setIsToolEditing(false, true)
      }

      message.success(t('templateCreation.unboundContentDeleted'))
    } else {
      await templateEntity.deleteTool(toolIdx)
    }
  }

  return (
    <div className={styles.container}>
      <ToolTitle
        isEditing={isEditing}
        toolNumber={toolNumber}
        revealedAt={revealedAt}
        isUnbound={isUnbound}
        idToCopy={idToCopy}
        isNewsMode={isNewsMode}
        inSearch={inSearch}
      />
      <div className={styles.btnContainer}>
        {!!isUnbound && idToCopy && (
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(idToCopy)
              message.success(t<string>('templateList.copiedId'))
            }}
          >
            <CopyOutlined />
          </IconButton>
        )}
        {!isFirstDrop && !isEditing && (
          <IconButton onClick={handleToolEdit}>
            <EditOutlined />
          </IconButton>
        )}
        <Tooltip title={disableRemoveBtn ? t('templateCreation.rejectUnboundContentDelete') : ''}>
          <div>
            <IconButton danger onClick={onDeleteTool} disabled={disableRemoveBtn}>
              <DeleteOutlined />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default observer(ToolTopBar)
