interface IDailyReportBase {}

export interface IDailyReportForDashboard extends IDailyReportBase {
  complete: number
  dailyReportModelForUsers: IDailyReportModelForUser[]
  dateTime: string
  day: number
  postCount: number
  trainingProgramId: number
  trainingProgramName: string
}

interface IDailyReportModelForUser {
  avatarId: number
  avatarKey: string
  personalComplete: number
  postViewCount: number
  questionnaireAnsweredCount: number
  questionnaireCount: number
  userId: number
  userName: string
  userPhoneNumber: string
}

export enum ButtonType {
  ProgramWallPostSummaries = 0,
  ProgramWallPostStoredFiles = 1,
  Questionnaires = 2,
  ProgramWallPostLinks = 3,
  NoContent = 4,
}

export interface IReportUserLocal {
  id: number
  firstName: string
  lastName: string
  avatarId: number
  phoneNumber: string
}
