import { useEffect, useState, useRef } from 'react'

interface UseInViewOptions {
  threshold?: number | number[]
  root?: Element | null
  rootMargin?: string
}

function useInView<T extends HTMLElement>(options?: UseInViewOptions): [React.RefObject<T>, boolean] {
  const [isInView, setIsInView] = useState(false)
  const elementRef = useRef<T>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting)
      },
      {
        threshold: options?.threshold ?? 0,
        root: options?.root ?? null,
        rootMargin: options?.rootMargin ?? '0px',
      }
    )

    const currentElement = elementRef.current
    if (currentElement) {
      observer.observe(currentElement)
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement)
      }
    }
  }, [options])

  return [elementRef, isInView]
}

export default useInView
