import { makeAutoObservable } from 'mobx'
import moment from 'moment'

import entryApi from 'api/entry.api'
import reportsApi from 'api/reports.api'

import { periodSelectEnum } from 'hooks/tanstack/queries/useActivityQuery'

import { IChartData, IWeekActivity } from 'types/MentorDashboard'
import { IDailyReport, IDailyReportUser } from 'types/Program'
import { IDailyReportForDashboard } from 'types/Report'

const parseDailyReportTrainees = (trainees: any[], data: IDailyReport | IDailyReportForDashboard) =>
  trainees.push(
    ...data?.dailyReportModelForUsers?.map(trainee => ({
      ...trainee,
      programName: data?.trainingProgramName,
      postCount: data?.postCount,
      dateTime: data?.dateTime,
      programId: data?.trainingProgramId,
    }))
  )

class Store {
  constructor() {
    makeAutoObservable(this)
  }

  async getChartData(programId: string, periodSelect: periodSelectEnum): Promise<void | IChartData[]> {
    try {
      const dateTimeTill = moment().format('yyyy-MM-DD')
      const dateTimeFrom =
        periodSelect === periodSelectEnum.WEEK
          ? moment().subtract(7, 'days').format('yyyy-MM-DD')
          : periodSelect === periodSelectEnum.MONTH
          ? moment().subtract(1, 'months').format('yyyy-MM-DD')
          : moment().subtract(6, 'months').format('yyyy-MM-DD')

      const data = await entryApi.getActivity({
        trainingProgramId: +programId,
        dateTimeFrom,
        dateTimeTill,
      })

      if (!data) return

      return data.map((data: IWeekActivity) => {
        let y = 0

        if (data.realDayActivity === 0 || data.maxDayActivity === 0) {
          y = 0
        } else {
          const calc = (data.realDayActivity * 100) / data.maxDayActivity

          if (calc > 100) {
            y = 100
          } else {
            y = calc
          }
        }

        return {
          x: moment(data.dateTime).format('DD/MM'),
          y,
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  async getRecentEntriesTrainees({
    trainingProgrmId = '',
    dateTimeFrom = '',
    dateTimeTill = '',
  }: {
    trainingProgrmId?: string | number
    dateTimeFrom?: string
    dateTimeTill?: string
  } = {}): Promise<void | IDailyReportUser[]> {
    try {
      let report: IDailyReportForDashboard[] = []

      if (!trainingProgrmId) {
        report = (await reportsApi.getDailyReportForDashboard({ dateTimeFrom, dateTimeTill })) || []
      } else {
        report = (await reportsApi.getDailyReport(trainingProgrmId, dateTimeFrom, dateTimeTill)) || []
      }

      const trainees: IDailyReportUser[] = []
      const partlyAppliedParse = parseDailyReportTrainees.bind(null, trainees)

      report?.map(partlyAppliedParse)

      return trainees.flatMap((trainee, idx) => {
        if (
          trainee.questionnaireAnsweredCount ||
          trainee?.userQuestionsAndAnswers?.some(question => !!question.answer)
        ) {
          return [{ ...trainee, id: trainee.id || `${trainee.userId}-${idx}` }]
        }
        return []
      })
    } catch (e) {
      console.log(e)
    }
  }
}

export default new Store()
