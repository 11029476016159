import { makeAutoObservable } from 'mobx'

import templatesApi from 'api/templates.api'
import wallPostApi from 'api/wallPost.api'

import { ITempalteBtn, IWallPostBtn } from 'types/TemplateButton'

class PlanBtn {
  constructor() {
    makeAutoObservable(this)
  }

  loading = false
  templatePlanBtn: ITempalteBtn | null = null
  wallPostsPlanBtn: IWallPostBtn | null = null
  templatePlanBtnSource: ITempalteBtn | null = null
  wallPostsPlanBtnSource: IWallPostBtn | null = null

  setTemplatePlanBtn(btn: ITempalteBtn | null) {
    this.templatePlanBtn = btn
  }

  setWallPostsPlanBtn(btn: IWallPostBtn | null) {
    this.wallPostsPlanBtn = btn
  }

  setTemplatePlanBtnSource(btn: ITempalteBtn | null) {
    this.templatePlanBtnSource = btn
  }

  setWallPostsPlanBtnSource(btn: IWallPostBtn | null) {
    this.wallPostsPlanBtnSource = btn
  }

  checkIfToolInBtn(id: number) {
    if (!this.wallPostsPlanBtn && !this.templatePlanBtn) return false

    const existInWallPost =
      !!this.wallPostsPlanBtn?.programWallPostUnboundId && this.wallPostsPlanBtn.programWallPostUnboundId === id

    const existInTemplate =
      !!this.templatePlanBtn?.templateEntityUnboundId && this.templatePlanBtn.templateEntityUnboundId === id

    return existInWallPost || existInTemplate
  }

  async setPlanBtnFromTemplateAPI(templateId: number) {
    try {
      this.loading = true
      const btns = await templatesApi.getTemplateButton(templateId)

      const btn = btns.find(item => item)

      if (btn) {
        const planBtnObj = { ...btn, contentTemporaryId: btn.templateEntityUnboundId.toString() }

        this.setTemplatePlanBtn(planBtnObj)
        this.setTemplatePlanBtnSource(planBtnObj)

        return btn
      }
    } catch (e) {
      console.log('e', e)
    } finally {
      this.loading = false
    }
  }

  async createWallPostBtn(btn: IWallPostBtn, programId: number) {
    await wallPostApi.createWallPostButton(btn)
    await this.setPlanBtnFromWallPostsAPI(programId)
  }

  async editWallPostBtn(btn: IWallPostBtn, programId: number, isContentIdChanged: boolean) {
    if (isContentIdChanged && btn.id) {
      await wallPostApi.deleteTemplateButton(btn.id)
      await wallPostApi.createWallPostButton(btn)
    } else {
      await wallPostApi.editWallPostButton(btn)
    }
    await this.setPlanBtnFromWallPostsAPI(programId)
  }

  async createTemplateBtn(btn: ITempalteBtn, templateId: number) {
    await templatesApi.createTemplateButton(btn)
    await this.setPlanBtnFromTemplateAPI(templateId)
  }

  async editTemplateBtn(btn: ITempalteBtn, templateId: number, isContentIdChanged: boolean) {
    if (isContentIdChanged && btn.id) {
      await templatesApi.deleteTemplateButton(btn.id)
      await templatesApi.createTemplateButton(btn)
    } else {
      await templatesApi.editTemplateButton(btn)
    }
    await this.setPlanBtnFromTemplateAPI(templateId)
  }

  async setPlanBtnFromWallPostsAPI(programId: number) {
    try {
      this.loading = true
      const btns = await wallPostApi.getWallPostButtons(programId)

      const btn = btns.find(item => item)

      if (btn) {
        const planBtnObj = { ...btn, contentTemporaryId: btn.programWallPostUnboundId.toString() }

        this.setWallPostsPlanBtn(planBtnObj)
        this.setWallPostsPlanBtnSource(planBtnObj)

        return btn
      }
    } catch (e) {
      console.log('e', e)
    } finally {
      this.loading = false
    }
  }
}

export default new PlanBtn()
