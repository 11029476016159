import { t } from 'i18next'
import moment from 'moment'

import { schedulerReminderOptionEnum } from 'types/ScheduleNotification'
import { LanguagesType } from 'types/common'

import { days } from './dateTime'

const dailyRegEx = /^(?:\*|[0-9]{1,2}) (?:\*|[0-9]{1,2}) \* \* \*$/
const weeklyRegEx = /^(?:\*|[0-9]{1,2}) (?:\*|[0-9]{1,2}) \* \* [0-7]$/
const monthlyRegEx = /^(?:\*|[0-9]{1,2}) (?:\*|[0-9]{1,2}) [0-9]{1,2} \* \*$/

export const scheduleReminderCronToRepeatOption = (cron: string) => {
  if (!cron) {
    return schedulerReminderOptionEnum.doNotRepeat
  }

  if (dailyRegEx.test(cron)) {
    return schedulerReminderOptionEnum.daily
  }

  if (weeklyRegEx.test(cron)) {
    return schedulerReminderOptionEnum.weekly
  }

  if (monthlyRegEx.test(cron)) {
    return schedulerReminderOptionEnum.monthly
  }

  return schedulerReminderOptionEnum.doNotRepeat
}

export const getCronLabel = (cron: string, language: LanguagesType) => {
  if (!cron) return ''

  if (dailyRegEx.test(cron)) {
    const [minutes, hours] = cron.split(' ')

    return `${t<string>('templateCreation.daily')} - ${hours}:${minutes}`
  }

  if (weeklyRegEx.test(cron)) {
    const [minutes, hours, _, __, day] = cron.split(' ')

    const lang = language === 'en' ? 'en' : language === 'ru' ? 'ru' : 'he'

    return `${t<string>('templateCreation.weekly')} - ${days[lang][+day]} ${hours}:${minutes}`
  }

  if (monthlyRegEx.test(cron)) {
    const [minutes, hours, dayNumber] = cron.split(' ')

    return `${t<string>('templateCreation.monthly')} - ${t<string>(
      'templateCreation.day'
    )} ${dayNumber} ${hours}:${minutes}`
  }

  return ''
}

export const scheduleReminderCronGenerate = ({
  executeAtDate,
  executeAtTime,
  option,
}: {
  option: schedulerReminderOptionEnum
  executeAtDate: string | undefined
  executeAtTime: string | undefined //hh:mm:ssZ
}) => {
  const arr = executeAtTime?.split(':')

  if (!arr) return ''

  const currentTime = moment().format('HH:mm')

  const diff = moment().set('minutes', +arr[1]).set('hours', +arr[0]).diff(moment(), 'minutes')
  const needToFixTime = diff <= 0 && diff >= -3

  const isToday = moment(executeAtDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')

  const hours = arr[0]
  const minutes = needToFixTime && isToday ? +currentTime.split(':')[1] + 1 : arr[1]

  switch (option) {
    case schedulerReminderOptionEnum.daily:
      return `${minutes} ${hours} * * *`

    case schedulerReminderOptionEnum.weekly:
      return `${minutes} ${hours} * * ${moment(executeAtDate).day()}`

    case schedulerReminderOptionEnum.monthly:
      return `${minutes} ${hours} ${moment(executeAtDate).date()} * *`

    case schedulerReminderOptionEnum.doNotRepeat:
      return ''
    default:
      return ''
  }
}
