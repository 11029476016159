import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useStore } from 'stores'

import useDebounce from 'hooks/useDebounce'

import BottomControlPanel from './BottomControlPanel'
import DayItemConfigHOC from './DayItemConfigHOC'
import DisplayCover from './DisplayCover'
import HeadInfo from './HeadInfo'
import NewContentPanel from './NewContentPanel'
import SearchedContent from './SearchedContent'
import WallPostModal from './WallPostModal'

import Loader from 'components/Loader'
import SearchBar from 'components/SearchBar'
import TemplateCreateModal from 'components/TemplateCreateModal'

import styles from './styles.module.scss'

interface IProps {
  fromList?: boolean
  isCreation?: boolean
  isNewsMode?: boolean
}

const TemplateModule: FC<IProps> = ({ fromList, isCreation, isNewsMode }) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const [templateNameModalVisible, setTemplateNameModalVisible] = useState(() => !!isCreation)
  const [wallPostModalVisible, setWallPostModalVisible] = useState(false)
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 500)

  const containerRef = useRef<HTMLDivElement | null>(null)

  const navigate = useNavigate()

  const { t } = useTranslation()

  const onDayDatePress = () => setWallPostModalVisible(true)

  const goBack = () => {
    if (templateStore.templateRedirectionInfo?.backTo) {
      navigate('/programs/create?step=2')
    } else {
      navigate(-1)
    }
  }

  const showNewContentAdding =
    (!templateStore.isToolEditing &&
      (!templateEntity.isWallPosts ||
        !templateEntity.currentDay?.dayDate ||
        moment().diff(templateEntity.currentDay?.dayDate, 'hours') < 24)) ||
    isNewsMode

  useEffect(() => {
    return () => {
      templateEntity.wipeTemplate()
      templateStore.clearCache()
      templateStore.setIsToolEditing(false, false)
      templateStore.setIsToolEditing(false, true)
    }
  }, [])

  if (templateEntity.loadingTemplate || !templateEntity.currentDay)
    return (
      <div>
        <TemplateCreateModal
          open={templateNameModalVisible}
          onCancel={() => {
            setTemplateNameModalVisible(false)
            goBack()
          }}
        />
      </div>
    )

  return (
    <>
      <div ref={ref => (containerRef.current = ref)}>
        <div className={styles.container} data-container="container">
          <DisplayCover />
          <HeadInfo
            showBackRoute={fromList}
            goBack={goBack}
            isNewsMode={isNewsMode}
            isSearchMode={!!debouncedSearch}
            PostHeader={
              templateEntity.programId &&
              !isNewsMode && (
                <SearchBar
                  placeholder={t('mentorDashboard.search')}
                  value={search}
                  onChangeCustom={str => {
                    templateStore.setIsToolEditing(false, false)
                    setSearch(str)
                  }}
                  className={styles.search}
                />
              )
            }
          />
          {templateEntity.loadingDay ? (
            <div className={styles.loaderDayContainer}>
              <Loader />
            </div>
          ) : debouncedSearch && templateEntity.programId ? (
            <SearchedContent search={debouncedSearch} programId={templateEntity.programId} isNewsMode={isNewsMode} />
          ) : (
            <div className={styles.day}>
              <DayItemConfigHOC onDayDatePress={onDayDatePress} isNewsMode={isNewsMode} />
              {showNewContentAdding && (
                <NewContentPanel isNewsMode={isNewsMode} title={t('templateCreation.contentSelectText')} />
              )}
            </div>
          )}
        </div>
        <BottomControlPanel containerRef={containerRef} isNewsMode={isNewsMode} />
      </div>
      <TemplateCreateModal
        open={templateNameModalVisible}
        onCancel={() => {
          setTemplateNameModalVisible(false)
          goBack()
        }}
      />
      {templateEntity.isWallPosts && (
        <WallPostModal visible={wallPostModalVisible} setVisible={setWallPostModalVisible} />
      )}
    </>
  )
}

export default observer(TemplateModule)
