import { ArrowUpOutlined } from '@ant-design/icons'
import { default as classNames, default as cn } from 'classnames'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

import useDaysMoving from 'hooks/tools/useDaysMoving'
import useIsRtl from 'hooks/useIsRtl'
import useTrackScrollPosition from 'hooks/useTrackScrollPosition'

import { useStore } from 'stores'

import message from 'utils/message'

import CustomButton from 'components/CustomComponents/CustomButton'

import styles from './styles.module.scss'

interface IProps {
  containerRef: React.MutableRefObject<HTMLDivElement | null>
  isNewsMode?: boolean
}

const BottomControlPanel: FC<IProps> = ({ containerRef, isNewsMode }) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const disableMoveToNextDay =
    !!templateEntity.templateInfo && templateEntity.currentDayIndex === templateEntity.templateInfo.duration - 1

  const { incrementDay, decrementDay } = useDaysMoving({ isNewsMode })

  const handleBackClick = () => {
    if (templateStore.isToolEditing) {
      message.error(t<string>('templateCreation.previousEdit'))
      return
    }

    decrementDay()
  }

  const handleNextClick = () => {
    if (disableMoveToNextDay) return

    if (templateStore.isToolEditing) {
      message.error(t<string>('templateCreation.previousEdit'))
      return
    }

    incrementDay()
  }

  const isRtl = useIsRtl()
  const { scrollPosition } = useTrackScrollPosition()

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.rtl]: isRtl,
      })}
    >
      <div className={styles.container}>
        <CustomButton
          onClick={handleBackClick}
          disabled={templateEntity.currentDayIndex <= 0}
          data-template-back-btn="template-back-btn"
          id="template-back-btn"
        >
          {t<string>('templateCreation.prevDay')}
        </CustomButton>
        <CustomButton
          icon={<ArrowUpOutlined />}
          onClick={() => {
            containerRef.current?.scrollIntoView({ behavior: 'smooth' })
          }}
          className={classNames(styles.toTopBtn, {
            [styles.visible]: scrollPosition > 400,
          })}
        >
          {t<string>('shared.toTop')}
        </CustomButton>
        <CustomButton
          onClick={handleNextClick}
          disabled={disableMoveToNextDay}
          data-template-next-btn="template-next-btn"
          id="template-next-btn"
        >
          {t<string>('templateCreation.nextDay')}
        </CustomButton>
      </div>
    </div>
  )
}

export default observer(BottomControlPanel)
