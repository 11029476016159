import { LoadingOutlined } from '@ant-design/icons'
import { Avatar, Image } from 'antd'
import cn from 'classnames'
import useUserAvatarQuery from 'hooks/tanstack/queries/useUserAvatarQuery'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

import { useStore } from 'stores'

import styles from './styles.module.scss'

interface IProps {
  avatarId?: number
  loading?: boolean
}

const UserAvatar: FC<IProps> = ({ avatarId, loading }) => {
  const { userStore } = useStore()

  const { avatar, isLoading } = useUserAvatarQuery({ id: avatarId || 0 })

  return (
    <div className={cn({ [styles.userLogo]: !avatar })}>
      {avatar ? (
        <Avatar
          className={styles.avatar}
          shape="square"
          icon={
            isLoading || loading ? (
              <LoadingOutlined style={{ color: '#333' }} />
            ) : (
              <Image src={avatar} className={styles.avatarPic} preview={false} style={{ width: 40, height: 38 }} />
            )
          }
          style={{ backgroundColor: 'transparent' }}
        />
      ) : (
        <div className={styles.userInitials}>
          {userStore.user?.firstName?.[0]} {userStore.user?.lastName?.[0]}
        </div>
      )}
    </div>
  )
}

export default observer(UserAvatar)
