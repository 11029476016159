import { Editor } from '@tinymce/tinymce-react'
import { Image, Space, Switch, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useForm } from 'antd/lib/form/Form'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import useDeleteEntity from 'hooks/tools/useDeleteEntity'
import useSaveTool from 'hooks/tools/useSaveTool'
import useSaveToolWithPlanning from 'hooks/tools/useSaveToolWithPlanning'
import useToolSchedule from 'hooks/tools/useToolSchedule'
import useToolScroll from 'hooks/tools/useToolScroll'
import useToolState from 'hooks/tools/useToolState'
import useToolTags from 'hooks/tools/useToolTags'
import useIsRtl from 'hooks/useIsRtl'

import { useStore } from 'stores'

import { IDefaultToolProps, ISummaryDayItem, ITagWithToolRelation, schedulerOptionEnum } from 'types/Template'
import { IScenarioDayItem, RepeatingTypeEnum } from 'types/Template/Tools'

import message, { someError } from 'utils/message'
import checkIfNeedShedulerConfirm from 'utils/tools/checkIfNeedShedulerConfirm'

import ToolRevealedPanel from 'modules/TemplateModule/DayItem/DayTool/ToolSwitch/ToolRevealedPanel'
import ToolWrapper from 'modules/TemplateModule/DayItem/DayTool/ToolSwitch/ToolWrapper'

import useEventPlacesShortQuery from 'hooks/tanstack/queries/useEventPlacesShortQuery'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import CustomButton from 'components/CustomComponents/CustomButton'
import { SelectOutlined, ShareAltOutlined } from '@ant-design/icons'
import { scenarioChannel } from 'utils/broadcast'
import CustomInput from 'components/CustomComponents/CustomInput'

let stateCopy: {
  name: string
  isAllDaySelected: null | boolean
  revealedTime: undefined | moment.Moment
  schedulerValue: number
  entityTags: number[]
  daysSheduleValue: number[]
} = {
  name: '',
  isAllDaySelected: null,
  revealedTime: undefined,
  schedulerValue: 1,
  entityTags: [],
  daysSheduleValue: [],
}

export interface IAdditionalFieldsForm {
  title: string
  subTitle: string
  imageLink: string
  buttonText: string
  buttonUrl: string
  progressValue: string
  eventPlaceId: number | null
}

const ScenarioTool = ({
  tool,
  toolIndex,
  isDraggingItem,
  isUnbound,
  dayId,
  dayIndex,
  onDelete,
  isNewsMode,
  inSearch,
}: IDefaultToolProps) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const { t } = useTranslation()

  const thisTool = tool as IScenarioDayItem

  const [name, setName] = useState('')
  const [toolData, setToolData] = useState<IScenarioDayItem | null>(null)
  const [isMounted, setIsMounted] = useState(false)
  const [isAdditionalFields, setIsAdditionalFields] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isPublished, setIsPublished] = useState(() => !!thisTool.isPublished)

  const [eventDate, setEventDate] = useState<moment.Moment | null>(null)
  const [eventTime, setEventTime] = useState<moment.Moment | null>(null)

  const [touched, setTouched] = useState(false)

  const isEventDayError = (!!eventDate && !eventTime) || (!!eventTime && !eventDate)
  const isWallPost = !!templateEntity.programId

  const {
    isAllDaySelected,
    isEditing,
    isFirstDrop,
    isSchedulerModalVisible,
    isUpdating,
    revealedTime,
    setIsAllDaySelected,
    setIsEditing,
    setIsFirstDrop,
    setIsSchedulerModalVisible,
    setIsUpdating,
    setRevealedTime,
  } = useToolState()

  const { handleTagsChange, selectedTagIds, setSelectedTagIds, selectedTags, entityTagsToRemove } = useToolTags({
    tags: thisTool.entityTags as ITagWithToolRelation[],
  })

  const { schedulerValue, setSchedulerValue, daysSheduleValue, setDaysScheduleValue } = useToolSchedule({
    initialSchedule: thisTool.schedulerOption,
    initialDaysSchedule: thisTool.daysSheduleValue,
  })

  const { resetEntityIdsToDelete } = useDeleteEntity({
    isUnbound,
  })

  useEffect(() => {
    setIsMounted(true)
    const scenarioData = thisTool
    if (!scenarioData) return

    setToolData(scenarioData)

    if (scenarioData?.id === -1) {
      setIsFirstDrop(true)
      setIsEditing(true)
      templateStore.setIsToolEditing(true, isUnbound)
    } else {
      setIsEditing(false)
      setName(scenarioData.name)

      if (scenarioData?.isAllDaySelected) {
        setIsAllDaySelected(scenarioData.isAllDaySelected)
        setRevealedTime(scenarioData.revealedTime ? moment(scenarioData.revealedTime).local() : undefined)
      } else {
        setRevealedTime(scenarioData.revealedTime ? moment(scenarioData.revealedTime).local() : undefined)
        setIsAllDaySelected(false)
      }
    }
  }, [])

  useToolScroll({
    isMounted,
    isFirstDrop,
    uniqueId: toolData?.uniqueId,
  })

  useEffect(() => {
    if (isMounted) {
      if (isDraggingItem) return

      const scenarioData = thisTool as IScenarioDayItem

      if (!scenarioData) return

      setToolData(scenarioData)
      setName(scenarioData.name)
      setIsPublished(thisTool.isPublished)

      if (scenarioData?.isAllDaySelected) {
        setIsAllDaySelected(scenarioData.isAllDaySelected)
        setRevealedTime(scenarioData.revealedTime ? moment(scenarioData.revealedTime).local() : undefined)
      } else {
        setRevealedTime(scenarioData.revealedTime ? moment(scenarioData.revealedTime).local() : undefined)
        setIsAllDaySelected(false)
      }
    }
  }, [thisTool])

  const validationBeforeActions = () => {
    setTouched(true)

    if (name.length < 1) {
      message.error(t('scenario.enterScenarioName'))
      setIsError(true)
      return true
    }

    if (!revealedTime && !isAllDaySelected) {
      message.error(t('templateCreation.revealedTimeError'))
      return true
    }

    if (isEventDayError) {
      message.error(t('templateCreation.incorrectEventDay'))
      return true
    }

    if (schedulerValue === schedulerOptionEnum.specificDay && !daysSheduleValue.length) {
      message.error(t('templateCreation.selectAtLeastOneDay'))
      return true
    }

    return false
  }

  const { onSave, savingLoading } = useSaveTool({ entityTagsToRemove, isUnbound, dayId })

  const handleSaveClick = async (
    repeatingOption?: RepeatingTypeEnum,
    publishParams?: {
      status: boolean
    }
  ) => {
    if (validationBeforeActions()) return

    setIsUpdating(true)

    if (!publishParams && checkIfNeedShedulerConfirm({ repeatingOption, schedulerValue, thisTool })) {
      return setIsSchedulerModalVisible(true)
    }

    const eventDay =
      eventDate && eventTime
        ? moment(eventDate)
            .set('h', eventTime.get('h'))
            .set('m', eventTime.get('m') + eventDate.utcOffset())
            .toISOString()
            .split('Z')[0]
        : null

    const tool = publishParams
      ? { ...thisTool, isPublished: publishParams.status }
      : ({
          ...thisTool,
          id: toolData?.id ?? -1,
          name,
          revealedTime: revealedTime?.toISOString(true).slice(0, -6) as string,
          isAllDaySelected,
          uniqueId: toolData?.uniqueId || v4(),
          schedulerOption: schedulerValue,
          position: isFirstDrop ? (isUnbound ? 0 : templateEntity.lastPosition) : toolData?.position || toolIndex,
          entityIds: toolData?.entityIds || [],
          entityTags: selectedTags,
          repeatId: toolData?.repeatId || v4(),
          eventDay,
          daysSheduleValue,
          isPublished,
        } as IScenarioDayItem)

    try {
      await onSave({ tool, repeatingOption, preventRefetch: !!publishParams })

      saveToolFlagsSet()
    } catch (e) {
      message.error(t('programCreation.smtWrong'))
    }
  }

  const { onSaveWithPlanning } = useSaveToolWithPlanning({ onSave: handleSaveClick, setIsSchedulerModalVisible })

  const handleToolEdit = () => {
    if (templateStore.checkIfToolEditing(isUnbound)) {
      message.error(t('templateCreation.previousEdit'))
      return
    }

    if (!isEditing) {
      templateStore.setIsToolEditing(true, isUnbound)

      stateCopy = {
        name,
        isAllDaySelected,
        revealedTime,
        schedulerValue,
        entityTags: selectedTagIds,
        daysSheduleValue,
      }
    } else {
      rollBackChanges()
    }
    setIsEditing(prevState => !prevState)
  }

  const rollBackChanges = () => {
    if (isFirstDrop) {
      onDelete(() => setIsEditing(false))

      return
    }
    setName(stateCopy.name)

    setDaysScheduleValue(stateCopy.daysSheduleValue)

    stateCopy.isAllDaySelected !== null && setIsAllDaySelected(stateCopy.isAllDaySelected)
    setRevealedTime(stateCopy.revealedTime)
    setSelectedTagIds(stateCopy.entityTags)
    if (stateCopy.schedulerValue) {
      setSchedulerValue(stateCopy.schedulerValue)
      handleSelectSchedulerOption(stateCopy.schedulerValue)
    }
    templateStore.setIsToolEditing(false, isUnbound)

    setIsEditing(false)
    resetEntityIdsToDelete()
  }

  const handleSelectSchedulerOption = (value: schedulerOptionEnum) => {
    if (schedulerValue === value || validationBeforeActions()) return

    setSchedulerValue(value)
  }

  const saveToolFlagsSet = () => {
    templateStore.setIsToolEditing(false, isUnbound)
    setIsEditing(false)
    setIsFirstDrop(false)
    setIsUpdating(false)
  }

  const onUpdateScenario = useCallback(
    async (event: MessageEvent) => {
      if (thisTool.id !== event.data) {
        return
      }

      if (isWallPost) {
        await templateStore.getWallPostById({
          programId: templateEntity?.programId?.toString() || '',
          dayIndex: templateEntity.currentDayIndex,
          skipLoading: true,
        })
      } else {
        await templateStore.getTemplateEntitiesDay({
          id: templateEntity?.templateInfo?.id || 0,
          dayIndex: templateEntity.currentDayIndex,
          skipLoading: true,
        })
      }
    },
    [isWallPost, thisTool.id, templateEntity?.programId, templateEntity?.templateInfo?.id]
  )

  useEffect(() => {
    scenarioChannel.addEventListener('message', onUpdateScenario)

    return () => {
      scenarioChannel.removeEventListener('message', onUpdateScenario)
    }
  }, [thisTool.id])

  if (isNewsMode && !isAdditionalFields && thisTool.id !== -1) return null

  return (
    <ToolWrapper
      id={isUnbound ? dayId?.toString() || '' : toolData?.uniqueId}
      isEditing={isEditing}
      handleSaveClick={() => handleSaveClick()}
      daysValue={daysSheduleValue}
      onDaysValueChange={setDaysScheduleValue}
      rollBackChanges={rollBackChanges}
      data-container-summary="container-summary"
      isFirstDrop={isFirstDrop}
      handleToolEdit={handleToolEdit}
      entityId={toolData?.id}
      toolNumber={isUnbound ? dayIndex + 1 : toolIndex + 1}
      revealedAt={isAllDaySelected || revealedTime}
      isUnbound={isUnbound}
      handleSelectSchedulerOption={handleSelectSchedulerOption}
      schedulerValue={schedulerValue}
      handleTagsChange={handleTagsChange}
      selectedTagIds={selectedTagIds}
      onDelete={() => {
        onDelete(() => setIsEditing(false))
      }}
      savingLoading={savingLoading}
      handleSchedulerApplyModalOk={onSaveWithPlanning}
      isSchedulerModalVisible={isSchedulerModalVisible}
      isUpdating={isUpdating}
      setIsSchedulerModalVisible={setIsSchedulerModalVisible}
      srcTool={thisTool}
      dayId={dayId}
      isNewsMode={isNewsMode}
      inSearch={inSearch}
    >
      {isEditing && (
        <CustomInput
          prefix={<ShareAltOutlined />}
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder={t('scenario.scenarioName')}
          status={isError ? 'error' : undefined}
        />
      )}
      {!isEditing && (
        <Space direction="vertical">
          <Typography.Text>
            <ShareAltOutlined /> {name}
          </Typography.Text>
          <Link
            target="_blank"
            to={
              isWallPost
                ? `/scenario/wall-post/${(templateEntity as any).currentDay.id}/${templateEntity.programId}/${
                    thisTool.id
                  }`
                : `/scenario/template/${templateEntity.templateInfo?.id}/${dayId}/${thisTool.id}`
            }
          >
            <CustomButton loading={isUpdating} icon={<SelectOutlined />}>
              {t('scenario.openInScenarioBuilder')}
            </CustomButton>
          </Link>
        </Space>
      )}

      {templateEntity.isWallPosts && (
        <div className={styles.publishContainer}>
          <span>{t('templateCreation.toolStatusPublished')}</span>
          <Switch
            className="switchUI"
            checked={isPublished}
            onChange={async value => {
              try {
                setIsPublished(value)
                if (!isEditing) {
                  await handleSaveClick(void 0, { status: value })
                  if (templateEntity.programId) {
                    templateStore.mapWallPostDayInCache({
                      dayIndex: templateEntity.currentDayIndex,
                      programId: templateEntity.programId,
                    })
                  }
                }
              } catch (e) {
                setIsPublished(!value)
                someError()
                console.log('e', e)
              }
            }}
          />
        </div>
      )}
    </ToolWrapper>
  )
}

export default observer(ScenarioTool)
