import { config } from 'config'

const env = (process.env.REACT_APP_ENV as 'PROD') || 'STAGE'

let envKey: 'PROD' | 'STAGE' = env

if (env !== 'PROD' && env !== 'STAGE') {
  const envHandled = (env as string)?.split('=')?.[1]

  if (envHandled && (envHandled === 'PROD' || envHandled === 'STAGE')) {
    envKey = envHandled
  }
}

const s3Api = {
  async getFileFromS3(key: string): Promise<void | Blob> {
    const response = await fetch(
      `https://${config?.[envKey]?.S3 ?? 'aimpact-stage-stored-files'}.s3.eu-central-1.amazonaws.com/${key}`,
      {
        method: 'GET',
      }
    )

    return await response.blob()
  },
}

export default s3Api
