import { ColumnType } from 'antd/es/table'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import invitationsApi from 'api/invitations.api'
import userApi from 'api/user.api'

import { ITrainees, ITraineeWithInvite } from 'types/User'
import { TraineeGroopingEnum } from '../index'

import correctPhoneNumberForRTL from 'utils/correctPhoneNumberForRTL'
import customConfirm from 'utils/customConfirm'
import dateTime from 'utils/dateTime'
import message from 'utils/message'

import ActionPanel from './ActionPanel'
import TraineeActions from './TraineeActions'
import TraineeStatus from './TraineeStatus'

import CustomTable from 'components/CustomComponents/CustomTable'
import Pagination from 'components/Pagination'
import UserAvatar from 'components/UserAvatar'

import styles from './styles.module.scss'

interface IProps {
  programId: string
  activeTab: TraineeGroopingEnum
  currentTab: TraineeGroopingEnum
  trainees: ITraineeWithInvite[]
  onInvalidate: () => void
  isFetching: boolean
  propsToPaginationComponent: {
    currentPage: number
    size: number
    onPageChange: (page: number) => void
    onSizeChange: ((size: number) => void) | undefined
    totalItems: number
  }
}

const Trainees: React.FC<IProps> = ({
  programId,
  activeTab,
  currentTab,
  trainees,
  onInvalidate,
  isFetching,
  propsToPaginationComponent,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const handleDeleteTrainees = async () => {
    try {
      await Promise.all([
        invitationsApi.deleteInvitationsRange(+programId, selectedRowKeys as number[]),
        ...selectedRowKeys.map(userID =>
          userApi.removeFromProgram({ userID: +(userID as string), trainingProgramId: +programId })
        ),
      ])

      onInvalidate()
      setSelectedRowKeys([])
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteOneTrainee = async (trainee: ITrainees) => {
    customConfirm({
      title: t('programCreation.modalDeleteTrainees'),
      content: t('programCreation.modalAreYouSure'),
      cancelText: t('programCreation.modalNo'),
      okText: t('programCreation.modalYes'),
      onOk: async () => {
        try {
          const dto = { userID: +trainee.id, trainingProgramId: +programId }

          await invitationsApi.deleteInvitation(dto)
          await userApi.removeFromProgram(dto)

          onInvalidate()
          message.success(t('programCreation.userDeleteSuccess'))
        } catch (e) {
          message.error(t('programCreation.deleteUserError'))
        }
      },
    })
  }

  const resendInvitations = async (traineeIds: number[]) => {
    try {
      await invitationsApi.resendInvitesRange(+programId, traineeIds)
      setSelectedRowKeys([])
      onInvalidate()
    } catch (e) {
      console.log(e)
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const columns = [
    {
      title: selectedRowKeys.length ? (
        <ActionPanel
          activeTab={activeTab}
          handleDeleteTrainees={handleDeleteTrainees}
          resendInvitations={() => resendInvitations(selectedRowKeys as number[])}
        />
      ) : (
        t('programCreation.nameTable')
      ),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text: string, row) => (
        <div className={styles.fullNameCell}>
          <UserAvatar avatarId={row.avatarId} />
          {row.firstName && row.lastName ? `${row.firstName} ${row.lastName}` : 'Unknown'}
        </div>
      ),
    },
    {
      title: selectedRowKeys.length ? '' : t('programCreation.phoneNumberTable'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: phoneNumber => (phoneNumber ? correctPhoneNumberForRTL(phoneNumber) : 'Unknown'),
    },
    {
      title: selectedRowKeys.length ? '' : t('programCreation.emailTable'),
      dataIndex: 'email',
      key: 'email',
      render: email => email || 'Unknown',
    },
    {
      title: t('dashboard.lastActive'),
      dataIndex: 'lastActiveTime',
      key: 'lastActiveTime',
      render: value => {
        if (!value) return ''
        return dateTime.dateWithTime(value)
      },
    },
    {
      title: selectedRowKeys.length ? '' : t('programCreation.status'),
      dataIndex: 'inviteDto',
      key: 'inviteDto',
      render: (inviteDto, trainee) => <TraineeStatus trainee={trainee} currentTab={currentTab} />,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 30,
      render: (id, trainee) => (
        <TraineeActions
          currentTab={currentTab}
          trainee={trainee}
          handleDeleteOneTrainee={handleDeleteOneTrainee}
          resendInvitations={resendInvitations}
        />
      ),
    },
  ] as ColumnType<ITraineeWithInvite>[]

  return (
    <>
      <CustomTable
        rowKey="id"
        dataSource={trainees}
        columns={columns}
        rowSelection={currentTab === TraineeGroopingEnum.All || !trainees.length ? void 0 : rowSelection}
        pagination={false}
        loading={isFetching}
        onRow={data => {
          return {
            onClick(e) {
              navigate('/trainees/' + data.phoneNumber)
            },
          }
        }}
        rowClassName={styles.row}
      />
      <Pagination {...propsToPaginationComponent} loading={!trainees.length} />
    </>
  )
}

export default observer(Trainees)
