import { UploadFile } from 'antd'
import { t } from 'i18next'
import message from 'utils/message'
import preventSecondCall from './preventSecondCall'

const _200MB = 200_000_000

const pickVideoValidation = (fileList: UploadFile<any>[]) => {
  const currentVideoIds = fileList.map(file => file.uid)

  if (preventSecondCall(currentVideoIds)) return
  const filteredFileList = fileList.filter((file: any) => file.type === 'video/mp4' || file.subType === 'mp4')

  if (filteredFileList.length !== fileList.length) {
    message.error(t<string>('templateCreation.videoSupportFormats'))
  }

  const tooBigVideo = filteredFileList.some(file => file.size && file?.size > _200MB)

  if (tooBigVideo) {
    message.error(t<string>('templateCreation.videoBiggerThanLimit'))
    return
  }

  return filteredFileList
}

export default pickVideoValidation
