import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'stores'

import { IUserGoal } from 'types/Program'

import CustomTable from 'components/CustomComponents/CustomTable'
import TableContainerWrapper from '../PlanBtn/TableContainerWrapper'

interface IProps {
  goals: IUserGoal[]
}

const GoalsTable: FC<IProps> = ({ goals }) => {
  const { t } = useTranslation()

  const { userStore } = useStore()

  const columns = useMemo(
    () => [
      {
        key: 'programGoalId',
        render: () => <div />,
        width: '20%',
      },
      {
        key: 'programGoalId',
        title: '',
        render: (value: string) => <div style={{ fontWeight: 700 }}>{t<string>('templateCreation.goal')}</div>,
        width: '20%',
      },
      {
        title: t<string>('templateCreation.goal'),
        dataIndex: 'text',
        key: 'text',
        render: (value: string) => <div>{value}</div>,
        width: '30%',
      },
      {
        title: t<string>('templateCreation.score'),
        dataIndex: 'counter',
        key: 'counter',
        render: (value: string, row: IUserGoal) => <div>{`${row.counter}/${row.completionAt}`}</div>,
        width: '20%',
      },
      {
        key: 'programGoalId',
        render: () => <div />,
        width: '10%',
      },
    ],
    [userStore.language]
  )

  if (!goals.length) return <></>

  return (
    <TableContainerWrapper>
      <CustomTable
        variant="transparent"
        rowKey="programGoalId"
        columns={columns}
        dataSource={goals}
        pagination={false}
        showHeader={false}
      />
    </TableContainerWrapper>
  )
}

export default GoalsTable
