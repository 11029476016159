import { observer } from 'mobx-react-lite'
import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useUnboundStore from 'hooks/useUnboundStore'

import { useStore } from 'stores'

import message from 'utils/message'

import NewContentPanel from 'modules/TemplateModule/NewContentPanel'
import UnboundedContentBlock from './UnboundContentBlock'

import CustomModal from 'components/CustomComponents/CustomModal'

import styles from './styles.module.scss'

interface IProps {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  isBackToPlan: boolean
  onBack: () => void
  setIsBackToPlan: Dispatch<SetStateAction<boolean>>
}

const UnboundContentModal: FC<IProps> = ({ visible, setVisible, isBackToPlan, onBack, setIsBackToPlan }) => {
  const { t } = useTranslation()

  const { templateStore, wallPostStore } = useStore()
  const { templateEntity } = templateStore

  const { unboundStore } = useUnboundStore()

  const getUnboundContentAPI = useCallback(async () => {
    if (templateEntity.programId) {
      await wallPostStore.setUnboundContentAPI(templateEntity.programId)
      return
    }

    if (templateEntity.templateInfo?.id) {
      await templateStore.setUnboundContentAPI(templateEntity.templateInfo.id)
      return
    }
  }, [templateEntity.templateInfo?.id, templateEntity.programId])

  useEffect(() => {
    getUnboundContentAPI()
  }, [getUnboundContentAPI])

  const onCancelModal = () => {
    if (templateStore.checkIfToolEditing(true)) {
      message.error(t('templateCreation.previousEdit'))
      return
    }

    templateStore.setIsToolEditing(false, true)
    setVisible(false)
    setIsBackToPlan(false)
  }

  return (
    <CustomModal
      className={styles.modal}
      title={t<string>('templateCreation.unboundContent')}
      open={visible}
      destroyOnClose
      onCancel={onCancelModal}
      footer={null}
    >
      {unboundStore.unboundContent.map((item, idx) => (
        <UnboundedContentBlock key={`${item.id}-${idx}`} dayData={item} dayIndex={idx} />
      ))}
      {!templateStore.unboundContentStore.isUnboundToolsEditing && (
        <NewContentPanel title={t<string>('templateCreation.addUnboundedContent')} isUnbound />
      )}
    </CustomModal>
  )
}

export default observer(UnboundContentModal)
