import { authApi } from 'config'

const entryApi = {
  async getActivity(params: { trainingProgramId: number; dateTimeFrom: string; dateTimeTill: string }) {
    const response = await authApi.get('Entry/getActivity', {
      params,
    })

    return response.data
  },
}

export default entryApi
