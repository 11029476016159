import { authApi } from 'config'

import {
  IAddTagToCategoryDTO,
  IAddTagToTraineeDTO,
  ICreateCategoryDTO,
  ICreateTagDTO,
  IDeleteTagDTO,
  IGetTagsByCategoryDTO,
  IRemoveTagToTraineeDTO,
  ITagInterest,
  IUpdateCategoryDTO,
  IUpdateTagDTO,
} from 'api/DTOs/tags.dto'

import { ICategory, ICompanyTag, ILightTag, IMentorTag, ITagWithDetails } from 'types/Tags'
import { PaginationResponse } from 'types/common'

const controller = 'Tag/'

const tagsApi = {
  async getAllByCompanyId(companyId: number) {
    const response = await authApi.get<ICompanyTag[]>(controller + 'tags/' + companyId)

    return response.data
  },
  async getMentorTags() {
    const response = await authApi.get<IMentorTag[]>(controller + 'tagsForMentor')

    return response.data
  },
  async addTagToTrainee(dto: IAddTagToTraineeDTO) {
    const response = await authApi.post(controller + 'addTagToTrainee', dto)

    return response.data
  },
  async removeTagToTrainee(dto: IRemoveTagToTraineeDTO) {
    const response = await authApi.delete(controller + 'removeTagToTrainee', { data: dto })

    return response.data
  },
  async createTag(dto: ICreateTagDTO) {
    const response = await authApi.post(controller + 'create', dto)

    return response.data
  },
  async updateTag(body: IUpdateTagDTO) {
    const response = await authApi.patch(controller + 'update', body)

    return response.data
  },
  async deleteTag(params: IDeleteTagDTO) {
    const response = await authApi.delete(controller + 'delete', { params })

    return response.data
  },
  async createCategory(params: ICreateCategoryDTO) {
    const response = await authApi.post<ICategory>(controller + 'createCategory', {}, { params })

    return response.data
  },
  async updateCategory(body: IUpdateCategoryDTO) {
    const response = await authApi.patch(controller + 'updateCategory', body)

    return response.data
  },
  // async deleteCategory(categoryId: number) {
  //   const response = await authApi.delete(controller + 'deleteCategory', { params: { categoryId } })

  //   return response.data
  // },
  async getTagsLight() {
    const response = await authApi.get<ILightTag[]>(controller + 'tagsLight')
    return response.data
  },
  async getTagsInterest(isForAlerts: boolean) {
    const response = await authApi.get<ITagInterest[]>(controller + 'companyAlerts', { params: { isForAlerts } })
    return response.data
  },
  async getTagsByCategory(params: IGetTagsByCategoryDTO) {
    const response = await authApi.get<PaginationResponse<ITagWithDetails>>(controller + 'tags', {
      params,
    })

    return response.data
  },
}

export default tagsApi
