import { Editor } from '@tinymce/tinymce-react'
import { Image, Switch } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useForm } from 'antd/lib/form/Form'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import useDeleteEntity from 'hooks/tools/useDeleteEntity'
import useSaveTool from 'hooks/tools/useSaveTool'
import useSaveToolWithPlanning from 'hooks/tools/useSaveToolWithPlanning'
import useToolSchedule from 'hooks/tools/useToolSchedule'
import useToolScroll from 'hooks/tools/useToolScroll'
import useToolState from 'hooks/tools/useToolState'
import useToolTags from 'hooks/tools/useToolTags'
import useIsRtl from 'hooks/useIsRtl'

import { useStore } from 'stores'

import { IDefaultToolProps, ISummaryDayItem, ITagWithToolRelation, schedulerOptionEnum } from 'types/Template'
import { RepeatingTypeEnum } from 'types/Template/Tools'

import message, { someError } from 'utils/message'
import checkIfNeedShedulerConfirm from 'utils/tools/checkIfNeedShedulerConfirm'

import ToolRevealedPanel from 'modules/TemplateModule/DayItem/DayTool/ToolSwitch/ToolRevealedPanel'
import ToolWrapper from 'modules/TemplateModule/DayItem/DayTool/ToolSwitch/ToolWrapper'

import SummaryForm from './SummaryForm'

import useEventPlacesShortQuery from 'hooks/tanstack/queries/useEventPlacesShortQuery'
import styles from './styles.module.scss'

let stateCopy: {
  isAllDaySelected: null | boolean
  revealedTime: undefined | moment.Moment
  summary: string
  schedulerValue: number
  entityTags: number[]
  daysSheduleValue: number[]
} = {
  summary: '',
  isAllDaySelected: null,
  revealedTime: undefined,
  schedulerValue: 1,
  entityTags: [],
  daysSheduleValue: [],
}

export interface IAdditionalFieldsForm {
  title: string
  subTitle: string
  imageLink: string
  buttonText: string
  buttonUrl: string
  progressValue: string
  eventPlaceId: number | null
}

const SummaryTool = ({
  tool,
  toolIndex,
  isDraggingItem,
  isUnbound,
  dayId,
  dayIndex,
  onDelete,
  isNewsMode,
  inSearch,
}: IDefaultToolProps) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const { t } = useTranslation()

  const thisTool = tool as ISummaryDayItem

  const [summary, setSummary] = useState('')
  const [toolData, setToolData] = useState<ISummaryDayItem | null>(null)
  const [isMounted, setIsMounted] = useState(false)
  const [isAdditionalFields, setIsAdditionalFields] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isPublished, setIsPublished] = useState(() => !!thisTool.isPublished)

  const [eventDate, setEventDate] = useState<moment.Moment | null>(null)
  const [eventTime, setEventTime] = useState<moment.Moment | null>(null)

  const [touched, setTouched] = useState(false)

  const isEventDayError = (!!eventDate && !eventTime) || (!!eventTime && !eventDate)

  const { places, isLoading } = useEventPlacesShortQuery()

  const {
    isAllDaySelected,
    isEditing,
    isFirstDrop,
    isSchedulerModalVisible,
    isUpdating,
    revealedTime,
    setIsAllDaySelected,
    setIsEditing,
    setIsFirstDrop,
    setIsSchedulerModalVisible,
    setIsUpdating,
    setRevealedTime,
  } = useToolState()

  const [form] = useForm<IAdditionalFieldsForm>()

  const isRtl = useIsRtl()

  const [formValues, setFormValues] = useState<IAdditionalFieldsForm>({
    imageLink: '',
    subTitle: '',
    title: '',
    buttonText: '',
    buttonUrl: '',
    progressValue: '',
    eventPlaceId: null,
  })

  const { handleTagsChange, selectedTagIds, setSelectedTagIds, selectedTags, entityTagsToRemove } = useToolTags({
    tags: thisTool.entityTags as ITagWithToolRelation[],
  })

  const { schedulerValue, setSchedulerValue, daysSheduleValue, setDaysScheduleValue } = useToolSchedule({
    initialSchedule: thisTool.schedulerOption,
    initialDaysSchedule: thisTool.daysSheduleValue,
  })

  const { resetEntityIdsToDelete } = useDeleteEntity({
    isUnbound,
  })

  useEffect(() => {
    setIsMounted(true)
    const summaryData = thisTool
    if (!summaryData) return

    setToolData(summaryData)

    if (!summaryData?.summary) {
      setIsFirstDrop(true)
      setIsEditing(true)
      templateStore.setIsToolEditing(true, isUnbound)
    } else {
      if (summaryData.eventDay) {
        const date = moment(summaryData.eventDay)
        setEventDate(date)
        setEventTime(date)

        setIsAdditionalFields(true)
      }

      if (
        summaryData.title ||
        summaryData.imageLink ||
        summaryData.subTitle ||
        summaryData.progressValue ||
        summaryData.buttonText ||
        summaryData.buttonUrl ||
        summaryData.eventPlace
      ) {
        const additionalFieldsValues = {
          title: summaryData.title || '',
          imageLink: summaryData.imageLink || '',
          subTitle: summaryData.subTitle || '',
          buttonText: summaryData.buttonText || '',
          buttonUrl: summaryData.buttonUrl || '',
          progressValue: summaryData.progressValue?.toString() || '',
          eventPlaceId: summaryData.eventPlace?.id || null,
        }

        form.setFieldsValue(additionalFieldsValues)
        setFormValues(additionalFieldsValues)
        setIsAdditionalFields(true)
      }

      setIsEditing(false)
      setSummary(summaryData.summary)

      if (summaryData?.isAllDaySelected) {
        setIsAllDaySelected(summaryData.isAllDaySelected)
        setRevealedTime(summaryData.revealedTime ? moment(summaryData.revealedTime).local() : undefined)
      } else {
        setRevealedTime(summaryData.revealedTime ? moment(summaryData.revealedTime).local() : undefined)
        setIsAllDaySelected(false)
      }
    }
  }, [])

  useToolScroll({
    isMounted,
    isFirstDrop,
    uniqueId: toolData?.uniqueId,
  })

  useEffect(() => {
    if (isMounted) {
      if (isDraggingItem) return

      const summaryData = thisTool as ISummaryDayItem

      if (!summaryData) return

      setToolData(summaryData)
      setSummary(summaryData.summary)
      setIsPublished(thisTool.isPublished)

      if (summaryData?.isAllDaySelected) {
        setIsAllDaySelected(summaryData.isAllDaySelected)
        setRevealedTime(summaryData.revealedTime ? moment(summaryData.revealedTime).local() : undefined)
      } else {
        setRevealedTime(summaryData.revealedTime ? moment(summaryData.revealedTime).local() : undefined)
        setIsAllDaySelected(false)
      }
    }
  }, [thisTool])

  const validationBeforeActions = () => {
    setTouched(true)

    const empty = !summary.replaceAll('&nbsp;', '').replaceAll('<p>', '').replaceAll('</p>', '').trim()

    if (!summary.length || empty) {
      message.error(t('templateCreation.inputQuestion'))
      setIsError(true)
      return true
    }

    if (!revealedTime && !isAllDaySelected) {
      message.error(t('templateCreation.revealedTimeError'))
      return true
    }

    if (isEventDayError) {
      message.error(t('templateCreation.incorrectEventDay'))
      return true
    }

    if (schedulerValue === schedulerOptionEnum.specificDay && !daysSheduleValue.length) {
      message.error(t('templateCreation.selectAtLeastOneDay'))
      return true
    }

    return false
  }

  const getAdditionalFields = (): IAdditionalFieldsForm => {
    if (isAdditionalFields) {
      const additionalFields = form.getFieldsValue()

      return {
        title: additionalFields?.title?.trim() || '',
        subTitle: additionalFields?.subTitle?.trim() || '',
        buttonText: additionalFields?.buttonText?.trim() || '',
        buttonUrl: additionalFields?.buttonUrl?.trim() || '',
        imageLink: additionalFields?.imageLink?.trim() || '',
        progressValue: additionalFields.progressValue || '',
        eventPlaceId: additionalFields.eventPlaceId || null,
      }
    }

    return {
      imageLink: '',
      subTitle: '',
      title: '',
      buttonText: '',
      buttonUrl: '',
      progressValue: '',
      eventPlaceId: null,
    }
  }

  const { onSave, savingLoading } = useSaveTool({ entityTagsToRemove, isUnbound, dayId })

  const handleSaveClick = async (
    repeatingOption?: RepeatingTypeEnum,
    publishParams?: {
      status: boolean
    }
  ) => {
    const valid = await form.validateFields()

    if (!valid) return

    if (validationBeforeActions()) return

    setIsUpdating(true)

    if (!publishParams && checkIfNeedShedulerConfirm({ repeatingOption, schedulerValue, thisTool })) {
      return setIsSchedulerModalVisible(true)
    }

    const additionalFields = getAdditionalFields()

    const eventDay =
      eventDate && eventTime
        ? moment(eventDate)
            .set('h', eventTime.get('h'))
            .set('m', eventTime.get('m') + eventDate.utcOffset())
            .toISOString()
            .split('Z')[0]
        : null

    const tool = publishParams
      ? { ...thisTool, isPublished: publishParams.status }
      : ({
          ...thisTool,
          id: toolData?.id ?? -1,
          summary,
          revealedTime: revealedTime?.toISOString(true).slice(0, -6) as string,
          isAllDaySelected,
          uniqueId: toolData?.uniqueId || v4(),
          schedulerOption: schedulerValue,
          position: isFirstDrop ? (isUnbound ? 0 : templateEntity.lastPosition) : toolData?.position || toolIndex,
          entityIds: toolData?.entityIds || [],
          entityTags: selectedTags,
          repeatId: toolData?.repeatId || v4(),
          programWallPostId: toolData?.programWallPostId,
          ...additionalFields,
          progressValue: +additionalFields.progressValue || 0,
          eventDay,
          daysSheduleValue,
          isPublished,
        } as ISummaryDayItem)

    try {
      await onSave({ tool, repeatingOption, preventRefetch: !!publishParams })

      saveToolFlagsSet()
    } catch (e) {
      message.error(t('programCreation.smtWrong'))
    }
  }

  const { onSaveWithPlanning } = useSaveToolWithPlanning({ onSave: handleSaveClick, setIsSchedulerModalVisible })

  const handleToolEdit = () => {
    if (templateStore.checkIfToolEditing(isUnbound)) {
      message.error(t('templateCreation.previousEdit'))
      return
    }

    if (!isEditing) {
      templateStore.setIsToolEditing(true, isUnbound)

      stateCopy = {
        summary,
        isAllDaySelected,
        revealedTime,
        schedulerValue,
        entityTags: selectedTagIds,
        daysSheduleValue,
      }
    } else {
      rollBackChanges()
    }
    setIsEditing(prevState => !prevState)
  }

  const rollBackChanges = () => {
    if (isFirstDrop) {
      onDelete(() => setIsEditing(false))

      return
    }
    setSummary(stateCopy.summary)

    setDaysScheduleValue(stateCopy.daysSheduleValue)

    stateCopy.isAllDaySelected !== null && setIsAllDaySelected(stateCopy.isAllDaySelected)
    setRevealedTime(stateCopy.revealedTime)
    setSelectedTagIds(stateCopy.entityTags)
    if (stateCopy.schedulerValue) {
      setSchedulerValue(stateCopy.schedulerValue)
      handleSelectSchedulerOption(stateCopy.schedulerValue)
    }
    templateStore.setIsToolEditing(false, isUnbound)

    setIsEditing(false)
    resetEntityIdsToDelete()
  }

  const handleSelectSchedulerOption = (value: schedulerOptionEnum) => {
    if (schedulerValue === value || validationBeforeActions()) return

    setSchedulerValue(value)
  }

  const additionalToolHandleChange = (value: string, index: number) => {
    setIsError(false)
    setSummary(value)
  }

  const saveToolFlagsSet = () => {
    templateStore.setIsToolEditing(false, isUnbound)
    setIsEditing(false)
    setIsFirstDrop(false)
    setIsUpdating(false)
  }

  const editorRef = useRef<Editor | null>(null)

  const handleInsert = (variable: string) => {
    if (!editorRef.current?.editor) return

    const content = editorRef.current.editor?.getContent()
    setIsError(false)

    if (!content) {
      editorRef.current?.editor?.insertContent(variable)
      return
    }

    const lastTagIndex = content.lastIndexOf('</')

    if (lastTagIndex !== -1) {
      const prevCharIndex = lastTagIndex - 1
      const insertIndex = lastTagIndex + 2 // Add 2 to include the closing angle bracket

      const prevChar = content.substring(prevCharIndex - 5, prevCharIndex + 1)

      if (prevChar === '&nbsp;') {
        editorRef.current.editor.insertContent(variable, { index: insertIndex })
      } else {
        editorRef.current.editor.insertContent(' ' + variable, { index: insertIndex })
      }
    }
  }

  const onFormChange = () => {
    const values = form.getFieldsValue()

    setFormValues(values)
  }

  const onAdditionalFieldsRevealingChange = (e: CheckboxChangeEvent) => {
    setIsAdditionalFields(e.target.checked)
  }

  const place = useMemo(() => {
    return places.find(place => place.id === formValues.eventPlaceId)?.name
  }, [places, formValues.eventPlaceId])

  if (isNewsMode && !isAdditionalFields && thisTool.id !== -1) return null

  return (
    <ToolWrapper
      id={isUnbound ? dayId?.toString() || '' : toolData?.uniqueId}
      isEditing={isEditing}
      handleSaveClick={() => handleSaveClick()}
      daysValue={daysSheduleValue}
      onDaysValueChange={setDaysScheduleValue}
      rollBackChanges={rollBackChanges}
      data-container-summary="container-summary"
      isFirstDrop={isFirstDrop}
      handleToolEdit={handleToolEdit}
      entityId={toolData?.id}
      toolNumber={isUnbound ? dayIndex + 1 : toolIndex + 1}
      revealedAt={isAllDaySelected || revealedTime}
      isUnbound={isUnbound}
      handleSelectSchedulerOption={handleSelectSchedulerOption}
      schedulerValue={schedulerValue}
      handleTagsChange={handleTagsChange}
      selectedTagIds={selectedTagIds}
      onDelete={() => {
        onDelete(() => setIsEditing(false))
      }}
      savingLoading={savingLoading}
      handleSchedulerApplyModalOk={onSaveWithPlanning}
      isSchedulerModalVisible={isSchedulerModalVisible}
      isUpdating={isUpdating}
      setIsSchedulerModalVisible={setIsSchedulerModalVisible}
      srcTool={thisTool}
      dayId={dayId}
      isNewsMode={isNewsMode}
      inSearch={inSearch}
    >
      {isEditing ? (
        <SummaryForm
          key={String(isRtl)}
          additionalToolHandleChange={additionalToolHandleChange}
          editorRef={editorRef}
          onFormChange={onFormChange}
          form={form}
          handleInsert={handleInsert}
          isAdditionalFields={isAdditionalFields}
          onAdditionalFieldsRevealingChange={onAdditionalFieldsRevealingChange}
          summary={summary}
          eventDate={eventDate}
          eventTime={eventTime}
          setEventDate={setEventDate}
          setEventTime={setEventTime}
          isEventDayError={isEventDayError}
          touched={touched}
          isError={isError}
          places={places}
        />
      ) : (
        <div className={styles.itemContainer}>
          {isAdditionalFields && (
            <>
              {formValues.imageLink && <Image src={formValues.imageLink} className={styles.pic} />}
              {formValues.title && <div className={styles.title}>{formValues.title}</div>}
              {formValues.subTitle && <div className={styles.subtitle}>{formValues.subTitle}</div>}
              {templateEntity.isWallPosts && !!place && <div className={styles.subtitle}>{place}</div>}
              {!!Number(formValues.progressValue) && (
                <div className={styles.subtitle}>
                  {t('templateCreation.summaryProgressValue')}: {formValues.progressValue}
                </div>
              )}
            </>
          )}
          <div className={styles.toolBody} dangerouslySetInnerHTML={{ __html: summary }} />
          {!!eventDate && !!eventTime && (
            <div className={styles.eventDay}>
              {t('programDetails.eventDay')}: {eventDate.format('DD/MM/YYYY')} {eventTime.format('HH:mm')}
            </div>
          )}
          {!!formValues.buttonText && !!formValues.buttonUrl && (
            <div className={styles.registerBtnContainer}>
              <div onClick={() => window.open(formValues.buttonUrl, '_blank')} className={styles.registerBtn}>
                {formValues.buttonText}
              </div>
            </div>
          )}
        </div>
      )}

      {isEditing && !isUnbound && (
        <div style={{ marginTop: 30, marginBottom: 20 }}>
          <ToolRevealedPanel
            isAllDaySelected={isAllDaySelected}
            isEditing={isEditing}
            onChangeCheckbox={e => setIsAllDaySelected(e.target.checked)}
            onChangeTimePicker={value => setRevealedTime(value ?? undefined)}
            revealedTime={revealedTime}
          />
        </div>
      )}

      {templateEntity.isWallPosts && (
        <div className={styles.publishContainer}>
          <span>{t('templateCreation.toolStatusPublished')}</span>
          <Switch
            className="switchUI"
            checked={isPublished}
            onChange={async value => {
              try {
                setIsPublished(value)
                if (!isEditing) {
                  await handleSaveClick(void 0, { status: value })
                  if (templateEntity.programId) {
                    templateStore.mapWallPostDayInCache({
                      dayIndex: templateEntity.currentDayIndex,
                      programId: templateEntity.programId,
                    })
                  }
                }
              } catch (e) {
                setIsPublished(!value)
                someError()
                console.log('e', e)
              }
            }}
          />
        </div>
      )}
    </ToolWrapper>
  )
}

export default observer(SummaryTool)
