import {
  IDayData,
  IQuestionnaireDayItem,
  IQuestionnaireNumeric,
  ITemplateFileAPI,
  ITemplateLinkAPI,
  ITemplateQuestionnaireAPI,
  ITemplateSummaryAPI,
  QuestionTypeEnum,
  planingToolTypesEnum,
} from 'types/Template'
import { ITemplateDayAPI_V2, ITemplateScenarioAPI, IWallPostDayAPI } from 'types/Template/TemplateToServer'

import { ParseTemplateUtil, TemplateFileToServerConstructor } from './index'
import scenarioApi from 'api/scenario.api'

export type { ParseTemplateToServer }

class ParseTemplateToServer {
  private templateData!: IDayData[]
  private programId?: number | null
  private isUnbound?: boolean
  private keepRevealedAt?: boolean

  private fields = {
    questionnaires: '',
    files: '',
    summary: '',
    links: '',
    scenarios: '',
  }
  private fileGenerator = new TemplateFileToServerConstructor()
  private utils = new ParseTemplateUtil()

  constructor() {}

  setTemplateData(templateData: IDayData[], programId?: number, isUnbound?: boolean, keepRevealedAt?: boolean) {
    this.templateData = templateData
    this.programId = programId
    this.isUnbound = isUnbound
    this.keepRevealedAt = !!keepRevealedAt

    this.constructFields()

    return this
  }

  private constructFields() {
    this.fields = {
      questionnaires: this.programId ? 'questionnaires' : 'questionnaires',
      files: this.programId ? 'programWallPostStoredFiles' : 'files',
      summary: this.programId ? 'programWallPostSummaries' : 'summaries',
      links: this.programId ? 'programWallPostLinks' : 'templateEntityLinks',
      scenarios: this.programId ? 'scenarios' : 'scenarios',
    }
  }

  private handleDay(day: IDayData) {
    const currentDay = day.dayDate

    const defaultDateStart = currentDay.split('T')[0] + 'T00:00:00'
    const defaultDateClose = currentDay.split('T')[0] + 'T23:59:59'

    const result = {
      trainingProgramId: this.programId,
      id: day.id,
      [this.fields.files]: [],
      [this.fields.summary]: [],
      [this.fields.questionnaires]: [],
      [this.fields.links]: [],
      [this.fields.scenarios]: [],
      day: currentDay,
    }

    for (let i = 0; i < day.dayItems.length; i++) {
      const tool = day.dayItems[i]
      const position = i

      const correctRevealedTime = this.utils.getCorrectRevealedTime(tool, currentDay)

      const correctOpenTime =
        !correctRevealedTime && (tool as IQuestionnaireDayItem)?.openTime
          ? currentDay.split('T')[0] + 'T' + (tool as IQuestionnaireDayItem).openTime?.split('T')[1]
          : ''

      const correctCloseTime =
        !correctRevealedTime && (tool as IQuestionnaireDayItem)?.closeTime
          ? (tool as IQuestionnaireDayItem).closeTime
          : ''

      if (
        tool.type === planingToolTypesEnum.IMAGE ||
        tool.type === planingToolTypesEnum.AUDIO ||
        tool.type === planingToolTypesEnum.VIDEO ||
        tool.type === planingToolTypesEnum.FILE
      ) {
        const genratedTool = this.fileGenerator.run(
          tool,
          tool?.position || position,
          defaultDateStart,
          correctRevealedTime
        )

        const filesInDay = result[this.fields.files] as ITemplateFileAPI[]
        filesInDay.push(...genratedTool)

        continue
      }

      if (tool.type === planingToolTypesEnum.QUESTIONNAIRE) {
        const questionnaires = tool.questionsData.map((questionData, questionIndex) => {
          const questions = this.utils.getQuestionsInQuestionnaire(
            questionData,
            questionIndex,
            tool,
            !!this.programId,
            this.isUnbound
          )

          const openQuestionnaire =
            tool.isAllDaySelected && tool.openTime
              ? tool.openTime.split('T')[0] + 'T00:00:00'
              : tool.isAllDaySelected
              ? defaultDateStart
              : correctOpenTime

          const closeQuestionnaire =
            tool.isAllDaySelected && tool.closeTime
              ? tool.closeTime.split('T')[0] + 'T23:59:59'
              : tool.isAllDaySelected
              ? defaultDateClose
              : correctCloseTime

          return {
            id: tool?.entityIds?.[questionIndex] ?? -1,
            questions,
            isGraphNeeded: tool.isGraphNeeded ?? false,
            position: tool.position,
            toolId: questionIndex,
            typeQuestionareTextAnswer: 0,
            openQuestionnaire,
            closeQuestionnaire,
            trainingProgramId: this.programId,
            typeQuestionnaire: questionData.type,
            minAnswerRange:
              questionData.type === QuestionTypeEnum.NUMERIC ? (questionData as IQuestionnaireNumeric).min : 0,
            maxAnswerRange:
              questionData.type === QuestionTypeEnum.NUMERIC ? (questionData as IQuestionnaireNumeric).max : 0,
            jsonForWeb: this.utils.generateJsonForWeb(tool, questionIndex),
            revealedAt: openQuestionnaire,
            entityTags: tool.entityTags,
            repeatId: tool.repeatId,
          } as ITemplateQuestionnaireAPI
        })

        const questionsInDay = result[this.fields.questionnaires] as ITemplateQuestionnaireAPI[]
        questionsInDay.push(...questionnaires)

        continue
      }

      if (tool.type === planingToolTypesEnum.SUMMARY) {
        const revealedAt =
          this.keepRevealedAt && tool.revealedTime
            ? tool.revealedTime
            : tool.isAllDaySelected
            ? defaultDateStart
            : correctRevealedTime

        const summary = {
          summary: tool.summary,
          revealedAt,
          position: tool.position,
          id: tool?.entityIds?.[0] ?? -1,
          toolId: tool.id,
          jsonForWeb: this.utils.generateJsonForWeb(tool),
          entityTags: tool.entityTags,
          imageLink: tool.imageLink,
          subTitle: tool.subTitle,
          title: tool.title,
          buttonText: tool.buttonText,
          buttonUrl: tool.buttonUrl,
          repeatId: tool.repeatId,
          eventDay: tool.eventDay || null,
          programWallPostId: tool.programWallPostId,
          progressValue: tool.progressValue || 0,
          isPublished: tool.isPublished,
          //@ts-ignore
          eventPlaceId: tool.eventPlaceId,
        } as ITemplateSummaryAPI

        ;(result[this.fields.summary] as ITemplateSummaryAPI[]).push(summary)
      }

      if (tool.type === planingToolTypesEnum.SCENARIO) {
        const revealedAt =
          this.keepRevealedAt && tool.revealedTime
            ? tool.revealedTime
            : tool.isAllDaySelected
            ? defaultDateStart
            : correctRevealedTime

        const { originalEntity = {} } = tool
        const originalJsonForWeb = JSON.parse(originalEntity.jsonForWeb || '{}')
        const toolJsonForWeb = JSON.parse(this.utils.generateJsonForWeb(tool))
        const jsonForWeb = JSON.stringify({
          ...originalJsonForWeb,
          ...toolJsonForWeb,
        })

        const scenario = {
          ...originalEntity,
          jsonForWeb,
          revealedAt,
          position: tool.position,
          id: tool?.entityIds?.[0] ?? -1,
          name: tool.name,
          toolId: tool.id,
          entityTags: tool.entityTags,
          repeatId: tool.repeatId,
          isPublished: tool.isPublished,
        } as ITemplateScenarioAPI

        ;(result[this.fields.scenarios] as ITemplateScenarioAPI[]).push(scenario)
      }

      if (tool.type === planingToolTypesEnum.LINK) {
        const links = tool.linksValues.map((postLink, toolId) => {
          return {
            postLink: postLink.join(' '),
            revealedAt: tool.isAllDaySelected ? defaultDateStart : correctRevealedTime,
            position: tool.position,
            id: tool?.entityIds?.[toolId] ?? -1,
            toolId,
            jsonForWeb: this.utils.generateJsonForWeb(tool),
            entityTags: tool.entityTags,
            repeatId: tool.repeatId,
          } as ITemplateLinkAPI
        })

        const linksInResult = result[this.fields.links] as ITemplateLinkAPI[]
        linksInResult.push(...links)

        continue
      }
    }

    return result
  }

  public run() {
    const result: ITemplateDayAPI_V2[] | IWallPostDayAPI[] = []

    for (let i = 0; i < this.templateData.length; i++) {
      const day = this.handleDay(this.templateData[i]) as any

      result.push(day)
    }

    return result
  }
}

export const templateParserToServer = new ParseTemplateToServer()
