import { makeAutoObservable } from 'mobx'

import templatesApi from 'api/templates.api'
import wallPostApi from 'api/wallPost.api'

import { IAvailableFollowUp, IDayData } from 'types/Template'

import BoundContentCache from 'utils/caches/BoundContentCache'
import { QuestionnaireFollowUpActionsParser } from 'utils/parseTemplate'
import transformPostTypeToText from 'utils/transformPostTypeToText'

class FollowUpStore {
  constructor() {
    makeAutoObservable(this)
  }

  private followUpParser = new QuestionnaireFollowUpActionsParser()

  availableContentForFollowUp: IAvailableFollowUp[] = []
  availableContentForFollowUpBound: IAvailableFollowUp[] = []

  followUpContentIds: number[] = []
  followUpUnboundContentIds: number[] = []

  private boundCache = new BoundContentCache()

  async setFollowUpContent(programId: number) {
    const content = await wallPostApi.getProgramContent(programId)

    this.followUpContentIds = content.map(item => item.postId)
  }

  setFollowUpUnboundContent(days: IDayData[]) {
    this.followUpUnboundContentIds = this.followUpParser.findActiveContentToFollowUpById(days)
  }

  setAvailableContentForFollowUp(days: IDayData[]) {
    this.availableContentForFollowUp = this.followUpParser.getAvailableContentToFollowUp(days)
  }

  async setAvailableContentForFollowUpBound({
    id,
    isWallPost,
    skipCache,
  }: {
    id: number
    isWallPost: boolean
    skipCache?: boolean
  }) {
    const cacheKey = this.boundCache.generateKey({ id, isWallPost })

    if (!this.boundCache.has(cacheKey) || skipCache) {
      let content

      if (isWallPost) {
        content = await wallPostApi.getProgramContent(id)
      } else {
        content = await templatesApi.getTemplateContent(id)
      }

      const followUp = content.map(item => ({ ...item, contentId: item.postId }))

      this.availableContentForFollowUpBound = followUp
      this.boundCache.add(cacheKey, followUp)
    } else {
      this.availableContentForFollowUpBound = this.boundCache.extract(cacheKey)
    }
  }

  clearStore() {
    this.availableContentForFollowUp = []
    this.availableContentForFollowUpBound = []
    this.followUpContentIds = []
    this.followUpUnboundContentIds = []
  }

  get followUpOptions() {
    return this.availableContentForFollowUp.map(content => ({
      label: `Content ${content.contentId} • ${transformPostTypeToText(content.postType)}`,
      value: content.contentId,
    }))
  }

  get getFollowUpOptionsForBound() {
    return [...this.availableContentForFollowUp, ...this.availableContentForFollowUpBound].map(content => ({
      label: `Content ${content.contentId} • ${transformPostTypeToText(content.postType)}`,
      value: content.contentId,
    }))
  }
}

export default new FollowUpStore()
