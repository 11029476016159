import classNames from 'classnames'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'

import { IQuestionnaireRating, QuestionTypeEnum } from 'types/Template'

import mergeTextAndVariable from 'utils/mergeTextAndVariable'

import QuestionEditActions from '../../QuestionEditActions'

import CustomInput from 'components/CustomComponents/CustomInput'
import ToolWatchMode from 'components/ToolWatchMode'

import styles from '../styles.module.scss'

interface IProps {
  question: string
  questionIndex: number
  handleChange: (questionState: IQuestionnaireRating, questionIndex: number) => void
  isEditing: boolean
  canValidate: boolean
  handleTypeChange: (option: QuestionTypeEnum | null) => void
  handleDelete: (questionIndex: number) => void
  questionCount: number
}

const Rating: React.FC<IProps> = ({
  question,
  handleChange,
  questionIndex,
  isEditing,
  canValidate,
  handleDelete,
  handleTypeChange,
  questionCount,
}) => {
  const isError = canValidate && !question

  const scrollParam = useMemo(() => {
    const obj: any = {}

    if (isError) {
      obj['data-scroll'] = '1'
    }

    return obj
  }, [isError])

  return (
    <div {...scrollParam}>
      <ToolWatchMode isEditing={isEditing} value={question} className={styles.styleClear}>
        <div className={styles.ToolWatchModeInner}>
          <CustomInput
            dir="ltr"
            className={classNames(styles.input, {
              [styles.error]: isError,
            })}
            value={question}
            onChange={e => handleChange({ type: QuestionTypeEnum.RATING, question: [e.target.value] }, questionIndex)}
            onSelectVariable={variable => {
              handleChange(
                { type: QuestionTypeEnum.RATING, question: [mergeTextAndVariable(question, variable)] },
                questionIndex
              )
            }}
            disabled={!isEditing}
            placeholder={t<string>('templateCreation.ratingInputPlaceholder')}
            data-rating-question-input="rating-question-input"
            id="rating-question-input"
          />
          <QuestionEditActions
            canValidate={canValidate}
            handleDelete={handleDelete}
            handleTypeChange={handleTypeChange}
            isEditing={isEditing}
            questionCount={questionCount}
            questionIndex={questionIndex}
            questionType={QuestionTypeEnum.RATING}
          />
        </div>
      </ToolWatchMode>
    </div>
  )
}

export default observer(Rating)
