import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { makeAutoObservable } from 'mobx'

import { INotification } from 'types/Notifications'

import LS, { LSKeys } from 'utils/LS'

const url = `${process.env.REACT_APP_HOST_URL}/notificationHub`

enum signalREnumInvokingEvents {
  GetActualNotifications = 'GetActualNotifications',
  ViewNotification = 'ViewNotification',
  ViewAllNotification = 'ViewAllInviteNotificationUpTo',
  GetNotifications = 'GetNotifications',
}

enum signalREnumReceiveEvents {
  SendNotifications = 'SendNotifications',
  UserAcceptInvite = 'UserAcceptInvite',
  ViewNotificationOk = 'ViewNotificationOk',
}

class Store {
  constructor() {
    makeAutoObservable(this)
  }

  hubConnection: HubConnection | null = null
  actualNotifications: INotification[] = []
  allNotifications: INotification[] = []
  actualNotificationsBadgeCount = 0
  isConnecting = false
  isLoading = false

  async connectToNotificationHub(): Promise<void | HubConnection> {
    try {
      const token = LS.get(LSKeys.TOKEN) || LS.get(LSKeys.ADMIN_TOKEN)

      const hubConnection = new HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => token,
          withCredentials: true,
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build()

      hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 10
      this.isLoading = true

      await hubConnection.start()

      this.hubConnection = hubConnection
      this.isConnecting = false

      hubConnection.on(signalREnumReceiveEvents.SendNotifications, message => {
        const notifications = JSON.parse(message).Items

        // parse notifications values to first letter lower case
        const parsedNotifications = notifications.map((obj: any) => {
          return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k[0].toLowerCase() + k.slice(1), v]))
        }) as INotification[]

        this.allNotifications = parsedNotifications
        this.actualNotifications = parsedNotifications.filter(item => !item.notificationVieweds.length)
      })

      hubConnection.on(signalREnumReceiveEvents.UserAcceptInvite, message => {
        const notifications = JSON.parse(message).Items

        // parse notifications values to first letter lower case
        const parsedNotifications = notifications.map((obj: any) => {
          return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k[0].toLowerCase() + k.slice(1), v]))
        }) as INotification[]

        this.actualNotifications = parsedNotifications
      })

      hubConnection.on(signalREnumReceiveEvents.ViewNotificationOk, message => {})

      this.getAllNotifications()

      return hubConnection
    } catch (e) {
      if (!this.isConnecting) {
        this.isConnecting = true
        // need new refresh logic
        // const statusOK = await sendRefreshRequest(true)
        // if (statusOK) this.connectToNotificationHub()
        // else message.error('Connect failed')
      }
      console.error(e)
    } finally {
      this.isLoading = false
    }
  }

  async getActualNotifications() {
    try {
      if (!this.hubConnection) return

      const payload = JSON.stringify({
        PageLength: 999,
        PageNumber: 1,
      })

      await this.hubConnection.invoke(signalREnumInvokingEvents.GetActualNotifications, payload)
    } catch (e) {
      console.log(e)
    }
  }

  async getAllNotifications() {
    try {
      if (!this.hubConnection) return

      const payload = JSON.stringify({
        PageLength: 999,
        PageNumber: 1,
      })

      await this.hubConnection.invoke(signalREnumInvokingEvents.GetNotifications, payload)
    } catch (e) {
      console.log(e)
    }
  }

  async viewNotification() {
    try {
      if (!this.hubConnection) return

      const payload = JSON.stringify({
        inviteNotificationId: this.actualNotifications?.[0]?.id,
      })

      await this.hubConnection.invoke(signalREnumInvokingEvents.ViewAllNotification, payload)
    } catch (e) {
      console.log(e)
    }
  }
}

export default new Store()
