import { t } from 'i18next'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import {
  IDeleteIds,
  IQuestionnaireCheckBox,
  IQuestionnaireMultipleChoice,
  IQuestionnaireNumeric,
  QuestionTypeEnum,
  QuestionnaireState,
} from 'types/Template'

import ChoiceType from './QuestionTypes/ChoiceType'
import Numeric from './QuestionTypes/Numeric'
import Rating from './QuestionTypes/Rating'
import Text from './QuestionTypes/Text'

import styles from './styles.module.scss'

interface IProps {
  isEditing: boolean
  questionIndex: number
  questionCount: number
  question: QuestionnaireState
  setIsValidationError: Dispatch<SetStateAction<boolean>>
  handleChange: (questionState: QuestionnaireState, questionIndex: number) => void
  handleDelete: (questionIndex: number) => void
  addEntityToDeleting: (entity: IDeleteIds) => void
  canValidate: boolean
  isUnbound?: boolean
  dayId?: number
  toolId?: number
}

const QuestionItem: React.FC<IProps> = ({
  questionIndex,
  question,
  handleChange,
  handleDelete,
  setIsValidationError,
  isEditing,
  questionCount,
  addEntityToDeleting,
  canValidate,
  isUnbound,
  dayId,
  toolId,
}) => {
  const [questionType, setQuestionType] = useState<QuestionTypeEnum | null>(null)

  useEffect(() => {
    if (!question) return

    setQuestionType(question.type === null ? null : question.type)
  }, [question])

  const handleTypeChange = (option: QuestionTypeEnum | null) => {
    setQuestionType(option)

    const obj = { ...question, type: option }

    if (option !== QuestionTypeEnum.NUMERIC) {
      //@ts-ignore
      delete obj.min
      //@ts-ignore
      delete obj.max
    }

    if (option !== QuestionTypeEnum.CHECK_BOX && option !== QuestionTypeEnum.MULTIPLE_CHOICE) {
      //@ts-ignore
      delete obj.options
    }

    //@ts-ignore
    handleChange(obj, questionIndex)
  }

  const defaultTypes = {
    handleChange,
    questionIndex,
    setIsValidationError,
    isEditing,
    canValidate,
    handleTypeChange,
    questionCount,
    handleDelete,
  }

  return (
    <div className={styles.question}>
      <div>
        <label>
          {t<string>('programCreation.questionField')} {questionIndex + 1}
        </label>
        {questionType === QuestionTypeEnum.NUMERIC ? (
          <Numeric {...(question as IQuestionnaireNumeric)} {...defaultTypes} />
        ) : questionType === QuestionTypeEnum.RATING ? (
          <Rating
            question={Array.isArray(question.question) ? question.question[0] : question.question}
            {...defaultTypes}
            isEditing={isEditing}
          />
        ) : questionType === QuestionTypeEnum.CHECK_BOX ? (
          <ChoiceType
            {...(question as IQuestionnaireCheckBox)}
            {...defaultTypes}
            isCheckBox={true}
            addEntityToDeleting={addEntityToDeleting}
            followUpAction={[]}
            followUpActionField={[]}
            isUnbound={isUnbound}
            dayId={dayId}
            toolId={toolId}
          />
        ) : questionType === QuestionTypeEnum.MULTIPLE_CHOICE ? (
          <ChoiceType
            {...(question as IQuestionnaireMultipleChoice)}
            {...defaultTypes}
            addEntityToDeleting={addEntityToDeleting}
            isUnbound={isUnbound}
            dayId={dayId}
            toolId={toolId}
          />
        ) : questionType === QuestionTypeEnum.TEXT ? (
          <Text question={question.question as string} {...defaultTypes} isEditing={isEditing} />
        ) : (
          <Text isEmpty question={question.question as string} {...defaultTypes} isEditing={isEditing} />
        )}
      </div>
    </div>
  )
}

export default QuestionItem
