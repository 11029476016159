import { UploadFile } from 'antd'

export interface ICopyStateFileType {
  fileList: [] | UploadFile<any>[]
  isAllDaySelected: null | boolean
  revealedTime: undefined | moment.Moment
  schedulerValue: number
  entityIds: number[]
  entityTags: number[]
  daysSheduleValue: number[]
}

export interface ICopyStateVideo {
  videoFileList: [] | UploadFile<any>[]
  videoLink: string
  modifiedVideoLink: string
  revealedTime: undefined | moment.Moment
  isAllDaySelected: null | boolean
  schedulerValue: number
  entityIds: number[]
  entityTags: number[]
  daysSheduleValue: number[]
}

export const toolStateCopyInitForFileTypeTool = () => {
  const stateCopy: ICopyStateFileType = {
    fileList: [],
    isAllDaySelected: null,
    revealedTime: undefined,
    schedulerValue: 1,
    entityIds: [],
    entityTags: [],
    daysSheduleValue: [],
  }

  return stateCopy
}

export const toolStateCopyInitForVideoTypeTool = () => {
  const stateCopy: ICopyStateVideo = {
    videoFileList: [],
    videoLink: '',
    modifiedVideoLink: '',
    revealedTime: undefined,
    isAllDaySelected: null,
    schedulerValue: 1,
    entityIds: [],
    entityTags: [],
    daysSheduleValue: [],
  }

  return stateCopy
}
