import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import programsApi from 'api/programs.api'

import message from 'utils/message'

import { IProgramList } from 'types/Program'
import { QueryKeysEnum } from 'types/QueryKeysEnum'

interface IProps {
  selectedProgram: IProgramList | undefined
  onSuccess: () => void
}

const useCopyProgramMutation = ({ selectedProgram, onSuccess }: IProps) => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const copyProgram = useCallback(
    async ({ programName }: { programName: string }) => {
      if (!selectedProgram) return

      const res = await programsApi.copyProgram(selectedProgram.id, programName)

      if (!res || !res.id) {
        message.error(t<string>('programList.programExist', { name: programName }))
        return
      }

      onSuccess()

      navigate('/programs/' + res.id)
    },
    [selectedProgram, onSuccess]
  )

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async ({ programName }: { programName: string }) => await copyProgram({ programName }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeysEnum.PROGRAMS] })
    },
  })

  return { copyProgram: mutateAsync, isLoading }
}

export default useCopyProgramMutation
