import LS, { LSKeys } from 'utils/LS'

export const getSavedProgramData = () => {
  return {
    program: LS.get(LSKeys.PROGRAM),
    logoImage: LS.get(LSKeys.PROGRAM_LOGO),
    groupImage: LS.get(LSKeys.PROGRAM_GROUP_LOGO),
    trainees: LS.get(LSKeys.PROGRAM_TRAINEES),
    traineesSelected: LS.get(LSKeys.PROGRAM_TRAINEES_SELECTED),
    templateId: LS.get(LSKeys.PROGRAM_TEMPLATE_ID),
    template: LS.get(LSKeys.PROGRAM_TEMPLATE),
  }
}

export const clearProgramStorage = () => {
  LS.remove(LSKeys.PROGRAM)
  LS.remove(LSKeys.PROGRAM_LOGO)
  LS.remove(LSKeys.PROGRAM_GROUP_LOGO)
  LS.remove(LSKeys.PROGRAM_TRAINEES)
  LS.remove(LSKeys.PROGRAM_TRAINEES_SELECTED)
  LS.remove(LSKeys.PROGRAM_TEMPLATE_ID)
  LS.remove(LSKeys.PROGRAM_TEMPLATE)
}
