import { Dispatch, FC, memo, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IDailyReportAnswer } from 'types/Program'

import MenuTopLine from '../MenuTopLine'
import Question from './Question'

import ContextMenu from 'components/ContextMenu'
import InfoMessage from 'components/InfoMessage'
import SearchBar from 'components/SearchBar'

import styles from './styles.module.scss'

interface IProps {
  repeatedQuestions: IDailyReportAnswer[]
  selectedQuestionNames: string[]
  setSelectedQuestionNames: Dispatch<SetStateAction<string[]>>
}

const SelectQuestionMenu: FC<IProps> = ({ repeatedQuestions, selectedQuestionNames, setSelectedQuestionNames }) => {
  const { t } = useTranslation()

  const [searchVal, setSearchVal] = useState('')

  const repeatedQuestionsSearch = useMemo(() => {
    return repeatedQuestions.filter(question => question.question.toLowerCase().includes(searchVal.toLowerCase()))
  }, [searchVal, repeatedQuestions])

  const selectAllChange = useCallback(
    (e: any) => {
      if (e.target.checked) {
        setSelectedQuestionNames(repeatedQuestions.map(question => question.question))
      } else {
        setSelectedQuestionNames([])
      }
    },
    [repeatedQuestions]
  )

  const selectOneChange = useCallback(
    (_: any, question: IDailyReportAnswer) => {
      if (selectedQuestionNames.includes(question.question)) {
        setSelectedQuestionNames(prev => prev.filter(name => name !== question.question))
      } else {
        setSelectedQuestionNames(prev => [...prev, question.question])
      }
    },
    [selectedQuestionNames]
  )

  return (
    <ContextMenu>
      <div>
        <SearchBar
          value={searchVal}
          onChangeCustom={setSearchVal}
          placeholder={t<string>('dailyReport.searchQuestion')}
        />
        <MenuTopLine
          title={`${t<string>('dailyReport.selectedQuestions')} (${selectedQuestionNames.length})`}
          checkboxChecked={selectedQuestionNames.length > 0 && selectedQuestionNames.length >= repeatedQuestions.length}
          onCheckboxChange={selectAllChange}
        />
        <InfoMessage title={t<string>('dailyReport.questionsFilterMessage')} />
        <div className={styles.itemsContainer}>
          {repeatedQuestionsSearch?.length ? (
            repeatedQuestionsSearch.map(question => (
              <Question
                key={question.questionId}
                selectOneChange={selectOneChange}
                selected={selectedQuestionNames.includes(question.question)}
                question={question}
              />
            ))
          ) : (
            <div className={styles.notFound}>{t<string>('dailyReport.questionsNotFound')}</div>
          )}
        </div>
      </div>
    </ContextMenu>
  )
}

export default memo(SelectQuestionMenu)
