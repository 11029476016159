import React from 'react'
import { Upload, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { uploadExcelFile } from 'api/uploadFile.api'
import Dragger from 'antd/lib/upload/Dragger'

import { useTranslation } from 'react-i18next'

const ExcelUpload: React.FC = () => {
  const { t } = useTranslation()
  const props: UploadProps = {
    beforeUpload: (file: RcFile) => {
      const isExcel =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      if (!isExcel) {
        message.error(t('uploadFile.onlyExcelFile'))
      }
      return isExcel || Upload.LIST_IGNORE
    },
    onChange: (info: UploadChangeParam<UploadFile<any>>) => {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} ${t('uploadFile.fileUploadSuccessfully')}`)
      } else if (status === 'error') {
        message.error(`${info.file.name} ${t('uploadFile.fileUploadFailed')}`)
      }
    },
    customRequest: async (options: UploadRequestOption<any>) => {
      const { file, onSuccess, onError } = options
      try {
        if (!(file instanceof Blob)) {
          throw new Error(t('uploadFile.invalidType'))
        }

        await uploadExcelFile(file)

        if (onSuccess) {
          onSuccess('ok')
        }
      } catch (error) {
        if (onError) {
          onError(error as Error)
        }
      }
    },
  }

  return (
    <Dragger {...props} style={{ padding: 20 }} accept=".xls,.xlsx">
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{t('uploadFile.clickOrGrad')}</p>
      <p className="ant-upload-hint">{t('uploadFile.supportText')}</p>
    </Dragger>
  )
}

export default ExcelUpload
