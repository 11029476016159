import { UploadFile } from 'antd/lib/upload/interface'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { useStore } from 'stores'

import useCheckIfUploadingFiles from 'hooks/tools/useCheckIfUploadingFiles'
import useDeleteEntity from 'hooks/tools/useDeleteEntity'
import useFileUpload from 'hooks/tools/useFileUpload'
import useInstallToolFileType from 'hooks/tools/useInstallToolFileType'
import useSaveTool from 'hooks/tools/useSaveTool'
import useSaveToolWithPlanning from 'hooks/tools/useSaveToolWithPlanning'
import useToolFileLoading from 'hooks/tools/useToolFileLoading'
import useToolSchedule from 'hooks/tools/useToolSchedule'
import useToolScroll from 'hooks/tools/useToolScroll'
import useToolState from 'hooks/tools/useToolState'
import useToolTags from 'hooks/tools/useToolTags'

import { IDefaultToolProps, IFileDayItem, ITagWithToolRelation, deleteEntityTypeEnum } from 'types/Template'
import { RepeatingTypeEnum, schedulerOptionEnum } from 'types/Template/Tools'

import message from 'utils/message'
import { toolStateCopyInitForFileTypeTool } from 'utils/toolStateCopyInit'
import checkIfNeedShedulerConfirm from 'utils/tools/checkIfNeedShedulerConfirm'
import pickFileValidation from 'utils/tools/pickFileValidation'

import ToolRevealedPanel from 'modules/TemplateModule/DayItem/DayTool/ToolSwitch/ToolRevealedPanel'
import ToolWrapper from 'modules/TemplateModule/DayItem/DayTool/ToolSwitch/ToolWrapper'

import DraggableUpload from 'components/DragableUploadList'

let stateCopy = toolStateCopyInitForFileTypeTool()

const FileTool = ({ tool, toolIndex, isDraggingItem, isUnbound, dayId, dayIndex, onDelete }: IDefaultToolProps) => {
  const { templateStore } = useStore()
  const { templateEntity } = templateStore

  const [filesList, setFilesList] = useState<UploadFile<any>[]>([])
  const [toolData, setToolData] = useState<IFileDayItem | null>(null)

  const {
    isAllDaySelected,
    isEditing,
    isFirstDrop,
    isSchedulerModalVisible,
    isUpdating,
    revealedTime,
    setIsAllDaySelected,
    setIsEditing,
    setIsFirstDrop,
    setIsSchedulerModalVisible,
    setIsUpdating,
    setRevealedTime,
  } = useToolState()

  const thisTool = tool as IFileDayItem

  const { handleTagsChange, selectedTagIds, setSelectedTagIds, selectedTags, entityTagsToRemove } = useToolTags({
    tags: thisTool.entityTags as ITagWithToolRelation[],
  })

  const { schedulerValue, setSchedulerValue, daysSheduleValue, setDaysScheduleValue } = useToolSchedule({
    initialSchedule: thisTool.schedulerOption,
    initialDaysSchedule: thisTool.daysSheduleValue,
  })

  const { toolLoading, loadingProgress } = useToolFileLoading({
    item: thisTool as IFileDayItem,
    setFiles: setFilesList,
    toolIndex,
  })

  const { someFilesUploadInProgress } = useCheckIfUploadingFiles({ fileList: filesList })

  const { forceUploadFile, uploadingProgress } = useFileUpload({ toolIndex })

  const {
    addEntityToDeleting,
    onDeleteEntities,
    resetEntityIdsToDelete,
    addUniqueIdToExclude,
    resetUniqueIdToExclude,
    uniqIdsToExclude,
  } = useDeleteEntity({ isUnbound })

  const { onSave, savingLoading } = useSaveTool({ entityTagsToRemove, isUnbound, dayId })

  const { isMounted } = useInstallToolFileType<IFileDayItem>({
    setIsAllDaySelected,
    setRevealedTime,
    toolInstance: thisTool,
    setFileList: setFilesList,
    setToolData,
    setIsEditing,
    setIsFirstDrop,
    isUnbound,
  })

  useToolScroll({
    isMounted,
    isFirstDrop,
    uniqueId: toolData?.uniqueId,
  })

  useEffect(() => {
    if (isMounted) {
      if (isDraggingItem) return

      const fileData = thisTool as IFileDayItem

      if (!fileData) return

      setFilesList(fileData.fileList)

      if (fileData.isAllDaySelected) {
        setIsAllDaySelected(fileData.isAllDaySelected)
      } else {
        setRevealedTime(fileData.revealedTime ? moment(fileData.revealedTime).local() : undefined)
        setIsAllDaySelected(false)
      }

      setToolData(fileData)
    }
  }, [thisTool])

  const handleFileUpload = async ({ fileList }: { fileList: UploadFile<any>[]; file: UploadFile<any> }) => {
    const filteredExisted = fileList.filter(file => !filesList.some(existedFile => existedFile.uid === file.uid))

    const filteredFileListByValidation = pickFileValidation(filteredExisted)

    if (!filteredFileListByValidation) return

    for (let i = 0; i < filteredFileListByValidation.length; i++) {
      forceUploadFile(filteredFileListByValidation[i])
    }

    setFilesList(prevState => [...prevState, ...filteredFileListByValidation])
  }

  const validationBeforeActions = () => {
    if (!filesList.length) {
      message.error(t<string>('templateCreation.uploadFiles'))
      return true
    }

    if (!revealedTime && !isAllDaySelected) {
      message.error(t<string>('templateCreation.revealedTimeError'))
      return true
    }

    if (schedulerValue === schedulerOptionEnum.specificDay && !daysSheduleValue.length) {
      message.error(t('templateCreation.selectAtLeastOneDay'))
      return true
    }

    return false
  }

  const handleSaveClick = async (repeatingOption?: RepeatingTypeEnum) => {
    if (validationBeforeActions()) return
    setIsUpdating(true)

    if (checkIfNeedShedulerConfirm({ repeatingOption, schedulerValue, thisTool })) {
      return setIsSchedulerModalVisible(true)
    }

    if (!toolData) return

    const uniqIds = toolData.uniqueId.filter(id => !uniqIdsToExclude.includes(id))

    const tool = {
      ...thisTool,
      id: toolData.id ?? -1,
      entityIds: toolData.entityIds,
      fileIds: toolData.fileIds,
      fileList: filesList,
      revealedTime: revealedTime?.toISOString(true).slice(0, -6) as string,
      isAllDaySelected,
      uniqueId: filesList.map((file, idx) => uniqIds[idx] || v4()),
      // uniqueId: toolData?.uniqueId || v4(),
      fromDay: toolData?.fromDay,
      schedulerOption: schedulerValue,
      entityTags: selectedTags,
      position: isFirstDrop ? (isUnbound ? 0 : templateEntity.lastPosition) : toolData?.position || toolIndex,
      repeatId: toolData?.repeatId || v4(),
      daysSheduleValue,
    } as IFileDayItem

    try {
      await onSave({ tool, onDeleteEntities, repeatingOption })

      saveToolFlagsSet()
    } catch (e) {
      message.error(t('programCreation.smtWrong'))
    }
  }

  const { onSaveWithPlanning } = useSaveToolWithPlanning({ onSave: handleSaveClick, setIsSchedulerModalVisible })

  const saveToolFlagsSet = () => {
    templateStore.setIsToolEditing(false, isUnbound)

    setIsEditing(false)
    setIsFirstDrop(false)
    setIsUpdating(false)
    resetUniqueIdToExclude()
  }

  const handleToolEdit = () => {
    if (templateStore.checkIfToolEditing(isUnbound)) {
      message.error(t('templateCreation.previousEdit'))
      return
    }

    if (!isEditing) {
      templateStore.setIsToolEditing(true, isUnbound)

      stateCopy = {
        isAllDaySelected,
        revealedTime,
        fileList: toolData?.fileList || [],
        schedulerValue,
        entityIds: thisTool.entityIds || [],
        entityTags: selectedTagIds,
        daysSheduleValue,
      }
    } else {
      rollBackChanges()
    }
    setIsEditing(prevState => !prevState)
  }

  const handlePreview = (file: UploadFile<any>) => {
    const link = document.createElement('a')
    if (file.url) {
      link.href = file.url
      link.download = file.name
    }
    link.click()
  }

  const handleRemove = (file: UploadFile<any>) => {
    if (!toolData) return
    const fileToolDataCopy = { ...toolData }
    setFilesList(prevState =>
      prevState.filter((item, fileIndex) => {
        if (item.uid === file.uid) {
          const filteredFileIds = toolData?.fileIds?.filter((item, fileIdIndex) => fileIdIndex !== fileIndex) ?? []
          const filteredItemIds =
            toolData?.entityIds?.filter((entityId, fileIdIndex) => {
              if (fileIdIndex === fileIndex) {
                addEntityToDeleting({ id: [entityId], entity: deleteEntityTypeEnum.file })

                if (tool.uniqueId[fileIndex] && !isUnbound) {
                  addUniqueIdToExclude(tool.uniqueId[fileIndex])
                }

                return false
              }

              return true
            }) ?? []
          fileToolDataCopy.fileIds = filteredFileIds
          fileToolDataCopy.entityIds = filteredItemIds
          setToolData(fileToolDataCopy)
          return false
        }
        return true
      })
    )
  }

  const handleSelectSchedulerOption = (value: number) => {
    if (!toolData || schedulerValue === value || validationBeforeActions()) return

    setSchedulerValue(value)
  }

  const rollBackChanges = () => {
    if (isFirstDrop) {
      onDelete(() => setIsEditing(false))
      return
    }

    setDaysScheduleValue(stateCopy.daysSheduleValue)

    if (stateCopy.isAllDaySelected !== null) {
      setIsAllDaySelected(stateCopy.isAllDaySelected)
    }

    if (stateCopy.entityTags) {
      setSelectedTagIds(stateCopy.entityTags)
    }

    setRevealedTime(stateCopy.revealedTime)

    if (stateCopy.fileList) {
      setFilesList(stateCopy.fileList)
    }

    if (stateCopy.schedulerValue) {
      setSchedulerValue(stateCopy.schedulerValue)
      handleSelectSchedulerOption(stateCopy.schedulerValue)
    }

    resetUniqueIdToExclude()
    resetEntityIdsToDelete()
    templateStore.setIsToolEditing(false, isUnbound)
    setIsEditing(false)
  }

  return (
    <ToolWrapper
      data-container-file="container-file"
      isEditing={isEditing}
      daysValue={daysSheduleValue}
      onDaysValueChange={setDaysScheduleValue}
      handleSaveClick={() => handleSaveClick()}
      rollBackChanges={rollBackChanges}
      id={isUnbound ? dayId?.toString() || '' : toolData?.uniqueId?.[0]}
      handleToolEdit={handleToolEdit}
      isFirstDrop={isFirstDrop}
      toolNumber={isUnbound ? dayIndex + 1 : toolIndex + 1}
      revealedAt={isAllDaySelected || revealedTime}
      isUnbound={isUnbound}
      handleSelectSchedulerOption={handleSelectSchedulerOption}
      schedulerValue={schedulerValue}
      handleTagsChange={handleTagsChange}
      selectedTagIds={selectedTagIds}
      onDelete={() => {
        onDelete(() => setIsEditing(false))
      }}
      savingLoading={savingLoading}
      handleSchedulerApplyModalOk={onSaveWithPlanning}
      isSchedulerModalVisible={isSchedulerModalVisible}
      isUpdating={isUpdating}
      setIsSchedulerModalVisible={setIsSchedulerModalVisible}
      srcTool={thisTool}
      isSaveDisabled={someFilesUploadInProgress}
      dayId={dayId}
    >
      <DraggableUpload
        fileList={filesList}
        setFileList={setFilesList}
        handleFileUpload={handleFileUpload}
        handleRemove={handleRemove}
        isEditing={isEditing}
        handlePreview={handlePreview}
        toolData={toolData}
        setToolData={setToolData}
        loading={toolLoading}
        fileIds={(thisTool as IFileDayItem)?.fileIds}
        loadingProgress={loadingProgress}
        uploadingProgress={uploadingProgress}
        data-file-tool="file-tool"
      />

      {isEditing && !isUnbound && (
        <div style={{ margin: '10px 0' }} data-sub-content-2="subContent-2">
          <ToolRevealedPanel
            isAllDaySelected={isAllDaySelected}
            isEditing={isEditing}
            onChangeCheckbox={e => setIsAllDaySelected(e.target.checked)}
            onChangeTimePicker={value => setRevealedTime(value ?? undefined)}
            revealedTime={revealedTime}
          />
        </div>
      )}
    </ToolWrapper>
  )
}

export default observer(FileTool)
