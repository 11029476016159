import { FC } from 'react'

import styles from './styles.module.scss'

interface IProps {
  progress?: number
}

const Loader: FC<IProps> = ({ progress }) => {
  return (
    <div className={styles.container}>
      <div className={styles.loader} data-loader-container="loader-container">
        <div data-loader-spin-1="loader-spin-1"></div>
        <div data-loader-spin-2="loader-spin-2"></div>
        <div data-loader-spin-3="loader-spin-3"></div>
        <div data-loader-spin-4="loader-spin-4"></div>
      </div>
      {!!progress && <div className={styles.progress}>{progress}%</div>}
    </div>
  )
}

export default Loader
