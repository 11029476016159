import { Dispatch, FC, SetStateAction, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { LINK_TEXT_DELIMITER, TEXT_SPACES_REPLACER, urlRe } from 'utils/const'
import { ILocalLink } from '../index'

import CustomDeleteIcon from 'components/CustomComponents/CustomDeteleIcon'
import CustomInput from 'components/CustomComponents/CustomInput'

import styles from './styles.module.scss'

interface IProps {
  link: ILocalLink
  isEditing: boolean
  setLinks: Dispatch<SetStateAction<ILocalLink[]>>
  idx: number
}

const LinkItem: FC<IProps> = ({ link, isEditing, setLinks, idx }) => {
  const isEmpty = !link.link
  const isInvalid = !urlRe.test(link.link)

  const { t } = useTranslation()

  const linkInside = useMemo(() => {
    return link.link.split(LINK_TEXT_DELIMITER)?.[0] || ''
  }, [link.link])

  const textInside = useMemo(() => {
    const text = link.link.split(LINK_TEXT_DELIMITER)?.[1] || ''

    return text.replaceAll(TEXT_SPACES_REPLACER, ' ')
  }, [link.link])

  const onChangeLink = (value: string) => {
    setLinks(prev =>
      prev.map((link, linkIdx) => {
        if (linkIdx === idx) {
          return { ...link, link: `${value}~${textInside.replaceAll(' ', TEXT_SPACES_REPLACER)}`, touched: true }
        }

        return link
      })
    )
  }

  const onChangeText = (value: string) => {
    setLinks(prev =>
      prev.map((link, linkIdx) => {
        if (linkIdx === idx) {
          return { ...link, link: `${linkInside}~${value.replaceAll(' ', TEXT_SPACES_REPLACER)}` }
        }

        return link
      })
    )
  }

  const onDelete = () => {
    if (!isEditing) return

    setLinks(prev => prev.filter((link, linkIdx) => linkIdx !== idx))
  }

  return (
    <div>
      {isEditing ? (
        <div className={styles.linkContainer}>
          <>
            <CustomInput
              value={textInside}
              onChange={e => onChangeText(e.target.value)}
              placeholder={t<string>('templateCreation.textToDisplay')}
              className={styles.input}
              disabled={!isEditing}
            />
            <CustomInput
              value={linkInside}
              onChange={e => onChangeLink(e.target.value)}
              placeholder={t<string>('templateCreation.insertLinkHere')}
              errorText={
                !link.touched
                  ? []
                  : isEmpty
                  ? [t<string>('templateCreation.linkRequired')]
                  : isInvalid
                  ? [t<string>('templateCreation.invalidUrl')]
                  : []
              }
              className={styles.input}
              disabled={!isEditing}
            />

            <div className={styles.deleteLink}>
              <CustomDeleteIcon onClick={onDelete} />
            </div>
          </>
        </div>
      ) : (
        <div
          className={styles.linkPresent}
          onClick={() => {
            if (!linkInside.includes('link.aimpact.ai/')) {
              window.open(linkInside, '_blank')?.focus()
            }
          }}
        >
          {textInside ? `${textInside} (${linkInside})` : linkInside}
        </div>
      )}
    </div>
  )
}

export default memo(LinkItem)
