import { useMutation, useQueryClient } from '@tanstack/react-query'

import tagsApi from 'api/tags.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

interface ITagAssingBody {
  tagId: number
  exclude: boolean
}

const useAssignTagToTraineeMutation = () => {
  const queryClient = useQueryClient()

  const { mutateAsync: assingTagToTrainee, isLoading } = useMutation({
    mutationFn: async ({ tags, traineeId }: { tags: ITagAssingBody[]; traineeId: number }) => {
      await Promise.all(tags.map(tag => tagsApi.addTagToTrainee({ tagId: tag.tagId, traineeId, exclude: tag.exclude })))

      return true
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeysEnum.ALL_USER_TRAINEES])
    },
    onError: () => {},
  })

  return { assingTagToTrainee, isLoading }
}

export default useAssignTagToTraineeMutation
