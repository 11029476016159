import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { checkValidVimeoLink, checkValidYoutubeLink } from 'utils/checkValidVideoLink'
import { removeURLParameter } from 'utils/helpers'
import message from 'utils/message'

interface IProps {
  setModifiedVideoLink: Dispatch<SetStateAction<string>>
}

const useVideoLinkValidate = ({ setModifiedVideoLink }: IProps) => {
  const { t } = useTranslation()

  const vimeoAndYoutubeLinkOnlyErrorShow = () => message.error(t('templateCreation.vimeoAndYoutubeLinkOnly'))

  const validateVideoLink = async (videoLink: string) => {
    const isValidVimeoLink =
      /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/.test(
        videoLink
      )
    const isValidYoutubeLink =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(
        videoLink
      )

    if (!isValidVimeoLink && !isValidYoutubeLink) {
      vimeoAndYoutubeLinkOnlyErrorShow()

      return
    }

    if (videoLink.includes('player.vimeo.com')) {
      setModifiedVideoLink(videoLink)
    } else if (videoLink.includes('vimeo.com')) {
      const isValid = await checkValidVimeoLink(videoLink)

      if (!isValid) return vimeoAndYoutubeLinkOnlyErrorShow()

      setModifiedVideoLink(videoLink.replace('vimeo.com/', 'player.vimeo.com/video/'))
    } else if (videoLink.includes('youtube.com')) {
      const isValid = await checkValidYoutubeLink(videoLink)

      if (!isValid) {
        vimeoAndYoutubeLinkOnlyErrorShow()

        return false
      }

      videoLink = removeURLParameter(removeURLParameter(videoLink, 'list'), 'index')
      setModifiedVideoLink(videoLink.replace('youtube.com/watch?v=', 'youtube.com/embed/').replace('&', '?'))
    } else if (videoLink.includes('youtu.be')) {
      const isValid = await checkValidYoutubeLink(videoLink)

      if (!isValid) return vimeoAndYoutubeLinkOnlyErrorShow()
      videoLink = removeURLParameter(removeURLParameter(videoLink, 'list'), 'index')
      setModifiedVideoLink(videoLink.replace('youtu.be/', 'youtube.com/embed/').replace('&', '?'))
    } else {
      vimeoAndYoutubeLinkOnlyErrorShow()
      return
    }
    return true
  }

  return { validateVideoLink }
}

export default useVideoLinkValidate
