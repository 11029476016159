import { action, computed, makeAutoObservable } from 'mobx'

import templatesApi from 'api/templates.api'
import wallPostApi from 'api/wallPost.api'

import {
  IAudioDayItem,
  IDayData,
  IFileDayItem,
  IImageDayItem,
  ILinkDayItem,
  IQuestionnaireDayItem,
  ISummaryDayItem,
  ITemplate,
  IVideoDayItem,
} from 'types/Template'
import { IScenarioDayItem } from 'types/Template/Tools'

const initialValues = {
  currentDayIndex: 0,
  loadingTemplate: false,
  loadingDay: false,
  programId: null,
  templateInfo: null,
  currentDay: null,
  dayKey: Date.now(),
  eventsDates: [],
}

class TemplateEntity {
  constructor() {
    makeAutoObservable(this)
  }

  public currentDayIndex: number = initialValues.currentDayIndex
  public programId: number | null = initialValues.programId
  public templateInfo: ITemplate | null = initialValues.templateInfo
  public currentDay: IDayData | null = initialValues.currentDay
  public loadingTemplate: boolean = initialValues.loadingTemplate
  public loadingDay: boolean = initialValues.loadingDay
  public dayKey: number = initialValues.dayKey
  public eventsDates: string[] = initialValues.eventsDates

  wipeTemplate() {
    this.currentDayIndex = initialValues.currentDayIndex
    this.programId = initialValues.programId
    this.templateInfo = initialValues.templateInfo
    this.currentDay = initialValues.currentDay
    this.loadingTemplate = initialValues.loadingTemplate
    this.loadingDay = initialValues.loadingDay
    this.eventsDates = initialValues.eventsDates
  }

  setEventsDates(events: string[]) {
    this.eventsDates = events
  }

  updateDayKey() {
    this.dayKey = Date.now()
  }

  setCurrentDayIndex(idx: number) {
    this.currentDayIndex = idx
  }

  setLoadingTemplate(status: boolean) {
    this.loadingTemplate = status
  }

  setLoadingDay(status: boolean) {
    this.loadingDay = status
  }

  setTemplateInfo(info: ITemplate | null) {
    this.templateInfo = info
  }

  setCurrentDay(day: IDayData | null) {
    this.currentDay = day
  }

  setProgramId(programId: number) {
    this.programId = programId
  }

  async deleteTool(idx: number) {
    if (!this.currentDay) throw new Error('current day is not found')

    const target = this.currentDay.dayItems.find((item, itemIdx) => itemIdx === idx)

    if (!target) return

    this.currentDay.dayItems = this.currentDay.dayItems.filter((tool, index) => index !== idx)
  }

  addTool(
    toolData:
      | IImageDayItem
      | IAudioDayItem
      | IVideoDayItem
      | IFileDayItem
      | ILinkDayItem
      | ISummaryDayItem
      | IQuestionnaireDayItem
      | IScenarioDayItem
  ) {
    if (!this.currentDay) throw new Error('current day is not found')

    this.currentDay.dayItems.push(toolData)
  }

  @action
  async renameTemplate(name: string) {
    if (!this.templateInfo) throw new Error('this.templateInfo is not found')

    await templatesApi.rename(this.templateInfo.id, name)

    this.templateInfo.name = name
  }

  async deleteDay(dayId: number) {
    try {
      this.setLoadingDay(true)

      if (!this.templateInfo) throw new Error('this.templateInfo is not found')

      if (this.isWallPosts && this.programId) {
        await wallPostApi.deleteWallPostDays({ dayIds: [dayId], trainingProgramId: this.programId })
      } else {
        await templatesApi.deleteTemplateDays({ dayIds: [dayId], templateId: this.templateInfo.id })
      }

      this.setTemplateInfo({
        ...this.templateInfo,
        duration: this.templateInfo.duration - 1,
      })
    } finally {
      this.setLoadingDay(false)
    }
  }

  @computed
  get isWallPosts() {
    return !!this.programId
  }

  @computed
  get lastPosition() {
    let lastPosition = 0

    this.currentDay?.dayItems.forEach(tool => {
      if (tool.position && tool.position > lastPosition) {
        lastPosition = tool.position
      }
    })

    return lastPosition
  }
}

export default new TemplateEntity()
