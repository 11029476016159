import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import templatesApi from 'api/templates.api'

import { QueryKeysEnum } from 'types/QueryKeysEnum'

const useQuickProgramTemplatesQuery = () => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null)
  const [selectedTemplateError, setSelectedTemplateError] = useState(false)

  const { data: templates, isLoading: templatesLoading } = useQuery({
    keepPreviousData: true,
    initialData: [],
    queryKey: [QueryKeysEnum.LIGHT_TEMPLATES],
    queryFn: async () => {
      const result = await templatesApi.getLightTemplates('', 1, 999)

      return result?.items || []
    },
  })

  return {
    templates,
    selectedTemplateId,
    setSelectedTemplateId,
    setSelectedTemplateError,
    selectedTemplateError,
    templatesLoading,
  }
}

export default useQuickProgramTemplatesQuery
